import React from 'react'
import { Descriptions, Table, Tag } from "antd"
import { StatusTag } from "components"

const { Column } = Table;

const IssueProductList = props => {

    const { selectedProduct, issueResponse } = props

    const findMeta = (metaId) => {
        if (selectedProduct.itemMetas) {
            const metaItem = selectedProduct.itemMetas.find(el => el.id === metaId)
            if (metaItem) return metaItem.meta
        }
        return []
    }

    return (
        <div>
            <h3>Issues for this product:</h3>
            {
                issueResponse && issueResponse.issues.map(item => {
                    const issueItems = item?.meta?.prices ? item.meta.prices : []
                    const itemsWithMeta = issueItems.map(el => {
                        el.meta = findMeta(el.id)
                        return el
                    })
                    const customerAction = item?.meta?.customer_action ? item.meta.customer_action : null
                    return (
                        <div key={item.id} style={{ marginTop: '15px' }}>
                            <Descriptions
                                title={item.type_label}
                                column={{ xxl: 3, xl: 3, lg: 3, md: 1, sm: 1, xs: 1 }}
                            >
                                <Descriptions.Item label="Issue status ">
                                    <StatusTag text={item?.status_label} slug={item.status} />
                                </Descriptions.Item>
                                <Descriptions.Item label="Message for customer">
                                    {item?.customer_note}
                                </Descriptions.Item>
                                <Descriptions.Item label="Note from admin">
                                    {item?.admin_note}
                                </Descriptions.Item>
                                <Descriptions.Item label="Customer action">
                                    {
                                        customerAction?.action && <Tag color="processing">{customerAction?.action}</Tag>
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label="Customer action at">
                                    {customerAction?.action_at}
                                </Descriptions.Item>
                            </Descriptions>

                            <Table
                                dataSource={itemsWithMeta}
                                bordered={false}
                                pagination={false}
                                size="small"
                                rowKey="id"
                            >
                                <Column
                                    title="#SL"
                                    dataIndex="id"
                                    key="SL"
                                    align={"center"}
                                    render={(key, record, index) => (
                                        index + 1
                                    )}
                                />

                                <Column
                                    title="Changed Price"
                                    dataIndex="price"
                                    key="price"
                                    align={"center"}
                                    render={(price, record) => (
                                        record && record.price
                                    )}
                                />
                                <Column
                                    title="Meta"
                                    dataIndex="meta"
                                    key="meta"
                                    align={"center"}
                                    render={(meta, record) => {
                                        const metaText = meta.reduce(((acc, current, idx) => idx === 0 ? acc + current.title + ': ' + current.value : acc + ' / ' + current.title + ':' + current.value), '')
                                        return metaText
                                    }}
                                />
                            </Table>
                        </div>
                    )
                })
            }
        </div>
    )

}

export default IssueProductList;
