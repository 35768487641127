import { PageHeader } from "@ant-design/pro-layout";
import { Button, Col, Result, Row, Skeleton } from "antd";
import { useAtom } from "jotai";
import { walletOverviewCollectionAtom } from "lib/core-react/store/store";
import { useEffect, useState } from "react";
import { useAgentWalletOverviewBalance } from "lib/core-react/hooks/private/useWallet";
import { WalletOverviewModel } from "models/walletOverviewCollectionModel";
import { useNavigate, useParams } from "react-router-dom";
import WalletCard from "../WalletCard";
import WalletTable from "./components/WalletTable";

const WalletOverviewForAgentCompany = () => {
  const { getAgentWalletOverviewBalance, isError, isLoading } =
    useAgentWalletOverviewBalance();
  const { id } = useParams();
  const navigate = useNavigate();

  const [{ data: walletOverviewData, refetch }] = useAtom(
    walletOverviewCollectionAtom,
  );

  const [walletId, setWalletId] = useState<number[]>([]);

  useEffect(() => {
    if (id) {
      getAgentWalletOverviewBalance(Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const walletOverviewCollectionData =
    walletOverviewData && new WalletOverviewModel(walletOverviewData);

  const currencyData = walletOverviewCollectionData?.getByCurrency();

  const currencies = Object.entries(currencyData || {}).map(
    ([currencyCode, data]) => ({
      code: currencyCode,
      totalAvailableBalance: data.totalAvailableBalance,
      totalReservedBalance: data.totalReservedBalance,
      totalPendingBalance: data.totalPendingBalance,
      symbol: data.symbol,
      walletIds: data.walletIds,
    }),
  );

  const handleWalletBalanceClick = (walletIds) => {
    setWalletId(walletIds);
  };

  useEffect(() => {
    if (currencies && currencies[0]?.walletIds) {
      setWalletId(currencies[0]?.walletIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletOverviewData]);

  if (!isLoading && isError) {
    return (
      <>
        <Result
          status="404"
          title="404"
          subTitle={isError}
          extra={
            <Button
              onClick={() => navigate("/agent-manage/agent-companies")}
              type="primary"
            >
              Back Home
            </Button>
          }
        />
      </>
    );
  }

  return (
    <PageHeader
      ghost={false}
      title="Agent Wallet"
      style={{ marginTop: "10px" }}
      onBack={() => window.history.back()}
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Row>
          <WalletCard
            walletId={walletId}
            handleWalletBalanceClick={handleWalletBalanceClick}
            item={currencies}
          />
        </Row>
      )}
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Row>
          <Col span={24}>
            <WalletTable isLoading={false} walletId={walletId} />
          </Col>
        </Row>
      )}
    </PageHeader>
  );
};

export default WalletOverviewForAgentCompany;
