import React from 'react'
import { Link } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { ReactComponent as Logo } from 'assets/logo.svg'
import menuItems from 'consts/menu-items'
import styles from './Sidebar.module.css'
import {Authorized} from "../../../../utils/helper";
import { MOVEON_ADMIN_USER_KEY, SUPER_ADMIN } from 'consts/storage'

const { SubMenu } = Menu
const { Sider } = Layout

const Sidebar = (props) => {
    const { isMenuCollapsed, handleCollapsed } = props
    const user = JSON.parse(window.localStorage.getItem(MOVEON_ADMIN_USER_KEY))
    const roles = user?.roles ? user?.roles : []
    let permissionItems = []
    if (user && user?.roles) {
        for (let i = 0; i < user?.roles?.length; i++) {
            for (let j = 0; j < user?.roles[i].permissions.length; j++) {
                permissionItems.push(user?.roles[i].permissions[j].name)
            }
        }
    }

    const isSuperAdmin = roles.find(role => role.name === SUPER_ADMIN)


    return (
        <Sider
            breakpoint="lg"
            collapsedWidth="0"
            collapsible
            collapsed={isMenuCollapsed}
            onCollapse={handleCollapsed}
            trigger={null}
            width={250}
            className={`${styles.siderMenu}`}
            style={{backgroundColor: "#fff"}}
        >
            <div className="logo" style={{padding: "16px"}}>
                <Link to={"/"}>
                    <Logo />
                </Link>
            </div>
            <Menu mode="inline" style={{fontWeight: "700"}}>
                {
                    menuItems && menuItems.filter(item => {
                        if (!item.requirePermission) {
                            return true
                        }
                        if (isSuperAdmin ||  Authorized.checkActionPermission(item.permission, item, null)) {
                            return true
                        } else {
                            return false
                        }
                    }).map((menuItem, idx) => {
                        if (menuItem.hasSubMenu) {
                            return (
                                <SubMenu key={`submenu-${idx}`} icon={menuItem.icon} title={menuItem.title} >
                                    {
                                        menuItem.subMenus && menuItem.subMenus.filter(item => {
                                            if (!item.requirePermission) {
                                                return true
                                            }
                                            if (isSuperAdmin || Authorized.checkActionPermission(item.permission, item, null)) {
                                                return true
                                            } else {
                                                return false
                                            }
                                        }).map((subMenu, subIdx) => {
                                            return <Menu.Item key={`sub-${idx}-${subIdx}`}>
                                                <Link to={subMenu.path} >{subMenu.title}</Link>
                                            </Menu.Item>
                                        })
                                    }
                                </SubMenu>

                            )
                        }
                        return (<Menu.Item key={`menu-${idx}`} icon={menuItem.icon}>
                            {menuItems.title}
                        </Menu.Item>
                        )
                    })
                }
            </Menu>
        </Sider>
    )
}

export default Sidebar
