import { Dispatch, SetStateAction, useEffect } from "react";
import { Button, Form, FormInstance, Select, Space } from "antd";

import { TrackerCommentVisibilityEnum } from "enums/shared";

import { RichTextEditor } from "components/RichTextEditor";
import { RoleCollectionModel } from "models/roleCollectionModel";
import { SelectLabelValue } from "./utils";

import { UserSelect } from "./UserSelect";
import { transformEnumToLabeledValue } from "utils/helpers";

interface EditorProps {
  mode: "reply" | "update" | "create";
  form: FormInstance<any>;
  onSubmit: () => void;
  loading: boolean;
  roleCollectionData: RoleCollectionModel;
  roleIds: SelectLabelValue[];
  specificUserIds: SelectLabelValue[];
  setSpecificUserIds: Dispatch<SetStateAction<SelectLabelValue[]>>;
  content: string;
  setContent: Dispatch<SetStateAction<string>>;
  onCancel?: () => void;
}

export const CommentEditor = ({
  mode = "create",
  form,
  onSubmit,
  loading,
  roleCollectionData,
  roleIds,
  specificUserIds,
  setSpecificUserIds,
  content,
  setContent,
  onCancel,
}: EditorProps) => {
  useEffect(() => {
    form.setFieldValue("role_ids", roleIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleIds]);


  return (
    <Form
      form={form}
      initialValues={{
        visibility: "public",
      }}
    >
      <Form.Item>
        <RichTextEditor
          content={mode === "reply" ? "" : content}
          setContent={setContent}
        />
      </Form.Item>
      {mode !== "reply" && (
        <Form.Item>
          <UserSelect
            specificUserIds={specificUserIds}
            setSpecificUserIds={setSpecificUserIds}
            placeholder="Mention specific users"
          />
        </Form.Item>
      )}
      {mode !== "reply" && (
        <Form.Item name="role_ids">
          <Select
            mode="multiple"
            placeholder="Mention users by role"
            options={roleCollectionData.getRolesLabeledValue()}
          />
        </Form.Item>
      )}
      {mode !== "reply" && (
        <Form.Item
          rules={[{ required: true, message: "Visibility is required" }]}
          name="visibility"
        >
          <Select
            options={transformEnumToLabeledValue(TrackerCommentVisibilityEnum)}
          />
        </Form.Item>
      )}
      <Form.Item>
        <Space>
          <Button
            htmlType="submit"
            loading={loading}
            onClick={onSubmit}
            type="primary"
          >
            {mode === "create"
              ? "Create"
              : mode === "reply"
                ? "Reply"
                : mode === "update"
                  ? "Update"
                  : ""}
          </Button>
          {(mode === "update" || mode === "reply") && onCancel && (
            <Button danger onClick={onCancel}>
              Cancel
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};
