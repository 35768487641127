import React from 'react'
import {
    Row,
    Col,
    Descriptions, Table, Typography, Divider,
} from 'antd'
import {StatusTag, DateTime} from "components"
// import { EyeFilled } from "@ant-design/icons"

const { Column } = Table;
const { Title } = Typography;

const OrderInvoices = props => {
    const {data} = props

    const buyInvoices = data?.buyInvoice ? data.buyInvoice : null
    const shippingInvoices = data?.invoices ? data.invoices : null
    const buyInvoicesAddress = data?.addresses ? data.addresses : null
    const buyProducts = data?.buyProducts ? data.buyProducts : null

    return (
        <>
            <Title level={4} style={{color: '#2db7f5'}}>Invoice Info</Title>
            <Divider/>
            <Row>
                <Col>
                    {
                        buyInvoices && buyInvoices.map(invoice => {

                            return (
                                <Descriptions
                                    title="Buy Invoice:"
                                    column={{md: 4, sm: 2, xs: 1}}
                                    key={invoice.id}
                                >
                                    <Descriptions.Item label="Invoice Number">{invoice.code}</Descriptions.Item>
                                    <Descriptions.Item label="Total Amount">{invoice.total.toFixed(2)}</Descriptions.Item>
                                    <Descriptions.Item label="Paid Amount">{invoice.paid_amount}</Descriptions.Item>
                                    <Descriptions.Item label="Due Amount">{(invoice.total - invoice.paid_amount).toFixed(2)}</Descriptions.Item>
                                    <Descriptions.Item label="Invoice To">{invoice.invoice_to}</Descriptions.Item>
                                    <Descriptions.Item label="Status">
                                        <StatusTag text={invoice.status_label} slug={invoice.status}/>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Paid Time">
                                        <DateTime date={invoice.paid_at}/>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Settled Time">
                                        <DateTime date={invoice.settled_at}/>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Creation Time">
                                        <DateTime date={invoice.created_at}/>
                                    </Descriptions.Item>
                                </Descriptions>
                            )
                        })
                    }
                    {
                        buyInvoicesAddress.billing &&
                        <Descriptions
                            title="Billing IAddress:"
                            column={{md: 4, sm: 2, xs: 1}}
                        >
                            <Descriptions.Item label="Name">{buyInvoicesAddress.billing.name}</Descriptions.Item>
                            <Descriptions.Item label="Phone">{buyInvoicesAddress.billing.phone}</Descriptions.Item>
                            <Descriptions.Item label="Country">{buyInvoicesAddress.billing.country}</Descriptions.Item>
                            <Descriptions.Item label="IAddress">
                                {buyInvoicesAddress.billing.address}, {buyInvoicesAddress.billing.area.name}, {buyInvoicesAddress.billing.district.name}, {buyInvoicesAddress.billing.postal_code}
                            </Descriptions.Item>
                        </Descriptions>
                    }

                    {
                        buyInvoicesAddress.shipping &&
                        <Descriptions
                            title="Shipping IAddress:"
                            column={{md: 4, sm: 2, xs: 1}}
                        >
                            <Descriptions.Item label="Name">{buyInvoicesAddress.shipping.name}</Descriptions.Item>
                            <Descriptions.Item label="Phone">{buyInvoicesAddress.shipping.phone}</Descriptions.Item>
                            <Descriptions.Item label="Country">{buyInvoicesAddress.shipping.country}</Descriptions.Item>
                            <Descriptions.Item label="IAddress">
                                {buyInvoicesAddress.shipping.address}, {buyInvoicesAddress.shipping.area.name}, {buyInvoicesAddress.shipping.district.name}, {buyInvoicesAddress.shipping.postal_code}
                            </Descriptions.Item>
                        </Descriptions>
                    }
                    {
                        buyProducts?.charges &&
                        <Table
                            dataSource={buyProducts?.charges}
                            bordered={false}
                            pagination={false}
                            style={{marginTop: '2%'}}
                        >
                            <Column
                                title="#SL"
                                dataIndex="id"
                                key="SL"
                                align={"center"}
                                render={(key, record, index) => (
                                    index + 1
                                )}
                            />
                            <Column
                                title="Quantity"
                                dataIndex="qty"
                                key="qty"
                                align={"center"}
                                render={(qty, record) => (
                                    record && record.qty
                                )}
                            />
                        </Table>
                    }

                    {
                        buyProducts.map((product, index) => {
                            return (
                                <div key={index + "item_meta"} style={{textAlign: "center", marginTop: '2%'}}>
                                    <span style={{fontWeight: "bold"}}>Product {product.product_number}</span>
                                    <Table
                                        dataSource={product.itemMetas}
                                        bordered={false}
                                        pagination={false}
                                        style={{marginTop: '2%'}}
                                    >
                                        <Column
                                            title="#SL"
                                            dataIndex="id"
                                            key="SL"
                                            align={"center"}
                                            render={(key, record, index) => (
                                                index + 1
                                            )}
                                        />
                                        <Column
                                            title="Meta"
                                            dataIndex="itemMetas"
                                            key="meta"
                                            align={"center"}
                                            render={(meta, record) => {
                                                return (
                                                    record.meta.map((itemVariation, itemVariationID) => {
                                                        return (
                                                            <div className="warehouse-address ml-lg-4" key={`varItem-${itemVariationID}`}>
                                                                <div>
                                                                    {itemVariation.title}: <span className="font-weight-bold" style={{ color: "black" }}>{itemVariation.value}</span>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                )
                                            }}
                                        />
                                        <Column
                                            title="Quantity"
                                            dataIndex="qty"
                                            key="qty"
                                            align={"center"}
                                            render={(qty, record) => (
                                                record && record.qty
                                            )}
                                        />
                                        <Column
                                            title="Price"
                                            dataIndex="price"
                                            key="price"
                                            align={"center"}
                                            render={(price, record) => (
                                                ( record?.qty * record?.price)
                                            )}
                                        />
                                        <Column
                                            title="Amount"
                                            dataIndex="qty"
                                            key="qty"
                                            align={"center"}
                                            render={(qty, record) => (
                                                ( record.qty * record.productPrice)
                                            )}
                                        />
                                    </Table>

                                    {
                                        product?.charges.length !== 0 &&
                                        <Table
                                            dataSource={product?.charges}
                                            bordered={false}
                                            pagination={false}
                                            style={{marginTop: '2%'}}
                                        >
                                            <Column
                                                dataIndex="id"
                                                key="SL"
                                                align={"center"}
                                                render={(key, record, index) => (
                                                    index + 1
                                                )}
                                            />
                                            <Column
                                                dataIndex="type_label"
                                                key="type_label"
                                                align={"center"}
                                                render={(key, record) => {
                                                    return (
                                                        <span>Type : {record.type_label}</span>
                                                    )
                                                }}
                                            />
                                            <Column
                                                dataIndex="amount"
                                                key="amount"
                                                align={"center"}
                                                render={(key, record) => (
                                                    record.amount
                                                )}
                                            />
                                        </Table>
                                    }
                                </div>
                            )
                        })

                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    {
                        shippingInvoices && shippingInvoices.map(invoice => {

                            return (
                                <Descriptions
                                    title="Shipping Invoice:"
                                    column={{md: 4, sm: 2, xs: 1}}
                                    key={invoice.id}
                                >
                                    <Descriptions.Item label="Invoice Total">{invoice.amount_total}</Descriptions.Item>
                                    <Descriptions.Item label="Status"><StatusTag text={invoice.status.name}
                                                                                 slug={invoice.status.slug}/></Descriptions.Item>
                                    <Descriptions.Item label="Settled at"><DateTime
                                        date={invoice.settled_time}/></Descriptions.Item>
                                </Descriptions>
                            )
                        })
                    }
                </Col>
            </Row>
        </>
    )
}

export default OrderInvoices
