import { useEffect } from "react";
import { useAtom } from "jotai";
import { Button, Space, Table, Tag, Typography } from "antd";

import { useGetAgentCompanyMembers } from "lib/core-react/hooks/private/useAgentCompany";
import { memberCollectionAtom } from "lib/core-react/store/store";

import useDataFilters from "hooks/useDataFilters";

import { AgentCompanyModel } from "models/agentCompanyCollectionModel";
import { ApiHelperModel } from "models/apiHelper";
import {
  MemberCollectionModel,
  MemberModel,
} from "models/memberCollectionModel";
import { PaginationModel } from "models/pagination";

import DateTimeFormatter from "components/DateTime/DateTimeFormatter";
import { DownOutlined, HddFilled } from "@ant-design/icons";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";

interface IProps {
  data: AgentCompanyModel;
}

export const MembersTable = ({ data }: IProps) => {
  const { getAgentCompanyMembers } = useGetAgentCompanyMembers(data.id);
  const [{ data: memberCollectionData, isLoading, refetch }] =
    useAtom(memberCollectionAtom);
  const { filters, handleFilterChange, isFirstCall, isFetched } =
    useDataFilters();

  const { isMobile } = useWindowWidth();

  // Api Call
  useEffect(() => {
    if ((filters && !isFetched && isFirstCall) || refetch) {
      ApiHelperModel.makeGetRequest({}, getAgentCompanyMembers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filters]);

  const MemberCollectionData =
    memberCollectionData && new MemberCollectionModel(memberCollectionData);

  // Pagination
  const paginationData = MemberCollectionData?.pagination;

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getAgentCompanyMembers,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    MemberCollectionData,
    handlePaginationChange,
  );

  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "id",
      align: "center" as const,
      width: 70,
      render: (_: string, __: MemberModel, index: number) => {
        return (
          <>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </>
        );
      },
    },
    {
      title: "User info",
      dataIndex: "user",
      key: "user",
      hidden: isMobile,
      render: (_: string, record: MemberModel) => {
        return (
          <Space direction="vertical">
            <Space>
              <Typography.Text strong>Name: </Typography.Text>
              <Typography.Text>{record.getUser().getName()}</Typography.Text>
            </Space>
            <Space>
              <Typography.Text strong>Phone: </Typography.Text>
              <Typography.Text>{record.getUser().getPhone()}</Typography.Text>
            </Space>
            <Space></Space>
            <Space>
              <Typography.Text strong>Email: </Typography.Text>
              <Typography.Text>{record.getUser().getEmail()}</Typography.Text>
            </Space>
            <Space>
              <Typography.Text strong>Shipping mark: </Typography.Text>{" "}
              <Typography.Text>
                {record.getUser().getShippingMark()}
              </Typography.Text>
            </Space>
          </Space>
        );
      },
    },
    {
      title: "User info",
      dataIndex: "user",
      hidden: !isMobile,
      key: "user",
      render: (_: string, record: MemberModel) => {
        return (
          <Space direction="vertical">
            <Space>
              <Typography.Text strong>Name: </Typography.Text>
              <Typography.Text>{record.getUser().getName()}</Typography.Text>
            </Space>
            <Space>
              <Typography.Text strong>Phone: </Typography.Text>
              <Typography.Text>{record.getUser().getPhone()}</Typography.Text>
            </Space>
            <Space></Space>
            <Space>
              <Typography.Text strong>Email: </Typography.Text>
              <Typography.Text>{record.getUser().getEmail()}</Typography.Text>
            </Space>
            <Space>
              <Typography.Text strong>Shipping mark: </Typography.Text>{" "}
              <Typography.Text>
                {record.getUser().getShippingMark()}
              </Typography.Text>
            </Space>
            <Space>
              {record.getRoles() ? (
                <div
                  style={{
                    textTransform: "capitalize",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 10,
                    width: 200,
                  }}
                >
                  {record.getRoles().map((role) => (
                    <Tag key={role.id} color="green">
                      {role.name}
                    </Tag>
                  ))}
                </div>
              ) : (
                ""
              )}
              ;
            </Space>
            <>
              {record.getStatus() ? (
                <Tag style={{ textTransform: "capitalize" }} color="green">
                  {record.getStatus()}
                </Tag>
              ) : (
                ""
              )}
            </>
            <>
              {record.getMemberSince() ? (
                <DateTimeFormatter date={record.getMemberSince()} />
              ) : (
                ""
              )}
            </>
          </Space>
        );
      },
    },
    {
      title: "Roles",
      dataIndex: "roles",
      hidden: isMobile,
      key: "roles",
      render: (_: string, record: MemberModel) => {
        return record.getRoles() ? (
          <div
            style={{
              textTransform: "capitalize",
              display: "flex",
              flexWrap: "wrap",
              gap: 10,
              width: 200,
            }}
          >
            {record.getRoles().map((role) => (
              <Tag key={role.id} color="green">
                {role.name}
              </Tag>
            ))}
          </div>
        ) : (
          ""
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center" as const,
      hidden: isMobile,
      key: "status",
      render: (_: string, record: MemberModel) => {
        return record.getStatus() ? (
          <Tag style={{ textTransform: "capitalize" }} color="green">
            {record.getStatus()}
          </Tag>
        ) : (
          ""
        );
      },
    },
    {
      title: "Member since",
      dataIndex: "user",
      align: "center" as const,
      key: "user",
      hidden: isMobile,
      render: (_: string, record: MemberModel) => {
        return record.getMemberSince() ? (
          <DateTimeFormatter date={record.getMemberSince()} />
        ) : (
          ""
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      fixed: isMobile ? "right" : undefined,
      render: (_: string, __: MemberModel) => {
        return (
          <Button icon={<HddFilled />}>
            {isMobile ? (
              <DownOutlined />
            ) : (
              <>
                Actions <DownOutlined />{" "}
              </>
            )}
          </Button>
        );
      },
    },
  ];

  return (
    <Table
      //@ts-ignore
      columns={columns}
      size="small"
      bordered
      loading={isLoading}
      dataSource={MemberCollectionData?.data}
      pagination={paginationConfig}
      rowKey="id"
    />
  );
};
