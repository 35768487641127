import {Button, Card, Col, Form, Image, Input, Row, Typography} from "antd"
import React, {useRef, useState} from "react"
import {PrinterOutlined} from "@ant-design/icons"
import ReactToPrint from 'react-to-print'
import JsBarcode from 'jsbarcode'
import "./LabelPrint.css"

import mainLogo from 'assets/label/label.svg';


class ComponentToPrint extends React.PureComponent {

    componentDidMount() {
        JsBarcode("#barcode", this.props.product.product_number, {
            width: 1,
            height: 40,
            fontSize: 18,
            fontOptions: "bold"
        })
        if (this.props.product.abroad_courier_tracking != null){
            JsBarcode("#barcodeTracking", this.props.product.abroad_courier_tracking, {
                width: 1,
                height: 40,
                fontSize: 18,
                fontOptions: "bold"
            })
        }
    }

    render() {
        const { Title } = Typography;
        return (
            <>
                <Row>
                    <Col span={24}>
                        <Row>
                            <Col span={24}>
                                <Card size="small" title={<Title style={{fontSize: '8px'}}>Product Number</Title>} headStyle={{ minHeight: 0, borderBottom: '1px solid #000000'}} style={{ width: 360, height: 125, marginLeft: '2%', border: '1px solid #000000' }}>
                                    <Col span={24}>
                                        <svg id="barcode"></svg>
                                    </Col>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24} style={{marginLeft: '2%'}}>
                        <Row>
                            <Col span={24}>
                                <Card size="small" title={<Title style={{fontSize: '8px'}}>Tracking Number</Title>} headStyle={{ minHeight: 0, borderBottom: '1px solid #000000'}} style={{ width: 360, height: 125, border: '1px solid #000000' }}>
                                    <Col span={24}>
                                        {
                                            this.props.product.abroad_courier_tracking === null ? null
                                                :
                                                <svg id="barcodeTracking"></svg>
                                        }
                                    </Col>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Card size="small" title={<Title style={{fontSize: '8px'}}>CBM & Carton</Title>} headStyle={{ minHeight: 0, borderBottom: '1px solid #000000'}}  style={{ width: 185, height: 80, border: '1px solid #000000' }}>
                                    <strong style={{fontSize: '8px'}}>CBM: {this.props.product.cbm}</strong><br/>
                                    <strong style={{fontSize: '8px'}}>Carton: {this.props.product.carton}</strong><br/>
                                    <strong style={{fontSize: '8px'}}>Shipping Mark: {this.props.product.shipping_mark}</strong>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card size="small" title={<Title style={{fontSize: '8px'}}>Label Text</Title>} headStyle={{ minHeight: 0, borderBottom: '1px solid #000000'}}  style={{ width: 168, height: 80, border: '1px solid #000000' }}>
                                    <strong style={{fontSize: '14px'}}>{this.props.product.label_text}</strong>
                                </Card>
                            </Col>
                            <Col span={24}>
                                <div style={{textAlign: 'center'}}>
                                    <Image
                                        width={40}
                                        src={mainLogo}
                                    /> <br/>
                                    <small style={{fontSize: '8px'}}>Handle With Care</small>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </>
        );
    }
}

const LabelPrint = Props => {
    const {product} = Props
    const [copy, setCopy] = useState(1)
    const componentRef = useRef()

    const handleCopy = (e) => {
        setCopy(e.target.value)
    }

    return (
        <>
            <Form
                layout={'inline'}
                initialValues={{
                    copy: product.carton,
                }}
            >
                <Row gutter={[16, 16]}>
                    <Col span={16}>
                        <Form.Item
                            label="Number of copies"
                            name="copy"
                        >
                            <Input
                                placeholder="Enter number of copies"
                                style={{width: "100%"}}
                                onChange={handleCopy}
                                autoFocus
                            />

                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <ReactToPrint
                            trigger={() => <Button type="primary" icon={<PrinterOutlined/>}
                                                   htmlType="submit">Print</Button>}
                            content={() => componentRef.current}
                        />
                    </Col>
                </Row>
                <div style={{display: 'none'}}>
                    <Form.Item>
                        <ComponentToPrint ref={componentRef} product={product} copy={copy} />
                    </Form.Item>
                </div>
            </Form>
        </>
    );
}
export default LabelPrint;
