/* eslint-disable react-hooks/exhaustive-deps */
import { DownOutlined, EditOutlined, HddFilled } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  MenuProps,
  Modal,
  Row,
  Table,
  Tag,
  notification,
} from "antd";
import FiltersComponent from "components/FiltersComponent";
import { RegionDataStatus } from "enums/regionCollectionEnums";
import useDataFilters from "hooks/useDataFilters";
import { useAtom } from "jotai";
import {
  useCreateRegion,
  useGetRegion,
  useUpdateRegion,
} from "lib/core-react/hooks/private/useRegion";
import { regionRegion } from "lib/core-react/store/store";
import { PaginationModel } from "models/pagination";
import { RegionDataModel, RegionModel } from "models/region";
import { useState, useEffect } from "react";
import { IFilterType } from "types/filters";
import { ApiHelperModel } from "models/apiHelper";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import { formatDateTime } from "../../../utils/helpers";
import { MenuInfo } from "rc-menu/lib/interface";
import RegionModifications from "./components/RegionModifications";
const { Column } = Table;


const Region = () => {
  const { getRegion } = useGetRegion();
  const { updateRegion, isLoading: updateLoading } = useUpdateRegion();
  const { createRegion, isLoading: createLoading } = useCreateRegion();
  const { isMobile } = useWindowWidth();
  const [{ data: regionStoreData, isLoading, refetch }] = useAtom(regionRegion);
  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
    refetch: refetchFromFilter
  } = useDataFilters();

  const [form] = Form.useForm();

  // Api Call
  useEffect(() => {
    if ((filters && !isFetched && isFirstCall) || refetch || refetchFromFilter) {
      filters && ApiHelperModel.makeGetRequest(filters, getRegion);
    }
  }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

  // Region
  const RegionData = regionStoreData && new RegionModel(regionStoreData);

  // Filter
  let filterData = RegionData?.filters;
  // Getting all available filters
  useEffect(() => {
    if (!isFetched && RegionData?.filters) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [isFetched, initializeAvailableFilter, RegionData?.filters]);

  // Update modal state
  const [isShowCreateUpdateModal, setIsShowCreateUpdateModal] =
    useState<boolean>(false);
  const [seletedRegion, setSeletedRegion] = useState<RegionDataModel>();

  // Handle Modal
  const handleMenuClick = (e: MenuInfo, record: RegionDataModel) => {
    setSeletedRegion(record);

    if (e.key === "update") {
      setIsShowCreateUpdateModal(true);
    }
  };


  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getRegion,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    RegionData,
    handlePaginationChange,
  );

  // Filter Handler
  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getRegion);
  };

  const onFinish = async (value) => {
    console.log(value, "values")
  
    try {
      const moreCountryIds = value.more_country_ids
        ? value.more_country_ids.map((obj) => parseInt(obj.item))
        : [];
      const moreLanguageIds = value.more_language_ids
        ? value.more_language_ids.map((obj) => parseInt(obj.item))
        : [];
  
      const payload = {
        name: value.name,
        code: value.code,
        status: value.status,
        store: value.store,
        country_ids: [...value.country_ids.map(id => parseInt(id)), ...moreCountryIds],
        language_ids: [...value.language_ids.map(id => parseInt(id)), ...moreLanguageIds],
        currency_id: parseInt(value.currency_id),
        store_type: value.store_type,
      };
  
      if (seletedRegion) {
        await updateRegion(seletedRegion?.id, payload);
        notification["success"]({
          message: "Region Update successfully",
        });
      } else {
        await createRegion(payload);
        notification["success"]({
          message: "Region Create successfully",
        });
      }
      setIsShowCreateUpdateModal(false);
    } catch (error: any) {
      notification["error"]({
        message: error.response.data.message,
      });
    }
  };
  

  return (
    <>
      <div>
        <PageHeader
          ghost={false}
          title="Region"
          style={{ marginTop: "10px" }}
          extra={[
            <Button
              key="1"
              type="primary"
              onClick={() => setIsShowCreateUpdateModal(true)}
            >
              Create Region
            </Button>,
          ]}
        >
          <div>
            {filters && Object.keys(filters).length > 0 && (
              <Row>
                <Col span={24}>
                  <Card title="Filter">
                    <FiltersComponent
                      handleProductFilter={handleProductFilter}
                      handleFilterChange={handleFilterChange}
                      handelFilterClear={handelFilterClear}
                      isFetched={isFetched}
                      filters={filters}
                      filtersData={filterData}
                      isFromProductReceived={true}
                    />
                  </Card>
                </Col>
              </Row>
            )}
            <Table
              bordered
              style={{ marginTop: "15px" }}
              loading={isLoading}
              rowKey="id"
              pagination={paginationConfig}
              dataSource={RegionData?.data}
              scroll={{ x: 1000 }}
            >
              <Column width={70} title="ID" dataIndex="id" key="id" />
              <Column title="Code" dataIndex="code" key="code" />
              <Column title="Name" dataIndex="name" key="name" />
              <Column
                title="Currency"
                dataIndex="currency"
                key="currency"
                render={(_, record: RegionDataModel) => (
                  <p>{record.currency && record.currency.name}</p>
                )}
              />
              <Column
                title="Country"
                dataIndex="countries"
                key="countries"
                render={(_, record: RegionDataModel) =>
                  record.countries &&
                  record.countries.data.map((item, index, arr) => (
                    <span key={index}>
                      {item.name}
                      {index + 1 === arr.length || ", "}
                    </span>
                  ))
                }
              />
              <Column
                title="Language"
                dataIndex="languages"
                key="languages"
                render={(_, record: RegionDataModel) =>
                  record.languages &&
                  record.languages.data.map((item, index, arr) => (
                    <span key={index}>
                      {item.name}
                      {index + 1 === arr.length || ", "}
                    </span>
                  ))
                }
              />
              <Column title="Store" dataIndex="store" key="store" />
              <Column
                title="Store Type"
                dataIndex="store_type"
                key="store_type"
              />
              <Column
                title="Status"
                dataIndex="status"
                key="status"
                render={(_, record: RegionDataModel) => (
                  <Tag
                    color={
                      record.status === RegionDataStatus.active
                        ? "green"
                        : "red"
                    }
                    key={record.status}
                  >
                    {record.status}
                  </Tag>
                )}
              />

              <Column
                title="Created at"
                dataIndex="created_at"
                hidden={isMobile}
                key="created_at"
                render={(created_at) => {
                  return (
                    <Tag color={"purple"}>{formatDateTime(created_at)}</Tag>
                  );
                }}
              />
              <Column
                title="Action"
                fixed={isMobile ? "right" : undefined}
                width={isMobile ? 100 : 150}
                key="action"
                render={(_, record: RegionDataModel) => {
                  const menuItems: MenuProps["items"] = [
                    {
                      label: "Update",
                      key: "update",
                      icon: <EditOutlined />,
                      onClick: (e) => handleMenuClick(e, record),
                    },
                  ];
                  if (isMobile) {
                    menuItems.push({
                      key: "2",
                      disabled: true,
                      label: (
                        <Tag color={"purple"}>
                          {formatDateTime(record.created_at)}
                        </Tag>
                      ),
                    });
                  }

                  return (
                    <Dropdown
                      menu={{
                        items: menuItems,
                      }}
                    >
                      <Button icon={<HddFilled />}>
                        {isMobile ? (
                          <DownOutlined />
                        ) : (
                          <>
                            {" "}
                            Actions <DownOutlined />
                          </>
                        )}
                      </Button>
                    </Dropdown>
                  );
                }}
              />
            </Table>
          </div>
        </PageHeader>
      </div>

      <Modal
        title={`${seletedRegion ? "Update" : "Create"} Region`}
        open={isShowCreateUpdateModal}
        okText={seletedRegion ? "Update" : "Create"}
        onCancel={() => {
          setIsShowCreateUpdateModal(false);
          setSeletedRegion(undefined);
        }}
        okButtonProps={{ loading: createLoading || updateLoading }}
        onOk={form.submit}
        centered
      >
        <RegionModifications
          data={seletedRegion}
          form={form}
          onFinish={onFinish}
        />
      </Modal>
    </>
  );
};

export default Region;
