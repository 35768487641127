/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import {
  Button,
  Dropdown,
  MenuProps,
  Modal,
  Space,
  Table,
  Typography,
} from "antd";

import { exchangeGroupCustomerUserCollectionAtom } from "lib/core-react/store/store";

import useDataFilters from "hooks/useDataFilters";

import { ApiHelperModel } from "models/apiHelper";
import { PaginationModel } from "models/pagination";

import {
  DeleteOutlined,
  DownOutlined,
  HddFilled,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { useGetExchangeGroupCustomers } from "lib/core-react/hooks/private/useExchangeGroup";
import { ExchangeGroupModel } from "models/exchangeGroupCollectionModel";
import {
  CustomerUserCollectionModel,
  CustomerUserModel,
} from "models/customerUserCollectionModel";
import UpdateExchangeGroupUser from "./UpdateExchangeGroupCustomer";
import DeleteExchangeGroupCustomer from "./DeleteExchangeGroupCustomer";
import useWindowWidth from "../../../../lib/core-react/hooks/public/useWindowWidth";

interface IProps {
  data: ExchangeGroupModel;
}
const ExchanegGroupCustomersTable = ({ data }: IProps) => {
  const { getExchangeGroupCustomers } = useGetExchangeGroupCustomers(data.id);
  const [{ data: customerCollectionData, isLoading, refetch }] = useAtom(
    exchangeGroupCustomerUserCollectionAtom,
  );
  const { filters, handleFilterChange, isFirstCall, isFetched } =
    useDataFilters();

  const [isShowUpdateModal, setIsShowUpdateModal] = useState<boolean>(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const {isMobile} = useWindowWidth()
  const handleMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key === "update") {
      setIsShowUpdateModal(true);
    } else if (e.key === "delete") {
      setIsShowDeleteModal(true);
    }
  };

  // Api Call
  useEffect(() => {
    if ((filters && !isFetched && isFirstCall) || refetch) {
      ApiHelperModel.makeGetRequest({}, getExchangeGroupCustomers);
    }
  }, [data, filters]);

  const CustomerCollectionData =
    customerCollectionData &&
    new CustomerUserCollectionModel(customerCollectionData);

  // Pagination
  const paginationData = CustomerCollectionData?.getPagination();

  const [selectedExchangeGroupCustomer, setSelectedExchangeGroupCustomer] =
    useState<CustomerUserModel | undefined>(undefined);

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getExchangeGroupCustomers,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    CustomerCollectionData,
    handlePaginationChange,
  );

  const columns = [
    {
      title: "#SL",
      width:60,
      dataIndex: "id",
      key: "id",
      align: "center" as const,
      render: (_: string, __: CustomerUserModel, index: number) => {
        return (
          <>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </>
        );
      },
    },
    {
      title: "User info",
      dataIndex: "user",
      key: "user",
      render: (_: string, record: CustomerUserModel) => {
        return (
          <Space direction="vertical">
            <Space>
              <Typography.Text strong>Name: </Typography.Text>
              <Typography.Text>{record.getName()}</Typography.Text>
            </Space>
            <Space>
              <Typography.Text strong>Phone: </Typography.Text>
              <Typography.Text>{record.getPhone()}</Typography.Text>
            </Space>
            <Space></Space>
            <Space>
              <Typography.Text strong>Email: </Typography.Text>
              <Typography.Text>{record.getEmail()}</Typography.Text>
            </Space>
            <Space>
              <Typography.Text strong>Shipping mark: </Typography.Text>{" "}
              <Typography.Text>{record.getShippingMark()}</Typography.Text>
            </Space>
          </Space>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      fixed:isMobile?"right":undefined,
      width: isMobile ? 90: 150,
      render: (_: string, record: CustomerUserModel) => {
        const items: MenuProps["items"] = [
          {
            icon: <UsergroupAddOutlined />,
            label: "Update Customer",
            key: "update",
          },
          { icon: <DeleteOutlined />, label: "Delete Customer", key: "delete" },
        ];

        const menuProps = {
          items,
          onClick: handleMenuClick,
        };

        return (
          <Dropdown
            menu={menuProps}
            onOpenChange={() => setSelectedExchangeGroupCustomer(record)}
          >
            <Button icon={<HddFilled />}>
              {isMobile ?  <DownOutlined /> : <>  Actions <DownOutlined /> </> }
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <Table
        //@ts-ignore
        columns={columns}
        size="small"
        bordered
        loading={isLoading}
        dataSource={CustomerCollectionData?.getData()}
        pagination={paginationConfig}
        rowKey="id"
        scroll={{x:350}}
      />

      {selectedExchangeGroupCustomer && (
        <Modal
          title={`Update Exchange Group Customer`}
          open={isShowUpdateModal}
          footer={false}
          onCancel={() => {
            setIsShowUpdateModal(false);
          }}
        >
          <UpdateExchangeGroupUser
            setIsShowUpdateModal={setIsShowUpdateModal}
            selectedCustomerUser={selectedExchangeGroupCustomer}
            data={data}
          />
        </Modal>
      )}

      {selectedExchangeGroupCustomer && (
        <Modal
          title="Delete Exchange Group Customer"
          open={isShowDeleteModal}
          onCancel={() => setIsShowDeleteModal(false)}
          footer={null}
        >
          <DeleteExchangeGroupCustomer
            setIsShowDeleteModal={setIsShowDeleteModal}
            selectedCustomerUser={selectedExchangeGroupCustomer}
            data={data}
          />
        </Modal>
      )}
    </>
  );
};

export default ExchanegGroupCustomersTable;
