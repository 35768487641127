import React, {useEffect, useState} from 'react'
import {Button, Col, Descriptions, Modal, Row, Spin, Table, Tooltip} from 'antd'
import DateTime from "components/DateTime"
import {getBuyShipInvoiceDetails} from "utils/buy-invoice-client.js";
import {BuyShipProductDetails, StatusTag} from "components";
import {EyeFilled} from "@ant-design/icons";
import InvoiceTransctions from "pages/BuyingAgentInvoice/components/InvoiceTransctions";

const {Column} = Table;

const InvoiceDetail = Props => {
    const {invoiceId} = Props
    const [invoiceData, setInvoiceData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [showProductDetails, setShowProductDetails] = useState(false)

    const fetchItem = React.useCallback(
        () => {
            setIsLoading(true)
            getBuyShipInvoiceDetails(invoiceId)
                .then(res => {
                    setInvoiceData(res.data)
                    setIsLoading(false)
                })
                .catch(err => {
                    setIsLoading(false)
                    alert('Error loading data.')
                })
        },
        [invoiceId],
    )

    useEffect(() => {
        fetchItem()
    }, [fetchItem])

    const handleShowProductDetail = (product) => {
        setSelectedProduct(product)
        setShowProductDetails(true)
    }


    const customer = invoiceData && invoiceData.invoice && invoiceData.invoice.order && invoiceData.invoice.order.customer ? invoiceData.invoice.order.customer : null

    const address = invoiceData && invoiceData.invoice && invoiceData.invoice.order && invoiceData.invoice.order.addresses ? invoiceData.invoice.order.addresses : null

    return (<>
        {
            !isLoading && invoiceData ?
                <>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Descriptions
                                title="Invoice"
                            >
                                <Descriptions.Item
                                    label="Invoice Number">{invoiceData.invoice.code}</Descriptions.Item>
                                <Descriptions.Item
                                    label="Order Number">{invoiceData.invoice.order.order_number}</Descriptions.Item>
                                <Descriptions.Item
                                    label="Total Amount">{invoiceData.invoice.total}</Descriptions.Item>
                                {
                                    invoiceData.invoice.refunded_amount !== undefined && invoiceData.invoice.refunded_amount > 0.0 &&
                                    (
                                        <Descriptions.Item
                                            label="Adjustment(refund)">{invoiceData.invoice.refunded_amount}</Descriptions.Item>
                                    )
                                }
                                <Descriptions.Item
                                    label="Invoice To">{invoiceData.invoice.invoice_to}</Descriptions.Item>
                                <Descriptions.Item label="IAddress">{invoiceData.invoice.address}</Descriptions.Item>
                                <Descriptions.Item label="Status">
                                    <StatusTag text={invoiceData.invoice.status_label}
                                               slug={invoiceData.invoice.status}/>
                                </Descriptions.Item>
                                <Descriptions.Item label="Valid To">
                                    <DateTime date={invoiceData.invoice.valid_to}/>
                                </Descriptions.Item>
                                <Descriptions.Item label="Paid Time">
                                    <DateTime date={invoiceData.invoice.paid_at}/>
                                </Descriptions.Item>
                                <Descriptions.Item label="Settled Time">
                                    <DateTime date={invoiceData.invoice.settled_at}/>
                                </Descriptions.Item>
                                <Descriptions.Item label="Created Time">
                                    <DateTime date={invoiceData.invoice.order.created_at}/>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    {
                        invoiceData.invoice.payments.length > 0 &&
                            <InvoiceTransctions invoiceId={invoiceId}/>
                    }
                    <Row gutter={[16, 16]} style={{marginTop: '2%'}}>
                        <Col span={24}>
                            {
                                customer && <Descriptions
                                    title="Customer"
                                >
                                    <Descriptions.Item label="Name">{customer.name}</Descriptions.Item>
                                    <Descriptions.Item label="Phone">{customer.phone}</Descriptions.Item>
                                    <Descriptions.Item label="Email">{customer.email}</Descriptions.Item>
                                    <Descriptions.Item
                                        label="Shipping mark">{customer.shipping_mark}</Descriptions.Item>
                                </Descriptions>
                            }
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            {
                                address?.shipping && <Descriptions
                                    title="Shipping IAddress"
                                >
                                    <Descriptions.Item label="Name">{address.shipping.name}</Descriptions.Item>
                                    <Descriptions.Item label="Phone">{address.shipping.phone}</Descriptions.Item>
                                    <Descriptions.Item label="Country">{address.shipping.country}</Descriptions.Item>
                                    <Descriptions.Item label="IAddress">
                                        {address.shipping.address}, {address.shipping.area.name}, {address.shipping.district.name}, {address.shipping.postal_code}
                                    </Descriptions.Item>
                                </Descriptions>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {
                                invoiceData.invoice.order.buyProducts.map((product, index) => {
                                    return (
                                        <div key={index + "item_meta"}
                                             style={{textAlign: "center", marginTop: '2%'}}>
                                            <span
                                                style={{fontWeight: "bold"}}>Product {product.product_number}</span>
                                            <Table
                                                dataSource={product.itemMetas}
                                                bordered={false}
                                                pagination={false}
                                                style={{marginTop: '2%'}}
                                            >
                                                <Column
                                                    title="#SL"
                                                    dataIndex="id"
                                                    key="SL"
                                                    align={"center"}
                                                    render={(key, record, index) => (
                                                        index + 1
                                                    )}
                                                />
                                                <Column
                                                    title="Meta"
                                                    dataIndex="itemMetas"
                                                    key="meta"
                                                    align={"center"}
                                                    render={(meta, record) => {
                                                        return (
                                                            record.meta.map((itemVariation, itemVariationID) => {
                                                                return (
                                                                    <div className="warehouse-address ml-lg-4"
                                                                         key={`varItem-${itemVariationID}`}>
                                                                        <div>
                                                                            {itemVariation.title}: <span
                                                                            className="font-weight-bold"
                                                                            style={{color: "black"}}>{itemVariation.value}</span>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        )
                                                    }}
                                                />
                                                <Column
                                                    title="Quantity"
                                                    dataIndex="qty"
                                                    key="qty"
                                                    align={"center"}
                                                    render={(qty, record) => (
                                                        record && record.qty
                                                    )}
                                                />
                                                <Column
                                                    title="Price"
                                                    dataIndex="price"
                                                    key="price"
                                                    align={"center"}
                                                    render={(price, record) => (
                                                        record?.price * record?.qty
                                                    )}
                                                />
                                                <Column
                                                    title="Price"
                                                    dataIndex="price"
                                                    key="price"
                                                    align={"center"}
                                                    render={(price, record) => (
                                                        record?.productPrice * record?.qty
                                                    )}
                                                />
                                                <Column
                                                    title="Detail"
                                                    dataIndex="id"
                                                    key="detail"
                                                    width={70}
                                                    align={"center"}
                                                    render={id => (
                                                        <Tooltip title="View Details">
                                                            <Button onClick={() => handleShowProductDetail(product)}
                                                                    type="text" icon={<EyeFilled/>}/>
                                                        </Tooltip>
                                                    )}
                                                />
                                            </Table>
                                            {
                                                // product?.charges.length !== 0 &&
                                                <Table
                                                    dataSource={product?.charges}
                                                    bordered={false}
                                                    pagination={false}
                                                    style={{marginTop: '2%'}}
                                                >
                                                    <Column
                                                        dataIndex="id"
                                                        key="SL"
                                                        align={"center"}
                                                        render={(key, record, index) => (
                                                            index + 1
                                                        )}
                                                    />
                                                    <Column
                                                        dataIndex="type_label"
                                                        key="type_label"
                                                        align={"center"}
                                                        render={(key, record) => {
                                                            return (
                                                                <span>Type : {record.type_label}</span>
                                                            )
                                                        }}
                                                    />
                                                    <Column
                                                        dataIndex="amount"
                                                        key="amount"
                                                        align={"center"}
                                                        render={(key, record) => (
                                                            record.amount
                                                        )}
                                                    />
                                                </Table>
                                            }

                                        </div>
                                    )
                                })

                            }

                            <Modal
                                open={showProductDetails}
                                onCancel={() => setShowProductDetails(false)}
                                width="60vw"
                                footer={null}
                            >
                                {selectedProduct && <BuyShipProductDetails product={selectedProduct}/>}
                            </Modal>
                        </Col>
                    </Row>
                </>
                :
                <Row>
                    <Col span={12} offset={12}>
                        <Spin/>
                    </Col>
                </Row>
        }
    </>);
}

export default InvoiceDetail;
