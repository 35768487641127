export enum ProductSourceEnum {
  WEBSITE_ORDER = "website",
  OWN_SOURCE = "own-source",
}

export enum WeightUnitEnum {
  KG = "kg",
  LB = "lb",
}

export enum ShippingTypeEnum {
  BY_AIR = "air",
  BY_SHIP = "ship",
}

export enum ShippingModeEnum {
  CARGO = "cargo",
  INTERNATIONAL = "international",
  P2P = "p2p",
}

export enum DimensionUnitEnum {
  CM = "cm",
  IN = "in",
}

export enum ContainsEnum {
  BATTERY = "battery",
  LIQUID = "liquid",
}

export enum TrackingTypesEnums {
  "Abroad Courier Tracking" = "abroad-courier-tracking",
  "Global Tracking" = "global-tracking",
}
