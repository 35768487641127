import { DestinationWarehouseModalType } from "types/destinationWarehouseCollection";

function getKeyByValue(value: string): string {
  const key =
    Object.keys(DestinationWarehouseModalType).find(
      (key) =>
        DestinationWarehouseModalType[
          key as keyof typeof DestinationWarehouseModalType
        ] === value
    ) || "";

  return key;
}

export { getKeyByValue };
