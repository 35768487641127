import { ResponsePromise } from "lib/core/request/types/typings";
import BaseResource from "../public/baseResource";
import { IUserCredentials, IUserStatus } from "types/userCollection";

class AdminUserResource extends BaseResource {
  adminUserPath = `/api/user/admin/admin-user/v1`;
  getList(customHeaders: Record<string, any> = {}): ResponsePromise {
    const path = `${this.adminUserPath}/admin-users`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  createAdmin(payload: IUserCredentials): ResponsePromise {
    const path = `${this.adminUserPath}/admin-users/`;
    return this.client.request("POST", path, payload,undefined, {});
  }

  UpdateAdmin(payload: IUserStatus, user_id: number): ResponsePromise {
    const path = `${this.adminUserPath}/admin-users/${user_id}`;
    return this.client.request("PUT", path, payload,undefined, {});
  }
}
export default AdminUserResource;
