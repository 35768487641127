/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { PageHeader } from "@ant-design/pro-layout";
import {
  Button,
  Card,
  Col,
  Dropdown,
  InputNumber,
  MenuProps,
  Modal,
  Popconfirm,
  Row,
  Space,
  Table,
  Tabs,
  Tag,
  Typography,
  message,
} from "antd";
import {
  CheckOutlined,
  DownOutlined,
  HddFilled,
  RestOutlined,
  SendOutlined,
} from "@ant-design/icons";

import { payoutRequestCollectionAtom } from "lib/core-react/store/store";
import useDataFilters from "hooks/useDataFilters";
import { IFilterType } from "types/filters";
import { PaginationModel } from "models/pagination";
import { ApiHelperModel } from "models/apiHelper";
import {
  useApprovePayoutRequest,
  useGetPayoutRequests,
  useUpdatePayoutRequest,
} from "lib/core-react/hooks/private/useWallet";
import {
  PayoutRequestCollectionModel,
  PayoutRequestModel,
} from "models/payoutRequestCollectionModel";
import { formatDateTime } from "utils/helpers";

import FiltersComponent from "components/FiltersComponent";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import { StatusTag } from "components";
import TabPane from "antd/es/tabs/TabPane";

const WithdrawalHistory = () => {
  const { getPayoutRequests } = useGetPayoutRequests();
  const { approvePayoutRequest } = useApprovePayoutRequest();
  const [{ data: payoutRequestCollectionData, isLoading, refetch }] = useAtom(
    payoutRequestCollectionAtom,
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [approvedAmount, setApprovedAmount] = useState<number | undefined>(
    undefined,
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<PayoutRequestModel[]>([]);
  const { isMobile } = useWindowWidth();

  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
  } = useDataFilters();

  // Api Call
  useEffect(() => {
    if ((!isFetched && isFirstCall) || refetch) {
      ApiHelperModel.makeGetRequest({}, getPayoutRequests);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstCall, isFetched, refetch]);

  const PayoutRequestCollectionData =
    payoutRequestCollectionData &&
    new PayoutRequestCollectionModel(payoutRequestCollectionData);

  // Filter
  const filterData = PayoutRequestCollectionData?.getFilters();
  // Pagination
  const paginationData = PayoutRequestCollectionData?.getPagination();

  const [selectedPayoutRequest, setSelectedPayoutRequest] = useState<
    PayoutRequestModel | undefined
  >(undefined);
  const [, setIsShowUpdateModal] = useState(false);
  const [activeTab, setActiveTab] = useState("agent");

  // Getting all available filters
  useEffect(() => {
    if (!isFetched && PayoutRequestCollectionData?.filters) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [
    isFetched,
    initializeAvailableFilter,
    PayoutRequestCollectionData?.getFilters(),
  ]);

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getPayoutRequests,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    PayoutRequestCollectionData,
    handlePaginationChange,
  );

  // Filter Handler
  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getPayoutRequests);
  };

  // Modal
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const { updatePayoutRequest } = useUpdatePayoutRequest();

  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const handleMenuClick = async (key: string) => {
    const actionMap = {
      processing: "processing",
      reject: "reject",
    };

    if (!selectedPayoutRequest) {
      return;
    }

    const currentStatus = selectedPayoutRequest.getStatus();

    if (key === "approve") {
      if (currentStatus === "approved") {
        message.error("This request is already approved.");
        return;
      }
      setIsModalVisible(true);
      return;
    }

    if (key === "reject" && currentStatus === "approved") {
      message.error("Cannot reject an already approved request.");
      return;
    }

    try {
      await updatePayoutRequest(selectedPayoutRequest.getId(), actionMap[key]);
      setIsShowUpdateModal(false);
      message.success(
        `${key.charAt(0).toUpperCase() + key.slice(1)} action successful.`,
      );
      setIsErrorVisible(false);
    } catch (error) {
      setIsErrorVisible(true);
      message.error(
        `${key.charAt(0).toUpperCase() + key.slice(1)} action failed.`,
      );
    }
  };

  const handleApproveConfirm = async () => {
    if (approvedAmount !== undefined && selectedPayoutRequest) {
      const currentStatus = selectedPayoutRequest.getStatus();

      if (currentStatus === "approved") {
        setIsModalVisible(false);
        message.error("This request is already approved.");
        return;
      }

      try {
        await approvePayoutRequest(selectedPayoutRequest.getId(), {
          approved_amount: approvedAmount,
        });
        setIsModalVisible(false);
        message.success("Payout request approved successfully.");
        setIsErrorVisible(false);
      } catch (error) {
        setIsModalVisible(false);
        setIsErrorVisible(true);
        message.error("Approval failed. Please try again.");
      }
    }
  };

  const onSelectChange = (
    newSelectedRowKeys: any,
    newSelectedRows: PayoutRequestModel[],
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const totalRequestAmount = selectedRows.reduce(
    (sum, row) => sum + (row.requested_amount || 0),
    0,
  );
  const totalApprovedAmount = selectedRows.reduce(
    (sum, row) => sum + (row.approved_amount || 0),
    0,
  );

  const { Text } = Typography;

  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "sl",
      align: "center" as const,
      width: 70,
      render: (_: string, __: PayoutRequestModel, index: number) => {
        return (
          <div>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </div>
        );
      },
    },
    {
      title: "Request No",
      dataIndex: "request_no",
      key: "request_no",
      hidden: !isMobile,
      width: 200,
      render: (_: string, record: PayoutRequestModel) => {
        // const adminNote = record.getAdminNote();
        // const customerNote = record.getCustomerNote();
        return (
          <Space direction="vertical" wrap>
            <Space>
              {/* <Text strong>RN:</Text> */}
              <Text copyable>{record.getRequestNumber()}</Text>
            </Space>
            <Space>
              <span style={{ fontWeight: "bold" }}>Status: </span>
              <StatusTag slug={record.getStatus()} text={record.getStatus()} />
            </Space>
          </Space>
        );
      },
    },
    {
      title: "Withdrawal info",
      dataIndex: "name",
      key: "name",
      hidden: isMobile,
      render: (_: string, record: PayoutRequestModel) => {
        const adminNote = record.getAdminNote();
        const customerNote = record.getCustomerNote();
        return (
          <Space direction="vertical" wrap>
            <Space>
              <Text strong>Request Number:</Text>
              <Text copyable>{record.getRequestNumber()}</Text>
            </Space>
            {customerNote && (
              <Space>
                <Text strong>Customer Note:</Text>
                <Text>{record.getCustomerNote()}</Text>
              </Space>
            )}
            {adminNote && (
              <Space>
                <Text strong>Admin note:</Text>
                <Text>{record.getAdminNote()}</Text>
              </Space>
            )}
            <Space>
              <Text strong>Requested At:</Text>
              <Tag color="purple">{formatDateTime(record.getCreatedAt())}</Tag>
            </Space>
          </Space>
        );
      },
    },
    {
      title: "User info",
      dataIndex: "amount",
      key: "amount",
      width: 250,
      render: (_: string, record: PayoutRequestModel) => {
        const customerEmail = record.getCustomer()?.getEmail();
        const agentEmail = record.getAgent()?.getEmail();
        const email = customerEmail || agentEmail;
        const customerType = record.getCustomer()?.getType();
        const color = customerType ? "red" : "cyan";
        return (
          <Space direction="vertical">
            <Space>
              <Text strong>Name:</Text>
              <Text>
                {record.getCustomer()?.getName()
                  ? record.getCustomer()?.getName()
                  : record.getAgent()?.getName()}
              </Text>
            </Space>
            <Space>
              <Text strong>Type:</Text>
              <Tag color={color}>
                {record.getCustomer()?.getType()
                  ? record.getCustomer()?.getType()
                  : "Agent"}
              </Tag>
            </Space>
            {email && (
              <Space>
                <Text strong>Email:</Text>
                <Text>{email ? customerEmail : agentEmail}</Text>
              </Space>
            )}

            <Space>
              <Text strong>Phone:</Text>
              <Text>
                {record.getCustomer()?.getPhone()
                  ? record.getCustomer()?.getPhone()
                  : "N/A"}
              </Text>
            </Space>
          </Space>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      children: [
        {
          title: "Requested",
          dataIndex: "requested",
          key: "requested",
          width: 100,
          render: (_: string, record: PayoutRequestModel) => {
            return (
              <>
                <Text strong>{record.getRequestedAmount()}</Text>
              </>
            );
          },
        },
        {
          title: "Approved",
          dataIndex: "approved ",
          key: "approved",
          width: 100,
          render: (_: string, record: PayoutRequestModel) => {
            return (
              <>
                <Text strong>{record.getApprovedAmount()}</Text>
              </>
            );
          },
        },
      ],
    },
    {
      title: "Account info",
      dataIndex: "payout_account",
      key: "payout_account",
      render: (_: string, record: PayoutRequestModel) => {
        return (
          <Space direction="vertical">
            <Space>
              <Text strong>Gateway name:</Text>
              <Text>
                {record.getPayoutAccount().getPayoutGateway().getName()}
              </Text>
            </Space>
            {record.getPayoutAccount().getAccountData() &&
            Object.keys(record.getPayoutAccount().getAccountData()).length
              ? Object.keys(record.getPayoutAccount().getAccountData()).map(
                  (key) => (
                    <Space key={key}>
                      <Text strong style={{ textTransform: "capitalize" }}>
                        {key.replaceAll("_", " ")}:
                      </Text>{" "}
                      <Text>
                        {record.getPayoutAccount().getAccountData()[key]}
                      </Text>
                    </Space>
                  ),
                )
              : ""}
          </Space>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      hidden: isMobile,
      align: "center" as const,
      render: (_: string, record: PayoutRequestModel) => {
        return (
          <StatusTag slug={record.getStatus()} text={record.getStatus()} />
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      fixed: isMobile ? "right" : undefined,
      align: "center" as const,
      width: 100,
      render: (_: string, record: PayoutRequestModel) => {
        const items: MenuProps["items"] = [
          {
            icon: <SendOutlined />,
            label: (
              <Popconfirm
                title="Are you sure you want to process?"
                onConfirm={() => handleMenuClick("processing")}
                okText="Yes"
                cancelText="No"
              >
                Processing
              </Popconfirm>
            ),
            key: "processing",
          },
          {
            icon: <CheckOutlined />,
            label: "Approve",
            key: "approve",
            onClick: () => handleMenuClick("approve"),
          },
          {
            icon: <RestOutlined />,
            label: (
              <Popconfirm
                title="Are you sure you want to reject?"
                onConfirm={() => handleMenuClick("reject")}
                okText="Yes"
                cancelText="No"
              >
                Reject
              </Popconfirm>
            ),
            key: "reject",
          },
        ];

        if (isMobile) {
          items.push({
            label: (
              <Tag color="purple">{formatDateTime(record.getCreatedAt())}</Tag>
            ),
            key: "created_at",
          });
        }

        return (
          <Dropdown
            menu={{ items }}
            onOpenChange={() => setSelectedPayoutRequest(record)}
          >
            <Button icon={<HddFilled />}>
              {isMobile ? (
                <DownOutlined />
              ) : (
                <>
                  Actions <DownOutlined />{" "}
                </>
              )}
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const customerData = PayoutRequestCollectionData?.getData().filter(
    (item: any) => item.getCustomer(),
  );
  const agentData = PayoutRequestCollectionData?.getData().filter((item: any) =>
    item.getAgent(),
  );

  return (
    <>
      <Row>
        <Col span={24}>
          <PageHeader
            style={{ marginTop: "10px" }}
            ghost={false}
            title="Withdrawal History"
          >
            {filters && Object.keys(filters).length > 0 && (
              <Row>
                <Col span={24}>
                  <Card title="Filter">
                    <FiltersComponent
                      handleProductFilter={handleProductFilter}
                      handleFilterChange={handleFilterChange}
                      handelFilterClear={handelFilterClear}
                      isFetched={isFetched}
                      filters={filters}
                      filtersData={filterData}
                      isFromProductReceived={true}
                    />
                  </Card>
                </Col>
              </Row>
            )}
            <div style={{ marginTop: 12, marginBottom: 12 }}>
              {hasSelected && (
                <>
                  <Tag
                    color="blue"
                    style={{
                      fontWeight: "bold",
                      padding: 3,
                      fontSize: 14,
                      marginTop: 5,
                      marginBottom: 5,
                    }}
                  >
                    Selected {selectedRowKeys.length} items
                  </Tag>
                  <Tag
                    color="green"
                    style={{
                      fontWeight: "bold",
                      padding: 3,
                      fontSize: 14,
                      marginTop: 5,
                      marginBottom: 5,
                    }}
                  >
                    Total Approved Amount: ৳{totalApprovedAmount.toFixed(2)}
                  </Tag>
                  <Tag
                    color="red"
                    style={{
                      fontWeight: "bold",
                      padding: 3,
                      fontSize: 14,
                      marginTop: 5,
                      marginBottom: 5,
                    }}
                  >
                    Total Requested Amount: ৳{totalRequestAmount.toFixed(2)}
                  </Tag>
                </>
              )}
            </div>
            <div>
              <Tabs
                defaultActiveKey="agent"
                onChange={(key) => setActiveTab(key)}
              >
                <TabPane tab="Agent" key="agent">
                  <Table
                    rowSelection={{
                      type: "checkbox",
                      ...rowSelection,
                    }}
                    //@ts-ignore
                    columns={columns}
                    dataSource={agentData}
                    bordered
                    loading={isLoading}
                    rowKey="id"
                    pagination={paginationConfig}
                    scroll={{ x: 1237 }}
                  />
                </TabPane>
                <TabPane tab="Customers" key="customer">
                  <Table
                    rowSelection={{
                      type: "checkbox",
                      ...rowSelection,
                    }}
                    //@ts-ignore
                    columns={columns}
                    dataSource={customerData}
                    bordered
                    loading={isLoading}
                    rowKey="id"
                    pagination={paginationConfig}
                    scroll={{ x: 1237 }}
                  />
                </TabPane>
              </Tabs>
            </div>
          </PageHeader>
        </Col>
      </Row>

      <Modal
        title="Approve Payout Request"
        visible={isModalVisible}
        onOk={handleApproveConfirm}
        onCancel={() => setIsModalVisible(false)}
      >
        <p>Enter the approved amount:</p>
        <InputNumber
          defaultValue={approvedAmount === null ? undefined : approvedAmount}
          onChange={(value) => setApprovedAmount(value ?? undefined)}
          style={{ width: "100%" }}
          min={0}
        />
      </Modal>
    </>
  );
};

export default WithdrawalHistory;
