import { useAtom } from "jotai";
import { useService } from "lib/core-react/contexts";
import { baseShippingCategoryAtom } from "lib/core-react/store/store";
import { BaseShippingCategoryRequestModel } from "models/baseShippingCategory";
import { useState } from "react";
import { getError } from "../utils/errors";
import {message, notification} from "antd";

export const useCreateBaseShippingCategory = () => {
  const { shippingCoreService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [baseShippingCategoryData, setBaseShippingCategoryData] = useAtom(
    baseShippingCategoryAtom
  );

  const createBaseShippingCategory = async (
    payload: BaseShippingCategoryRequestModel
  ) => {
    setIsLoading(true);
    try {
      const response = await shippingCoreService.baseShippingCategoryResource.create(
        payload
      );
      setIsLoading(false);
      setBaseShippingCategoryData({
        ...baseShippingCategoryData,
        refetch: true,
      });

      notification['success']({
        message: "Create successfully",
      })
      return response;
    } catch (error: any) {
      if(error.response?.data?.message){
        notification['error']({
          message: error.response.data.message,
        })
      }
      setError(getError(error));
      setIsLoading(false);
    }
  };

  return { createBaseShippingCategory, isLoading, isError };
};

export const useUpdateBaseShippingCategory = () => {
  const { shippingCoreService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [baseShippingCategoryData, setBaseShippingCategoryData] = useAtom(
    baseShippingCategoryAtom
  );
  const updateBaseShippingCategory = async (
    id: string,
    payload: BaseShippingCategoryRequestModel
  ) => {
    setIsLoading(true);
    try {
      const response = await shippingCoreService.baseShippingCategoryResource.update(
        id,
        payload
      );
      setIsLoading(false);
      setBaseShippingCategoryData({
        ...baseShippingCategoryData,
        refetch: true,
      });
      notification['success']({
        message: "Update successfully",
      })
      return response.data;
    } catch (error: any) {
      if(error.response?.data?.message){
        notification['error']({
          message: error.response.data.message,
        })
      }
      setError(getError(error));
      setIsLoading(false);

    }
  };

  return { updateBaseShippingCategory, isLoading, isError };
};

export const useGetBaseShippingCategory = () => {
  const [baseShippingCategoryData, setBaseShippingCategoryData] = useAtom(
    baseShippingCategoryAtom
  );
  const { shippingCoreService } = useService();
  const getBaseShippingCategory = async (params?: string): Promise<void> => {
    setBaseShippingCategoryData({
      ...baseShippingCategoryData,
      isLoading: true,
      error: null,
    });
    try {
      const response = await shippingCoreService.baseShippingCategoryResource.get(params);
      setBaseShippingCategoryData({
        ...baseShippingCategoryData,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setBaseShippingCategoryData({
        ...baseShippingCategoryData,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getBaseShippingCategory } as const;
};
