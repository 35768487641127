import { useEffect } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { Card, Col, Row } from "antd";
import { useAtomValue } from "jotai";

import { useGetShipmentProducts } from "lib/core-react/hooks/private/useShipping";
import { shipmentProductsAtom } from "lib/core-react/store/store";
import useDataFilters from "hooks/useDataFilters";
import { PaginationModel } from "models/pagination";
import { ApiHelperModel } from "models/apiHelper";
import { ShipmentProductCollectionModel } from "models/shipmentProductCollectionModel";
import { IFilterType } from "types/filters";

import FiltersComponent from "components/FiltersComponent";
import ShipmentProductsTable from "../ShipmentOrders/components/ShipmentProductsTable";

const ShipmentProducts = () => {
  const { getShipmentProducts } = useGetShipmentProducts();
  const {
    data: shipmentProductCollectionData,
    isLoading,
    refetch,
  } = useAtomValue(shipmentProductsAtom);

  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
    refetch: refetchFromFilter
  } = useDataFilters();

  // Api Call
  useEffect(() => {
    if ((filters && !isFetched && isFirstCall) || refetch || refetchFromFilter) {
      filters && ApiHelperModel.makeGetRequest(filters, getShipmentProducts);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstCall, filters, isFetched, refetch , refetchFromFilter]);

  const shipmentProductCollection =
    shipmentProductCollectionData &&
    new ShipmentProductCollectionModel(shipmentProductCollectionData);

  let filterData = shipmentProductCollection?.filters;

  useEffect(() => {
    if (!isFetched && shipmentProductCollection?.filters) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetched,
    initializeAvailableFilter,
    shipmentProductCollection?.filters,
  ]);

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getShipmentProducts,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    shipmentProductCollection,
    handlePaginationChange,
  );

  // Filter Handler
  const handleFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getShipmentProducts);
  };

  return (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Shipment Products"
      style={{ marginTop: "10px" }}
    >
      {filters && Object.keys(filters).length > 0 && (
        <Row>
          <Col span={24}>
            <Card title="Filter">
              <FiltersComponent
                handleProductFilter={handleFilter}
                handleFilterChange={handleFilterChange}
                handelFilterClear={handelFilterClear}
                isFetched={isFetched}
                filters={filters}
                filtersData={filterData}
                isFromProductReceived={true}
              />
            </Card>
          </Col>
        </Row>
      )}

      <ShipmentProductsTable
        data={shipmentProductCollection?.getAllProducts()}
        isLoading={isLoading}
        paginationConfig={paginationConfig}
      />
    </PageHeader>
  );
};

export default ShipmentProducts;
