import React, {useState} from 'react'
import {getCustomersByKeyword} from 'utils/customer-client'
import {Select, Spin} from 'antd';
const {Option} = Select;

const CustomerSelect = Props => {
    const {handleCustomerSelect} = Props;
    const [fetching, setFetching] = useState(false);
    const [data, setData] = useState([]);

    const fetchUser = value => {
        setData([]);
        setFetching(true);
        const data = {
            'q': value
        }
        getCustomersByKeyword(data).then(res => {
            setData(res.data?.data);
        }).catch(error => {

        }).finally(x => {
            setFetching(false);
        })
    };

    const handleChange = value => {
        setData([]);
        setFetching(false);
        handleCustomerSelect(data.filter(item => item.id === value)[0])
    };

    return(
        <Select
            showSearch
            placeholder="Select A customer"
            notFoundContent={fetching ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={fetchUser}
            onSelect={handleChange}
            style={{width: '100%'}}
        >
            {data.map((d,index) => (
                <Option key={index} value={d.id}>
                    {d.name} - {d.phone}
                </Option>
            ))}
        </Select>
    );
}

export default CustomerSelect;
