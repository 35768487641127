import {
  UserOutlined,
  SwitcherOutlined,
  BuildOutlined,
  AppstoreOutlined,
  GlobalOutlined,
  CarOutlined,
  HomeOutlined,
  EnvironmentOutlined,
  BorderHorizontalOutlined,
  MoneyCollectOutlined,
  ReadOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import { ROLE_PERMISSION } from "./parmission";

const menuItems = [
  {
    title: "Admin Manage",
    icon: <SwitcherOutlined />,
    hasSubMenu: true,
    requirePermission: true,
    permission: [
      ROLE_PERMISSION.ADMIN_ROLE_INDEX,
      ROLE_PERMISSION.ADMIN_ROLE_CREATE,
      ROLE_PERMISSION.ADMIN_ROLE_UPDATE,
      ROLE_PERMISSION.ADMIN_ROLE_DELETE,
      ROLE_PERMISSION.ADMIN_USER_ROLE_SYNC,
      ROLE_PERMISSION.ADMIN_ROLE_PERMISSION_SYNC,
    ],
    subMenus: [
      {
        path: "/admin-manage/users",
        title: "Admin User",
        permission: [ROLE_PERMISSION.ADMIN_ROLE_INDEX],
        requirePermission: true,
        icon: null,
      },
    ],
  },
  {
    title: "Role Permission",
    icon: <SwitcherOutlined />,
    hasSubMenu: true,
    requirePermission: true,
    permission: ["ship-for-me-order-list-view"],
    subMenus: [
      // {
      //   path: "/role-manage/overview",
      //   title: "Overview",
      //   permission: "admin_role_index",
      //   requirePermission: true,
      //   icon: null,
      // },
      {
        path: "/role-manage/role",
        title: "Roles",
        permission: ["ship-for-me-order-list-view"],
        requirePermission: true,
        icon: null,
      },

      // {
      //   path: "/role-manage/agent-permissions",
      //   title: "Agent permissions",
      //   permission: "view-all-permission",
      //   requirePermission: true,
      //   icon: null,
      // },
    ],
  },
  {
    title: "Customer Manage",
    icon: <UserOutlined />,
    hasSubMenu: true,
    permission: [],
    requirePermission: true,
    subMenus: [
      {
        path: "/customer/user",
        title: "User List",
        permission: [],
        requirePermission: true,
        icon: null,
      },
      {
        path: "/customer/wishlists",
        title: "Wishlists",
        permission: [],
        requirePermission: true,
        icon: null,
      },
      {
        path: "/customer/shipping-carts",
        title: "Shipping Carts",
        permission: [],
        requirePermission: true,
        icon: null,
      },
      // {
      //   path: "/customer/regular",
      //   title: "Regular Customers (Old)",
      //   permission: "all-customer-view",
      //   requirePermission: true,
      //   icon: null,
      // },
      // {
      //   path: "/customer/drop-user",
      //   title: "Drop Customers (Old)",
      //   permission: "all-customer-view",
      //   requirePermission: true,
      //   icon: null,
      // },
    ],
  },
  // {
  //   title: "Discount Manage",
  //   icon: <UserOutlined />,
  //   hasSubMenu: true,
  //   permission:[],
  //   requirePermission: true,
  //   subMenus: [
  //     {
  //       path: "/discount-manage/discounts",
  //       title: "Discounts",
  //       permission: [],
  //       requirePermission: true,
  //       icon: null,
  //     },
  //     {
  //       path: "/discount-manage/discount-usage-histories",
  //       title: "Discount Usage History",
  //       permission: [],
  //       requirePermission: true,
  //       icon: null,
  //     },
  //   ],
  // },
  {
    title: "Refund Manage",
    icon: <RetweetOutlined />,
    hasSubMenu: true,
    permission: [], //? what will be the permission?
    requirePermission: true,
    subMenus: [
      {
        path: "/refund-manage/refund",
        title: "Refund",
        permission: [],
        requirePermission: true,
        icon: null,
      },
    ],
  },
  {
    title: "RFQ Manage",
    icon: <RetweetOutlined />,
    hasSubMenu: true,
    permission: [], //? what will be the permission?
    requirePermission: true,
    subMenus: [
      {
        path: "/rfq-manage/requests/list",
        title: "RFQ List",
        permission: [],
        requirePermission: true,
        icon: null,
      },
    ],
  },

  {
    title: "Store Manage",
    icon: <AppstoreOutlined />,
    hasSubMenu: true,
    permission: [],
    requirePermission: true,
    subMenus: [
      {
        path: "/store-manage/store-lists",
        title: "Store Lists",
        permission: [],
        requirePermission: true,
        icon: null,
      },
      {
        path: "/store-manage/store-exchanges",
        title: "Exchange Lists",
        permission: [],
        requirePermission: true,
        icon: null,
      },
      {
        path: "/store-manage/exchange-groups",
        title: "Exchange Groups",
        permission: [],
        requirePermission: true,
        icon: null,
      },
    ],
  },
  {
    title: "Addon Manage",
    icon: <ReadOutlined />,
    hasSubMenu: true,
    permission: [],
    requirePermission: true,
    subMenus: [
      {
        path: "/addon-services",
        title: "Addon Services",
        permission: [],
        requirePermission: true,
        icon: null,
      },
    ],
  },
  {
    title: "Campaigns Manage",
    icon: <ReadOutlined />,
    hasSubMenu: true,
    permission: ["view_any_campaign"],
    requirePermission: true,
    subMenus: [
      {
        path: "/campaigns",
        title: "Campaigns",
        permission: ["view_any_campaign"],
        requirePermission: true,
        icon: null,
      },
    ],
  },
  {
    title: "Agent Manage",
    icon: <BuildOutlined />,
    hasSubMenu: true,
    permission: ["all-agent-view"],
    requirePermission: true,
    subMenus: [
      {
        path: "/agent-manage/agent-companies",
        title: "Agent Companies",
        permission: ["all-agent-view"],
        requirePermission: true,
        icon: null,
      },
      {
        path: "/agent-manage/agent-warehouses",
        title: "Agent Warehouses",
        permission: ["all-agent-view"],
        requirePermission: true,
        icon: null,
      },
    ],
  },
  {
    icon: <GlobalOutlined />,
    title: "Region",
    requirePermission: true,
    permission: [],
    hasSubMenu: true,
    subMenus: [
      {
        path: "/region/currency",
        title: "Currency",
        requirePermission: true,
        permission: [],
        icon: null,
      },
      {
        path: "/region/country",
        title: "Country",
        requirePermission: true,
        permission: [],
        icon: null,
      },
      {
        path: "/region/language",
        title: "Language",
        requirePermission: true,
        permission: [],
        icon: null,
      },
      {
        path: "/region/region",
        title: "Region",
        requirePermission: true,
        icon: null,
        permission: [],
      },
    ],
  },
  // {
  //   icon: <EnvironmentOutlined />,
  //   title: "Courier",
  //   requirePermission: true,
  //   permission: [],
  //   hasSubMenu: true,
  //   subMenus: [
  //     {
  //       path: "/courier/courier-gateway",
  //       title: "Courier Gateway",
  //       requirePermission: true,
  //       permission: [],
  //       icon: null,
  //     },
  //   ],
  // },
  {
    icon: <EnvironmentOutlined />,
    title: "Product Manage",
    requirePermission: true,
    permission: [],
    hasSubMenu: true,
    subMenus: [
      {
        path: "/product-manage/products",
        title: "Products",
        requirePermission: true,
        permission: [],
        icon: null,
      },
      // {
      //   path: "/product-manage/products-old",
      //   title: "Products Old",
      //   requirePermission: true,
      //   icon: null,
      // },
    ],
  },
  {
    icon: <CarOutlined />,
    title: "Shipping",
    requirePermission: true,
    permission: [],
    hasSubMenu: true,
    subMenus: [
      {
        path: "/shipping/shipping-country",
        title: "Shipping Country",
        requirePermission: true,
        permission: [],
        icon: null,
      },
      {
        path: "/shipping/shipping-category",
        title: "Shipping Category",
        permission: [],
        requirePermission: true,
        icon: null,
      },
      {
        path: "/shipping/base-shipping-category",
        title: "Base Shipping Category",
        permission: [],
        requirePermission: true,
        icon: null,
      },
      {
        path: "/shipping/auction-rules",
        title: "Auction Rules",
        permission: [],
        requirePermission: true,
        icon: null,
      },
      {
        path: "/shipping/commission",
        permission: [],
        title: "Commission",
        requirePermission: true,
        icon: null,
      },
      {
        path: "/shipping/shipment-orders",
        title: "Shipment Orders",
        requirePermission: true,
        permission: [],
        icon: null,
      },
      {
        path: "/shipping/shipment-products",
        title: "Shipment Products",
        requirePermission: true,
        permission: [],
        icon: null,
      },
    ],
  },
  {
    icon: <HomeOutlined />,
    title: "Destination",
    requirePermission: true,
    permission: [],
    hasSubMenu: true,
    subMenus: [
      {
        path: "/destination/destination-warehouse",
        title: "Destination Warehouse",
        permission: [],
        requirePermission: true,
        icon: null,
      },
    ],
  },
  {
    icon: <BorderHorizontalOutlined />,
    title: "Buy & Ship",
    requirePermission: true,
    permission: [],
    hasSubMenu: true,
    subMenus: [
      {
        path: "/buy-and-ship-for-me/orders",
        title: "Buy Orders",
        requirePermission: true,
        permission: [],
        icon: null,
      },
      {
        path: "/buy-and-ship-for-me/products",
        title: "Buy Products",
        requirePermission: true,
        permission: [],
        icon: null,
      },
      {
        path: "/buy-and-ship-for-me/actions-needed",
        title: "Actions Needed",
        requirePermission: true,
        permission: [],
        icon: null,
      },
    ],
  },
  // {
  //   icon: <BorderHorizontalOutlined />,
  //   title: "Buy & Ship For Me",
  //   requirePermission: true,
  //   hasSubMenu: true,
  //   subMenus: [
  //     {
  //       path: "/buy-and-ship/orders",
  //       title: "Orders",
  //       requirePermission: true,
  //       icon: null,
  //     },
  //     {
  //       path: "/buy-and-ship/products",
  //       title: "Products",
  //       requirePermission: true,
  //       icon: null,
  //     },
  //   ],
  // },
  {
    icon: <MoneyCollectOutlined />,
    title: "Payment Manage",
    requirePermission: true,
    hasSubMenu: true,
    permission: [],
    subMenus: [
      {
        path: "/payment/gateway",
        title: "Gateway",
        requirePermission: true,
        permission: [],
        icon: null,
      },
      {
        path: "/payment/payout-gateways",
        title: "Payout Gateway",
        permission: [],
        requirePermission: true,
        icon: null,
      },

      {
        path: "/payment/transaction",
        permission: [],
        title: "All Transactions",
        requirePermission: true,
        icon: null,
      },
    ],
  },
  {
    title: "Wallet Manage",
    icon: <MoneyCollectOutlined />,
    hasSubMenu: true,
    permission: ["view-wallet-balance"],
    requirePermission: true,
    subMenus: [
      {
        path: "/wallet-manage/overview",
        title: "Overview",
        permission: ["view-wallet-balance"],
        requirePermission: true,
        icon: null,
      },
      // {
      //   path: "/wallet-manage/all-transactions",
      //   title: "All Transactions",
      //   permission: "view-wallet-balance",
      //   requirePermission: true,
      //   icon: null,
      // },
      // {
      //   path: "/wallet-manage/anomalies-transactions",
      //   title: "Anomalies Transactions",
      //   permission: "view-wallet-balance",
      //   requirePermission: true,
      //   icon: null,
      // },
      {
        path: "/wallet-manage/withdrawal-history",
        title: "Withdrawal History",
        permission: [],
        requirePermission: true,
        icon: null,
      },
    ],
  },
  {
    title: "Invoice",
    icon: <ReadOutlined />,
    hasSubMenu: true,
    requirePermission: true,
    permission: ["ship-for-me-order-list-view"],
    subMenus: [
      {
        path: "/invoice/customer-invoices",
        title: "Customer",
        permission: ["ship-for-me-order-list-view"],
        requirePermission: true,
        icon: null,
      },
      {
        path: "/invoice/agent-invoices",
        title: "Agent",
        permission: ["view_buy_invoices"],
        requirePermission: true,
        icon: null,
      },
      {
        path: "/invoice/invoice-payment-rule-groups",
        title: "Rule Groups",
        permission: ["view_buy_invoices"],
        requirePermission: true,
        icon: null,
      },
    ],
  },
];

export default menuItems;
