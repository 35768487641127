import client from './api-client'
import Qs from 'qs'

const getAgents = (page = null, filter = null, perPage = 10) => {
	let data = {
		page: page ? page : 1,
		per_page: perPage
	}

	if (filter) {
		data = { ...data, ...filter }
	}
	return client.get('admin/agents', {
		params: data,
		paramsSerializer: function (params) {
			return Qs.stringify(params, { arrayFormat: 'brackets' })
		},
	})
}

const storeAgent = (data) => {
	return client.post('admin/agents', data)
}

const updateAgentCompany = (agentCompany, data) => {
	return client.put(`admin/agents/${agentCompany}`, data)
}

const getAgent = (agentId) => {
	return client.get(`admin/agents/${agentId}/detail`)
}

export {
	getAgents,
	storeAgent,
	getAgent,
	updateAgentCompany
}