/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { PageHeader } from "@ant-design/pro-layout";
import {
  Button,
  Card,
  Col,
  Dropdown,
  MenuProps,
  Modal,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import { DownOutlined, EditOutlined, HddFilled } from "@ant-design/icons";

import { useGetAgentWarehouse } from "lib/core-react/hooks/private";
import { agentWarehouseCollectionAtom } from "lib/core-react/store/store";
import useDataFilters from "hooks/useDataFilters";

import { IFilterType } from "types/filters";
import {
  AgentWarehouseCollectionModel,
  AgentWarehouseModel,
} from "models/agentWarehouseCollectionModel";
import { PaginationModel } from "models/pagination";
import { ApiHelperModel } from "models/apiHelper";
import {
  AgentCompanyVerificationStatusEnums,
  AgentWarehouseVerificationStatusEnums,
} from "enums/shared";

import { UpdateAgentWarehouse } from "./components";
import FiltersComponent from "components/FiltersComponent";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";

const AgentWarehouse = () => {
  const { getAgentWarehouses } = useGetAgentWarehouse();
  const [{ data: agentWarehouseCollectionData, isLoading, refetch }] = useAtom(
    agentWarehouseCollectionAtom,
  );

  const { isMobile } = useWindowWidth();

  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
  } = useDataFilters();

  // Api Call
  useEffect(() => {
    if ((!isFetched && isFirstCall) || refetch) {
      ApiHelperModel.makeGetRequest({}, getAgentWarehouses);
    }
  }, [isFirstCall, isFetched, refetch]);

  const AgentWarehouseCollectionData =
    agentWarehouseCollectionData &&
    new AgentWarehouseCollectionModel(agentWarehouseCollectionData);

  // Filter
  const filterData = AgentWarehouseCollectionData?.getFilters();
  // Pagination
  const paginationData = AgentWarehouseCollectionData?.getPagination();

  const [selectedAgentWarehouse, setSelectedAgentWarehouse] = useState<
    AgentWarehouseModel | undefined
  >(undefined);
  const [isShowUpdateModal, setIsShowUpdateModal] = useState<boolean>(false);

  const handleMenuClick = (record: AgentWarehouseModel) => {
    setIsShowUpdateModal(true);
    setSelectedAgentWarehouse(record);
  };

  // Getting all available filters
  useEffect(() => {
    if (!isFetched && AgentWarehouseCollectionData?.getFilters()) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [
    isFetched,
    initializeAvailableFilter,
    AgentWarehouseCollectionData?.getFilters(),
  ]);

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getAgentWarehouses,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    AgentWarehouseCollectionData,
    handlePaginationChange,
  );

  // Filter Handler
  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getAgentWarehouses);
  };

  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "sl",
      width: 70,
      align: "center" as const,
      render: (_: string, __: AgentWarehouseModel, index: number) => {
        return (
          <div>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </div>
        );
      },
    },
    {
      title: "Warehouse info",
      dataIndex: "name",
      key: "name",
      render: (_: string, record: AgentWarehouseModel) => {
        return (
          <Space direction="vertical" wrap>
            <Space>
              <Typography.Text strong>Name:</Typography.Text>
              <Typography.Text>{record.getName()}</Typography.Text>
            </Space>
            <Space>
              <Typography.Text strong>Shipping mark:</Typography.Text>
              <Typography.Text>{record.getShippingMark()}</Typography.Text>
            </Space>
            <Space>
              <Typography.Text strong>Contact person:</Typography.Text>
              <Typography.Text>{record.getContactPerson()}</Typography.Text>
            </Space>
            <Space>
              <Typography.Text strong>Contact number:</Typography.Text>
              <Typography.Text>{record.getContactNumber()}</Typography.Text>
            </Space>
            <Space>
              <Typography.Text strong>Instrtuction:</Typography.Text>
              <Typography.Text>{record.getInstruction()}</Typography.Text>
            </Space>
            <Space>
              <Typography.Text strong>Country:</Typography.Text>
              <Space align="center">
                <img
                  src={record.getCountry().getFlag()}
                  style={{ width: 18, height: 18 }}
                  alt=""
                />
                {record.getCountry().getName()}
              </Space>
            </Space>
            {/* <Space>
              <Typography.Text strong>Operation status:</Typography.Text>
              {record.getOperationStatus() ? (
                <Tag
                  color={
                    record.getOperationStatus() ===
                    AgentWarehouseOperationStatusEnums.CLOSED_OPERATION
                      ? "red"
                      : "green"
                  }
                  style={{ textTransform: "capitalize" }}
                >
                  {record.getOperationStatus().replaceAll("-", " ")}
                </Tag>
              ) : (
                ""
              )}
            </Space> */}
            <Space>
              <Typography.Text strong>Verification status:</Typography.Text>
              {record.getVerificationStatus() ? (
                <Tag
                  color={
                    record.getVerificationStatus() ===
                    AgentWarehouseVerificationStatusEnums.APPROVED
                      ? "green"
                      : "red"
                  }
                  style={{ textTransform: "capitalize" }}
                >
                  {record.getVerificationStatus().replaceAll("-", " ")}
                </Tag>
              ) : (
                ""
              )}
            </Space>
          </Space>
        );
      },
    },
    {
      title: "Company info",
      dataIndex: "agent_company",
      key: "agent-company",
      render: (_: string, record: AgentWarehouseModel) => {
        return (
          <Space direction="vertical">
            <Space>
              <Typography.Text strong>Business name: </Typography.Text>
              <Typography.Text>
                {record.getAgentCompany().getPrimaryName()}
              </Typography.Text>
            </Space>
            <Space>
              <Typography.Text strong>Brand name: </Typography.Text>
              <Typography.Text>
                {record.getAgentCompany().getSecondaryName()}
              </Typography.Text>
            </Space>
            <Space></Space>
            <Space>
              <Typography.Text strong>Director: </Typography.Text>
              {record.getAgentCompany().getDirectorName() &&
              record.getAgentCompany().getDesignation() ? (
                <Typography.Text style={{ textTransform: "capitalize" }}>
                  {record.getAgentCompany().getDirectorName()} (
                  {record.getAgentCompany().getDesignation()})
                </Typography.Text>
              ) : (
                ""
              )}
            </Space>
            <Space>
              <Typography.Text strong>Type: </Typography.Text>
              <Typography.Text style={{ textTransform: "capitalize" }}>
                {record.getAgentCompany().getCompanyType()
                  ? record
                      .getAgentCompany()
                      .getCompanyType()
                      .replaceAll("-", " ")
                  : ""}
              </Typography.Text>
            </Space>
            <Space>
              <Typography.Text strong>Phone: </Typography.Text>
              <Typography.Text>
                {record.getAgentCompany().getBusinessPhone() ? (
                  <a
                    href={`tel:${record.getAgentCompany().getBusinessPhone()}`}
                  >
                    {record.getAgentCompany().getBusinessPhone()}
                  </a>
                ) : (
                  ""
                )}
              </Typography.Text>
            </Space>
            {/* <Space>
              <Typography.Text strong>Years of experience: </Typography.Text>
              <Typography.Text style={{ textTransform: "capitalize" }}>
                {record.getAgentCompany().getYearsOfExperience()
                  ? record
                      .getAgentCompany()
                      .getYearsOfExperience()
                      .replaceAll("_", " ")
                  : ""}
              </Typography.Text>
            </Space> */}
            {/* <Space>
              <Typography.Text strong>Operation status: </Typography.Text>
              {record.getAgentCompany().getOperationStatus() ? (
                <Tag
                  color={
                    record.getAgentCompany().getOperationStatus() ===
                    AgentCompanyOperationStatusEnums.CLOSED_OPERATION
                      ? "red"
                      : "green"
                  }
                  style={{ textTransform: "capitalize" }}
                >
                  {record
                    .getAgentCompany()
                    .getOperationStatus()
                    .replaceAll("-", " ")}
                </Tag>
              ) : (
                ""
              )}
            </Space> */}
            <Space>
              <Typography.Text strong>Verification status: </Typography.Text>
              {record.getAgentCompany().getVerificationStatus() ? (
                <Tag
                  style={{ textTransform: "capitalize" }}
                  color={
                    record.getAgentCompany().getVerificationStatus() ===
                    AgentCompanyVerificationStatusEnums.APPROVED
                      ? "green"
                      : "red"
                  }
                >
                  {record.getAgentCompany().getVerificationStatus()}
                </Tag>
              ) : (
                ""
              )}
            </Space>
          </Space>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      fixed: isMobile ? "right" : undefined,
      render: (_: string, record: AgentWarehouseModel) => {
        const items: MenuProps["items"] = [
          {
            icon: <EditOutlined />,
            label: "Update",
            key: "update",
            onClick: () => {
              handleMenuClick(record);
            },
          },
        ];

        return (
          <Dropdown
            menu={{ items: items }}
            onOpenChange={() => setSelectedAgentWarehouse(record)}
          >
            <Button icon={<HddFilled />}>
              Actions <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <PageHeader
            style={{ marginTop: "10px" }}
            ghost={false}
            title="Agent Warehouses"
          >
            {filters && Object.keys(filters).length > 0 && (
              <Row>
                <Col span={24}>
                  <Card title="Filter">
                    <FiltersComponent
                      handleProductFilter={handleProductFilter}
                      handleFilterChange={handleFilterChange}
                      handelFilterClear={handelFilterClear}
                      isFetched={isFetched}
                      filters={filters}
                      filtersData={filterData}
                      isFromProductReceived={true}
                    />
                  </Card>
                </Col>
              </Row>
            )}
            <div>
              <Table
                //@ts-ignore
                columns={columns}
                dataSource={AgentWarehouseCollectionData?.getData()}
                bordered
                loading={isLoading}
                rowKey="id"
                pagination={paginationConfig}
                scroll={{ x: 900 }}
              />
            </div>
          </PageHeader>
        </Col>
      </Row>

      {selectedAgentWarehouse && (
        <Modal
          title="Update Agent Warehouse"
          open={isShowUpdateModal}
          onCancel={() => setIsShowUpdateModal(false)}
          footer={null}
        >
          <UpdateAgentWarehouse
            setIsShowUpdateModal={setIsShowUpdateModal}
            selectedAgentWarehouse={selectedAgentWarehouse}
          />
        </Modal>
      )}
    </>
  );
};

export default AgentWarehouse;
