import { AntDesignLayout } from "layout";
import { AgentCompany } from "pages/AgentManage/AgentCompany";
import AgentWarehouse from "pages/AgentManage/AgentWarehouse";
import { Route, Routes ,Navigate} from "react-router-dom";
import ShippingCountry from "pages/Shipping/ShippingCountry";
import ShippingCategory from "pages/Shipping/ShippingCategory";
import BaseShippingCategory from "pages/Shipping/BaseShippingCategory";
import AuctionRule from "pages/Shipping/AuctionRule";
import { Button, Result } from 'antd';
import {
  Commission,
  CreateCommission,
  UpdateCommissionCategory,
} from "pages/Shipping/Commission";
import CreateOrderForCustomer from "pages/Shipping/components/CreateOrderForCustomer";
import ShipmentOrders from "pages/Shipping/ShipmentOrders";
import ShipmentProducts from "pages/Shipping/ShipmentProducts";
import Country from "pages/Region/Country";
import Currency from "pages/Region/Currency";
import Language from "pages/Region/Language";
import Region from "pages/Region/Region";
import Wishlists from "pages/CustomerManage/Wishlists";
import DestinationWarehouse from "pages/Destination/DestinationWarehouse";
import { PageNotFound } from "pages/Generic";
import CreateStoreExchange from "pages/StoreManage/StoreExchange/CreateStoreExchange";
import CourierGateway from "pages/Courier/CourierGateway";
import Overview from "pages/RoleManage/Overview";
import Role from "pages/RoleManage/Role";
import CustomerUser from "pages/CustomerManage/User/CustomerUser";
import ShippingCarts from "pages/CustomerManage/ShippingCarts";
import PaymentGateway from "pages/PaymentManage/PaymentGateway";
import ProductManage from "pages/ProductManage/ProductManage";
import BuyOrders from "pages/BuyAndShip/BuyOrders";
import BuyProducts from "pages/BuyAndShip/BuyProducts";
import Transaction from "pages/PaymentManage/Transactions";
import WalletOverview from "pages/WalletManage/Balance/walletOverview";
import {
  CustomerInvoiceDetails,
  CustomerInvoices,
  InvoicePaymentRule
} from "pages/InvoiceManage/Invoice";
import PrintCustomerInvoice from "pages/InvoiceManage/Invoice/Cutomer/printCustomerInvoice";
import PrintAgentInvoice from "pages/InvoiceManage/Invoice/agent/printAgentInvoice";
import ProductDetail from "pages/ProductManage/ProductDetail";
import CampaignsAll from "pages/CampaignsManage/Campaign";
import ActionsNeeded from "pages/BuyAndShip/ActionsNeeded";
import {
  CreateDiscount,
  UpdateDiscount,
} from "pages/DiscountManage/Discount/components";
import { Discount, DiscountUsageHistory } from "pages/DiscountManage";
import { RefundDispute, RefundDisputeDetails } from "pages/RefundManage";
import WithdrawalHistory from "pages/WalletManage/WithdrawalHistory";
import EditOrder from "pages/BuyAndShip/BuyOrders/EditOrder";
import { RfqManage } from "pages/RfqManage";
import AddonServices from "pages/AddonManage/AddonService";
import { EditShipmentProduct } from "pages/Shipping/ShipmentProducts";
import HarvestJobManage from "pages/HarvestJobManage/HarvestJobMange";
import TransactionDetailSingle from "pages/PaymentManage/Transactions/components/transactionsDetails";
import BuyProductViewDetail from "pages/BuyAndShip/components/ViewDetails";
import BuyOrderCreate from "pages/BuyAndShip/BuyOrders/components/BuyOrderCreate";
import StoreExchange from "pages/StoreManage/StoreExchange";
import ExchangeGroup from "pages/StoreManage/ExchangeGroup";
import StoreList from "pages/StoreManage/StoreList";
import WalletOverviewForAgentCompany from "pages/WalletManage/AgentWallet/walletOverviewForAgentCopmany";
import WalletOverviewForUser from "pages/WalletManage/UserWallet/walletOverviewForUser";
import PayoutGateways from "pages/PaymentManage/PayoutManage/PayoutGateways/PayoutGateways";
import { AdminUser } from "pages/AdminManage/User/AdminUser";
import {AgentInvoiceDetails, AgentInvoices} from "../pages/InvoiceManage/Invoice/agent";
import AuthenticatedRoute from "../components/Authorized/AuthenticatedRoute";
import {lazy} from "react";
import {getEnvironmentMode} from "../utils/helper";
const Login = lazy(() => import("pages/AuthAdmin/Login"));
const Settings = lazy(() => import("pages/AuthAdmin/Settings"));
const Recovery = lazy(() => import("pages/AuthAdmin/Recovery"));
const AuthError = lazy(() => import("pages/AuthAdmin/AuthError"));


const AllRoutes = () => {


  return (
    <Routes>
      { getEnvironmentMode() !== "development" &&  <>
        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/settings" element={<Settings />} />
        <Route path="/auth/recovery" element={<Recovery />} />
        <Route path="/auth/error" element={<AuthError />} />
      </>
      }
      <Route path={"/"} element={<AntDesignLayout/> }>
         <Route index element={<h1>Welcome to Homepage</h1>} />
        <Route path="/403" element={ <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={<Button type="primary">Back Home</Button>}
        />} />
          <Route  path={"/agent-manage"}>
            <Route index element={<Navigate to="/agent-manage/agent-companies" replace />} />
            <Route
              path="/agent-manage/agent-companies"
              element={ <AgentCompany />}
            />
            <Route
              path="/agent-manage/agent-warehouses"
              element={
                <AuthenticatedRoute permissions={["ship-for-me-order-list-view"]} >
                  <AgentWarehouse />
                </AuthenticatedRoute>
            }
            />
            <Route
              path="/agent-manage/agent-company/:id/wallet"
              element={<WalletOverviewForAgentCompany />}
            />
          </Route>
          {/* Shipping */}
          <Route path={"/shipping"}>
            <Route
              path="/shipping/shipping-country"
              element={ <ShippingCountry />}
            />
            <Route
              path="/shipping/shipping-category"
              element={<ShippingCategory />}
            />
            <Route
              path="/shipping/base-shipping-category"
              element={<BaseShippingCategory />}
            />

            <Route
              path="/shipping/auction-rules"
              element={<AuctionRule />}
            />
            <Route
              path="/shipping/commission"
              element={<Commission />}
            />
            <Route
              path="/shipping/commission/create"
              element={<CreateCommission />}
            />
            <Route
              path="/shipping/commission/update-category/:regionId/:commissionId/:commissionAssociationId/:categoryId"
              element={ <UpdateCommissionCategory />}
            />

            <Route
              path="/shipping/shipment-orders"
              element={ <ShipmentOrders />}
            />

            <Route
              path="/shipping/shipment-orders/create"
              element={ <CreateOrderForCustomer />}
            />

            <Route
              path="/shipping/shipment-products"
              element={<ShipmentProducts />}
            />

            <Route
              path={"/shipping/shipment-products/edit/:productNumber"}
              element={ <EditShipmentProduct />}
            />
          </Route>

          {/* RegionRoutes */}
          <Route path={"/region"}>
            <Route
              path="/region/country"
              element={<Country />}
            />
            <Route
              path="/region/currency"
              element={ <Currency />}
            />
            <Route
              path="/region/language"
              element={<Language /> }
            />
            <Route
              path="/region/region"
              element={ <Region /> }
            />
          </Route>

          {/* Customer Routes */}
          <Route path={"/customer"}>
            <Route
              path="/customer/wishlists"
              element={ <Wishlists /> }
            />
            <Route
              path="/customer/user/:id/wallet"
              element={<WalletOverviewForUser />}
            />
            <Route
              path="/customer/user"
              element={ <CustomerUser />}
            />
            <Route
              path="/customer/shipping-carts"
              element={ <ShippingCarts /> }
            />
          </Route>

          {/* Destination */}
          <Route path={"/destination"}>
            <Route
              path="/destination/destination-warehouse"
              element={<DestinationWarehouse /> }
            />
          </Route>

          {/* Store Manage */}

          <Route path={"/store-manage"}>
            <Route
              path="/store-manage/store-lists"
              element={  <StoreList /> }
            />
            <Route
              path="/store-manage/store-exchanges"
              element={ <StoreExchange /> }
            />
            <Route
              path="/store-manage/store-exchanges/create"
              element={ <CreateStoreExchange />}
            />
            <Route
              path="/store-manage/exchange-groups"
              element={ <ExchangeGroup /> }
            />
          </Route>

          {/*Courier  */}
          <Route path={"/courier"}>
            <Route
              path="/courier/courier-gateway"
              element={ <CourierGateway /> }
            />
          </Route>

          {/*Role Manage  */}
          <Route path={"/admin-manage"}>
            <Route
              path="/admin-manage/users"
              element={ <AdminUser />}
            />
          </Route>

          {/*Role Manage  */}
          <Route path={"/role-manage"}>
            <Route
              path="/role-manage/overview"
              element={<Overview /> }
            />
            <Route
              path="/role-manage/role"
              element={ <Role /> }
            />
          </Route>

          {/*BuyOrders Route*/}
          <Route path={"/buy-and-ship-for-me"}>
            <Route
              path={"/buy-and-ship-for-me/orders"}
              element={ <BuyOrders /> }
            />
            <Route
              path={"/buy-and-ship-for-me/orders/edit/:id"}
              element={<EditOrder />}
            />
            <Route
              path={"/buy-and-ship-for-me/products"}
              element={ <BuyProducts />}
            />

            <Route
              path={"/buy-and-ship-for-me/actions-needed"}
              element={<ActionsNeeded />}
            />
            <Route
              path={"/buy-and-ship-for-me/detail/:id"}
              element={<BuyProductViewDetail />}
            />
            <Route
              path="/buy-and-ship-for-me/orders/create"
              element={<BuyOrderCreate /> }
            />
          </Route>
          {/*Product Manage  */}
          <Route path={"/product-manage"}>
            <Route
              path="/product-manage/products"
              element={ <ProductManage />}
            />
            <Route
              path="/product-manage/products/product_detail/:id"
              element={<ProductDetail />}
            />
          </Route>

          {/* Payment Manage */}
          <Route path={"/payment"}>
            <Route
              path="/payment/gateway"
              element={<PaymentGateway />}
            />

            <Route
              path="/payment/transaction"
              element={ <Transaction />}
            />

            <Route
              path="/payment/payout-gateways"
              element={<PayoutGateways />}
            />
            <Route
              path="/payment/transaction-detail/:id"
              element={<TransactionDetailSingle />}
            />
          </Route>

          {/* Wallet */}
          <Route path={"/wallet-manage"}>
            <Route
              path="/wallet-manage/overview"
              element={ <WalletOverview />}
            />

            <Route
              path="/wallet-manage/withdrawal-history"
              element={<WithdrawalHistory />}
            />
          </Route>

          {/* invoice */}
          <Route path={"/invoice"}>
            <Route
              path="/invoice/customer-invoices"
              element={<CustomerInvoices />}
            />
            <Route
              path="/invoice/customer-invoice/details/:id"
              element={  <CustomerInvoiceDetails />}
            />
            <Route
              path="/invoice/customer-invoice/print/:id"
              element={ <PrintCustomerInvoice />}
            />

            <Route
              path="/invoice/agent-invoices"
              element={ <AgentInvoices />}
            />
            <Route
              path="/invoice/agent-invoice/details/:id"
              element={<AgentInvoiceDetails />}
            />
            <Route
              path="/invoice/agent-invoice/print/:id"
              element={<PrintAgentInvoice />}
            />
            <Route
              path="/invoice/invoice-payment-rule-groups"
              element={ <InvoicePaymentRule /> }
            />
          </Route>

          <Route>
            <Route
              path="/campaigns"
              element={ <CampaignsAll />}
            />
          </Route>
          {/* Discount */}
          <Route path="/discount-manage">
            <Route
              path="/discount-manage/discounts"
              element={ <Discount /> }
            />
            <Route
              path="/discount-manage/discounts/create"
              element={ <CreateDiscount />}
            />
            <Route
              path="/discount-manage/discounts/update/:id"
              element={ <UpdateDiscount />}
            />
            <Route
              path="/discount-manage/discount-usage-histories"
              element={ <DiscountUsageHistory />}
            />
          </Route>

          {/* Refund */}
          <Route path="/refund-manage">
            <Route
              path="/refund-manage/refund"
              element={<RefundDispute />}
            />

            <Route
              path="/refund-manage/refund/details/:id"
              element={ <RefundDisputeDetails />}
            />
          </Route>
          {/* RFQ */}
          <Route path="/rfq-manage">
            <Route
              path="/rfq-manage/requests/list"
              element={<RfqManage />}
            />
          </Route>
          <Route>
            <Route
              path="/addon-services"
              element={<AddonServices />}
            />
          </Route>
          <Route>
            <Route
              path="/harvest-job"
              element={<HarvestJobManage />}
            />
          </Route>

          <Route path="*" element={<PageNotFound />} />

      </Route>

      {/* Shipping */}
      <Route path={"/shipping"}>
        <Route
          path="/shipping/shipping-country"
          element={
            <AntDesignLayout>
              <ShippingCountry />
            </AntDesignLayout>
          }
        />
        <Route
          path="/shipping/shipping-category"
          element={
            <AntDesignLayout>
              <ShippingCategory />
            </AntDesignLayout>
          }
        />
        <Route
          path="/shipping/base-shipping-category"
          element={
            <AntDesignLayout>
              <BaseShippingCategory />
            </AntDesignLayout>
          }
        />

        <Route
          path="/shipping/auction-rules"
          element={
            <AntDesignLayout>
              <AuctionRule />
            </AntDesignLayout>
          }
        />
        <Route
          path="/shipping/commission"
          element={
            <AntDesignLayout>
              <Commission />
            </AntDesignLayout>
          }
        />
        <Route
          path="/shipping/commission/create"
          element={
            <AntDesignLayout>
              <CreateCommission />
            </AntDesignLayout>
          }
        />
        <Route
          path="/shipping/commission/update-category/:regionId/:commissionId/:commissionAssociationId/:categoryId"
          element={
            <AntDesignLayout>
              <UpdateCommissionCategory />
            </AntDesignLayout>
          }
        />

        <Route
          path="/shipping/shipment-orders"
          element={
            <AntDesignLayout>
              <ShipmentOrders />
            </AntDesignLayout>
          }
        />

        <Route
          path="/shipping/shipment-orders/create"
          element={
            <AntDesignLayout>
              <CreateOrderForCustomer />
            </AntDesignLayout>
          }
        />

        <Route
          path="/shipping/shipment-products"
          element={
            <AntDesignLayout>
              <ShipmentProducts />
            </AntDesignLayout>
          }
        />

        <Route
          path={"/shipping/shipment-products/edit/:productId"}
          element={
            <AntDesignLayout>
              <EditShipmentProduct />
            </AntDesignLayout>
          }
        />
      </Route>

      {/* RegionRoutes */}
      <Route path={"/region"}>
        <Route
          path="/region/country"
          element={
            <AntDesignLayout>
              <Country />
            </AntDesignLayout>
          }
        />
        <Route
          path="/region/currency"
          element={
            <AntDesignLayout>
              <Currency />
            </AntDesignLayout>
          }
        />
        <Route
          path="/region/language"
          element={
            <AntDesignLayout>
              <Language />
            </AntDesignLayout>
          }
        />
        <Route
          path="/region/region"
          element={
            <AntDesignLayout>
              <Region />
            </AntDesignLayout>
          }
        />
      </Route>

      {/* Customer Routes */}
      <Route path={"/customer"}>
        <Route
          path="/customer/wishlists"
          element={
            <AntDesignLayout>
              <Wishlists />
            </AntDesignLayout>
          }
        />
        <Route
          path="/customer/user/:id/wallet"
          element={
            <AntDesignLayout>
              <WalletOverviewForUser />
            </AntDesignLayout>
          }
        />
        <Route
          path="/customer/user"
          element={
            <AntDesignLayout>
              <CustomerUser />
            </AntDesignLayout>
          }
        />
        <Route
          path="/customer/shipping-carts"
          element={
            <AntDesignLayout>
              <ShippingCarts />
            </AntDesignLayout>
          }
        />
      </Route>

      {/* Destination */}
      <Route path={"/destination"}>
        <Route
          path="/destination/destination-warehouse"
          element={
            <AntDesignLayout>
              <DestinationWarehouse />
            </AntDesignLayout>
          }
        />
      </Route>

      {/* Store Manage */}

      <Route path={"/store-manage"}>
        <Route
          path="/store-manage/store-lists"
          element={
            <AntDesignLayout>
              <StoreList />
            </AntDesignLayout>
          }
        />
        <Route
          path="/store-manage/store-exchanges"
          element={
            <AntDesignLayout>
              <StoreExchange />
            </AntDesignLayout>
          }
        />
        <Route
          path="/store-manage/store-exchanges/create"
          element={
            <AntDesignLayout>
              <CreateStoreExchange />
            </AntDesignLayout>
          }
        />
        <Route
          path="/store-manage/exchange-groups"
          element={
            <AntDesignLayout>
              <ExchangeGroup />
            </AntDesignLayout>
          }
        />
      </Route>

      {/*Courier  */}
      <Route path={"/courier"}>
        <Route
          path="/courier/courier-gateway"
          element={
            <AntDesignLayout>
              <CourierGateway />
            </AntDesignLayout>
          }
        />
      </Route>

      {/*Role Manage  */}
      <Route path={"/admin-manage"}>
        <Route
          path="/admin-manage/users"
          element={
            <AntDesignLayout>
              <AdminUser />
            </AntDesignLayout>
          }
        />
      </Route>

      {/*Role Manage  */}
      <Route path={"/role-manage"}>
        <Route
          path="/role-manage/overview"
          element={
            <AntDesignLayout>
              <Overview />
            </AntDesignLayout>
          }
        />
        <Route
          path="/role-manage/role"
          element={
            <AntDesignLayout>
              <Role />
            </AntDesignLayout>
          }
        />
      </Route>

      {/*BuyOrders Route*/}
      <Route path={"/buy-and-ship-for-me"}>
        <Route
          path={"/buy-and-ship-for-me/orders"}
          element={
            <AntDesignLayout>
              <BuyOrders />
            </AntDesignLayout>
          }
        />
        <Route
          path={"/buy-and-ship-for-me/orders/edit/:id"}
          element={
            <AntDesignLayout>
              <EditOrder />
            </AntDesignLayout>
          }
        />
        <Route
          path={"/buy-and-ship-for-me/products"}
          element={
            <AntDesignLayout>
              <BuyProducts />
            </AntDesignLayout>
          }
        />

        <Route
          path={"/buy-and-ship-for-me/actions-needed"}
          element={
            <AntDesignLayout>
              <ActionsNeeded />
            </AntDesignLayout>
          }
        />
        <Route
          path={"/buy-and-ship-for-me/detail/:id"}
          element={
            <AntDesignLayout>
              <BuyProductViewDetail />
            </AntDesignLayout>
          }
        />
        <Route
          path="/buy-and-ship-for-me/orders/create"
          element={
            <AntDesignLayout>
              <BuyOrderCreate />
            </AntDesignLayout>
          }
        />
        {/*<Route*/}
        {/*  path="/buy-and-ship-for-me/products/tracking-timeline/:id"*/}
        {/*  element={*/}
        {/*    <AntDesignLayout>*/}
        {/*      <TimelineTracking />*/}
        {/*    </AntDesignLayout>*/}
        {/*  }*/}
        {/*/>*/}
      </Route>
      {/*Product Manage  */}
      <Route path={"/product-manage"}>
        <Route
          path="/product-manage/products"
          element={
            <AntDesignLayout>
              <ProductManage />
            </AntDesignLayout>
          }
        />
        <Route
          path="/product-manage/products/product_detail/:id"
          element={
            <AntDesignLayout>
              <ProductDetail />
            </AntDesignLayout>
          }
        />
      </Route>

      {/* Payment Manage */}
      <Route path={"/payment"}>
        <Route
          path="/payment/gateway"
          element={
            <AntDesignLayout>
              <PaymentGateway />
            </AntDesignLayout>
          }
        />

        <Route
          path="/payment/transaction"
          element={
            <AntDesignLayout>
              <Transaction />
            </AntDesignLayout>
          }
        />

        <Route
          path="/payment/payout-gateways"
          element={
            <AntDesignLayout>
              <PayoutGateways />
            </AntDesignLayout>
          }
        />
        <Route
          path="/payment/transaction-detail/:id"
          element={
            <AntDesignLayout>
              <TransactionDetailSingle />
            </AntDesignLayout>
          }
        />
      </Route>

      {/* Wallet */}
      <Route path={"/wallet-manage"}>
        <Route
          path="/wallet-manage/overview"
          element={
            <AntDesignLayout>
              <WalletOverview />
            </AntDesignLayout>
          }
        />

        <Route
          path="/wallet-manage/withdrawal-history"
          element={
            <AntDesignLayout>
              <WithdrawalHistory />
            </AntDesignLayout>
          }
        />
      </Route>

      {/* invoice */}
      <Route path={"/invoice"}>
        <Route
          path="/invoice/customer-invoices"
          element={
            <AntDesignLayout>
              <CustomerInvoices />
            </AntDesignLayout>
          }
        />
        <Route
          path="/invoice/customer-invoice/details/:id"
          element={
            <AntDesignLayout>
              <CustomerInvoiceDetails />
            </AntDesignLayout>
          }
        />
        <Route
          path="/invoice/customer-invoice/print/:id"
          element={
            <AntDesignLayout>
              <PrintCustomerInvoice />
            </AntDesignLayout>
          }
        />

        <Route
          path="/invoice/agent-invoices"
          element={
            <AntDesignLayout>
              <AgentInvoices />
            </AntDesignLayout>
          }
        />
        <Route
          path="/invoice/agent-invoice/details/:id"
          element={
            <AntDesignLayout>
              <AgentInvoiceDetails />
            </AntDesignLayout>
          }
        />
        <Route
          path="/invoice/agent-invoice/print/:id"
          element={
            <AntDesignLayout>
              <PrintAgentInvoice />
            </AntDesignLayout>
          }
        />
      </Route>

      <Route>
        <Route
          path="/campaigns"
          element={
            <AntDesignLayout>
              <CampaignsAll />
            </AntDesignLayout>
          }
        />
      </Route>
      {/* Discount */}
      <Route path="/discount-manage">
        <Route
          path="/discount-manage/discounts"
          element={
            <AntDesignLayout>
              <Discount />
            </AntDesignLayout>
          }
        />
        <Route
          path="/discount-manage/discounts/create"
          element={
            <AntDesignLayout>
              <CreateDiscount />
            </AntDesignLayout>
          }
        />
        <Route
          path="/discount-manage/discounts/update/:id"
          element={
            <AntDesignLayout>
              <UpdateDiscount />
            </AntDesignLayout>
          }
        />
        <Route
          path="/discount-manage/discount-usage-histories"
          element={
            <AntDesignLayout>
              <DiscountUsageHistory />
            </AntDesignLayout>
          }
        />
      </Route>

      {/* Refund */}
      <Route path="/refund-manage">
        <Route
          path="/refund-manage/refund"
          element={
            <AntDesignLayout>
              <RefundDispute />
            </AntDesignLayout>
          }
        />

        <Route
          path="/refund-manage/refund/details/:id"
          element={
            <AntDesignLayout>
              <RefundDisputeDetails />
            </AntDesignLayout>
          }
        />
      </Route>
      {/* RFQ */}
      <Route path="/rfq-manage">
        <Route
          path="/rfq-manage/requests/list"
          element={
            <AntDesignLayout>
              <>
                <RfqManage />
              </>
            </AntDesignLayout>
          }
        />
      </Route>
      <Route>
        <Route
          path="/addon-services"
          element={
            <AntDesignLayout>
              <AddonServices />
            </AntDesignLayout>
          }
        />
      </Route>
      <Route>
        <Route
          path="/harvest-job"
          element={
            <AntDesignLayout>
              <HarvestJobManage />
            </AntDesignLayout>
          }
        />
      </Route>

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AllRoutes;
