import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import qs from "qs";

import { useGetProductDetailsCollection } from "lib/core-react/hooks/private/useProduct";
import { useGetCampaginProductList } from "lib/core-react/hooks/private/useCampaign";

import ProductListing from "./ProductListing";
import AppsContainer from "components/App/AppsContainer";
import { CampaignOperationEnum } from "enums/campaignCollectionEnums";
import ProductsSidebar from "./ProductsSidebar";
import { useAtom } from "jotai";
import { campaignProductCollectionAtom } from "lib/core-react/store/store";

export const VIEW_TYPE = {
  GRID: "grid",
  LIST: "list",
};

const Products = () => {
  const location = useLocation();
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });

  const operation = new URLSearchParams(location.search).get("operation");
  const region = new URLSearchParams(location.search).get("region");
  const id = new URLSearchParams(location.search).get("id");

  // Product list
  const { getProductDetailsCollection } = useGetProductDetailsCollection();
  // Product list (campaign)
  const { getCampaignProducts } = useGetCampaginProductList();
  const [{ refetch }] = useAtom(campaignProductCollectionAtom);
  const [viewType, setViewType] = React.useState(VIEW_TYPE.GRID);

  useEffect(() => {
    if (operation === CampaignOperationEnum.ViewCampaignProduct && id) {
      getCampaignProducts(id, {
        ...parsedQuery,
      });
    } else {
      getProductDetailsCollection(
        { region: region ?? "BD", locale: "bn", keyword: "bag" },
        { ...parsedQuery },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operation, refetch]);

  return (
    <AppsContainer
      title={"Products"}
      sidebarContent={
        operation === CampaignOperationEnum.ViewCampaignProduct ? null : (
          <ProductsSidebar />
        )
      }
    >
      <ProductListing viewType={viewType} setViewType={setViewType} />
    </AppsContainer>
  );
};

export default Products;
