import { ResponsePromise } from "lib/core/request/types/typings";
import BaseResource from "../public/baseResource";
import { CreateServicesnPostRequestModel, Price, UpdateServicesnPutRequestModel } from "models/addonServiceCollectionModel";

class AddonServicsResource extends BaseResource {
  path = `/api/addon/admin/addon-service/v1/addon-services`;
  getList(customHeaders: Record<string, any> = {}): ResponsePromise {
    const path = `${this.path}`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  CreateService(
    payload: CreateServicesnPostRequestModel,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.path}/`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }
  
  updateService(
    id: number,
    payload: UpdateServicesnPutRequestModel,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.path}/${id}`;
    return this.client.request("PUT", path, payload, {}, customHeaders);
  }

  addPrice(
    id: number,
    payload: Price,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.path}/${id}/prices`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }

  updatePrice(
    id: number,
    priceid: number,
    payload: Price,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.path}/${id}/prices/${priceid}`;
    return this.client.request("PUT", path, payload, {}, customHeaders);
  }

  deletePrice(
    id: number,
    priceid: number,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.path}/${id}/prices/${priceid}`;
    return this.client.request("DELETE", path, {},undefined, customHeaders);
  }

  
}

export default AddonServicsResource;
