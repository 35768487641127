/* eslint-disable react-hooks/exhaustive-deps */
import {
  DownOutlined,
  EditOutlined,
  FileAddOutlined,
  HddFilled,
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import {
  Button,
  Card,
  Col,
  Dropdown,
  MenuProps,
  Modal,
  Row,
  Table,
  Tag,
} from "antd";

import FiltersComponent from "components/FiltersComponent";
import useDataFilters from "hooks/useDataFilters";
import { useAtom } from "jotai";

import { useGetShippingCategory } from "lib/core-react/hooks/private";
import { shippingCategoryAtom } from "lib/core-react/store/store";
import { PaginationModel } from "models/pagination";
import {
  ShippingCategoryDataModel,
  ShippingCategoryModel,
} from "models/shippingCategory";
import { useState, useEffect } from "react";
import { IFilterType } from "types/filters";
import CreateShippingCategory from "./components/CreateShippingCategory";
import UpdateShippingCategory from "./components/UpdateShippingCategory";
import AddChildren from "./components/AddChildren";
import { ApiHelperModel } from "models/apiHelper";
import {StatusTag} from "../../../components";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
const { Column } = Table;
const ShippingCategory = () => {
  const { getShippingCategory } = useGetShippingCategory();
  const {isMobile} = useWindowWidth()
  const [{ data: shippingCategoryStoreData, isLoading, refetch }] =
    useAtom(shippingCategoryAtom);

  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
  } = useDataFilters();

  // Api Call
  useEffect(() => {
    if ((filters && !isFetched && isFirstCall) || refetch) {
      filters && ApiHelperModel.makeGetRequest(filters, getShippingCategory);
    }
  }, [isFirstCall, filters, isFetched, refetch]);

  //  Shipping category
  const ShippingCategoryData =
    shippingCategoryStoreData &&
    new ShippingCategoryModel(shippingCategoryStoreData);

  // Filter
  let filterData = ShippingCategoryData?.filters;

  // Getting all available filters
  useEffect(() => {
    if (!isFetched && ShippingCategoryData?.filters) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [isFetched, initializeAvailableFilter, ShippingCategoryData?.filters]);

  // Add Child state
  const [isShowAddChildModal, setIsShowAddChildModal] =
    useState<boolean>(false);
  const [addChildPayload, setAddChildPayload] = useState<
    ShippingCategoryDataModel | undefined
  >(undefined);

  const showAddChildModal = (record: ShippingCategoryDataModel) => {
    setAddChildPayload(record);
    setIsShowAddChildModal(true);
  };

  // Create state
  const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);

  // Update modal state
  const [isShowUpdateModal, setIsShowUpdateModal] = useState<boolean>(false);
  const [updatePayload, setUpdatePayload] = useState<
    ShippingCategoryDataModel | undefined
  >(undefined);

  // Show update modal with data
  const showUpdateModal = (record: ShippingCategoryDataModel) => {
    setUpdatePayload(record);
    setIsShowUpdateModal(true);
  };

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getShippingCategory,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    ShippingCategory,
    handlePaginationChange,
  );

  // Filter Handler
  const handleFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getShippingCategory);
  };

  return (
    <>
      <div>
        <PageHeader
          ghost={false}
          title="Shipping Category"
          style={{ marginTop: "10px" }}
          extra={[
            <Button
              key="1"
              type="primary"
              onClick={() => setIsShowCreateModal(true)}
            >
              Create Shipping Category
            </Button>,
          ]}
        >
          <div>
            {filters && Object.keys(filters).length > 0 && (
              <Row>
                <Col span={24}>
                  <Card title="Filter">
                    <FiltersComponent
                      handleProductFilter={handleFilter}
                      handleFilterChange={handleFilterChange}
                      handelFilterClear={handelFilterClear}
                      isFetched={isFetched}
                      filters={filters}
                      filtersData={filterData}
                      isFromProductReceived={true}
                    />
                  </Card>
                </Col>
              </Row>
            )}
            <Table
              bordered
              style={{ marginTop: "15px" }}
              loading={isLoading}
              rowKey="id"
              pagination={paginationConfig}
              dataSource={ShippingCategoryData?.data}
              scroll={{ x: 1000 }}
            >
              <Column title="Name" dataIndex="name" key="name" />

              <Column title="Slug" dataIndex="slug" key="slug" />

              <Column
                title="Status"
                dataIndex="status"
                key="status"
                render={(_, record: ShippingCategoryDataModel) => (
                  <StatusTag  text={record.status} slug={record.status}/>
                  )}
              />
              <Column
                title = "Action"
                key = "action"
                fixed = {isMobile ? "right" : undefined}
                align = "center"
                width = {100}
                render={(_, record: ShippingCategoryDataModel) => {
                  const items: MenuProps["items"] = [
                    {
                      key: "1",
                      label: (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => showUpdateModal(record)}
                        >
                          <EditOutlined /> <span> Update</span>
                        </div>
                      ),
                    },
                    {
                      key: "2",
                      label: (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => showAddChildModal(record)}
                        >
                          <FileAddOutlined /> <span> Add Child</span>
                        </div>
                      ),
                    },
                  ];

                  return (
                    <Dropdown menu={{ items }}>
                      <Button icon={<HddFilled />}>
                        {isMobile ? (
                          <DownOutlined />
                        ) : (
                          <>
                            Actions <DownOutlined />{" "}
                          </>
                        )}
                      </Button>
                    </Dropdown>
                  );
                }}
              />
            </Table>
          </div>
        </PageHeader>
      </div>

      <Modal
        title={`Create Shippinng Category`}
        open={isShowCreateModal}
        footer={false}
        onCancel={() => setIsShowCreateModal(false)}
      >
        <CreateShippingCategory setIsShowCreateModal={setIsShowCreateModal} />
      </Modal>

      <Modal
        title={`Update Shippinng Category`}
        open={isShowUpdateModal}
        footer={false}
        onCancel={() => setIsShowUpdateModal(false)}
      >
        <UpdateShippingCategory
          setIsShowUpdateModal={setIsShowUpdateModal}
          data={updatePayload as ShippingCategoryDataModel}
        />
      </Modal>

      <Modal
        title={`Add Children`}
        open={isShowAddChildModal}
        footer={false}
        onCancel={() => setIsShowAddChildModal(false)}
      >
        <AddChildren
          setIsShowAddChildModal={setIsShowAddChildModal}
          data={addChildPayload as ShippingCategoryDataModel}
        />
      </Modal>
    </>
  );
};

export default ShippingCategory;
