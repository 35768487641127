/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { Card, Col, Row, Space, Table, Typography } from "antd";
import FiltersComponent from "../../../../components/FiltersComponent";
import { useGetAgentCompany } from "../../../../lib/core-react/hooks/private";
import { useAtom } from "jotai/index";
import { agentCompanyCollectionAtom } from "../../../../lib/core-react/store/store";
import useDataFilters from "../../../../hooks/useDataFilters";
import {
  AgentCompanyCollectionModel,
  AgentCompanyModel,
} from "../../../../models/agentCompanyCollectionModel";
import { ApiHelperModel } from "../../../../models/apiHelper";
import { IFilterType } from "../../../../types/filters";
import { PaginationModel } from "../../../../models/pagination";

import AgentCommissionSubmit from "./AgentCommissionSubmit";
import useWindowWidth from "../../../../lib/core-react/hooks/public/useWindowWidth";

const {Text } = Typography
interface IProps {
  isApproveModalOpen: boolean;
  setIsApproveModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  productId: number;
  storeId?:number;
  selectedRowKeys: React.Key[]
}

const BuyProductAgentAssignModal: React.FC<IProps> = ({
  setIsApproveModalOpen,
  productId,
  storeId,
  selectedRowKeys
}) => {
  const { getAgentCompanies } = useGetAgentCompany();

  const [{ data: agentCompanyCollectionData, isLoading, refetch }] = useAtom(
    agentCompanyCollectionAtom,
  );

  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
  } = useDataFilters();

  const AgentCompanyCollectionData =
    agentCompanyCollectionData &&
    new AgentCompanyCollectionModel(agentCompanyCollectionData);


  useEffect(()=>{
    if(storeId){
      handleFilterChange({purchase_store_id:storeId})
    }
  },[storeId,productId])

  // Api Call
  useEffect(() => {
    if ((filters && !isFetched && isFirstCall) || refetch) {
      filters && ApiHelperModel.makeGetRequest(filters, getAgentCompanies);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstCall, filters, isFetched, refetch]);

  // Filter
  const filterData = AgentCompanyCollectionData?.getFilters();
  // Pagination
  const paginationData = AgentCompanyCollectionData?.getPagination();

  // Getting all available filters
  useEffect(() => {
    if (
      !isFetched &&
      AgentCompanyCollectionData &&
      AgentCompanyCollectionData.getFilters()
    ) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [
    isFetched,
    initializeAvailableFilter,
    AgentCompanyCollectionData?.getFilters(),
  ]);

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getAgentCompanies,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    AgentCompanyCollectionData,
    handlePaginationChange,
  );

  // Filter Handler
  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getAgentCompanies);
  };

  const CompanyInfoColumn = ({ record }) => {
    const {width} = useWindowWidth();

    const renderRow = (key, value) => {
      if (width < 400) {
        return (
          <Space direction="vertical">
            <Text strong>{key}</Text>
            <Text>{value}</Text>
          </Space>
        );
      }
      return (
        <Space>
          <Text strong>{key}</Text>
          <Text>{value}</Text>
        </Space>
      );
    };

    return (
      <Space direction="vertical">
        {renderRow("Business name: ", record.getPrimaryName())}
        {renderRow("Brand name: ", record.getSecondaryName())}
      </Space>
    );
  };


  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "sl",
      align: "center" as const,
      render: (_: string, __: AgentCompanyModel, index: number) => {
        return (
          <div>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </div>
        );
      },
    },
    {
      title: "Company info",
      dataIndex: "primary_name",
      key: "primary_name",
      render: (_: string, record: AgentCompanyModel) => <CompanyInfoColumn record={record} />,

    },

    {
      title: "Actions",
      key: "actions",
      render: (_: string, record: AgentCompanyModel) => {
        return (
          <AgentCommissionSubmit
            selectedRowKeys={selectedRowKeys}
            setIsApproveModalOpen={setIsApproveModalOpen}
            record={record}
            productId={productId}
          />
        );
      },
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <PageHeader
          style={{ marginTop: "10px" }}
          ghost={false}
          title="Agent Companies"
        >
          {filters && Object.keys(filters).length > 0 && (
            <Row>
              <Col span={24}>
                <Card title="Filter">
                  <FiltersComponent
                    handleProductFilter={handleProductFilter}
                    handleFilterChange={handleFilterChange}
                    handelFilterClear={handelFilterClear}
                    isFetched={isFetched}
                    filters={filters}
                    filtersData={filterData}
                    isFromProductReceived={true}
                  />
                </Card>
              </Col>
            </Row>
          )}
          <Table
            bordered
            columns={columns}
            dataSource={AgentCompanyCollectionData?.getData()}
            size="middle"
            loading={isLoading}
            pagination={paginationConfig}
            rowKey="id"
            scroll={{x:400}}
          />
        </PageHeader>
      </Col>
    </Row>
  );
};

export default BuyProductAgentAssignModal;
