import { CheckCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { Card, Col, Descriptions, Image, Row, Space, Tag } from "antd";
import { StatusTag } from "components";
import { useAtom } from "jotai";
import { useTransactionsDetail } from "lib/core-react/hooks/private/useTransactions";
import { transactionDetailAtom } from "lib/core-react/store/store";
import { TransactionModel } from "models/transactionDetailModels";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { formatDateTime } from "utils/helpers";
const TransactionDetailSingle = () => {
  const { getTransctionsDetail } = useTransactionsDetail();

  const [{ data: transactionDetail }] = useAtom(transactionDetailAtom);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getTransctionsDetail(id);
    }
  }, [id]);

  const data = transactionDetail && new TransactionModel(transactionDetail);

  const fee = data?.getGateway()?.getFee();
  const feeType = data?.getGateway()?.getFeeType();

  const formattedFee =
    feeType === "percentage" && typeof fee === "number" ? `${fee}%` : fee;

  const attachment = data?.getAttachement()?.data;
  const invoice = data?.association?.getPaymentSession().getSessionInvoices();

  return (
    <PageHeader
      ghost={false}
      title={data ? `Transaction - #${data.getId()}` : ""}
      subTitle={
        data ? (
          <Space style={{ textTransform: "lowercase" }}>
            <Tag
              icon={
                data.getStatus() === "pending" ? (
                  <SyncOutlined spin />
                ) : (
                  <CheckCircleOutlined />
                )
              }
              color={
                data.getStatus() === "pending"
                  ? "processing"
                  : data.getStatus() === "rejected"
                    ? "error"
                    : "success"
              }
            >
              {data.getStatus().replaceAll("-", " ")}
            </Tag>
          </Space>
        ) : (
          ""
        )
      }
      onBack={() => window.history.back()}
    >
      <div style={{ margin: "10px" }}>
        <Row gutter={[24, 24]} style={{ marginTop: "10px" }}>
          <Col xs={24} sm={24} md={12} lg={16}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card title="Transaction Details">
                  <Descriptions bordered column={1}>
                    <Descriptions.Item label={<b>Status</b>}>
                      <StatusTag
                        slug={data?.getStatus() as string}
                        text={data?.getStatus() as string}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label={<b>Transaction Souce</b>}>
                      {data?.getTransactionSource()}
                    </Descriptions.Item>
                    <Descriptions.Item label={<b>Amount</b>}>
                      {data?.getAmount()}
                    </Descriptions.Item>
                    <Descriptions.Item label={<b>Request Amount</b>}>
                      {data?.getRequestAmout()}
                    </Descriptions.Item>
                    <Descriptions.Item label={<b>Currency</b>}>
                      {data?.getCurrency()?.getName()}
                    </Descriptions.Item>
                    <Descriptions.Item label={<b>Region</b>}>
                      {data?.getRegion()?.name}
                    </Descriptions.Item>
                    <Descriptions.Item label={<b>Created At</b>}>
                      <Tag color="purple">
                        {formatDateTime(data?.getCreated())}
                      </Tag>
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              </Col>
              <Col span={24}>
                <Card title="Transaction Slip & Attachment">
                  {attachment && attachment.length > 0 ? (
                    <>
                      {data
                        ?.getAttachement()
                        ?.data.map((attachment, index) => (
                          <Image
                            key={index}
                            src={attachment.image.src}
                            style={{ height: "150px", maxWidth: "100%" }}
                          />
                        ))}
                    </>
                  ) : (
                    "No Transaction Slip & Attachment available yet."
                  )}
                </Card>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card title="Gateways">
                  <Descriptions bordered column={1}>
                    <Descriptions.Item label={<b>Bank Name</b>}>
                      {data?.getGateway()?.getName()}
                    </Descriptions.Item>
                    <Descriptions.Item label={<b>Type</b>}>
                      {data?.getGateway()?.getType()}
                    </Descriptions.Item>
                    <Descriptions.Item label={<b>Fee</b>}>
                      <Tag color="green">{formattedFee}</Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label={<b>Fee Type</b>}>
                      {data?.getGateway()?.getFeeType()}
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              </Col>
              <Col span={24}>
                <Card title="Invoice Details">
                  <Descriptions bordered column={1}>
                    {invoice && invoice.data.length > 0 ? (
                      <>
                        <Descriptions.Item label={<b>Invoice Number</b>}>
                          {data?.association
                            ?.getPaymentSession()
                            .getSessionInvoices()
                            ?.data[0]?.getInvoiceNumber()}
                        </Descriptions.Item>
                        <Descriptions.Item label={<b>Status</b>}>
                          <StatusTag
                            slug={
                              data?.association
                                ?.getPaymentSession()
                                .getSessionInvoices()
                                ?.data[0]?.getStatus() as string
                            }
                            text={
                              data?.association
                                ?.getPaymentSession()
                                .getSessionInvoices()
                                ?.data[0]?.getStatus() as string
                            }
                          />
                        </Descriptions.Item>
                        <Descriptions.Item label={<b>Total Amount</b>}>
                          {data?.association
                            ?.getPaymentSession()
                            .getSessionInvoices()
                            ?.data[0]?.getTotalAmount()}
                        </Descriptions.Item>
                        <Descriptions.Item label={<b>Initial Amount</b>}>
                          {data?.association
                            ?.getPaymentSession()
                            .getSessionInvoices()
                            ?.data[0]?.getInitialAmount()}
                        </Descriptions.Item>
                        <Descriptions.Item label={<b>Refundable Amount</b>}>
                          {data?.association
                            ?.getPaymentSession()
                            .getSessionInvoices()
                            ?.data[0]?.getRefundableAmount()}
                        </Descriptions.Item>
                      </>
                    ) : (
                      "No Invoice available yet."
                    )}
                  </Descriptions>
                  <Descriptions
                    style={{ marginTop: 10 }}
                    title="Transaction Summary"
                    bordered
                    column={1}
                  >
                    <Descriptions.Item label={<b>Paid</b>}>
                      {
                        data?.association
                          ?.getPaymentSession()
                          .getSessionInvoices()
                          ?.data[0]?.getTransactionSummary().paid.toFixed(2)
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<b>Due</b>}>
                      {
                        data?.association
                          ?.getPaymentSession()
                          .getSessionInvoices()
                          ?.data[0]?.getTransactionSummary().due.toFixed(2)
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<b>Over Paid</b>}>
                      {
                        data?.association
                          ?.getPaymentSession()
                          .getSessionInvoices()
                          ?.data[0]?.getTransactionSummary().overpaid.toFixed(2)
                      }
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </PageHeader>
  );
};

export default TransactionDetailSingle;
