import { Alert, Button, Form, Select, message } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { useAtom } from "jotai";
import React, { useEffect, useState, createRef } from "react";

import { exchangeGroupCollectionAtom } from "lib/core-react/store/store";
import {
  useAddExchangeGroupCustomer,
  useGetExchangeGroup,
} from "lib/core-react/hooks/private/useExchangeGroup";

import { ExchangeGroupCollectionModel } from "models/exchangeGroupCollectionModel";
import { IExchangeGroupUserRequest } from "types/exchangeGroupCollection";

import { renderOptionsFromEnum } from "components/Form/forms";
import { CustomerUserModel } from "models/customerUserCollectionModel";

interface IProps {
  setIsShowAddCustomerModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCustomerUser: CustomerUserModel;
}

const AddExchangeGroupCustomer = ({
  setIsShowAddCustomerModal,
  selectedCustomerUser,
}: IProps) => {
  const { getExchangeGroup } = useGetExchangeGroup();
  const [
    { data: exchangeGroupCollectionDataAtom, isLoading: exchangeGroupLoading },
  ] = useAtom(exchangeGroupCollectionAtom);

  const { addExchangeGroupCustomer, isLoading, isError } =
    useAddExchangeGroupCustomer();
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const formRef: React.Ref<FormInstance<any>> = createRef();
  const [form] = Form.useForm();

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [selectedCustomerUser, form]);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  useEffect(() => {
    getExchangeGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ExchangeGroupCollectionData =
    exchangeGroupCollectionDataAtom &&
    new ExchangeGroupCollectionModel(exchangeGroupCollectionDataAtom);

  const onFinish = async (value: IExchangeGroupUserRequest) => {
    const payload = {
      user_id: selectedCustomerUser.getId(),
      exchange_group_id: Number(value.exchange_group_id),
    };

    try {
      await addExchangeGroupCustomer(payload as IExchangeGroupUserRequest);
      setIsShowAddCustomerModal(false);
      setIsErrorVisible(false);
      message.success("Added customer to exchange group successfully");
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      layout="vertical"
      name="Add-Exchange-Group-Customer"
      ref={formRef}
    >
      <Form.Item
        rules={[{ required: true, message: "Exchange group is required" }]}
        name="exchange_group_id"
        label="Exchange Group"
      >
        <Select
          loading={exchangeGroupLoading}
          placeholder="Please select an exchange group"
        >
          {ExchangeGroupCollectionData?.getExchangeGroupIdsAsObject() &&
            renderOptionsFromEnum(
              ExchangeGroupCollectionData?.getExchangeGroupIdsAsObject(),
            )}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Submit
        </Button>
      </Form.Item>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isError}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </Form>
  );
};

export default AddExchangeGroupCustomer;
