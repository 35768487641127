import React, { useState, useEffect } from "react";
import {
  PlusOutlined,
  MinusCircleOutlined,
  HddFilled,
  DownOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import {
  Descriptions,
  Form,
  Col,
  Row,
  Spin,
  Input,
  Table,
  Switch,
  InputNumber,
  Button,
  Drawer,
  Menu,
  Dropdown,
  Tag,
  Divider,
  Select,
  message,
  Image,
} from "antd";
import ImageUpload from "components/ImageUpload";
import ImageGallery from "components/ImageGallery";
import ApprovedBuyingAgent from "../ApproveBuyingAgent";
import RejectConfirm from "pages/ShipForMe/Products/RejectConfirm/RejectConfirm";
import { getProductDetails } from "utils/buy-products";
import { useCreateOrder } from "context/create-order-context";
import { getShippingAgentCategory } from "utils/categories-client";
import { calculateShipping } from "utils/shipment-product-client";
import { FALLBACK_IMAGE_URL } from "consts/appConstants";
import "./ProductItem.css";
import Text from "antd/es/typography/Text.js";
import { formattedWithFloatAmount } from "utils/helpers";

const { Column } = Table;
const { Search } = Input;
const { Option } = Select;

const ProductItem = (Props) => {
  const { show, onHide, isEdit } = Props;
  const [form] = Form.useForm();
  const [imageEditChecked, setImageEditChecked] = useState(true);
  const [showApproveProduct, setShowApproveProduct] = useState(false);
  const [showRejectConfirm, setShowRejectConfirm] = useState(false);
  const [isCategoryEdit, setIsCategoryEdit] = useState(false);
  const [isFullEdit, setIsFullEdit] = useState(false);
  const {
    currentProduct,
    handleCurrentProduct,
    variations,
    selectedVariationKeys,
    setSelectedVariationKeys,
    handleAddToOrder,
    awCategory,
    setAwCategory,
    category,
    store,
    setStore,
    setCategory,
    storeList,
    calculateCommission,
    totalQuantity,
    setTotalQuantity,
  } = useCreateOrder();

  const [isLoading, setIsLoading] = useState(false);
  const [origin, setOrigin] = useState(null);
  const [unitType, setUnitType] = useState("kg");
  const [fetchAwCategories, setFetchAwCategories] = useState(false);

  let totalWeight = 1;

  if (currentProduct) {
    totalWeight = formattedWithFloatAmount(
      currentProduct.weight * (totalQuantity !== 0 ? totalQuantity : 1),
    );
  }
  const onSearch = (value, event) => {
    if (value === "") {
      return;
    }
    fetchItem(value);
  };

  const fetchItem = (url) => {
    setIsLoading(true);
    getProductDetails(url)
      .then((res) => {
        const resData = res.data;
        if (resData.code === 200) {
          handleCurrentProduct(resData.data);
        } else {
          handleCurrentProduct({
            vid: "",
            title: "",
            stock: 0,
            link: url,
            image: null,
            vendor: "",
            shop: null,
            store_id: null,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          message.error("The action is not authorized");
        } else {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.errors &&
            err.response.data.errors.url
          ) {
            message.error(err.response.data.errors.url);
          } else {
            message.error(err?.response?.data?.message);
          }
        }
      })
      .finally((x) => setIsLoading(false));
  };

  const onRowSelectChange = (keys) => {
    setSelectedVariationKeys(keys);
  };

  const rowSelection = {
    selectedRowKeys: selectedVariationKeys,
    onChange: onRowSelectChange,
  };

  useEffect(() => {
    if (currentProduct) {
      let prod = currentProduct;
      let fields = {
        title: prod.title,
        link: prod.link,
        vendor: prod.vendor,
        vid: prod.vid,
        image: prod.image || FALLBACK_IMAGE_URL,
        store_id: prod.store_id,
      };
      form.setFieldsValue(fields);
      let store = storeList.filter((item) => item.storeId === prod.store_id)[0];
      setStore(store);
    }
  }, [currentProduct, setStore, form, storeList]);

  useEffect(() => {
    let fields = {};
    if (store) {
      variations.forEach((variation, index) => {
        let price = variation.price;
        fields[`variations_${variation.uid}_qty`] = variation.qty;
        fields[`variations_${variation.uid}_price`] = price;
        fields[`variations_${variation.uid}_weight`] = variation.weight;
        fields[`variations_${variation.uid}_meta`] = variation.meta;
        fields[`variations_${variation.uid}_key`] = variation.key;
      });
      form.setFieldsValue(fields);
    }
  }, [variations, store, form]);

  const handleAwCategorySelect = (category) => {
    setAwCategory(category);
  };

  const updateResponse = (res) => {};

  const handleApproveResponse = (res) => {
    updateResponse([res.data]);
  };

  const handleRejectResponse = (res) => {
    updateResponse(res);
    setShowRejectConfirm(false);
  };

  const addToOrder = (data) => {
    if (!awCategory) {
      message.error("Please select a warehouse category first");
      return;
    }

    if (!store) {
      message.error("Please select a store first");
      return;
    }

    let changedVariations = [];

    if (!isFullEdit) {
      changedVariations = selectedVariationKeys.map((key) => {
        let filteredVariation = variations.filter((item) => item.uid === key);
        let variation =
          filteredVariation.length > 0 ? filteredVariation[0] : {};
        let price = parseFloat(data[`variations_${key}_price`]);
        let commission = calculateCommission(store.mvn_commission, price);
        variation.qty = data[`variations_${key}_qty`];
        variation.weight = data[`variations_${key}_weight`];
        variation.price = price + commission;
        return variation;
      });
    } else {
      changedVariations = data.metas.map((item) => {
        return {
          sku_id: Math.random().toString(36).substring(7),
          key: Math.random().toString(36).substring(7),
          qty: item.qty,
          weight: item.weight,
          price:
            item.price + calculateCommission(store.mvn_commission, item.price),
          v_price: item.price,
          meta: item.meta || [],
        };
      });
    }
    let prod = {};
    if (currentProduct) {
      prod = currentProduct;
    }
    prod.title = data.title;
    prod.image = data.image ? data.image : undefined;
    prod.link = data.link;
    prod.vendor = store?.name;
    prod.vid = data.vid;
    prod.aw_cat_id = awCategory.id;
    prod.category = category;
    prod.agentCategory = awCategory;
    prod.metaItems = changedVariations;
    prod.store_id = store?.storeId;
    prod.store = store;
    prod.weight = totalWeight;

    if (!prod.store_id) {
      prod.store_id = parseInt(data["store_id"]);
    }

    handleAddToOrder(prod);
    form.resetFields();

    setTimeout(() => {
      onHide();
    }, 200);
  };

  const handleUpload = (file) => {
    const imageURL = file.response.imageURL;
    form.setFieldsValue({ logo: imageURL });
  };

  const handleAwCategorySubmit = () => {
    if (origin) {
      setFetchAwCategories(true);
    }
  };

  const getDrawerWidth = () => {
    if (window.innerWidth > 1600) {
      return 1200;
    }
    if (window.innerWidth > 1400) {
      return 1000;
    } else if (window.innerWidth > 900) {
      return 700;
    } else if (window.innerWidth > 900) {
      return 700;
    }
    return window.innerWidth - 100;
  };

  return (
    <Drawer
      title={isEdit ? "Edit product" : "Add product"}
      width={getDrawerWidth()}
      onClose={onHide}
      open={show}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          rootStyle={{
            textAlign: "right",
          }}
        >
          <Button
            type="primary"
            onClick={() => form.submit()}
            rootStyle={{ marginRight: 8 }}
          >
            Save
          </Button>
          <Button onClick={() => onHide()} rootStyle={{ marginRight: 8 }}>
            Cancel
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        layout={"vertical"}
        name="register"
        onFinish={addToOrder}
        initialValues={{
          residence: ["zhejiang", "hangzhou", "xihu"],
          prefix: "86",
        }}
        scrollToFirstError
      >
        <Row>
          <Col span={24}>
            <Row>
              <Col span={22}>
                <Search
                  defaultValue={currentProduct ? currentProduct.link : ""}
                  allowClear
                  onSearch={onSearch}
                  placeholder="Copy & paste your desired product link here."
                  enterButton="Search"
                  size="large"
                  loading={isLoading}
                />
              </Col>
              <Col span={2}>
                <Button
                  type="dashed"
                  rootStyle={{ marginleft: "8px" }}
                  icon={<CloseCircleFilled />}
                  size={"64"}
                >
                  Clear
                </Button>
              </Col>
            </Row>
            <Row rootStyle={{ marginTop: 20 }}>
              <Col span={24}>
                {currentProduct && currentProduct.product_number && (
                  <Descriptions title="Product Info">
                    <Descriptions.Item label={"Product Number"}>
                      {currentProduct.product_number}
                    </Descriptions.Item>
                  </Descriptions>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[{ required: true }]}
                >
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Form.Item
                  name="link"
                  label="Link"
                  rules={[{ required: true }]}
                >
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </Row>
            {currentProduct ? (
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="image"
                    label={
                      <>
                        <label
                          htmlFor="register_image"
                          rootClassName="ant-form-item-required"
                          title="Image"
                          rootStyle={{
                            marginRight: 20,
                          }}
                        >
                          Image
                        </label>
                        <Switch
                          checkedChildren="Edit"
                          unCheckedChildren="View"
                          checked={imageEditChecked}
                          onChange={(checked, event) => {
                            setImageEditChecked(checked);
                          }}
                        />
                      </>
                    }
                    rules={[
                      { required: true, message: "Please enter image url" },
                    ]}
                  >
                    {!imageEditChecked ? (
                      <ImageUpload handleUpload={handleUpload} />
                    ) : (
                      <ImageGallery
                        images={[
                          {
                            imageURL: form.getFieldValue("image"),
                            thumbURL: form.getFieldValue("image"),
                          },
                        ]}
                        rootStyle={{
                          image: {
                            width: 100,
                            height: 100,
                          },
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="image"
                    label="Image link"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row rootStyle={{ marginBottom: 20 }} gutter={10}>
              <Col xxl={12} xl={12} span={24}>
                <Descriptions
                  column={1}
                  title={
                    <>
                      <div rootClassName="ant-descriptions-title">
                        <span rootStyle={{ marginRight: 10 }}>Category</span>
                        <Switch
                          checkedChildren="View"
                          unCheckedChildren="Edit"
                          checked={isCategoryEdit}
                          onChange={(checked, event) => {
                            setIsCategoryEdit(checked);
                          }}
                        />
                      </div>
                    </>
                  }
                >
                  {!isCategoryEdit ? (
                    category ? (
                      <>
                        <Descriptions.Item
                          label={
                            <span rootStyle={{ fontWeight: "bold" }}>Name</span>
                          }
                        >
                          {category.name}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={
                            <span rootStyle={{ fontWeight: "bold" }}>
                              Parent Category
                            </span>
                          }
                        >
                          {category.parent && category.parent.name
                            ? category.parent.name
                            : "N/A"}
                        </Descriptions.Item>
                      </>
                    ) : (
                      <p>
                        Category is not available, please add a category first
                      </p>
                    )
                  ) : (
                    <>
                      <Descriptions.Item>
                        <CategorySearch
                          handleCategorySelect={(category) => {
                            setCategory(category);
                          }}
                          rootStyle={{ marginTop: 20 }}
                        />
                      </Descriptions.Item>
                      <Descriptions.Item>
                        <Row gutter={4}>
                          <Col>
                            <Select
                              placeholder="Select origin"
                              onChange={(value) => {
                                setOrigin(value);
                              }}
                              rootStyle={{ width: "100px" }}
                            >
                              <Option value="CN">China</Option>
                              <Option value="US">USA</Option>
                              <Option value="IN">India</Option>
                              <Option value="AE">UAE</Option>
                            </Select>
                          </Col>
                          <Col>
                            <Select
                              placeholder="Select unit type"
                              onChange={(value) => {
                                setUnitType(value);
                              }}
                              rootStyle={{ width: "100px" }}
                            >
                              <Option value="kg">Kg</Option>
                              <Option value="pieces">Pieces</Option>
                            </Select>
                          </Col>
                        </Row>
                      </Descriptions.Item>

                      <Descriptions.Item>
                        <Button
                          type="primary"
                          size="small"
                          onClick={handleAwCategorySubmit}
                        >
                          Search
                        </Button>
                      </Descriptions.Item>
                      <Descriptions.Item>
                        <Text strong type="danger">
                          Total Weight: {totalWeight} KG
                        </Text>
                      </Descriptions.Item>
                    </>
                  )}
                </Descriptions>
                <Divider />
                {currentProduct && currentProduct.shop && (
                  <Descriptions title="Shop info" column={1}>
                    <Descriptions.Item
                      label={
                        <span rootStyle={{ fontWeight: "bold" }}>
                          Shop Name
                        </span>
                      }
                    >
                      {currentProduct.shop.name}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <span rootStyle={{ fontWeight: "bold" }}>
                          Shop Title
                        </span>
                      }
                    >
                      {currentProduct.shop.identifier}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <span rootStyle={{ fontWeight: "bold" }}>Fx Rate</span>
                      }
                    >
                      {currentProduct.shop.currency_code +
                        " " +
                        currentProduct.shop.fx}
                    </Descriptions.Item>
                  </Descriptions>
                )}
              </Col>

              <Col xxl={12} xl={12} span={24}>
                <Descriptions title="Category shipping cost" />
                <ShippingInfo
                  category={category}
                  awCategory={awCategory}
                  origin={origin}
                  unit_type={unitType}
                  fetchAwCategories={fetchAwCategories}
                  handleCategorySelect={handleAwCategorySelect}
                  totalWeight={totalWeight}
                />
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Form.Item name="vid" label="Vid">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="store_id" label="Store">
                  <StoreSelect
                    stores={storeList}
                    currentStore={store}
                    handleStoreSelect={(store) => setStore(store)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row>
              {currentProduct && currentProduct.agentCompany && (
                <Col span={12}>
                  <Descriptions title="Agent Company">
                    <>
                      <Descriptions.Item label="Name">
                        {currentProduct.agentCompany?.name}
                      </Descriptions.Item>
                      <Descriptions.Item label="IAddress">
                        {currentProduct.agentCompany?.address}
                      </Descriptions.Item>
                      <Descriptions.Item label="Status">
                        <Tag color={"success"}>
                          {currentProduct.agentCompany?.is_active
                            ? "Active"
                            : "Inactive"}
                        </Tag>
                      </Descriptions.Item>
                    </>
                  </Descriptions>
                </Col>
              )}
              {currentProduct && currentProduct.id && (
                <Col span={12}>
                  <Descriptions title="Change Agent Company" />
                  <Dropdown
                    overlay={
                      <Menu>
                        {
                          <Menu.Item
                            onClick={() => setShowApproveProduct(true)}
                          >
                            Approve
                          </Menu.Item>
                        }
                        {
                          <Menu.Item
                            onClick={() => {
                              setShowRejectConfirm(true);
                            }}
                          >
                            Reject
                          </Menu.Item>
                        }
                      </Menu>
                    }
                    placement="bottomCenter"
                  >
                    <Button icon={<HddFilled />}>
                      Actions <DownOutlined />
                    </Button>
                  </Dropdown>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Divider />
        {/*<Descriptions title="Price">*/}
        {/*    <Descriptions.Item label="Item price">৳ {totalPrice}</Descriptions.Item>*/}
        {/*    <Descriptions.Item label="Moveon Commission">৳ {shipRate}</Descriptions.Item>*/}
        {/*    <Descriptions.Item label="Total">৳ {shipRate+totalPrice}</Descriptions.Item>*/}
        {/*</Descriptions>*/}

        <Row>
          <Col span={24}>
            <Descriptions
              title={
                <>
                  <div rootClassName="ant-descriptions-title">
                    <span rootStyle={{ marginRight: 10 }}>
                      Product Properties
                    </span>
                    <Switch
                      checkedChildren="Basic Edit"
                      unCheckedChildren="Full Edit"
                      checked={isFullEdit}
                      onChange={(checked, event) => {
                        setIsFullEdit(checked);
                      }}
                    />
                  </div>
                </>
              }
            />
            {isFullEdit ? (
              <FullEdit form={form} />
            ) : (
              <BasicEdit
                currentProduct={currentProduct}
                variations={variations}
                rowSelection={rowSelection}
                form={form}
                setTotalQuantity={setTotalQuantity}
              />
            )}
          </Col>
        </Row>
      </Form>
      {currentProduct && currentProduct.id && (
        <ApprovedBuyingAgent
          product={currentProduct}
          show={showApproveProduct}
          onHide={() => setShowApproveProduct(false)}
          handleResponse={handleApproveResponse}
        />
      )}
      {currentProduct && currentProduct.id && (
        <RejectConfirm
          show={showRejectConfirm}
          onHide={() => setShowRejectConfirm(false)}
          products={[currentProduct]}
          handleUpdateProducts={handleRejectResponse}
        />
      )}
    </Drawer>
  );
};

const BasicEdit = (Props) => {
  const { form, setTotalQuantity } = Props;
  const [variations, setVariations] = useState([]);
  const [rowSelection, setRowCollection] = useState([]);
  const [count, setCount] = useState(1);

  useEffect(() => {
    setVariations(Props.variations);
    setRowCollection(Props.rowSelection);
  }, [Props]);

  const getTotalPrice = (price, quantity) => {
    let p = parseFloat(price);
    let q = parseFloat(quantity);
    return p * q;
  };

  let result = "";

  result = variations.filter((o1) =>
    rowSelection.selectedRowKeys.some((o2) => o1.uid === o2),
  );

  const handleUpdatePrice = (value, record, tag) => {
    if (tag === "qty") {
      result.filter((items) => {
        if (items.uid === record.uid) {
          items.qty = value;
        }
        return result;
      });
    }

    if (tag === "price") {
      result.filter((items) => {
        if (items.uid === record.uid) {
          items.price = value;
        }
        return result;
      });
    }
  };

  const totalQuantity = result.reduce((acc, value) => {
    if (value.qty === "") {
      value = 0;
    } else {
      value = parseInt(value.qty);
    }
    return acc + value;
  }, 0);

  useEffect(() => {
    setTotalQuantity(totalQuantity);
  }, [setTotalQuantity, totalQuantity]);

  const totalPrice = result.reduce((acc, value) => {
    if (value.price === "") {
      value = 0;
    } else {
      value = parseFloat(value.price);
    }
    return acc + value;
  }, 0);

  return (
    <Table
      dataSource={variations}
      bordered={true}
      rowKey="uid"
      pagination={false}
      rowSelection={rowSelection}
      summary={(pageData) => {
        return (
          <Table.Summary.Row>
            <Table.Summary.Cell colSpan={4}>Total</Table.Summary.Cell>
            <Table.Summary.Cell colSpan={2}>
              <span>{totalQuantity}</span>
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              <span>{totalQuantity * totalPrice}</span>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        );
      }}
    >
      <Column
        title="Sku"
        dataIndex="meta"
        key="Meta"
        align={"center"}
        width={"30%"}
        render={(meta, row, index) => (
          <div className={"product-info"}>
            {meta &&
              meta.map((item, index) => {
                return `${item.value} ${index !== meta.length - 1 ? "/ " : ""}`;
              })}
          </div>
        )}
      />
      <Column
        title="Meta"
        dataIndex="meta"
        key="Meta"
        align={"center"}
        width={"30%"}
        render={(meta, row, index) => (
          /*<MetaFields
                        skuId={uid}
                        meta={row}
                    />*/
          <div className={"product-metas"}>
            {meta &&
              meta.map((item, index) => {
                return (
                  <Row key={"manual-order-meta-item-" + index}>
                    <Col span={12}>
                      <span style={{ fontWeight: "bold" }}>{item.title}</span>:
                      &nbsp; {item.label || item.value}
                    </Col>
                    {item?.thumb && (
                      <Col span={12}>
                        <Image width={50} src={item?.thumb} />
                      </Col>
                    )}
                  </Row>
                );
              })}
          </div>
        )}
      />
      <Column title="Stock" dataIndex="stock" key="stock" align={"center"} />
      <Column
        title="Quantity"
        dataIndex="uid"
        key="qty"
        align={"center"}
        render={(uid, row, index) => (
          <Form.Item
            name={`variations_${uid}_qty`}
            label={<></>}
            rules={[
              {
                validator: (_, value) => {
                  if (value > 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Quantity cannot be less than 1");
                },
              },
            ]}
          >
            <InputNumber
              onChange={(e) => {
                setCount(count + 1);
                handleUpdatePrice(e, row, "qty");
              }}
            />
          </Form.Item>
        )}
      />
      <Column
        title="Unit Price"
        dataIndex="uid"
        key="price"
        align={"center"}
        render={(uid, row, index) => (
          <Form.Item name={`variations_${uid}_price`} label={<></>}>
            <InputNumber
              onChange={(e) => {
                handleUpdatePrice(e, row, "price");
              }}
            />
          </Form.Item>
        )}
      />
      <Column
        title="Total Price"
        dataIndex="uid"
        key="price"
        align={"center"}
        render={(uid, row, index) => (
          <InputNumber
            defaultValue={1000}
            value={getTotalPrice(
              form.getFieldValue(`variations_${uid}_price`),
              form.getFieldValue(`variations_${uid}_qty`),
            )}
            readOnly
          />
        )}
      />
    </Table>
  );
};

const FullEdit = () => {
  return (
    <Form.List name="metas">
      {(fields, { add, remove }) => (
        <>
          <Table dataSource={fields} bordered={true} pagination={false}>
            <Column
              title="#SL"
              dataIndex="id"
              key="SL"
              align={"center"}
              render={(id, row, index) => index + 1}
            />
            <Column
              title="Meta"
              dataIndex="name"
              key="Meta"
              align={"center"}
              render={(name, row, index) => <MetaFields meta={row} />}
            />
            <Column
              title="Quantity"
              dataIndex="qty"
              key="qty"
              align={"center"}
              render={(id, row, index) => (
                <Form.Item
                  {...row}
                  name={[row.name, "qty"]}
                  fieldKey={[row.fieldKey, "qty"]}
                  label={<></>}
                >
                  <InputNumber />
                </Form.Item>
              )}
            />
            <Column
              title="Unit Price (In store currency)"
              dataIndex="price"
              key="price"
              align={"center"}
              render={(id, row, index) => (
                <Form.Item
                  {...row}
                  name={[row.name, "price"]}
                  fieldKey={[row.fieldKey, "price"]}
                  label={<></>}
                >
                  <InputNumber />
                </Form.Item>
              )}
            />

            <Column
              title="Remove"
              dataIndex="meta"
              key="meta"
              align={"center"}
              render={(id, row, index) => (
                <MinusCircleOutlined
                  className="dynamic-delete-button"
                  onClick={() => remove(index)}
                />
              )}
            />
          </Table>
          <Form.Item>
            <Button
              type="primary"
              onClick={() => add()}
              style={{ width: "10%", marginTop: 10 }}
              icon={<PlusOutlined />}
            >
              Add
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

const CategorySearch = ({ handleCategorySelect }) => {
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState([]);

  const fetchCategories = (value) => {
    setData([]);
    setFetching(true);
    getShippingAgentCategory(value)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {})
      .finally((x) => {
        setFetching(false);
      });
  };

  const handleChange = (value) => {
    setFetching(false);
    let category = data.find((item) => item.id === value);
    handleCategorySelect(category);
  };

  return (
    <Select
      showSearch
      placeholder="Select a category"
      notFoundContent={fetching ? <Spin size="small" /> : null}
      filterOption={false}
      onSearch={fetchCategories}
      onSelect={handleChange}
      style={{ width: "100%" }}
    >
      {data.map((d, index) => (
        <Option key={index} value={d.id}>
          {d.name}
        </Option>
      ))}
    </Select>
  );
};

const ShippingInfo = (Props) => {
  const { unit_type, origin, fetchAwCategories, totalWeight } = Props;
  const [category, setCategory] = useState(Props.category);
  const [awCategory, setAwCategory] = useState(null);
  const [shippingList, setShippingList] = useState([]);
  const [selectedShipping, setSelectedShipping] = useState([]);
  const [meta, setMeta] = useState(null);
  const [awCategoryList, setAwCategoryList] = useState([]);

  useEffect(() => {
    setCategory(Props.category);
    setAwCategory(Props.awCategory);
  }, [Props]);

  const fetchAwCategory = React.useCallback(
    (category, page) => {
      calculateShipping({
        unit_type: unit_type,
        shipping_type: "air",
        category_id: category?.id,
        p_origin: origin,
        page: page,
      })
        .then((res) => {
          setMeta(res.data.meta);
          setAwCategoryList(res.data.data);
          let data = res.data.data.map((calculator, index) => {
            let item = {};
            let shipRate = calculator.rate;
            let rqWeight = totalWeight ? totalWeight : 0;
            if (calculator.categoryPriceSlots.length > 0) {
              for (let i = 0; i < calculator.categoryPriceSlots.length; i++) {
                const element = calculator.categoryPriceSlots[i];

                if (
                  rqWeight >= parseFloat(element.min_amount) &&
                  rqWeight <= parseFloat(element.max_amount)
                ) {
                  shipRate = element.rate_per_unit;
                  break;
                }
              }
            }

            if (calculator.commission) {
              shipRate = shipRate + shipRate * (calculator.commission / 100);
            }

            let categoryPriceSlot = calculator.categoryPriceSlots.map((el) => {
              if (calculator.commission) {
                let shippingRateLabel = "Not Found";
                if (
                  rqWeight >= parseFloat(el.min_amount) &&
                  rqWeight <= parseFloat(el.max_amount)
                ) {
                  shippingRateLabel = "Found";
                }
                el.rate_per_unit =
                  el.rate_per_unit +
                  el.rate_per_unit * (calculator.commission / 100);
                el.delivery_item = calculator?.warehouse.est_sh_time;
                return {
                  ...el,
                  label: shippingRateLabel,
                };
              } else {
                el.delivery_item = calculator?.warehouse.est_sh_time;
                return el;
              }
            });

            item.name = calculator?.warehouse?.company?.name;
            item.rate = ` ${shipRate} / ${calculator.unit_type}`;
            item.delivery_item = calculator?.warehouse.est_sh_time;

            if (awCategory && awCategory.id === calculator.id) {
              setSelectedShipping([calculator.id]);
            }

            item.index = index;
            item.key = index;
            item.id = calculator.id;
            item.categoryPriceSlots = categoryPriceSlot;
            return item;
          });
          setShippingList(data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    [awCategory, origin, totalWeight, unit_type],
  );

  useEffect(() => {
    if (category && fetchAwCategories) {
      fetchAwCategory(category, 1);
    }
  }, [category, fetchAwCategory, fetchAwCategories]);

  const onRowSelectChange = (keys, rows) => {
    setSelectedShipping(keys);
    if (rows.length > 0) {
      let awCategory = awCategoryList.filter(
        (item) => item.id === rows[0].id,
      )[0];
      Props.handleCategorySelect(awCategory);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedShipping,
    onChange: onRowSelectChange,
  };

  const handlePageChange = (page) => {
    fetchAwCategory(category, page);
  };

  const paginationConfig = meta
    ? {
        total: meta.total,
        current: meta.current_page,
        onChange: handlePageChange,
        pageSize: meta.per_page,
        position: ["none", "bottomLeft"],
      }
    : false;

  return (
    <Table
      dataSource={shippingList}
      bordered={true}
      rowKey="id"
      pagination={paginationConfig}
      rowSelection={{
        type: "radio",
        ...rowSelection,
      }}
      expandable={{
        expandedRowRender: (record) => (
          <>
            {record?.categoryPriceSlots?.length > 0 && (
              <Table
                dataSource={record?.categoryPriceSlots}
                bordered={true}
                rowKey="id"
                pagination={false}
              >
                <Column
                  title="#SL"
                  dataIndex="id"
                  key="SL"
                  align={"center"}
                  render={(key, record, index) => (
                    <>
                      {record.label === "Found" ? (
                        <Tag color="success">Active</Tag>
                      ) : (
                        <span>{index + 1}</span>
                      )}
                    </>
                  )}
                />
                <Column
                  title="Slot"
                  dataIndex="slot"
                  key="slot"
                  align={"center"}
                  render={(key, record) => (
                    <Text strong>
                      {record.min_amount} - {record.max_amount}
                    </Text>
                  )}
                />
                <Column
                  title="Rate"
                  dataIndex="rate"
                  key="rate"
                  align={"center"}
                  render={(key, record) => (
                    <Text type="danger">{record.rate_per_unit}</Text>
                  )}
                />
                <Column
                  title="Delivery Time"
                  dataIndex="delivery_item"
                  key="delivery_item"
                  align={"center"}
                  render={(key, record) => (
                    <Text type="success">{record.delivery_item}</Text>
                  )}
                />
              </Table>
            )}
          </>
        ),
        rowExpandable: (record) => record?.categoryPriceSlots?.length > 0,
      }}
    >
      <Column
        title="Shipping"
        dataIndex="name"
        key="shipping"
        align={"center"}
      />
      <Column title="Cost" dataIndex="rate" key="qty" align={"center"} />
      <Column
        title="Delivery Time"
        dataIndex="delivery_item"
        key="delivery_item"
        align={"center"}
      />
    </Table>
  );
};

const StoreSelect = ({ stores, currentStore, handleStoreSelect }) => {
  const [fetching, setFetching] = useState(false);

  const handleChange = (value) => {
    setFetching(false);
    let val = stores.filter((item) => item.storeId === value);
    handleStoreSelect(val[0]);
  };

  return (
    <Select
      placeholder="Select Store"
      notFoundContent={fetching ? <Spin size="small" /> : null}
      filterOption={false}
      onSelect={handleChange}
      defaultValue={currentStore?.storeId}
      style={{ width: "100%" }}
    >
      {stores.map((d, index) => (
        <Option key={index} value={d.storeId}>
          {d.name}
        </Option>
      ))}
    </Select>
  );
};

const MetaFields = (Props) => {
  const [meta, setMeta] = useState(Props.meta);

  useEffect(() => {
    setMeta(Props.meta);
  }, [Props]);

  return (
    <Form.List name={[meta?.name, "meta"]}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <>
              <Row style={{ marginBottom: 5 }}>
                <Col span={11}>
                  <Form.Item
                    {...field}
                    name={[field.name, "title"]}
                    fieldKey={[field.fieldKey, "title"]}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message:
                          "Please input passenger's name or delete this field.",
                      },
                    ]}
                    noStyle
                  >
                    <Input placeholder="Key" style={{ width: "90%" }} />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    {...field}
                    name={[field.name, "value"]}
                    fieldKey={[field.fieldKey, "value"]}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message:
                          "Please input passenger's name or delete this field.",
                      },
                    ]}
                    noStyle
                  >
                    <Input placeholder="Key" style={{ width: "90%" }} />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item {...field} noStyle>
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(index)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ))}
          <Form.Item>
            <Button
              type="primary"
              onClick={() => add()}
              style={{ width: "50%", margin: 10, float: "right" }}
              icon={<PlusOutlined />}
            >
              Add
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default ProductItem;
