import { useEffect, useState } from "react";
import { Alert, Button, Form, Input, message } from "antd";

import {
  useDestinationWarehouseRoleCreate,
  useGetRole,
} from "lib/core-react/hooks/private";
import { IDestinationWarehouseRoleForm } from "types/roleCollection";

interface IProps {
  destinationWarehouseId: number;
  onHide: () => void;
}

export const AddRole = ({ destinationWarehouseId, onHide }: IProps) => {
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const {
    createDestinationWarehouseRole,
    isLoading: isLoadingSend,
    isError,
  } = useDestinationWarehouseRoleCreate();
  const { getRole } = useGetRole();

  const [form] = Form.useForm<IDestinationWarehouseRoleForm>();

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationWarehouseId]);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const onFinishInvite = async (value: IDestinationWarehouseRoleForm) => {
    try {
      if (destinationWarehouseId) {
        const payload: IDestinationWarehouseRoleForm = {
          destination_warehouse_id: destinationWarehouseId,
          label: value.label,
          name: value.name,
        };
        await createDestinationWarehouseRole(payload);
        await getRole(`destination_warehouse_id=${destinationWarehouseId}`);
        message.success("Role successfully created");
        onHide();
        setIsErrorVisible(false);
      }
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  return (
    <Form
      onFinish={onFinishInvite}
      form={form}
      layout="vertical"
      name="Add Role"
    >
      <Form.Item
        rules={[
          {
            required: true,
            message: "Name is required",
          },
        ]}
        label="Name"
        name="name"
      >
        <Input placeholder="Enter name" />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Label is required" }]}
        label="Label"
        name="label"
      >
        <Input placeholder="Enter label" />
      </Form.Item>

      <Form.Item>
        <Button
          loading={isLoadingSend}
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Submit
        </Button>
      </Form.Item>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isError}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </Form>
  );
};
