import React, {useState, useEffect} from 'react'
import {
    Table, Row, Col, Divider, Input, Select, Spin, Button, message, Descriptions
} from 'antd'
import { getProductDetails } from "utils/buy-products"
import { useCreateOrder } from "context/create-order-context"
import {getShippingAgentCategory} from 'utils/categories-client'
import {getStoreList} from 'utils/store-client'
import ImageGallery from "../ImageGallery";
import {CloseCircleFilled} from "@ant-design/icons"

const { Search } = Input
const { Option } = Select

const CategorySearch = ({handleCategorySelect}) => {
    const [ fetching, setFetching ] = useState(false);
    const [ data, setData ] = useState([]);

    const fetchCategories = value => {
        setData([]);
        setFetching(true);
        getShippingAgentCategory(value).then(res => {
            let result = res.data.data.map(el => {
                return {
                    name: el.name, value: el.id,
                }
            });
            setData(result);
        }).catch(error => {

        }).finally(x => {
            setFetching(false);
        })
    };

    const handleChange = value => {
        setData([]);
        setFetching(false);
        handleCategorySelect(data.filter(item => item.value === value)[0])
    };

    return(
        <Select
            showSearch
            placeholder="Select Aw Category"
            notFoundContent={fetching ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={fetchCategories}
            onSelect={handleChange}
            style={{ width: '100%' }}
        >
            {data.map((d,index) => (
                <Option key={index} value={d.value}>{d.name}</Option>
            ))}
        </Select>
    );
}

const StoreSelect = ({handleStoreSelect}) => {
    const [ fetching, setFetching ] = useState(false);
    const [ data, setData ] = useState([]);

    useEffect(()=>{
        setData([]);
        setFetching(true);
        getStoreList().then(res => {
            let result = res.data.data.map(el => {
                return {
                    name: el.name, value: el.id,
                }
            });
            setData(result);
        }).catch(error => {

        }).finally(x => {
            setFetching(false);
        })
    },[])

    const handleChange = value => {
        setData([]);
        setFetching(false);
        let val = data.filter(item => item.value === value);
        handleStoreSelect(val[0])
    };

    return(
        <Select
            placeholder="Select Store"
            notFoundContent={fetching ? <Spin size="small" /> : null}
            filterOption={false}
            onSelect={handleChange}
            style={{ width: '100%' }}
        >
            {data.map((d,index) => (
                <Option key={index} value={d.value}>{d.name}</Option>
            ))}
        </Select>
    );
}
const AddProductItem = Props => {
    const {
        currentProduct,
        handleCurrentProduct,
        variations,
        getVariationProp,
        selectedVariations,
        setSelectedVariations,
        handleAddToOrder,
        awCategory,
        store,
        setAwCategory,
        setStore
    } = useCreateOrder()

    const [isLoading, setIsLoading] = useState(false)


    const onSearch = (value, event) => {
        if(value === ""){
            return;
        }
        fetchItem(value);
    }

    const fetchItem = (url) => {
        setIsLoading(true)
        getProductDetails(url)
            .then(res => {
                const resData = res.data
                if (resData.code === 200) {
                    handleCurrentProduct(resData.data)

                }
                setIsLoading(false)
            }).catch(err => {
                if (err.response && err.response.status === 403) {
                    message.error("The action is not authorized")
                } else {
                    if(err && err.response && err.response.data && err.response.data.errors && err.response.data.errors.url){
                        message.error(err.response.data.errors.url)
                    }else{
                        message.error(err?.response?.data?.message)
                    }

                }
            }).finally(x => setIsLoading(false))
    }

    const onRowSelectChange = (keys, rows) => {
        setSelectedVariations(rows)
    }

    const rowSelection = {
        selectedVariations,
        onChange: onRowSelectChange,
    }

    const columns = [
        {
            title: 'Props',
            dataIndex: 'props',
            key: 'props',
            width: '20%',
            render: (props) => {
                let propText = getVariationProp(props)
                return propText
            }
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (_, record) => {
                return record?.price?.offer ? record.price.offer : record.price.actual
            }
        },
        {
            title: 'Stock',
            dataIndex: 'stock',
            key: 'stock',
            render: (_, record) => {
                return record?.stock?.available ? `${record.stock.available} in stock` : null
            }
        },
    ]

    // const onFinish = (values) => {
    //     fetchItem(values.url)
    // }

    const addToOrder = () => {
        if(!currentProduct){
            message.error("No product found");
            return;
        }
        if(!awCategory){
            message.error("Please select a warehouse category first");
            return;
        }
        if(!store){
            message.error("Please select a store first");
            return;
        }

        handleAddToOrder();
        setTimeout(()=>{
            Props.handleClose()
        },400)
    }

    return (
        <div>
                <Row>
                    <Col span={22}>
                        <Search allowClear onSearch={onSearch} placeholder="Copy & paste your desired product link here." enterButton="Search" size="large" loading={isLoading} />
                    </Col>
                    <Col span={2}>
                        <Button
                            type="dashed"
                            style={{ marginleft: '8px' }}
                            onClick={()=>handleCurrentProduct(null)}
                            icon={<CloseCircleFilled/>}
                            size={"64"}
                        >Clear</Button>
                    </Col>
                </Row>
                {
                    currentProduct &&
                    (
                        <Row style={{marginTop: 20}}>
                            <Col >
                                <Descriptions title="Product Info">
                                    <Descriptions.Item label={<span style={{fontWeight: "bold"}}>Title</span>}>{currentProduct.title}</Descriptions.Item>
                                    <Descriptions.Item label={<span style={{fontWeight: "bold"}}>Vid</span>}>{currentProduct.vid}</Descriptions.Item>
                                    <Descriptions.Item label={<span style={{fontWeight: "bold"}}>Stock</span>}>{currentProduct.stock}</Descriptions.Item>
                                    <Descriptions.Item>
                                        <ImageGallery
                                            images={
                                                [{
                                                    imageURL: currentProduct.image,
                                                    thumbURL: currentProduct.image
                                                }]
                                            }
                                            style={{
                                                image:{
                                                    width: 100,
                                                    height: 100
                                                }
                                            }}
                                        />
                                    </Descriptions.Item>
                                    <Descriptions.Item label={<span style={{fontWeight: "bold"}}>Category</span>}>{(currentProduct.categories && currentProduct.categories.length ? currentProduct.categories[0].name : "N/A")}</Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                    )
                }
                <Divider dashed />
                <Row style={{marginBottom: 20}}>
                    <Col span={10}>
                        <span style={{fontWeight: "bold", marginBottom: 10}}>Select warehouse category</span>
                        <CategorySearch
                            handleCategorySelect={category => setAwCategory(category)}
                        />
                    </Col>
                    <Col span={4}>
                        &nbsp;
                    </Col>
                    <Col span={10}>
                        <span style={{fontWeight: "bold", marginBottom: 10}}>Select Store</span>
                        <StoreSelect
                            handleStoreSelect={store => setStore(store)}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Table
                            dataSource={variations.skus}
                            columns={columns}
                            loading={isLoading}
                            rowKey="key"
                            pagination={false}
                            rowSelection={rowSelection}
                            scroll={{ y: 360 }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Button
                            type="primary"
                            style={{ marginTop: '15px' }}
                            onClick={addToOrder}
                        >Add to order</Button>
                    </Col>
                </Row>
        </div>
    )
}

export default AddProductItem
