/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { Button, Form, Row } from "antd";
import { ICustomer } from "types/customerCollection";
import { useGetCustomers } from "lib/core-react/hooks/private/useBulkUser";
import { bulkUserCustomer } from "lib/core-react/store/store";
import { useAtomValue } from "jotai";
import { BulkUserCollectionModel } from "models/bulkUserCollectionModel";
import CustomerSelect from "components/shared/CustomerSelect";
import Product from "./OrderCreate/Product";

const CreateOrderForCustomer = () => {
  const [selectedCustomer, setSelectedCustomer] = useState<
    ICustomer | undefined
  >(undefined);
  console.log(selectedCustomer);
  // Customer
  const { getCustomers } = useGetCustomers();
  const { data: bulkUserCustomerData, isLoading: bulkUserCustomerDataLoading } =
    useAtomValue(bulkUserCustomer);

  const customerData =
    bulkUserCustomerData && new BulkUserCollectionModel(bulkUserCustomerData);

  // useEffect(() => {
  //   getCustomers("per_page=200");
  // }, []);

  const handleCustomerSelect = (customer: ICustomer) => {
    setSelectedCustomer(customer);
  };

  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    console.log("Received values of form:", values);
  };

  const handleChange = () => {
    form.setFieldsValue({ sights: [] });
  };

  return (
    <div>
      <PageHeader
        style={{ marginTop: "10px" }}
        ghost={false}
        title="Create Order for Customer"
        onBack={() => window.history.back()}
      >
        Not implemented yet
        {/* <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          form={form}
          name="dynamic_form_complex"
          onFinish={onFinish}
          // style={{ maxWidth: 600 }}
          autoComplete="off"
          initialValues={{ products: [{ packages: [{}] }] }}
        >
          <Row>
            <div className="label">
              <sup style={{ color: "red", fontSize: "12px" }}>* </sup>Select
              customer
            </div>
            <div
              style={{ width: "100%", marginBottom: "20px", marginTop: "10px" }}
            >
              <CustomerSelect handleCustomerSelect={handleCustomerSelect} />
            </div>
          </Row>

          <Form.List name="products">
            {(fields, { add, remove }) => (
              <div
              // style={{ display: "flex", rowGap: 16, flexDirection: "column" }}
              >
                <Row gutter={[20, 20]}>
                  {fields.map((field, index) => (
                    <Product
                      index={index}
                      key={field.key}
                      productRemove={remove}
                      customerId={
                        selectedCustomer ? selectedCustomer.id : undefined
                      }
                      field={field}
                    />
                  ))}
                </Row>

                <Button
                  type="dashed"
                  onClick={() => add()}
                  className=" my-2"
                  block
                >
                  + Add More Product
                </Button>
              </div>
            )}
          </Form.List>

          <Form.Item>
            <Button type="primary" className="my-2" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form> */}
      </PageHeader>
    </div>
  );
};

export default CreateOrderForCustomer;
