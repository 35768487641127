import { useAtom } from "jotai";
import { useService } from "lib/core-react/contexts";
import { useState } from "react";
import {
  IBuyPlaceOrderCartFormData,
  IDeleteCartProduct,
} from "types/buyOrderPlace";
import { getError } from "../utils/errors";
import {
  buyOrderPlaceAtom,
  purchaseCartCollectionAtom,
} from "lib/core-react/store/store";

export const useGetCartList = () => {
  const [purchaseCartCollectionAtomData, setPurchaseCartCollectionAtomData] =
    useAtom(purchaseCartCollectionAtom);

  const { purchaseCartService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");

  const getCartList = async (user_id: number) => {
    setIsLoading(true);
    try {
      const response =
        await purchaseCartService.purchaseCartResource.get(user_id);
      setIsLoading(false);
      setPurchaseCartCollectionAtomData({
        ...purchaseCartCollectionAtomData,
        refetch: false,
        isLoading: false,
        data: response,
      });
      return response;
    } catch (error: any) {
      setPurchaseCartCollectionAtomData({
        ...purchaseCartCollectionAtomData,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
    }
  };

  return { getCartList, isLoading, isError };
};

export const usePlaceOrderCartProduct = () => {
  const [buyOrderPlaceAtomData, setByOrderPlaceAtomData] =
    useAtom(buyOrderPlaceAtom);

  const { purchaseCartService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");

  const cartProduct = async (
    region: string,
    locale: string,
    payload: IBuyPlaceOrderCartFormData,
  ) => {
    setIsLoading(true);
    try {
      const response =
        await purchaseCartService.purchaseCartResource.cartProduct(
          region,
          locale,
          payload,
        );
      setIsLoading(false);
      setByOrderPlaceAtomData({
        ...buyOrderPlaceAtomData,
        refetch: true,
      });
      return response;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
    }
  };

  return { cartProduct, isLoading, isError };
};

export const useDeleteCartProduct = () => {
  const [purchaseCartCollectionAtomData, setPurchaseCartCollectionAtomData] =
    useAtom(purchaseCartCollectionAtom);

  const { purchaseCartService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");

  const deleteCartProduct = async (
    region: string,
    locale: string,
    payload: IDeleteCartProduct,
  ) => {
    setIsLoading(true);
    try {
      const response =
        await purchaseCartService.purchaseCartResource.cartDeleteProduct(
          region,
          locale,
          payload,
        );
      setIsLoading(false);
      setPurchaseCartCollectionAtomData({
        ...purchaseCartCollectionAtomData,
        refetch: false,
        isLoading: false,
        data: response,
      });
      return response;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
    }
  };

  return { deleteCartProduct, isLoading, isError };
};
