import { Avatar, Space, Table, Typography } from "antd";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import { WishlistItemModel } from "models/wishlistCollectionModel";
import { Link, useNavigate } from "react-router-dom";

interface IProps {
  data: WishlistItemModel[];
}
const WishlistItemTable = ({ data }: IProps) => {
  const { isMobile } = useWindowWidth();
  const navigate = useNavigate();

  const handleOnClickDetails = (url) => {
    navigate(`/product-manage/products/product_detail/find?productUrl=${url}`);
  };
  const wishlistItemColumns = [
    {
      title: "Product Title",
      dataIndex: "product_title",
      hidden: isMobile,
      key: "product_title",
      render: (_: string, record: WishlistItemModel) => {
        const productUrl = record.getProductLink();
        const id = record.id;
        return (
          <Space>
            <Avatar
              shape="square"
              size="large"
              src={record.getProductImage()}
            />
            <Link
              to={`/product-manage/products/product_detail/find?userId=${id}&productUrl=${productUrl}`}
            >
              {record.getTitle()}
            </Link>
          </Space>
        );
      },
    },
    {
      title: "Product Title",
      dataIndex: "product_title",
      hidden: !isMobile,
      key: "product_title",
      render: (_: string, record: WishlistItemModel) => {
        return (
          <div>
            <Space>
              <Avatar
                shape="square"
                size="large"
                src={record.getProductImage()}
              />
              <a href={record.getProductLink()}>{record.getTitle()}</a>
            </Space>
            <br />
            <Space direction="vertical">
              <Space direction="horizontal" style={{ whiteSpace: "nowrap" }}>
                <Typography.Text strong> Original:</Typography.Text>
                {record.getProductMaxOriginal()}-
                {record.getProductMinOriginal()}
              </Space>
              <Space direction="horizontal" style={{ whiteSpace: "nowrap" }}>
                <Typography.Text strong>Discount: </Typography.Text>
                {record.getProductMaxDiscount()}-
                {record.getProductMinDiscount()}
              </Space>
            </Space>
            <br />
            <Space direction="vertical">
              <Space direction="horizontal" style={{ whiteSpace: "nowrap" }}>
                <Typography.Text strong>
                  Weight ({record.getProductWeight()?.unit}):
                </Typography.Text>
                <Typography.Text>
                  {record.getProductWeight()?.value}
                </Typography.Text>
              </Space>
              <Space direction="horizontal" style={{ whiteSpace: "nowrap" }}>
                <Typography.Text strong>
                  Width ({record.getProductWidth()?.unit}):
                </Typography.Text>
                <Typography.Text>
                  {record.getProductWidth()?.value}
                </Typography.Text>
              </Space>
              <Space direction="horizontal" style={{ whiteSpace: "nowrap" }}>
                <Typography.Text strong>
                  Height ({record.getProductHeight()?.unit}):{" "}
                </Typography.Text>
                <Typography.Text>
                  {record.getProductHeight()?.value}
                </Typography.Text>
              </Space>
            </Space>
            <br />
            Seller:{" "}
            <p style={{fontWeight: "bold"}}>{record.getSellerName()}</p>;
          </div>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "product_price",
      hidden: isMobile,
      key: "product_price",
      render: (_: string, record: WishlistItemModel) => {
        return (
          <Space direction="vertical">
            <Space direction="horizontal" style={{ whiteSpace: "nowrap" }}>
              <Typography.Text strong> Original:</Typography.Text>
              {record.getProductMaxOriginal()}-{record.getProductMinOriginal()}
            </Space>
            <Space direction="horizontal" style={{ whiteSpace: "nowrap" }}>
              <Typography.Text strong>Discount: </Typography.Text>
              {record.getProductMaxDiscount()}-{record.getProductMinDiscount()}
            </Space>
          </Space>
        );
      },
    },
    {
      title: "Dimension",
      dataIndex: "product_dimension",
      hidden: isMobile,
      key: "product_dimension",
      render: (_: string, record: WishlistItemModel) => {
        return (
          <Space direction="vertical">
            <Space direction="horizontal" style={{ whiteSpace: "nowrap" }}>
              <Typography.Text strong>
                Weight ({record.getProductWeight()?.unit}):
              </Typography.Text>
              <Typography.Text>
                {record.getProductWeight()?.value}
              </Typography.Text>
            </Space>
            {/* <Space direction="horizontal" style={{ whiteSpace: "nowrap" }}>
              <Typography.Text strong>
                Width ({record.getProductWidth()?.unit}):
              </Typography.Text>
              <Typography.Text>
                {record.getProductWidth()?.value}
              </Typography.Text>
            </Space>
            <Space direction="horizontal" style={{ whiteSpace: "nowrap" }}>
              <Typography.Text strong>
                Height ({record.getProductHeight()?.unit}):{" "}
              </Typography.Text>
              <Typography.Text>
                {record.getProductHeight()?.value}
              </Typography.Text>
            </Space> */}
          </Space>
        );
      },
    },
    {
      title: "Rating",
      dataIndex: "product_ratings_avg",
      hidden: isMobile,
      key: "product_ratings",
      render: (_: string, record: WishlistItemModel) => {
        return <Typography>{record.getProductAverageRating()}</Typography>;
      },
    },

    {
      title: "Seller",
      dataIndex: "seller",
      hidden: isMobile,
      key: "seller",
      render: (_: string, record: WishlistItemModel) => {
        return <p style={{fontWeight:"bold"}}>{record.getSellerName()}</p>;
      },
    },
  ];

  return (
    <Table
      columns={wishlistItemColumns}
      dataSource={data}
      pagination={{ pageSize: 10 }}
      rowKey="id"
    />
  );
};

export default WishlistItemTable;
