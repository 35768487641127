import { EditOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row } from "antd";

import { ISourceDestinationProps } from "types/shipmentProductCollection";
import { ChangeDestinationWarehouseModal, ChangeShippingCountryModal } from ".";

export const SourceDestination = ({
  isLoading,
  title,
  ShipmentProductData,
  changeDestinationWarehouseDetails,
  changeDestinationWarehouseHandler,
  changeShippingCountryDetails,
  changeShippingCountryHandler,
  setChangeDestinationWarehouseDetails,
  setChangeShippingCountryDetails,
}: ISourceDestinationProps) => {
  return (
    <>
      <Card title={title} bordered={false} loading={isLoading}>
        <Row justify="center" align="middle" gutter={[16, 16]}>
          <Col xs={24} sm={12} md={8}>
            <Card
              title={<p style={{ textAlign: "center" }}>Source</p>}
              bordered
              style={{ flex: 1 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 5,
                  fontWeight: 500,
                  whiteSpace: "nowrap",
                }}
              >
                <img
                  src={ShipmentProductData?.getShippingCountry()
                    ?.getCountry()
                    .getFlag()}
                  style={{ width: 18, height: 18 }}
                  alt=""
                />
                {ShipmentProductData?.getShippingCountry()
                  ?.getCountry()
                  .getName()}
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  onClick={() =>
                    changeShippingCountryHandler(
                      [ShipmentProductData!.getId()],
                      ShipmentProductData!.getShippingCountry(),
                    )
                  }
                >
                  <EditOutlined />
                </Button>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={1} style={{ textAlign: "center" }}>
            <SendOutlined />
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Card
              title={<p style={{ textAlign: "center" }}>Destination</p>}
              bordered
              style={{ flex: 1, width: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 5,
                  fontWeight: 500,
                  whiteSpace: "nowrap",
                }}
              >
                <img
                  src={ShipmentProductData?.getDestinationCountry().getFlag()}
                  style={{ width: 18, height: 18 }}
                  alt="flag"
                />
                {ShipmentProductData?.getDestinationCountry().getName()}
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 5,
                  fontWeight: 500,
                  whiteSpace: "nowrap",
                }}
              >
                ({ShipmentProductData?.getDestinationWarehouse().getName()})
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  onClick={() =>
                    changeDestinationWarehouseHandler(
                      [ShipmentProductData!.getId()],
                      ShipmentProductData!.getDestinationWarehouse(),
                    )
                  }
                >
                  <EditOutlined />
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </Card>

      {changeShippingCountryDetails.productIds &&
        changeShippingCountryDetails.productIds.length &&
        changeShippingCountryDetails.existingShippingCountry && (
          <ChangeShippingCountryModal
            existingShippingCountryId={changeShippingCountryDetails.existingShippingCountry.getId()}
            isOpen={changeShippingCountryDetails.isOpen}
            productIds={changeShippingCountryDetails.productIds}
            onCancel={() =>
              setChangeShippingCountryDetails({
                isOpen: false,
                existingShippingCountry: undefined,
                productIds: undefined,
              })
            }
          />
        )}

      {ShipmentProductData &&
        changeDestinationWarehouseDetails.productIds &&
        changeDestinationWarehouseDetails.productIds.length &&
        changeDestinationWarehouseDetails.existingWarehouse && (
          <ChangeDestinationWarehouseModal
            country_id={ShipmentProductData.getDestinationCountry().getId()}
            existingWarehouseId={changeDestinationWarehouseDetails.existingWarehouse.getId()}
            isOpen={changeDestinationWarehouseDetails.isOpen}
            productIds={changeDestinationWarehouseDetails.productIds}
            onCancel={() =>
              setChangeDestinationWarehouseDetails({
                isOpen: false,
                existingWarehouse: undefined,
                productIds: undefined,
              })
            }
          />
        )}
    </>
  );
};
