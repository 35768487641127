import { PageHeader } from "@ant-design/pro-layout";
import { Card, Col, Modal, Row, Table, Tabs, Tag, Typography } from "antd";
import { useState, useEffect } from "react";
import { useAtom } from "jotai";

import { regionRegion, shippingCartAtom } from "lib/core-react/store/store";
import { useGetRegion } from "lib/core-react/hooks/private";
import useDataFilters from "hooks/useDataFilters";
import { RegionModel } from "models/region";
import { ApiHelperModel } from "models/apiHelper";
import { PaginationModel } from "models/pagination";
import { useGetShippingCart } from "lib/core-react/hooks/private/useShippingCart";
import {
  ShippingCartCollectionModel,
  ShippingCartItemPackageModel,
  ShippingCartModel,
} from "models/shippingCartCollectionModel";
import { IFilterType } from "types/filters";

import FiltersComponent from "components/FiltersComponent";
import CartItemsTable from "./components/CartItemsTable";
import PackagesTable from "./components/PackagesTable";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import { formateDate } from "utils/helpers";

const ShippingCarts = () => {
  const { getRegion } = useGetRegion();
  const { getShippingCart } = useGetShippingCart();
  const [{ data, isLoading, refetch }] = useAtom(shippingCartAtom);
  const [selectedRegion, setSelectedRegion] = useState("3");
  const [selectedPackage, setSelectedPackage] = useState<
    ShippingCartItemPackageModel[] | undefined
  >(undefined);
  const [{ data: regionDataAtom }] = useAtom(regionRegion);
  const RegionData = regionDataAtom && new RegionModel(regionDataAtom);
  const { isMobile } = useWindowWidth();
  useEffect(() => {
    getRegion("per_page=200");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getShippingCart(`region_id=${selectedRegion}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion]);

  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
  } = useDataFilters();

  // Api Call
  useEffect(() => {
    if ((filters && !isFetched && isFirstCall) || refetch) {
      filters && ApiHelperModel.makeGetRequest(filters, getShippingCart);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstCall, filters, isFetched, refetch, selectedRegion]);

  const ShippingCartData = data && new ShippingCartCollectionModel(data);

  // Filter
  let filterData = ShippingCartData?.filters;

  // Pagination
  const paginationData = ShippingCartData?.pagination;

  // Getting all available filters
  useEffect(() => {
    if (!isFetched && ShippingCartData?.filters) {
      initializeAvailableFilter(filterData as IFilterType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, initializeAvailableFilter, ShippingCartData?.filters]);

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getShippingCart,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    ShippingCartData,
    handlePaginationChange,
  );

  // Filter Handler
  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getShippingCart);
  };

  const onChangeRegion = (key: string) => {
    setSelectedRegion(key);
  };

  const expandedRowRender = ({ cart_items }: ShippingCartModel) => {
    return (
      <CartItemsTable
        data={cart_items.data}
        setSelectedPackage={setSelectedPackage}
      />
    );
  };

  const shippingCartColumns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "sl",
      align: "center" as const,
      width: 70,
      render: (_: string, __: ShippingCartModel, index: number) => {
        return (
          <div>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </div>
        );
      },
    },
    {
      title: "Info",
      dataIndex: "user_id",
      hidden: !isMobile,
      key: "user_id",
      render: (title: string, record: ShippingCartModel) => {
        return (
          <>
            User: <Typography>{record.user_id}</Typography>
            <br />
            Guest Id: <Typography>{record.guest_id}</Typography>
            <br />
            Created At:{" "}
            <Tag color="purple">{formateDate(record.created_at)}</Tag>
          </>
        );
      },
    },
    {
      title: "User",
      dataIndex: "user_id",
      hidden: isMobile,
      key: "user_id",
      render: (title: string) => {
        return <Typography>{title}</Typography>;
      },
    },
    {
      title: "Guest",
      dataIndex: "guest_id",
      hidden: isMobile,
      key: "guest_id",
      render: (title: string) => {
        return <Typography>{title}</Typography>;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      hidden: isMobile,
      key: "created",
      render: (createdAt: string) => {
        return <Tag color="purple">{formateDate(createdAt)}</Tag>;
      },
    },
  ];

  function removeRegionFilter(filters: IFilterType) {
    // Clone the filters object to avoid modifying the original
    const updatedFilters = JSON.parse(JSON.stringify(filters));

    // Check if "region_id" exists in the filters and remove it
    if (updatedFilters.hasOwnProperty("region_id")) {
      delete updatedFilters.region_id;
    }

    return updatedFilters;
  }

  return (
    <>
      <div>
        <PageHeader
          ghost={false}
          title="Shipping Cart"
          style={{ marginTop: "10px" }}
        >
          <Tabs
            type="card"
            size="large"
            activeKey={selectedRegion}
            tabPosition="top"
            animated
            centered
            onChange={onChangeRegion}
            items={RegionData?.data.map((region) => {
              return {
                label: region.name,
                key: region.id.toString(),
                children: (
                  <>
                    {filters &&
                      filterData &&
                      Object.keys(filters).length > 0 && (
                        <Row>
                          <Col span={24}>
                            <Card title="Filter">
                              <FiltersComponent
                                handleProductFilter={handleProductFilter}
                                handleFilterChange={handleFilterChange}
                                handelFilterClear={handelFilterClear}
                                isFetched={isFetched}
                                filters={filters}
                                filtersData={removeRegionFilter(filterData)}
                                isFromProductReceived={true}
                              />
                            </Card>
                          </Col>
                        </Row>
                      )}
                    <Table
                      style={{ background: "yelllow", marginTop: 10 }}
                      loading={isLoading}
                      rowKey="id"
                      bordered={true}
                      dataSource={ShippingCartData?.data}
                      expandable={{
                        expandedRowRender,
                        defaultExpandedRowKeys: ["0"],
                      }}
                      scroll={{ x: 1000 }}
                      columns={shippingCartColumns}
                      pagination={paginationConfig}
                    />
                  </>
                ),
              };
            })}
          />
        </PageHeader>
      </div>

      <Modal
        open={Boolean(selectedPackage)}
        onCancel={() => setSelectedPackage(undefined)}
        width={isMobile ? "90vw" : "70vw"}
        footer={null}
        title="Package Details"
      >
        {selectedPackage && <PackagesTable data={selectedPackage} />}
      </Modal>
    </>
  );
};

export default ShippingCarts;
