import { useAtom } from "jotai";
import { useService } from "lib/core-react/contexts";
import { bulkUserAdmin, bulkUserCustomer } from "lib/core-react/store/store";
import { getError } from "../utils/errors";

export const useGetAdmins = () => {
  const [bulkUserAdminData, setBulkUserAdminData] = useAtom(bulkUserAdmin);
  const { v1Service } = useService();
  const getAdmins = async (params: string): Promise<void> => {
    setBulkUserAdminData({
      ...bulkUserAdminData,
      isLoading: true,
      error: null,
    });
    try {
      const response = await v1Service.v1Resource.getUsers(
        "user_types[]=admin&" + params,
      );
      setBulkUserAdminData({
        ...bulkUserAdminData,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setBulkUserAdminData({
        ...bulkUserAdminData,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getAdmins } as const;
};
export const useGetCustomers = () => {
  const [bulkUserCustomerData, setBulkUserCustomerData] =
    useAtom(bulkUserCustomer);
  const { v1Service } = useService();
  const getCustomers = async (params: string): Promise<void> => {
    setBulkUserCustomerData({
      ...bulkUserCustomerData,
      isLoading: true,
      error: null,
    });
    try {
      const response = await v1Service.v1Resource.getUsers(
        "user_types[]=customer&" + params,
      );
      setBulkUserCustomerData({
        ...bulkUserCustomerData,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setBulkUserCustomerData({
        ...bulkUserCustomerData,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getCustomers } as const;
};
