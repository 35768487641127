import React, { useEffect, useState, createRef } from "react";
import { Alert, Button, Form, Input, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";

import { Forms } from "models/form";

import TextArea from "antd/lib/input/TextArea";
import { renderOptionsFromEnumAddonForEnum } from "components/Form/forms";
import {
  AddonServiceModel,
  UpdateServicesnPutRequestModel,
} from "models/addonServiceCollectionModel";
import { AddonForEnum } from "enums/addonServicesCollectionEnums";
import { useUpdateService } from "lib/core-react/hooks/private/useAddonService";
import { SingleChunkUpload } from "components/SingleChunkUpload";

interface IProps {
  setIsShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedAddonServics: AddonServiceModel;
}

const UpdateService = ({
  selectedAddonServics,
  setIsShowUpdateModal,
}: IProps) => {
  const { updateService, isLoading, isError } = useUpdateService();
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  // const [{ data: regionDataAtom, isLoading: regionLoading }] =
  //   useAtom(regionRegion);

  //const regionData = regionDataAtom && new RegionModel(regionDataAtom);

  const formRef: React.Ref<FormInstance<any>> = createRef();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const onFinish = async (value: UpdateServicesnPutRequestModel) => {
    try {
      await updateService(Number(selectedAddonServics.getId()), value);
      setIsShowUpdateModal(false);
      setIsErrorVisible(false);
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  const handleUpload = (files: any) => {
    form.setFieldsValue({ logo: files });
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    Forms.formValueChange(form, changedValues, allValues);
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      layout="vertical"
      name="Update Services"
      onFieldsChange={onValuesChange}
      ref={formRef}
      initialValues={{
        name: selectedAddonServics.getName(),
        identifier: selectedAddonServics.getIdentifier(),
        description: selectedAddonServics.getDescription(),
        addon_for: selectedAddonServics.getAddonFor(),
        logo: selectedAddonServics.getImage()?.getSrc(),
      }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Name is required" }]}
      >
        <Input placeholder="Enter name" />
      </Form.Item>

      <Form.Item
        label="Identifier"
        name="identifier"
        rules={[{ required: true, message: "Identifier  is required" }]}
      >
        <Input placeholder="Enter identifier" />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        rules={[{ required: true, message: "Description is required" }]}
      >
        <TextArea placeholder="Enter Description" />
      </Form.Item>

      <Form.Item label="Addon For" name="addon_for">
        <Select placeholder="Please select a Publishing Status">
          {renderOptionsFromEnumAddonForEnum(AddonForEnum)}
        </Select>
      </Form.Item>

      <Form.Item name="logo" label="Images">
        <SingleChunkUpload
          form={form}
          accept="image/jpeg,image/png,image/jpg,image/gif,image/webp"
          fieldName="small_logo"
        />
      </Form.Item>

      <Form.Item>
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Submit
        </Button>
      </Form.Item>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isError}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </Form>
  );
};

export default UpdateService;
