import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Card,
  Form,
  Modal,
  Space,
  Timeline,
  Typography,
  message,
} from "antd";
import { useAtom } from "jotai";
import { ClockCircleOutlined } from "@ant-design/icons";

import {
  TimelineTrackingModel,
  TrackingEventModel,
} from "models/timelineTrackingModel";
import { RoleCollectionModel } from "models/roleCollectionModel";
import { IShipmentProductDetailCommonProps } from "types/shipmentProductCollection";

import { formatDateTime } from "utils/helpers";
import { shipmentProductTimelineTrackingAtom } from "lib/core-react/store/shipmentOrder/shipmentOrderAtom";
import { getError } from "lib/core-react/hooks/utils/errors";
import {
  useProductCreateComment,
  useGetRole,
  useProductDeleteComment,
  useProductUpdateComment,
  useProductReplyComment,
  useShipmentProductGetTimelineTracking,
} from "lib/core-react/hooks/private";
import { roleAtom } from "lib/core-react/store/store";

import {
  handleCreateComment,
  handleDeleteComment,
  handleReplyComment,
  handleUpdateComment,
} from "components/TrackingTimeline/utils";
import { CommentEditor, RenderComment } from "components/TrackingTimeline";
import { SelectLabelValue } from "types/buyOrderPlace";

export const TimelineTracking = ({
  title,
}: IShipmentProductDetailCommonProps) => {
  const params = useParams();
  const { productNumber } = params;
  const { Text } = Typography;

  const { getTimelineTracking } = useShipmentProductGetTimelineTracking();
  const [{ data, isLoading: isLoadingTimelineTracking, error, refetch }] =
    useAtom(shipmentProductTimelineTrackingAtom);

  useEffect(() => {
    if (productNumber) {
      getTimelineTracking(productNumber);
      getRole();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productNumber]);

  useEffect(() => {
    if (productNumber && refetch) {
      getTimelineTracking(productNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const TimelineTrackingData = data && new TimelineTrackingModel(data);

  // Comment
  const { getRole } = useGetRole();
  const [{ data: roleCollectionData, isLoading: isRoleLoading }] =
    useAtom(roleAtom);
  const RoleCollectionData =
    roleCollectionData && new RoleCollectionModel(roleCollectionData);

  const [form] = Form.useForm();
  const visibility = Form.useWatch("visibility", form);
  const role_ids = Form.useWatch("role_ids", form);

  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [customError, setCustomError] = useState<string | null>(null);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const {
    createComment,
    isLoading: isLoadingCreateComment,
    error: errorCreate,
  } = useProductCreateComment();
  const {
    updateComment,
    isLoading: isLoadingUpdateComment,
    error: errorUpdate,
  } = useProductUpdateComment();
  const { deleteComment, isLoading: isLoadingDeleteComment } =
    useProductDeleteComment();
  const {
    replyComment,
    isLoading: isLoadingReplyComment,
    error: errorReply,
  } = useProductReplyComment();

  const [commentContent, setCommentContent] = useState<string>("");
  const [specificUserIds, setSpecificUserIds] = useState<SelectLabelValue[]>(
    [],
  );
  const [roleIds, setRoleIds] = useState<SelectLabelValue[]>([]);
  const [selectedCommentIdToUpdate, setSelectedCommentIdToUpdate] =
    useState<number>();
  const [selectedCommentIdToReply, setSelectedCommentIdToReply] =
    useState<number>();

  const handleCreateCommentCall = async () => {
    await handleCreateComment({
      specificUserIds,
      commentContent,
      TimelineTrackingData,
      createComment,
      form,
      setCommentContent,
      setSpecificUserIds,
      message,
      setIsErrorVisible,
      setCustomError,
      visibility,
      role_ids,
      productId:productNumber as string
    });
  };

  const handleUpdateCommentCall = async () => {
    await handleUpdateComment({
      specificUserIds,
      commentContent,
      TimelineTrackingData,
      selectedCommentIdToUpdate,
      updateComment,
      form,
      setCommentContent,
      setSpecificUserIds,
      setSelectedCommentIdToUpdate,
      message,
      setIsErrorVisible,
      setCustomError,
      visibility,
      role_ids,
      productId:productNumber as string
    });
  };

  const handleDeleteCommentCall = async (data: TrackingEventModel) => {
    await handleDeleteComment({
      data,
      deleteComment,
      getTimelineTracking,
      message,
      setIsErrorVisible,
      productId: productNumber as string
    });
  };

  const handleReplyCommentCall = async () => {
    await handleReplyComment({
      commentContent,
      setCommentContent,
      selectedCommentIdToReply,
      setSelectedCommentIdToReply,
      replyComment,
      form,
      message,
      setIsErrorVisible,
      setCustomError,
      productId:productNumber as string
    });
  };

  if (error) {
    return <div>Something went wrong : {getError(error)}</div>;
  }

  return (
    <>
      <Card title={title} loading={isLoadingTimelineTracking}>
        {TimelineTrackingData ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              type="inner"
              title="Leave a comment"
              bordered={false}
              hoverable
              style={{ marginBottom: 15 }}
            >
              {/* Display error */}
              {isErrorVisible &&
                !selectedCommentIdToReply &&
                !selectedCommentIdToUpdate && (
                  <Alert
                    style={{ marginTop: "7px", marginBottom: "7px" }}
                    message={errorCreate || customError}
                    closable
                    afterClose={() => setIsErrorVisible(false)}
                    type="error"
                    showIcon
                  />
                )}

              {!isRoleLoading && RoleCollectionData && (
                <CommentEditor
                  mode="create"
                  form={form}
                  roleCollectionData={RoleCollectionData}
                  roleIds={roleIds}
                  specificUserIds={specificUserIds}
                  setSpecificUserIds={setSpecificUserIds}
                  content={commentContent}
                  setContent={setCommentContent}
                  onSubmit={handleCreateCommentCall}
                  loading={isLoadingCreateComment}
                />
              )}
            </Card>

            <Timeline reverse mode="left">
              {TimelineTrackingData?.getData()
                .getTrackingEvents()
                .getData()
                .map((data) => {
                  return (
                    <Timeline.Item
                      dot={
                        data.getTemplate() && !data.getMessage() ? (
                          <ClockCircleOutlined style={{ fontSize: "16px" }} />
                        ) : undefined
                      }
                      label={
                        data.getTemplate() && data.getMessage() ? (
                          <Space direction="vertical">
                            <Text type="secondary">
                              {formatDateTime(data.getCreatedAt())}
                            </Text>
                            <Text strong>{data.getCompletedTemplate()}</Text>
                          </Space>
                        ) : (
                          <Text type="secondary">
                            {formatDateTime(data.getCreatedAt())}
                          </Text>
                        )
                      }
                      key={data.getId()}
                    >
                      {data.getTemplate() && !data.getMessage() ? (
                        <Text strong>{data.getCompletedTemplate()}</Text>
                      ) : data.getMessage() ? (
                        <RenderComment
                          data={data}
                          handleDeleteComment={handleDeleteCommentCall}
                          isLoadingDeleteComment={isLoadingDeleteComment}
                          setCommentContent={setCommentContent}
                          setSpecifiedUserIds={setSpecificUserIds}
                          setRoleIds={setRoleIds}
                          setSelectedCommentIdToUpdate={
                            setSelectedCommentIdToUpdate
                          }
                          setSelectedCommentIdToReply={
                            setSelectedCommentIdToReply
                          }
                        />
                      ) : (
                        ""
                      )}
                    </Timeline.Item>
                  );
                })}
            </Timeline>
          </div>
        ) : (
          ""
        )}

        {/* Comment edit modal */}
        {selectedCommentIdToUpdate && !isRoleLoading && RoleCollectionData && (
          <Modal
            title="Update Comment"
            centered
            open={Boolean(selectedCommentIdToUpdate)}
            onCancel={() => {
              setCommentContent("");
              setSelectedCommentIdToUpdate(undefined);
            }}
            footer={null}
          >
            {/* Display error */}
            {isErrorVisible && (
              <Alert
                style={{ marginBottom: "7px" }}
                message={errorUpdate || customError}
                closable
                afterClose={() => setIsErrorVisible(false)}
                type="error"
                showIcon
              />
            )}
            <CommentEditor
              mode="update"
              form={form}
              roleCollectionData={RoleCollectionData}
              roleIds={roleIds}
              specificUserIds={specificUserIds}
              setSpecificUserIds={setSpecificUserIds}
              content={commentContent}
              setContent={setCommentContent}
              onSubmit={handleUpdateCommentCall}
              loading={isLoadingUpdateComment}
              onCancel={() => setSelectedCommentIdToUpdate(undefined)}
            />
          </Modal>
        )}

        {/* Comment reply modal */}
        {selectedCommentIdToReply && !isRoleLoading && RoleCollectionData && (
          <Modal
            title="Leave a reply"
            centered
            open={Boolean(selectedCommentIdToReply)}
            onCancel={() => {
              setCommentContent("");
              setSelectedCommentIdToReply(undefined);
            }}
            footer={null}
          >
            {/* Display error */}
            {isErrorVisible && (
              <Alert
                style={{ marginBottom: "7px" }}
                message={errorReply || customError}
                closable
                afterClose={() => setIsErrorVisible(false)}
                type="error"
                showIcon
              />
            )}
            <CommentEditor
              mode="reply"
              form={form}
              roleCollectionData={RoleCollectionData}
              roleIds={roleIds}
              specificUserIds={specificUserIds}
              setSpecificUserIds={setSpecificUserIds}
              content={commentContent}
              setContent={setCommentContent}
              onSubmit={handleReplyCommentCall}
              loading={isLoadingReplyComment}
              onCancel={() => setSelectedCommentIdToReply(undefined)}
            />
          </Modal>
        )}
      </Card>
    </>
  );
};
