import React, { useState } from 'react'
import { Button, Col, Row, Table, message, Modal, Dropdown, Menu, Space, Skeleton } from 'antd'
import { RadarChartOutlined, HddFilled, DownOutlined } from "@ant-design/icons"
import { useQuery } from 'react-query'
import { getAgents } from "utils/agent-client"
import './ApprovedBuyingAgent.css'
import { buyItemApproveService } from 'utils/buy-products'
import { toastErrors } from 'utils/helpers'

const { Column } = Table

const ApprovedBuyingAgent = Props => {
    const { product, isBulkItemAgentAssign, handleResponse, onHide } = Props
    const { isLoading, data: response } = useQuery(['agents', { product }], async (key, params) => {
        const { data } = await getAgents(null, {abilities: 'buying'})
        return data
    }, {
        refetchOnWindowFocus: false,
    })

    const [selectedWarehouseCategory, setSelectedWarehouseCategory] = useState(null)
    const [showConfirm, setShowConfirm] = useState(false)
    const [isApproving, setIsApproving] = useState(false)

    const sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms))
    }

    const handleAsignAgent = async () => {
      let assignAbleItems = [];

      if (isBulkItemAgentAssign) {
        assignAbleItems = product.filter(item => item.status === "buy-pending" || item.status === 'buy-reject').map(item => item.id);
      } else {
        assignAbleItems = [product.id]
      }

      let idx = 0;

      if (assignAbleItems?.length > 0) {
        for (const item of assignAbleItems) {
          if (idx === 0 && isBulkItemAgentAssign) {
            message.warn('Assign agent to selected products started! Please Do not close the tab')
          }

          setIsApproving(true)
          await sleep(200)

          await buyItemApproveService(item, selectedWarehouseCategory.id)
            .then(res => {
              handleResponse(res.data)
              message.success("Buying agent assigned successfully")
            }).catch(({response}) => {
                if (response?.data) {
                    toastErrors(response.data)
                }
            })
            .finally(() => {
              setShowConfirm(false)
              setIsApproving(false)
              onHide();
            })

          if ((idx === (assignAbleItems.length - 1)) && isBulkItemAgentAssign) {
            await sleep(200)
            message.info('Assign agent to selected products has been done!')
            await sleep(200)
            message.info('Please refresh the page to see updated status of all items!')
          }

          idx++;
        }
      }
    }

    return (
        (<Modal
            title={`Select Buying Agent`}
            width={890}
            onCancel={Props.onHide}
            open={Props.show}
            footer={false}
        >
            <Row>
                <Col span={24}>
                    <h4>Suggested Buying Agent:</h4>
                    {
                        isLoading ? <Skeleton active /> :
                            <Table
                                dataSource={response?.data}
                                loading={isLoading}
                                bordered={true}
                                pagination={false}
                                rowKey="id"
                                rowClassName={(record, idx) => {
                                    let rowActive = ''
                                    if (product.agentCompany && product.agentCompany.id === record.id) {
                                        rowActive = 'agent-active'
                                    }
                                    return rowActive
                                }}
                            >
                                <Column
                                    title="Name"
                                    dataIndex="name"
                                    key="name"
                                    align={"center"}
                                />
                                <Column
                                    title="IAddress"
                                    dataIndex="address"
                                    key="address"
                                    align={"center"}
                                />
                                <Column
                                    title="Action"
                                    dataIndex="unit_type"
                                    key="unit_type"
                                    align={"center"}
                                />
                                <Column
                                    title="Actions"
                                    dataIndex="id"
                                    key="products"
                                    align={"center"}
                                    render={(id, row) => (
                                        <Dropdown
                                            overlay={
                                                <Menu>
                                                    <Menu.Item
                                                        icon={<RadarChartOutlined />}
                                                        onClick={() => {
                                                            setSelectedWarehouseCategory(row);
                                                            setShowConfirm(true)
                                                        }}
                                                    >Approve</Menu.Item>
                                                </Menu>
                                            }
                                            placement="bottomCenter"
                                        >

                                            <Button icon={<HddFilled />}>Actions <DownOutlined /></Button>
                                        </Dropdown>
                                    )}
                                />
                            </Table>
                    }

                </Col>
                <Modal
                    title="Assign buy agent"
                    open={showConfirm}
                    closable={false}
                    footer={null}
                >
                    <p>Assign product to buying agent</p>
                    <Space>
                        <Button onClick={() => setShowConfirm(false)} disabled={isApproving}>Cancel</Button>
                        <Button type="primary" onClick={handleAsignAgent} disabled={isApproving} loading={isApproving}>Submit</Button>
                    </Space>
                </Modal>
            </Row>
        </Modal>)
    );
}

export default ApprovedBuyingAgent;
