import {AuthProvider} from "./auth-context";
import {UserProvider} from "./user-context";
import AppContextProvider from "./AppContextProvider";
import AppThemeProvider from "./AppThemeProvider";
import {useThemeContext} from "./AppContextProvider/ThemeContextProvider";
import {GlobalStyles} from "layout/GlobalStyle";
import {Normalize} from "styled-normalize";
import React, {ReactElement} from "react";

const AppProviders: React.FC<{ children?: ReactElement }> = ({children}) => {
  const {theme} = useThemeContext();
  return (
    <AuthProvider>
      <UserProvider>
        <AppContextProvider>
          <AppThemeProvider>
            <>
              <GlobalStyles theme={theme}/>
              <Normalize/>
              {children}
            </>
          </AppThemeProvider>
        </AppContextProvider>
      </UserProvider>
    </AuthProvider>
  );
}

export default AppProviders;
