import React, { useMemo, useRef, useState } from "react";
import { Select, Spin, Form } from "antd";
import type { SelectProps } from "antd";
import { ICustomerUser } from "types/customerUserCollection";
import client from "utils/api-client";
import {
  ServiceBaseUrl,
  ServiceNameEnum,
} from "lib/core/apiServices/servicBaseUrl";
import { SelectLabelValue } from "types/buyOrderPlace";
import { filterResourceEnum } from "enums/filterResourceEnum";

const debounce = <F extends (...args: any[]) => void>(
  fn: F,
  T: number,
): (() => void) => {
  let timer: NodeJS.Timeout | null;

  return function (...args: any[]) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, T);
  };
};

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, "options" | "children"> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
  resource: string;
}

function DebounceSelect<
  ValueType extends {
    value: string | number;
  } = any,
>({
  fetchOptions,
  debounceTimeout = 800,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      showSearch
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

type ResourceType = "customer" | "admin" | "orderHandler" | "user";

async function fetchUserList(
  resource: ResourceType | string,
  username: string,
): Promise<SelectLabelValue[]> {
  let endpoint;

  if (
    resource === filterResourceEnum.CUSTOMER ||
    resource === filterResourceEnum.USER
  ) {
    endpoint = `${ServiceBaseUrl.getServiceBaseUrl(ServiceNameEnum.COMMERCE_SERVICE)}/api/user/admin/customer-user/v1/customer-users?name=${username}`;
  } else if (resource === filterResourceEnum.ADMIN) {
    endpoint = `${ServiceBaseUrl.getServiceBaseUrl(ServiceNameEnum.COMMERCE_SERVICE)}/api/user/admin/admin-user/v1/admin-users?name=${username}`;
  } else {
    throw new Error("Unsupported resource type");
  }

  const response = await client.get(endpoint);
  const users = response.data;

  return users.data.map((user: ICustomerUser) => ({
    label: user.name, // Since "agent-company" is not a valid resource, we always use user.name
    value: user.id,
  }));
}

export const UserSelect = ({
  value,
  allowClear,
  resource,
  showSearch,
  placeholder,
  onChange,
  style,
  ...props
}: Omit<DebounceSelectProps, "fetchOptions">) => {
  const form = Form.useFormInstance();
  const handleChangeUser = async (
    newValue:
      | { label: React.ReactNode; value: string | number }
      | { label: React.ReactNode; value: string | number }[],
    option: any,
  ) => {
    if ("label" in newValue) {
      form.setFieldsValue({ user: newValue.value });
    }
    if (onChange) {
      onChange(newValue, option);
    }
  };

  return (
    <DebounceSelect
      resource={""}
      value={value}
      allowClear={allowClear}
      showSearch={showSearch}
      placeholder={placeholder}
      fetchOptions={(username) => fetchUserList(resource, username)}
      onChange={handleChangeUser}
      style={style}
      {...props}
    />
  );
};
