import { useEffect, useState } from "react";
import { Button, Modal, Tag, Tooltip, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import {
  ArrowRightOutlined,
  EditOutlined,
  ShopOutlined,
  UserOutlined,
} from "@ant-design/icons";
import StoreIcon from "assets/storeIcon";
import { StatusTag } from "../../../components";
import { UpdateFax } from "../BuyProducts/BuyProducts";
import UpdateFxModal from "../BuyProducts/components/UpdateFxModal";
import { CommissionRateTypeEnums } from "enums/exchangeListCollectionEnums";
import { ITransactionSummary } from "types/buyOrderCollection";

export interface IProductInfo {
  productTitle: { title: string; link: string };
  productSeller: { name: string; id: string };
  productId: string;
  buyproductid: number;
  orderId: string;
  productStatus: string;
  invoice: string;
  paidPercentage: string;
  store: string;
  fx: number;
  customerInvoiceSummary: ITransactionSummary;
  customerName: string;
  orderHandler: string;
  commissionRate: number;
  commissionType: string;
}
const ProductInfo = ({ productInfo }: { productInfo: IProductInfo }) => {
  const { Text } = Typography;

  const navigate = useNavigate();
  const [selectedBuyProductDetail, setSelectedBuyProductDetail] = useState<
    number | undefined
  >(undefined);

  const [totalValue, setTotalValue] = useState<number | string | null>();

  const [isSeletedFaxUpdate, setIsSeletedFaxUpdate] = useState<UpdateFax>();

  const [isUpdateFxModalOpen, setIsUpdateFxModalOpen] =
    useState<boolean>(false);

  const handleShowDetail = (record: number) => {
    setSelectedBuyProductDetail(record);
  };

  useEffect(() => {
    if (selectedBuyProductDetail !== undefined) {
      navigate(`/buy-and-ship-for-me/detail/${selectedBuyProductDetail}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBuyProductDetail]);

  const showUpdateFx = (operation) => {
    const payloadData = {
      product_id: operation.buyproductid,
      fxRate: operation.fx,
      commission_rate: operation.commissionRate,
      commission_type: operation.commissionType,
      totalFxAndCommission: operation.totalFxAndCommission,
    };
    setIsSeletedFaxUpdate(payloadData);
    setIsUpdateFxModalOpen(true);
  };

  useEffect(() => {
    if (productInfo.fx && productInfo.commissionRate) {
      let total = 0;
      if (productInfo.commissionType === CommissionRateTypeEnums.PERCENTAGE) {
        total =
          productInfo.fx + (productInfo.fx * productInfo.commissionRate) / 100;
      }
      setTotalValue(total.toFixed(2));
    }
  }, [productInfo]);

  return (
    <div>
      <p style={{ margin: "0 auto", fontWeight: "bold" }}>
        <span>
          <ShopOutlined style={{ color: "red", paddingRight: 4, width: 20 }} />
        </span>
        {productInfo.productSeller.name}
      </p>
      <a
        href={productInfo.productTitle.link}
        target={"_blank"}
        rel="noreferrer"
      >
        {productInfo.productTitle.title.length >= 50
          ? productInfo.productTitle.title.slice(0, 50) + "..."
          : productInfo.productTitle.title}
      </a>

      <p style={{ margin: "1px 0" }}>
        PB:{" "}
        <Text type="success" copyable>
          {productInfo.productId}
        </Text>
      </p>
      <p style={{ margin: "1px 0" }}>
        Product Status:{" "}
        <StatusTag
          text={productInfo.productStatus}
          slug={productInfo.productStatus}
        />
      </p>

      {/* <p style={{ margin: "1px 0" }}>
        Invoice:{" "}
        <Tag
          style={{
            textTransform: "capitalize",
          }}
          color={"error"}
        >
          {productInfo.invoice}
        </Tag>
      </p> */}

      <Tooltip
        placement="top"
        title={`Paid - ${productInfo.customerInvoiceSummary.paid}, Overpaid - ${productInfo.customerInvoiceSummary.overpaid}, Due - ${productInfo.customerInvoiceSummary.due}`}
      >
        <p style={{ margin: "1px 0", marginTop: 5, marginBottom: 5 }}>
          Paid Percentage:{" "}
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            <Tag color="#CA8A04">{productInfo.paidPercentage}%</Tag>
          </span>
        </p>
      </Tooltip>

      <p style={{ margin: "1px 0" }}>
        FX:{" "}
        <Tooltip
          placement="top"
          title={`Commision ${productInfo.commissionRate}${productInfo.commissionType === CommissionRateTypeEnums.PERCENTAGE ? "%" : ""}, Total Profit - ${(Number(totalValue) - productInfo.fx).toFixed(2)}`}
        >
          <Tag
            style={{
              textTransform: "capitalize",
            }}
            color={"#EF4444"}
          >
            {" "}
            {productInfo.fx}
          </Tag>
        </Tooltip>
        <EditOutlined onClick={() => showUpdateFx(productInfo)} />
        <Tooltip placement="top" title={"Total Fx"}>
          {" "}
          <Tag color="red">{totalValue}</Tag>
        </Tooltip>
      </p>

      {/* <p style={{ margin: "1px 0" }}>
        Add/View Note: <PlusOutlined />
      </p> */}

      <p style={{ margin: "1px 0" }}>
        <UserOutlined />{" "}
        <span
          style={{
            fontWeight: "600",
          }}
        >
          {productInfo.customerName}
        </span>
      </p>

      <p style={{ margin: "1px 0" }}>
        Order Handler:{" "}
        <span
          style={{
            fontWeight: "600",
          }}
        >
          {productInfo.orderHandler}
        </span>
      </p>

      {/* <p style={{ margin: "1px 0" }}>
        Customer Note: <PlusOutlined />
      </p> */}

      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <StoreIcon
          style={{
            border: "1px solid #f7f7f7",
            width: "25px",
            height: "25px",
            padding: "4px",
            borderRadius: "100%",
          }}
        />
        <Text style={{ marginLeft: "10px" }} type="success" strong>
          {productInfo.store}
        </Text>
      </div>

      <Button
        style={{ padding: 0 }}
        type="link"
        onClick={() => handleShowDetail(productInfo.buyproductid)}
      >
        View Details <ArrowRightOutlined />
      </Button>

      {isSeletedFaxUpdate && (
        <Modal
          title="Update Fx"
          open={isUpdateFxModalOpen}
          destroyOnClose={true}
          onCancel={() => {
            setIsUpdateFxModalOpen(false);
          }}
          footer={false}
        >
          <UpdateFxModal
            isSeletedFaxUpdate={isSeletedFaxUpdate}
            setIsUpdateFxModalOpen={setIsUpdateFxModalOpen}
          />
        </Modal>
      )}
    </div>
  );
};

export default ProductInfo;
