export enum TakeActionsEnums {
  PRICE_INCREASED = "price-increased",
  PRICE_DECREASED = "price-decreased",
  PRODUCT_STOCK_OUT = "product-stock-out",
  FX_MISMATCHED = "fx-mismatched",
  QUANTITY_UPDATE = "quantity-update",
  SKU_MISSMATCHED = "sku-mismatched",
  CATEGORY_CHANGE = "category-change",
  LOCAL_DELIVERY_CHARGE_ADDED = "local-delivery-charge-added",
  TAX_ADDED = "tax-added",
  HIGH_TAX_POSSIBILITY = "high-tax-possibility",
  ILLEGAL_ITEM = "illegal-item",
  GENERAL_TERMS_AND_CONDITIONS = "general-terms-and-conditions",
  NO_RISK_PRODUCT = "no-risk-product",
  SHIPPING_CHARGE_INCREASE = "shipping-charge-increase",
  SERVICE_CHARGE = "service-charge",
  PACKING_CHARGE = "packing-charge",
  PURCHASE_CONFIRMATION = "purchase-confirmation",
}
