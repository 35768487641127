import React, { useMemo, useRef, useState } from "react";
import { Form, Select, Spin } from "antd";
import type { SelectProps } from "antd";
import client from "utils/api-client";

import { ICustomerUser } from "types/customerUserCollection";

import { useAtom } from "jotai";
import { buyOrderPlaceAtom } from "lib/core-react/store/store";
import {
  ServiceBaseUrl,
  ServiceNameEnum,
} from "lib/core/apiServices/servicBaseUrl";
import { SelectLabelValue } from "types/buyOrderPlace";

const debounce = <F extends (...args: any[]) => void>(
  fn: F,
  T: number,
): (() => void) => {
  let timer: NodeJS.Timeout | null;

  return function (...args: any[]) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, T);
  };
};

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, "options" | "children"> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

function DebounceSelect<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any,
>({
  fetchOptions,
  debounceTimeout = 800,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      showSearch
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

async function fetchUserList(username: string): Promise<SelectLabelValue[]> {
  const commerceServiceApiUrl = ServiceBaseUrl.getServiceBaseUrl(
    ServiceNameEnum.CART_SERVICE,
  );
  const path = `${commerceServiceApiUrl}/api/user/admin/customer-user/v1/customer-users?name=${username}`;
  const response = await client.get(path);
  const users = response.data;

  return users.data.map((user: ICustomerUser) => {
    return {
      label: `${user.name} (${user.email})`,
      value: user.id,
    };
  });
}

export const UserSelect = () => {
  const form = Form.useFormInstance();
  const [buyOrderPlaceAtomData, setByOrderPlaceAtomData] =
    useAtom(buyOrderPlaceAtom);

  const handleChangeUser = async (
    newValue: SelectLabelValue | SelectLabelValue[],
  ) => {
    if ("label" in newValue) {
      form.setFieldsValue({ user: newValue.value });
      setByOrderPlaceAtomData({
        ...buyOrderPlaceAtomData,
        isLoading: false,
        data: {
          ...buyOrderPlaceAtomData.data,
          user: newValue,
        },
      });
    }
  };

  return (
    <DebounceSelect
      onClick={() =>
        form.setFieldsValue({ user: buyOrderPlaceAtomData.data?.user })
      }
      placeholder="Select user"
      value={buyOrderPlaceAtomData.data?.user}
      fetchOptions={fetchUserList}
      onChange={handleChangeUser}
      style={{ width: "100%" }}
    />
  );
};
