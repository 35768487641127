import React, { useState } from 'react'
import client from 'utils/api-client'
import {
    Form, Input, Button, message, Modal, Space
} from 'antd'
import {updateShippingProduct} from "app/actions/shipForMeOrder";
import {useDispatch} from "react-redux";

const { TextArea } = Input

const RejectConfirm = Props => {
    const dispatch = useDispatch()
    const { products, show, onHide } = Props
    const [isFormLoading, setIsFormLoading] = useState(false)
    const [form] = Form.useForm();

    const onFinish = (values) => {
        setIsFormLoading(true);
        let productIds = products.map(product => {
            return product.id
        })
        client.post(`/admin/shipment-products/reject`, {
            products: productIds,
            reason: values.reason
        })
            .then(res => {
                setIsFormLoading(false)
                if (productIds?.length > 0){
                    dispatch(updateShippingProduct(productIds[0], {
                        status: res.data.products[0].status
                    }))
                }else {
                    dispatch(updateShippingProduct(productIds[0]))
                }
                onHide()
                message.success("Product successfully rejected")
                form.resetFields()
            }).catch(err => {
                if (err.response && err.response.status === 403) {
                    message.error("The action is not authorized")
                } else {
                    message.error("Reject failed!")
                }
            });
    }

    return (
        (<Modal
            title="Reject Products"
            open={show}
            onCancel={onHide}
            width={600}
            footer={null}
            closable={false}
        >
            <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
            >
                <Form.Item
                    name="reason"
                    label="Reason"
                    rules={[{ required: true, message: 'Please enter a reason' }]}
                >
                    <TextArea />
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button onClick={onHide} disabled={isFormLoading}>Cancel</Button>
                        <Button type="primary" htmlType="submit" disabled={isFormLoading} loading={isFormLoading}>
                            Submit
                    </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>)
    );
}

export default RejectConfirm
