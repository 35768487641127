import React, {useState, useEffect} from 'react'
import {Descriptions, Button, Col, Row, Divider, Tag, Table, Skeleton, message, Image} from 'antd'
import './ProductDetail.css'
import Date from "../Date"
import ImageGallery from "../ImageGallery"
import {getProductStatusLogs, getShipmentProductDetails} from "utils/shipment-product-client"
import {ShipmentProductTimeline, StatusTag} from 'components';
import {updateSize} from "utils/helpers";
import {getInvoiceDetails} from "utils/invoice-client";
import {ShipmentInvoiceDetails} from "pages/ShipmentProducts/components";

const {Column} = Table

const ProductDetail = Props => {
    const {product: productProp} = Props
    const [product, setProduct] = useState(null)
    const [isProductLoading, setIsProductLoading] = useState(false)

    const [productTimeline, setProductTimeline] = useState(null)
    const [isTimelineLoading, setIsTimelineLoading] = useState(false)

    const [invoiceData, setInvoiceData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const fetchShipmentProductDetails = React.useCallback(
        () => {
            setIsProductLoading(true)
            getShipmentProductDetails(productProp.id)
                .then(res => {
                    setProduct(res.data.data)
                    setIsProductLoading(false)
                })
                .catch(err => {
                    setIsProductLoading(false)
                })
        },
        [productProp],
    )

    const fetchStatusLogs = React.useCallback(
        () => {
            setIsTimelineLoading(true)
            getProductStatusLogs(productProp.id)
                .then(res => {
                    setProductTimeline(res.data.data)
                    setIsTimelineLoading(false)
                })
                .catch(err => {
                    setIsTimelineLoading(false)
                    message.error('Log data loading failed')
                })
        },
        [productProp],
    )

    const invoiceId = product?.invoice_item?.invoice_id

    const fetchItem = React.useCallback(
        () => {
            setIsLoading(true)
            getInvoiceDetails(invoiceId)
                .then(res => {
                    setInvoiceData(res.data.invoice)
                    setIsLoading(false)
                })
                .catch(err => {
                    setIsLoading(false)
                })
        },
        [invoiceId],
    )



    useEffect(() => {
        fetchShipmentProductDetails()
        return () => {
            setProductTimeline(null)
            setInvoiceData(null)
        }
    }, [fetchShipmentProductDetails])

    if (isProductLoading) return <Skeleton active/>

    return (
        <>
            {
                product &&
                <>
                    <Row>
                        <Col span={24}>
                            <Descriptions title="Product Info">
                                <Descriptions.Item label="Title">{product.title}</Descriptions.Item>
                                <Descriptions.Item label="Product Number">{product.product_number}</Descriptions.Item>
                                <Descriptions.Item label="Status">
                                    <StatusTag text={product?.status?.name} slug={product?.status?.slug}/>
                                </Descriptions.Item>
                                {
                                    product.category &&
                                    <Descriptions.Item label="Category">{product.category.name}</Descriptions.Item>
                                }
                                <Descriptions.Item label="Origin">{product.origin}</Descriptions.Item>
                                <Descriptions.Item label="Price">{product.price} {product.currency}</Descriptions.Item>
                                <Descriptions.Item label="Weight">{product.weight} KG</Descriptions.Item>
                                <Descriptions.Item label="Quantity">{product.quantity} Pieces</Descriptions.Item>

                                <Descriptions.Item label="Shipping Type">{product.shipping_type}</Descriptions.Item>
                                <Descriptions.Item label="Ship To"><Date date={product.ship_to}/></Descriptions.Item>
                                <Descriptions.Item label="Valid To"><Date date={product.valid_to}/></Descriptions.Item>
                                <Descriptions.Item
                                    label="Label Text">{product.label_text && product.label_text}</Descriptions.Item>
                                <Descriptions.Item label="Contains">
                                    {
                                        product.contains && product.contains.map((item, index) => {
                                            return (
                                                <Tag color="#108ee9" key={index + "contains"}
                                                     style={{marginBottom: '5%'}}>{item}</Tag>
                                            )
                                        })
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label=""></Descriptions.Item>
                                {
                                    product.link &&
                                    <Descriptions.Item label="Link">
                                        <a href={product.link}>{product.link}</a> <br/>
                                    </Descriptions.Item>
                                }
                                <Descriptions.Item label="Description">{product.description}</Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    {
                        product.width &&
                        <Row>
                            <Col span={24}>
                                <Descriptions title="Product Properties">
                                    <Descriptions.Item label="Width">{product.width} CM</Descriptions.Item>
                                    <Descriptions.Item label="Height">{product.height} CM</Descriptions.Item>
                                    <Descriptions.Item label="Length">{product.length} CM</Descriptions.Item>
                                    <Descriptions.Item label="CBM">{product.cbm} CM<sup>3</sup></Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                    }

                    {
                        product.order.customer &&
                        <>
                            <Row>
                                <Col span={24}>
                                    <Descriptions title="Customer">
                                        <Descriptions.Item
                                            label="Name">{product.order.customer.name}</Descriptions.Item>
                                        <Descriptions.Item
                                            label="Phone">{product.order.customer.phone}</Descriptions.Item>
                                        <Descriptions.Item
                                            label="Email">{product.order.customer.email}</Descriptions.Item>
                                        <Descriptions.Item
                                            label="Shipping mark">{product.order.customer.shipping_mark}</Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                            <Divider/>
                        </>
                    }
                    {
                        product.order &&
                        <>
                            <Row>
                                <Col span={24}>
                                    <Descriptions title="Order Info">
                                        <Descriptions.Item
                                            label="Order Number">{product.order.order_number}</Descriptions.Item>
                                        <Descriptions.Item label="Status">
                                            <StatusTag text={product?.order?.status?.name} slug={product?.order?.status?.slug}/>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Payment Status">
                                            <StatusTag text={product?.order?.payment_status?.name} slug={product?.order?.payment_status?.slug}/>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Created At">
                                            <Date date={product.order.created_at}/>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                            <Divider/>
                        </>
                    }

                    <Descriptions title="Images">
                    </Descriptions>
                    {
                        product.image_link && true ?
                            <Image
                                width={100}
                                src={updateSize(product.image_link, 800)}
                                alt={product.title}
                            />
                            :
                            <ImageGallery
                                images={product.images}
                            />
                    }
                    <Divider/>

                    {
                        product.charges &&
                        <>
                            <Row>
                                <Col span={24}>
                                    <Descriptions title="Product Charges">
                                    </Descriptions>
                                    <Table
                                        dataSource={product.charges}
                                        bordered={true}
                                        pagination={false}
                                    >
                                        <Column
                                            title="#SL"
                                            dataIndex="id"
                                            key="SL"
                                            align={"center"}
                                            render={(id, row) => (
                                                product.charges.indexOf(row) + 1
                                            )}
                                        />
                                        <Column
                                            title="Type"
                                            dataIndex="type"
                                            key="type"
                                            align={"center"}
                                        />
                                        <Column
                                            title="Amount"
                                            dataIndex="amount"
                                            key="amount"
                                            align={"center"}
                                        />
                                        <Column
                                            title="Note"
                                            dataIndex="note"
                                            key="note"
                                            align={"center"}
                                        />
                                    </Table>
                                </Col>
                            </Row>
                            <Divider/>
                        </>
                    }
                    <Row>
                        <Col span={12} offset={10}>
                            {
                                !productTimeline &&
                                <Button type="primary" onClick={() => fetchStatusLogs()} loading={isTimelineLoading}
                                        disabled={isTimelineLoading}>
                                    Show Timeline
                                </Button>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} offset={2} style={{marginTop: 20, marginBottom: 20}}>
                            {
                                productTimeline &&
                                <ShipmentProductTimeline response={productTimeline} isResponseLoading={isTimelineLoading}/>
                            }
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12} offset={10}>
                            {
                                !invoiceData &&
                                <Button type="primary" onClick={() => fetchItem()} loading={isLoading}
                                        disabled={isLoading}>
                                    Show Invoice
                                </Button>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{marginTop: 20, marginBottom: 20}}>
                            {
                                invoiceData &&
                                <ShipmentInvoiceDetails response={invoiceData} isResponseLoading={isLoading}/>
                            }
                        </Col>
                    </Row>
                </>
            }
        </>
    );
}

export default ProductDetail;
