import { ServiceNameEnum } from "./serviceNameEnum";

export class ServiceBaseUrl {
  private static PRODUCT_SERVICE_HOST =
    process.env.REACT_APP_PRODUCT_SERVICE_HOST;

  private static COMMERCE_SERVICE_HOST =
    process.env.REACT_APP_COMMERCE_SERVICE_HOST;

  private static CART_SERVICE_HOST =
    process.env.REACT_APP_CART_SERVICE_HOST;

  private static AUTH_SERVICE_HOST = process.env.REACT_APP_AUTH_SERVICE_HOST;

  private static LIVE_STREAMS_SERVICE_HOST =
    process.env.REACT_APP_LIVE_STREAMS_SERVICE_HOST;

  private static CMS_STRAPI_SERVICE_HOST =
    process.env.REACT_APP_CMS_STRAPI_SERVICE_HOST;

  public static getServiceBaseUrl(serviceType: ServiceNameEnum): string {
    switch (serviceType) {
      case ServiceNameEnum.COMMERCE_SERVICE:
        return `${this.COMMERCE_SERVICE_HOST}`;

      case ServiceNameEnum.PRODUCT_SERVICE:
        return `${this.PRODUCT_SERVICE_HOST}`;

      case ServiceNameEnum.CART_SERVICE:
        return `${this.CART_SERVICE_HOST}`;

      case ServiceNameEnum.AUTH_SERVICE:
        return `${this.AUTH_SERVICE_HOST}`;

      case ServiceNameEnum.LIVE_STREAMS_SERVICE:
        return `${this.LIVE_STREAMS_SERVICE_HOST}`;

      case ServiceNameEnum.CMS_STRAPI_SERVICE:
        return `${this.CMS_STRAPI_SERVICE_HOST}`;

      default:
        return "";
    }
  }
}
