export const FALLBACK_IMAGE_URL =
  "https://i.ibb.co/VDvYS53/output-onlinepngtools.png";

export const SUPER_ADMIN_SLUG = "super-admin";
export const ORDER_HANDLER_SLUG = "Order Handler";

export const DEFAULT_REGION_ID = "3";

export const UPLOAD_URL =
  "https://api.global.sandbox.moveon.work/commerce-service/api/storage/generic/asset/v1/assets";
