import { PageHeader } from "@ant-design/pro-layout";
import {
  Button,
  Form,
  Modal,
  message,
  Skeleton,
} from "antd";
import useDataFilters from "hooks/useDataFilters";
import { useAtom } from "jotai";
import {
  useCreatedPayoutGateway,
  useGetPayoutGateways,
  useUpdatePayoutGateway,
} from "lib/core-react/hooks/private/useWallet";
import { payoutGateWayCollectionAtom } from "lib/core-react/store/store";
import { ApiHelperModel } from "models/apiHelper";
import { MenuInfo } from "rc-menu/lib/interface";
import {
  PayoutGatewayCollectionModel,
  PayoutGatewayModel,
} from "models/payoutGatewayCollectionModel";
import { useEffect, useState } from "react";
import { PayoutGatewayModification } from "./components/PayoutGatewayModification";
import { PaymentGatewayLogoTypeEnums } from "enums/paymentGatewayCollectionEnums";
import { IPayoutGateWayFormRequest, Logo } from "types/payoutGatewayCollection";
import { getError } from "lib/core-react/hooks/utils/errors";
import PayoutGatewayMasterTable from "./payoutGatewaysMasterTable";

const PayoutGateways = () => {
  const { getPayoutGateWay } = useGetPayoutGateways();
  const { createdPayoutGateway, isLoading: isLoadingCreate } =
    useCreatedPayoutGateway();
  const { updatePayoutGateway, isLoading: isLoadingUpdate } =
    useUpdatePayoutGateway();

  const [form] = Form.useForm();

  const [{ data: payoutGatewayCollectionData, refetch, isLoading }] = useAtom(
    payoutGateWayCollectionAtom,
  );

  const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);

  const [selectedPayoutGateway, setSelectedPayoutGateway] = useState<
    PayoutGatewayModel | undefined
  >(undefined);

  const { filters, isFirstCall, isFetched, handleFilterChange , refetch: refetchFromFilter } =
    useDataFilters();

  // Api Call
  useEffect(() => {
    if ((!isFetched && isFirstCall) || refetch || refetchFromFilter) {
      ApiHelperModel.makeGetRequest({}, getPayoutGateWay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstCall, isFetched, refetch , refetchFromFilter]);

  const PayoutGatewayCollectionData =
    payoutGatewayCollectionData &&
    new PayoutGatewayCollectionModel(payoutGatewayCollectionData);

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getPayoutGateWay,
    );
  };

  const handleMenuClick = (e: MenuInfo, record: PayoutGatewayModel) => {
    setSelectedPayoutGateway(record);
    if (e.key === "update") {
      setIsShowCreateModal(true);
    }
  };

  const onFinish = async (values) => {
    try {
      const logos: Logo[] = [];

      if (values.small_logo) {
        logos.push({
          image_src: values.small_logo,
          type: PaymentGatewayLogoTypeEnums.SMALL,
        });
      }

      if (values.medium_logo) {
        logos.push({
          image_src: values.medium_logo,
          type: PaymentGatewayLogoTypeEnums.MEDIUM,
        });
      }

      const payload: IPayoutGateWayFormRequest = {
        name: values.name,
        slug: values.slug,
        type: values.type,
        country_id: values.country,
        logos,
      };

      if (selectedPayoutGateway) {
        await updatePayoutGateway(selectedPayoutGateway.getId(), payload);
        setSelectedPayoutGateway(undefined);
      } else {
        await createdPayoutGateway(payload);
      }
      setIsShowCreateModal(false);
    } catch (error) {
      message.error(getError(error));
    }
  };

  return (
    <div>
      <PageHeader
        ghost={false}
        title="Payout Gateway"
        style={{ marginTop: "10px" }}
        onBack={() => window.history.back()}
        extra={[
          <Button
            key="1"
            type="primary"
            onClick={() => setIsShowCreateModal(true)}
          >
            Add Payout Gateway
          </Button>,
        ]}
      >
        {PayoutGatewayCollectionData ? (
          <PayoutGatewayMasterTable
            isLoading={isLoading}
            payoutGatewayCollectionData={PayoutGatewayCollectionData}
            handlePaginationChange={handlePaginationChange}
            handleMenuClick={handleMenuClick}
          />
        ) : (
          <Skeleton />
        )}
      </PageHeader>
      <Modal
        title={`${selectedPayoutGateway ? "Update" : "Create"} Payout Gateway`}
        open={isShowCreateModal}
        onCancel={() => {
          setIsShowCreateModal(false);
          setSelectedPayoutGateway(undefined);
        }}
        onOk={form.submit}
        okText={selectedPayoutGateway ? "Update" : "Create"}
        okButtonProps={{ loading: isLoadingCreate || isLoadingUpdate }}
        centered
      >
        <PayoutGatewayModification
          data={selectedPayoutGateway}
          form={form}
          onFinish={onFinish}
        />
      </Modal>
    </div>
  );
};

export default PayoutGateways;
