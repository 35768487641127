import React, {useEffect, useState} from 'react'
import {getOAuthClients} from 'utils/customer-client'
import {Select, Spin} from 'antd';
import {nanoid} from "nanoid";
const {Option} = Select;

const ClientSelect = Props => {
    const {customer, handleClientSelect} = Props;
    const [fetching, setFetching] = useState(false);
    const [data, setData] = useState([]);

    const fetchClients = (customer) => {
      setData([]);
      setFetching(true);
      getOAuthClients(customer.id, 1, null, 1000).then(res => {
            setData(res.data?.data);
      }).catch(error => {

      }).finally(x => {
            setFetching(false);
      })
    };

    useEffect(() => {
      fetchClients(customer)
    }, [customer])

    const handleChange = value => {
        setData([]);
        setFetching(false);
        handleClientSelect(data.filter(item => item.id === value)[0])
    };

    return(
        <Select
            showSearch
            placeholder="Select A Website Client"
            notFoundContent={fetching ? <Spin size="small" /> : null}
            filterOption={false}
            onSelect={handleChange}
            style={{width: '100%'}}
        >
            {data.map((d, index) => (
                <Option key={nanoid()} value={d.id}>
                    {d.name} - {d.phone}
                </Option>
            ))}
        </Select>
    );
}

export default ClientSelect;
