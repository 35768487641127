import { IPagination } from "types/pagination";
import {
  StoreAvilabilityStatusEnums,
  StoreCollectionEnums,
} from "enums/storeCollectionEnums";
import { IStore, IStoreCollection } from "types/storeCollection";
import { IFilterType } from "types/filters";
import {formatDateTime} from "../utils/helpers";

export class StoreModel {
  object: StoreCollectionEnums.Store;
  id: number;
  name: string;
  identifier: string;
  availability_status: StoreAvilabilityStatusEnums;
  created_at: string;

  constructor(dataItem: IStore) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.name = dataItem.name;
    this.identifier = dataItem.identifier;
    this.availability_status = dataItem.availability_status;
    this.created_at = dataItem.created_at;
  }

  getId = () => this.id;
  getName = () => this.name;
  getIdentifier = () => this.identifier;
  getAvailabilityStatus = () => this.availability_status;
  getCreatedAt = () => formatDateTime(this.created_at);
}

export class StoreCollectionModel {
  object: StoreCollectionEnums.StoreCollection;
  data: StoreModel[];
  pagination: IPagination;
  filters?: IFilterType;

  constructor(data: IStoreCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new StoreModel(item));
    this.pagination = data.pagination;
    this.filters = data.filter;
  }
  getData = () => this.data;
  getFilters = () => this.filters;
  getPagination = () => this.pagination;
  getStoreById(id: number): StoreModel | undefined {
    return this.getData().find((item) => item.getId() === id);
  }

  getStoreLabeledValue() {
    return this.data.map((item) => ({
      label: item.getName(),
      value: item.getId(),
    }));
  }

  getStoreIdsAsObject() {
    const obj: { [key: number]: string } = {};
    this.getData().forEach((item) => (obj[item.getId()] = item.getName()));
    return obj;
  }
}
