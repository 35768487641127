import {
  Alert,
  Button,
  Form,
  Input,
  Modal,
  Select,
  Space,
  message,
} from "antd";
import { useAtom } from "jotai";
import { useGetRole } from "lib/core-react/hooks/private";
import { useDestinationWarehouseInvitationSend } from "lib/core-react/hooks/private/useDestinationWarehouse";
import { roleAtom } from "lib/core-react/store/store";
import { RoleCollectionModel } from "models/roleCollectionModel";
import { useEffect, useState } from "react";
import { IDestinationWarehouseMemberInvitationForm } from "types/destinationWarehouseCollection";
import { AddRole } from "./AddRole";

interface IProps {
  destinationWarehouseId: number;
  onHide: () => void;
}

export const SendInvitation = ({ destinationWarehouseId, onHide }: IProps) => {
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const {
    sendInvitation,
    isLoading: isLoadingSend,
    isError,
  } = useDestinationWarehouseInvitationSend();
  const { getRole } = useGetRole();

  const [form] = Form.useForm<IDestinationWarehouseMemberInvitationForm>();

  const [{ data: roleCollectionData, isLoading: isLoadingRole }] =
    useAtom(roleAtom);
  const RoleCollectionData =
    roleCollectionData && new RoleCollectionModel(roleCollectionData);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationWarehouseId]);

  useEffect(() => {
    getRole(`destination_warehouse_id=${destinationWarehouseId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationWarehouseId]);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const onFinishInvite = async (
    value: IDestinationWarehouseMemberInvitationForm,
  ) => {
    try {
      destinationWarehouseId &&
        (await sendInvitation(destinationWarehouseId, value));
      message.success("Invitation successfully sent");
      onHide();
      setIsErrorVisible(false);
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  return (
    <Form
      onFinish={onFinishInvite}
      form={form}
      layout="vertical"
      name="Send Invite"
    >
      <Form.Item
        rules={[
          {
            required: true,
            message: "User email is required",
          },
        ]}
        label="User email"
        name="user_email"
      >
        <Input placeholder="Enter user email" />
      </Form.Item>

      <Form.Item label="Roles">
        <Space>
          <Form.Item
            rules={[{ required: true, message: "Role is required" }]}
            name="role_ids"
            noStyle
          >
            <Select
              style={{ width: 260 }}
              loading={isLoadingRole}
              mode="multiple"
              placeholder="Please select roles"
              options={RoleCollectionData?.getRolesLabeledValue()}
            />
          </Form.Item>

          <Button onClick={() => setModalOpen(true)}>Add role?</Button>
        </Space>
      </Form.Item>

      <Form.Item>
        <Button
          loading={isLoadingSend}
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Submit
        </Button>
      </Form.Item>

      <Modal
        title={modalOpen && "Add Role"}
        open={Boolean(modalOpen)}
        footer={null}
        onCancel={() => setModalOpen(false)}
      >
        <AddRole
          onHide={() => setModalOpen(false)}
          destinationWarehouseId={destinationWarehouseId}
        />
      </Modal>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isError}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </Form>
  );
};
