import { BaseResource } from "../public";
import { ResponsePromise } from "lib/core/request/types/typings";
import { IPayoutGateWayFormRequest } from "types/payoutGatewayCollection";

class WalletResource extends BaseResource {
  pathBalance = `/api/wallet/admin/wallet/v1/master/balance`;
  path = `/api/wallet/admin/wallet/v1`;
  pathPayoutGateWay = `/api/wallet/admin/payout-gateway/v1/payout-gateways`;
  pathPayoutRequests = `/api/wallet/admin/payout-request/v1/payout-requests`;
  pathPayoutRequestUpdate = `/api/wallet/admin/payout-request/v1/payout-requests`;

  getOverviewBalance(customHeaders: Record<string, any> = {}): ResponsePromise {
    const path = `${this.pathBalance}`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  getTransactions(
    walletId: Number,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/master/${walletId}/transactions`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  getOverviewAgentCompanyBalance(
    agentCompanyId: Number,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/agent-company/${agentCompanyId}/balance`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  getTransactionsAgentCompany(
    agentCompanyId: Number,
    walletId: Number,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/agent-company/${agentCompanyId}/${walletId}/transactions`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  getOverviewUserBalance(
    userId: Number,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/user/${userId}/balance`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  getTransactionsUser(
    userId: Number,
    walletId: Number,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/user/${userId}/${walletId}/transactions`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  //Payout Gateways
  getPayoutGateWays(customHeaders: Record<string, any> = {}): ResponsePromise {
    let path = `${this.pathPayoutGateWay}`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  createPayoutGateWays(
    payload: IPayoutGateWayFormRequest,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.pathPayoutGateWay}`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }

  updatePayoutGateWays(
    id: number,
    payload: IPayoutGateWayFormRequest,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.pathPayoutGateWay}/${id}`;
    return this.client.request("PUT", path, payload, {}, customHeaders);
  }

  //Payout Gateways For Customer
  getPayoutGateWaysForCustomer(
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    let path = `${this.pathPayoutGateWay}`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  //Payout requests
  getPayoutRequests(
    params?: string,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    let path = `${this.pathPayoutRequests}`;
    if (params) {
      path = `${path}&${params}`;
    }
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  updatePayoutRequest(
    id: number,
    action: "processing" | "reject",
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.pathPayoutRequests}/${id}/${action}`;
    return this.client.request("PUT", path, {},undefined, customHeaders);
  }

  approvePayoutRequest(
    id: number,
    payload: {
      approved_amount: number;
    },
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.pathPayoutRequests}/${id}/approve`;
    return this.client.request("PUT", path, payload, {}, customHeaders);
  }
}

export default WalletResource;
