import { Col, Row } from "antd";
import { BuyActionModel } from "models/buyActionCollectionModel";
import ProductDetails from "./productDetails";
import ActionNeededIssuesType from "./ActionNeededIssuesType";
import { PageHeader } from "@ant-design/pro-layout/es/components/PageHeader";

interface IProps {
  selectedProduct: BuyActionModel;
}

const ActionViewDetailsModal: React.FC<IProps> = (selectedProduct) => {
  return (
    <>
      <Row>
        <Col span={24}>
          <PageHeader ghost={false} title="Product Information">
            <Col span={24}>
              <ProductDetails data={selectedProduct.selectedProduct} />
            </Col>

            <Col
              span={24}
              style={{
                marginTop: "30px",
                borderLeft: "5px solid #5755FE",
                padding: "10px 5px 10px 5px",
                background: "#FFF7FC",
              }}
            >
              {selectedProduct.selectedProduct.getSequences().map((item) => (
                <ActionNeededIssuesType data={item} />
              ))}
            </Col>
          </PageHeader>
        </Col>
      </Row>
    </>
  );
};

export default ActionViewDetailsModal;
