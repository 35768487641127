import { useState, useEffect, createRef } from "react";
import { useCreateBaseShippingCategory } from "lib/core-react/hooks/private";
import { BaseShippingCategoryRequestModel } from "models/baseShippingCategory";
import React from "react";
import { Alert, Button, Form, Input, Select } from "antd";
import { renderOptionsFromEnum } from "components/Form/forms";
import { BaseShippingCategoryDataStatus } from "enums/baseShippingCategoryCollectionEnums";
import { FormInstance } from "antd/es/form/Form";
import { Forms } from "models/form";

interface IProps {
  setIsShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const CreateBaseShippingCategory = ({ setIsShowCreateModal }: IProps) => {

  const { createBaseShippingCategory, isLoading, isError } =
  useCreateBaseShippingCategory();
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const formRef: React.Ref<FormInstance<any>> = createRef();
  const [form] = Form.useForm();

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [form]);

   //hide error message after 5 sec
   useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const onFinish = async (value: BaseShippingCategoryRequestModel) => {
    const payload = {
      name: value.name,
      status: value.status,
      slug: value.slug,
    };

    try {
      await createBaseShippingCategory(
        payload as BaseShippingCategoryRequestModel
      );
      setIsShowCreateModal(false);
      setIsErrorVisible(false);
    } catch (error) {
      setIsErrorVisible(true);
    }
  };
  const onValuesChange = (changedValues: any, allValues: any) => {
    Forms.formValueChange(form, changedValues, allValues);
  };

  return (
    <>
      <div>
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          name="Create_Base_Shipping_Category"
          ref={formRef}
          onFieldsChange={onValuesChange}
        >
          <Form.Item
            rules={[
              {
                required: true,
                message: "Base shipping category name is required",
              },
            ]}
            label="Name"
            name="name"
          >
            <Input placeholder="Base shipping category name" />
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: "Slug is required" }]}
            label="Slug"
            name="slug"
          >
            <Input readOnly placeholder="Slug" />
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: "Status is required" }]}
            name="status"
            label="Status"
          >
            <Select placeholder="Please select a Status">
              {renderOptionsFromEnum(BaseShippingCategoryDataStatus)}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              loading={isLoading}
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
            >
              Submit
            </Button>
          </Form.Item>
          
          {isErrorVisible && (
            <Alert
              style={{ marginTop: "7px" }}
              message={isError}
              closable
              afterClose={() => setIsErrorVisible(false)}
              type="error"
              showIcon
            />
          )}
        </Form>
      </div>
    </>
  );
};

export default CreateBaseShippingCategory;
