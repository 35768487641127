import { Alert, Button, Form, message, Input } from "antd";
import { useCopyExchange } from "lib/core-react/hooks/private/useExchange";
import React, { useEffect, useState } from "react";
import { IExchangeList } from "types/exchangeListCollection";

interface IProps {
  selectedExchange: IExchangeList;
  setIsShowCopyModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CopyExchange = ({ selectedExchange, setIsShowCopyModal }: IProps) => {
  const [form] = Form.useForm();
  const { copyExchange, isError, isLoading } = useCopyExchange();
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const onFinish = async (value:{name:string}) => {
    const data = {
      name: value.name
    }
    try {
      const response = await copyExchange(selectedExchange.id, data);
      setIsShowCopyModal(false);
      setIsErrorVisible(false);
      message.success(response);
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  return (
    <>
      <Form form={form} layout="vertical"  onFinish={onFinish}>
        <Form.Item>
          <h5>
            Are you sure you want to make a copy of {selectedExchange.name} ?
          </h5>
        </Form.Item>

        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please enter name' }]}
        >
          <Input placeholder="Enter the name" />
        </Form.Item>


        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button style={{minWidth:"100px"}}  onClick={() => setIsShowCopyModal(false)}>No</Button>
          <Button htmlType="submit" style={{marginLeft:"10px" , minWidth:"100px"}} type="primary" loading={isLoading}>
            Yes
          </Button>
          </div>
        </Form.Item>
      </Form>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isError}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </>
  );
};

export default CopyExchange;
