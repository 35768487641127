import { Dispatch, SetStateAction } from "react";

import { ITrackerReplyCommentFormData } from "types/timelineTracking";
import { FormInstance } from "antd";

interface IProps {
  commentContent: string;
  selectedCommentIdToReply: number | undefined;
  form: FormInstance<any>;
  setCommentContent: Dispatch<SetStateAction<string>>;
  setSelectedCommentIdToReply: Dispatch<SetStateAction<number | undefined>>;
  message: any;
  setIsErrorVisible: (value: SetStateAction<boolean>) => void;
  replyComment: (
    id: number,
    payload: ITrackerReplyCommentFormData,
    productId:string,
  ) => Promise<any>;
  setCustomError: Dispatch<SetStateAction<string | null>>;
  productId:string;
}

export const handleReplyComment = async ({
  commentContent,
  setCommentContent,
  selectedCommentIdToReply,
  setSelectedCommentIdToReply,
  replyComment,
  form,
  message,
  setIsErrorVisible,
  setCustomError,
  productId

}: IProps) => {
  // Check if comment contains only a single empty HTML tag
  const tagRegex = /^<\w+><\/\w+>$/;
  if (!commentContent || tagRegex.test(commentContent)) {
    setIsErrorVisible(true);
    setCustomError("Comment message cannot be empty");
    return;
  }

  const mentioned_user_ids: number[] = [];

  // parse mentioned user ids
  const regex = /\/profile\/(\d+)/g;
  let match;
  while ((match = regex.exec(commentContent)) !== null) {
    mentioned_user_ids.push(Number(match[1]));
  }

  if (selectedCommentIdToReply) {
    try {
      const payload: ITrackerReplyCommentFormData = {
        mentioned_user_ids,
        message: commentContent,
      };

      await replyComment(selectedCommentIdToReply, payload, productId);
      form.resetFields();
      setCommentContent("");
      setSelectedCommentIdToReply(undefined);
      message.success("Added reply to comment successfully");
      setIsErrorVisible(false);
    } catch (error) {
      setIsErrorVisible(true);
    }
  }
};
