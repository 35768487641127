/* eslint-disable react-hooks/exhaustive-deps */
import { PageHeader } from "@ant-design/pro-layout";
import {
  Button,
  Card,
  Col,
  Dropdown,
  MenuProps,
  Modal,
  Row,
  Table, Tag,
} from "antd";
import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { exchangeGroupCollectionAtom } from "lib/core-react/store/store";
import useDataFilters from "hooks/useDataFilters";
import { ApiHelperModel } from "models/apiHelper";
import {useDeleteExchangeGroup, useGetExchangeGroup} from "lib/core-react/hooks/private/useExchangeGroup";
import {
  ExchangeGroupCollectionModel,
  ExchangeGroupModel,
} from "models/exchangeGroupCollectionModel";
import {
  DeleteOutlined,
  DownOutlined,
  HddFilled,
  TeamOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import CreateExchangeGroup from "./components/CreateExchangeGroup";
import UpdateExchangeGroup from "./components/UpdateExchangeGroup";
import FiltersComponent from "components/FiltersComponent";
import { PaginationModel } from "models/pagination";
import ExchanegGroupCustomersTable from "./components/ExchangeGroupCustomersTable";
import { IFilterType } from "types/filters";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";

const ExchangeGroup = () => {
  const { getExchangeGroup } = useGetExchangeGroup();
  const [{ data: exchangeGroupStoreData, isLoading, refetch }] = useAtom(
    exchangeGroupCollectionAtom,
  );
  const { deleteExchangeGroup} = useDeleteExchangeGroup();
  const {isMobile} = useWindowWidth()

  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
    refetch: refetchFromFilter
  } = useDataFilters();

  // Api Call
  useEffect(() => {
    if ((!isFetched && isFirstCall) || refetch || refetchFromFilter) {
      ApiHelperModel.makeGetRequest({}, getExchangeGroup);
    }
  }, [isFirstCall, isFetched, refetch , refetchFromFilter]);

  const ExchangeGroupCollectionData =
    exchangeGroupStoreData &&
    new ExchangeGroupCollectionModel(exchangeGroupStoreData);

  // Filter
  const filterData = ExchangeGroupCollectionData?.getFilters();
  // Pagination
  const paginationData = ExchangeGroupCollectionData?.getPagination();

  const [selectedExchangeGroup, setSelectedExchangeGroup] = useState<
    ExchangeGroupModel | undefined
  >(undefined);
  const [isShowUpdateModal, setIsShowUpdateModal] = useState<boolean>(false);
  const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);
  const [isShowCustomerModal, setIsShowCustomerModal] =
    useState<boolean>(false);

  const handleMenuClick: MenuProps["onClick"] = async(e) => {
    if (e.key === "update") {
      setIsShowUpdateModal(true);
    } else if (e.key === "delete" && selectedExchangeGroup) {
      await deleteExchangeGroup(selectedExchangeGroup.id);
    }
  };

  // Getting all available filters
  useEffect(() => {
    if (!isFetched && ExchangeGroupCollectionData?.getFilters()) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [
    isFetched,
    initializeAvailableFilter,
    ExchangeGroupCollectionData?.getFilters(),
  ]);

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getExchangeGroup,
    );
  };
  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    ExchangeGroupCollectionData,
    handlePaginationChange,
  );
  // Filter Handler
  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getExchangeGroup);
  };

  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      width:70,
      key: "sl",
      render: (_: string, __: ExchangeGroupModel, index: number) => {
        return (
          <div>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </div>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_: string, record: ExchangeGroupModel) => {
        return <div>{record.getName()}</div>;
      },
    },
    {
      title: "Exchange list",
      dataIndex: "exchange_list",
      key: "exchange_list",
      render: (_: string, record: ExchangeGroupModel) => {
        return <div>{record.getExchangeList().getName()}</div>;
      },
    },
    {
      title: "Customers",
      dataIndex: "customers",
      hidden:isMobile,
      key: "customers",
      align: "center" as const,
      render: (_: string, record: ExchangeGroupModel) => {
        return (
          <Button
            onClick={() => {
              setSelectedExchangeGroup(record);
              setIsShowCustomerModal(true);
            }}
            icon={<TeamOutlined />}
          >
            Customers
          </Button>
        );
      },
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      hidden:isMobile,
      key: "created_at",
      render: (_: string, record: ExchangeGroupModel) => {
        return <Tag color={"purple"}>{record.getCreatedAt()}</Tag>;
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: isMobile ? 100: 150,
      fixed:isMobile? "right" :undefined,
      render: (_: string, record: ExchangeGroupModel) => {

        const items: MenuProps["items"] = [
          {
            icon: <UsergroupAddOutlined />,
            label: "Update Group",
            key: "update",
          },
          { icon: <DeleteOutlined />, label: "Delete Group", key: "delete" },
        ];

        if(isMobile){
          items.push(
            {
              label: "Customers",
              key: "customers",
              icon: <TeamOutlined/>,
              onClick: ()=> {
                setSelectedExchangeGroup(record);
                setIsShowCustomerModal(true);
              }
            },
            {
            label: <Tag color={"purple"}>{record.getCreatedAt()}</Tag>,
            disabled:isMobile,
            key: "created_at",
          },)
        }

        const menuProps = {
          items,
          onClick: handleMenuClick,
        };

        return (
          <Dropdown
            menu={menuProps}
            onOpenChange={() => setSelectedExchangeGroup(record)}
          >
            <Button icon={<HddFilled />}>

              {isMobile ?<DownOutlined /> : <> Actions  <DownOutlined /></> }

            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <PageHeader
            ghost={false}
            title="Exchange Group"
            style={{ marginTop: "10px" }}
            extra={[
              <Button
                key="1"
                type="primary"
                onClick={() => setIsShowCreateModal(true)}
              >
                Create Exchange Group
              </Button>,
            ]}
            onBack={() => window.history.back()}
          >
            {filters && Object.keys(filters).length > 0 && (
              <Row>
                <Col span={24}>
                  <Card style={{marginBottom:"10px"}} title="Filter">
                    <FiltersComponent
                      handleProductFilter={handleProductFilter}
                      handleFilterChange={handleFilterChange}
                      handelFilterClear={handelFilterClear}
                      isFetched={isFetched}
                      filters={filters}
                      filtersData={filterData}
                      isFromProductReceived={true}
                    />
                  </Card>
                </Col>
              </Row>
            )}
            <div>
              <Table
                loading={isLoading}
                rowKey="id"
                bordered={true}
                dataSource={ExchangeGroupCollectionData?.getData()}
                //@ts-ignore
                columns={columns}
                pagination={paginationConfig}
                scroll={{x:1000}}
              />
            </div>
          </PageHeader>
        </Col>
      </Row>

      <Modal
        title={`Create Exchange Group`}
        open={isShowCreateModal}
        footer={false}
        onCancel={() => setIsShowCreateModal(false)}
      >
        <CreateExchangeGroup setIsShowCreateModal={setIsShowCreateModal} />
      </Modal>

      {selectedExchangeGroup && (
        <Modal
          title={`Update Exchange Group`}
          open={isShowUpdateModal}
          footer={false}
          onCancel={() => setIsShowUpdateModal(false)}
        >
          <UpdateExchangeGroup
            setIsShowUpdateModal={setIsShowUpdateModal}
            selectedExchangeGroup={selectedExchangeGroup}
          />
        </Modal>
      )}

      {selectedExchangeGroup && (
        <Modal
          open={isShowCustomerModal}
          onCancel={() => {
            setIsShowCustomerModal(false);
          }}
          width={isMobile? undefined:"60vw"}
          footer={null}
          title={`Customers (${selectedExchangeGroup.getName()})`}
        >
          <ExchanegGroupCustomersTable data={selectedExchangeGroup} />
        </Modal>
      )}
    </>
  );
};

export default ExchangeGroup;
