import {Card, Descriptions, Divider, List, Space, Spin, Timeline, Typography} from 'antd'
import {formatDateTime} from "utils/helpers";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import useFilters from "hooks/useFilters";
import Text from "antd/es/typography/Text";
import {Link} from 'react-router-dom';
import {getIssues} from "app/actions/issues";
import {ArrowDownOutlined} from "@ant-design/icons";
import IssueMetaDetails from "components/issues/IssueMetaDetails";
import IssueMetaHistoryDetails from "components/issues/IssueMetaHistoryDetails";
import {StatusTag} from "../index";

const {Paragraph} = Typography;

const IssueDetails = (props) => {
  const {itemsDetails, cameFrom} = props
  const dispatch = useDispatch();
  const { filters, handleFilterChange } = useFilters();

  const fx = itemsDetails.fx
  const mv_comm = itemsDetails.mv_comm

  const {
    itemIssues,
    itemIssueLoading,
  } = useSelector((state) => state.issues);

  let itemID = itemsDetails?.order_item_id

  if (cameFrom === "item_details") {
    itemID = itemsDetails?.id
  }

  useEffect(() => {
    if (itemID) {
      dispatch(getIssues({issueable_id: itemID}));
    }
  }, [dispatch, itemID]);

  const handlePaginationChange = (pageCount, pageSize) => {
    handleFilterChange({page: pageCount, per_page: pageSize});
    dispatch(getIssues({...filters, ...{page: pageCount, per_page: pageSize}}));
  };

  const paginationConfig = itemIssues?.meta?.last_page > 1
    ? {
      current: itemIssues.meta.current_page,
      showSizeChanger: true,
      total: itemIssues.meta.total,
      pageSize: itemIssues.meta.per_page,
      onChange: handlePaginationChange,
    }
    : false;

  return (
    <Spin spinning={itemIssueLoading}>
      {itemIssues?.data?.length > 0 && (
        <Card bordered={false} title="Issues related to this item" className="card-padding">
          <List
            loading={itemIssueLoading}
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 1,
              xl: 1,
              xxl: 2,
            }}
            dataSource={itemIssues.data}
            pagination={paginationConfig}
            renderItem={(item) => {
              let actionText = null

              if (item?.meta?.customer_action) {
                if (item?.meta?.customer_action?.action === "purchase") {
                  actionText = "Customer Want to Purchase at"
                } else if (item?.meta?.customer_action?.action === "refund") {
                  actionText = "Customer Want to Refund at"
                } else if (item?.meta?.customer_action?.action === "cancel") {
                  actionText = "Customer Want to Cancel at"
                }
              } else {
                actionText = "Action is not taken"
              }

              return(<List.Item>
                <Descriptions
                  bordered
                  column={1}
                  size="small"
                >
                  <Descriptions.Item label="Issue Status">
                    <StatusTag text={item.status_label} slug={item.status}/>
                  </Descriptions.Item>
                  {
                    item?.admin_note &&
                    <Descriptions.Item label="Admin Note">
                      <Paragraph ellipsis={{rows: 2, expandable: true, symbol: 'more'}}>
                        {item?.admin_note}
                      </Paragraph>
                    </Descriptions.Item>
                  }
                  <Descriptions.Item label="Customer Note">
                    <Paragraph ellipsis={{rows: 2, expandable: true, symbol: 'more'}}>
                      {item?.customer_note}
                    </Paragraph>
                  </Descriptions.Item>
                  <Descriptions.Item label="Item ID">
                    <Link to={`/item/${itemID}`}>#{itemID}</Link>
                  </Descriptions.Item>
                  {
                    item?.type &&
                    <Descriptions.Item label="Type">
                      {item?.type_label}
                    </Descriptions.Item>
                  }
                  <Descriptions.Item label="Create Time">
                    {formatDateTime(item?.created_at)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Reasonable Details">
                    <Space direction="vertical">
                      {
                        actionText === "Action is not taken" ?
                            <Text strong type="danger">{actionText}</Text>
                            :
                            <>
                              {
                                  item?.meta?.customer_action &&
                                    <span>{actionText} <Text strong type="danger">{formatDateTime(item?.meta?.customer_action?.action_at)}</Text></span>
                              }
                              <Divider dashed style={{margin: '0px'}}>Details</Divider>
                              {
                                  item?.meta &&
                                      <IssueMetaDetails selectedMeta={item?.meta} itemDetails={itemsDetails}/>
                              }
                            </>
                      }
                    </Space>
                  </Descriptions.Item>
                  <Descriptions.Item label="Action Timeline">
                    <Space direction="vertical">
                      <Timeline style={{ marginTop: '2%' }}>
                        {
                            item && item.history.map((item, index) => {
                              return (
                                  <Timeline.Item key={index} dot={<ArrowDownOutlined />}>
                                    <Text style={{ textTransform: 'uppercase' }} strong type="warning">{item?.action_by?.type}</Text> -&nbsp;
                                    <Text strong type="success">{formatDateTime(item.created_at)}</Text> -&nbsp;
                                    {item?.visibility !== "admin" && <Text type="danger">{item?.visibility.toUpperCase()}</Text>}
                                    <strong>by <a href={`/users/${item.action_by.id}`} target="_blank" rel="noreferrer">{item?.action_by?.name ?? item?.action_by?.shipping_mark}</a></strong><br/>
                                    {
                                      item?.meta &&
                                        <IssueMetaHistoryDetails selectedMeta={item.meta} totalFx={((fx * mv_comm) / 100) + fx}/>
                                    }
                                  </Timeline.Item>
                              )
                            })
                        }
                      </Timeline>
                    </Space>
                  </Descriptions.Item>
                </Descriptions>
              </List.Item>
            )
            }}
          />
        </Card>
      )}
    </Spin>
  )
}

export default IssueDetails;
