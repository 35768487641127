import qs from "qs";
import { IFilterType } from "types/filters";
export class ApiHelperModel {
  static makeGetRequest = async (
    filters: IFilterType,
    getRequest: (data: string) => Promise<void>
  ): Promise<void> => {
    await getRequest(qs.stringify({ ...filters }));
  };
}
