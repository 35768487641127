import React, { useEffect, useState, createRef } from "react";
import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  notification,
} from "antd";
import { FormInstance } from "antd/es/form/Form";

import { Forms } from "models/form";

import {
  CampaignModel,
  UpdateCampaignPostRequestModel,
} from "models/campaignCollectionModel";
import { useUpdateCampaign } from "lib/core-react/hooks/private/useCampaign";
import TextArea from "antd/lib/input/TextArea";
import {
  renderOptionsFromEnum,
  renderOptionsFromEnumUpdateCampainsPublished,
} from "components/Form/forms";
import { PublishStatusEnum } from "enums/campaignCollectionEnums";
import { useAtom } from "jotai";
import { regionRegion } from "lib/core-react/store/store";
import { RegionModel } from "models/region";
import dayjs from "dayjs";
import { CampaignStripeModel } from "models/campaignStripModel";
import { ServiceBaseUrl, ServiceNameEnum } from "lib/core/apiServices/servicBaseUrl";

interface IProps {
  setIsShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCampaign: CampaignModel;
}

const UpdateCampaign = ({ setIsShowUpdateModal, selectedCampaign }: IProps) => {
  const { updateCampaign, isLoading, isError } = useUpdateCampaign();
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [data, setData] = useState<CampaignStripeModel[] | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState(null);
  const dataStrpi = data || [];

  const [{ data: regionDataAtom, isLoading: regionLoading }] =
    useAtom(regionRegion);

  const regionData = regionDataAtom && new RegionModel(regionDataAtom);

  const formRef: React.Ref<FormInstance<any>> = createRef();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  useEffect(() => {
    form.setFieldsValue({
      region_id: selectedCampaign.getRegion().getId().toString(),
      title: selectedCampaign.getTitle(),
      slug: selectedCampaign.getSlug(),
      strapi_content_slug: selectedCampaign.getStrapiContentSlug(),
      start_at: dayjs(selectedCampaign.getStartAt()),
      finish_at: dayjs(selectedCampaign.getFinishAt()),
      publishing_status: selectedCampaign.getPublishStatus(),
    });
  }, [selectedCampaign, form]);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${ServiceBaseUrl.getServiceBaseUrl(ServiceNameEnum.CMS_STRAPI_SERVICE)}/campaigns`);
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        const result = await response.json();
        setData(result.data);
      } catch (err: any) {
        setError(err);
      }
    };

    fetchData();
  }, []);

  const onFinish = async (value: UpdateCampaignPostRequestModel) => {
    const formattedValues = {
      ...value,
      start_at: dayjs(value.start_at).format("YYYY-MM-DD HH:mm:ss"),
      finish_at: dayjs(value.finish_at).format("YYYY-MM-DD HH:mm:ss"),
    };

    try {
      await updateCampaign(selectedCampaign.getId(), formattedValues);
      notification["success"]({
        message: "Updated Campagin successfully",
      });
      setIsShowUpdateModal(false);
    } catch (error: any) {
      if (error?.response?.data?.message) {
        notification["error"]({
          message: error.response.data.message,
        });
      }
    }
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    Forms.formValueChange(form, changedValues, allValues);
  };
  return (
    <Form
      onFinish={onFinish}
      form={form}
      layout="vertical"
      name="Create Campaign"
      onFieldsChange={onValuesChange}
      ref={formRef}
    >
      <Form.Item
        rules={[{ required: true, message: "Region is required" }]}
        name={["region_id"]}
        label="Region"
      >
        <Select loading={regionLoading} placeholder="Please select a region">
          {regionData?.getRegionCodesAsObject() &&
            renderOptionsFromEnum(regionData?.getRegionCodesAsObject())}
        </Select>
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Title is required" }]}
        label="Title"
        name="title"
      >
        <TextArea rows={4} placeholder="Please write your title" />
      </Form.Item>

      <Form.Item
        rules={[
          {
            required: true,
            message: "slug id is required",
          },
        ]}
        label="Slug"
        name="slug"
      >
        <Input placeholder="Create the Slug" />
      </Form.Item>

      <Form.Item
        label="Strapi Content Slug"
        name="strapi_content_slug"
        rules={[{ required: true, message: "Slug is required" }]}
      >
        <Select placeholder="Please select your Strapi Content Slug">
          {dataStrpi &&
            dataStrpi.map((item) => (
              <Select.Option key={item.id} value={item.attributes.slug}>
                {item.attributes.title}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Start At"
        name="start_at"
        rules={[{ required: true, message: "Please input the start date!" }]}
      >
        <DatePicker
          showTime={{ defaultValue: dayjs("00:00:00", "HH:mm:ss") }}
          format="YYYY-MM-DD HH:mm:ss"
        />
      </Form.Item>

      <Form.Item
        label="Finish At"
        name="finish_at"
        rules={[{ required: true, message: "Please input the finish date!" }]}
      >
        <DatePicker
          showTime={{ defaultValue: dayjs("00:00:00", "HH:mm:ss") }}
          format="YYYY-MM-DD HH:mm:ss"
        />
      </Form.Item>
      <Form.Item
        name="publishing_status"
        label="Select Publishing Status"
        rules={[{ required: true }]}
      >
        <Select placeholder="Please select a Slug">
          {renderOptionsFromEnumUpdateCampainsPublished(PublishStatusEnum)}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Submit
        </Button>
      </Form.Item>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isError}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </Form>
  );
};

export default UpdateCampaign;
