import { Link } from "react-router-dom";
import { useState } from "react";
import {
  Avatar,
  Button,
  Dropdown,
  Modal,
  Row,
  Table,
  notification,
  Tag,
  Typography,
  Image,
  MenuProps,
  TablePaginationConfig,
} from "antd";
import { ShipmentOrderCollectionModel } from "models/shipmentOrderCollectionModel";
import {
  ArrowRightOutlined,
  BorderOuterOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  DownOutlined,
  EditOutlined,
  HddFilled,
  HomeTwoTone,
  RadiusBottomleftOutlined,
  RadiusUpleftOutlined,
  UnorderedListOutlined,
  VerticalLeftOutlined,
} from "@ant-design/icons";

import { useRejectedShippingProduct } from "lib/core-react/hooks/private/useShipping";
import { ShipmentProductModel } from "models/shipmentProductCollectionModel";
import { ShippingModesEnums, ShippingTypesEnums } from "enums/shippingEnums";
import { ICategoryPriceDetails } from "types/agentCategoryPriceReadCollection";

import {
  getShippingModeKeyByValue,
  getShippingTypeKeyByValue,
} from "pages/Shipping/ShipmentProducts/utils";
import {
  BidList,
  Carton,
  ChangeDestinationWarehouseModal,
  ChangeShippingCategoryModal,
  ChangeShippingCountryModal,
  ChangeShippingModeModal,
  ChangeShippingTypeModal,
  SelectWarehouseModal,
} from "pages/Shipping/ShipmentProducts";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";

const { Column } = Table;

interface IProps {
  data?: ShipmentProductModel[];
  isLoading?: boolean;
  paginationConfig?: false | TablePaginationConfig;
}

const ShipmentProductsTable = ({
  data,
  isLoading,
  paginationConfig,
}: IProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [selectedRows, setSelectedRows] = useState<ShipmentProductModel[]>([]);

  const { isMobile } = useWindowWidth();

  // to handle single product actions
  const [selectedProduct, setSelectedProduct] =
    useState<ShipmentProductModel>();

  // hooks
  const { rejectedShippingProduct, isLoading: isRejectedLoading } =
    useRejectedShippingProduct();

  // State
  const [modalOpen, setModalOpen] = useState<
    "carton" | "reject" | "assignAgent" | "bidList"
  >();

  const [categoryPriceDetailsInfo, setCategoryPriceDetailsInfo] = useState<
    ICategoryPriceDetails | undefined
  >(undefined);

  const agentAssignHandler = (record?: ShipmentProductModel) => {
    const firstRow = record ? record : selectedRows[0];
    const categoryDetails: ICategoryPriceDetails = {
      user_id: firstRow.getUserId(),
      region: firstRow.getRegionCode(),
      shipping_category_id: firstRow.getShippingCategoryId(),
      destination_warehouse_id: firstRow.getDestinationWarehouseId(),
      shipping_type: firstRow.getShippingType(),
      shipping_mode: firstRow.getShippingMode(),
      shipping_country_id: firstRow.getShippingCountry().getId(),
    };
    setCategoryPriceDetailsInfo(categoryDetails);
    setModalOpen("assignAgent");
  };

  const rejectedModalOkHandler = async () => {
    if (selectedProduct) {
      try {
        const data = await rejectedShippingProduct(
          selectedProduct.getId(),
        );
        if (data) {
          notification["success"]({
            message: data.message,
          });
          setModalOpen(undefined);
        }
      } catch (error: any) {
        notification["error"]({
          message: error.response.data.message,
        });
      }
    }
  };

  // Change shipping Country -
  const [changeShippingCountryDetails, setChangeShippingCountryDetails] =
    useState<{
      isOpen: boolean;
      productIds: number[] | undefined;
      existingShippingCountryId: number | undefined;
    }>({
      isOpen: false,
      productIds: undefined,
      existingShippingCountryId: undefined,
    });
  const changeShippingCountryHandler = (
    ids: number[],
    shippingCountryId: number | undefined,
  ) => {
    setChangeShippingCountryDetails({
      existingShippingCountryId: shippingCountryId,
      isOpen: true,
      productIds: ids,
    });
  };
  // Change Destination warehouse
  const [
    changeDestinationWarehouseDetails,
    setChangeDestinationWarehouseDetails,
  ] = useState<{
    isOpen: boolean;
    productIds: number[] | undefined;
    existingWarehouseId: number | undefined;
    existingCountryId: number | undefined;
  }>({
    isOpen: false,
    productIds: undefined,
    existingWarehouseId: undefined,
    existingCountryId: undefined,
  });

  const changeDestinationWarehouseHandler = (
    ids: number[],
    warehouseId: number | undefined,
    countryId: number | undefined,
  ) => {
    setChangeDestinationWarehouseDetails({
      existingCountryId: countryId,
      existingWarehouseId: warehouseId,
      isOpen: true,
      productIds: ids,
    });
  };
  // Change Shipping category
  const [changeShippingCategoryDetails, setChangeShippingCategoryDetails] =
    useState<{
      isOpen: boolean;
      productIds: number[] | undefined;
      existingId: string | undefined;
    }>({
      isOpen: false,
      productIds: undefined,
      existingId: undefined,
    });

  const changeShippingCategoryHandler = (
    ids: number[],
    categoryId: string | undefined,
  ) => {
    setChangeShippingCategoryDetails({
      existingId: categoryId,
      isOpen: true,
      productIds: ids,
    });
  };
  // Change Shipping mode
  const [changeShippingModeDetails, setChangeShippingModeDetails] = useState<{
    isOpen: boolean;
    productIds: number[] | undefined;
    existingData: ShippingModesEnums | undefined;
  }>({
    isOpen: false,
    productIds: undefined,
    existingData: undefined,
  });

  const changeShippingModeHandler = (
    ids: number[],
    shippingMode: ShippingModesEnums | undefined,
  ) => {
    setChangeShippingModeDetails({
      existingData: shippingMode,
      isOpen: true,
      productIds: ids,
    });
  };
  // Change Shipping type
  const [changeShippingTypeDetails, setChangeShippingTypeDetails] = useState<{
    isOpen: boolean;
    productIds: number[] | undefined;
    existingData: ShippingTypesEnums | undefined;
  }>({
    isOpen: false,
    productIds: undefined,
    existingData: undefined,
  });

  const changeShippingTypeHandler = (
    ids: number[],
    shippingType: ShippingTypesEnums | undefined,
  ) => {
    setChangeShippingTypeDetails({
      existingData: shippingType,
      isOpen: true,
      productIds: ids,
    });
  };

  const onCancelModal = () => {
    setSelectedProduct(undefined);
    setModalOpen(undefined);
    setCategoryPriceDetailsInfo(undefined);
  };

  const bulkItems: MenuProps["items"] = [
    {
      icon: <CheckCircleTwoTone />,
      label: "Approve and Assign Agent",
      key: "approve-and-assign-agent",
      onClick: () => agentAssignHandler(),
    },
    {
      icon: <VerticalLeftOutlined />,
      label: "Change source country",
      key: "change-source-country",
      onClick: () =>
        changeShippingCountryHandler(
          selectedRowKeys.map((item) => Number(item)),
          undefined,
        ),
    },
    {
      icon: <HomeTwoTone />,
      label: "Change destination warehouse",
      key: "change-destination-warehouse",
      onClick: () =>
        changeDestinationWarehouseHandler(
          selectedRowKeys,
          undefined,
          selectedRows[0].getDestinationCountry().getId(),
        ),
    },
    {
      icon: <BorderOuterOutlined />,
      label: "Change shipping category",
      key: "change-shipping-category",
      onClick: () =>
        changeShippingCategoryHandler(
          selectedRowKeys.map((item) => Number(item)),
          undefined,
        ),
    },
    {
      icon: <RadiusBottomleftOutlined />,
      label: "Change shipping type",
      key: "change-shipping-type",
      onClick: () =>
        changeShippingTypeHandler(
          selectedRowKeys.map((item) => Number(item)),
          undefined,
        ),
    },
    {
      icon: <RadiusUpleftOutlined />,
      label: "Change shipping mode",
      key: "change-shipping-mode",
      onClick: () =>
        changeShippingModeHandler(
          selectedRowKeys.map((item) => Number(item)),
          undefined,
        ),
    },
  ];

  const { Text } = Typography;

  return (
    <>
      <div style={{ marginTop: "15px" }}>
        <div style={{ margin: "20px 0", textAlign: "right" }}>
          <Dropdown
            menu={{
              items: bulkItems,
            }}
            disabled={selectedRowKeys.length === 0}
          >
            <Button icon={<HddFilled />}>
              Bulk Actions
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
        <Table
          loading={isLoading}
          dataSource={data}
          rowKey="id"
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            type: "checkbox",
            onChange: (selectedRowKeys, selectedRows) => {
              const rowsAsNumber = selectedRowKeys.map((key) => Number(key));
              setSelectedRowKeys(rowsAsNumber);
              setSelectedRows(selectedRows);
            },
          }}
          bordered
          scroll={{ x: 1237 }}
          pagination={paginationConfig}
        >
          <Column
            ellipsis
            title="Product Details"
            dataIndex="product_details"
            key="product_details"
            width={350}
            render={(_: string, record: ShipmentProductModel) => (
              <div style={{ display: "flex", gap: "16px" }}>
                <Avatar
                  shape="square"
                  size={80}
                  style={{ flex: "none" }}
                  src={
                    <Image src={`${record.getImages().getFirstImageSrc()}`} />
                  }
                />
                <div>
                  <Text
                    style={{ width: 200 }}
                    ellipsis={{
                      tooltip: record.getTitle(),
                    }}
                  >
                    {record.getTitle()}
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>Order no:</Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 5,
                        fontWeight: "500",
                      }}
                    >
                      {record.getOrder().getOrderNumber()}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>Order status:</Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 5,
                        fontWeight: "500",
                      }}
                    >
                      {record.getOrder().getStatus()}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>Product no:</Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 5,
                        fontWeight: "500",
                      }}
                    >
                      {record.getProductNumber()}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>Product id:</Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 5,
                        fontWeight: "500",
                      }}
                    >
                      {record.getId()}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>Status:</Text>
                    <Tag
                      color={
                        ShipmentOrderCollectionModel.getStatusLabel(
                          record.getStatus(),
                        )?.color || ""
                      }
                    >
                      {record.getStatusLabel()}
                    </Tag>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>Price:</Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 5,
                        fontWeight: "500",
                      }}
                    >
                      {Math.ceil(record.getPackages().getTotalProductPrice())}{" "}
                      {record?.getRegion()?.getCurrency()?.getCode()}
                    </div>
                  </div>
                  <p style={{ margin: 0 }}>
                    Source:{" "}
                    <span
                      style={{
                        display: "inline-block",
                        fontWeight: "500",
                      }}
                    >
                      {record.getOrder().getOauthClientId() ? (
                        <Tag color="#F15412">MoveOnDrop</Tag>
                      ) : (
                        <Tag color="#87d068">MoveOn</Tag>
                      )}
                    </span>
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text style={{ whiteSpace: "nowrap" }}>
                      Shipping category:
                    </Text>
                    <Button
                      style={{ padding: 0 }}
                      type="link"
                      onClick={() =>
                        changeShippingCategoryHandler(
                          [record.getId()],
                          record.getShippingCategory().getId(),
                        )
                      }
                    >
                      <Text
                        style={{ width: 90, fontWeight: 500 }}
                        ellipsis={{
                          tooltip: record.getShippingCategory().getName(),
                        }}
                      >
                        {record.getShippingCategory().getName()}
                      </Text>
                      <EditOutlined />
                    </Button>
                  </div>

                  <Link
                    to={`/shipping/shipment-products/edit/${record.getId()}`}
                  >
                    View Details <ArrowRightOutlined />
                  </Link>
                </div>
              </div>
            )}
          />

          <Column
            title="Cartons"
            dataIndex="packages"
            key="packages"
            width={150}
            ellipsis
            render={(_: string, record: ShipmentProductModel) => (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <Text>Quantity:</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                      fontWeight: "500",
                    }}
                  >
                    {record.getPackages().getTotalQuantity() + " "}
                    pieces
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <Text>Weight:</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                      fontWeight: "500",
                    }}
                  >
                    {record.getPackages().getTotalWeight().toFixed(3) + " "}
                    KG
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <Text>CBM:</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                      fontWeight: "500",
                    }}
                  >
                    {record.getPackages().getTotalCbm()}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <Text>Carton:</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                      fontWeight: "500",
                    }}
                  >
                    {record.getPackages().getData().length}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <Text>Contains:</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                      fontWeight: "500",
                      textTransform: "capitalize",
                    }}
                  >
                    {Array.from(
                      new Set(
                        record
                          .getPackages()
                          .getData()
                          .map((item) => item.getContains()),
                      ),
                    ).join(", ")}
                  </div>
                </div>
                <Button
                  type="link"
                  style={{ padding: 0 }}
                  onClick={() => {
                    setSelectedProduct(record);
                    setModalOpen("carton");
                  }}
                >
                  View Details <ArrowRightOutlined />
                </Button>
              </div>
            )}
          />

          <Column
            title="Shipping & Rate"
            dataIndex="shipping"
            key="shipping"
            width={250}
            ellipsis
            render={(_: string, record: ShipmentProductModel) => (
              <Row style={{ display: "flex", alignItems: "flex-start" }}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>Type:</Text>
                    <Button
                      style={{ padding: 0 }}
                      type="link"
                      onClick={() =>
                        changeShippingTypeHandler(
                          [record.getId()],
                          record.getShippingType() as unknown as ShippingTypesEnums,
                        )
                      }
                    >
                      <Text
                        style={{ maxWidth: 95, fontWeight: 500 }}
                        ellipsis={{
                          tooltip: getShippingTypeKeyByValue(
                            record.getShippingType(),
                          ),
                        }}
                      >
                        {getShippingTypeKeyByValue(record.getShippingType())}
                      </Text>
                      <EditOutlined />
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>Mode:</Text>
                    <Button
                      style={{ padding: 0 }}
                      type="link"
                      onClick={() =>
                        changeShippingModeHandler(
                          [record.getId()],
                          record.getShippingMode() as unknown as ShippingModesEnums,
                        )
                      }
                    >
                      <Text
                        style={{ maxWidth: 95, fontWeight: 500 }}
                        ellipsis={{
                          tooltip: getShippingModeKeyByValue(
                            record.getShippingMode(),
                          ),
                        }}
                      >
                        {getShippingModeKeyByValue(record.getShippingMode())}
                      </Text>
                      <EditOutlined />
                    </Button>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>Source:</Text>
                    <Button
                      style={{
                        padding: 0,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 5,
                      }}
                      type="link"
                      onClick={() =>
                        changeShippingCountryHandler(
                          [record.getId()],
                          record.getShippingCountry().getId(),
                        )
                      }
                    >
                      <img
                        src={record
                          .getShippingCountry()
                          ?.getCountry()
                          .getFlag()}
                        style={{ width: 18, height: 18 }}
                        alt=""
                      />
                      <Text style={{ fontWeight: 500 }}>
                        {record.getShippingCountry()?.getCountry().getName()}
                      </Text>
                      <EditOutlined />
                    </Button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text style={{ whiteSpace: "nowrap" }}>Destination:</Text>
                    <Button
                      style={{
                        padding: 0,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 5,
                        fontWeight: "500",
                      }}
                      type="link"
                      onClick={() =>
                        changeDestinationWarehouseHandler(
                          [record.getId()],
                          record.getDestinationWarehouse().getId(),
                          record.getDestinationCountry().getId(),
                        )
                      }
                    >
                      <img
                        src={record
                          .getDestinationWarehouse()
                          ?.getCountry()
                          .getFlag()}
                        style={{ width: 18, height: 18 }}
                        alt=""
                      />
                      <Text
                        style={{ maxWidth: 90 }}
                        ellipsis={{
                          tooltip: record.getDestinationWarehouse().getName(),
                        }}
                      >
                        {record.getDestinationWarehouse().getName()}
                      </Text>
                      <EditOutlined />
                    </Button>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>Customer rate:</Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 5,
                        fontWeight: "500",
                      }}
                    >
                      {record.getCustomerAssociation()?.getRate() ? (
                        <Tag color="success">
                          {record.getCustomerAssociation()?.getRate()}
                        </Tag>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>Agent rate:</Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 5,
                        fontWeight: "500",
                      }}
                    >
                      {record.getAgentAssociation()?.getRate()}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Text>MoveOn rate:</Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 5,
                        fontWeight: "500",
                      }}
                    >
                      {record.getMoveonAssociation()?.getRate()}
                    </div>
                  </div>
                  {record.getStatusLabel() !== "Pending" &&
                    record.getStatusLabel() !== "Rejected" && (
                      <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => {
                          setSelectedProduct(record);
                          setModalOpen("bidList");
                        }}
                      >
                        Bid List <ArrowRightOutlined />
                      </Button>
                    )}
                </div>
              </Row>
            )}
          />

          <Column
            title="Customer & Shipping Partner"
            dataIndex="packages"
            key="packages"
            width={250}
            ellipsis
            render={(_: string, record: ShipmentProductModel) => (
              <div>
                <p style={{ margin: 0 }}>
                  Customer Name:{" "}
                  <span
                    style={{
                      display: "inline-block",
                      fontWeight: "500",
                    }}
                  >
                    {record.getOrder().getCustomer().getName()}
                  </span>
                </p>
                <div style={{ margin: 0 }}>
                  Customer Email:{" "}
                  <Text
                    style={{ fontWeight: 500, maxWidth: 130 }}
                    ellipsis={{
                      tooltip: record.getOrder().getCustomer().getEmail(),
                    }}
                  >
                    <a
                      href={`mailto:${record.getOrder().getCustomer().getEmail()}`}
                    >
                      {record.getOrder().getCustomer().getEmail()}jkdfj dijfdffj
                      dfjkfj
                    </a>
                  </Text>
                </div>
                <div style={{ margin: 0 }}>
                  Customer Phone:{" "}
                  <Text
                    style={{ fontWeight: 500, maxWidth: 130 }}
                    ellipsis={{
                      tooltip: record.getOrder().getCustomer().getPhone(),
                    }}
                  >
                    <a
                      href={`tel:${record.getOrder().getCustomer().getPhone()}`}
                    >
                      {record.getOrder().getCustomer().getPhone()}
                    </a>
                  </Text>
                </div>
                <p style={{ margin: 0 }}>
                  Shipping mark:{" "}
                  <span
                    style={{
                      display: "inline-block",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    {record.getOrder().getCustomer().getShippingMark()}
                  </span>
                </p>
                <p style={{ margin: 0 }}>
                  Order Handler:{" "}
                  <span
                    style={{
                      display: "inline-block",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    {record?.getOrder()?.getOrderHandler()?.getName()}
                  </span>
                </p>
                <p style={{ margin: 0 }}>
                  Shipping Agent:{" "}
                  <span
                    style={{
                      display: "inline-block",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    {record
                      .getAgentAssociation()
                      ?.getAgentCompany()
                      .getPrimaryName()}
                  </span>
                </p>
              </div>
            )}
          />

          <Column
            title="Actions"
            dataIndex="actions"
            key="actions"
            width={110}
            fixed= {isMobile ? "right" : undefined}
            ellipsis
            render={(_: string, record: ShipmentProductModel) => {
              const items: MenuProps["items"] = [
                {
                  icon: <CheckCircleTwoTone />,
                  label: "Approve and Assign Agent",
                  key: "approve-and-assign-agent",
                  onClick: () => {
                    setSelectedProduct(record);
                    agentAssignHandler(record);
                  },
                },
                {
                  icon: <CloseCircleTwoTone />,
                  label: "Reject product",
                  key: "reject-product",
                  onClick: () => {
                    setSelectedProduct(record);
                    setModalOpen("reject");
                  },
                },
                {
                  icon: <UnorderedListOutlined />,
                  label: "Bid list",
                  key: "bid-list",
                  onClick: () => {
                    setSelectedProduct(record);
                    setModalOpen("bidList");
                  },
                },
                {
                  icon: <VerticalLeftOutlined />,
                  label: "Change source country",
                  key: "change-source-country",
                  onClick: () =>
                    changeShippingCountryHandler(
                      [record.getId()],
                      record.getShippingCountry().getId(),
                    ),
                },
                {
                  icon: <HomeTwoTone />,
                  label: "Change destination warehouse",
                  key: "change-destination-warehouse",
                  onClick: () =>
                    changeDestinationWarehouseHandler(
                      [record.getId()],
                      record.getDestinationWarehouse().getId(),
                      record.getDestinationCountry().getId(),
                    ),
                },
                {
                  icon: <BorderOuterOutlined />,
                  label: "Change shipping category",
                  key: "change-shipping-category",
                  onClick: () =>
                    changeShippingCategoryHandler(
                      [record.getId()],
                      record.getShippingCategory().getId(),
                    ),
                },
                {
                  icon: <RadiusBottomleftOutlined />,
                  label: "Change shipping type",
                  key: "change-shipping-type",
                  onClick: () =>
                    changeShippingTypeHandler(
                      [record.getId()],
                      record.getShippingType(),
                    ),
                },
                {
                  icon: <RadiusUpleftOutlined />,
                  label: "Change shipping mode",
                  key: "change-shipping-mode",
                  onClick: () =>
                    changeShippingModeHandler(
                      [record.getId()],
                      record.getShippingMode(),
                    ),
                },
              ];
              return (
                <Dropdown
                  menu={{
                    items: items,
                  }}
                >
                  <Button icon={<HddFilled />}>
                    {isMobile ? (
                      <DownOutlined />
                    ) : (
                      <>
                        Actions <DownOutlined />{" "}
                      </>
                    )}
                  </Button>
                </Dropdown>
              );
            }}
          />
        </Table>
      </div>

      {categoryPriceDetailsInfo && (
        <SelectWarehouseModal
          categoryPriceDetails={categoryPriceDetailsInfo}
          selectedProductIds={
            selectedProduct ? [selectedProduct.getId()] : selectedRowKeys
          }
          selectedProducts={selectedProduct ? [selectedProduct] : selectedRows}
          onCancel={onCancelModal}
          open={modalOpen === "assignAgent"}
        />
      )}
      <Modal
        title="Are you sure?"
        open={modalOpen === "reject"}
        onOk={rejectedModalOkHandler}
        confirmLoading={isRejectedLoading}
        onCancel={onCancelModal}
      >
        Are you sure want to reject?
      </Modal>

      {changeShippingCountryDetails.productIds &&
        changeShippingCountryDetails.productIds.length && (
          <ChangeShippingCountryModal
            existingShippingCountryId={
              changeShippingCountryDetails.existingShippingCountryId
            }
            isOpen={changeShippingCountryDetails.isOpen}
            productIds={changeShippingCountryDetails.productIds}
            onCancel={() =>
              setChangeShippingCountryDetails({
                isOpen: false,
                existingShippingCountryId: undefined,
                productIds: undefined,
              })
            }
          />
        )}
      {changeDestinationWarehouseDetails.productIds &&
        changeDestinationWarehouseDetails.productIds.length &&
        changeDestinationWarehouseDetails.existingWarehouseId &&
        changeDestinationWarehouseDetails.existingCountryId && (
          <ChangeDestinationWarehouseModal
            country_id={changeDestinationWarehouseDetails.existingCountryId}
            existingWarehouseId={
              changeDestinationWarehouseDetails.existingWarehouseId
            }
            isOpen={changeDestinationWarehouseDetails.isOpen}
            productIds={changeDestinationWarehouseDetails.productIds}
            onCancel={() =>
              setChangeDestinationWarehouseDetails({
                isOpen: false,
                existingWarehouseId: undefined,
                existingCountryId: undefined,
                productIds: undefined,
              })
            }
          />
        )}
      {changeShippingCategoryDetails.productIds &&
        changeShippingCategoryDetails.productIds.length && (
          <ChangeShippingCategoryModal
            existingId={changeShippingCategoryDetails.existingId}
            isOpen={changeShippingCategoryDetails.isOpen}
            productIds={changeShippingCategoryDetails.productIds}
            onCancel={() =>
              setChangeShippingCategoryDetails({
                isOpen: false,
                existingId: undefined,
                productIds: undefined,
              })
            }
          />
        )}
      {changeShippingModeDetails.productIds &&
        changeShippingModeDetails.productIds.length && (
          <ChangeShippingModeModal
            existingMode={changeShippingModeDetails.existingData}
            isOpen={changeShippingModeDetails.isOpen}
            productIds={changeShippingModeDetails.productIds}
            onCancel={() =>
              setChangeShippingModeDetails({
                isOpen: false,
                existingData: undefined,
                productIds: undefined,
              })
            }
          />
        )}
      {changeShippingTypeDetails.productIds &&
        changeShippingTypeDetails.productIds.length && (
          <ChangeShippingTypeModal
            existingType={changeShippingTypeDetails.existingData}
            isOpen={changeShippingTypeDetails.isOpen}
            productIds={changeShippingTypeDetails.productIds}
            onCancel={() =>
              setChangeShippingTypeDetails({
                isOpen: false,
                existingData: undefined,
                productIds: undefined,
              })
            }
          />
        )}

      <Modal
        width={modalOpen === "bidList" ? "900px" : undefined}
        open={modalOpen === "carton" || modalOpen === "bidList"}
        onCancel={onCancelModal}
        destroyOnClose={true}
        footer={null}
        title={
          modalOpen === "bidList"
            ? "Bid List"
            : modalOpen === "carton"
              ? "Package Details"
              : ""
        }
      >
        {selectedProduct && modalOpen === "carton" && (
          <Carton ShipmentProductData={selectedProduct} isLoading={false} />
        )}

        {selectedProduct && modalOpen === "bidList" && (
          <BidList product={selectedProduct} />
        )}
      </Modal>
    </>
  );
};

export default ShipmentProductsTable;
