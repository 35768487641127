import { Alert, Button, Form, Input, InputNumber, Select, notification } from "antd";
import { FormInstance } from "antd/es/form/Form";
import React, { useEffect, useState, createRef } from "react";
import {
  renderOptionsFromEnumUpdateStatus,
  renderOptionsFromEnumUpdateStatusRejcted,
} from "components/Form/forms";
import {
  TransactionModel,
  UpdateStatusModel,
} from "models/transactionCollectionModels";
import { useUpeateStatus } from "lib/core-react/hooks/private/useTransactions";
import {
  TransactionsRejectEnums,
  TransactionsStatusEnums,
} from "enums/transactionCollectionsEnums";

interface IProps {
  data: TransactionModel;
  setIsShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateStatus = ({ data, setIsShowUpdateModal }: IProps) => {
  const { updateStatus, isLoading } = useUpeateStatus();
  const formRef: React.Ref<FormInstance<any>> = createRef();
  const [form] = Form.useForm();
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [isOtherRejectReason, setIsOtherRejectReason] = useState<boolean>(false);

  useEffect(() => {
    const defaultStatus =
      data && typeof data.getStatus === "function" ? data.getStatus() : null;
    const defaultAmount =
      data && typeof data.getAmount === "function" ? data.getAmount() : null;

    const initialValues: any = {};

    if (defaultStatus !== null) {
      initialValues.status = defaultStatus;
      setSelectedStatus(defaultStatus);
    }

    if (defaultStatus === "approved" && defaultAmount !== null) {
      initialValues.authorized_amount = defaultAmount;
    }

    form.setFieldsValue(initialValues);
  }, [data, form]);

  useEffect(() => {
    form.resetFields();
  }, [data]);

  const onFinish = async (
    value: UpdateStatusModel & { authorized_amount?: number } & { reject_reason?: string } & { other_reason?: string }
  ) => {
    const payload: any = {
      status: value.status,
    };

    if (value.status === "approved" && value.authorized_amount !== undefined) {
      payload.authorized_amount = value.authorized_amount;
    }

    if (value.status === "rejected") {
      payload.reject_reason =
        value.reject_reason === "others" ? value.other_reason : value.reject_reason;
    }

    try {
      await updateStatus(data.id as number, payload as UpdateStatusModel);
      notification["success"]({
        message: "Status Update successfully",
      });
      setIsShowUpdateModal(false);
    } catch (error: any) {
      notification["error"]({
        message: error.response.data.message,
      });
    }
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      layout="vertical"
      name="Update Status"
      ref={formRef}
      initialValues={{
        status: data.getStatus ? data.getStatus() : null,
        authorized_amount: data.getRequestAmount() ? data.getRequestAmount() : null,
      }}
      onFieldsChange={(changedFields) => {
        if (changedFields.length > 0 && changedFields[0].name[0] === "status") {
          const newStatus = changedFields[0].value;
          setSelectedStatus(newStatus);
          if (newStatus !== "approved") {
            form.setFieldsValue({ authorized_amount: undefined });
          } 
        }

        if (changedFields.length > 0 && changedFields[0].name[0] === "reject_reason") {
          const newRejectReason = changedFields[0].value;
          setIsOtherRejectReason(newRejectReason === "others");
        }
      }}
    >
      <Form.Item
        rules={[{ required: true, message: "Status is required" }]}
        name="status"
        label="Status"
      >
        <Select placeholder="Please select a status">
          {renderOptionsFromEnumUpdateStatus(TransactionsStatusEnums)}
        </Select>
      </Form.Item>

      {selectedStatus === "rejected" && (
        <>
          <Form.Item
            rules={[{ required: true, message: "Reject reason is required" }]}
            name="reject_reason"
            label="Reject Reason"
          >
            <Select placeholder="Please select a reject reason">
              {renderOptionsFromEnumUpdateStatusRejcted(TransactionsRejectEnums)}
            </Select>
          </Form.Item>
          {isOtherRejectReason && (
            <Form.Item
              rules={[{ required: true, message: "Other reason is required" }]}
              name="other_reason"
              label="Other Reason"
            >
              <Input placeholder="Please specify the reason" />
            </Form.Item>
          )}
        </>
      )}

      {selectedStatus === "approved" && (
        <Form.Item
          rules={[{ required: true, message: "Authorized amount is required" }]}
          name="authorized_amount"
          label="Authorized Amount"
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
      )}

      <Form.Item>
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UpdateStatus;
