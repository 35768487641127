import { useAtom } from "jotai";
import { useService } from "lib/core-react/contexts";
import {
  destinationWarehouse,
  destinationWarehouseCollectionAtom,
  destinationWarehouseInvitationCollectionAtom,
  destinationWarehouseMemberCollectionAtom,
} from "lib/core-react/store/store";
import { useState } from "react";
import { getError } from "../utils/errors";
import {
  IDestinationWarehouseForm,
  IDestinationWarehouseMemberForm,
  IDestinationWarehouseMemberInvitationForm,
} from "types/destinationWarehouseCollection";

export const useCreateDestinationWarehouse = () => {
  const { destinationService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [warehouse, setWarehouse] = useAtom(destinationWarehouse);
  const createWarehouse = async (payload: IDestinationWarehouseForm) => {
    setIsLoading(true);
    try {
      const response =
        await destinationService.destinationWarehouseResource.create(payload);
      setIsLoading(false);
      setWarehouse({ ...warehouse, refetch: true });
      return response;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
    }
  };

  return { createWarehouse, isLoading, isError };
};

export const useUpdateDestinationWarehouse = () => {
  const { destinationService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [warehouse, setWarehouse] = useAtom(destinationWarehouse);
  const updateWarehouse = async (
    id: number,
    payload: IDestinationWarehouseForm
  ) => {
    setIsLoading(true);
    try {
      const response =
        await destinationService.destinationWarehouseResource.update(
          id,
          payload
        );
      setIsLoading(false);
      setWarehouse({ ...warehouse, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
    }
  };

  return { updateWarehouse, isLoading, isError };
};

export const useGetDestinationWarehouse = () => {
  const [warehouse, setWarehouse] = useAtom(destinationWarehouseCollectionAtom);
  const { destinationService } = useService();
  const getDestinationWarehouse = async (params?: string) => {
    setWarehouse({ ...warehouse, isLoading: true, error: null });
    try {
      const response =
        await destinationService.destinationWarehouseResource.get(params);
      setWarehouse({
        ...warehouse,
        refetch: false,
        isLoading: false,
        data: response,
      });
    } catch (error: any) {
      setWarehouse({
        ...warehouse,
        refetch: false,
        isLoading: false,
        error: getError(error),
      });
    }
  };
  return { getDestinationWarehouse } as const;
};

export const useGetDestinationWarehouseMembers = (
  destination_warehouse_id: number
) => {
  const [destinationWarehouseMembers, setDestinationWarehouseMembers] = useAtom(
    destinationWarehouseMemberCollectionAtom
  );

  const { destinationService } = useService();

  const getDestinationWarehouseMembers = async (
    params?: string
  ): Promise<void> => {
    setDestinationWarehouseMembers({
      ...destinationWarehouseMembers,
      isLoading: true,
      error: null,
    });
    try {
      const response =
        await destinationService.destinationWarehouseResource.getMembers(
          destination_warehouse_id,
          params
        );
      setDestinationWarehouseMembers({
        ...destinationWarehouseMembers,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setDestinationWarehouseMembers({
        ...destinationWarehouseMembers,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
    }
  };

  return { getDestinationWarehouseMembers };
};

export const useDestinationWarehouseMemberDelete = () => {
  const { destinationService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [destinationWarehouseMemberData, setDestinationWarehouseMemberData] =
    useAtom(destinationWarehouseMemberCollectionAtom);

  const deleteMember = async (destination_warehouse_id: number, id: number) => {
    setIsLoading(true);
    try {
      const response =
        await destinationService.destinationWarehouseResource.deleteMember(
          destination_warehouse_id,
          id
        );
      setIsLoading(false);
      setDestinationWarehouseMemberData({
        ...destinationWarehouseMemberData,
        refetch: true,
      });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
    }
  };
  return { deleteMember, isLoading, isError };
};

export const useDestinationWarehouseMemberUpdate = () => {
  const { destinationService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [destinationWarehouseMemberData, setDestinationWarehouseMemberData] =
    useAtom(destinationWarehouseMemberCollectionAtom);

  const updateMember = async (
    destination_warehouse_id: number,
    id: number,
    payload: IDestinationWarehouseMemberForm
  ) => {
    setIsLoading(true);
    try {
      const response =
        await destinationService.destinationWarehouseResource.updateMember(
          destination_warehouse_id,
          id,
          payload
        );
      setIsLoading(false);
      setDestinationWarehouseMemberData({
        ...destinationWarehouseMemberData,
        refetch: true,
      });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
    }
  };
  return { updateMember, isLoading, isError };
};

export const useDestinationWarehouseInvitationsGet = (
  destination_warehouse_id: number
) => {
  const [destinationWarehouseInvitations, setDestinationWarehouseInvitations] =
    useAtom(destinationWarehouseInvitationCollectionAtom);

  const { destinationService } = useService();

  const getDestinationWarehouseInvitations = async (
    params?: string
  ): Promise<void> => {
    setDestinationWarehouseInvitations({
      ...destinationWarehouseInvitations,
      isLoading: true,
      error: null,
    });
    try {
      const response =
        await destinationService.destinationWarehouseResource.getInvitations(
          destination_warehouse_id,
          params
        );
      setDestinationWarehouseInvitations({
        ...destinationWarehouseInvitations,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setDestinationWarehouseInvitations({
        ...destinationWarehouseInvitations,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
    }
  };

  return { getDestinationWarehouseInvitations };
};

export const useDestinationWarehouseInvitationDelete = () => {
  const { destinationService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [
    destinationWarehouseInvitationData,
    setDestinationWarehouseInvitationData,
  ] = useAtom(destinationWarehouseInvitationCollectionAtom);

  const deleteInvitation = async (
    destination_warehouse_id: number,
    id: number
  ) => {
    setIsLoading(true);
    try {
      const response =
        await destinationService.destinationWarehouseResource.deleteInvitation(
          destination_warehouse_id,
          id
        );
      setIsLoading(false);
      setDestinationWarehouseInvitationData({
        ...destinationWarehouseInvitationData,
        refetch: true,
      });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
    }
  };
  return { deleteInvitation, isLoading, isError };
};

export const useDestinationWarehouseInvitationSend = () => {
  const { destinationService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [
    destinationWarehouseInvitationData,
    setDestinationWarehouseInvitationData,
  ] = useAtom(destinationWarehouseInvitationCollectionAtom);

  const sendInvitation = async (
    destination_warehouse_id: number,
    payload: IDestinationWarehouseMemberInvitationForm
  ) => {
    setIsLoading(true);
    try {
      const response =
        await destinationService.destinationWarehouseResource.sendInvitation(
          destination_warehouse_id,
          payload
        );
      setIsLoading(false);
      setDestinationWarehouseInvitationData({
        ...destinationWarehouseInvitationData,
        refetch: true,
      });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
    }
  };
  return { sendInvitation, isLoading, isError };
};

export const useDestinationWarehouseInvitationResend = () => {
  const { destinationService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [
    destinationWarehouseInvitationData,
    setDestinationWarehouseInvitationData,
  ] = useAtom(destinationWarehouseInvitationCollectionAtom);

  const resendInvitation = async (
    destination_warehouse_id: number,
    id: number
  ) => {
    setIsLoading(true);
    try {
      const response =
        await destinationService.destinationWarehouseResource.resendInvitation(
          destination_warehouse_id,
          id
        );
      setIsLoading(false);
      setDestinationWarehouseInvitationData({
        ...destinationWarehouseInvitationData,
        refetch: true,
      });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
    }
  };
  return { resendInvitation, isLoading, isError };
};
