import { TakeActionsEnums } from "enums/takeActionsEnums";

export const driverOptions = [
  { value: TakeActionsEnums.PRICE_INCREASED, label: "Price Increased" },
  { value: TakeActionsEnums.PRICE_DECREASED, label: "Price Decreased" },
  { value: TakeActionsEnums.QUANTITY_UPDATE, label: "Quantity Update" },
  { value: TakeActionsEnums.SKU_MISSMATCHED, label: "Sku Missmatched" },
  { value: TakeActionsEnums.PRODUCT_STOCK_OUT, label: "Product Stock Out" },
  {
    value: TakeActionsEnums.LOCAL_DELIVERY_CHARGE_ADDED,
    label: "Local Delivery charge",
  },
  {
    value: TakeActionsEnums.GENERAL_TERMS_AND_CONDITIONS,
    label: "General Terms and Conditions",
  },
  { value: TakeActionsEnums.TAX_ADDED, label: "Tax Added" },
  { value: TakeActionsEnums.NO_RISK_PRODUCT, label: "No Risk Products" },
  {
    value: TakeActionsEnums.SHIPPING_CHARGE_INCREASE,
    label: "Shipping Charge Increase",
  },
  {
    value: TakeActionsEnums.PURCHASE_CONFIRMATION,
    label: "Purchase Confirmation",
  },
  {
    value: TakeActionsEnums.HIGH_TAX_POSSIBILITY,
    label: "High Tax Possibility",
  },
  { value: TakeActionsEnums.CATEGORY_CHANGE, label: "Category Changes" },
  { value: TakeActionsEnums.PACKING_CHARGE, label: "Packing Charge" },
  { value: TakeActionsEnums.SERVICE_CHARGE, label: "Services Charge" },
  { value: TakeActionsEnums.ILLEGAL_ITEM, label: "Illegal Item" },
  { value: TakeActionsEnums.FX_MISMATCHED, label: "Fx Mismatched" },
].map((option) => ({ value: option.value, label: option.label })) as {
  id: any;
  value: TakeActionsEnums;
  label: string;
}[];
