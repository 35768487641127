import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Select, message } from "antd";
import { renderOptionsFromEnum } from "components/Form/forms";
import { useAtom } from "jotai";
import { useGetAgentWarehouse } from "lib/core-react/hooks/private";
import { useCopyCommissionForWarehouse } from "lib/core-react/hooks/private/useCommission";
import { agentWarehouseCollectionAtom } from "lib/core-react/store/store";
import { AgentWarehouseCollectionModel } from "models/agentWarehouse";
import {
  CommissionAssociationModel,
  CommissionCopyForWarehouseRequestModel,
} from "models/commissionCollectionModel";
import {
  ICommission,
  ICommissionCopyForWarehouseRequest,
} from "types/commissionCollection";

interface IProps {
  selectedCommission: ICommission;
  selectedCommissionAssociation: CommissionAssociationModel;
  setIsShowCopyModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CopyCommissionForWarehouse = ({
  selectedCommission,
  selectedCommissionAssociation,
  setIsShowCopyModal,
}: IProps) => {
  const [form] = Form.useForm();
  const { getAgentWarehouses } = useGetAgentWarehouse();
  const { copyCommission, isError } = useCopyCommissionForWarehouse();
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const [{ data: agentWarehouseStoreData, isLoading }] = useAtom(
    agentWarehouseCollectionAtom,
  );

  useEffect(() => {
    form.resetFields();
  }, [form]);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  useEffect(() => {
    getAgentWarehouses("per_page=200");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // @ts-ignore FIXME
  const AgentWarehouseData =
    agentWarehouseStoreData &&
    // @ts-ignore FIXME
    new AgentWarehouseCollectionModel(agentWarehouseStoreData);

  const onFinish = async (value: CommissionCopyForWarehouseRequestModel) => {
    const payload: ICommissionCopyForWarehouseRequest = {
      agent_warehouse_id: value.agent_warehouse_id,
    };
    try {
      const response = await copyCommission(
        selectedCommission.id,
        selectedCommissionAssociation.id,
        payload,
      );
      setIsShowCopyModal(false);
      setIsErrorVisible(false);
      message.success(response);
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name="create-commission-form"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          rules={[{ required: true, message: "Warehouse is required" }]}
          name="agent_warehouse_id"
          label="Warehouse"
        >
          <Select loading={isLoading} placeholder="Please select a warehouse">
            {AgentWarehouseData?.getWarehouseIdsAsObject &&
              renderOptionsFromEnum(
                AgentWarehouseData?.getWarehouseIdsAsObject(),
              )}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button block type="primary" htmlType="submit" loading={isLoading}>
            Copy
          </Button>
        </Form.Item>
      </Form>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isError}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </>
  );
};

export default CopyCommissionForWarehouse;
