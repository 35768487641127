export enum DiscountCollectionEnum {
  DiscountCollection = "DiscountCollection",
  Discount = "Discount",
}

export enum DiscountRuleCollectionEnum {
  DiscountRuleCollection = "DiscountRuleCollection",
  DiscountRule = "DiscountRule",
}

export enum DiscountRulesSchemaEnum {
  DiscountRulesSchema = "DiscountRulesSchema",
}

export enum DiscountUsageHistoryCollectionEnum {
  DiscountUsageHistoryCollection = "DiscountUsageHistoryCollection",
  DiscountUsageHistory = "DiscountUsageHistory",
}

export enum DiscountAmountTypeEnum {
  "Flat Amount" = "flat-amount",
  Percentage = "percentage",
}

export enum DiscountLimitationTypeEnum {
  "NO LIMITS" = "no-limits",
  "N TIMES PER DAY" = "n-times-per-day",
  "N TIMES PER USER" = "n-times-per-user",
  "N TIMES PER DAY PER USER" = "n-times-per-day-per-user",
}

export enum DiscountStatusEnum {
  ACTIVE = "active",
  INACTIVE = "inactive",
}
