import { useService } from "lib/core-react/contexts";
import {
  TransactionCollectionAtom,
  payoutGateWayCollectionAtom,
  payoutRequestCollectionAtom,
  walletOverviewCollectionAtom,
} from "lib/core-react/store/store";
import { getError } from "../utils/errors";
import { useAtom } from "jotai";
import { useState } from "react";
import { IPayoutGateWayFormRequest } from "types/payoutGatewayCollection";

export const useWalletTransactions = () => {
  const [walletTransaction, setWalletTransaction] = useAtom(
    TransactionCollectionAtom,
  );

  const { walletService } = useService();

  const getWalletTransctions = async (walletId: Number): Promise<void> => {
    setWalletTransaction({
      ...walletTransaction,
      isLoading: true,
      error: null,
    });
    try {
      const response =
        await walletService.walletResource.getTransactions(walletId);
      setWalletTransaction({
        ...walletTransaction,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setWalletTransaction({
        ...walletTransaction,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };

  return { getWalletTransctions } as const;
};

// export const useWalletOverviewBalance = () => {
//   const [walletOverviewBalance, setWalletOverviewBalance] = useAtom(
//     walletOverviewCollectionAtom
//   );

//   const { walletService } = useService();

//   const getWalletOverviewBalance = async (params?: string): Promise<void> => {
//     setWalletOverviewBalance({ ...walletOverviewBalance, isLoading: true, error: null });
//     try {
//       const response = await walletService.walletResource.getOverviewBalance(params);
//       setWalletOverviewBalance({
//         ...walletOverviewBalance,
//         isLoading: false,
//         data: response,
//         refetch: false,
//       });
//     } catch (error: any) {
//       setWalletOverviewBalance({
//         ...walletOverviewBalance,
//         isLoading: false,
//         refetch: false,
//         error: getError(error),
//       });
//       throw error;
//     }
//   };

//   return { getWalletOverviewBalance } as const;

// };

export const useWalletOverviewBalance = () => {
  const [walletOverviewBalance, setWalletOverviewBalance] = useAtom(
    walletOverviewCollectionAtom,
  );

  const { walletService } = useService();

  const getWalletOverviewBalance = async () => {
    try {
      const response = await walletService.walletResource.getOverviewBalance();
      setWalletOverviewBalance({
        ...walletOverviewBalance,
        isLoading: false,
        data: response.data,
        refetch: false,
      });
    } catch (error: any) {
      setWalletOverviewBalance({
        ...walletOverviewBalance,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };

  return {
    getWalletOverviewBalance,
  } as const;
};

// Agent Wallet
export const useAgentWalletOverviewBalance = () => {
  const [walletOverviewBalance, setWalletOverviewBalance] = useAtom(
    walletOverviewCollectionAtom,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");

  const { walletService } = useService();

  const getAgentWalletOverviewBalance = async (agentCompanyId: Number) => {
    setIsLoading(true);
    try {
      const response =
        await walletService.walletResource.getOverviewAgentCompanyBalance(
          agentCompanyId,
        );
      setWalletOverviewBalance({
        ...walletOverviewBalance,
        isLoading: false,
        data: response.data,
        refetch: false,
      });
      setIsLoading(false);
    } catch (error: any) {
      setWalletOverviewBalance({
        ...walletOverviewBalance,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      setIsLoading(false);
      setError(getError(error));
      //throw error;
    }
  };

  return {
    getAgentWalletOverviewBalance,
    isError,
    isLoading,
  } as const;
};

export const useAgentWalletTransactions = () => {
  const [walletTransaction, setWalletTransaction] = useAtom(
    TransactionCollectionAtom,
  );
  const [isError, setError] = useState<string>("");

  const { walletService } = useService();

  const getAgentWalletTransctions = async (
    agentCompanyId: Number,
    walletId: Number,
  ): Promise<void> => {
    try {
      const response =
        await walletService.walletResource.getTransactionsAgentCompany(
          agentCompanyId,
          walletId,
        );
      setWalletTransaction({
        ...walletTransaction,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setWalletTransaction({
        ...walletTransaction,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      setError(getError(error));
      throw error;
    }
  };

  return { getAgentWalletTransctions, isError } as const;
};

// User Wallet
export const useUserWalletOverviewBalance = () => {
  const [walletOverviewBalance, setWalletOverviewBalance] = useAtom(
    walletOverviewCollectionAtom,
  );

  const { walletService } = useService();

  const getUserWalletOverviewBalance = async (userId: Number) => {
    try {
      const response =
        await walletService.walletResource.getOverviewUserBalance(userId);
      setWalletOverviewBalance({
        ...walletOverviewBalance,
        isLoading: false,
        data: response.data,
        refetch: false,
      });
    } catch (error: any) {
      setWalletOverviewBalance({
        ...walletOverviewBalance,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };

  return {
    getUserWalletOverviewBalance,
  } as const;
};

export const useUserWalletTransactions = () => {
  const [walletTransaction, setWalletTransaction] = useAtom(
    TransactionCollectionAtom,
  );

  const { walletService } = useService();

  const getUserWalletTransctions = async (
    userId: Number,
    walletId: Number,
  ): Promise<void> => {
    try {
      const response = await walletService.walletResource.getTransactionsUser(
        userId,
        walletId,
      );
      setWalletTransaction({
        ...walletTransaction,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setWalletTransaction({
        ...walletTransaction,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };

  return { getUserWalletTransctions } as const;
};

// Payout GateWays
export const useGetPayoutGateways = () => {
  const [payoutGateWayCollectionData, setPayoutGateWaysCollectionData] =
    useAtom(payoutGateWayCollectionAtom);
  const { walletService } = useService();
  const getPayoutGateWay = async (): Promise<void> => {
    try {
      const response = await walletService.walletResource.getPayoutGateWays();
      setPayoutGateWaysCollectionData({
        ...payoutGateWayCollectionData,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setPayoutGateWaysCollectionData({
        ...payoutGateWayCollectionData,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getPayoutGateWay } as const;
};

export const useCreatedPayoutGateway = () => {
  const { walletService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [payoutGateWayCollectionData, setPayoutGateWaysCollectionData] =
    useAtom(payoutGateWayCollectionAtom);

  const createdPayoutGateway = async (payload: IPayoutGateWayFormRequest) => {
    setIsLoading(true);
    try {
      const response =
        await walletService.walletResource.createPayoutGateWays(payload);
      setIsLoading(false);
      setPayoutGateWaysCollectionData({
        ...payoutGateWayCollectionData,
        refetch: true,
      });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };
  return { createdPayoutGateway, isLoading, isError };
};

export const useUpdatePayoutGateway = () => {
  const { walletService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [payoutGateWayCollectionData, setPayoutGateWaysCollectionData] =
    useAtom(payoutGateWayCollectionAtom);

  const updatePayoutGateway = async (
    id: number,
    payload: IPayoutGateWayFormRequest,
  ) => {
    setIsLoading(true);
    try {
      const response = await walletService.walletResource.updatePayoutGateWays(
        id,
        payload,
      );
      setIsLoading(false);
      setPayoutGateWaysCollectionData({
        ...payoutGateWayCollectionData,
        refetch: true,
      });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };
  return { updatePayoutGateway, isLoading, isError };
};

// Payout Request
export const useGetPayoutRequests = () => {
  const [payoutRequestCollectionData, setPayoutRequestCollectionData] = useAtom(
    payoutRequestCollectionAtom,
  );
  const { walletService } = useService();
  const getPayoutRequests = async (params?: string): Promise<void> => {
    setPayoutRequestCollectionData({
      ...payoutRequestCollectionData,
      isLoading: true,
      error: null,
    });
    try {
      const response =
        await walletService.walletResource.getPayoutRequests(params);
      setPayoutRequestCollectionData({
        ...payoutRequestCollectionData,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setPayoutRequestCollectionData({
        ...payoutRequestCollectionData,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getPayoutRequests } as const;
};

export const useUpdatePayoutRequest = () => {
  const { walletService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [payoutRequestCollectionData, setPayoutRequestCollectionData] = useAtom(
    payoutRequestCollectionAtom,
  );

  const updatePayoutRequest = async (
    id: number,
    action: "processing" | "reject",
  ) => {
    setIsLoading(true);
    try {
      const response = await walletService.walletResource.updatePayoutRequest(
        id,
        action,
      );
      setIsLoading(false);
      setPayoutRequestCollectionData({
        ...payoutRequestCollectionData,
        refetch: true,
      });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };
  return { updatePayoutRequest, isLoading, isError };
};

export const useApprovePayoutRequest = () => {
  const { walletService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [payoutRequestCollectionData, setPayoutRequestCollectionData] = useAtom(
    payoutRequestCollectionAtom,
  );

  const approvePayoutRequest = async (
    id: number,
    payload: {
      approved_amount: number;
    },
  ) => {
    setIsLoading(true);
    try {
      const response = await walletService.walletResource.approvePayoutRequest(
        id,
        payload,
      );
      setIsLoading(false);
      setPayoutRequestCollectionData({
        ...payoutRequestCollectionData,
        refetch: true,
      });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };
  return { approvePayoutRequest, isLoading, isError };
};
