/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import {
  Button,
  Dropdown,
  MenuProps,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  HddFilled,
} from "@ant-design/icons";

import { destinationWarehouseMemberCollectionAtom } from "lib/core-react/store/store";
import {
  useDestinationWarehouseMemberDelete,
  useGetDestinationWarehouseMembers,
} from "lib/core-react/hooks/private/useDestinationWarehouse";
import { getError } from "lib/core-react/hooks/utils/errors";
import useDataFilters from "hooks/useDataFilters";

import {
  DestinationWarehouseMemberCollectionModel,
  DestinationWarehouseMemberModel,
} from "models/destinationWarehouseCollectionModel";
import { ApiHelperModel } from "models/apiHelper";
import { PaginationModel } from "models/pagination";
import { DestinationWarehouseMemberModalType } from "types/destinationWarehouseCollection";

import { getKeyByValue } from "./utils";
import { DestinationWarehouseMemberModification } from "./DestinationWarehouseMemberModification";
import useWindowWidth from "../../../../../lib/core-react/hooks/public/useWindowWidth";

interface IProps {
  destinationWarehouseId: number;
}

export const DestinationWarehouseMembersTable = ({
  destinationWarehouseId,
}: IProps) => {
  const { deleteMember, isLoading: isLoadingDelete } =
    useDestinationWarehouseMemberDelete();
  const { getDestinationWarehouseMembers } = useGetDestinationWarehouseMembers(
    destinationWarehouseId,
  );
  const [{ data: memberCollectionData, isLoading, refetch }] = useAtom(
    destinationWarehouseMemberCollectionAtom,
  );
  const MemberCollectionData =
    memberCollectionData &&
    new DestinationWarehouseMemberCollectionModel(memberCollectionData);

  const [modalOpen, setModalOpen] = useState<
    DestinationWarehouseMemberModalType | undefined
  >(undefined);
  const [
    selectedDestinationWarehouseMemberId,
    setSelectedDestinationWarehouseMemberId,
  ] = useState<number | undefined>(undefined);

  const { filters, handleFilterChange, isFirstCall, isFetched } =
    useDataFilters();

  // Api Call
  useEffect(() => {
    if ((filters && !isFetched && isFirstCall) || refetch) {
      ApiHelperModel.makeGetRequest({}, getDestinationWarehouseMembers);
    }
  }, [destinationWarehouseId, isFirstCall, filters, isFetched, refetch]);

  // Pagination
  const paginationData = MemberCollectionData?.getPagination();
  const { isMobile, width } = useWindowWidth();

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getDestinationWarehouseMembers,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    MemberCollectionData,
    handlePaginationChange,
  );

  const handleDeleteMember = async (id: number) => {
    try {
      await deleteMember(destinationWarehouseId, id);
      await getDestinationWarehouseMembers();
      message.success("Member removed.");
    } catch (error) {
      message.error(getError(error));
    }
  };

  const onCancelModal = () => {
    setModalOpen(undefined);
    setSelectedDestinationWarehouseMemberId(undefined);
  };

  console.log(width, "width", isMobile);

  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "id",
      width: 50,
      ellipsis: true,
      align: "center" as const,
      render: (
        _: string,
        __: DestinationWarehouseMemberModel,
        index: number,
      ) => {
        return (
          <>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </>
        );
      },
    },
    {
      title: "User info",
      dataIndex: "user",
      width: 300,
      ellipsis: true,
      key: "user",
      render: (_: string, record: DestinationWarehouseMemberModel) => {
        return (
          <Space direction="vertical">
            <Space>
              <Typography.Text strong>Name: </Typography.Text>
              <Typography.Text>{record.getUser().getName()}</Typography.Text>
            </Space>
            <Space>
              <Typography.Text strong>Phone: </Typography.Text>
              <Typography.Text>{record.getUser().getPhone()}</Typography.Text>
            </Space>
            <Space></Space>
            <Space>
              <Typography.Text strong>Email: </Typography.Text>
              <Typography.Text>{record.getUser().getEmail()}</Typography.Text>
            </Space>
            <Space>
              <Typography.Text strong>Shipping mark: </Typography.Text>{" "}
              <Typography.Text>
                {record.getUser().getShippingMark()}
              </Typography.Text>
            </Space>
            <Space>
              <Typography.Text strong>Role: </Typography.Text>{" "}
              <Typography.Text>
                {record.getRoles() ? (
                  <div
                    style={{
                      textTransform: "capitalize",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 10,
                      width: 200,
                    }}
                  >
                    {record
                      .getRoles()
                      .getData()
                      .map((role) => (
                        <Tag key={role.id} color="green">
                          {role.name}
                        </Tag>
                      ))}
                  </div>
                ) : (
                  ""
                )}
              </Typography.Text>
            </Space>
            <Space>
              <Typography.Text strong>Status: </Typography.Text>{" "}
              <Typography.Text>
                <Tag
                  icon={
                    record.getStatus() === "active" ? (
                      <CheckCircleOutlined />
                    ) : (
                      <ExclamationCircleOutlined />
                    )
                  }
                  color={
                    record.getStatus() === "active" ? "success" : "warning"
                  }
                >
                  {record.getStatus()}
                </Tag>
              </Typography.Text>
            </Space>
          </Space>
        );
      },
    },
    {
      title: "Roles",
      dataIndex: "roles",
      hidden: true,
      width: 200,
      ellipsis: true,
      key: "roles",
      render: (_: string, record: DestinationWarehouseMemberModel) => {
        return record.getRoles() ? (
          <div
            style={{
              textTransform: "capitalize",
              display: "flex",
              flexWrap: "wrap",
              gap: 10,
              width: 200,
            }}
          >
            {record
              .getRoles()
              .getData()
              .map((role) => (
                <Tag key={role.id} color="green">
                  {role.name}
                </Tag>
              ))}
          </div>
        ) : (
          ""
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      hidden: true,
      width: 100,
      ellipsis: true,
      align: "center" as const,
      key: "status",
      render: (_: string, record: DestinationWarehouseMemberModel) => {
        return (
          <Tag
            icon={
              record.getStatus() === "active" ? (
                <CheckCircleOutlined />
              ) : (
                <ExclamationCircleOutlined />
              )
            }
            color={record.getStatus() === "active" ? "success" : "warning"}
          >
            {record.getStatus()}
          </Tag>
        );
      },
    },
    {
      title: "Member since",
      dataIndex: "user",
      width: 150,
      ellipsis: true,
      hidden: isMobile,
      align: "center" as const,
      key: "user",
      render: (_: string, record: DestinationWarehouseMemberModel) => {
        return record.getMemberSince() ? (
          <Tag color={"purple"}>{record.getMemberSince()}</Tag>
        ) : (
          ""
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      fixed: isMobile ? "right" : undefined,
      width: isMobile ? 80 : 150,
      ellipsis: true,
      render: (_: string, record: DestinationWarehouseMemberModel) => {
        const items: MenuProps["items"] = [
          {
            key: "1",
            label: (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectedDestinationWarehouseMemberId(record.getId());
                  setModalOpen(
                    DestinationWarehouseMemberModalType["Update Member"],
                  );
                }}
              >
                <EditOutlined /> <span> Update member</span>
              </div>
            ),
          },
          {
            key: "2",
            label: (
              <Popconfirm
                title="Remove this member"
                description="Are you sure to remove this member?"
                onConfirm={() => handleDeleteMember(record.getId())}
                onCancel={onCancelModal}
                okButtonProps={{ loading: isLoadingDelete }}
                okText="Yes"
                okType="danger"
                cancelText="No"
              >
                <span onClick={(e) => e.stopPropagation()}>
                  <DeleteOutlined /> Delete
                </span>
              </Popconfirm>
            ),
            danger: true,
          },
        ];
        if (isMobile) {
          items.push({
            label: <Tag color="purple">{record.getMemberSince()}</Tag>,
            key: "created_at",
            disabled: true,
          });
        }

        return (
          <Dropdown menu={{ items }}>
            <Button icon={<HddFilled />}>
              {isMobile ? (
                <DownOutlined />
              ) : (
                <>
                  Actions <DownOutlined />{" "}
                </>
              )}
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <Table
        //@ts-ignore
        columns={columns}
        size="small"
        bordered
        loading={isLoading}
        dataSource={MemberCollectionData?.getData()}
        pagination={paginationConfig}
        rowKey="id"
        scroll={{ x: 375 }}
      />

      <Modal
        title={modalOpen && getKeyByValue(modalOpen)}
        open={Boolean(modalOpen)}
        footer={null}
        onCancel={onCancelModal}
      >
        {modalOpen === DestinationWarehouseMemberModalType["Update Member"] &&
          selectedDestinationWarehouseMemberId && (
            <DestinationWarehouseMemberModification
              onHide={onCancelModal}
              destinationWarehouseId={destinationWarehouseId}
              destinationWarehouseMemberId={
                selectedDestinationWarehouseMemberId
              }
            />
          )}
      </Modal>
    </>
  );
};
