export enum PaymentGatewayCollectionEnums {
  PaymentGatewayCollection = "PaymentGatewayCollection",
  PaymentGateway = "PaymentGateway",
}

export enum PaymentGatewayStatusEnums {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum PaymentGatewayFeeTypeEnums {
  FIXED = "fixed",
  PERCENTAGE = "percentage",
}

export enum PaymentGatewayIdentifierEnums {
  BKASH = "bkash",
  "SSL COMMERZ" = "ssl-commerz",
  NAGAD = "nagad",
  "UPAY MERCHANT" = "upay-merchant",
  "ROCKET MERCHANT" = "rocket-merchant",
  "BRAC BANK" = "brac-bank",
  "CITY BANK" = "city-bank",
}

export enum PaymentGatewayLogoTypeEnums {
  SMALL = "small",
  MEDIUM = "medium",
}

export enum PaymentGatewayTypeEnums {
  "AUTOMATIC PAYMENT" = "automatic-payment",
  "BANK DEPOSIT" = "bank-deposit",
  WALLET = "wallet",
  "MOBILE MERCHANT" = "mobile-merchant",
}
