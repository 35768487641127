import { ExchangeGroupCollectionEnums } from "enums/exchangeGroupCollectionEnums";
import {
  IExchangeGroup,
  IExchangeGroupCollection,
  IExchangeGroupExchangeList,
} from "types/exchangeGroupCollection";
import { ExchangeListCollectionEnums } from "enums/exchangeListCollectionEnums";
import { IFilterType } from "types/filters";
import { PaginationModel } from "./paginationModel";
import {formatDateTime} from "../utils/helpers";

export class ExchangeGroupExchangeListModel {
  object: ExchangeListCollectionEnums.ExchangeList;
  id: number;
  name: string;
  is_default: number;

  constructor(dataItem: IExchangeGroupExchangeList) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.name = dataItem.name;
    this.is_default = dataItem.is_default;
  }
  getId = () => this.id;
  getName = () => this.name;
  getIsDefault = () => this.is_default;
}

export class ExchangeGroupModel {
  object: ExchangeGroupCollectionEnums.ExchangeGroup;
  id: number;
  name: string;
  created_at: string;
  exchange_list: ExchangeGroupExchangeListModel;

  constructor(dataItem: IExchangeGroup) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.name = dataItem.name;
    this.created_at = dataItem.created_at;
    this.exchange_list = new ExchangeGroupExchangeListModel(
      dataItem.exchange_list,
    );
  }
  getId = () => this.id;
  getName = () => this.name;
  getCreatedAt = () => formatDateTime(this.created_at);
  getExchangeList = () => this.exchange_list;
}

export class ExchangeGroupCollectionModel {
  object: ExchangeGroupCollectionEnums.ExchangeGroupCollection;
  data: ExchangeGroupModel[];
  pagination: PaginationModel;
  filters: IFilterType;

  constructor(data: IExchangeGroupCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new ExchangeGroupModel(item));
    this.pagination = new PaginationModel(data.pagination);
    this.filters = data.filters;
  }
  getExchangeGroupById(id: number): ExchangeGroupModel | undefined {
    return this.data.find((item) => item.id === id);
  }
  getExchangeGroupIdsAsObject() {
    const obj: { [key: number]: string } = {};
    this.data.forEach((item) => (obj[item.id] = item.name));
    return obj;
  }

  getData = () => this.data;
  getPagination = () => this.pagination;
  getFilters = () => this.filters;
}
