import { BaseResource } from "../public";
import { ResponsePromise } from "lib/core/request/types/typings";
import QueryString from "qs";

class ProductResource extends BaseResource {
  productsPath = `/api/product/admin/v1/products`;

  //Product List
  getProductList(
    params: Record<string, any>,
    payload?: Record<string, any>,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    let path = `${this.productsPath}/search?${QueryString.stringify(params)}`;

    return this.client.request("POST", path, payload, {}, customHeaders);
  }

  //Product Details
  getProductDetails(
    params: Record<string, any>,
    productId: string,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    let path = `${this.productsPath}/find/${productId}?${QueryString.stringify(
      params,
    )}`;

    return this.client.request("GET", path, undefined, {}, customHeaders);
  }
}

export default ProductResource;
