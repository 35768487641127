/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Button, Form, Input, Select, message } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { useAtom } from "jotai";
import React, { useEffect, useState, createRef } from "react";

import { storeExchangeAtom } from "lib/core-react/store/store";
import { useUpdateExchangeGroup } from "lib/core-react/hooks/private/useExchangeGroup";
import { useGetExchange } from "lib/core-react/hooks/private/useExchange";

import { ExchangeGroupModel } from "models/exchangeGroupCollectionModel";
import { ExchangeListCollectionModel } from "models/exchangeListCollectionModel";
import { IExchangeGroupPostRequest } from "types/exchangeGroupCollection";

import { renderOptionsFromEnum } from "components/Form/forms";

interface IProps {
  setIsShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedExchangeGroup: ExchangeGroupModel;
}

const UpdateExchangeGroup = ({
  setIsShowUpdateModal,
  selectedExchangeGroup,
}: IProps) => {
  const { getExchange } = useGetExchange();
  const [{ data: exchangeDataAtom, isLoading: exchangeLoading }] =
    useAtom(storeExchangeAtom);

  const { updateExchangeGroup, isLoading, isError } = useUpdateExchangeGroup();
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const formRef: React.Ref<FormInstance<any>> = createRef();
  const [form] = Form.useForm();

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [selectedExchangeGroup, form]);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  useEffect(() => {
    getExchange();
  }, []);

  const ExchangeListCollectionData =
    exchangeDataAtom && new ExchangeListCollectionModel(exchangeDataAtom);

  const onFinish = async (value: IExchangeGroupPostRequest) => {
    const payload = {
      name: value.name,
      exchange_list_id: value.exchange_list_id,
    };

    try {
      await updateExchangeGroup(
        selectedExchangeGroup.id,
        payload as IExchangeGroupPostRequest,
      );
      setIsShowUpdateModal(false);
      setIsErrorVisible(false);
      message.success("Exchange group updated successfully");
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      layout="vertical"
      name="Update-Exchange-Group"
      ref={formRef}
      initialValues={{
        name: selectedExchangeGroup.getName(),
        exchange_list_id: {
          label: selectedExchangeGroup.getExchangeList().getName(),
          value: selectedExchangeGroup.getExchangeList().getId(),
        },
      }}
    >
      <Form.Item
        rules={[
          {
            required: true,
            message: "Exchange group name is required",
          },
        ]}
        label="Name"
        name="name"
      >
        <Input placeholder="Exchange group name" />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Exchange list is required" }]}
        name="exchange_list_id"
        label="Exchange List"
      >
        <Select
          loading={exchangeLoading}
          placeholder="Please select an exchange list"
        >
          {ExchangeListCollectionData?.getExchangeListIdsAsObject() &&
            renderOptionsFromEnum(
              ExchangeListCollectionData?.getExchangeListIdsAsObject(),
            )}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Submit
        </Button>
      </Form.Item>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isError}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </Form>
  );
};

export default UpdateExchangeGroup;
