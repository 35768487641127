import moment from "moment";
import React from "react";

interface IDateTimeFormatterProps {
  date: string;
}
const DateTimeFormatter = (Props: IDateTimeFormatterProps) => {
  const { date } = Props;

  if (!date) return null;

  return <>{moment.utc(date).local().format("DD/MM/YYYY, h:mm a")}</>;
};

export default DateTimeFormatter;
