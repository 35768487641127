import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MenuInfo } from "rc-menu/lib/interface";
import {
  DeleteOutlined,
  DownOutlined,
  HddFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import {
  Avatar,
  Button,
  Descriptions,
  Dropdown,
  Image,
  MenuProps,
  Popconfirm,
  Skeleton,
  Table,
  Tag,
  Typography,
  Card, Result,
} from "antd";
import { useAtom } from "jotai";

import {
  useDeleteBuyOrderProduct,
  useGetBuyOrdersDetail,
} from "lib/core-react/hooks/private/usePurchase";
import { buyOrderDetailAtom } from "lib/core-react/store/buyOrderAtoms/buyOrderAtoms";
import {
  BuyOrderDetailModel,
  BuyProductDetailModel,
} from "models/buyOrderDetailModel";
import { BuyOrderOperationEnum } from "enums/buyOrderCollectionEnums";
import { formatDateTime } from "utils/helpers";

import ProductDetailInfo from "pages/BuyAndShip/BuyOrders/EditOrder/components/ProductDetailInfo";
import ProductDetailMeta from "pages/BuyAndShip/BuyOrders/EditOrder/components/ProductDetailMeta";
import { v4 } from "uuid";
import {capitalize, getStatusBadgeColor} from "../../../../utils/helper";
import "./editOrder.css"
import useWindowWidth from "../../../../lib/core-react/hooks/public/useWindowWidth";
import {StatusTag} from "../../../../components";
const {Text} = Typography

const EditOrder = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {isMobile} = useWindowWidth()

  const { getBuyOrdersDetail } = useGetBuyOrdersDetail();
  const { deleteProduct, isLoading: deleteLoading } =
    useDeleteBuyOrderProduct();
  const [selectedProduct, setSelectedProduct] =
    useState<BuyProductDetailModel>();

  useEffect(() => {
    if (id) {
      getBuyOrdersDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [{ data, isLoading, error }] = useAtom(buyOrderDetailAtom);

  const orderDetail = data && new BuyOrderDetailModel(data);

  const handleMenuClick = (e: MenuInfo) => {
    if (
      e.key === BuyOrderOperationEnum.AddBuyOrderProductVariant &&
      selectedProduct
    ) {
      navigate(
        `/product-manage/products/product_detail/${selectedProduct.getProductId()}?buyProductId=${selectedProduct.getId()}&buyOrderId=${id}&operation=${
          BuyOrderOperationEnum.AddBuyOrderProductVariant
        }`,
      );
    }
  };

  const handleAddProduct = () => {
    if (id)
      navigate(
        `/product-manage/products?buyOrderId=${id}&operation=${BuyOrderOperationEnum.AddBuyOrderProduct}`,
      );
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      width:150,
      key: "id",
      render: (_: string, record: BuyProductDetailModel) => (
        <Avatar
          shape="square"
          size={128}
          src={<Image src={record.getProductImage()} />}
        />
      ),
    },
    {
      title: "Product Info",
      dataIndex: "productInfo",
      key: "id",
      render: (_: string, record: BuyProductDetailModel) => (
        <>
          <ProductDetailInfo productInfo={record} />
          { isMobile &&  <ProductDetailMeta id={id} detailId={record.getId()} />

          }

        </>

      ),
    },
    {
      title: "Product Meta",
      key: "id",
      hidden:isMobile,
      dataIndex: "productMeta",
      align: "center" as const,
      render: (_: string, record: BuyProductDetailModel) => (
        <ProductDetailMeta id={id} detailId={record.getId()} />
      ),
    },
    {
      title: "Actions",
      fixed: isMobile? "right" : undefined,
      width: isMobile ? 100 : 150,
      key: "id",
      render: (_: string, record: BuyProductDetailModel) => {
        const items: MenuProps["items"] = [
          {
            icon: <PlusOutlined />,
            label: "Add more variant",
            key: BuyOrderOperationEnum.AddBuyOrderProductVariant,
          },
          {
            icon: <DeleteOutlined />,
            label: (
              <Popconfirm
                title="Delete this product?"
                onConfirm={ async () => await deleteProduct(record.getId())}
                okButtonProps={{ loading: deleteLoading }}
                okText="Delete"
                okType="danger"
                cancelText="No"
              >
                <span onClick={(e) => e.stopPropagation()}>Delete product</span>
              </Popconfirm>
            ),
            key: BuyOrderOperationEnum.DeleteBuyOrderProduct,
          },
        ];

        const menuProps = {
          items,
          onClick: handleMenuClick,
        };
        return (
          <Dropdown
            menu={menuProps}
            onOpenChange={() => setSelectedProduct(record)}
          >
            <Button icon={<HddFilled />}>
              {isMobile ?  <DownOutlined /> : <>Actions <DownOutlined /> </> }

            </Button>
          </Dropdown>
        );
      },
    },
  ];

  if (error) {
    return  <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Button onClick={()=>navigate('/buy-and-ship-for-me/orders')} type="primary">Back Home</Button>}
    />
  }
  const shippingAddress = orderDetail
    ?.getProducts()
    .getFirstProductShippingAddress();

  return (
    <>
      <PageHeader
        ghost={false}
        title="Order Details"
        style={{ marginTop: "16px" }}
        onBack={() =>navigate('/buy-and-ship-for-me/orders')}
      >
        <div>
          {isLoading ? (
            <Skeleton active />
          ) : (
            <Card title={<Text strong>Order Info</Text>}>
              <Descriptions
                size="small"
                bordered
                column={{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }}
                className="responsive-descriptions"
              >
                <Descriptions.Item span={2} label={<Text strong style={{ whiteSpace: 'nowrap' }}>Order no</Text>}>
                  <Text copyable>{orderDetail?.getOrderNumber()}</Text>
                </Descriptions.Item>
                <Descriptions.Item label={<Text strong style={{ whiteSpace: 'nowrap' }}>Order placed</Text>}>
                  <Tag color="purple">{formatDateTime(orderDetail?.getCreatedAt())}</Tag>
                </Descriptions.Item>
                <Descriptions.Item label={<Text strong style={{ whiteSpace: 'nowrap' }}>Order type</Text>}>
                  <StatusTag text={orderDetail?.getOrderType() || ""} slug={orderDetail?.getOrderType() || ""}/>
                </Descriptions.Item>
                <Descriptions.Item label={<Text strong style={{ whiteSpace: 'nowrap' }}>Order status</Text>}>
                  {orderDetail?.getOrderStatus() ? (
                    <Tag color={getStatusBadgeColor(orderDetail?.getOrderStatus())}>
                      {capitalize(orderDetail.getOrderStatus())}
                    </Tag>
                  ) : (
                    ''
                  )}
                </Descriptions.Item>
                <Descriptions.Item label={<Text strong style={{ whiteSpace: 'nowrap' }}>Payment status</Text>}>
                  <Tag
                    color={
                      orderDetail?.getPaymentStatus() === 'invoice-unpaid'
                        ? 'warning'
                        : 'success'
                    }
                  >
                    {capitalize(orderDetail?.getPaymentStatus() || '')}
                  </Tag>
                </Descriptions.Item>
                <Descriptions.Item label={<Text strong style={{ whiteSpace: 'nowrap' }}>Order tag</Text>}>
                  {orderDetail?.getOrderTag()}
                </Descriptions.Item>
                <Descriptions.Item span={2} label={<Text strong style={{ whiteSpace: 'nowrap' }}>Note</Text>}>
                  {orderDetail?.getNote()}
                </Descriptions.Item>
                <Descriptions.Item
                  span={3}
                  label={<Text strong style={{ whiteSpace: 'nowrap' }}>Shipping Address</Text>}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 10,
                    }}
                  >
                    {shippingAddress && Object.keys(shippingAddress).length ? (
                      Object.keys(shippingAddress).map((key) => (
                        <div key={v4()}>
                          <Text strong style={{ textTransform: 'capitalize' }}>
                            {key}:
                          </Text> {shippingAddress[key]}
                        </div>
                      ))
                    ) : (
                      <Tag color="red">Address not found</Tag>
                    )}
                  </div>
                </Descriptions.Item>
              </Descriptions>
            </Card>

          )
          }
          {isLoading ? (
            <Skeleton style={{ marginTop: "16px" }} active />
          ) : orderDetail?.getOrderHandler ? (

            <Card style={{ margin: '10px 0px' }} title={<Text strong>Order Handler</Text>}>
              <Descriptions size="small" bordered>
                <Descriptions.Item label={<Text strong>Name</Text>}>
                  {orderDetail?.getOrderHandler()?.getName()}
                </Descriptions.Item>
                <Descriptions.Item label={<Text strong>Email</Text>}>
                  {orderDetail?.getOrderHandler()?.getEmail()}
                </Descriptions.Item>
                <Descriptions.Item label={<Text strong>Phone</Text>}>
                  {orderDetail?.getOrderHandler()?.getPhone()}
                </Descriptions.Item>
              </Descriptions>
            </Card>

          ) : (
            ""
          )}

          {isLoading ? (
            <Skeleton style={{ marginTop: "16px" }} active />
          ) : (

            <Card title={<Text strong>Customer</Text>}>
              <Descriptions
                size="small"
                bordered
                style={{ marginTop: '16px' }}
                title=""
              >
                <Descriptions.Item label={<Text strong>Name</Text>}>
                  {orderDetail?.getCustomer()?.getName()}
                </Descriptions.Item>
                <Descriptions.Item label={<Text strong>Email</Text>}>
                  {orderDetail?.getCustomer()?.getEmail()}
                </Descriptions.Item>
                <Descriptions.Item label={<Text strong>Phone</Text>}>
                  {orderDetail?.getCustomer()?.getPhone()}
                </Descriptions.Item>
              </Descriptions>
            </Card>

          )}
        </div>
      </PageHeader>
      <PageHeader
        ghost={false}
        title="Products"
        extra={[
          <Button key={v4()} type="primary" onClick={handleAddProduct}>
            Add Product
          </Button>,
        ]}
      >
        <div>
          <Table
            loading={isLoading}
            //@ts-ignore
            columns={columns}
            rowKey="id"
            dataSource={orderDetail?.getProducts().getData()}
            scroll={{x:1000}}
          />
        </div>
      </PageHeader>
    </>
  );
};
export default EditOrder;
