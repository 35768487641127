import React, { useState } from "react";
import { Button, Input, notification, Popconfirm } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { AgentCompanyModel } from "../../../../models/agentCompanyCollectionModel";
import {
  useApproveBuyProduct,
  useBulkApproveBuyProduct,
} from "../../../../lib/core-react/hooks/private/usePurchase";
import { BulkApprovedPayload } from "types/buyOrderCollection";

const AgentCommissionSubmit = ({
  record,
  productId,
  setIsApproveModalOpen,
  selectedRowKeys,
}: {
  record: AgentCompanyModel;
  productId: number;
  setIsApproveModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRowKeys: React.Key[];
}) => {
  const [open, setOpen] = useState(false);
  const [, setInputError] = useState(false);
  const [value, setValue] = useState("");
  const [isAssigned, setIsAssigned] = useState(false);

  const { approveBuyProduct, isLoading: isApproveLoading } =
    useApproveBuyProduct();

  const { bulkApproveBuyProduct, isLoading: isBulkApproveLoading } =
    useBulkApproveBuyProduct();

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    const inputValue = (e.target as HTMLInputElement).value;

    setValue(inputValue);
    if (inputValue && Number(inputValue) >= 0) {
      setInputError(false);
    }
  };

  const handleSubmit = async () => {
    const payload = {
      commission_rate: Number(value),
      agent_company_id: record.getId(),
    };

    try {
      if (selectedRowKeys && selectedRowKeys.length > 0) {
        const bulkPayload: BulkApprovedPayload = {
          ...payload,
          product_ids: selectedRowKeys.map((key) => Number(key)),
        };
        await bulkApproveBuyProduct(bulkPayload);
      } else {
        await approveBuyProduct(productId, payload);
      }
      setOpen(false);
      setValue("");
      setIsAssigned(true);
      setIsApproveModalOpen(false);
    } catch (error: any) {
      notification["error"]({
        message: error.response.data.message,
      });
    }
  };

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setInputError(false);
  };

  return (
    <Popconfirm
      placement="top"
      title={
        <div>
          <p>Commission(Optional):</p>
          <Input
            defaultValue={value}
            onInput={(e) => handleChange(e)}
            type={"number"}
            min={0}
            suffix="%"
            style={{ WebkitAppearance: "none" }}
          />
        </div>
      }
      open={open}
      okButtonProps={{ loading: isApproveLoading }}
      onConfirm={() => handleSubmit()}
      okText={"Submit"}
      cancelText={"Cancel"}
      onCancel={handleCancel}
      icon={""}
    >
      <Button
        icon={<CheckCircleOutlined />}
        onClick={showPopconfirm}
        style={{
          backgroundColor: isAssigned ? "green" : "",
          color: isAssigned ? "white" : "",
        }}
        disabled={isAssigned}
      >
        {isAssigned ? "Assigned" : "Assign"}
      </Button>
    </Popconfirm>
  );
};

export default AgentCommissionSubmit;
