import { useAtom } from "jotai";
import { useService } from "lib/core-react/contexts";
import { addonServicsCollectionAtom } from "lib/core-react/store/store";
import { getError } from "../utils/errors";
import { useState } from "react";
import {
  CreateServicesnPostRequestModel,
  Price,
  UpdateServicesnPutRequestModel,
} from "models/addonServiceCollectionModel";

export const useAddonSerivesList = () => {
  const [addons, setAddon] = useAtom(addonServicsCollectionAtom);

  const { addonService } = useService();

  const getAddonSerives = async (params?: string): Promise<void> => {
    setAddon({ ...addons, isLoading: true, error: null });
    try {
      const response = await addonService.addonServicsResource.getList();
      setAddon({
        ...addons,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setAddon({
        ...addons,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
    }
  };

  return { getAddonSerives } as const;
};

export const useCreateService = () => {
  const { addonService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [addons, setAddon] = useAtom(addonServicsCollectionAtom);

  const createService = async (payload: CreateServicesnPostRequestModel) => {
    setIsLoading(true);
    try {
      const response =
        await addonService.addonServicsResource.CreateService(payload);

      setIsLoading(false);
      setAddon({ ...addons, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
    }
  };

  return { createService, isLoading, isError };
};

export const useUpdateService = () => {
  const { addonService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [addons, setAddon] = useAtom(addonServicsCollectionAtom);

  const updateService = async (
    id: number,
    payload: UpdateServicesnPutRequestModel,
  ) => {
    setIsLoading(true);
    try {
      const response = await addonService.addonServicsResource.updateService(
        id,
        payload,
      );

      setIsLoading(false);
      setAddon({ ...addons, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
    }
  };

  return { updateService, isLoading, isError };
};

export const useAddPrice = () => {
  const { addonService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [addons, setAddon] = useAtom(addonServicsCollectionAtom);

  const addPrice = async (id: number, payload: Price) => {
    setIsLoading(true);
    try {
      const response = await addonService.addonServicsResource.addPrice(
        id,
        payload,
      );

      setIsLoading(false);
      setAddon({ ...addons, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
    }
  };

  return { addPrice, isLoading, isError };
};

export const useUpdatePrice = () => {
  const { addonService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [addons, setAddon] = useAtom(addonServicsCollectionAtom);

  const updatePrice = async (id: number, priceid: number, payload: Price) => {
    setIsLoading(true);
    try {
      const response = await addonService.addonServicsResource.updatePrice(
        id,
        priceid,
        payload,
      );

      setIsLoading(false);
      setAddon({ ...addons, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
    }
  };

  return { updatePrice, isLoading, isError };
};

export const useDeletePrice = () => {
  const { addonService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [addons, setAddon] = useAtom(addonServicsCollectionAtom);

  const deletePrice = async (id: number, priceid: number) => {
    setIsLoading(true);
    try {
      const response = await addonService.addonServicsResource.deletePrice(
        id,
        priceid,
      );

      setIsLoading(false);
      setAddon({ ...addons, refetch: true });
      return response.data;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
    }
  };

  return { deletePrice, isLoading, isError };
};
