import { Alert, Card, Col, Row, Skeleton} from 'antd'
import FiltersComponent from 'components/FiltersComponent'
import useDataFilters from 'hooks/useDataFilters'
import { useAtom } from 'jotai'
import { useWalletTransactions } from 'lib/core-react/hooks/private/useWallet'
import { TransactionCollectionAtom } from 'lib/core-react/store/store'
import { ApiHelperModel } from 'models/apiHelper'
import {
  TransactionCollectionModel,
} from 'models/walletTransactionCollectionModel'
import WalletMasterTable from 'pages/WalletManage/WalletMasterTable'
import { useEffect } from 'react'
import { IFilterType } from 'types/filters'

interface IWalletTableData {
  isLoading: false
  walletId: Number[]
}

const WalletTable = ({ isLoading, walletId }: IWalletTableData) => {
  const [{ data: TransactionCollectionData, error: isError }] =
    useAtom(TransactionCollectionAtom)

  const { getWalletTransctions } = useWalletTransactions()

  const {
    filters,
    handleFilterChange,
    isFetched,
    handelFilterClear,
    initializeAvailableFilter,
    refetch: refetchFromFilter
  } = useDataFilters()

  useEffect(() => {
    if (walletId && walletId.length > 0 || refetchFromFilter) {
      getWalletTransctions(walletId[0])
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletId , refetchFromFilter])

  const transactionsCollectionData =
    TransactionCollectionData &&
    new TransactionCollectionModel(TransactionCollectionData)

  const filterData = transactionsCollectionData?.getFilters()

  useEffect(() => {
    if (!isFetched && transactionsCollectionData?.getFilters()) {
      initializeAvailableFilter(filterData as IFilterType)
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetched,
    initializeAvailableFilter,
      // eslint-disable-next-line react-hooks/exhaustive-deps
    transactionsCollectionData?.getFilters(),
  ])

  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const walletIdValue = walletId[0]
    const pageInfo = { page: pageCount, per_page: pageSize }
    handleFilterChange(pageInfo)
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      (data: string) => getWalletTransctions(walletIdValue),
    )
  }

  const handleProductFilter = () => {
    filters &&
      ApiHelperModel.makeGetRequest(filters, (data: string) =>
        getWalletTransctions(walletId[0]),
      )
  }

  return (
    <>
      {filters && Object.keys(filters).length > 0 && (
        <Row>
          <Col span={24}>
            <Card title="Filter">
              <FiltersComponent
                handleProductFilter={handleProductFilter}
                handleFilterChange={handleFilterChange}
                handelFilterClear={handelFilterClear}
                isFetched={isFetched}
                filters={filters}
                filtersData={filterData}
                isFromProductReceived={true}
              />
            </Card>
          </Col>
        </Row>
      )}
      <div style={{ marginTop: 10 }}>
        {isLoading ? (
          <Skeleton active />
        ) : isError ? (
          <Alert message={isError} type="error" />
        ) : transactionsCollectionData ? (
          <WalletMasterTable
            isLoading={isLoading}
            transactionsCollectionData={transactionsCollectionData}
            handlePaginationChange={handlePaginationChange}
          />
        ) : (
          <Skeleton />
        )}
      </div>
    </>
  )
}

export default WalletTable
