/* eslint-disable react-hooks/exhaustive-deps */
import { DownOutlined, EditOutlined, HddFilled } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  MenuProps,
  Modal,
  Row,
  Table,
  Tag,
  notification,
} from "antd";
import { useState, useEffect } from "react";
import UpdateLanguage from "./components/LanguageModifictionas";
import { LanguageDataModel, LanguageModel } from "models/language";
import useDataFilters from "hooks/useDataFilters";
import { useAtom } from "jotai";
import { regionLanguage } from "lib/core-react/store/store";
import {
  useCreateLanguage,
  useGetLanguage,
  useUpdateLanguage,
} from "lib/core-react/hooks/private/useLanguage";
import { IFilterType } from "types/filters";
import FiltersComponent from "components/FiltersComponent";
import { LanguageDataStatus } from "enums/languageCollectionEnums";
import { PaginationModel } from "models/pagination";
import { ApiHelperModel } from "models/apiHelper";
import { formatDateTime } from "../../../utils/helpers";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import { MenuInfo } from "rc-menu/lib/interface";
import { ILanguageUpdateRequest } from "types/language";
const { Column } = Table;

const Language = () => {
  const { getLanguage } = useGetLanguage();
  const { updateLanguage, isLoading: updateLoading } = useUpdateLanguage();
  const { createLanguage, isLoading: createLoading } = useCreateLanguage();
  const { isMobile } = useWindowWidth();


  const [form] = Form.useForm();

  const [{ data: languageStoreData, isLoading, refetch }] =
    useAtom(regionLanguage);

  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
    refetch: refetchFromFilter
  } = useDataFilters();

  // Api Call
  useEffect(() => {
    if ((filters && !isFetched && isFirstCall) || refetch || refetchFromFilter) {
      filters && ApiHelperModel.makeGetRequest(filters, getLanguage);
    }
  }, [isFirstCall, filters, isFetched, refetch , refetchFromFilter]);

  // Language
  const LanguageData =
    languageStoreData && new LanguageModel(languageStoreData);

  // Filter
  let filterData = LanguageData?.filters;
  // Getting all available filters
  useEffect(() => {
    if (!isFetched && LanguageData?.filters) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [isFetched, initializeAvailableFilter, LanguageData?.filters]);

  // Update modal state
  const [isShowCreateUpdateModal, setIsShowCreateUpdateModal] =
    useState<boolean>(false);
  const [seletedLanguage, setSeletedLanguage] = useState<LanguageDataModel>();
  const [rtlData, setRTLData] = useState(Boolean(seletedLanguage?.is_rtl));

  // Handle Modal
  const handleMenuClick = (e: MenuInfo, record: LanguageDataModel) => {
    setSeletedLanguage(record);

    if (e.key === "update") {
      setIsShowCreateUpdateModal(true);
    }
  };

  const onFinish = async (value) => {
    try {
      const payload: ILanguageUpdateRequest = {
        code: value.code,
        is_rtl: rtlData,
        name: value.name,
        status: value.status,
      };

      if (seletedLanguage) {
        await updateLanguage(seletedLanguage?.id as number, payload);
        setSeletedLanguage(undefined);
        notification['success']({
          message: "Language Update successfully",
        })
      }else{
        await createLanguage(payload);
        notification['success']({
          message: "Language Create successfully",
        })
      }
      setIsShowCreateUpdateModal(false);
    } catch (error:any) {
      notification['error']({
        message: error.response.data.message,
      })
    }
  };

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getLanguage,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    LanguageData,
    handlePaginationChange,
  );

  // Filter Handling
  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getLanguage);
  };

  return (
    <>
      <div>
        <PageHeader
          ghost={false}
          title="Region Language"
          style={{ marginTop: "10px" }}
          extra={[
            <Button
              key="1"
              type="primary"
              onClick={() => setIsShowCreateUpdateModal(true)}
            >
              Add Language
            </Button>,
          ]}
        >
          <div>
            {filters && Object.keys(filters).length > 0 && (
              <Row>
                <Col span={24}>
                  <Card title="Filter">
                    <FiltersComponent
                      handleProductFilter={handleProductFilter}
                      handleFilterChange={handleFilterChange}
                      handelFilterClear={handelFilterClear}
                      isFetched={isFetched}
                      filters={filters}
                      filtersData={filterData}
                      isFromProductReceived={true}
                    />
                  </Card>
                </Col>
              </Row>
            )}

            <Table
              style={{ marginTop: "15px" }}
              loading={isLoading}
              rowKey="id"
              pagination={paginationConfig}
              dataSource={LanguageData?.data}
              scroll={{ x: 1000 }}
            >
              <Column width={70} title="ID" dataIndex="id" key="id" />
              <Column title="Language Code" dataIndex="code" key="code" />
              <Column title="Language Name" dataIndex="name" key="name" />
              <Column
                title="Is RTL"
                dataIndex="is_rtl"
                key="is_rtl"
                render={(_, record: LanguageDataModel) => (
                  <Tag
                    color={record.is_rtl ? "green" : "red"}
                    key={record.status}
                  >
                    {record.is_rtl ? "true" : "false"}
                  </Tag>
                )}
              />
              <Column
                title="Status"
                dataIndex="status"
                key="status"
                render={(_, record: LanguageDataModel) => (
                  <Tag
                    color={
                      record.status === LanguageDataStatus.active // active
                        ? "green"
                        : "red"
                    }
                    key={record.status}
                  >
                    {record.status}
                  </Tag>
                )}
              />
              <Column
                title="Created at"
                hidden={isMobile}
                dataIndex="created_at"
                key="created_at"
                render={(created_at) => {
                  return (
                    <Tag color={"purple"}>{formatDateTime(created_at)}</Tag>
                  );
                }}
              />
              <Column
                title="Action"
                key="action"
                fixed={isMobile ? "right" : undefined}
                width={isMobile ? 100 : 150}
                render={(_, record: LanguageDataModel) => {
                  const menuItems: MenuProps["items"] = [
                    {
                      label: "Update",
                      key: "update",
                      icon: <EditOutlined />,
                      onClick: (e) => handleMenuClick(e, record),
                    },
                  ];
                  if (isMobile) {
                    menuItems.push({
                      key: "2",
                      disabled: true,
                      label: (
                        <Tag color={"purple"}>
                          {formatDateTime(record.created_at)}
                        </Tag>
                      ),
                    });
                  }

                  return (
                    <Dropdown
                      menu={{
                        items: menuItems,
                      }}
                    >
                      <Button icon={<HddFilled />}>
                        {isMobile ? (
                          <DownOutlined />
                        ) : (
                          <>
                            {" "}
                            Actions <DownOutlined />
                          </>
                        )}
                      </Button>
                    </Dropdown>
                  );
                }}
              />
            </Table>
          </div>
        </PageHeader>
      </div>

      <Modal
        title={`${seletedLanguage ? "Update" : "Create"} Language`}
        open={isShowCreateUpdateModal}
        okText={seletedLanguage ? "Update" : "Create"}
        okButtonProps={{ loading: createLoading || updateLoading }}
        onCancel={() => {
          setIsShowCreateUpdateModal(false);
          setSeletedLanguage(undefined);
        }}
        onOk={form.submit}
        centered
      >
        <UpdateLanguage
          rtlData={rtlData}
          setRTLData={setRTLData}
          data={seletedLanguage}
          form={form}
          onFinish={onFinish}
        />
      </Modal>
    </>
  );
};

export default Language;
