import { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { Table, Row, Button, Col, Switch, Alert, Modal } from "antd";
import { useAtom } from "jotai";
import { PlusOutlined } from "@ant-design/icons";

import { permissionAtom, roleAtom } from "lib/core-react/store/store";
import { useGetPermission } from "lib/core-react/hooks/private/usePermission";
import {
  useGetRole,
  useUpdateRole,
} from "lib/core-react/hooks/private/useRole";
import useDataFilters from "hooks/useDataFilters";

import {
  PermissionCollectionModel,
  PermissionModel,
} from "models/permissionCollectionModel";
import {
  RoleCollectionModel,
  RoleModel,
  RoleUpdateRequestModel,
} from "models/roleCollectionModel";
import { ApiHelperModel } from "models/apiHelper";
import RoleModification from "../Role/components/RoleModification";
import { v4 } from "uuid";

interface RowData {
  key: string;
  name: string;
  [roleName: string]: boolean | string;
}

const makeData = (
  permissions: PermissionModel[],
  roles: RoleModel[],
): RowData[] => {
  return permissions.map((item) => {
    let rowData: RowData = {
      key: item.id.toString(),
      name: item.name,
    };

    for (let i = 0; i < roles.length; i++) {
      let found = roles[i]?.permissions?.data?.find(
        (perm) => perm.id === item.id,
      );
      rowData[roles[i].name] = found ? true : false;
    }

    return rowData;
  });
};

const Overview = () => {
  const { getRole } = useGetRole();
  const [{ data: roleData, isLoading, refetch }] = useAtom(roleAtom);
  const [isCall, setIsCall] = useState(false);
  const { getPermission } = useGetPermission();
  const [{ data: permissionData, isLoading: isLoadingPermission }] =
    useAtom(permissionAtom);

  const {
    updateRole,
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
  } = useUpdateRole();

  const [isShowCreateModal, setIsShowCreateModal] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const { filters, isFirstCall, isFetched } = useDataFilters();

  const RoleData = roleData && new RoleCollectionModel(roleData);
  const PermissionData =
    permissionData && new PermissionCollectionModel(permissionData);

  // Api Call
  useEffect(() => {
    if ((filters && !isFetched && isFirstCall) || refetch) {
      filters && ApiHelperModel.makeGetRequest(filters, getRole);
      ApiHelperModel.makeGetRequest({}, getPermission);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstCall, filters, isFetched, refetch]);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  let permissionColumns = [
    {
      title: "Permissions",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
  ];

  const handlePermissionChange = async (
    text: boolean,
    record: RowData,
    item: RoleModel,
  ) => {
    setIsCall(true);
    const permission_ids: number[] = item.permissions?.data?.map(
      (permission) => permission.id,
    );
    if (text) {
      permission_ids.push(Number(record.key));
    } else {
      permission_ids.filter((permission) => permission === Number(record.key));
    }

    const payload: RoleUpdateRequestModel = {
      name: item.name,
      label: item.label,
      permission_ids,
    };
    try {
      await updateRole(item.id, payload);
      setIsErrorVisible(false);
    } catch (error) {
      setIsErrorVisible(true);
      setIsCall(false);
    } finally {
      setIsCall(false);
    }
  };

  let roleColumns = RoleData
    ? RoleData.data.map((item) => {
        return {
          align: "center",
          title: item.name,
          dataIndex: item.name,
          item: item,
          key: item.name,
          width: 200,
          render: (text: boolean, record: RowData) => {
            return (
              <Switch
                defaultChecked={text}
                onChange={(e) => handlePermissionChange(e, record, item)}
                disabled={isLoadingUpdate || isCall}
              />
            );
          },
        };
      })
    : [];

  let newColumns = [...permissionColumns, ...roleColumns];

  // combine table data of roles and permissions
  let tableData =
    RoleData && RoleData.data && PermissionData && PermissionData.data
      ? makeData(PermissionData?.data, RoleData?.data)
      : [];

  return (
    <>
      <Row>
        <Col span={24}>
          <PageHeader
            ghost={false}
            style={{ marginTop: "10px" }}
            title={"Roles and permissions"}
            extra={[
              <Button
                key={v4()}
                onClick={() => {
                  setIsShowCreateModal(true);
                }}
                icon={<PlusOutlined />}
                type="primary"
              >
                Add new role
              </Button>,
            ]}
          >
            <Table
              columns={newColumns}
              dataSource={tableData}
              loading={isLoading || isLoadingPermission || isCall}
              bordered
              pagination={false}
              scroll={{ y: "70vh" }}
            />
          </PageHeader>
        </Col>
      </Row>

      <Modal
        title={`Create Role`}
        open={isShowCreateModal}
        footer={false}
        onCancel={() => setIsShowCreateModal(false)}
      >
        <RoleModification onHide={() => setIsShowCreateModal(false)} />
      </Modal>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isErrorUpdate}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </>
  );
};

export default Overview;
