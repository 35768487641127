import {
  DownOutlined,
  EditOutlined,
  HddFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import {
  Button,
  Card,
  Col,
  Dropdown,
  MenuProps,
  Modal,
  Row,
  Table,
  Tag,
  Typography,
} from "antd";
import { StatusTag } from "components";
import FiltersComponent from "components/FiltersComponent";
import useDataFilters from "hooks/useDataFilters";
import { useAtom } from "jotai";
import {
  useAdminUserList,
} from "lib/core-react/hooks/private/useAdminUser";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import { adminUserCollection } from "lib/core-react/store/store";
import { ApiHelperModel } from "models/apiHelper";
import { PaginationModel } from "models/pagination";
import { UserCollectionModel, UserModel } from "models/userCollectionModel";
import { useEffect, useState } from "react";
import { IFilterType } from "types/filters";
import { formatDate } from "utils/helpers";
import { v4 } from "uuid";
import UpdateAdminUser from "./components/updateAdminUser";
import { MenuInfo } from "rc-menu/lib/interface";
import CreateAdminUser from "./components/createAdminUser";
import { generateColor } from "utils/helper";
import { useGetRole } from "lib/core-react/hooks/private";
import { RoleCollectionModel } from "models/roleCollectionModel";
import UpdateAdminUserRoleOnly from "./components/updateOnlyRoles";

export const AdminUser = () => {
  const { getAdminUser } = useAdminUserList();
  const [{ data: adminUserData, isLoading, refetch }] =
    useAtom(adminUserCollection);
  const { isMobile } = useWindowWidth();

  const [isShowUpdateModal, setIsShowUpdateModal] = useState<boolean>(false);
  const [selectedAdminUser, setSelectedAdminUser] = useState<
    UserModel | undefined
  >(undefined);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<UserModel | undefined>(
    undefined,
  );

  const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);

  const { Text } = Typography;
  const {
    filters,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
    handleFilterChange,
    handelFilterClear,
    refetch: refetchFromFilter
  } = useDataFilters();

  const { getRole } = useGetRole();

  // Api Call
  useEffect(() => {
    getRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  // Api Call
  useEffect(() => {
    if ((filters && !isFetched && isFirstCall) || refetch || refetchFromFilter) {
      filters && ApiHelperModel.makeGetRequest(filters, getAdminUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstCall, filters, isFetched, refetch , refetchFromFilter]);

  const adminUserCollectionData =
    adminUserData && new UserCollectionModel(adminUserData);

  // Filter
  const filterData = adminUserCollectionData?.filters;

  // Pagination
  const paginationData = adminUserCollectionData?.pagination;

  // Getting all available filters
  useEffect(() => {
    if (!isFetched && adminUserCollectionData?.filters) {
      initializeAvailableFilter(filterData as IFilterType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, initializeAvailableFilter, adminUserCollectionData?.filters]);

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getAdminUser,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    adminUserCollectionData,
    handlePaginationChange,
  );

  // Filter Handler
  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getAdminUser);
  };

  const handleMenuClick = (e: MenuInfo, record: UserModel) => {
    if (e.key === "update") {
      setSelectedAdminUser(record);
      setIsShowUpdateModal(true);
    }
  };

  const handleRoleChange = (value) => {
    setIsModalVisible(true);
    setSelectedRoles(value);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "sl",
      align: "center" as const,
      width: 70,
      render: (_: string, __: UserModel, index: number) => {
        return (
          <div>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </div>
        );
      },
    },
    {
      title: "Customer Details",
      dataIndex: "name",
      hidden: !isMobile,
      key: "name",
      render: (text: string, record: UserModel) => {
        const status = record.is_active === 1 ? "Active" : "Inactive";
        return (
          <>
            <span style={{ fontWeight: "bold" }}>Name: </span>{" "}
            <Text>{record.getName()}</Text>
            <br />
            <span style={{ fontWeight: "bold" }}>Email: </span>
            <Text>{record.getEmail()}</Text>
            <br />
            <span style={{ fontWeight: "bold", marginTop: 10 }}>Status: </span>
            <StatusTag
              text={status.toLowerCase()}
              slug={status.toLowerCase()}
            />
            <br />
            <span style={{ fontWeight: "bold", marginTop: 10 }}>
              Created At:
            </span>{" "}
            <Tag style={{ marginTop: 10 }} color="purple">
              {formatDate(record.getCreatedAt())}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      hidden: isMobile,
      key: "name",
      render: (text: string) => {
        return (
          <>
            <Row>{text}</Row>
          </>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      hidden: isMobile,
      key: "email",
      render: (email: string) => {
        return (
          <>
            <Text>{email}</Text>
          </>
        );
      },
    },
    {
      title: "Role",
      dataIndex: "roles",
      key: "roles",
      width: 200,
      hidden: !isMobile,
      render: (role: RoleCollectionModel, record: UserModel) => {
        if (role.data.length === 0) {
          return <Text type="danger">No Available Roles</Text>;
        }
        return (
          <>
            {role.data.map((singleRole, index) => (
              <Tag
                style={{ marginTop: 10 }}
                key={index}
                color={generateColor(singleRole.name)}
              >
                {singleRole.name}
              </Tag>
            ))}
            <EditOutlined onClick={() => handleRoleChange(record)} />
          </>
        );
      },
    },
    {
      title: "Role",
      dataIndex: "roles",
      hidden: isMobile,
      width: 400,
      key: "roles",
      render: (role: RoleCollectionModel, record: UserModel) => {
        if (role.data.length === 0) {
          return <Text type="danger">No Available Roles</Text>;
        }
        return (
          <>
            {role.data.map((singleRole, index) => (
              <Tag
                style={{ marginTop: 10 }}
                key={index}
                color={generateColor(singleRole.name)}
              >
                {singleRole.name}
              </Tag>
            ))}
            <EditOutlined onClick={() => handleRoleChange(record)} />
          </>
        );
      },
    },
    {
      title: "Active",
      dataIndex: "is_active",
      hidden: isMobile,
      key: "is_active",
      render: (is_active: number) => {
        const status = is_active === 1 ? "Active" : "Inactive";
        return (
          <>
            <StatusTag
              text={status.toLowerCase()}
              slug={status.toLowerCase()}
            />
          </>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      hidden: isMobile,
      key: "created_at",
      render: (created_at: string) => {
        return (
          <>
            <Tag color="purple">{formatDate(created_at)}</Tag>
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      fixed: isMobile ? "right" : undefined,
      width: 100,
      render: (_: string, record: UserModel) => {
        const items: MenuProps["items"] = [
          {
            icon: <EditOutlined />,
            label: "Update",
            key: "update",
            onClick: async (e) => handleMenuClick(e, record),
          },
        ];

        return (
          <Dropdown
            menu={{
              items: items,
            }}
          >
            <Button icon={<HddFilled />}>
              {isMobile ? (
                <DownOutlined />
              ) : (
                <>
                  Actions <DownOutlined />{" "}
                </>
              )}
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <PageHeader
            ghost={false}
            style={{ marginTop: "10px" }}
            title={"Admin User"}
            extra={[
              <Button
                key={v4()}
                onClick={() => {
                  setIsShowCreateModal(true);
                }}
                icon={<PlusOutlined />}
                type="primary"
              >
                Create Admin
              </Button>,
            ]}
            onBack={() => window.history.back()}
          >
            {filters && Object.keys(filters).length > 0 && (
              <Row>
                <Col span={24}>
                  <Card title="Filter">
                    <FiltersComponent
                      handleProductFilter={handleProductFilter}
                      handleFilterChange={handleFilterChange}
                      handelFilterClear={handelFilterClear}
                      isFetched={isFetched}
                      filters={filters}
                      filtersData={filterData}
                      isFromProductReceived={true}
                    />
                  </Card>
                </Col>
              </Row>
            )}

            <Table
              style={{ marginTop: 10 }}
              //@ts-ignore
              columns={columns}
              dataSource={adminUserCollectionData?.data}
              loading={isLoading}
              size="middle"
              pagination={paginationConfig}
              rowKey="id"
              scroll={{ x: 700 }}
            />
          </PageHeader>
        </Col>
      </Row>
      {selectedAdminUser && (
        <Modal
          title={`Update Admin User`}
          open={isShowUpdateModal}
          footer={false}
          onCancel={() => setIsShowUpdateModal(false)}
        >
          <UpdateAdminUser
            selectedAdminUser={selectedAdminUser}
            setIsShowUpdateModal={setIsShowUpdateModal}
          />
        </Modal>
      )}

      {isShowCreateModal && (
        <Modal
          title={`Create Admin User`}
          open={isShowCreateModal}
          footer={false}
          onCancel={() => setIsShowCreateModal(false)}
        >
          <CreateAdminUser setIsShowCreateModal={setIsShowCreateModal} />
        </Modal>
      )}

      {selectedRoles && (
        <Modal
          title="Select Roles"
          visible={isModalVisible}
          footer={false}
          onCancel={handleCancel}
        >
          <UpdateAdminUserRoleOnly selectedRoles={selectedRoles}  setIsModalVisible={setIsModalVisible} />
        </Modal>
      )}
    </>
  );
};
