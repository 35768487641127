import React from "react"
import { Menu } from "antd"
import { getStatusesWithIcon } from "./Statuses"
import { DoubleRightOutlined, RollbackOutlined } from "@ant-design/icons"

const { SubMenu } = Menu

const StatusAction = props => {
    const { handleStatusUpdate, product, ...rest } = props
    const statuses = getStatusesWithIcon(product.shipping_type)

    const availableStatuses = () => {
        let data = []
        for (let i = 0; i < statuses.length; i++) {
            if (product?.status?.slug === 'shipping-started') {
                data.push(statuses[0])
                break
            }
            else if (statuses[i]?.slug === product?.status?.slug) {
                if (!i) {
                    let forward = statuses[i + 1]
                    forward.icon = <DoubleRightOutlined />
                    data.push(forward)
                } else if (i === statuses.length - 1) {
                    let backward = statuses[i - 1]
                    backward.icon = <RollbackOutlined />
                    data.push(backward)
                } else {
                    let backward = statuses[i - 1]
                    let forward = statuses[i + 1]
                    backward.icon = <RollbackOutlined />
                    forward.icon = <DoubleRightOutlined />
                    data.push(backward)
                    data.push(forward)
                }
                break
            }
        }
        return data
    }

    return (
        <SubMenu title="Change Status" key={`change_status${product}`} {...rest}>
            {
                availableStatuses().map((status, index) => {
                    return (
                        <Menu.Item
                            key={index}
                            onClick={() => handleStatusUpdate(product?.id, status.slug)}
                            icon={status.icon}
                        >Set As {status.name}</Menu.Item>
                    )
                })
            }
        </SubMenu>
    )
}

export default StatusAction