import { PageHeader } from "@ant-design/pro-layout";
import { Card, Col, Modal, Row, Space, Table, Tabs, Typography } from "antd";
import { useState, useEffect } from "react";
import { useAtom } from "jotai";

import { regionRegion, wishlistAtom } from "lib/core-react/store/store";
import { useGetRegion } from "lib/core-react/hooks/private";
import useDataFilters from "hooks/useDataFilters";
import { RegionModel } from "models/region";
import { ApiHelperModel } from "models/apiHelper";
import { PaginationModel } from "models/pagination";
import { IFilterType } from "types/filters";

import FiltersComponent from "components/FiltersComponent";
import { useGetWishlist } from "lib/core-react/hooks/private/useWishlist";
import {
  WishlistCollectionModel,
  WishlistItemModel,
  WishlistModel,
} from "models/wishlistCollectionModel";
import WishlistItemTable from "./components/WishlistItemTable";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";

const Wishlists = () => {
  const { getRegion } = useGetRegion();
  const { getWishlist } = useGetWishlist();
  const [{ data, isLoading, refetch }] = useAtom(wishlistAtom);
  const [selectedRegion, setSelectedRegion] = useState("3");
  const [selectedWishlistItems, setSelectedWishlistItems] = useState<
    WishlistItemModel[] | undefined
  >(undefined);

  const { isMobile } = useWindowWidth();
  const [{ data: regionDataAtom }] = useAtom(regionRegion);
  const RegionData = regionDataAtom && new RegionModel(regionDataAtom);

  useEffect(() => {
    getRegion("per_page=200");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getWishlist(`region_id=${selectedRegion}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRegion]);

  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
  } = useDataFilters();

  // Api Call
  useEffect(() => {
    if ((filters && !isFetched && isFirstCall) || refetch) {
      filters && ApiHelperModel.makeGetRequest(filters, getWishlist);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstCall, filters, isFetched, refetch, selectedRegion]);

  const WishlistData = data && new WishlistCollectionModel(data);

  // Filter
  let filterData = WishlistData?.filters;

  // Pagination
  const paginationData = WishlistData?.pagination;

  // Getting all available filters
  useEffect(() => {
    if (!isFetched && WishlistData?.filters) {
      initializeAvailableFilter(filterData as IFilterType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, initializeAvailableFilter, WishlistData?.filters]);

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getWishlist,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    WishlistData,
    handlePaginationChange,
  );

  // Filter Handler
  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getWishlist);
  };

  const onChangeRegion = (key: string) => {
    console.log(key,"key")
    setSelectedRegion(key);
  };

  const expandedRowRender = ({ wishlist_items }: WishlistModel) => {
    return <WishlistItemTable data={wishlist_items.data} />;
  };

  const wishlistColumns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "sl",
      align: "center" as const,
      width: 70,
      render: (_: string, __: WishlistModel, index: number) => {
        return (
          <div>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </div>
        );
      },
    },
    {
      title: "Details",
      dataIndex: "customer",
      hidden: !isMobile,
      key: "customer",
      render: (_: string, record: WishlistModel) => {
        return (
          <>
            {record.getCustomer() ? (
              <Space direction="vertical">
                <Space direction="horizontal" style={{ whiteSpace: "nowrap" }}>
                  <Typography.Text strong>Name:</Typography.Text>
                  <Typography.Text>
                    {record.getCustomer()?.name}
                  </Typography.Text>
                </Space>
                <Space direction="horizontal" style={{ whiteSpace: "nowrap" }}>
                  <Typography.Text strong>Email:</Typography.Text>
                  <Typography.Text>
                    {record.getCustomer()?.email}
                  </Typography.Text>
                </Space>
              </Space>
            ) : (
              ""
            )}
            <br />
            Guest id: <Typography>{record.guest_id}</Typography>
          </>
        );
      },
    },
    {
      title: "Customer",
      dataIndex: "customer",
      hidden: isMobile,
      key: "customer",
      render: (_: string, record: WishlistModel) => {
        return (
          <>
            {record.getCustomer() ? (
              <Space direction="vertical">
                <Space direction="horizontal" style={{ whiteSpace: "nowrap" }}>
                  <Typography.Text strong>Name:</Typography.Text>
                  <Typography.Text>
                    {record.getCustomer()?.name}
                  </Typography.Text>
                </Space>
                <Space direction="horizontal" style={{ whiteSpace: "nowrap" }}>
                  <Typography.Text strong>Email:</Typography.Text>
                  <Typography.Text>
                    {record.getCustomer()?.email}
                  </Typography.Text>
                </Space>
              </Space>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      title: "Guest",
      dataIndex: "guest_id",
      hidden: isMobile,
      key: "guest_id",
      render: (title: string) => {
        return <Typography>{title}</Typography>;
      },
    },
  ];

  function removeRegionFilter(filters: IFilterType) {
    // Clone the filters object to avoid modifying the original
    const updatedFilters = JSON.parse(JSON.stringify(filters));

    // Check if "region_id" exists in the filters and remove it
    if (updatedFilters.hasOwnProperty("region_id")) {
      delete updatedFilters.region_id;
    }

    return updatedFilters;
  }

  return (
    <>
      <div>
        <PageHeader
          ghost={false}
          title="Wishlists"
          style={{ marginTop: "10px" }}
        >
          <Tabs
            type="card"
            size="large"
            activeKey={selectedRegion}
            tabPosition="top"
            animated
            centered
            onChange={onChangeRegion}
            items={RegionData?.data.map((region) => {
              return {
                label: region.name,
                key: region.id.toString(),
                children: (
                  <>
                    {filters &&
                      filterData &&
                      Object.keys(filters).length > 0 && (
                        <Row>
                          <Col span={24}>
                            <Card title="Filter">
                              <FiltersComponent
                                handleProductFilter={handleProductFilter}
                                handleFilterChange={handleFilterChange}
                                handelFilterClear={handelFilterClear}
                                isFetched={isFetched}
                                filters={filters}
                                filtersData={removeRegionFilter(filterData)}
                                isFromProductReceived={true}
                              />
                            </Card>
                          </Col>
                        </Row>
                      )}
                    <Table
                      loading={isLoading}
                      rowKey="id"
                      bordered={true}
                      dataSource={WishlistData?.data}
                      expandable={{
                        expandedRowRender,
                        defaultExpandedRowKeys: ["0"],
                      }}
                      columns={wishlistColumns}
                      pagination={paginationConfig}
                    />
                  </>
                ),
              };
            })}
          />
        </PageHeader>
      </div>

      <Modal
        open={Boolean(selectedWishlistItems)}
        onCancel={() => setSelectedWishlistItems(undefined)}
        width="60vw"
        footer={null}
        title="Wishlist Items"
      >
        {selectedWishlistItems && (
          <WishlistItemTable data={selectedWishlistItems} />
        )}
      </Modal>
    </>
  );
};

export default Wishlists;
