export enum CurrencyEnum {
  CurrencyCollection = "CurrencyCollection",
  Currency = "Currency",
}
export enum CurrencyDataStatus {
  active = "active",
  inactive = "inactive",
}

export enum CurrencyCode {
  BDT = "BDT",
  USD = "USD",
  CNY = "CNY",
  EUR = "EUR",
  INR = "INR",
}
