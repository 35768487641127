import {
  RegionCode,
  RegionDataStatus,
  RegionStoreType,
} from "enums/regionCollectionEnums";
import { ICountry } from "types/country";
import {
  IAgentInvoice,
  IAgentInvoiceCollection,
  IAgentInvoiceDetails,
  IBuyProduct,
  ICurrencyData,
  ICustomer,
  IInvoice,
  IInvoiceAgentCompany,
  IInvoiceCollection,
  IInvoiceItem,
  IItemBuyProductVariation,
  InvoiceProductType,
  InvoiceTransaction,
  InvoiceTransactionDetails,
  IOrder,
  IRegion,
  IShipmentProduct,
  IShipmentProductImage,
  IShipmentProductImageCollection,
  IShipmentProductShippingAddress,
  OrderType,
} from "types/InvoiceCollection";
import { ILanguage } from "types/language";
import { IPagination } from "types/pagination";
import { IFilterType } from "types/filters";
import {
  CurrencyDataStatus,
  CurrencyEnum,
} from "enums/currencyCollectionEnums";
import { formatDateTime } from "../utils/helpers";

export class InvoiceItemModel {
  private item: IInvoiceItem;
  constructor(item: IInvoiceItem) {
    this.item = item;
  }

  getTotalAmount(): number {
    return this.item.total_amount;
  }

  getInitialAmount(): number {
    return this.item.initial_amount;
  }

  getRefundableAmount(): number {
    return this.item.refundable_amount;
  }

  getStatus() {
    return this.item.status;
  }
  getCreatedAt() {
    return formatDateTime(this.item.created_at);
  }
  getProductVariation(): IItemBuyProductVariation | undefined {
    return this.item.buy_product_variation;
  }
  getProductImageUrl(): string | undefined {
    return this.item.buy_product_variation?.image;
  }
}

export class CustomerModel {
  public readonly object: string;
  public readonly id: number;
  public readonly name: string;
  public readonly email: string;
  public readonly shipping_mark: string;
  public readonly created_at: string;
  constructor(data: ICustomer) {
    this.object = data.object;
    this.id = data.id;
    this.name = data.name;
    this.email = data.email;
    this.shipping_mark = data.shipping_mark;
    this.created_at = data.created_at;
  }

  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }
  getEmail() {
    return this.email;
  }

  getShippingMark() {
    return this.shipping_mark;
  }
  getCreateData() {
    return formatDateTime(this.created_at);
  }
}

export class ShipmentProductImageModel {
  public readonly object: string;
  public readonly id: number;
  public readonly title: string;
  public readonly alt: string;
  public readonly src: string;
  public readonly display_order: number;

  constructor(data: IShipmentProductImage) {
    this.object = data.object;
    this.id = data.id;
    this.title = data.title;
    this.alt = data.alt;
    this.src = data.src;
    this.display_order = data.display_order;
  }

  getById() {
    return this.id;
  }

  getTitle() {
    return this.title;
  }

  getAlt() {
    return this.alt;
  }

  getSrc() {
    return this.src;
  }

  getDisplayOrder() {
    return this.display_order;
  }
}

export class ShipmentProductImageCollectionModel {
  public readonly object: string;
  public readonly data: ShipmentProductImageModel[];

  constructor(data: IShipmentProductImageCollection) {
    this.object = data?.object;
    this.data = data?.data?.map((item) => new ShipmentProductImageModel(item));
  }

  getShipmentProductImageCollectionData() {
    return this.data;
  }
}

export class ShipmentProductModel {
  public readonly object: string;
  public readonly id: number;
  public readonly product_number: string;
  // public readonly title: string;
  // public readonly images: IShipmentProductImageCollection;
  public readonly shipping_address: IShipmentProductShippingAddress;
  public readonly shipment_instruction: string | null;
  public readonly shipping_mode: string;
  public readonly shipping_type: string;
  public readonly status: string;

  constructor(data: IShipmentProduct) {
    this.object = data.object;
    this.id = data.id;
    this.product_number = data.product_number;
    // this.title = data.title;
    // this.images = data.images;
    this.shipping_address = data.shipping_address;
    this.shipment_instruction = data.shipment_instruction;
    this.shipping_mode = data.shipping_mode;
    this.shipping_type = data.shipping_type;
    this.status = data.status;
  }

  getById() {
    return this.id;
  }

  getProductNumber() {
    return this.product_number;
  }

  // getTitle() {
  //   return this.title;
  // }
  //
  // getImages() {
  //   return this.images;
  // }

  getShippingAddress() {
    return this.shipping_address;
  }

  getShippingInstrucation() {
    return this.shipment_instruction;
  }

  getShippingMode() {
    return this.shipping_mode;
  }

  getShippingType() {
    return this.shipping_type;
  }

  getStatus() {
    return this.status;
  }
  getFullName() {
    return `${this.shipping_address.first_name}  ${this.shipping_address.last_name}`;
  }
}

export class BuyProductModel {
  public readonly object: string;
  public readonly id: number;
  public readonly product_number: string;
  public readonly product_id: string;
  public readonly product_vid: string;
  public readonly cart_item_id: null | string;
  public readonly product_title: string;
  public readonly product_image: string;
  public readonly product_link: string;
  public readonly shipping_provider: string;
  public readonly total_quantity: number;
  public readonly status: string;
  public readonly source_type: string;
  public readonly source_id: number;
  public readonly created_at: string;

  constructor(data: IBuyProduct) {
    this.object = data.object;
    this.id = data.id;
    this.product_number = data.product_number;
    this.product_id = data.product_id;
    this.product_vid = data.product_vid;
    this.cart_item_id = data.cart_item_id;
    this.product_title = data.product_title;
    this.product_image = data.product_image;
    this.product_link = data.product_link;
    this.shipping_provider = data.shipping_provider;
    this.total_quantity = data.total_quantity;
    this.status = data.status;
    this.source_type = data.source_type;
    this.source_id = data.source_id;
    this.created_at = data.created_at;
  }

  getProductID() {
    return this.product_id;
  }

  getProductVID() {
    return this.product_vid;
  }

  getCartItemID() {
    return this.cart_item_id;
  }

  getProductTitle() {
    return this.product_title;
  }

  getProductImage() {
    return this.product_image;
  }

  getProductLink() {
    return this.product_link;
  }

  getShippingProvider() {
    return this.shipping_provider;
  }

  getTotalQuantity() {
    return this.total_quantity;
  }

  getStatus() {
    return this.status;
  }

  getSourceType() {
    return this.source_type;
  }

  getSourceID() {
    return this.source_id;
  }

  getCreatedAt() {
    return formatDateTime(this.created_at);
  }
}

export class CurrencyDataModel {
  public readonly object: CurrencyEnum.Currency;
  public readonly id: number;
  public readonly name: string;
  public readonly code: string;
  public readonly symbol: string;
  public readonly symbol_native: string;
  public readonly status: CurrencyDataStatus;
  public readonly created_at: string;
  constructor(dataItem: ICurrencyData) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.name = dataItem.name;
    this.code = dataItem.code;
    this.symbol = dataItem.symbol;
    this.symbol_native = dataItem.symbol_native;
    this.status = dataItem.status;
    this.created_at = dataItem.created_at;
  }

  getSymbolNative() {
    return this.symbol_native;
  }
}

export class RegionModel {
  public readonly object: string;
  public readonly id: number;
  public readonly name: string;
  public readonly code: RegionCode;
  public readonly store_type: RegionStoreType;
  public readonly store: string;
  public readonly status: RegionDataStatus;
  public readonly created_at: string;
  public readonly currency: CurrencyDataModel;
  public readonly countries?: ICountry;
  public readonly languages?: ILanguage;

  constructor(data: IRegion) {
    this.object = data.object;
    this.id = data.id;
    this.store = data.store;
    this.store_type = data.store_type;
    this.code = data.code;
    this.name = data.name;
    this.status = data.status;
    this.created_at = data.created_at;
    this.currency = data.currency && new CurrencyDataModel(data.currency);
    this.countries = data.countries;
    this.languages = data.languages;
  }

  getId() {
    return this.id;
  }

  getStore() {
    return this.store;
  }

  getStoreType() {
    return this.store_type;
  }

  getCode() {
    return this.code;
  }

  getName() {
    return this.name;
  }

  getStatus() {
    return this.status;
  }
  getCreatedAt() {
    return formatDateTime(this.created_at);
  }

  getCurrency() {
    return this.currency;
  }
}

export class OrderModel {
  public readonly object: string;
  public readonly id: number;
  public readonly order_number: string;
  public readonly order_tag: string | null;
  public readonly order_type: OrderType;
  public readonly order_status: string;
  public readonly payment_status: string;
  // public readonly user_id: number;
  // public readonly order_handler: number;
  public readonly created_at: string;

  constructor(data: IOrder) {
    this.object = data.object;
    this.id = data.id;
    this.order_number = data.order_number;
    // this.user_id = data.user_id;
    // this.order_handler = data.order_handler;
    this.order_tag = data.order_tag;
    this.order_type = data.order_type;
    this.order_status = data.order_status;
    this.payment_status = data.payment_status;
    this.created_at = data.created_at;
  }

  getId() {
    return this.id;
  }

  getOrderNumber() {
    return this.order_number;
  }
  getOrderTag() {
    return this.order_tag;
  }

  getType() {
    return this.order_type;
  }

  getStatus() {
    return this.order_status;
  }

  getCreatedAt() {
    return formatDateTime(this.created_at);
  }
}

export class InvoiceModel {
  public readonly object: string;
  public readonly id: number;
  public readonly invoice_number: string;
  public readonly status: InvoiceStatus;
  public readonly total_amount: number;
  public readonly initial_amount: number;
  public readonly refundable_amount: number;
  public readonly transaction_summary: {
    paid: number;
    due: number;
    overpaid: number;
  };
  // public readonly type: string | null;
  // public readonly product_number: string | null;
  public readonly buy_product: BuyProductModel | undefined;
  public readonly customer?: CustomerModel;
  public readonly region: RegionModel;
  public readonly order: OrderModel;
  public readonly shipment_product: ShipmentProductModel | undefined;
  public readonly items: InvoiceItemModel[] | undefined;
  public readonly invoiceProductType: InvoiceProductType;
  public readonly transactions: TransactionsModel[] | undefined;
  private readonly created_at: string;

  constructor(data: IInvoice) {
    this.object = data.object;
    this.id = data.id;
    this.invoice_number = data.invoice_number;
    this.status = data.status;
    this.total_amount = data.total_amount;
    this.initial_amount = data.initial_amount;
    this.refundable_amount = data.refundable_amount;
    this.transaction_summary = data.transaction_summary;
    this.buy_product = data.buy_product
      ? new BuyProductModel(data.buy_product)
      : undefined;
    this.customer = data.customer && new CustomerModel(data.customer);
    this.region = new RegionModel(data.region);
    this.order = new OrderModel(data.order);
    this.created_at = data.created_at;
    this.shipment_product = data.shipment_product
      ? new ShipmentProductModel(data.shipment_product)
      : undefined;
    if (data.items && Array.isArray(data.items.data)) {
      this.items = data.items.data.map((item) => new InvoiceItemModel(item));
    } else {
      this.items = [];
    }
    this.invoiceProductType = data.buy_product
      ? "BuyProduct"
      : "ShipmentProduct";
    if (data.transactions && Array.isArray(data.transactions.data)) {
      this.transactions = data.transactions.data.map(
        (item) => new TransactionsModel(item),
      );
    } else {
      this.items = [];
    }
  }

  getId() {
    return this.id;
  }

  getInvoiceNumber() {
    return this.invoice_number;
  }
  getItems() {
    return this.items;
  }

  getCustomer() {
    return this.customer;
  }

  getRegion() {
    return this.region;
  }

  getOrder() {
    return this.order;
  }
  getStatus() {
    return this.status;
  }

  getTotalAmount() {
    return this.total_amount;
  }

  getInitialAmount() {
    return this.initial_amount;
  }

  getRefundableAmount() {
    return this.refundable_amount;
  }

  getTransactionSummery() {
    return this.transaction_summary;
  }

  getBuyProduct() {
    return this.buy_product;
  }
  getShipmentProduct() {
    return this.shipment_product;
  }
  getInvoiceProductType() {
    return this.invoiceProductType;
  }
  getTransactions() {
    return this.transactions;
  }
  isBuyProductVariationPresentInItems() {
    return this.items?.some((item) => item.getProductVariation() !== undefined);
  }

  getCreatedAt() {
    return formatDateTime(this.created_at);
  }
}

export class AgentInvoiceDetailsModel extends InvoiceModel {
  public readonly agent_company: InvoiceAgentCompanyModel;
  constructor(data: IAgentInvoiceDetails) {
    super(data);
    this.agent_company = new InvoiceAgentCompanyModel(data.agent_company);
  }
  getAgentCompany() {
    return this.agent_company;
  }
}
export class InvoiceAgentCompanyModel {
  private data: IInvoiceAgentCompany;

  constructor(data: IInvoiceAgentCompany) {
    this.data = data;
  }

  // Method to get company primary name
  getPrimaryName(): string {
    return this.data.primary_name;
  }

  // Method to get company secondary name
  getSecondaryName(): string {
    return this.data.secondary_name;
  }

  // Method to get company slug
  getSlug(): string {
    return this.data.slug;
  }

  // Method to get company operation status
  getOperationStatus(): string {
    return this.data.operation_status;
  }

  // Method to get company verification status
  getVerificationStatus(): string {
    return this.data.verification_status;
  }

  // Method to get company type
  getCompanyType(): string {
    return this.data.company_type;
  }

  // Method to get director name
  getDirectorName(): string {
    return this.data.director_name;
  }

  // Method to get designation
  getDesignation(): string {
    return this.data.designation;
  }

  // Method to get business phone
  getBusinessPhone(): string {
    return this.data.business_phone;
  }

  // Method to get years of experience
  getYearsOfExperience(): string {
    return this.data.years_of_experience;
  }

  // Method to get number of people in team
  getNumberOfPeopleInTeam(): string {
    return this.data.number_of_people_in_team;
  }

  // Method to get address
  getAddress() {
    return this.data.address;
  }

  // Method to get registered country detail
  getRegisteredCountryDetail() {
    return {
      ...this.data.registered_country_detail,
      created_at: formatDateTime(
        this.data.registered_country_detail.created_at,
      ),
    };
  }
}

export class InvoiceCollectionCommonModel {
  public readonly object: string;
  public readonly pagination: IPagination;
  public readonly filters: IFilterType;

  constructor(data: {
    object: string;
    pagination: IPagination;
    filters: IFilterType;
  }) {
    this.object = data.object;
    this.pagination = data.pagination;
    this.filters = data.filters;
  }
  getPagination() {
    return this.pagination;
  }
  getFilter() {
    return this.filters;
  }
}
export class InvoiceCollectionModel extends InvoiceCollectionCommonModel {
  public readonly data: InvoiceModel[];
  constructor(data: IInvoiceCollection) {
    super(data);
    this.data = data.data.map((item: IInvoice) => new InvoiceModel(item));
  }
  getData() {
    return this.data;
  }
}
export enum InvoiceStatus {
  UNPAID = "unpaid",
  PARTIAL_PAID = "partial-paid",
  PAID = "paid",
  SETTLED = "settled",
}

export class TransactionsModel {
  private data: InvoiceTransaction;

  constructor(data: InvoiceTransaction) {
    this.data = data;
  }

  getId() {
    return this.data.id;
  }
  getTransactionNumber() {
    return this.data.transaction_number;
  }
  getAmount() {
    return this.data.amount;
  }
  getDescription() {
    return this.data.description;
  }
  getType() {
    return this.data.type;
  }
  getCreatedAt() {
    return formatDateTime(this.data.created_at);
  }
  private getAssociations() {
    return this.data.associations.data.data[0];
  }
  getAssociationsAmount() {
    return this.getAssociations().amount;
  }
  getAssociationsWallet() {
    return this.getAssociations().wallet;
  }
}

export class TransactionDetailsModel extends TransactionsModel {
  private transaction: InvoiceTransactionDetails;
  constructor(transaction: InvoiceTransactionDetails) {
    super(transaction);
    this.transaction = transaction;
  }

  getInvoiceNumber() {
    return this.transaction.invoice.invoice_number;
  }
  getInvoiceStatus() {
    return this.transaction.invoice.status;
  }
  getTransactionSummary() {
    return this.transaction.invoice.transaction_summary;
  }
  getTotalInvoiceAmount() {
    return this.transaction.invoice.total_amount;
  }
}

export class AgentInvoiceModel {
  private data: IAgentInvoice;
  constructor(data: IAgentInvoice) {
    this.data = data;
  }

  getStatus() {
    return this.data.status;
  }
  getId() {
    return this.data.id;
  }
  getInvoiceNumber() {
    return this.data.invoice_number;
  }
  getTotalAmount() {
    return this.data.total_amount;
  }
  getInitialAmount() {
    return this.data.initial_amount;
  }
  getRefundableAmount() {
    return this.data.refundable_amount;
  }

  getTransaction() {
    return this.data.transaction_summary;
  }
  getCreatedAt() {
    return formatDateTime(this.data.created_at);
  }
}

export class AgentInvoiceCollectionModel extends InvoiceCollectionCommonModel {
  private readonly invoices: AgentInvoiceModel[];
  constructor(data: IAgentInvoiceCollection) {
    super(data);
    this.invoices = data.data.map((item) => new AgentInvoiceModel(item));
  }
  getData() {
    return this.invoices;
  }
}
