import { SetStateAction } from "react";

import { TrackingEventModel } from "models/timelineTrackingModel";

interface IProps {
  data: TrackingEventModel;
  deleteComment: (id: number, productId:string) => Promise<any>;
  getTimelineTracking: (
    product_number: string,
    params?: string | undefined,
  ) => Promise<void>;
  message: any;
  setIsErrorVisible: (value: SetStateAction<boolean>) => void;
  productId: string
}

export const handleDeleteComment = async ({
  data,
  deleteComment,
  message,
  setIsErrorVisible,
  productId
}: IProps) => {
  try {
    await deleteComment(data.getId(), productId);
    message.success("Comment deleted successfully");
  } catch (err) {
    setIsErrorVisible(true);
  }
};
