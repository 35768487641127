import { useLocation, useNavigate } from "react-router-dom";
import { Checkbox, Rate } from "antd";

import {
  StyledProductListAction,
  StyledProductListActionItem,
  StyledProductListCard,
  StyledProductListCardContent,
  StyledProductListContentPara,
  StyledProductListPrice,
  StyledProductListPriceItem,
  StyledProductListPriceItemText,
  StyledProductListPriceItemValue,
  StyledProductListPriceItemValueCut,
  StyledProductListSlider,
  StyledProductListSliderContent,
  StyledProductListSliderContentHeader,
  StyledProductListSliderThumb,
} from "./index.styled";
import { ProductDetailModel } from "models/productDetailCollectionModel";
import { calculateDiscountPercentage } from "./helpers/calculateDiscountPercentage";
import { BuyOrderOperationEnum } from "enums/buyOrderCollectionEnums";
import { useEffect } from "react";

type Props = {
  item: ProductDetailModel;
  isChecked: boolean;
  handleCheck: (id: string) => void;
  selectable: boolean;
};

const ListItem = (props: Props) => {
  const location = useLocation();
  const operation = new URLSearchParams(location.search).get("operation");
  const buyOrderId = new URLSearchParams(location.search).get("buyOrderId");

  useEffect(() => {
    const operation = new URLSearchParams(location.search).get("operation");
    console.log(operation, "operations");
  }, []);

  const { item, isChecked, handleCheck, selectable } = props;
  const navigate = useNavigate();

  return (
    <StyledProductListCard
      extra={
        selectable ? (
          <Checkbox
            checked={isChecked}
            onChange={() => handleCheck(item.getId())}
          />
        ) : null
      }
      className=""
    >
      <StyledProductListCardContent>
        <StyledProductListSlider onClick={() => handleCheck(item.getId())}>
          <StyledProductListSliderThumb key={item.getId()}>
            <img src={item.getImage()} alt="watch" />
          </StyledProductListSliderThumb>
        </StyledProductListSlider>

        <StyledProductListSliderContent>
          <StyledProductListSliderContentHeader>
            <h3
              onClick={() => {
                console.log("Click");
                if (
                  operation === BuyOrderOperationEnum.AddBuyOrderProduct &&
                  buyOrderId
                ) {
                  operation === BuyOrderOperationEnum.AddBuyOrderProduct &&
                  buyOrderId
                    ? navigate(
                        "product_detail/" +
                          item.getId() +
                          `?buyOrderId=${buyOrderId}&operation=${operation}`,
                      )
                    : navigate("product_detail/" + item.getId());
                }
                if (operation === BuyOrderOperationEnum.PlaceBuyOrder) {
                  operation === BuyOrderOperationEnum.PlaceBuyOrder
                    ? navigate(
                        "product_detail/" +
                          item.getId() +
                          `&operation=${operation}`,
                      )
                    : navigate("product_detail/" + item.getId());
                } else {
                  navigate(`product_detail/${item.getId()}`);
                }
              }}
              className="text-truncate item-hover"
            >
              {item.getTitle()}
            </h3>
          </StyledProductListSliderContentHeader>

          <StyledProductListContentPara>
            {/* {item.getDescription()} */}
          </StyledProductListContentPara>

          <StyledProductListPrice>
            <StyledProductListPriceItem>
              <StyledProductListPriceItemText>
                Price:
              </StyledProductListPriceItemText>
              <StyledProductListPriceItemValue>
                ৳
                {item.getPrice().getDiscount().getMin()
                  ? item.getPrice().getDiscount().getMin()
                  : item.getPrice().getOriginal().getMin()}
              </StyledProductListPriceItemValue>
            </StyledProductListPriceItem>
            <StyledProductListPriceItem>
              Mrp:
              <StyledProductListPriceItemValueCut>
                ৳{item.getPrice().getOriginal().getMin()}
              </StyledProductListPriceItemValueCut>
            </StyledProductListPriceItem>
            {calculateDiscountPercentage(
              item.getPrice().getOriginal().getMin(),
              item.getPrice().getDiscount().getMin(),
            ) > 0 ? (
              <StyledProductListPriceItem className="green">
                {calculateDiscountPercentage(
                  item.getPrice().getOriginal().getMin(),
                  item.getPrice().getDiscount().getMin(),
                )}
                % off
              </StyledProductListPriceItem>
            ) : (
              ""
            )}
          </StyledProductListPrice>

          <StyledProductListAction>
            <StyledProductListActionItem>
              <Rate value={item.getAverageRatings()} />
            </StyledProductListActionItem>
          </StyledProductListAction>
        </StyledProductListSliderContent>
      </StyledProductListCardContent>
    </StyledProductListCard>
  );
};

export default ListItem;
