import { AddonServicsResource } from "lib/core/resources/private";
import CommerceApiService from "lib/core/apiServices/commerceApiService";

class AddonService extends CommerceApiService {
  public addonServicsResource: AddonServicsResource;

  constructor() {
    super();
    this.addonServicsResource = new AddonServicsResource(this.config);
  }
}
export default AddonService;
