/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useAtom } from "jotai";
import {
  Button,
  Dropdown,
  MenuProps,
  Popconfirm,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  HddFilled,
  ReloadOutlined,
} from "@ant-design/icons";
import moment from "moment";

import { destinationWarehouseInvitationCollectionAtom } from "lib/core-react/store/store";
import {
  useDestinationWarehouseInvitationDelete,
  useDestinationWarehouseInvitationResend,
  useDestinationWarehouseInvitationsGet,
} from "lib/core-react/hooks/private/useDestinationWarehouse";
import { getError } from "lib/core-react/hooks/utils/errors";
import useDataFilters from "hooks/useDataFilters";

import {
  DestinationWarehouseMembershipInvitationCollectionModel,
  DestinationWarehouseMembershipInvitationModel,
} from "models/destinationWarehouseCollectionModel";
import { ApiHelperModel } from "models/apiHelper";
import { PaginationModel } from "models/pagination";
import useWindowWidth from "../../../../../lib/core-react/hooks/public/useWindowWidth";

interface IProps {
  destinationWarehouseId: number;
}

export const DestinationWarehouseInvitationsTable = ({
  destinationWarehouseId,
}: IProps) => {
  const { deleteInvitation, isLoading: isLoadingDelete } =
    useDestinationWarehouseInvitationDelete();
  const { resendInvitation } = useDestinationWarehouseInvitationResend();
  const { getDestinationWarehouseInvitations } =
    useDestinationWarehouseInvitationsGet(destinationWarehouseId);
  const [{ data: invitationCollectionData, isLoading, refetch }] = useAtom(
    destinationWarehouseInvitationCollectionAtom,
  );
  const InvitationCollectionData =
    invitationCollectionData &&
    new DestinationWarehouseMembershipInvitationCollectionModel(
      invitationCollectionData,
    );
  const {isMobile} = useWindowWidth()

  const { filters, handleFilterChange, isFirstCall, isFetched } =
    useDataFilters();

  // Api Call
  useEffect(() => {
    if ((filters && !isFetched && isFirstCall) || refetch) {
      ApiHelperModel.makeGetRequest({}, getDestinationWarehouseInvitations);
    }
  }, [destinationWarehouseId, isFirstCall, filters, isFetched, refetch]);

  // Pagination
  const paginationData = InvitationCollectionData?.getPagination();

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getDestinationWarehouseInvitations,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    InvitationCollectionData,
    handlePaginationChange,
  );

  const handleResendInvitation = async (id: number) => {
    try {
      await resendInvitation(destinationWarehouseId, id);
      await getDestinationWarehouseInvitations();
      message.success("Invitation resent.");
    } catch (error) {
      message.error(getError(error));
    }
  };

  const handleDeleteInvitation = async (id: number) => {
    try {
      await deleteInvitation(destinationWarehouseId, id);
      await getDestinationWarehouseInvitations();
      message.success("Invitation deleted.");
    } catch (error) {
      message.error(getError(error));
    }
  };

  const { Text } = Typography;

  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "id",
      width:70,
      align: "center" as const,
      render: (
        _: string,
        __: DestinationWarehouseMembershipInvitationModel,
        index: number,
      ) => {
        return (
          <Text>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </Text>
        );
      },
    },
    {
      hidden:isMobile,
      title: "User Email",
      dataIndex: "user_email",
      key: "user_email",
      render: (
        _: string,
        record: DestinationWarehouseMembershipInvitationModel,
      ) => {
        return <Text strong>{record.getUserEmail()}</Text>;
      },
    },
    {
      hidden:isMobile,
      title: "Roles",
      dataIndex: "roles",
      key: "roles",
      render: (
        _: string,
        record: DestinationWarehouseMembershipInvitationModel,
      ) => {
        return record.getRoles() ? (
          <div
            style={{
              textTransform: "capitalize",
              display: "flex",
              flexWrap: "wrap",
              gap: 10,
              width: 200,
            }}
          >
            {record
              .getRoles()
              .getData()
              .map((role) => (
                <Tag key={role.id} color="green">
                  {role.name}
                </Tag>
              ))}
          </div>
        ) : (
          ""
        );
      },
    },
    {
      hidden:isMobile,
      title: "Status",
      dataIndex: "status",
      align: "center" as const,
      key: "status",
      render: (
        _: string,
        record: DestinationWarehouseMembershipInvitationModel,
      ) => {
        return (
          <Tag
            icon={
              record.getStatus() === "accepted" ? (
                <CheckCircleOutlined />
              ) : (
                <ExclamationCircleOutlined />
              )
            }
            color={record.getStatus() === "accepted" ? "success" : "warning"}
          >
            {record.getStatus()}
          </Tag>
        );
      },
    },
    {
      hidden:isMobile,
      title: "Expires at",
      dataIndex: "expired_at",
      key: "expired_at",
      render: (
        _: string,
        record: DestinationWarehouseMembershipInvitationModel,
      ) => {
        const expirationDate = moment(
          record.expires_at,
          "YYYY-MM-DD HH:mm:ss",
        );
        const currentDate = moment();

        const minutesRemaining = expirationDate.diff(currentDate, "minutes");

        return minutesRemaining > 0 ? (
            <Tag color="purple" icon={<ExclamationCircleOutlined />}>
              {record.getExpiresAt()}
            </Tag>) : (
          <Tag color="warning" icon={<ExclamationCircleOutlined />}>
            Expired
          </Tag>
        );
      },
    },
    {
      title: "Details",
      hidden:!isMobile,
      key: 'details',
      render: (_, record) => {
        const expirationDate = moment(record.expires_at, "YYYY-MM-DD HH:mm:ss");
        const currentDate = moment();
        const minutesRemaining = expirationDate.diff(currentDate, "minutes");

        return (
          <div>
            <div style={{margin:'10px 0px '}}>
              <Text strong>User Email:</Text> <Text>{record.getUserEmail()}</Text>
            </div>
            <div>
              <Text strong>Roles:</Text>
              {record.getRoles() ? (
                <div
                  style={{
                    textTransform: 'capitalize',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 5,
                    minWidth: 100,
                  }}
                >
                  {record.getRoles().getData().map((role) => (
                    <Tag key={role.id} color="green">
                      {role.name}
                    </Tag>
                  ))}
                </div>
              ) : (
                ''
              )}
            </div>
            <div style={{margin:'10px 0px '}}>
              <Text strong>Status:</Text>
              <Tag
                icon={
                  record.getStatus() === 'accepted' ? (
                    <CheckCircleOutlined />
                  ) : (
                    <ExclamationCircleOutlined />
                  )
                }
                color={record.getStatus() === 'accepted' ? 'success' : 'warning'}
              >
                {record.getStatus()}
              </Tag>
            </div>
            <div>
              <Text strong>Expires at:</Text>
              {minutesRemaining > 0 ? (
                <Tag color={"purple"} >
                  {record.getExpiresAt()}
                </Tag>
              ) : (
                <Tag color="warning" icon={<ExclamationCircleOutlined />}>
                  Expired
                </Tag>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      width:isMobile? 100: 150,
      fixed:isMobile?"right":undefined,
      render: (
        _: string,
        record: DestinationWarehouseMembershipInvitationModel,
      ) => {
        const items: MenuProps["items"] = [
          {
            key: "1",
            label: (
              <div onClick={() => handleResendInvitation(record.getId())}>
                <ReloadOutlined /> <span> Resend invitation</span>
              </div>
            ),
          },
          {
            key: "2",
            label: (
              <Popconfirm
                title="Delete this invitation"
                description="Are you sure to delete this invitation?"
                onConfirm={() => handleDeleteInvitation(record.getId())}
                okButtonProps={{ loading: isLoadingDelete }}
                okText="Yes"
                okType="danger"
                cancelText="No"
              >
                <span onClick={(e) => e.stopPropagation()}>
                  <DeleteOutlined />
                  <span>Delete</span>
                </span>
              </Popconfirm>
            ),
            danger: true,
          },
        ];

        return (
          <Dropdown menu={{ items }}>
            <Button icon={<HddFilled />}>
              {isMobile ? <DownOutlined /> : <> Actions <DownOutlined /> </>  }
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <Table
        //@ts-ignore
        columns={columns}
        size="small"
        bordered
        loading={isLoading}
        dataSource={InvitationCollectionData?.getData()}
        pagination={paginationConfig}
        rowKey="id"
      />
    </>
  );
};
