import { useEffect } from "react";
import { Form, Input, Select } from "antd";

import { transformEnumToLabeledValue } from "utils/helpers";
import { StoreModel } from "models/storeCollectionModel";
import {
  StoreAvilabilityStatusEnums,
  StoreIdentifierEnums,
} from "enums/storeCollectionEnums";

interface IProps {
  data?: StoreModel;
  form: any;
  onFinish: (values: any) => Promise<void>;
}

export const StoreModification = ({ data, form, onFinish }: IProps) => {
  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  return (
    <Form
      onFinish={onFinish}
      form={form}
      layout="vertical"
      name="Add Store"
      initialValues={{
        name: data?.getName(),
        availability_status: data?.getAvailabilityStatus(),
      }}
    >
      <Form.Item
        rules={[
          {
            required: true,
            message: "Store name is required",
          },
        ]}
        label="Name"
        name="name"
      >
        <Input placeholder="Store name" />
      </Form.Item>

      {!data && (
        <Form.Item
          rules={[{ required: true, message: "Identifier is required" }]}
          name="identifier"
          label="Identifier"
        >
          <Select
            placeholder="Please select a identifier"
            options={transformEnumToLabeledValue(StoreIdentifierEnums)}
          />
        </Form.Item>
      )}

      <Form.Item
        rules={[{ required: true, message: "Availability Status is required" }]}
        name="availability_status"
        label="Availability Status"
      >
        <Select
          placeholder="Please select a availability status"
          options={transformEnumToLabeledValue(StoreAvilabilityStatusEnums)}
        />
      </Form.Item>
    </Form>
  );
};
