import { useState } from "react";
import { useService } from "../../contexts";
import { useAtom } from "jotai";
import { getError } from "../utils/errors";
import { storeExchangeAtom } from "lib/core-react/store/store";
import { ExchangeListPostRequestModel } from "models/exchangeListCollectionModel";
import {notification} from "antd";
import {IExchangeStoreUpdatePayload, IStoreRateUpdatePayload} from "../../../../types/exchangeListCollection";

export const useGetExchange = () => {
  const [exchange, setExchange] = useAtom(storeExchangeAtom);
  const { storeService } = useService();
  const getExchange = async (params?: string): Promise<void> => {
    setExchange({ ...exchange, isLoading: true, error: null });
    try {
      const response = await storeService.exchangeResource.get(params);
      setExchange({
        ...exchange,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setExchange({
        ...exchange,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getExchange } as const;
};


export const useUpdateExchange = () => {
  const { storeService } = useService();
  const {getExchange} = useGetExchange()
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);

  const updateExchange = async (id:number, payload: IExchangeStoreUpdatePayload) => {
    setIsLoading(true);
    try {
        await storeService.exchangeResource.update(id,payload);
        setIsLoading(false);
        notification['success']({
          message: "Update successfully",
        })
     await  getExchange()
    } catch (error: any) {
      notification['error']({
        message: getError(error),
      })
      setError(getError(error));
      setIsLoading(false);

    }
  };

  return { updateExchange, isLoading, isError };
};
export const useExchangeRegionalStoreRateUpdate = () => {
  const { storeService } = useService();
  const {getExchange} = useGetExchange()
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);

  const updateStoreRate = async (payload:IStoreRateUpdatePayload) => {
    setIsLoading(true);
    try {
      await storeService.exchangeResource.updateRate(payload);
      setIsLoading(false);
      notification['success']({
        message: "Update successfully",
      })
      await  getExchange()
    } catch (error: any) {
      notification['error']({
        message: getError(error),
      })
      setError(getError(error));
      setIsLoading(false);

    }
  };

  return { updateStoreRate, isLoading, isError };
};

export const useCreateExchange = () => {
  const { storeService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [exchangeData, setExchangeData] = useAtom(storeExchangeAtom);

  const createExchange = async (payload: ExchangeListPostRequestModel) => {
    setIsLoading(true);
    try {
      const response = await storeService.exchangeResource.create(payload);
      setIsLoading(false);
      setExchangeData({ ...exchangeData, refetch: true });
      return response.message;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { createExchange, isLoading, isError };
};

export const useCopyExchange = () => {
  const { storeService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [exchangeData, setExchangeData] = useAtom(storeExchangeAtom);

  const copyExchange = async (id: number, payload: {name:string}) => {
    setIsLoading(true);
    try {
      const response = await storeService.exchangeResource.copy(id, payload);
      setIsLoading(false);
      setExchangeData({ ...exchangeData, refetch: true });
      return response.message;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
    }
  };

  return { copyExchange, isLoading, isError };
};
