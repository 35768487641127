import { Alert, Button, Form, Input, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { useAtom } from "jotai";
import {
  useCreateCourierGateway,
  useGetCourierGateway,
} from "lib/core-react/hooks/private";
import { useGetCountry } from "lib/core-react/hooks/private/useCountry";
import React, { useEffect, useState, createRef } from "react";
import { regionCountry } from "lib/core-react/store/store";
import { CountryModel } from "models/country";
import { renderOptionsFromEnum } from "components/Form/forms";
import { Forms } from "models/form";
import { ICourierGatewayForm } from "types/courierGatewayCollection";
import { CourierGatewayStatus } from "enums/courierGatewayCollectionEnums";

interface IProps {
  setIsShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreateCourierGateway = ({ setIsShowCreateModal }: IProps) => {
  const { getCountry } = useGetCountry();
  const [{ data: countryDataAtom, isLoading: countryLoading }] =
    useAtom(regionCountry);
  const { createCourierGateway, isLoading, isError } =
    useCreateCourierGateway();
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const formRef: React.Ref<FormInstance<any>> = createRef();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const { getCourierGateway } = useGetCourierGateway();

  useEffect(() => {
    getCountry("per_page=200");
    getCourierGateway();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // @ts-ignore FIXME
  const countryData = countryDataAtom && new CountryModel(countryDataAtom);

  const onFinish = async (value: ICourierGatewayForm) => {
    const payload = {
      name: value.name,
      country_id: value.country_id,
      slug: value.slug,
      status: value.status,
    };

    try {
      await createCourierGateway(payload);
      setIsShowCreateModal(false);
      setIsErrorVisible(false);
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    Forms.formValueChange(form, changedValues, allValues);
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      layout="vertical"
      name="Add Courier Gateway"
      onFieldsChange={onValuesChange}
      ref={formRef}
    >
      <Form.Item
        rules={[
          {
            required: true,
            message: "Courier gateway name is required",
          },
        ]}
        label="Name"
        name="name"
      >
        <Input placeholder="Courier gateway name" />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Slug name is required" }]}
        label="Slug"
        name="slug"
      >
        <Input readOnly placeholder="Slug name" />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Country is required" }]}
        name="country_id"
        label="Country"
      >
        <Select loading={countryLoading} placeholder="Please select a Country">
          {countryData?.getCountryCode() &&
            renderOptionsFromEnum(countryData?.getCountryId())}
        </Select>
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Status is required" }]}
        name="status"
        label="Status"
      >
        <Select placeholder="Please select a Status">
          {renderOptionsFromEnum(CourierGatewayStatus)}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Submit
        </Button>
      </Form.Item>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isError}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </Form>
  );
};
