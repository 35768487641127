import { MOVEON_ADMIN_TOKEN_KEY } from 'consts/storage';
import client from './api-client'
import { jwtDecode } from "jwt-decode";


function getUser() {
	const token = getToken()
	if (!token) {
		return Promise.resolve(null)
	}
	return client.get('admin/profile/me').catch(error => {
		// logout()
		return Promise.reject(error)
	})
}

function login({ email, password, remember_me }) {
	return client.post('admin/login', { email, password, remember_me })
}

function loggedIn() {
	const token = getToken()
	return !!token && !isTokenExpired(token)
}

function isTokenExpired(token) {
	try {
		const decoded = jwtDecode(token);
		if (decoded.exp < Date.now() / 1000) {
			return true;
		} else return false;
	}
	catch (err) {
		return false;
	}
}


const logout = () => {
	return client.post('admin/auth/logout')
}

const logoutFromOtherDevices = () => {
	return client.post('admin/auth/logout-from-other-devices')
}

function getToken() {
	return window.localStorage.getItem(MOVEON_ADMIN_TOKEN_KEY)
}

export { login, loggedIn, isTokenExpired, logout, logoutFromOtherDevices, getToken, getUser }
