import { useAtom } from "jotai";
import { useService } from "lib/core-react/contexts";
import { getError } from "../utils/errors";
import { useState } from "react";
import { rfqRequestsList } from "lib/core-react/store/store";
import { IRfqRequestsStatus } from "lib/core/resources/private/rfqResource";
import { IHeaders } from "lib/core/request/types/request";

export const useGetRfq = () => {
  const [requests, setRequests] = useAtom(rfqRequestsList);
  const { rfqService } = useService();

  const getRequestsList = async (params?: string) => {
    try {
      setRequests({
        ...requests,
        isLoading: true,
        error: null,
        refetch: true,
      });
      const response = await rfqService.rfqResource.getRequestsList(params);
      setRequests({
        ...requests,
        data: response,
        isLoading: false,
        error: null,
        refetch: false,
      });
    } catch (error) {
      setRequests({
        ...requests,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });

      throw error;
    }
  };

  return { getRequestsList } as const;
};

export const useUpdateRfqApprove = () => {
  const { rfqService } = useService();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");

  const updateRequestsApprove = async ({
    requestId,
    payload,
    customHeaders = {},
    params,
  }: {
    params?: string;
    requestId: string;
    payload: IRfqRequestsStatus;
    customHeaders?: IHeaders;
  }): Promise<any> => {
    setIsLoading(true);
    try {
      const response = await rfqService.rfqResource.postRequestsApprove({
        requestId,
        payload,
        customHeaders,
        params,
      });
      setIsLoading(false);
      return response;
    } catch (error: any) {
      setIsLoading(false);
      console.error("error", error);
      setError(getError(error));
      throw error;
    }
  };
  return { updateRequestsApprove, isLoading, isError };
};

export const useUpdateRfqReject = () => {
  const { rfqService } = useService();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");

  const updateRequestsReject = async ({
    requestId,
    payload,
    customHeaders = {},
    params,
  }: {
    params?: string;
    requestId: string;
    payload: {
      reject_reason: string;
    };
    customHeaders: IHeaders;
  }): Promise<any> => {
    setIsLoading(true);
    try {
      const response = await rfqService.rfqResource.postRequestsReject({
        requestId,
        payload,
        customHeaders,
        params,
      });

      setIsLoading(false);
      return response;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };
  return { updateRequestsReject, isLoading, isError };
};
