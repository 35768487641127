import { BaseResource } from "../public";
import { ResponsePromise } from "lib/core/request/types/typings";
import { ExchangeListPostRequestModel } from "models/exchangeListCollectionModel";
import {IExchangeStoreUpdatePayload, IStoreRateUpdatePayload} from "../../../../types/exchangeListCollection";

class ExchangeResource extends BaseResource {
  path = `/api/store/admin/exchange/v1/exchanges`;

  get(
    params?: string,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.path}${params ? `?${params}` : ""}`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  create(
    payload: ExchangeListPostRequestModel,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.path}/`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }

  copy(id: number,payload:{name:string},  customHeaders: Record<string, any> = {}): ResponsePromise {
    const path = `${this.path}/${id}`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }
  update(id: number,data:IExchangeStoreUpdatePayload ,customHeaders: Record<string, any> = {}): ResponsePromise {
    const path = `${this.path}/${id}`;
    return this.client.request("PUT", path, data, {}, customHeaders);
  }
  updateRate(data:IStoreRateUpdatePayload ,customHeaders: Record<string, any> = {}): ResponsePromise {
    const path = `/api/store/admin/exchange/v1/update-store-exchange`;
    return this.client.request("POST", path, data, {}, customHeaders);
  }
}

export default ExchangeResource;
