import { PageHeader } from "@ant-design/pro-layout";
import {
  Avatar,
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  MenuProps,
  Modal,
  Row,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { paymentGatewayCollectionAtom } from "lib/core-react/store/store";
import useDataFilters from "hooks/useDataFilters";
import { ApiHelperModel } from "models/apiHelper";
import { MenuInfo } from "rc-menu/lib/interface";
import { PaginationModel } from "models/pagination";
import {
  useCreatePaymentGateway,
  useGetPaymentGateway,
  useUpdateUpdatePaymentGateway,
} from "lib/core-react/hooks/private/usePayment";
import {
  PaymentGatewayCollectionModel,
  PaymentGatewayModel,
} from "models/paymentGatewayCollectionModel";
import { DownOutlined, EditOutlined, HddFilled } from "@ant-design/icons";
import { IFilterType } from "types/filters";
import FiltersComponent from "components/FiltersComponent";
import { IPaymentGatewayFormData, Logo } from "types/paymentGatewayCollection";
import { PaymentGatewayLogoTypeEnums } from "enums/paymentGatewayCollectionEnums";
import { getError } from "lib/core-react/hooks/utils/errors";
import { PaymentGatewayModification } from "./components";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import { StatusTag } from "../../../components";

const PaymentGateway = () => {
  const { getPaymentGateway } = useGetPaymentGateway();
  const [{ data: paymentGatewayCollectionData, isLoading, refetch }] = useAtom(
    paymentGatewayCollectionAtom,
  );
  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
    refetch: refetchFromFilter,
  } = useDataFilters();

  // Api Call
  useEffect(() => {
    if ((!isFetched && isFirstCall) || refetch || refetchFromFilter) {
      ApiHelperModel.makeGetRequest({}, getPaymentGateway);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstCall, isFetched, refetch, refetchFromFilter]);
  const { isMobile } = useWindowWidth();

  const PaymentGatewayCollectionData =
    paymentGatewayCollectionData &&
    new PaymentGatewayCollectionModel(paymentGatewayCollectionData);

  // Filter
  const filterData = PaymentGatewayCollectionData?.getFilters();
  // Pagination
  const paginationData = PaymentGatewayCollectionData?.getPagination();

  const [selectedPaymentGateway, setSelectedPaymentGateway] = useState<
    PaymentGatewayModel | undefined
  >(undefined);
  const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);

  const handleMenuClick = (e: MenuInfo, record: PaymentGatewayModel) => {
    setSelectedPaymentGateway(record);

    if (e.key === "update") {
      setIsShowCreateModal(true);
    }
  };

  // Getting all available filters
  useEffect(() => {
    if (!isFetched && PaymentGatewayCollectionData?.filters) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [
    isFetched,
    initializeAvailableFilter,
    PaymentGatewayCollectionData?.filters,
  ]);

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getPaymentGateway,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    PaymentGatewayCollectionData,
    handlePaginationChange,
  );

  // Filter Handler
  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getPaymentGateway);
  };

  const { createPaymentGateway, isLoading: isLoadingCreate } =
    useCreatePaymentGateway();
  const { updatePaymentGateway, isLoading: isLoadingUpdate } =
    useUpdateUpdatePaymentGateway();

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const logos: Logo[] = [];

      if (values.small_logo) {
        logos.push({
          image_src: values.small_logo,
          type: PaymentGatewayLogoTypeEnums.SMALL,
        });
      }

      if (values.medium_logo) {
        logos.push({
          image_src: values.medium_logo,
          type: PaymentGatewayLogoTypeEnums.MEDIUM,
        });
      }

      const payload: IPaymentGatewayFormData = {
        name: values.name,
        identifier: values.identifier,
        type: values.type,
        fee_type: values.fee_type,
        fee: values.fee.toFixed(2),
        status: values.status,
        logos,
        currencies: values.currencies,
      };

      if (selectedPaymentGateway) {
        await updatePaymentGateway(selectedPaymentGateway.getId(), payload);
        setSelectedPaymentGateway(undefined);
      } else {
        await createPaymentGateway(payload);
      }
      setIsShowCreateModal(false);
    } catch (error) {
      message.error(getError(error));
    }
  };

  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "sl",
      align: "center" as const,
      width: 70,
      render: (_: string, __: PaymentGatewayModel, index: number) => {
        return (
          <div>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </div>
        );
      },
    },
    {
      title: "Gateway info",
      dataIndex: "gateway_info",
      key: "gateway_info",
      hidden: isMobile,
      render: (_: string, record: PaymentGatewayModel) => {
        const logoCollection = record.getLogo();
        console.log(logoCollection, "URL");
        const logoUrl = logoCollection
          ?.getData()
          .map((logo) => logo.getSmall()?.src)
          .find((url) => url);
        //console.log(logoUrl, "URL");
        return (
          <div style={{ display: "flex", gap: "16px", width: 300 }}>
            <Avatar
              shape="circle"
              size={70}
              style={{ objectFit: "contain" }}
              src={<Image src={logoUrl} />}
            />
            <div
              style={{
                display: "flex",
                gap: "10px",
                flexDirection: "column",
              }}
            >
              <div>
                <span style={{ fontWeight: "bold" }}>Name: </span>

                <Typography.Text strong>{record.getName()}</Typography.Text>
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Identifier: </span>

                <Tag color="green">
                  {record.getIdentifier().replaceAll("-", " ")}
                </Tag>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      hidden: isMobile,
      render: (_: string, record: PaymentGatewayModel) => {
        return (
          <div>
            <Tag color="green">{record.getType().replaceAll("-", " ")}</Tag>
          </div>
        );
      },
    },
    {
      title: "Free",
      dataIndex: "fee",
      key: "fee",
      hidden: isMobile,
      render: (_: string, record: PaymentGatewayModel) => {
        return (
          <div>
            {record.getFee() && record.getFeeType() ? (
              <Typography.Text>
                <Tag color="#54C21A">{`${record.getFee()} ${record.getFeeType() === "percentage" ? "%" : ""}`}</Tag>
              </Typography.Text>
            ) : (
              ""
            )}
          </div>
        );
      },
    },

    {
      title: "Currencies",
      dataIndex: "currencies",
      key: "currencies",
      hidden: isMobile,
      render: (_: string, record: PaymentGatewayModel) => {
        return (
          <div>
            {record.getCurrencies() &&
              record.getCurrencies().getData() &&
              record
                .getCurrencies()
                .getData()
                .map((currency, index, array) => (
                  <span key={currency.getId()}>
                    {currency.getName()}{" "}
                    {currency.getSymbol() ? `(${currency.getSymbol()})` : ""}
                    {index < array.length - 1 && ", "}
                  </span>
                ))}
          </div>
        );
      },
    },
    {
      title: "Gateway info",
      dataIndex: "name",
      hidden: !isMobile,
      key: "name",
      render: (_: string, record: PaymentGatewayModel) => {
        const logoCollection = record.getLogo();
        console.log(logoCollection, "URL");
        const logoUrl = logoCollection
          ?.getData()
          .map((logo) => logo.getSmall()?.src)
          .find((url) => url);
        return (
          <div style={{ display: "flex", gap: "16px", width: 300 }}>
            <Avatar
              shape="circle"
              size={70}
              style={{ objectFit: "contain" }}
              src={<Image src={logoUrl} />}
            />
            <div
              style={{
                display: "flex",
                gap: "10px",
                flexDirection: "column",
              }}
            >
              <div>
                <span style={{ fontWeight: "bold" }}>Name: </span>

                <Typography.Text strong>{record.getName()}</Typography.Text>
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Identifier: </span>

                <Tag color="green">
                  {record.getIdentifier().replaceAll("-", " ")}
                </Tag>
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Type: </span>

                <Tag color="green">{record.getType().replaceAll("-", " ")}</Tag>
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Fee: </span>
                {record.getFee() && record.getFeeType() ? (
                  <Typography.Text>
                    <Tag color="#54C21A">{`${record.getFee()} ${record.getFeeType() === "percentage" ? "%" : ""}`}</Tag>
                  </Typography.Text>
                ) : (
                  ""
                )}
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Status: </span>

                {record.getStatus() && (
                  <StatusTag
                    slug={record.getStatus()}
                    text={record.getStatus()}
                  />
                )}
              </div>
              <div>
                {record.getCurrencies() &&
                  record.getCurrencies().getData() &&
                  record
                    .getCurrencies()
                    .getData()
                    .map((currency, index, array) => (
                      <span key={currency.getId()}>
                        {currency.getName()}{" "}
                        {currency.getSymbol()
                          ? `(${currency.getSymbol()})`
                          : ""}
                        {index < array.length - 1 && ", "}
                      </span>
                    ))}
              </div>
            </div>
          </div>
        );
      },
    },
    // {
    //   title: "Merchant info",
    //   dataIndex: "merchant_data",
    //   key: "merchant_data",
    //   render: (
    //     merchantData: { [key: string]: string } | null,
    //     record: PaymentGatewayModel,
    //   ) => {
    //     return (
    //       <div
    //         style={{
    //           display: "flex",
    //           flexWrap: "wrap",
    //           gap: 10,
    //           width: 200,
    //         }}
    //       >
    //         {merchantData &&
    //           Object.keys(record.getMerchantData()).map((key) => (
    //             <div key={key}>
    //               <strong style={{ textTransform: "capitalize" }}>
    //                 {key}:
    //               </strong>{" "}
    //               {merchantData[key]}
    //             </div>
    //           ))}
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      hidden: isMobile,
      render: (_: string, record: PaymentGatewayModel) => {
        return (
          <div>
            {record.getStatus() && (
              <StatusTag slug={record.getStatus()} text={record.getStatus()} />
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      fixed: isMobile ? "right" : undefined,
      width: 100,
      render: (_: string, record: PaymentGatewayModel) => {
        const menuItems: MenuProps["items"] = [
          {
            label: "Update",
            key: "update",
            icon: <EditOutlined />,
            onClick: (e) => handleMenuClick(e, record),
          },
        ];

        return (
          <Dropdown
            menu={{
              items: menuItems,
            }}
          >
            <Button icon={<HddFilled />}>
              {isMobile ? (
                <DownOutlined />
              ) : (
                <>
                  Actions <DownOutlined />{" "}
                </>
              )}
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <PageHeader
          ghost={false}
          title="Payment Gateway"
          style={{ marginTop: "10px" }}
          extra={[
            <Button
              key="1"
              type="primary"
              onClick={() => setIsShowCreateModal(true)}
            >
              Add Payment Gateway
            </Button>,
          ]}
        >
          {filters && Object.keys(filters).length > 0 && (
            <Row>
              <Col span={24}>
                <Card title="Filter">
                  <FiltersComponent
                    handleProductFilter={handleProductFilter}
                    handleFilterChange={handleFilterChange}
                    handelFilterClear={handelFilterClear}
                    isFetched={isFetched}
                    filters={filters}
                    filtersData={filterData}
                    isFromProductReceived={true}
                  />
                </Card>
              </Col>
            </Row>
          )}
          <div>
            <Table
              style={{ marginTop: 10 }}
              loading={isLoading}
              rowKey="id"
              bordered={true}
              dataSource={PaymentGatewayCollectionData?.data}
              //@ts-ignore
              columns={columns}
              pagination={paginationConfig}
              scroll={{ x: 600 }}
            />
          </div>
        </PageHeader>
      </div>

      <Modal
        title={`${selectedPaymentGateway ? "Update" : "Create"} Payment Gateway`}
        open={isShowCreateModal}
        onCancel={() => {
          setIsShowCreateModal(false);
          setSelectedPaymentGateway(undefined);
        }}
        onOk={form.submit}
        okText={selectedPaymentGateway ? "Update" : "Create"}
        okButtonProps={{ loading: isLoadingCreate || isLoadingUpdate }}
        centered
      >
        <PaymentGatewayModification
          data={selectedPaymentGateway}
          form={form}
          onFinish={onFinish}
        />
      </Modal>
    </>
  );
};

export default PaymentGateway;
