import React, {
  Dispatch,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from "react";
import { Select, Spin } from "antd";
import type { SelectProps } from "antd";
import client from "utils/api-client";

import { ICustomerUser } from "types/customerUserCollection";

import { SelectLabelValue } from "./utils";
import {
  ServiceBaseUrl,
  ServiceNameEnum,
} from "lib/core/apiServices/servicBaseUrl";

const debounce = <F extends (...args: any[]) => void>(
  fn: F,
  T: number,
): (() => void) => {
  let timer: NodeJS.Timeout | null;

  return function (...args: any[]) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, T);
  };
};

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, "options" | "children"> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

function DebounceSelect<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any,
>({
  fetchOptions,
  debounceTimeout = 800,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

async function fetchUserList(username: string): Promise<SelectLabelValue[]> {
  const commerceServiceApiUrl = ServiceBaseUrl.getServiceBaseUrl(
    ServiceNameEnum.COMMERCE_SERVICE,
  );
  const path = `${commerceServiceApiUrl}/api/user/admin/customer-user/v1/customer-users?name=${username}`;
  const response = await client.get(path);
  const users = response.data;

  return users.data.map((user: ICustomerUser) => {
    return {
      label: `${user.name} (${user.email})`,
      value: user.id,
    };
  });
}

interface IProps {
  specificUserIds: SelectLabelValue[];
  setSpecificUserIds: Dispatch<SetStateAction<SelectLabelValue[]>>;
  placeholder?: string;
}

export const UserSelect = ({
  specificUserIds,
  setSpecificUserIds,
  placeholder,
}: IProps) => {
  return (
    <DebounceSelect
      mode="multiple"
      value={specificUserIds}
      placeholder={placeholder || "Select users"}
      fetchOptions={fetchUserList}
      onChange={(newValue) => {
        setSpecificUserIds(newValue as SelectLabelValue[]);
      }}
      style={{ width: "100%" }}
    />
  );
};
