import React from 'react'
import {Descriptions, Col, Row, Divider, Table, Image, Typography, Tag} from 'antd'
import './ShippingProductDetails.css'
import Date from "../Date"
import {StatusTag} from "components/index.js";
import ImageGallery from "components/ImageGallery";
const { Column } = Table;
const { Title } = Typography;

const ShippingProductDetails = props => {
    const { shippingInfo } = props

    const updateSize = (url, size) => {
        return url.replace(/_\d+x\d+/, `_${size}x${size}`)
    }

    if (shippingInfo === null){
        return <Title level={4} style={{color: '#2db7f5'}}>Product is not shipped </Title>
    }

    return (
        <>
            <Title level={4} style={{color: '#2db7f5'}}>Shipment Info</Title>
            <Divider/>
            <Row>
                <Col span={24}>
                    <Descriptions title="Product Info">
                        <Descriptions.Item label="Product Number">{shippingInfo?.product_number}</Descriptions.Item>
                        <Descriptions.Item label="Status">
                            <StatusTag text={shippingInfo?.status?.name} slug={shippingInfo?.status?.slug} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Title">{shippingInfo?.title}</Descriptions.Item>
                        <Descriptions.Item label="Description">{shippingInfo?.description}</Descriptions.Item>
                        {
                            shippingInfo?.category && <Descriptions.Item label="Category">{shippingInfo?.category.name}</Descriptions.Item>
                        }
                        <Descriptions.Item label="Origin">{shippingInfo?.origin}</Descriptions.Item>
                        <Descriptions.Item label="Price">{shippingInfo?.price} {shippingInfo?.currency}</Descriptions.Item>
                        <Descriptions.Item label="Weight">{shippingInfo?.weight} KG</Descriptions.Item>
                        <Descriptions.Item label="Quantity">{shippingInfo?.quantity} Pieces</Descriptions.Item>

                        <Descriptions.Item label="Shipping Type">{shippingInfo?.shipping_type}</Descriptions.Item>
                        <Descriptions.Item label="Ship To"><Date date={shippingInfo?.ship_to} /></Descriptions.Item>
                        <Descriptions.Item label="Valid To"><Date date={shippingInfo?.valid_to} /></Descriptions.Item>
                        <Descriptions.Item label="Label Text">{shippingInfo?.label_text && shippingInfo?.label_text}</Descriptions.Item>
                        <Descriptions.Item label="Contains">
                            {
                                shippingInfo?.contains && shippingInfo?.contains.map((item, index) => {
                                    return(
                                        <Tag color="#108ee9" key={index + "contains"} style={{marginBottom: '5%'}}>{item}</Tag>
                                    )
                                })
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label=""></Descriptions.Item>
                        {
                            shippingInfo?.link &&
                            <Descriptions.Item label="Link">
                                <a href={shippingInfo?.link}>{shippingInfo?.link}</a> <br />
                            </Descriptions.Item>
                        }
                    </Descriptions>
                </Col>
            </Row>
            {
                shippingInfo?.width &&
                <Row>
                    <Col span={24}>
                        <Descriptions title="Product Properties">
                            <Descriptions.Item label="Width">{shippingInfo?.width} CM</Descriptions.Item>
                            <Descriptions.Item label="Height">{shippingInfo?.height} CM</Descriptions.Item>
                            <Descriptions.Item label="Length">{shippingInfo?.length} CM</Descriptions.Item>
                            <Descriptions.Item label="CBM">{shippingInfo?.cbm} CM<sup>3</sup></Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
            }

            {
                shippingInfo?.order && shippingInfo?.order.customer &&
                <>
                    <Row>
                        <Col span={24}>
                            <Descriptions title="Customer">
                                <Descriptions.Item label="Name">{shippingInfo?.order.customer.name}</Descriptions.Item>
                                <Descriptions.Item label="Phone">{shippingInfo?.order.customer.phone}</Descriptions.Item>
                                <Descriptions.Item label="Email">{shippingInfo?.order.customer.email}</Descriptions.Item>
                                <Descriptions.Item label="Shipping mark">{shippingInfo?.order.customer.shipping_mark}</Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <Divider />
                </>
            }

            {
                shippingInfo?.order &&
                <>
                    <Row>
                        <Col span={24}>
                            <Descriptions title="Order Info">
                                <Descriptions.Item label="Order Number">{shippingInfo?.order.order_number}</Descriptions.Item>
                                <Descriptions.Item label="Status">
                                    {/*<StatusTag.tsx text={shippingInfo?.status?.name} slug={shippingInfo?.status?.slug} />*/}
                                    {/*<StatusItem status={product.order.status} />*/}
                                </Descriptions.Item>
                                <Descriptions.Item label="Payment Status">
                                    {/*<StatusItem status={product.order.payment_status} />*/}
                                </Descriptions.Item>
                                <Descriptions.Item label="Created At">
                                    <Date date={shippingInfo?.order.created_at} />
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <Divider />
                </>
            }
            <Descriptions title="Images">
            </Descriptions>
            {
                shippingInfo?.image_link && shippingInfo?.image_link != null ?
                    <Image
                        width={100}
                        src={updateSize(shippingInfo?.image_link, 800)}
                        alt={shippingInfo?.title}
                    />
                    :
                    <ImageGallery
                        images={shippingInfo?.images}
                    />
            }
            <Divider />
            {
                shippingInfo?.warehouse &&
                <>
                    <Row>
                        <Col span={24}>
                            <Descriptions title="Warehouse Info">
                                <Descriptions.Item label="Warehouse Name ">{shippingInfo?.warehouse?.name}</Descriptions.Item>
                                <Descriptions.Item label="IAddress ">{shippingInfo?.warehouse?.address}</Descriptions.Item><br />
                                <Descriptions.Item label="Contact Person">{shippingInfo?.warehouse?.contact_person}</Descriptions.Item>
                                <Descriptions.Item label="Contact Number">{shippingInfo?.warehouse?.contact_number}</Descriptions.Item>
                                {
                                    shippingInfo?.warehouse?.company &&
                                    <Descriptions.Item label="Warehouse Company">{shippingInfo?.warehouse?.company?.name}</Descriptions.Item>
                                }
                            </Descriptions>,
                        </Col>
                    </Row>
                    <Divider />
                </>
            }
            {
                shippingInfo?.rate_per_unit &&
                <>
                    <Row>
                        <Col span={24}>
                            <Descriptions title="Shipping Info">
                                <Descriptions.Item label="Unit Type">{shippingInfo?.unit_type}</Descriptions.Item>
                                <Descriptions.Item label="Rate Per Unit">{shippingInfo?.rate_per_unit} {shippingInfo?.agent_rate_per_unit === shippingInfo?.rate_per_unit && '(CI)'}</Descriptions.Item><br />
                                <Descriptions.Item label="Agent Rate Per Unit">{shippingInfo?.agent_rate_per_unit}</Descriptions.Item><br />
                            </Descriptions>
                        </Col>
                    </Row>
                    <Divider />
                </>
            }
            {
                shippingInfo?.charges &&
                <>
                    <Row>
                        <Col span={24} >
                            <Descriptions title="Product Charges">
                            </Descriptions>
                            <Table
                                dataSource={shippingInfo?.charges}
                                bordered={true}
                                pagination={false}
                            >
                                <Column
                                    title="#SL"
                                    dataIndex="id"
                                    key="SL"
                                    align={"center"}
                                    render={(id, row) => (
                                        shippingInfo?.charges.indexOf(row) + 1
                                    )}
                                />
                                <Column
                                    title="Type"
                                    dataIndex="type"
                                    key="type"
                                    align={"center"}
                                />
                                <Column
                                    title="Amount"
                                    dataIndex="amount"
                                    key="amount"
                                    align={"center"}
                                />
                                <Column
                                    title="Note"
                                    dataIndex="note"
                                    key="note"
                                    align={"center"}
                                />
                            </Table>
                        </Col>
                    </Row>
                    <Divider />
                </>
            }
            {/*<Row>*/}
            {/*    <Col span={24} style={{ marginTop: 20, marginBottom: 20 }}>*/}
            {/*        <Button type="primary" onClick={() => setShowTimeline(true)}>*/}
            {/*            Show Timeline*/}
            {/*        </Button>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </>
    );
}

export default ShippingProductDetails;
