import { useAtom } from "jotai";
import { useService } from "lib/core-react/contexts";
import {
  productDetailAtom,
  productSearchResultAtom,
} from "lib/core-react/store/store";
import { getError } from "../utils/errors";

export const useGetProductDetailsCollection = () => {
  const [productSearchResult, setProductSearchResult] = useAtom(
    productSearchResultAtom,
  );
  const { productService } = useService();
  const getProductDetailsCollection = async (
    params: Record<string, any>,
    payload?: Record<string, any>,
  ): Promise<void> => {
    setProductSearchResult({
      ...productSearchResult,
      isLoading: true,
      error: null,
    });
    try {
      const response = await productService.productResource.getProductList(
        params,
        payload,
      );
      setProductSearchResult({
        ...productSearchResult,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setProductSearchResult({
        ...productSearchResult,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getProductDetailsCollection } as const;
};

export const useGetProductDetails = () => {
  const [productDetailResult, setProductDetailResult] =
    useAtom(productDetailAtom);
  const { productService } = useService();
  const getProductDetails = async (
    params: Record<string, any>,
    productId: string,
  ): Promise<void> => {
    setProductDetailResult({
      ...productDetailResult,
      isLoading: true,
      error: null,
    });
    try {
      const response = await productService.productResource.getProductDetails(
        params,
        productId,
      );
      setProductDetailResult({
        ...productDetailResult,
        isLoading: false,
        data: response.data,
        refetch: false,
      });
    } catch (error: any) {
      setProductDetailResult({
        ...productDetailResult,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getProductDetails } as const;
};

export const useGetProductDetailsByUrl = () => {
  const [productDetailResult, setProductDetailResult] =
    useAtom(productDetailAtom);
  const { productService } = useService();
  const getProductDetails = async (
    params: Record<string, any>,
    productId: string,
  ): Promise<void> => {
    setProductDetailResult({
      ...productDetailResult,
      isLoading: true,
      error: null,
    });
    try {
      const response = await productService.productResource.getProductDetails(
        params,
        productId,
      );
      setProductDetailResult({
        ...productDetailResult,
        isLoading: false,
        data: response.data,
        refetch: false,
      });
    } catch (error: any) {
      setProductDetailResult({
        ...productDetailResult,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getProductDetails } as const;
};
