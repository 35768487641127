import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  HddFilled,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Modal,
  Table,
  Tag,
  Typography,
  Row,
  Col,
  Card,
  Space,
  MenuProps,
} from "antd";
import useDataFilters from "hooks/useDataFilters";
import { useAtom } from "jotai";

import { addonServicsCollectionAtom } from "lib/core-react/store/store";
import { ApiHelperModel } from "models/apiHelper";
import { PaginationModel } from "models/pagination";
import { useEffect, useState } from "react";
import { IFilterType } from "types/filters";
import CreateService from "./components/CreateServices";
import { useAddonSerivesList } from "lib/core-react/hooks/private/useAddonService";
import {
  AddonServiceCollectionModel,
  AddonServiceModel,
  AddonServicePriceSlotsModel,
  AddonServicePricesModel,
} from "models/addonServiceCollectionModel";
import FiltersComponent from "components/FiltersComponent";
import UpdateService from "./components/UpdateService";

import { MenuInfo } from "rc-menu/lib/interface";
import AddPrice from "./components/PriceSlot/addPrice";
import DeletePrice from "./components/PriceSlot/delete";
import UpdatePrice from "./components/PriceSlot/edit";
import { PageHeader } from "@ant-design/pro-layout/es/components/PageHeader";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import { formatDateTime } from "utils/helpers";
const AddonServices = () => {
  const { getAddonSerives } = useAddonSerivesList();
  const [{ data: addonServicsCollection, isLoading, refetch }] = useAtom(
    addonServicsCollectionAtom,
  );
  const { isMobile } = useWindowWidth();
  const [modalAction, setModalAction] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = (action: any) => {
    setModalAction(action);
    setModalVisible(true);
  };

  const handleOk = () => {
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const { Text } = Typography;

  const {
    filters,
    handleFilterChange,
    isFirstCall,
    isFetched,
    handelFilterClear,
    initializeAvailableFilter,
    refetch: refetchFromFilter,
  } = useDataFilters();

  // Api Call
  useEffect(() => {
    if ((!isFetched && isFirstCall) || refetch || refetchFromFilter) {
      ApiHelperModel.makeGetRequest({}, getAddonSerives);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstCall, isFetched, refetch, refetchFromFilter]);

  const AddonServiceCollectionData =
    addonServicsCollection &&
    new AddonServiceCollectionModel(addonServicsCollection);

  const [selectedAddonService, setSelectedAddonService] = useState<
    AddonServiceModel | undefined
  >(undefined);

  const [selectedPrice, setSelectedPrice] = useState<
    AddonServicePricesModel | undefined
  >(undefined);

  const paginationData = AddonServiceCollectionData?.getPagination();

  const filterData = AddonServiceCollectionData?.getFilters();

  const handleMenuClick = (e: MenuInfo, record: AddonServiceModel) => {
    setSelectedAddonService(record);

    if (e.key === "update_service") {
      showModal("update_service");
    }
  };

  const handleEdit = (priceSlot: any) => {
    setSelectedPrice(priceSlot);
    showModal("update_price");
  };

  const handleAdd = (priceSlot: any) => {
    setSelectedPrice(priceSlot);
    showModal("add_price");
  };

  const handleDelete = (priceSlot: any) => {
    setSelectedPrice(priceSlot);
    showModal("delete_price");
  };

  // Getting all available filters
  useEffect(() => {
    if (!isFetched && AddonServiceCollectionData?.getFilters()) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [
    isFetched,
    initializeAvailableFilter,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    AddonServiceCollectionData?.getFilters(),
  ]);

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getAddonSerives,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    AddonServiceCollectionData,
    handlePaginationChange,
  );

  // Filter Handler
  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getAddonSerives);
  };

  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "sl",
      align: "center" as const,
      width: 70,
      render: (_: string, __: AddonServiceModel, index: number) => {
        return (
          <div>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </div>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      hidden: isMobile,
      key: "name",
      align: "center" as const,
      render: (_: string, record: AddonServiceModel, index: number) => {
        return (
          <div>
            <Text>{record.getName()}</Text>
          </div>
        );
      },
    },
    {
      title: "Addon Status",
      hidden: !isMobile,
      width: 250,
      key: "exchange-rates",
      render: (_: string, record: AddonServiceModel) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              minWidth: "150px",
            }}
          >
            <div>
              Name:
              <Text>{record.getName()}</Text>
            </div>
            <div style={{ marginBottom: "4px" }}>
              <Text strong style={{ marginRight: "5px" }}>
                Addon For:
              </Text>
              <Tag color="cyan">{record.getAddonFor()}</Tag>
            </div>
            <div style={{ marginBottom: "4px" }}>
              <Text strong style={{ marginRight: "5px" }}>
                Identifier:
              </Text>
              <Tag color="processing">{record.getIdentifier()}</Tag>
            </div>
            <div>
              <Text strong style={{ marginRight: "5px" }}>
                Status:
              </Text>
              <Tag color={record.getStatus() === "active" ? "#87d068" : "#f50"}>
                {record.getStatus()}
              </Tag>
            </div>
          </div>
        );
      },
    },
    {
      title: "Addon For",
      dataIndex: "addon_for",
      hidden: isMobile,
      key: "addon_for",
      align: "center" as const,
      render: (_: string, record: AddonServiceModel, index: number) => {
        return (
          <div>
            <Tag color="cyan">{record.getAddonFor()}</Tag>
          </div>
        );
      },
    },
    {
      title: "Identifier",
      dataIndex: "identifier",
      key: "identifier",
      hidden: isMobile,
      align: "center" as const,
      render: (_: string, record: AddonServiceModel, index: number) => {
        return (
          <div>
            <Tag color="processing">{record.getIdentifier()}</Tag>
          </div>
        );
      },
    },
    {
      title: "Descriptions",
      dataIndex: "descriptions",
      key: "descriptions",
      width: 200,
      align: "center" as const,
      render: (_: string, record: AddonServiceModel, index: number) => {
        return (
          <div>
            <Text type="secondary">{record.getDescription()}</Text>
          </div>
        );
      },
    },
    {
      title: "Prices",
      dataIndex: "prices",
      key: "prices",
      align: "center" as const,
      render: (_: string, record: AddonServiceModel) => {
        const pricesData = record.prices.data;
        if (pricesData && pricesData.length > 0) {
          return (
            <Table<AddonServicePricesModel>
              dataSource={pricesData}
              columns={[
                { title: "Price", dataIndex: "price", key: "price" },
                {
                  title: "Price Slots",
                  dataIndex: "price_slots",
                  key: "price_slots",
                  align: "center" as const,
                  render: (
                    priceSlots: AddonServicePricesModel["price_slots"],
                  ) => (
                    <Table<AddonServicePriceSlotsModel>
                      dataSource={priceSlots.getData()}
                      columns={[
                        {
                          title: "Min",
                          dataIndex: "min_amount",
                          key: "min_amount",
                        },
                        {
                          title: "Max",
                          dataIndex: "max_amount",
                          key: "max_amount",
                        },
                        { title: "Price", dataIndex: "price", key: "price" },
                      ]}
                      pagination={false}
                    />
                  ),
                },
                {
                  title: "Actions",
                  dataIndex: "actions",
                  key: "actions",
                  render: (_, priceSlot: AddonServicePricesModel) => (
                    <Space>
                      <Button
                        type="primary"
                        onClick={() => handleAdd(priceSlot)}
                        icon={<PlusOutlined />}
                      ></Button>
                      <Button
                        onClick={() => handleEdit(priceSlot)}
                        icon={<EditOutlined />}
                        type="default"
                      ></Button>
                      <Button
                        danger
                        onClick={() => handleDelete(priceSlot)}
                        icon={<DeleteOutlined />}
                      ></Button>
                    </Space>
                  ),
                },
              ]}
              pagination={false}
            />
          );
        } else {
          return (
            <Button
              type="primary"
              onClick={() => handleAdd(record)}
              icon={<PlusOutlined />}
            >
              Add Price
            </Button>
          );
        }
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      hidden: isMobile,
      key: "status",
      align: "center" as const,
      render: (_: string, record: AddonServiceModel, index: number) => {
        return (
          <div>
            <Tag color={record.getStatus() === "active" ? "#87d068" : "#f50"}>
              {record.getStatus()}
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      hidden: isMobile,
      key: "created_at",
      align: "center" as const,
      render: (_: string, record: AddonServiceModel, index: number) => {
        return (
          <div>
            <Tag color="purple">{formatDateTime(record.getCreatedAt())}</Tag>
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      fixed: isMobile ? "right" : undefined,
      width: 100,
      render: (_: string, record: AddonServiceModel) => {
        const menuItems: MenuProps["items"] = [
          {
            label: "Update Service",
            key: "update_service",
            icon: <EditOutlined />,
            onClick: async (e) => handleMenuClick(e, record),
            style: { margin: "5px", padding: "8px 16px" },
          },
          // {
          //   label: isRecalculating ? "Wait...." : "Re-calculate",
          //   key: "re-calculate",
          //   icon: <CalculatorFilled />,
          //   onClick: handleRecalculate,
          //   disabled: isRecalculating,
          //   style: { margin: "5px", padding: "8px 16px" },
          // },
        ];

        if (isMobile) {
          menuItems.push({
            label: (
              <Tag color="purple">{formatDateTime(record.getCreatedAt())}</Tag>
            ),
            key: "created_at",
          });
        }

        return (
          <Dropdown
            menu={{
              items: menuItems,
            }}
          >
            <Button icon={<HddFilled />}>
              {isMobile ? (
                <DownOutlined />
              ) : (
                <>
                  Actions <DownOutlined />{" "}
                </>
              )}
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <PageHeader
          ghost={false}
          title="All Addons"
          style={{ marginTop: "10px" }}
          extra={[
            <Button
              onClick={() => {
                showModal("create_service");
              }}
              icon={<PlusOutlined />}
              type="primary"
            >
              Create a Addons
            </Button>,
          ]}
        >
          {filters && Object.keys(filters).length > 0 && (
            <Row>
              <Col span={24}>
                <Card title="Filter">
                  <FiltersComponent
                    handleProductFilter={handleProductFilter}
                    handleFilterChange={handleFilterChange}
                    handelFilterClear={handelFilterClear}
                    isFetched={isFetched}
                    filters={filters}
                    filtersData={filterData}
                    isFromProductReceived={true}
                  />
                </Card>
              </Col>
            </Row>
          )}
          <div>
            <Table
              loading={isLoading}
              rowKey="id"
              bordered={true}
              dataSource={AddonServiceCollectionData?.getData()}
              //@ts-ignore
              columns={columns}
              pagination={paginationConfig}
              scroll={{ x: 1237 }}
            />
          </div>
        </PageHeader>
      </div>

      <Modal
        title={
          modalAction === "create_service"
            ? "Create Service"
            : modalAction === "update_service"
              ? "Update Service"
              : modalAction === "add_price"
                ? "Add Price"
                : modalAction === "update_price"
                  ? "Update Price"
                  : "Delete Price"
        }
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {modalAction === "create_service" && (
          <CreateService setIsShowCreateModal={setModalVisible} />
        )}
        {modalAction === "update_service" && selectedAddonService && (
          <UpdateService
            selectedAddonServics={selectedAddonService}
            setIsShowUpdateModal={setModalVisible}
          />
        )}
        {modalAction === "add_price" && selectedPrice && (
          <AddPrice
            selectedAddPrice={selectedPrice}
            setIsShowAddPriceModal={setModalVisible}
          />
        )}
        {modalAction === "update_price" && selectedPrice && (
          <UpdatePrice
            selectedUpdatePrice={selectedPrice}
            setIsShowUpdatePriceModal={setModalVisible}
          />
        )}
        {modalAction === "delete_price" && selectedPrice && (
          <DeletePrice
            setIsShowDeleteModal={setModalVisible}
            isShowDeleteId={selectedPrice}
          />
        )}
      </Modal>

      {/* {selectedAddonService && (
        <Modal
          title={`Update Service`}
          open={isShowUpdateModal}
          footer={false}
          onCancel={() => setIsShowUpdateModal(false)}
        >
          <UpdateService
            selectedAddonServics={selectedAddonService}
            setIsShowUpdateModal={setIsShowUpdateModal}
          />
        </Modal>
      )}

      {selectedAddPrice && isShowAddPriceModal && (
        <Modal
          title={`Add Price`}
          open={isShowAddPriceModal}
          footer={false}
          onCancel={() => setIsShowAddPriceModal(false)}
        >
          <AddPrice
            selectedAddPrice={selectedAddPrice}
            setIsShowAddPriceModal={setIsShowAddPriceModal}
          />
        </Modal>
      )}

      {selectedAddPrice && (
        <Modal
          title={`Update Price`}
          open={isShowUpdatePriceModal}
          footer={false}
          onCancel={() => setIsShowAddPriceModal(false)}
        >
          <UpdatePrice
            selectedAddPrice={selectedAddPrice}
            setIsShowUpdatePriceModal={setIsShowUpdatePriceModal}
          />
        </Modal>
      )}

      {selectedAddPrice && isShowDeleteModal && (
        <Modal
          title="Delete Price"
          open={isShowDeleteModal}
          onCancel={() => setIsShowDeleteModal(false)}
          footer={null}
        >
          <DeletePrice
            setIsShowDeleteModal={setIsShowDeleteModal}
            isShowDeleteId={selectedAddPrice}
          />
        </Modal>
      )}

      <Modal
        title={`Create Service`}
        open={isShowCreateModal}
        footer={false}
        onCancel={() => setIsShowCreateModal(false)}
      >
        <CreateService setIsShowCreateModal={setIsShowCreateModal} />
      </Modal> */}
    </>
  );
};

export default AddonServices;
