import {
  Card,
  Col,
  Descriptions,
  DescriptionsProps,
  Typography,
} from "antd";
import {BuyProductViewModel} from "../../../../models/buyProductDetailModel";

export const Delivery = ({ isLoading, productData, title }:{productData:BuyProductViewModel, isLoading:boolean, title:string}) => {
  const shippingAddress = productData?.getShippingAddress();

  const shippingAddressItems: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Name",
      children: (
        <Typography.Text
          style={{ width: "150px" }}
          ellipsis={{
            tooltip: `${shippingAddress?.first_name || ""} ${shippingAddress?.last_name || ""}`,
          }}
        >
          {`${shippingAddress?.first_name || ""} ${shippingAddress?.last_name || ""}`}
        </Typography.Text>
      ),
    },
    {
      key: "2",
      label: "Email",
      children: shippingAddress?.email || "",
    },
    {
      key: "3",
      label: "Phone",
      children: (
        <a href={`tel:${shippingAddress?.phone || ""}`}>
          {shippingAddress?.phone || "N/A"}
        </a>
      ),
    },
    {
      key: "4",
      label: "Address",
      children: `${shippingAddress?.address_1 || ""}, ${shippingAddress?.address_2 || ""}`,
    },
    {
      key: "5",
      label: "Area",
      children: `${shippingAddress?.area || ""}`,
    },
    {
      key: "6",
      label: "District",
      children: `${shippingAddress?.district || ""}`,
    },
    {
      key: "7",
      label: "Post Code",
      children: `${shippingAddress?.postal_code || ""}`,
    },
    {
      key: "8",
      label: "Country",
      children: `${shippingAddress?.country || ""}`,
    },
  ];
  return (
    <Card title={title} bordered={false} loading={isLoading}>
      <Col span={24}>
        <Card

          style={{
            flex: 1,
            maxHeight: "200px",
            overflowY: "auto",
          }}
        >
          {shippingAddress ? (
            <Descriptions items={shippingAddressItems} />
          ) : (
            "No company available yet."
          )}
        </Card>
      </Col>
    </Card>
  );
};
