import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Form, Input, Checkbox, Button, Row, Col, Select } from "antd";
import { Forms } from "models/form";
import { useGetCountry } from "lib/core-react/hooks/private";
import { useAtom } from "jotai";
import {
  addressSchemaAtom,
  buyOrderPlaceAtom,
  regionCountry,
} from "lib/core-react/store/store";
import { CountryCollectionModel } from "models/countryCollectionModel";
import { CreateAddressRequestModel } from "models/buyPlaceOrderModel";
import { useCreateAddress } from "lib/core-react/hooks/private/useAddress";
import { useAddressSchemaRetrive } from "lib/core-react/hooks/private/useAddressSchema";
import { AddressSchemaModel } from "models/addressSchemaModel";

type SetModalVisible = Dispatch<SetStateAction<boolean>>;

const AddressModal: React.FC<{ setIsModalVisible: SetModalVisible }> = ({
  setIsModalVisible,
}) => {
  const [form] = Form.useForm();
  const { getCountry } = useGetCountry();
  const { createAddress, isLoading } = useCreateAddress();
  const { getAddressSchema } = useAddressSchemaRetrive();
  const [, setIsErrorVisible] = useState(false);

  const [{ data: countryCollectionAtom }] = useAtom(regionCountry);

  const [{ data: adddresSchema }] = useAtom(addressSchemaAtom);

  const CountryData =
    countryCollectionAtom && new CountryCollectionModel(countryCollectionAtom);
  const locale = "en";

  const [buyOrderPlaceAtomData] = useAtom(buyOrderPlaceAtom);

  useEffect(() => {
    getCountry("per_page=200");
    if (buyOrderPlaceAtomData.data?.countryCode) {
      getAddressSchema(buyOrderPlaceAtomData.data?.countryCode[0], locale);
    }
  }, []);

  useEffect(() => {
    form.resetFields();
  }, []);

  const addressSchemaData =
    adddresSchema && new AddressSchemaModel(adddresSchema);

  const webFull = addressSchemaData?.data?.web_full || {};
  const fields = addressSchemaData?.data?.fields || {};

  const handleOnFinsh = async (values) => {
    const userId = buyOrderPlaceAtomData.data?.user?.value;
    const country = buyOrderPlaceAtomData.data?.countryId;
    const countryId = Number(country);
    const UserIdN = Number(userId);
    const countryCode = buyOrderPlaceAtomData.data?.countryCode
      ? buyOrderPlaceAtomData.data?.countryCode[0]
      : "";

    const payload: CreateAddressRequestModel = {
      user_id: UserIdN,
      country_id: countryId,
      address: {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone: values.phone,
        district: values.district,
        area: values.area,
        postal_code: values.postal_code,
        address_1: values.address_1,
        address_2: values.address_2 || "",
        country: values.country,
      },
      label_ids: [],
      is_default_shipping: values.is_default_shipping,
      is_default_billing: values.is_default_billing,
    };

    try {
      await createAddress(UserIdN, countryCode, payload);
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    Forms.formValueChange(form, changedValues, allValues);
  };

  return (
    <Form
      layout="vertical"
      onFinish={(values) => handleOnFinsh(values)}
      onFieldsChange={(changedFields, allFields) =>
        onValuesChange(changedFields, allFields)
      }
      initialValues={{
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        district: "",
        area: "",
        postal_code: "",
        address_1: "",
        address_2: "",
        country: null,
        is_default_shipping: false,
        is_default_billing: false,
      }}
    >
      {Object.entries(webFull).map(([line, fieldsArray]) => (
        <Row gutter={16} key={line}>
          {fieldsArray.map((fieldName, index) => {
            const fieldData = fields[fieldName];
            const {
              type,
              place_holder,
              label,
              is_required,
              data_source,
              data,
            } = fieldData;
            let fieldComponent;

            switch (type) {
              case "string":
                fieldComponent = <Input placeholder={place_holder} />;
                break;
              case "textarea":
                fieldComponent = <Input.TextArea placeholder={place_holder} />;
                break;
              case "select":
                const dataSource =
                  data_source === "external" && CountryData
                    ? CountryData.getCountryLabeledValueAndId()
                    : Object.entries(data?.data || {}).map(
                        ([value, label]) => ({ value, label }),
                      );
                fieldComponent = (
                  <Select placeholder={place_holder}>
                    {dataSource.map(({ value, label }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    ))}
                  </Select>
                );
                break;
              default:
                fieldComponent = null;
            }

            return (
              <Col span={12} key={fieldName}>
                <Form.Item
                  label={label}
                  name={fieldName}
                  initialValue={null} // Ensure field is null by default
                  rules={[
                    {
                      required: is_required,
                      message: `Please input your ${label}!`,
                    },
                  ]}
                >
                  {fieldComponent}
                </Form.Item>
              </Col>
            );
          })}
        </Row>
      ))}

      <Row gutter={16}>
        <Col span={12} style={{ marginBottom: "16px" }}>
          <Form.Item
            name="is_default_shipping"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox>Default Shipping Address</Checkbox>
          </Form.Item>
        </Col>
        <Col span={12} style={{ marginBottom: "16px" }}>
          <Form.Item
            name="is_default_billing"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox>Default Billing Address</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button loading={isLoading} disabled={isLoading} type="primary" htmlType="submit" style={{ width: "100%" }}>
          Update
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddressModal;
