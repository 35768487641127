import {
  LanguageDataStatus,
  LanguageEnum,
} from "enums/languageCollectionEnums";
import { IFilterType } from "types/filters";
import {
  ILanguage,
  ILanguageData,
  ILanguageUpdateRequest,
} from "types/language";
import { IPagination } from "types/pagination";

export class LanguageDataModel {
  public readonly object: LanguageEnum.Language;
  public readonly id: number;
  public readonly code: string;
  public readonly name: string;
  public readonly status: LanguageDataStatus;
  public readonly is_rtl: boolean;
  public readonly created_at: string;

  constructor(dataItem: ILanguageData) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.code = dataItem.code;
    this.name = dataItem.name;
    this.status = dataItem.status;
    this.is_rtl = dataItem.is_rtl;
    this.created_at = dataItem.created_at;
  }
}

export class LanguageModel {
  public readonly object: LanguageEnum.LanguageCollection;
  public readonly data: LanguageDataModel[];
  public readonly pagination: IPagination;
  public readonly filters: IFilterType;

  constructor(data: ILanguage) {
    this.object = data.object;
    this.data = data.data.map((item) => new LanguageDataModel(item));
    this.pagination = data.pagination;
    this.filters = data.filters;
  }
  getLanguageId() {
    const obj: { [key: number]: string } = {};
    this.data.forEach((item) => (obj[item.id] = item.name));
    return obj;
  }
}
