import { IFilterType } from "types/filters";
import {
  HarvestBatchCollection,
  IContext,
  IHarvestBatch,
  IHarvestBatchTag,
  IHarvestBatchTagCollection,
  IUser,
} from "types/harvestBatchCollection";
import { IPagination } from "types/pagination";

export class UserModel {
  public readonly object: string;
  public readonly id: number;
  public readonly name: string;
  public readonly phone: string | null;
  public readonly email: string;
  public readonly email_verified_at: string;
  public readonly type: string;
  public readonly is_active: number;
  public readonly created_at: string;
  public readonly avater?: string;

  constructor(data: IUser) {
    this.object = data.object;
    this.id = data.id;
    this.name = data.name;
    this.phone = data.phone;
    this.email = data.email;
    this.email_verified_at = data.email_verified_at;
    this.type = data.type;
    this.is_active = data.is_active;
    this.created_at = data.created_at;
    this.avater = data.avatar;
  }

  getId(): number {
    return this.id;
  }

  getName(): string {
    return this.name;
  }

  getPhone(): string | null {
    return this.phone;
  }

  getAvater() {
    return this.avater;
  }

  getEmail(): string {
    return this.email;
  }

  getEmailVerifiedAt(): string {
    return this.email_verified_at;
  }

  getType(): string {
    return this.type;
  }

  getIsActive(): number {
    return this.is_active;
  }

  getCreatedAt(): string {
    return this.created_at;
  }
}

export class ContextModel {
  public readonly modify: { status: string; warehouse_shipment_id: string };
  public readonly filters: { status: string };
  public readonly attachment_upload: string;

  constructor(data: IContext) {
    this.modify = data.modify;
    this.filters = data.filters;
    this.attachment_upload = data.attachment_upload;
  }

  getModify() {
    return this.modify;
  }

  getFilter() {
    return this.filters;
  }

  getAttachemntUpload() {
    return this.attachment_upload;
  }
}

export class HarvestBatchTagModel {
  public readonly object: string;
  public readonly id: number;
  public readonly tag: string;

  constructor(data: IHarvestBatchTag) {
    this.object = data.object;
    this.id = data.id;
    this.tag = data.tag;
  }

  getId(): number {
    return this.id;
  }

  getTag(): string {
    return this.tag;
  }
}

export class HarvestBatchTagCollectionModel {
  public readonly object: string;
  public readonly data: HarvestBatchTagModel[];

  constructor(data: IHarvestBatchTagCollection) {
    this.object = data.object;
    this.data = data.data.map((tag) => new HarvestBatchTagModel(tag));
  }
}

export class HarvestBatchModel {
  public readonly object: string;
  public readonly id: number;
  public readonly batch_number: string;
  public readonly ownable: any;
  public readonly user: UserModel;
  public readonly type: string;
  public readonly status: string;
  public readonly settings: any;
  public readonly context: ContextModel;
  public readonly tags: HarvestBatchTagCollectionModel;
  public readonly created_at: string;

  constructor(data: IHarvestBatch) {
    this.object = data.object;
    this.id = data.id;
    this.batch_number = data.batch_number;
    this.ownable = data.ownable;
    this.user = new UserModel(data.user);
    this.type = data.type;
    this.status = data.status;
    this.settings = data.settings;
    this.context = new ContextModel(data.context);
    this.tags = new HarvestBatchTagCollectionModel(data.tags);
    this.created_at = data.created_at;
  }

  getId(): number {
    return this.id;
  }

  getBatchNumber(): string {
    return this.batch_number;
  }

  getUser() {
    return this.user;
  }

  getType(): string {
    return this.type;
  }

  getTags() {
    return this.tags;
  }

  getContext() {
    return this.context;
  }

  getStatus(): string {
    return this.status;
  }

  getCreatedAt(): string {
    return this.created_at;
  }
}

export class HarvestBatchCollectionModel {
  public readonly object: string;
  public readonly data: HarvestBatchModel[];
  public readonly paginations: IPagination;
  public readonly filters: IFilterType;

  constructor(data: HarvestBatchCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new HarvestBatchModel(item));
    this.paginations = data.paginations;
    this.filters = data.filters;
  }

  getData() {
    return this.data;
  }

  getPaginations() {
    return this.paginations;
  }

  getFilters() {
    return this.filters;
  }
}
