import React, {ReactElement, Suspense, useEffect, useState} from "react";
import {Layout, Button, Row, Col, Spin, Popover, Result} from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LoadingOutlined,
  BulbOutlined,
} from "@ant-design/icons";
import { Sidebar } from "./components";
import styles from "./AntDesignLayout.module.css";
import { Identity, Session } from "@ory/client";
import {
  NotificationBell,
  PopoverNotificationCenter,
} from "components/NovuPackage";
import HarvestJobPopupOver from "pages/HarvestJobManage/components/harvestJobPopupOver";
import ory from "pages/AuthAdmin/pkg/sdk"
import {Link, Outlet} from "react-router-dom";
import {getEnvironmentMode} from "../../utils/helper";

const { Header, Content } = Layout;

const AntDesignLayout: React.FC<{ children?: ReactElement }> = ({
  children,
}) => {
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);

  const [session, setSession] = useState<Session | undefined>()
  const [logoutUrl, setLogoutUrl] = useState<string | undefined>()


  const [showHarvestJobDrawer, setShowHarvestJobDrawer] = useState(false);
  const handleCloseDrawer = () => {
    setShowHarvestJobDrawer(false);
  };

  const isModeProduction =  getEnvironmentMode() !== "development"
  // Returns either the email or the username depending on the user's Identity Schema
  const getUserName = (identity?: Identity) =>
    identity?.traits.email || identity?.traits.username

  // Second, gather session data, if the user is not logged in, redirect to login
  useEffect(() => {

    if(isModeProduction){
      ory
        .toSession()
        .then(({data}) => {
          // User has a session!
          setSession(data)
          ory.createBrowserLogoutFlow().then(({data}) => {
            // Get also the logout url
            setLogoutUrl(data.logout_url)
          })
        })
        .catch((err) => {
          console.error(err)
          // Redirect to login page
          window.location.replace(`/auth/login`)
        })
    }
  }, [])

  const handleMenuCollapsed = () => {
    setIsMenuCollapsed(!isMenuCollapsed);
  };

  if (!session && isModeProduction) {
    return (
      <Row justify={"center"} align="middle">
        <Spin />
      </Row>
    );
  }

  return (
    <Layout className={`${styles.layoutStyle}`}>
      <Sidebar
        isMenuCollapsed={isMenuCollapsed}
        handleCollapsed={handleMenuCollapsed}
      />
      <Layout
        className={`site-layout ${
          isMenuCollapsed
            ? styles.layoutOnSiderCollapse
            : styles.layoutOnSiderExpand
        }`}
      >
        <Header
          className={`site-layout-background ${styles.layoutStyle__header}`}
        >
          <Row>
            <Col span={4}>
              {React.createElement(
                isMenuCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: `trigger ${styles.triggerButton}`,
                  onClick: handleMenuCollapsed,
                },
              )}
            </Col>
            <Col
              lg={{ span: 3, offset: 10 }}
              xs={{ span: 3, offset: 14 }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <p>{getUserName(session?.identity)}</p>
              <Link to={logoutUrl ? logoutUrl : "/auth-admin/login"}>
                {/*<Button*/}
                {/*  className="ant-dropdown-link"*/}
                {/*  onClick={handleLogout}*/}
                {/*  disabled={!session}*/}
                {/*  loading={!session}*/}
                {/*>*/}
                {/*  Ory Logout/ Login*/}
                {/*</Button>*/}
              </Link>

              {/* Notifications */}
              <PopoverNotificationCenter colorScheme="light">
                {({ unseenCount }) => (
                  <NotificationBell unseenCount={unseenCount} />
                )}
              </PopoverNotificationCenter>

              {/* Harvest Job */}
              <Popover
                placement="bottomRight"
                content={
                  <HarvestJobPopupOver
                    open={true}
                    onClose={handleCloseDrawer}
                  />
                }
                trigger="click"
                overlayStyle={{ width: 450 }}
              >
                <Button type="dashed" shape="circle">
                  <BulbOutlined />
                </Button>
              </Popover>

              {showHarvestJobDrawer && (
                <HarvestJobPopupOver
                  open={showHarvestJobDrawer}
                  onClose={handleCloseDrawer}
                />
              )}
            </Col>
          </Row>
        </Header>
        <Content className={`${styles.layoutStyle__content}`}>

          {session && isModeProduction ? (
          <div
            className={`site-layout-background ${styles.layoutStyle__contentAuth}`}
          >
            <Suspense
              fallback={
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              }
            >
              <Outlet/>
            </Suspense>
          </div>
          ) :isModeProduction ?  (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={<Button type="primary">Back Home</Button>}
            />
          ): <div
            className={`site-layout-background ${styles.layoutStyle__contentAuth}`}
          >
            <Suspense
              fallback={
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              }
            >
              <Outlet/>
            </Suspense>
          </div>}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AntDesignLayout;
