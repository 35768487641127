export enum PublishStatusEnum {
  draft = "draft",
  published = "published",
}
export enum CampaignStatusEnum {
  AWAITING_PUBLISH = "awaiting-publish",
  UPCOMING = "upcoming",
  RUNNING = "running",
  PAUSED = "paused",
  END = "end",
}

export enum CampaignProductConfigurationTypeEnum {
  Percentage = "percentage",
  Fixed = "fixed",
}

//Operations

export enum CampaignOperationEnum {
  AddCampaignProduct = "add-campaign-product",
  ViewCampaignProduct = "view-campaign-product",
}
