import { IFilterType } from "types/filters";
import { PaginationModel } from "./paginationModel";
import {
  ICampaignProcuctCollection,
  ICampaignProduct,
  ICampaignProductDetailPrice,
} from "types/campaignProductCollection";
import { ICampaignProductDetail } from "../types/campaignProductCollection";
import {
  ProductGalleryCollectionModel,
  WholesalePriceCollectionModel,
} from "./productDetailExtendedModel";
import {
  ProductMetaModel,
  ProductSpecificationCollectionModel,
  ProductVariationModel,
  ProductVideoCollectionModel,
} from "./productDetailExtendedModel";
import { IProductPriceRangeExtended } from "types/productDetailExtended";

export class CampaignProductDetailPriceModel {
  object: string;
  discount: IProductPriceRangeExtended;
  original: IProductPriceRangeExtended;
  wholesales: WholesalePriceCollectionModel;
  base: {
    max: number;
    min: number;
  };

  constructor(payload: ICampaignProductDetailPrice) {
    this.object = payload.object;
    this.discount = payload.discount;
    this.original = payload.original;
    this.wholesales = new WholesalePriceCollectionModel(payload.wholesales);
    this.base = payload.base;
  }

  getDiscountMin() {
    return this.discount.min;
  }
  getDiscountMax() {
    return this.discount.max;
  }
  getOriginalMin() {
    return this.original.min;
  }
  getOriginalMax() {
    return this.original.max;
  }
  getWholesales() {
    return this.wholesales;
  }
  getHikeMin() {
    return this.base.min;
  }
  getHikeMax() {
    return this.base.max;
  }
}

export class CampaignProductDetailModel {
  object: string;
  id: string;
  etag: string;
  resource_version: string;
  title: string;
  slug: string;
  image: string;
  description: string;
  stock: number;
  sales: number;
  shipping_category: {
    object: string;
    id: string;
    parent_id: string;
    name: string;
    slug: string;
  };
  shop: {
    object: string;
    id: string;
    identifier: string;
  };
  vendor: {
    object: string;
    vid: string;
    link: string;
  };
  seller: {
    object: string;
    id: string;
    name: string;
    link: string;
  };
  // country: IProductDetailExtendedCountry;
  // measurement: ProductMeasurementModel;
  meta: ProductMetaModel;
  gallery: ProductGalleryCollectionModel;
  ratings: {
    object: string;
    frequency: number[];
    count: number;
    average: number;
  };
  specifications: ProductSpecificationCollectionModel;
  price: CampaignProductDetailPriceModel;
  videos: ProductVideoCollectionModel;
  variation: ProductVariationModel;

  constructor(data: ICampaignProductDetail) {
    this.object = data.object;
    this.id = data.id;
    this.etag = data.etag;
    this.resource_version = data.resource_version;
    this.title = data.title;
    this.slug = data.slug;
    this.image = data.image;
    this.description = data.description;
    this.stock = data.stock;
    this.sales = data.sales;
    this.shipping_category = data.shipping_category;
    this.shop = data.shop;
    this.vendor = data.vendor;
    this.seller = data.seller;
    // this.country = new ProductDetailExtendedCountryModel(data.country);
    // this.measurement = new ProductMeasurementModel(data.measurement);
    this.meta = new ProductMetaModel(data.meta);
    this.gallery = new ProductGalleryCollectionModel(data.gallery);
    this.ratings = data.ratings;
    this.specifications = new ProductSpecificationCollectionModel(
      data.specifications,
    );
    this.price = new CampaignProductDetailPriceModel(data.price);
    this.videos = new ProductVideoCollectionModel(data.videos);
    this.variation = new ProductVariationModel(data.variation);
  }

  getTitle() {
    return this.title;
  }

  getSlug() {
    return this.slug;
  }

  getImage() {
    return this.image;
  }

  getId() {
    return this.id;
  }

  getSales() {
    return this.sales;
  }
  getStock = () => this.stock;

  getSellerId() {
    return this.seller.id;
  }

  getSellerName() {
    return this.seller.name;
  }

  getSellerLink() {
    return this.seller.link;
  }

  getVendorLink = () => this.vendor.link;

  getShippingCategoryId() {
    return this.shipping_category.id;
  }

  // getDimension() {
  //   return this.measurement;
  // }

  getGallery() {
    return this.gallery.data;
  }

  getRatingsCount() {
    return this.ratings.count;
  }

  getRatingsAverage() {
    return this.ratings.average;
  }

  getDescription() {
    return this.description;
  }

  getSpecifications() {
    return this.specifications.data;
  }

  getPrice() {
    return this.price;
  }

  getWholesalePriceRange() {
    return this.price.wholesales.data;
  }

  getVariation() {
    return this.variation;
  }

  getVariationPropertiesData() {
    return this.variation.properties.data;
  }

  getVariationSkusData() {
    return this.variation.skus.data;
  }
  getAverageRatings = () => this.ratings.average;
}

export class CampaignProductModel {
  object: string;
  id: string;
  product: CampaignProductDetailModel;
  constructor(data: ICampaignProduct) {
    this.object = data.object;
    this.id = data.id;
    this.product = new CampaignProductDetailModel(data.product);
  }
  getId = () => this.id;
  getProduct = () => this.product;
}

export class CampaignProductCollectionModel {
  object: string;
  data: CampaignProductModel[];
  pagination: PaginationModel;
  filters: IFilterType;

  constructor(data: ICampaignProcuctCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new CampaignProductModel(item));
    this.pagination = new PaginationModel(data.pagination);
    this.filters = data.filters;
  }

  getData() {
    return this.data;
  }

  getCampaignProductById(id: string): CampaignProductModel | undefined {
    return this.data.find((item) => item.getId() === id);
  }

  getPagination() {
    return this.pagination;
  }

  getFilters() {
    return this.filters;
  }
}
