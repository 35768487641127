import Products from "./Products";

const ProductManage = () => {
  return (
    <>
      <Products />
    </>
  );
};

export default ProductManage;
