import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { Col, Skeleton, Typography } from "antd";

import { StyledProductDetails } from "./index.styled";
import { ProductDetailExtendedModel } from "models/productDetailExtendedModel";
import { useGetProductDetails } from "lib/core-react/hooks/private/useProduct";
import { productDetailAtom } from "lib/core-react/store/store";

import Header from "./Header";
import ProductImageSlide from "./ProductImageSlide";
import ProductView from "./ProductView";
import AppRowContainer from "components/App/AppRowContainer";
import AppAnimate from "components/App/AppAnimate";
import AppCard from "components/App/AppCard";
import AppInfoView from "components/App/AppInfoView";
import {
  StyledProductViewTitle,
  StyledStrokeSubtitle,
  StyledProductView,
} from "./ProductView/index.styled";

const ProductDetail = () => {
  const { id } = useParams();
  const { getProductDetails } = useGetProductDetails();

  // For product search by url
  const location = useLocation();
  const url = new URLSearchParams(location.search).get("productUrl");
  const region = new URLSearchParams(location.search).get("region") || "BD";
  const user_id = new URLSearchParams(location.search).get("userId");

  useEffect(() => {
    if (id && id !== "find") {
      getProductDetails(
        {
          region: "BD",
          apply_exchange: 1,
          locale: "bn",
        },
        id,
      );
    }
    if (id && id === "find") {
      getProductDetails(
        {
          region: region,
          apply_exchange: 1,
          locale: "bn",
          user_id,
          url,
        },
        "",
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [{ data, isLoading }] = useAtom(productDetailAtom);

  const productDetail = data && new ProductDetailExtendedModel(data);

  return (
    <StyledProductDetails style={{ marginTop: "16px" }}>
      {isLoading ? (
        <Skeleton active />
      ) : productDetail ? (
        <AppAnimate animation="transition.slideUpIn" delay={200}>
          <AppCard key="product_detail">
            <AppRowContainer>
              <Col sm={24} lg={20}>
                <Header product={productDetail} />
                <StyledProductView>
                  <StyledProductViewTitle>
                    ৳
                    {productDetail.getPrice().getDiscountMin()
                      ? productDetail.getPrice().getDiscountMin()
                      : productDetail.getPrice().getOriginalMin()}
                    <span className="line-through">
                      ৳{productDetail.getPrice().getOriginalMin()}
                    </span>
                  </StyledProductViewTitle>
                </StyledProductView>
                <StyledStrokeSubtitle>
                  {productDetail.getStock() ? "In stock" : "Out of stock"}
                </StyledStrokeSubtitle>
              </Col>
              <Col sm={24} lg={4}>
                <ProductImageSlide product={productDetail} />
              </Col>
            </AppRowContainer>
            <ProductView product={productDetail} />
          </AppCard>
        </AppAnimate>
      ) : (
        <Typography.Text type="danger">Product not found!</Typography.Text>
      )}
      <AppInfoView />
    </StyledProductDetails>
  );
};

export default ProductDetail;
