/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  HddFilled,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import {
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  MenuProps,
  Modal,
  Row,
  Switch,
  Table,
  Tag,
  Typography,
} from "antd";
import { useAtom } from "jotai";
import { MenuInfo } from "rc-menu/lib/interface";

import { formatDateTime } from "utils/helpers";
import { CampaignOperationEnum } from "enums/campaignCollectionEnums";
import { useGetRegion } from "lib/core-react/hooks/private";
import useDataFilters from "hooks/useDataFilters";
import {
  useCampaginList,
  usePauseCampaign,
  useResumeCampaign,
} from "lib/core-react/hooks/private/useCampaign";
import { campaignCollectionAtom } from "lib/core-react/store/store";
import { ApiHelperModel } from "models/apiHelper";
import {
  CampaignCollectionModel,
  CampaignModel,
} from "models/campaignCollectionModel";
import { PaginationModel } from "models/pagination";
import { IFilterType } from "types/filters";

import CreateCampaign from "./components/CreateCampaign";
import UpdateCampaign from "./components/UpdateCampaign";
import DeleteCampaign from "./components/DeleteCampaign";
import { PageHeader } from "@ant-design/pro-layout";
import { v4 } from "uuid";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import FiltersComponent from "components/FiltersComponent";
import { StatusTag } from "components";

const CampaignsAll = () => {
  const navigate = useNavigate();
  const { getCampaign } = useCampaginList();
  const { getPauseCampaign } = usePauseCampaign();
  const { getResumeCampaign } = useResumeCampaign();
  const { getRegion } = useGetRegion();
  const [{ data: campaignsCollection, isLoading, refetch }] = useAtom(
    campaignCollectionAtom,
  );

  const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);
  const [isShowUpdateModal, setIsShowUpdateModal] = useState<boolean>(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);

  const { Text } = Typography;
  const { isMobile } = useWindowWidth();

  const {
    filters,
    handleFilterChange,
    isFirstCall,
    handelFilterClear,
    isFetched,
    initializeAvailableFilter,
    refetch: refetchFromFilter,
  } = useDataFilters();

  // Api Call
  useEffect(() => {
    if ((!isFetched && isFirstCall) || refetch || refetchFromFilter) {
      ApiHelperModel.makeGetRequest({}, getCampaign);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstCall, isFetched, refetch, refetchFromFilter]);

  // Api Call
  useEffect(() => {
    if ((filters && !isFetched && isFirstCall) || refetch) {
      filters && ApiHelperModel.makeGetRequest(filters, getRegion);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstCall, filters, isFetched, refetch]);

  const handleSwitchChange = (checked: boolean, record: CampaignModel) => {
    if (checked) {
      getResumeCampaign(record.id);
    } else {
      getPauseCampaign(record.id);
    }
  };

  const CampignCollectionData =
    campaignsCollection && new CampaignCollectionModel(campaignsCollection);

  const [selectedCampaign, setSelectedCampaign] = useState<
    CampaignModel | undefined
  >(undefined);

  const paginationData = CampignCollectionData?.getPaginations();

  const filterData = CampignCollectionData?.getFilter();

  const handleMenuClick = (e: MenuInfo) => {
    if (e.key === "update") {
      setIsShowUpdateModal(true);
    }
    if (e.key === "delete") {
      setIsShowDeleteModal(true);
    }
    if (e.key === CampaignOperationEnum.AddCampaignProduct) {
      navigate(
        `/product-manage/products?operation=${
          CampaignOperationEnum.AddCampaignProduct
        }&region=${selectedCampaign
          ?.getRegion()
          .getCode()}&campaignId=${selectedCampaign?.getId()}`,
      );
    }
    if (e.key === CampaignOperationEnum.ViewCampaignProduct) {
      navigate(
        `/product-manage/products?operation=${
          CampaignOperationEnum.ViewCampaignProduct
        }&id=${selectedCampaign?.getId()}`,
      );
    }
  };

  // Getting all available filters
  useEffect(() => {
    if (!isFetched && CampignCollectionData?.getFilter()) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [
    isFetched,
    initializeAvailableFilter,
    CampignCollectionData?.getFilter(),
  ]);

  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getCampaign);
  };

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getCampaign,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    CampignCollectionData,
    handlePaginationChange,
  );

  const columns = [
    {
      title: "#SL",
      dataIndex: "sl",
      key: "sl",
      align: "center" as const,
      width: 70,
      render: (_: string, __: CampaignModel, index: number) => {
        return (
          <div>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </div>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      hidden: isMobile,
      align: "center" as const,
      render: (_: string, record: CampaignModel, index: number) => {
        return (
          <div>
            <Text>{record.getTitle()}</Text>
          </div>
        );
      },
    },
    {
      title: "Campaign Details",
      dataIndex: "title",
      hidden: !isMobile,
      key: "title",
      render: (_: string, record: CampaignModel, index: number) => {
        return (
          <>
            <div style={{ marginBottom: 10 }}>
              <Text>{record.getTitle()}</Text>
            </div>
            <div style={{ marginBottom: 10 }}>
              Region: <Tag color="geekblue">{record.getRegion().getCode()}</Tag>
            </div>
            <div style={{ marginBottom: 10 }}>
              Slug: <Tag color="cyan">{record.getSlug()}</Tag>
            </div>
            <div style={{ marginBottom: 10 }}>
              Status:{" "}
              <StatusTag
                text={record.getPublishStatus()}
                slug={record.getPublishStatus()}
              />
            </div>
            <div>
              Campaign Status:{" "}
              <StatusTag
                text={record.getCampainStatus()}
                slug={record.getCampainStatus()}
              />
            </div>
          </>
        );
      },
    },
    {
      title: "Slug",
      dataIndex: "slug",
      hidden: isMobile,
      key: "slug",
      align: "center" as const,
      render: (_: string, record: CampaignModel, index: number) => {
        return (
          <div>
            <Tag color="cyan">{record.getSlug()}</Tag>
          </div>
        );
      },
    },
    {
      title: "Publishing Status",
      dataIndex: "publishing_status",
      key: "publishing_status",
      hidden: isMobile,
      align: "center" as const,
      render: (_: string, record: CampaignModel, index: number) => {
        return (
          <div>
            <StatusTag
              text={record.getPublishStatus()}
              slug={record.getPublishStatus()}
            />
          </div>
        );
      },
    },
    {
      title: "Campaign Status",
      dataIndex: "campaign_status",
      hidden: isMobile,
      key: "campaign_status",
      align: "center" as const,
      render: (_: string, record: CampaignModel) => {
        return (
          <div>
            <StatusTag
              text={record.getCampainStatus()}
              slug={record.getCampainStatus()}
            />
          </div>
        );
      },
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
      hidden: isMobile,
      align: "center" as const,
      render: (_: string, record: CampaignModel) => {
        return (
          <div>
            <Tag color="geekblue">{record.getRegion().getCode()}</Tag>
          </div>
        );
      },
    },
    {
      title: "Start - Finish",
      dataIndex: "start_at",
      key: "start_at",
      align: "center" as const,
      render: (_: string, record: CampaignModel) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div>
              Start:{" "}
              <Tag color="purple">{formatDateTime(record.getStartAt())}</Tag>
            </div>
            <Divider style={{ margin: "8px 0" }} />
            <div>
              Finish:{" "}
              <Tag color="purple">{formatDateTime(record.getFinishAt())}</Tag>
            </div>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      hidden: isMobile,
      align: "center" as const,
      render: (_: string, record: CampaignModel) => (
        <Switch
          checkedChildren="Resume"
          unCheckedChildren="Pause"
          defaultChecked={record.getCampainStatus() === "running"}
          onChange={(checked) => handleSwitchChange(checked, record)}
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      fixed: isMobile ? "right" : undefined,
      align: "center" as const,
      width: 100,
      render: (_: string, record: CampaignModel) => {
        const items: MenuProps["items"] = [
          {
            icon: <EditOutlined />,
            label: "Update Campaign",
            key: "update",
          },
          {
            icon: <DeleteOutlined />,
            label: "Delete Campaign",
            key: "delete",
          },
          {
            icon: <PlusOutlined />,
            label: "Add Product",
            key: CampaignOperationEnum.AddCampaignProduct,
          },
          {
            icon: <SearchOutlined />,
            label: "View Products",
            key: CampaignOperationEnum.ViewCampaignProduct,
          },
        ];

        if (isMobile) {
          items.push({
            label: (
              <Switch
                checkedChildren="Resume"
                unCheckedChildren="Pause"
                defaultChecked={record.getCampainStatus() === "running"}
                onChange={(checked) => handleSwitchChange(checked, record)}
              />
            ),
            key: "status",
          });
        }

        return (
          <Dropdown
            menu={{ items: items, onClick: handleMenuClick }}
            onOpenChange={() => {
              setSelectedCampaign(record);
            }}
          >
            <Button icon={<HddFilled />}>
              {isMobile ? (
                <DownOutlined />
              ) : (
                <>
                  Actions <DownOutlined />{" "}
                </>
              )}
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <PageHeader
          ghost={false}
          title="All Campaigns"
          style={{ marginTop: "10px" }}
          extra={[
            <Button
              key={v4()}
              onClick={() => {
                setIsShowCreateModal(true);
              }}
              icon={<PlusOutlined />}
              type="primary"
            >
              Create a Campaign
            </Button>,
          ]}
        >
          {filters && Object.keys(filters).length > 0 && (
            <Row>
              <Col span={24}>
                <Card title="Filter">
                  <FiltersComponent
                    handleProductFilter={handleProductFilter}
                    handleFilterChange={handleFilterChange}
                    handelFilterClear={handelFilterClear}
                    isFetched={isFetched}
                    filters={filters}
                    filtersData={filterData}
                    isFromProductReceived={true}
                  />
                </Card>
              </Col>
            </Row>
          )}
          <div>
            <Table
              style={{ marginTop: 10 }}
              loading={isLoading}
              rowKey="id"
              bordered={true}
              dataSource={CampignCollectionData?.getData()}
              //@ts-ignore
              columns={columns}
              pagination={paginationConfig}
              scroll={{ x: 1257 }}
            />
          </div>
        </PageHeader>
      </div>

      {selectedCampaign && (
        <Modal
          title={`Update Campaign`}
          open={isShowUpdateModal}
          footer={false}
          onCancel={() => setIsShowUpdateModal(false)}
        >
          <UpdateCampaign
            selectedCampaign={selectedCampaign}
            setIsShowUpdateModal={setIsShowUpdateModal}
          />
        </Modal>
      )}

      {selectedCampaign && (
        <Modal
          title="Delete Campaign"
          open={isShowDeleteModal}
          onCancel={() => setIsShowDeleteModal(false)}
          footer={null}
        >
          <DeleteCampaign
            setIsShowDeleteModal={setIsShowDeleteModal}
            selectedCampaign={selectedCampaign}
          />
        </Modal>
      )}

      <Modal
        title={`Create Campaign`}
        open={isShowCreateModal}
        footer={false}
        onCancel={() => setIsShowCreateModal(false)}
      >
        <CreateCampaign setIsShowCreateModal={setIsShowCreateModal} />
      </Modal>
    </>
  );
};

export default CampaignsAll;
