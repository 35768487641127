import { Col, DatePicker, Form, Input, Select } from "antd";
import { useAtomValue } from "jotai";
import { ReactNode, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  useGetAgentCompany,
  useGetAgentWarehouse,
  useGetCurrency,
  useGetDestinationWarehouse,
  useGetLanguage,
  useGetShippingCategory,
  useGetShippingCountry,
  useGetStore,
} from "lib/core-react/hooks/private";
import {
  useGetAdmins,
  useGetCustomers,
} from "lib/core-react/hooks/private/useBulkUser";
import { useGetCountry } from "lib/core-react/hooks/private/useCountry";
import { useGetRegion } from "lib/core-react/hooks/private/useRegion";
import {
  agentCompanyCollectionAtom,
  agentWarehouseCollectionAtom,
  destinationWarehouse,
  exchangeGroupCollectionAtom,
  regionCountry,
  regionCurrency,
  regionLanguage,
  regionRegion,
  shippingCountry,
  storeStoreAtom,
} from "lib/core-react/store/store";
import { IFilterType } from "types/filters";
import { UserSelect } from "./UserSelect";
import ShippingCategoryTreeSelect from "./ShippingCategoryTreeSelect";
import { useAtom } from "jotai";
import { useGetExchangeGroup } from "../../lib/core-react/hooks/private/useExchangeGroup";
import { filterResourceEnum } from "enums/filterResourceEnum";
const { Option } = Select;
const { RangePicker } = DatePicker;

interface ICustomElementProps {
  type: string;
  label: string;
  name: string;
  placeholder: string | null;
  handleChange: (fields: IFilterType) => void;
  fieldValue: any;
  options: any[] | IFilterType | undefined;
  resource: string | null;
  data: string | null;
}

const CustomElement = ({
  type,
  label,
  name,
  placeholder,
  handleChange,
  fieldValue,
  options,
  resource,
  data,
}: ICustomElementProps) => {
  const { getCountry } = useGetCountry();
  const { getRegion } = useGetRegion();
  const { getCurrency } = useGetCurrency();
  const { getAgentCompanies } = useGetAgentCompany();
  const { getLanguage } = useGetLanguage();
  const { getDestinationWarehouse } = useGetDestinationWarehouse();
  const { getAdmins } = useGetAdmins();
  const { getCustomers } = useGetCustomers();
  const { getExchangeGroup } = useGetExchangeGroup();
  const { getShippingCategory } = useGetShippingCategory();
  const { getShippingCountry } = useGetShippingCountry();
  const navigate = useNavigate();
  const location = useLocation();
  const { getStore } = useGetStore();
  const { getAgentWarehouses } = useGetAgentWarehouse();

  const { data: countryDataAtom, isLoading: countryLoading } =
    useAtomValue(regionCountry);

  const { data: agentCompanyDataAtom } = useAtomValue(
    agentCompanyCollectionAtom,
  );
  const { data: regionDataAtom, isLoading: regionLoading } =
    useAtomValue(regionRegion);

  const { data: currencyDataAtom, isLoading: currencyLoading } =
    useAtomValue(regionCurrency);

  const { data: languageDataAtom, isLoading: languageLoading } =
    useAtomValue(regionLanguage);
  const {
    data: destinationWarehouseData,
    isLoading: destinationWarehouseDataLoading,
  } = useAtomValue(destinationWarehouse);

  const { data: stores, isLoading: storeLoading } =
    useAtomValue(storeStoreAtom);

  const [{ data: exchangeGroupStoreData }] = useAtom(
    exchangeGroupCollectionAtom,
  );

  const [{ data: agentWarehouseStoreData, isLoading: warehouseLoading }] =
    useAtom(agentWarehouseCollectionAtom);

  const { data: shippingCountryData, isLoading: shippingCountryDataLoading } =
    useAtomValue(shippingCountry);

  useEffect(() => {
    const fetchData = async () => {
      if (data === "external") {
        switch (resource) {
          case filterResourceEnum.COUNTRY:
            await getCountry("per_page=200");
            break;

          case filterResourceEnum.AGENT_WAREHOUSE:
            await getAgentWarehouses("per_page=200");
            break;

          case filterResourceEnum.REGION:
            await getRegion("per_page=200");
            break;

          // case "language":
          //   await getLanguage("per_page=200");
          //   break;

          case filterResourceEnum.DESTINATION_WAREHOUSE:
            await getDestinationWarehouse("per_page=200");
            break;

          case filterResourceEnum.ADMIN:
            await getAdmins("per_page=200");
            break;

          case filterResourceEnum.CUSTOMER:
          case filterResourceEnum.USER:
            await getCustomers("per_page=200");
            break;

          case filterResourceEnum.SHIPPING_CATEGORY:
            await getShippingCategory("per_page=200");
            break;

          case filterResourceEnum.SHIPPING_COUNTRY:
            await getShippingCountry("per_page=200");
            break;

          case filterResourceEnum.CURRENCY:
            await getCurrency("per_page=200");
            break;

          case filterResourceEnum.AGENT_COMPANY:
            await getAgentCompanies("per_page=200");
            break;

          case filterResourceEnum.STORE:
            await getStore("per_page=200");
            break;

          case filterResourceEnum.EXCHANGE_GROUP:
            await getExchangeGroup("per_page=200");
            break;
          default:
            break;
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, resource]);

  let fieldInput: ReactNode;

  if (type === "array") {
    fieldInput = (
      <Select
        showSearch
        value={fieldValue}
        onChange={(value) => handleChange({ [name]: value })}
        mode="multiple"
        placeholder={placeholder}
        filterOption={(input, option) =>
          option && typeof option.children === "string"
            ? (option.children as String)
                .toLowerCase()
                .includes(input.toLowerCase())
            : false
        }
      >
        {Object.keys(options as IFilterType).map((opt, idx) => (
          <Option key={`${name}-${idx}`} value={opt}>
            {(options as IFilterType)[opt]}
          </Option>
        ))}
      </Select>
    );
  } else if (type === "daterange") {
    // let defaultDates;
    // if (fieldValue) {
    //   defaultDates = fieldValue.map((dVal: Moment) => moment(dVal));
    // }

    fieldInput = (
      <RangePicker
        showTime={{ format: "YYYY-MM-DD HH:mm:ss" }}
        style={{ width: "100%" }}
        // value={defaultDates || null}
        onChange={(_, dateString) => {
          const isEmptyArray = dateString.every((item) => item === "");
          if (isEmptyArray) {
            navigate(location.pathname);
          } else {
            return handleChange({ [name]: dateString });
          }
        }}
      />
    );
  } else if (type === "external_array") {
    fieldInput = (
      <Select
        value={fieldValue}
        onChange={(value) => handleChange({ [name]: value })}
        mode="multiple"
        placeholder={placeholder}
      >
        {(options as any[]).map((opt, idx) => (
          <Option key={`${name}-${idx}`} value={opt.id}>
            {opt.name}
          </Option>
        ))}
      </Select>
    );
  } else if (type === "select") {
    if (Array.isArray(options)) {
      if (options && !resource) {
        fieldInput = (
          <Select
            value={fieldValue}
            allowClear
            onChange={(value) => handleChange({ [name]: value })}
            placeholder={placeholder}
          >
            {options?.map((data) => (
              <Option key={`${data.value}`} value={data.value}>
                {data.label}
              </Option>
            ))}
          </Select>
        );
      }
    } else {
      if (options && !resource) {
        fieldInput = (
          <Select
            value={fieldValue}
            allowClear
            onChange={(value) => handleChange({ [name]: value })}
            placeholder={placeholder}
          >
            {Object.keys(options as IFilterType).map((opt, idx) => (
              <Option key={`${name}-${idx}`} value={opt}>
                {(options as IFilterType)[opt]}
              </Option>
            ))}
          </Select>
        );
      } else {
        if (resource === filterResourceEnum.COUNTRY) {
          fieldInput = (
            <Select
              loading={countryLoading}
              value={fieldValue}
              allowClear
              onChange={(value) => handleChange({ [name]: value })}
              placeholder={placeholder}
            >
              {countryDataAtom?.data.map((data) => (
                <Option key={`${data.name}-${data.id}`} value={data.id}>
                  {data.name}
                </Option>
              ))}
            </Select>
          );
        } else if (resource === filterResourceEnum.REGION) {
          fieldInput = (
            <Select
              loading={regionLoading}
              value={fieldValue}
              allowClear
              onChange={(value) => handleChange({ [name]: value })}
              placeholder={placeholder}
            >
              {regionDataAtom?.data.map((data) => (
                <Option key={`${data.name}-${data.id}`} value={data.id}>
                  {data.name}
                </Option>
              ))}
            </Select>
          );
        } else if (resource === filterResourceEnum.AGENT_COMPANY) {
          fieldInput = (
            <Select
              loading={regionLoading}
              value={fieldValue}
              allowClear
              onChange={(value) => handleChange({ [name]: value })}
              placeholder={placeholder}
            >
              {agentCompanyDataAtom?.data.map((data) => (
                <Option key={`${data.primary_name}-${data.id}`} value={data.id}>
                  {data.primary_name}
                </Option>
              ))}
            </Select>
          );
        } else if (resource === filterResourceEnum.STORE) {
          fieldInput = (
            <Select
              loading={storeLoading}
              value={fieldValue}
              allowClear
              onChange={(value) => handleChange({ [name]: value })}
              placeholder={placeholder}
            >
              {stores?.data.map((data) => (
                <Option key={`${data.name}-${data.id}`} value={data.id}>
                  {data.name}
                </Option>
              ))}
            </Select>
          );
        } else if (resource === filterResourceEnum.CURRENCY) {
          fieldInput = (
            <Select
              loading={currencyLoading}
              value={fieldValue}
              allowClear
              onChange={(value) => handleChange({ [name]: value })}
              placeholder={placeholder}
              onFocus={async () => {
                if (!currencyDataAtom) {
                  await getCurrency(`per_page=200`);
                }
              }}
            >
              {currencyDataAtom?.data.map((data) => (
                <Option key={`${data.name}-${data.id}`} value={data.id}>
                  {data.name}
                </Option>
              ))}
            </Select>
          );
        } else if (resource === filterResourceEnum.WAREHOUSE) {
          fieldInput = (
            <Select
              loading={warehouseLoading}
              value={fieldValue}
              allowClear
              onChange={(value) => handleChange({ [name]: value })}
              placeholder={placeholder}
            >
              {agentWarehouseStoreData?.data.map((data) => (
                <Option key={`${data.name}-${data.id}`} value={data.id}>
                  {data.name}
                </Option>
              ))}
            </Select>
          );
        } else if (resource === filterResourceEnum.DESTINATION_WAREHOUSE) {
          fieldInput = (
            <Select
              loading={destinationWarehouseDataLoading}
              value={fieldValue}
              allowClear
              onChange={(value) => handleChange({ [name]: value })}
              placeholder={placeholder}
            >
              {destinationWarehouseData?.data.map((data) => (
                <Option key={`${data.name}-${data.id}`} value={data.id}>
                  {data.name}
                </Option>
              ))}
            </Select>
          );
        }
        //  else if (resource === "language") {
        //   fieldInput = (
        //     <Select
        //       loading={languageLoading}
        //       value={fieldValue}
        //       allowClear
        //       onChange={(value) => handleChange({ [name]: value })}
        //       placeholder={placeholder}
        //     >
        //       {languageDataAtom?.data.map((data) => (
        //         <Option key={`${data.name}-${data.id}`} value={data.id}>
        //           {data.name}
        //         </Option>
        //       ))}
        //     </Select>
        //   );
        // }
        else if (resource === filterResourceEnum.EXCHANGE_GROUP) {
          fieldInput = (
            <Select
              loading={languageLoading}
              value={fieldValue}
              allowClear
              onChange={(value) => handleChange({ [name]: value })}
              placeholder={placeholder}
            >
              {exchangeGroupStoreData?.data.map((data) => (
                <Option key={`${data.name}-${data.id}`} value={data.id}>
                  {data.name}
                </Option>
              ))}
            </Select>
          );
        } else if (resource === filterResourceEnum.ADMIN) {
          fieldInput = (
            <UserSelect
              resource={resource}
              value={fieldValue}
              allowClear={true}
              showSearch={true}
              placeholder={placeholder}
              onChange={(value) => handleChange({ [name]: value.label })}
              style={{ width: "100%" }}
            />
            // <Select
            //   loading={bulkUserAdminDataLoading}
            //   value={fieldValue}
            //   showSearch
            //   allowClear
            //   onChange={(value) => handleChange({ [name]: value })}
            //   placeholder={placeholder}
            // >
            //   {name === 'name'
            //     ? bulkUserAdminData?.data.map((data) => (
            //         <Option key={`${data.name}-${data.id}`} value={data.name}>
            //           {data.name}
            //         </Option>
            //       ))
            //     : bulkUserAdminData?.data.map((data) => (
            //         <Option key={`${data.name}-${data.id}`} value={data.id}>
            //           {data.name}
            //         </Option>
            //       ))}
            // </Select>
          );
        } else if (
          resource === filterResourceEnum.CUSTOMER ||
          resource === filterResourceEnum.USER
        ) {
          fieldInput = (
            <UserSelect
              resource={resource}
              value={fieldValue}
              allowClear={true}
              showSearch={true}
              placeholder={placeholder}
              onChange={(value) => handleChange({ [name]: value.label })}
              style={{ width: "100%" }}
            />
          );
        } else if (resource === filterResourceEnum.SHIPPING_CATEGORY) {
          fieldInput = (
            <ShippingCategoryTreeSelect
              resource={resource}
              value={fieldValue}
              allowClear={true}
              showSearch={true}
              placeholder={placeholder}
              onChange={(value) => handleChange({ [name]: value.value })}
              style={{ width: "100%" }}
            />
          );
        } else if (resource === filterResourceEnum.SHIPPING_COUNTRY) {
          fieldInput = (
            <Select
              loading={shippingCountryDataLoading}
              value={fieldValue}
              allowClear
              onChange={(value) => handleChange({ [name]: value })}
              placeholder={placeholder}
            >
              {shippingCountryData?.data.map((data) => (
                <Option key={`${data.id}`} value={data.id}>
                  {data.country?.name}
                </Option>
              ))}
            </Select>
          );
        }
      }
    }
  } else if (type === "multiselect") {
    if (options && !resource) {
      fieldInput = (
        <Select
          mode="multiple"
          value={fieldValue}
          style={{ width: "100%" }}
          placeholder={placeholder}
          onChange={(value) => handleChange({ [name]: value })}
          optionLabelProp="label"
        >
          {options.map((option: { [key: string]: string }) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      );
    }
  } else if (type === "pricerange") {
    let makePriceOptions = {
      1: "1",
      10: "10",
      50: "50",
      100: "100",
      300: "300",
      500: "500",
      1000: "1000",
      5000: "5000",
      10000: "10000",
    };
    fieldInput = (
      <Select
        value={fieldValue}
        onChange={(value) => handleChange({ [name]: value })}
        mode="multiple"
        placeholder={placeholder}
      >
        {Object.keys(makePriceOptions).map((opt, idx) => (
          <Option key={`${name}-${idx}`} value={opt}>
            {opt}
          </Option>
        ))}
      </Select>
    );
  } else {
    fieldInput = (
      <Input
        placeholder={placeholder || undefined}
        value={fieldValue}
        onChange={(e) => handleChange({ [name]: e.target.value })}
      />
    );
  }

  return (
    <Col xs={24} md={12} xl={8} xxl={6}>
      <Form.Item label={label}>{fieldInput}</Form.Item>
    </Col>
  );
};

export default CustomElement;
