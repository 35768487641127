import React from 'react'
import {Col, Descriptions, Divider, Row, Spin, Typography,} from 'antd'
import {DateTime, StatusTag} from "components"

const {Title} = Typography;

const ShipmentInvoiceDetails = props => {
    const {response: invoice, isResponseLoading} = props

    if (isResponseLoading){
        return (
            <Spin/>
        )
    }

    const buyInvoicesAddress = invoice?.order?.addresses

    return (
        <>
            <Title level={4} style={{color: '#2db7f5'}}>Invoice Info</Title>
            <Divider/>
            <Row>
                <Col>
                    <Descriptions
                        title="Buy Invoice:"
                        column={{md: 4, sm: 2, xs: 1}}
                        key={invoice.id}
                    >
                        <Descriptions.Item label="Invoice Number">{invoice.invoice_code}</Descriptions.Item>
                        <Descriptions.Item label="Total Amount">{invoice.amount_total.toFixed(2)}</Descriptions.Item>
                        <Descriptions.Item label="Paid Amount">{invoice.amount_paid}</Descriptions.Item>
                        <Descriptions.Item
                            label="Due Amount">{(invoice.amount_total - invoice.amount_paid).toFixed(2)}</Descriptions.Item>
                        <Descriptions.Item label="Invoice To">{invoice.invoice_to}</Descriptions.Item>
                        <Descriptions.Item label="Status">
                            <StatusTag text={invoice.status?.name} slug={invoice?.status?.slug}/>
                        </Descriptions.Item>
                        <Descriptions.Item label="Paid Time">
                            <DateTime date={invoice.paid_time}/>
                        </Descriptions.Item>
                        <Descriptions.Item label="Settled Time">
                            <DateTime date={invoice.settled_time}/>
                        </Descriptions.Item>
                        <Descriptions.Item label="Creation Time">
                            <DateTime date={invoice.created_at}/>
                        </Descriptions.Item>
                    </Descriptions>
                    {
                        buyInvoicesAddress && buyInvoicesAddress.billing &&
                        <Descriptions
                            title="Billing IAddress:"
                            column={{md: 4, sm: 2, xs: 1}}
                        >
                            <Descriptions.Item label="Name">{buyInvoicesAddress.billing.name}</Descriptions.Item>
                            <Descriptions.Item label="Phone">{buyInvoicesAddress.billing.phone}</Descriptions.Item>
                            <Descriptions.Item label="Country">{buyInvoicesAddress.billing.country}</Descriptions.Item>
                            <Descriptions.Item label="IAddress">
                                {buyInvoicesAddress.billing.address}, {buyInvoicesAddress.billing.area.name}, {buyInvoicesAddress.billing.district.name}, {buyInvoicesAddress.billing.postal_code}
                            </Descriptions.Item>
                        </Descriptions>
                    }
                    {
                      buyInvoicesAddress && buyInvoicesAddress.shipping &&
                        <Descriptions
                            title="Shipping IAddress:"
                            column={{md: 4, sm: 2, xs: 1}}
                        >
                            <Descriptions.Item label="Name">{buyInvoicesAddress.shipping.name}</Descriptions.Item>
                            <Descriptions.Item label="Phone">{buyInvoicesAddress.shipping.phone}</Descriptions.Item>
                            <Descriptions.Item label="Country">{buyInvoicesAddress.shipping.country}</Descriptions.Item>
                            <Descriptions.Item label="IAddress">
                                {buyInvoicesAddress.shipping.address}, {buyInvoicesAddress.shipping.area.name}, {buyInvoicesAddress.shipping.district.name}, {buyInvoicesAddress.shipping.postal_code}
                            </Descriptions.Item>
                        </Descriptions>
                    }
                </Col>
            </Row>
        </>
    )
}

export default ShipmentInvoiceDetails
