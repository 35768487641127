/* eslint-disable react-hooks/exhaustive-deps */

import { Form, Input, Select } from "antd";
import { renderOptionsFromEnum } from "components/Form/forms";

import {
  RegionCode,
  RegionDataStatus,
  RegionStoreType,
} from "enums/regionCollectionEnums";
import { useAtom } from "jotai";
import { useGetCurrency, useGetLanguage } from "lib/core-react/hooks/private";
import { useGetCountry } from "lib/core-react/hooks/private/useCountry";
import {
  regionCountry,
  regionCurrency,
  regionLanguage,
} from "lib/core-react/store/store";
import { CountryModel } from "models/country";
import { CurrencyModel } from "models/currency";
import { LanguageModel } from "models/language";
import { RegionDataModel } from "models/region";
import { useEffect } from "react";

interface IProps {
  data?: RegionDataModel;
  form: any;
  onFinish: (values: any) => Promise<void>;
}

const RegionModifications = ({ data, form, onFinish }: IProps) => {
  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  // Country, Currency, Language
  const { getCountry } = useGetCountry();
  const { getCurrency } = useGetCurrency();
  const { getLanguage } = useGetLanguage();
  const [{ data: countryDataAtom, isLoading: countryLoading }] =
    useAtom(regionCountry);
  const [{ data: currencyDataAtom, isLoading: currencyLoading }] =
    useAtom(regionCurrency);
  const [{ data: languageDataAtom, isLoading: languageLoading }] =
    useAtom(regionLanguage);
  useEffect(() => {
    getCountry("per_page=200");
    getCurrency("per_page=200");
    getLanguage("per_page=200");
  }, []);
  // @ts-ignore FIXME
  const countryData = countryDataAtom && new CountryModel(countryDataAtom);
  const currencyData = currencyDataAtom && new CurrencyModel(currencyDataAtom);
  const languageData = languageDataAtom && new LanguageModel(languageDataAtom);

  return (
    <>
      <div>
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          name="Add region"
          initialValues={{
            name: data?.name,
            code: data?.code,
            status: data?.status,
            store: data?.store,
            store_type: data?.store_type,
            currency_id: data?.currency?.id?.toString(),
            country_ids: data?.countries?.data.map((country) => country.id?.toString()),
            language_ids: data?.languages?.data.map((language) => language.id?.toString()),
          }}
        >
          <Form.Item
            rules={[{ required: true, message: "Region name is required" }]}
            label="Name"
            name="name"
          >
            <Input placeholder="Region name" />
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: "Region code is required" }]}
            label="Code"
            name="code"
          >
            <Select placeholder="Please select a region code" disabled={!!data}>
              {renderOptionsFromEnum(RegionCode)}
            </Select>
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: "Store name is required" }]}
            label="Store"
            name="store"
          >
            <Input placeholder="Store name" />
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: "Store type is required" }]}
            name="store_type"
            label="Store Type"
          >
            <Select placeholder="Please select a Store Type">
              {renderOptionsFromEnum(RegionStoreType)}
            </Select>
          </Form.Item>

          {/* Currency Id */}
          <Form.Item
            rules={[{ required: true, message: "Currency is required" }]}
            name="currency_id"
            label="Currency"
          >
            <Select
              loading={currencyLoading}
              placeholder="Please select a currency"
            >
              {currencyData?.getCurrencyId() &&
                renderOptionsFromEnum(currencyData?.getCurrencyId())}
            </Select>
          </Form.Item>

          {/* Country ID */}
          <div>
            <Form.Item
              rules={[{ required: true, message: "Country is required" }]}
              name="country_ids"
              label="Country"
            >
              <Select
                mode="multiple"
                loading={countryLoading}
                placeholder="Please select countries"
              >
                {countryData?.getCountryCode() &&
                  renderOptionsFromEnum(countryData?.getCountryId())}
              </Select>
            </Form.Item>
          </div>
          {/* Language */}
          <div>
            <Form.Item
              rules={[{ required: true, message: "Language is required" }]}
              name="language_ids"
              label="Language"
            >
              <Select
                mode="multiple"
                loading={languageLoading}
                placeholder="Please select languages"
              >
                {languageData?.getLanguageId() &&
                  renderOptionsFromEnum(languageData?.getLanguageId())}
              </Select>
            </Form.Item>
          </div>

          <Form.Item
            rules={[
              {
                required: true,
                message: "Status is required",
              },
            ]}
            name="status"
            label="Status"
          >
            <Select placeholder="Please select a status">
              {renderOptionsFromEnum(RegionDataStatus)}
            </Select>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default RegionModifications;
