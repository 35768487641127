import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  message,
  Skeleton
} from "antd";
import { useAtom } from "jotai";

import {
  useDwAddressCreate,
  useDwAddressUpdate,
  useGetAddressV2,
} from "lib/core-react/hooks/private/useAddress";
import { useGetCountry } from "lib/core-react/hooks/private";
import { addressSchemaAtom, regionCountry } from "lib/core-react/store/store";
import { useDwAddressSchema } from "lib/core-react/hooks/private/useAddressSchema";
import { AddressSchemaModel } from "models/addressSchemaModel";
import { IDestinationWarehouseAddressForm } from "types/destinationWarehouseCollection";
import { AddressModel } from "models/addressCollectionModel";
import { DestinationWarehouseModel } from "models/destinationWarehouseCollectionModel";

interface IProps {
  destinationWarehouse: DestinationWarehouseModel;
  data?: AddressModel;
  onHide: () => void;
}

export const DwAddressModification = ({
  destinationWarehouse,
  data,
  onHide,
}: IProps) => {
  const [form] = Form.useForm();
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const { getCountry } = useGetCountry();
  const { getAddress } = useGetAddressV2();
  const [{ data: countryCollectionAtom, isLoading: isLoadingCountry }] =
    useAtom(regionCountry);

  const { createAddress, isError, isLoading } = useDwAddressCreate();
  const {
    updateAddress,
    isError: isErrorUpdate,
    isLoading: isLoadingUpdate,
  } = useDwAddressUpdate();

  const { getAddressSchema } = useDwAddressSchema();

  const [{ data: addressSchema, isLoading: isLoadingSchema }] =
    useAtom(addressSchemaAtom);

  const addressSchemaData =
    addressSchema && new AddressSchemaModel(addressSchema);

  useEffect(() => {
    form.resetFields();
  }, []);

  useEffect(() => {
    getAddressSchema(destinationWarehouse.getCountry().getCode(), "en");
  }, [destinationWarehouse]);

  useEffect(() => {
    if (data) {
      const fieldsValue = {};
      Object.keys(data.address).forEach((key) => {
        fieldsValue[key] = data.address[key];
      });
      form.setFieldsValue(fieldsValue);
      form.setFieldValue("is_default_shipping", data.getIsDefaultShipping());
    }
  }, [data]);

  const onFinishInvite = async (values: any) => {
    const { is_default_shipping, ...restOfValues } = values;
    try {
      const payload: IDestinationWarehouseAddressForm = {
        destination_warehouse_id: destinationWarehouse.getId(),
        is_default_shipping,
        address: restOfValues,
      };
      !data && (await createAddress(payload));
      data && (await updateAddress(data.getId(), payload));
      getAddress(
        `countries[0]=${destinationWarehouse.getCountry().getCode()}&destination_warehouse_id=${destinationWarehouse.getId()}`,
      );
      message.success(
        data ? "Address updated successfully" : "Address created successfully",
      );
      onHide();
      form.resetFields();
      setIsErrorVisible(false);
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  const webFull = addressSchemaData?.getData().getWebFull() || {};
  const fields = addressSchemaData?.getData().getFields() || {};


  if(isLoadingSchema){

    return <Skeleton active  paragraph={{ rows: 6 }}  />
  }
  return !isLoadingSchema && !addressSchemaData ? (
    <div style={{ color: "red" }}>Address schema not found!</div>
  ) : (
    <Form
      onFinish={onFinishInvite}
      form={form}
      layout="vertical"
      name="Add address"
    >
      {Object.entries(webFull).map(([line, fieldsArray]) => (
        <Row gutter={16} key={line}>
          {fieldsArray.map((fieldName, index) => {
            const fieldData = fields[fieldName];
            const {
              type,
              place_holder,
              label,
              is_required,
              data,
              data_source,
              resource,
            } = fieldData;
            let fieldComponent: React.ReactNode;

            switch (type) {
              case "string":
                fieldComponent = <Input placeholder={place_holder} />;
                break;
              case "textarea":
                fieldComponent = <Input.TextArea placeholder={place_holder} />;
                break;
              case "select":
                if (data_source !== "external") {
                  fieldComponent = (
                    <Select key={index} placeholder={place_holder}>
                      {Object.entries(data || {})
                        .map(([value, label]) => ({
                          value,
                          label,
                        }))
                        .map(({ value, label }) => (
                          <Select.Option key={value} value={value}>
                            {label}
                          </Select.Option>
                        ))}
                    </Select>
                  );
                }
                if (data_source === "external" && resource === "country") {
                  fieldComponent = (
                    <Select
                      loading={isLoadingCountry}
                      placeholder={place_holder}
                      onFocus={async () => {
                        if (!countryCollectionAtom) {
                          await getCountry(`per_page=200`);
                        }
                      }}
                    >
                      {countryCollectionAtom?.data.map((data) => (
                        <Select.Option
                          key={`${data.name}-${data.id}`}
                          value={data.name}
                        >
                          {data.name}
                        </Select.Option>
                      ))}
                    </Select>
                  );
                }
                break;
              default:
                fieldComponent = null;
            }

            return (
              <Col span={12} key={fieldName}>
                <Form.Item
                  key={index}
                  label={label}
                  name={fieldName}
                  rules={[
                    {
                      required: is_required,
                      message: `${label} is required`,
                    },
                  ]}
                >
                  {fieldComponent}
                </Form.Item>
              </Col>
            );
          })}
        </Row>
      ))}

      <Form.Item
        name="is_default_shipping"
        valuePropName="checked"
        initialValue={false}
      >
        <Checkbox>Default Address</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
          loading={isLoading || isLoadingUpdate}
        >
          {data ? "Update" : "Create"}
        </Button>
      </Form.Item>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isError || isErrorUpdate}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </Form>
  );
};
