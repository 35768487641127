import { PageHeader } from "@ant-design/pro-layout";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { invoiceCollectionAtom } from "lib/core-react/store/store";
import useDataFilters from "hooks/useDataFilters";
import { ApiHelperModel } from "models/apiHelper";
import { PaginationModel } from "models/pagination";
import {
  CalculatorFilled,
  DownOutlined,
  EditOutlined,
  EyeFilled,
  HddFilled,
  PrinterOutlined,
} from "@ant-design/icons";
import { useInvoice } from "lib/core-react/hooks/private/useInvoice";
import {
  AgentInvoiceModel,
  InvoiceCollectionModel,
  InvoiceModel,
} from "models/invoiceCollectionModel";
import FiltersComponent from "components/FiltersComponent";
import { IFilterType } from "types/filters";
import { useNavigate } from "react-router-dom";
import "./invoice.css";
import useWindowWidth from "../../../../lib/core-react/hooks/public/useWindowWidth";
import { StatusTag } from "../../../../components";
import { formatDateTime } from "utils/helpers";
import {ROLE_PERMISSION} from "../../../../consts/parmission";
import {ExtendedMenuItemType} from "../../../../types";
import checkActionPermission, {IAuthorityType} from "../../../../components/Authorized/CheckPermissions";

const CustomerInvoiceList = () => {
  const navigate = useNavigate();
  const { getCustomerInvoice, recalculateInvoiceData, settleInvoice } =
    useInvoice();
  const [{ data: invoiceCollectionData, isLoading, refetch }] = useAtom(
    invoiceCollectionAtom,
  );
  const { isMobile } = useWindowWidth();
  const [isRecalculating, setIsRecalculating] = useState(false);
  const { Text } = Typography;
  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
  } = useDataFilters();
  // Api Call
  useEffect(() => {
    if ((!isFetched && isFirstCall) || refetch) {
      ApiHelperModel.makeGetRequest(filters || {}, getCustomerInvoice);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstCall, isFetched, refetch]);
  const InvoiceCollectionData =
    invoiceCollectionData && new InvoiceCollectionModel(invoiceCollectionData);
  const paginationData = InvoiceCollectionData?.getPagination();
  const filterData = InvoiceCollectionData?.getFilter();

  // Getting all available filters
  useEffect(() => {
    if (!isFetched && InvoiceCollectionData?.getFilter()) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [
    isFetched,
    initializeAvailableFilter,
    InvoiceCollectionData?.getFilter(),
  ]);

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getCustomerInvoice,
    );
  };

  const handleShowInvoice = (invoiceId: number) => {
    if (!invoiceId) return;
    navigate(`/invoice/customer-invoice/print/${invoiceId}`);
  };

  const handleShowDetail = async (invoiceId: number) => {
    navigate(`/invoice/customer-invoice/details/${invoiceId}`);
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    InvoiceCollectionData,
    handlePaginationChange,
  );

  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getCustomerInvoice);
  };

  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "sl",
      width: 70,
      align: "center" as const,
      render: (_: string, __: InvoiceModel, index: number) => {
        return (
          <div>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </div>
        );
      },
    },
    {
      title: "Invoice and Order Number",
      key: "invoice_and_order",
      width: 230,
      align: "center" as const,
      render: (_: string, record: InvoiceModel, index: number) => {
        return (
          <div className="invoice-order-container">
            <div className="invoice-order-detail">
              <Text strong className="invoice-order-text">
                Invoice:
              </Text>
              <Text
                copyable={{ text: record.getInvoiceNumber() }}
                className="copyable-text"
              >
                {record.getInvoiceNumber().slice(0, 12)}
              </Text>
            </div>
            <div className="invoice-order-detail">
              <Text strong className="invoice-order-text">
                Order:
              </Text>
              <Text copyable>{record.getOrder().getOrderNumber()}</Text>
            </div>
          </div>
        );
      },
    },

    {
      title: "Order Type",
      dataIndex: "order",
      key: "order_type",
      align: "center" as const,
      render: (_: string, record: InvoiceModel, index: number) => {
        const orderType = record.getOrder().getType();
        return (
          <div>
            <StatusTag slug={orderType} text={orderType} />
          </div>
        );
      },
    },
    {
      title: "Total",
      dataIndex: "total_amount",
      key: "total_amount",
      align: "center",
      render: (_, record, index) => {
        return (
          <div className="financial-details-container">
            <Text>{record.getTotalAmount().toFixed(2)}</Text>
          </div>
        );
      },
    },
    {
      title: "Financial Details",
      key: "transaction_summary",
      align: "center",
      render: (_, record, index) => {
        return (
          <div className="financial-details-container">
            <div className="financial-detail">
              <Text strong className="financial-text">
                Paid:
              </Text>
              <Text>{record.getTransactionSummery().paid?.toFixed(2)}</Text>
            </div>
            <div className="financial-detail">
              <Text strong className="financial-text">
                Due:
              </Text>
              <Text>{record.getTransactionSummery().due?.toFixed(2)}</Text>
            </div>
          </div>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center" as const,
      render: (_: string, record: InvoiceModel, index: number) => {
        return (
          <div>
            <StatusTag slug={record.getStatus()} text={record.getStatus()} />
          </div>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      align: "center" as const,
      render: (created_at, record: InvoiceModel) => {
        return <Tag color="purple">{record.getCreatedAt()}</Tag>;
      },
    },
    {
      title: "Details",
      dataIndex: "ud",
      hidden: isMobile,
      key: "details",
      align: "center" as const,
      render: (_: string, record: AgentInvoiceModel, index: number) => {
        return (
          <>
            <Space size={4}>
              <Tooltip title="Invoice Details">
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    handleShowDetail(record.getId());
                  }}
                  shape="round"
                  icon={<EyeFilled />}
                />
              </Tooltip>
              <br />
              <Tooltip title="Invoice">
                <Button
                  type="default"
                  size="small"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    handleShowInvoice(record.getId());
                  }}
                  shape="round"
                  icon={<PrinterOutlined />}
                />
              </Tooltip>
            </Space>
          </>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      fixed: isMobile ? "right" : undefined,
      align: "center" as const,
      width: 100,
      render: (_: string, record: InvoiceModel) => {
        const handleRecalculate = async () => {
          setIsRecalculating(true);
          try {
            await recalculateInvoiceData(record.getId(), "customer");
          } finally {
            setIsRecalculating(false);
          }
        };

        const menuItems:ExtendedMenuItemType[] = [
          {
            label: "Settlement",
            key:"update-settlement",
            permission: ROLE_PERMISSION.ADMIN_SHIP_FOR_ME_LIST_VIEW,
            icon: <EditOutlined />,
            onClick: async (e) => {
              await settleInvoice(record.getId(), "customer");
            },
            style: { margin: "5px", padding: "8px 16px" },
          },
          {
            label: isRecalculating ? "Wait...." : "Re-calculate",
            key: "re-calculate",
            icon: <CalculatorFilled />,
            onClick: handleRecalculate,
            disabled: isRecalculating,
            style: { margin: "5px", padding: "8px 16px" },
          },
        ];

        if (isMobile) {
          menuItems.push(
            {
              label: "Details",
              key: "details",
              icon: <EyeFilled />,
              onClick: () => handleShowDetail(record.getId()),
              style: { margin: "5px", padding: "8px 16px" },
            },
            {
              label: "Invoice",
              key: "details-invoice",
              icon: <PrinterOutlined />,
              onClick: () => handleShowInvoice(record.getId()),
              style: { margin: "5px", padding: "8px 16px" },
            },
            {
              label: (
                <Tag color="purple">
                  {formatDateTime(record.getCreatedAt())}
                </Tag>
              ),
              key: "created_at",
            },
          );
        }

        return (
          <Dropdown
            menu={{
              items:menuItems.filter((x)=>checkActionPermission(x.permission as IAuthorityType, x, null)) ,
            }}
          >
            <Button icon={ <HddFilled />}>
              {isMobile ? (
                <DownOutlined />
              ) : (
                <>
                  Actions <DownOutlined />{" "}
                </>
              )}
            </Button>
          </Dropdown>
        );
      },
    },
  ];
  return (
    <>
      <div>
        <PageHeader
          ghost={false}
          title="Customer Invoices"
          style={{ marginTop: "10px" }}
        >
          {filters && Object.keys(filters).length > 0 && (
            <Row>
              <Col span={24}>
                <Card style={{ marginBottom: "10px" }} title="Filter">

                  { checkActionPermission(ROLE_PERMISSION.ADMIN_SHIP_FOR_ME_LIST_VIEW,  <FiltersComponent
                    handleProductFilter={handleProductFilter}
                    handleFilterChange={handleFilterChange}
                    handelFilterClear={handelFilterClear}
                    isFetched={isFetched}
                    filters={filters}
                    filtersData={filterData}
                    isFromProductReceived={true}
                  />, null )

                  }

                </Card>
              </Col>
            </Row>
          )}
          <div className="responsive-table-container">
            <Table
              loading={isLoading}
              rowKey="id"
              bordered
              dataSource={InvoiceCollectionData?.data}
              //@ts-ignore
              columns={columns}
              pagination={paginationConfig}
              scroll={{ x: 1237 }}
            />
          </div>
        </PageHeader>
      </div>
    </>
  );
};

export default CustomerInvoiceList;
