import { createDataAtom } from "../jotaiUtility";
import {
  BuyOrderCollectionModel,
  BuyProductCollectionModel,
} from "../../../../models/buyOrderCollectionModel";
import { IBuyOrderDetail } from "types/buyOrderDetail";
import { ITimelineTracking } from "types/timelineTracking";
import { BuyProductViewModel } from "models/buyProductDetailModel";

export const buyOrderCollectionAtom = createDataAtom<BuyOrderCollectionModel>();
export const buyProductCollectionAtom =
  createDataAtom<BuyProductCollectionModel>();
export const buyProductTimelineTrackingAtom =
  createDataAtom<ITimelineTracking>();

export const buyProductDetailAtom = createDataAtom<BuyProductViewModel>();

export const buyOrderDetailAtom = createDataAtom<IBuyOrderDetail>();
