import {Select} from "antd";
import React from "react";
import useFetch from "utils/FetchHook";
const {Option} = Select;

const ShipmentList = Props => {
    const warehouseId = Props.warehouseId;
    const [response, isLoading] = useFetch(
        `/admin/shipment-products/warehouses/${warehouseId}/shipments`,
        {data: null},
        'get',
        {}
    );

    const findById = id => {
        for(let i = 0; i < response.data.length; i++){
            if(response.data[i].id === id){
                return response.data[i];
            }
        }
    }

    if (response.data && response.data.length !== 0 &&  Props.handleDefaultSelectedItem !== undefined){
        Props.handleDefaultSelectedItem(response.data[0])
    }

    return(
        <Select
            allowClear
            showSearch
            size={Props.size}
            style={{width: Props.width? Props.width : 200}}
            placeholder="Select a Shipment"
            optionFilterProp="children"
            defaultValue={response.data && response.data[0]?.id}
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onSelect={id => Props.shipmentSelect(findById(id))}
            loading={isLoading}
        >
            {
                response && response.data && response.data.map((shipment,index) => {
                    return <Option key={index} value={shipment.id} >{shipment.shipment_number}</Option>
                })
            }
        </Select>
    );
}

export default ShipmentList;
