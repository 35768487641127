const pathPermissions = [
	{
		path: '/',
		permission: 'admin_dashboard'
	},
	{
		path: '/admins',
		permission: 'admin_index'
	},
	{
		path: '/customers',
		permission: 'customer_index'
	},
]

export default pathPermissions