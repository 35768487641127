/* eslint-disable no-lone-blocks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {Button, Card, Col, Modal, Row, Table, Tag, Tooltip, Typography} from "antd";
import useDataFilters from "hooks/useDataFilters";
import { useAtom } from "jotai";
import { buyActionCollectionAtom } from "lib/core-react/store/store";
import { ApiHelperModel } from "models/apiHelper";
import { useEffect, useState } from "react";
import { useTakeActionList } from "lib/core-react/hooks/private/useBuyAction";
import {
  ActionSequenceModel,
  BuyActionCollectionModel,
  BuyActionModel,
} from "models/buyActionCollectionModel";
import moment from "moment";
import { EyeFilled } from "@ant-design/icons";
import ActionViewDetailsModal from "./ActionViewDetailsModal";
import { PageHeader } from "@ant-design/pro-layout/es/components/PageHeader";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import {StatusTag} from "../../../components";
import {IFilterType} from "../../../types/filters";
import {PaginationModel} from "../../../models/pagination";
import FiltersComponent from "../../../components/FiltersComponent";

const ActionsNeeded = () => {
  const { getAllTakeActions } = useTakeActionList();
  const [{ data: buyActionCollectionData, isLoading, refetch }] = useAtom(
    buyActionCollectionAtom,
  );
  const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);
  const [selectedBuyProduct, setSelectedBuyProduct] = useState<
    BuyActionModel | undefined
  >(undefined);

  const [selectedActionsSequene, setSelectedActionsSequene] = useState<
    ActionSequenceModel | undefined
  >(undefined);

  const { Text } = Typography;
  const {width, isMobile} = useWindowWidth()

  const { filters, isFirstCall, isFetched,handleFilterChange,handelFilterClear, initializeAvailableFilter } = useDataFilters();

  // Api Call
  useEffect(() => {
    if ((!isFetched && isFirstCall) || refetch) {
      ApiHelperModel.makeGetRequest(filters || {}, getAllTakeActions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstCall, isFetched, refetch]);

  const AllTackActonsCollectionData =
    buyActionCollectionData &&
    new BuyActionCollectionModel(buyActionCollectionData);

  const paginationData = AllTackActonsCollectionData?.pagination;

  const filterData = AllTackActonsCollectionData?.getFilters();

  const handleShowDetail = (record: any) => {
    setSelectedBuyProduct(record);
    setIsShowCreateModal(record);
    setSelectedActionsSequene(record);
  };

  // // Getting all available filters
  useEffect(() => {
    if (!isFetched && AllTackActonsCollectionData?.getFilters()) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [
    isFetched,
    initializeAvailableFilter,
    AllTackActonsCollectionData?.getFilters(),
  ]);

  console.log(filterData,'filterData')

  // // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getAllTakeActions
    );
  };

  // // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    AllTackActonsCollectionData,
    handlePaginationChange
  );

  // Filter Handler

  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getAllTakeActions);
  };

  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      width:70,
      key: "sl",
      align: "center" as const,
      render: (_: string, __: BuyActionModel, index: number) => {
        return (
          <div>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </div>
        );
      },
    },
    {
      title: "Product & Id",
      dataIndex: "product_Number",
      key: "product_Number",
      align: "center" as const,
      render: (_: string, record: BuyActionModel) => {
        return (
          <div>
            <Text copyable={true}>{record.getBuyProduct().getProductNumber()}</Text>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center" as const,
      render: (_: string, record: BuyActionModel) => {
        return (
          <div>
            <StatusTag text={record.getBuyProduct().getStatus()} slug={record.getBuyProduct().getStatus()}/>
          </div>
        );
      },
    },
    {
      title: "Issue Type",
      dataIndex: "issue_type",
      key: "issue_type",
      align: "center" as const,
      render: (_: string, record: BuyActionModel) => {
        return (
          <div>
            {record.getSequences().map((item) => (
              <Tag color="volcano">{item.getType()}</Tag>
            ))}
          </div>
        );
      },
    },
    {
      title: "Create At",
      dataIndex: "create_at",
      key: "create_at",
      align: "center" as const,
      render: (_: string, record: BuyActionModel) => {
        return (
          <div>
            <Tag color={"purple"}> {record.getCreatedAt()}</Tag>
          </div>
        );
      },
    },
    {
      title: "Details",
      dataIndex: "ud",
      key: "details",
      fixed: isMobile ? "right": undefined,
      width: isMobile ? 100 : 150,
      align: "center" as const,
      render: (_: string, record: BuyActionModel) => {
        return (
          <Tooltip title="View Details">
            <Button
              onClick={() => handleShowDetail(record)}
              shape="round"
              icon={<EyeFilled />}
            />
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <PageHeader
          ghost={false}
          title="Actions Needed"
          style={{ marginTop: "10px" }}
        >
          {filters && Object.keys(filters).length > 0 && (
            <Row>
              <Col span={24}>
                <Card style={{marginBottom:"10px"}} title="Filter">
                  <FiltersComponent
                    handleProductFilter={handleProductFilter}
                    handleFilterChange={handleFilterChange}
                    handelFilterClear={handelFilterClear}
                    isFetched={isFetched}
                    filters={filters}
                    filtersData={filterData}
                    isFromProductReceived={true}
                  />
                </Card>
              </Col>
            </Row>
          )}
          <div>
            <Table
              loading={isLoading}
              rowKey="id"
              bordered={true}
              dataSource={AllTackActonsCollectionData?.getData()}
              //@ts-ignore
              columns={columns}
              scroll={{x:1000}}
              pagination={paginationConfig}
            />
          </div>
        </PageHeader>
        {selectedBuyProduct && (
          <Modal
            title={`Take action Details for #${selectedBuyProduct
              ?.getBuyProduct()
              .getProductNumber()}`}
            open={isShowCreateModal}
            onCancel={() => {
              {
                setIsShowCreateModal(false);
              }
            }}
            onOk={() => {
              {
                setIsShowCreateModal(false);
              }
            }}
            width={width < 600 ? "90vw": "50vw"}
          >
            <ActionViewDetailsModal selectedProduct={selectedBuyProduct} />
          </Modal>
        )}
      </div>
    </>
  );
};

export default ActionsNeeded;
