import {
  IAddonService,
  IAddonServiceCollection,
  IAddonServiceImage,
  IAddonServicePriceCollection,
  IAddonServicePriceSlotCollection,
  IAddonServicePriceSlots,
  IAddonServicePrices,
  ICreateServicesnPostRequest,
  IPrice,
  IPriceSlot,
  IUpdateServicesnPUTRequest,
} from "types/addonServiceCollection";
import { IFilterType } from "types/filters";
import { IPagination } from "types/pagination";

export class AddonServicePriceSlotsModel {
  public readonly object: string;
  public readonly id: number;
  public readonly addon_service_price_id: number;
  public readonly min_amount: number;
  public readonly max_amount: number;
  public readonly price: number;

  constructor(data: IAddonServicePriceSlots) {
    this.object = data.object;
    this.id = data.id;
    this.addon_service_price_id = data.addon_service_price_id;
    this.min_amount = data.min_amount;
    this.max_amount = data.max_amount;
    this.price = data.price;
  }

  getId(): number {
    return this.id;
  }
  getAddonServicePriceid() {
    return this.addon_service_price_id;
  }

  getMinAmount() {
    return this.min_amount;
  }

  getMaxAmount() {
    return this.max_amount;
  }

  getPrice() {
    return this.price;
  }
}

export class AddonServicePriceSlotCollectionModel {
  public readonly object: string;
  public readonly data: AddonServicePriceSlotsModel[];

  constructor(data: IAddonServicePriceSlotCollection) {
    this.object = data.object;
    this.data = data.data.map((slot) => new AddonServicePriceSlotsModel(slot));
  }

  getData() {
    return this.data;
  }
}

export class AddonServicePricesModel {
  public readonly object: string;
  public readonly id: number;
  public readonly addon_service_id: number;
  public readonly region_id: number;
  public readonly depends_on: string;
  public readonly is_primary: number;
  public readonly price: number;
  public readonly price_slots: AddonServicePriceSlotCollectionModel;

  constructor(data: IAddonServicePrices) {
    this.object = data.object;
    this.id = data.id;
    this.addon_service_id = data.addon_service_id;
    this.region_id = data.region_id;
    this.depends_on = data.depends_on;
    this.is_primary = data.is_primary;
    this.price = data.price;
    this.price_slots = new AddonServicePriceSlotCollectionModel(
      data.price_slots,
    );
  }

  getId() {
    return this.id;
  }

  getAddonServiceId() {
    return this.addon_service_id;
  }

  getRegionId() {
    return this.region_id;
  }

  getDependsOn() {
    return this.depends_on;
  }

  getIsPrimary() {
    return this.is_primary;
  }

  getPrice() {
    return this.price;
  }

  getPriceSlots() {
    return this.price_slots;
  }
}

export class AddonServicePriceCollectionModel {
  public readonly object: string;
  public readonly data: AddonServicePricesModel[];

  constructor(data: IAddonServicePriceCollection) {
    this.object = data.object;
    this.data = data.data.map((price) => new AddonServicePricesModel(price));
  }

  getData() {
    return this.data;
  }
}

export class AddonServiceImageModel {
  public readonly object: string;
  public readonly id: number;
  public readonly type: string;
  public readonly src: string;
  public readonly created_at: string;

  constructor(data: IAddonServiceImage) {
    this.object = data.object;
    this.id = data.id;
    this.type = data.type;
    this.src = data.src;
    this.created_at = data.created_at;
  }

  getId() {
    return this.id;
  }

  getType() {
    return this.type;
  }

  getSrc() {
    return this.src;
  }

  getCreatedAt() {
    return this.created_at;
  }
}

export class AddonServiceModel {
  public readonly object: string;
  public readonly id: number;
  public readonly name: string;
  public readonly addon_for: string;
  public readonly identifier: string;
  public readonly description: string;
  public readonly status: string;
  public readonly image?: AddonServiceImageModel;
  public readonly prices: AddonServicePriceCollectionModel;
  public readonly created_at: string;

  constructor(data: IAddonService) {
    this.object = data.object;
    this.id = data.id;
    this.name = data.name;
    this.addon_for = data.addon_for;
    this.identifier = data.identifier;
    this.description = data.description;
    this.status = data.status;
    this.image = data.image
      ? new AddonServiceImageModel(data.image)
      : undefined;
    this.prices = new AddonServicePriceCollectionModel(data.prices);
    this.created_at = data.created_at;
  }

  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getAddonFor() {
    return this.addon_for;
  }

  getIdentifier() {
    return this.identifier;
  }

  getDescription() {
    return this.description;
  }

  getStatus() {
    return this.status;
  }

  getImage() {
    return this.image;
  }

  getPrices() {
    return this.prices;
  }

  getCreatedAt() {
    return this.created_at;
  }
}

export class AddonServiceCollectionModel {
  public readonly object: string;
  public readonly data: AddonServiceModel[];
  public readonly filters: IFilterType;
  public readonly pagination: IPagination;

  constructor(data: IAddonServiceCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new AddonServiceModel(item));
    this.filters = data.filters;
    this.pagination = data.pagination;
  }

  getObject() {
    return this.object;
  }

  getData() {
    return this.data;
  }

  getPagination() {
    return this.pagination;
  }

  getFilters() {
    return this.filters;
  }
}

// Create model
export class CreateServicesnPostRequestModel {
  public readonly name: string;
  public readonly identifier: string;
  public readonly description: string;
  public readonly addon_for: string;
  public readonly logo: string;
  public readonly prices: Price[];

  constructor(data: ICreateServicesnPostRequest) {
    this.name = data.name;
    this.identifier = data.identifier;
    this.description = data.description;
    this.addon_for = data.addon_for;
    this.logo = data.logo;
    this.prices = data.prices.map((price: any) => new Price(price));
  }
}

export class PriceSlot {
  public readonly min_amount: number;
  public readonly max_amount: number;
  public readonly price: number;

  constructor(data: IPriceSlot) {
    this.min_amount = data.min_amount;
    this.max_amount = data.max_amount;
    this.price = data.price;
  }
}

export class Price {
  public readonly region_id: number;
  public readonly depends_on: string;
  public readonly is_primary: boolean;
  public readonly price: number;
  public readonly slots: PriceSlot[];

  constructor(data: IPrice) {
    this.region_id = data.region_id;
    this.depends_on = data.depends_on;
    this.is_primary = data.is_primary;
    this.price = data.price;
    this.slots = data.slots.map((slot: any) => new PriceSlot(slot));
  }
}

export class UpdateServicesnPutRequestModel {
  public readonly name: string;
  public readonly identifier: string;
  public readonly description: string;
  public readonly addon_for: string;
  public readonly logo: string;

  constructor(data: IUpdateServicesnPUTRequest) {
    this.name = data.name;
    this.identifier = data.identifier;
    this.description = data.description;
    this.addon_for = data.addon_for;
    this.logo = data.logo;
  }
}
