import {
  IShipmentOrder,
  IShipmentOrderCollection,
} from "types/shipmentOrderCollection";
import {
  ShipmentOrderShipmentProductCollectionModel,
  ShipmentProductCollectionModel,
} from "./shipmentProductCollectionModel";
import { IFilterType } from "types/filters";
import { CommonUserModel } from "./shared";
import { PaginationModel } from "./paginationModel";

export class ShipmentOrderModel {
  object: string;
  id: number;
  order_number: string;
  created_at: string;
  customer: CommonUserModel;
  products: ShipmentOrderShipmentProductCollectionModel;

  constructor(data: IShipmentOrder) {
    this.object = data.object;
    this.id = data.id;
    this.order_number = data.order_number;
    this.created_at = data.created_at;
    this.products = new ShipmentOrderShipmentProductCollectionModel(
      data.products,
    );
    this.customer = new CommonUserModel(data.customer);
  }

  getId = () => this.id;
  getOrderNumber = () => this.order_number;
  getCreatedAt = () => this.created_at;
  getProducts = () => this.products;
  getCustomer = () => this.customer;
}
export class ShipmentOrderCollectionModel {
  object: string;
  data: ShipmentOrderModel[];
  filters: IFilterType;
  pagination: PaginationModel;

  constructor(data: IShipmentOrderCollection) {
    this.object = data.object;
    this.filters = data.filters;
    this.pagination = new PaginationModel(data.pagination);
    this.data = data.data.map((item) => new ShipmentOrderModel(item));
  }

  getData = () => this.data;
  getFilters = () => this.filters;
  getPagination = () => this.pagination;

  getOrders() {
    return this.data.map((order) => {
      return {
        key: order.getId(),
        orderId: order.getOrderNumber(),
        customerName: order.getCustomer().getName(),
        customerPhone: order.getCustomer().getPhone(),
        customerEmail: order.getCustomer().getEmail(),
        customerId: order.getCustomer().getId(),
        customerGravatar: order.getCustomer().getGravatar(),
        shippingMark: order.getCustomer().getShippingMark(),
        createdAt: order.getCreatedAt(),

        products: order.getProducts().getData(),
      };
    });
  }
  // getAllProducts(): IShipmentOrderTable[] {
  //   return this.data
  //     .map((order) => {
  //       return order.products.data.map((product) => {
  //         return {
  //           ...product,
  //           orderNumber: order.order_number,
  //           orderCreatedAt: order.created_at,
  //           customerDetails: order.customer,
  //         };
  //       });
  //     })
  //     .flat();
  // }
  static getStatusLabel(value: string) {
    const statusInfo = [
      { label: "Pending", value: "shipment-pending", color: "warning" },
      { label: "Approved", value: "shipment-approved", color: "success" },
      { label: "Rejected", value: "shipment-rejected", color: "error" },
      {
        label: "Assigned agent",
        value: "shipment-assigned-agent",
        color: "processing",
      },
      {
        label: "Shipping started",
        value: "shipment-shipping-started",
        color: "processing",
      },
      {
        label: "Shipping not arrived",
        value: "shipment-not-arrived",
        color: "error",
      },
      {
        label: "Shipping cancelled",
        value: "shipment-shipping-cancelled",
        color: "error",
      },
      {
        label: "Arrived at warehouse",
        value: "shipment-arrived-at-warehouse",
        color: "processing",
      },
      {
        label: "Handover to travel agent",
        value: "shipment-handover-to-travel-agent",
        color: "processing",
      },
      {
        label: "Handover to MoveOn",
        value: "shipment-handover-to-moveon",
        color: "processing",
      },
      {
        label: "Preparing for transport",
        value: "shipment-preparing-for-transport",
        color: "processing",
      },
      {
        label: "Handover to ship",
        value: "shipment-handover-to-ship",
        color: "processing",
      },
      {
        label: "Handover to airline",
        value: "shipment-handover-to-airline",
        color: "processing",
      },
      {
        label: "Arrived at destination port",
        value: "shipment-arrived-at-destination-port",
        color: "processing",
      },
      {
        label: "Arrived at destination airport",
        value: "shipment-arrived-at-destination-airport",
        color: "processing",
      },
      {
        label: "Customs released",
        value: "shipment-customs-released",
        color: "processing",
      },
      {
        label: "Received by MoveOn",
        value: "shipment-received-by-moveon",
        color: "success",
      },
      {
        label: "Delivery requested",
        value: "shipment-delivery-requested",
        color: "success",
      },
      {
        label: "Delivery processing",
        value: "shipment-delivery-processing",
        color: "success",
      },
      {
        label: "Prepared for delivery",
        value: "shipment-prepared-for-delivery",
        color: "success",
      },
      {
        label: "Handover to courier",
        value: "shipment-handover-to-courier",
        color: "success",
      },
      {
        label: "Delivered to customer",
        value: "shipment-delivered-to-customer",
        color: "success",
      },
      {
        label: "Delivery failed",
        value: "shipment-delivery-failed",
        color: "success",
      },
      {
        label: "Delivery returned",
        value: "shipment-delivery-returned",
        color: "success",
      },
    ];
    return statusInfo.find((item) => item.value === value);
  }
}
