import { PageHeader } from "@ant-design/pro-layout";
import {
  Badge,
  Button,
  Card,
  Col,
  List,
  Modal,
  Row,
  Skeleton,
  Typography,
  Table,
  Tag, MenuProps, Dropdown,
} from "antd";
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import {Link} from "react-router-dom";

import useDataFilters from "hooks/useDataFilters";
import { ApiHelperModel } from "models/apiHelper";
import { storeExchangeAtom } from "lib/core-react/store/store";
import { useGetExchange } from "lib/core-react/hooks/private/useExchange";
import {
  ExchangeListCollectionModel,
  ExchangeListModel, RegionalExchangeModel,
  StoreExchangeModel,
} from "models/exchangeListCollectionModel";
import { IFilterType } from "types/filters";
import CopyExchange from "./components/CopyExchange";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import {CommissionRateTypeEnums} from "../../../enums/exchangeListCollectionEnums";
import { DownOutlined, EditOutlined, HddFilled} from "@ant-design/icons";
import UpdateExchange from "./components/UpdateExchange";
import {capitalize} from "../../../utils/helper";
import StoreRateUpdate from "./components/StoreRateUpdate";

const {Text} =Typography
type ModalForType = 'exchange-update' | 'exchange-copy' | 'rate-update';

const StoreExchange = () => {
  const { getExchange } = useGetExchange();
  const [{ data: exchangeStoreData, isLoading, refetch }] =
    useAtom(storeExchangeAtom);
  const [selectedExchangeStore, setSelectedExchangeStore] = useState<StoreExchangeModel | undefined>(undefined)
  const [selectedRegionData, setSelectedRegionData] = useState<RegionalExchangeModel | undefined>(undefined)
const [modalFor, setModalFor] = useState<ModalForType>("exchange-update")
  const ExchangeListCollectionData =
    exchangeStoreData && new ExchangeListCollectionModel(exchangeStoreData);

  const {
    filters,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
  } = useDataFilters();
  const [selectedExchange, setSelectedExchange] = useState<
    ExchangeListModel | undefined
  >(undefined);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const {isMobile} = useWindowWidth()

  // Api Call
  useEffect(() => {
    if ((!isFetched && isFirstCall) || refetch) {
      ApiHelperModel.makeGetRequest({}, getExchange);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstCall, isFetched, refetch]);

  // Filter
  const filterData = ExchangeListCollectionData?.getFilters();

  // Getting all available filters
  useEffect(() => {
    if (!isFetched && ExchangeListCollectionData?.getFilters()) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [
    isFetched,
    initializeAvailableFilter,
    ExchangeListCollectionData?.getFilters(),
  ]);

  useEffect(()=>{
    if(selectedExchange){
      setSelectedRegionData(selectedExchange.getRegionalExchanges().getData()[0])
    }

  },[selectedExchange])

  // Filter Handler
  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getExchange);
  };

  useEffect(()=>{

    if(exchangeStoreData){
      const collections = new ExchangeListCollectionModel(exchangeStoreData);
      setSelectedExchange(collections.getData()[0])
    }

  },[exchangeStoreData])

  const onChangeExchange = (data: ExchangeListModel) => {
    setSelectedExchange(data);
  };

  const getModelTitle=(modelFor:ModalForType)=>{
    switch (modelFor) {
      case 'exchange-update':
        return `Update Exchange (${selectedExchangeStore?.getStore().name })`;
      case 'exchange-copy':
        return 'Copy Exchange';
      default:
        return '';
    }

  }

  const  getModalContent = ()=> {
    if (modalFor === "exchange-update" && selectedExchangeStore && selectedExchange) {
      return (
        <UpdateExchange
          selectedExchange={selectedExchange}
          setIsShowUpdateModal={setIsShowModal}
          selectedStore={selectedExchangeStore}
        />
      );
    } else if (modalFor === "exchange-copy" && selectedExchange) {
      return (
        <CopyExchange
          selectedExchange={selectedExchange}
          setIsShowCopyModal={setIsShowModal}
        />
      );
    } else if (modalFor === "rate-update" && selectedExchangeStore && selectedExchange && selectedRegionData) {
      return (
        <StoreRateUpdate
          selectedExchange={selectedExchange}
          setIsShowUpdateModal={setIsShowModal}
          selectedStore={selectedExchangeStore}
          selectedRegion={selectedRegionData}
        />
      );
    } else {
      return <div>Please select a proper action </div>
    }
  }



  const exchangeColumns = [
    {
      title: "ID",
      dataIndex: "id",
      width:70,
      key: "exchange-id",
      render: (_: string, record: StoreExchangeModel) => {
        return <div>{record.getId()}</div>;
      },
    },
    {
      title: "Store",
      dataIndex: "store",
      key: "exchange-store",
      render: (_: string, record: StoreExchangeModel) => {
        return <div>{record.getStore().getName()}</div>;
      },
    },
    {
      title: "FX Rate",
      dataIndex: "fx_rate",
      hidden: isMobile,
      key: "exchange-fx-rate",
      render: (_: string, record: StoreExchangeModel) => {
        return <div>{record.getFxRate()
        }</div>;
      },
    },
    {
      title: "Commission Rate",
      dataIndex: "commission_rate",
      hidden: isMobile,
      key: "exchange-commission-rate",
      render: (_: string, record: StoreExchangeModel) => {
        return <div>{ record.getCommissionRate() } {" "} {record.getCommissionRateType() === CommissionRateTypeEnums.PERCENTAGE ?"%":"" } </div>;
      },
    },
    {
      title: "Total",
      dataIndex: "commission_rate_type",
      hidden: isMobile,
      key: "exchange-commission-rate-total",
      render: (_: string, record: StoreExchangeModel) => {
        return (
          <Tag key={record.getId()} color="green">
            {record.getTotal()}
          </Tag>
        );
      },
    },
    {
      title: "Exchange Rates",
      hidden: !isMobile,
      key: "exchange-rates",
      render: (_: string, record: StoreExchangeModel) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", minWidth:"150px" }}>
            <div style={{ marginBottom: "4px" }}>
              <Text strong style={{ marginRight: "5px" }}>FX Rate:</Text>
              <Text>{record.getFxRate()}</Text>
            </div>
            <div style={{ marginBottom: "4px" }}>
              <Text strong style={{ marginRight: "5px" }}>Commission:</Text>
              <Text>{record.getCommissionRate() } {" "} {record.getCommissionRateType() === CommissionRateTypeEnums.PERCENTAGE ?"%":"" }</Text>
            </div>
            <div>
              <Text strong style={{ marginRight: "5px" }}>Total:</Text>
              <Tag color="green">{record.getTotal()}</Tag>
            </div>
          </div>
        );
      },
    },

    {
      title: "Minimum Order Amount",
      dataIndex: ["options", "minimum_order_amount"],
      key: "commission-rate-rate",
      render: (_: string, record: StoreExchangeModel) => {
        return <div>{record.getMinimumOrderAmount()}</div>;
      },
    },
    {
      title: "Actions",
      key: "actions",
      fixed: isMobile ? "right" : undefined,
      align: "center" as const,
      width: 100,
      render: (_: string, record: StoreExchangeModel) => {

        const menuItems: MenuProps["items"] = [
          {
            label: "Update",
            key: "update",
            icon: <EditOutlined />,
            onClick: async (e) => {
              setSelectedExchangeStore(record)
              setIsShowModal(true)
              setModalFor("exchange-update")
            },
            style: { margin: "5px", padding: "8px 16px" },
          },

        ];

        if(selectedExchange && selectedExchange.getIsDefault()){

          menuItems.push( {
            label: "Update rate",
            key: "update-rate",
            icon: <EditOutlined />,
            onClick: async (e) => {
              setSelectedExchangeStore(record)
              setIsShowModal(true)
              setModalFor("rate-update")
            },
            style: { margin: "5px", padding: "8px 16px" },
          },)
        }

        return (
          <Dropdown
            menu={{
              items: menuItems,
            }}
          >
            <Button icon={<HddFilled />}>
              {isMobile ? (
                <DownOutlined />
              ) : (
                <>
                  Actions <DownOutlined />{" "}
                </>
              )}
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader
        ghost={false}
        title="Exchange List"
        style={{ marginTop: "10px" }}
        extra={[
          <Link key={1} to="/store-manage/store-exchanges/create">
            <Button key="create-exchange-button" type="primary">
              Create Exchange List
            </Button>
          </Link>,
        ]}
        onBack={() => window.history.back()}
      >
        {/*{filters && Object.keys(filters).length > 0 && (*/}
        {/*  <Row>*/}
        {/*    <Col span={24}>*/}
        {/*      <Card title="Filter">*/}
        {/*        <FiltersComponent*/}
        {/*          handleProductFilter={handleProductFilter}*/}
        {/*          handleFilterChange={handleFilterChange}*/}
        {/*          handelFilterClear={handelFilterClear}*/}
        {/*          isFetched={isFetched}*/}
        {/*          filters={filters}*/}
        {/*          filtersData={filterData}*/}
        {/*          isFromProductReceived={true}*/}
        {/*        />*/}
        {/*      </Card>*/}
        {/*    </Col>*/}
        {/*  </Row>*/}
        {/*)}*/}

        <Row>
          <Col span={24}>
            <Card  style={{margin:"10px 0px"}} title="Item List">
              <List
                loading={isLoading}
                itemLayout="horizontal"
                dataSource={ExchangeListCollectionData?.getData()}
                grid={{ gutter: 16, xs: 2, sm: 2, md: 4, lg: 4, xl: 5, xxl: 6 }}
                renderItem={(item) => (
                  <List.Item>
                    <Skeleton title={false} loading={isLoading} active>
                      <div

                        key={item.getId()}
                        onClick={() => onChangeExchange(item)}
                        style={{
                          padding:"8px 0px",
                          textAlign:"center",
                          backgroundColor: `${
                            selectedExchange &&
                            selectedExchange.getId() === item.getId()
                              ? "white"
                              : "#FAFAFA"
                          }`,
                          border: `${
                            selectedExchange &&
                            selectedExchange.getId() === item.getId()
                              ? "2px solid #DFFFED"
                              : "1px solid #FAFAFA"
                          }`,
                          fontWeight:"bold",
                          color: `${
                            selectedExchange &&
                            selectedExchange.getId() === item.getId()
                              ? "black"
                              : "black"
                          }`,
                          cursor: "pointer",
                        }}
                      >
                        {item.getIsDefault() ? (
                          <Badge.Ribbon text="Default" style={{ fontSize: '12px', height: '21px', lineHeight: '16px', padding: '2px 10px' }}>
                            {item.getName()}
                          </Badge.Ribbon>                        ) : (
                          item.getName()
                        )}
                      </div>
                    </Skeleton>
                  </List.Item>
                )}
              />

            </Card>
          </Col>
          { selectedExchange && <Col span={24}>
            <Card  style={{margin:"10px 0px"}} title={`${capitalize(selectedExchange.getName())} -> Region`}>
              <List
                loading={isLoading}
                itemLayout="horizontal"
                dataSource={selectedExchange.getRegionalExchanges().getData()}
                grid={{ gutter: 16, xs: 2, sm: 2, md: 4, lg: 4, xl: 5, xxl: 6 }}
                renderItem={(item) => (
                  <List.Item>
                    <div
                      key={item.getId()}
                      onClick={() => setSelectedRegionData(item)}
                      style={{
                        padding:"8px 0px",
                        textAlign:"center",
                        backgroundColor: `${
                          selectedRegionData &&
                          selectedRegionData.getId() === item.getId()
                            ? "white"
                            : "#FAFAFA"
                        }`,
                        border: `${
                          selectedRegionData &&
                          selectedRegionData.getId() === item.getId()
                            ? "2px solid #DFFFED"
                            : "1px solid #FAFAFA"
                        }`,
                        fontWeight:"bold",
                        color: `${
                          selectedRegionData &&
                          selectedRegionData.getId() === item.getId()
                            ? "black"
                            : "black"
                        }`,
                        cursor: "pointer",
                      }}
                    >
                      {item.getRegion().name}
                    </div>
                  </List.Item>
                )}
              />

            </Card>
          </Col>

          }

        </Row>
        {(selectedExchange && selectedRegionData) &&  (
          <Card
            title={`${capitalize(selectedExchange.getName())} -> ${selectedRegionData.getRegion().name} -> Store`}
            bordered={false}
            extra={[
              <Button
                onClick={() => {
                  setIsShowModal(true)
                  setModalFor("exchange-copy")
                }}
                key="copy-commission-for-another-region"
                type="primary"
              >
                Create a Copy
              </Button>,
            ]}
          >
            <Table
              rowKey="id"
              bordered
              dataSource={selectedRegionData.getStoreExchanges().getData()}
              //@ts-ignore
              columns={exchangeColumns}
              scroll={{x:1000}}
              pagination={{ pageSize: 20 }}
            />
          </Card>
        )}
      </PageHeader>

      {selectedExchange && (
        <Modal
          title={getModelTitle(modalFor)}
          open={isShowModal}
          footer={false}
          onCancel={() => setIsShowModal(false)}
        >
          {getModalContent()}
        </Modal>
      )}
    </>
  );
};

export default StoreExchange;
