import { SendOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row } from "antd";
import useWindowWidth from "../../../../lib/core-react/hooks/public/useWindowWidth";

export const SourceDestination = ({
  isLoading,
  title,
  productData,
}) => {
  const sourcCountry = productData
    ?.getMoveonShipping()
    ?.getAgentWarehouse()
    .getAgentCompany();
  const destinationCountry = productData
    ?.getMoveonShipping()
    ?.getDestinationWarehouse();
  const {width } = useWindowWidth()

  return (
    <>
      <Card title={title} bordered={false} loading={isLoading}>
        <Row justify="center" align="middle" gutter={16}>
          <Col xs={24} sm={10}>
            <Card
              bordered
              style={{ flex: 1 }}
            >

              <strong style={{ textAlign: "center", display:"block", marginBottom:"10px" }}>Source</strong>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 5,
                  fontWeight: 500,
                  whiteSpace: "nowrap",
                }}
              >

                {sourcCountry.getRegisteredCountryDetail().name}

              </div>
            </Card>
          </Col>
          <Col style={{textAlign:"center", margin:"5px 0px "}} xs={24} sm={4}>
            <SendOutlined rotate={ width < 572? 90 :0} />
          </Col>
          <Col xs={24} sm={10}>
            <Card
              bordered
              style={{ flex: 1 }}
            >
              <strong style={{ textAlign: "center", display:"block", marginBottom:"10px" }}>Destination</strong>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 5,
                  fontWeight: 500,
                  whiteSpace: "nowrap",
                }}
              >
                {destinationCountry.getName()}
              </div>
            </Card>
          </Col>
        </Row>
      </Card>
    </>
  );
};
