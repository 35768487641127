import { useState } from "react";
import { useAtom } from "jotai";

import { useService } from "lib/core-react/contexts";
import { shipmentOrderCollectionAtom } from "lib/core-react/store/shipmentOrder/shipmentOrderAtom";
import { getError } from "../utils/errors";
import {
  IDefaultAssignAgentPayload,
  IDefaultWithPriceAssignAgentPayload,
  IOpenBidPayload,
  IShipmentProductAddPackageForm,
  IShipmentProductAttachImageForm,
} from "types/shipmentProductCollection";
import {
  bidCollectionAtom,
  shipmentProductAtom,
  shipmentProductsAtom,
} from "lib/core-react/store/store";
import { ShippingModesEnums, ShippingTypesEnums } from "enums/shippingEnums";
import { TrackingTypesEnums } from "enums/shipForMeContextEnums";

export const useGetShipmentOrder = () => {
  const [shipmentOrderCollectionData, setShipmentOrderCollectionData] = useAtom(
    shipmentOrderCollectionAtom,
  );
  const { shippingService } = useService();
  const getShipmentOrder = async (params?: string): Promise<void> => {
    setShipmentOrderCollectionData({
      ...shipmentOrderCollectionData,
      isLoading: true,
      error: null,
    });
    try {
      const response =
        await shippingService.shippingResource.getShipmentOrders(params);
      setShipmentOrderCollectionData({
        ...shipmentOrderCollectionData,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setShipmentOrderCollectionData({
        ...shipmentOrderCollectionData,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getShipmentOrder } as const;
};
export const useGetAllBids = () => {
  const [bidCollectionAtomData, setBidCollectionAtomData] =
    useAtom(bidCollectionAtom);
  const { shippingService } = useService();
  const getAllBids = async (productNumber: number): Promise<void> => {
    setBidCollectionAtomData({
      ...bidCollectionAtomData,
      isLoading: true,
      error: null,
    });
    try {
      const response =
        await shippingService.shippingResource.getAllBids(productNumber);
      setBidCollectionAtomData({
        ...bidCollectionAtomData,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setBidCollectionAtomData({
        ...bidCollectionAtomData,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getAllBids } as const;
};

export const useGetShipmentProducts = () => {
  const [shipmentProductsData, setShipmentProductsData] =
    useAtom(shipmentProductsAtom);
  const { shippingService } = useService();
  const getShipmentProducts = async (params?: string): Promise<void> => {
    setShipmentProductsData({
      ...shipmentProductsData,
      isLoading: true,
      error: null,
    });
    try {
      const response =
        await shippingService.shippingResource.getShipmentProducts(params);
      setShipmentProductsData({
        ...shipmentProductsData,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setShipmentProductsData({
        ...shipmentProductsData,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getShipmentProducts } as const;
};

export const useGetShipmentProduct = () => {
  const [shipmentProductData, setShipmentProductData] =
    useAtom(shipmentProductAtom);
  const { shippingService } = useService();
  const getShipmentProduct = async (
    productId: number,
    params?: string,
  ): Promise<void> => {
    setShipmentProductData({
      ...shipmentProductData,
      isLoading: true,
      error: null,
    });
    try {
      const response =
        await shippingService.shippingResource.getShipmentProduct(
          productId,
          params,
        );
      setShipmentProductData({
        ...shipmentProductData,
        isLoading: false,
        data: response.data,
        refetch: false,
      });
    } catch (error: any) {
      setShipmentProductData({
        ...shipmentProductData,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };
  return { getShipmentProduct } as const;
};

export const useUpdateShippingCountry = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [shipmentProductsData, setShipmentProductsData] =
    useAtom(shipmentProductsAtom);
  const [shipmentProductData, setShipmentProductData] =
    useAtom(shipmentProductAtom);

  const updateShippingCountry = async (payload: {
    shipment_product_ids: number[];
    shipping_country_id: number;
  }) => {
    setIsLoading(true);
    try {
      const response =
        await shippingService.shippingResource.updateShippingCountry(payload);
      setShipmentProductsData({ ...shipmentProductsData, refetch: true });
      setShipmentProductData({ ...shipmentProductData, refetch: true });
      setIsLoading(false);
      return response;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { updateShippingCountry, isLoading, isError };
};
export const useUpdateDestinationWarehouse = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [shipmentProductsData, setShipmentProductsData] =
    useAtom(shipmentProductsAtom);
  const [shipmentProductData, setShipmentProductData] =
    useAtom(shipmentProductAtom);

  const updateDestinationWarehouse = async (payload: {
    shipment_product_ids: number[];
    destination_warehouse_id: number;
  }) => {
    setIsLoading(true);
    try {
      const response =
        await shippingService.shippingResource.updateDestinationWarehouse(
          payload,
        );
      setShipmentProductsData({ ...shipmentProductsData, refetch: true });
      setShipmentProductData({ ...shipmentProductData, refetch: true });
      setIsLoading(false);
      return response;
    } catch (error: any) {
      setError(error?.response?.data?.message);
      setIsLoading(false);
      throw error;
    }
  };

  return { updateDestinationWarehouse, isLoading, isError };
};
export const useUpdateShippingCategory = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [shipmentProductsData, setShipmentProductsData] =
    useAtom(shipmentProductsAtom);
  const [shipmentProductData, setShipmentProductData] =
    useAtom(shipmentProductAtom);

  const updateShippingCategory = async (payload: {
    shipment_product_ids: number[];
    shipping_category_id: string;
  }) => {
    setIsLoading(true);
    try {
      const response =
        await shippingService.shippingResource.updateShippingCategory(payload);
      setShipmentProductsData({ ...shipmentProductsData, refetch: true });
      setShipmentProductData({ ...shipmentProductData, refetch: true });
      setIsLoading(false);
      return response;
    } catch (error: any) {
      setError(error?.response?.data?.message);
      setIsLoading(false);
      throw error;
    }
  };

  return { updateShippingCategory, isLoading, isError };
};
export const useUpdateShippingMode = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [shipmentProductsData, setShipmentProductsData] =
    useAtom(shipmentProductsAtom);
  const [shipmentProductData, setShipmentProductData] =
    useAtom(shipmentProductAtom);

  const updateShippingMode = async (payload: {
    shipment_product_ids: number[];
    shipping_mode: ShippingModesEnums;
  }) => {
    setIsLoading(true);
    try {
      const response =
        await shippingService.shippingResource.updateShippingMode(payload);
      setShipmentProductsData({ ...shipmentProductsData, refetch: true });
      setShipmentProductData({ ...shipmentProductData, refetch: true });
      setIsLoading(false);
      return response;
    } catch (error: any) {
      setError(error?.response?.data?.message);
      setIsLoading(false);
      throw error;
    }
  };

  return { updateShippingMode, isLoading, isError };
};
export const useUpdateShippingType = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [shipmentProductsData, setShipmentProductsData] =
    useAtom(shipmentProductsAtom);
  const [shipmentProductData, setShipmentProductData] =
    useAtom(shipmentProductAtom);
  const updateShippingType = async (payload: {
    shipment_product_ids: number[];
    shipping_type: ShippingTypesEnums;
  }) => {
    setIsLoading(true);
    try {
      const response =
        await shippingService.shippingResource.updateShippingType(payload);
      setShipmentProductsData({ ...shipmentProductsData, refetch: true });
      setShipmentProductData({ ...shipmentProductData, refetch: true });
      setIsLoading(false);
      return response;
    } catch (error: any) {
      setError(error?.response?.data?.message);
      setIsLoading(false);
      throw error;
    }
  };

  return { updateShippingType, isLoading, isError };
};
export const useAttachTrackingId = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [shipmentProductsData, setShipmentProductsData] =
    useAtom(shipmentProductsAtom);
  const [shipmentProductData, setShipmentProductData] =
    useAtom(shipmentProductAtom);
  const attachTrackingId = async (
    productId: number,
    payload: {
      tracking: string;
      type: TrackingTypesEnums;
      package_ids: number[];
    },
  ) => {
    setIsLoading(true);
    try {
      const response = await shippingService.shippingResource.attachTrackingId(
        productId,
        payload,
      );
      setIsLoading(false);
      setShipmentProductsData({ ...shipmentProductsData, refetch: true });
      setShipmentProductData({ ...shipmentProductData, refetch: true });
      setIsLoading(false);
      return response;
    } catch (error: any) {
      setError(error?.response?.data?.message);
      setIsLoading(false);
      throw error;
    }
  };

  return { attachTrackingId, isLoading, isError };
};

export const useShipmentProductAttachTrackingId = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [shipmentProductsData, setShipmentProductsData] =
    useAtom(shipmentProductsAtom);
  const [shipmentProductData, setShipmentProductData] =
    useAtom(shipmentProductAtom);
  const attachTrackingId = async (
    productId: number,
    payload: {
      tracking: string;
      type: TrackingTypesEnums;
      package_ids: number[];
    },
  ) => {
    setIsLoading(true);
    try {
      const response = await shippingService.shippingResource.attachTrackingId(
        productId,
        payload,
      );
      setIsLoading(false);
      setShipmentProductsData({ ...shipmentProductsData, refetch: true });
      setShipmentProductData({ ...shipmentProductData, refetch: true });
      setIsLoading(false);
      return response;
    } catch (error: any) {
      setError(error?.response?.data?.message);
      setIsLoading(false);
      throw error;
    }
  };

  return { attachTrackingId, isLoading, isError };
};

export const useShipmentProductUpdateTrackingId = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [shipmentProductsData, setShipmentProductsData] =
    useAtom(shipmentProductsAtom);
  const [shipmentProductData, setShipmentProductData] =
    useAtom(shipmentProductAtom);
  const updateTrackingId = async (
    shipmentProductId: number,
    trackingId: number,
    payload: {
      tracking: string;
      type: TrackingTypesEnums;
    },
  ) => {
    setIsLoading(true);
    try {
      const response = await shippingService.shippingResource.updateTrackingId(
        shipmentProductId,
        trackingId,
        payload,
      );
      setIsLoading(false);
      setShipmentProductsData({ ...shipmentProductsData, refetch: true });
      setShipmentProductData({ ...shipmentProductData, refetch: true });
      setIsLoading(false);
      return response;
    } catch (error: any) {
      setError(error?.response?.data?.message);
      setIsLoading(false);
      throw error;
    }
  };

  return { updateTrackingId, isLoading, isError };
};

export const useAddProductImage = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [shipmentProductsData, setShipmentProductsData] =
    useAtom(shipmentProductsAtom);
  const [shipmentProductData, setShipmentProductData] =
    useAtom(shipmentProductAtom);

  const addProductImage = async (payload: {
    shipment_product_ids: number[];
    images: string[];
  }) => {
    setIsLoading(true);
    try {
      const response =
        await shippingService.shippingResource.addProductImage(payload);
      setShipmentProductsData({ ...shipmentProductsData, refetch: true });
      setShipmentProductData({ ...shipmentProductData, refetch: true });
      setIsLoading(false);
      return response;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { addProductImage, isLoading, isError };
};

export const useApprovedShippingProduct = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [shipmentOrderCollectionData, setShipmentOrderCollectionData] = useAtom(
    shipmentOrderCollectionAtom,
  );
  const [shipmentProductData, setShipmentProductData] =
    useAtom(shipmentProductAtom);
  const approvedShippingProduct = async (shipmentProductId: number) => {
    setIsLoading(true);
    try {
      const response =
        await shippingService.shippingResource.approvedShipmentProduct(
          shipmentProductId,
        );
      setIsLoading(false);
      setShipmentOrderCollectionData({
        ...shipmentOrderCollectionData,
        refetch: true,
      });
      setShipmentProductData({
        ...shipmentProductData,
        refetch: true,
      });
      return response;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { approvedShippingProduct, isLoading, isError };
};

export const useApprovedShippingProductForProduct = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [shipmentProductsData, setShipmentProductsData] =
    useAtom(shipmentProductsAtom);
  const approvedShippingProduct = async (productId: number) => {
    setIsLoading(true);
    try {
      const response =
        await shippingService.shippingResource.approvedShipmentProduct(
          productId,
        );
      setIsLoading(false);
      setShipmentProductsData({
        ...shipmentProductsData,
        refetch: true,
      });
      return response;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { approvedShippingProduct, isLoading, isError };
};
export const useRejectedShippingProduct = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [shipmentOrderCollectionData, setShipmentOrderCollectionData] = useAtom(
    shipmentOrderCollectionAtom,
  );
  const [shipmentProductCollectionData, setShipmentProductCollectionData] =
    useAtom(shipmentProductsAtom);
  const rejectedShippingProduct = async (productId: number) => {
    setIsLoading(true);
    try {
      const response =
        await shippingService.shippingResource.rejectShipmentProduct(productId);
      setIsLoading(false);
      setShipmentOrderCollectionData({
        ...shipmentOrderCollectionData,
        refetch: true,
      });
      setShipmentProductCollectionData({
        ...shipmentProductCollectionData,
        refetch: true,
      });
      return response;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { rejectedShippingProduct, isLoading, isError };
};
export const useDefaultAgentAssign = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [shipmentOrderCollectionData, setShipmentOrderCollectionData] = useAtom(
    shipmentOrderCollectionAtom,
  );
  const [shipmentProductCollectionData, setShipmentProductCollectionData] =
    useAtom(shipmentProductsAtom);
  const defaultAgentAssign = async (payload: IDefaultAssignAgentPayload) => {
    setIsLoading(true);
    try {
      const response =
        await shippingService.shippingResource.agentDefaultAssign(payload);
      setIsLoading(false);
      setShipmentOrderCollectionData({
        ...shipmentOrderCollectionData,
        refetch: true,
      });
      setShipmentProductCollectionData({
        ...shipmentProductCollectionData,
        refetch: true,
      });
      return response;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { defaultAgentAssign, isLoading, isError };
};
export const useDefaultAgentAssignWithPrice = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const [shipmentOrderCollectionData, setShipmentOrderCollectionData] = useAtom(
    shipmentOrderCollectionAtom,
  );
  const [shipmentProductCollectionData, setShipmentProductCollectionData] =
    useAtom(shipmentProductsAtom);
  const defaultAgentAssignWithPrice = async (
    payload: IDefaultWithPriceAssignAgentPayload,
  ) => {
    setIsLoading(true);
    try {
      const response =
        await shippingService.shippingResource.agentDefaultWithPriceAssign(
          payload,
        );
      setIsLoading(false);
      setShipmentOrderCollectionData({
        ...shipmentOrderCollectionData,
        refetch: true,
      });
      setShipmentProductCollectionData({
        ...shipmentProductCollectionData,
        refetch: true,
      });
      return response;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { defaultAgentAssignWithPrice, isLoading, isError };
};

export const useCreateBid = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const createBid = async (payload: IOpenBidPayload, productId: number) => {
    setIsLoading(true);
    try {
      const response = await shippingService.shippingResource.createBid(
        payload,
        productId,
      );
      setIsLoading(false);
      return response;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { createBid, isLoading, isError };
};
export const useUpdateBid = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const updateBid = async (payload: IOpenBidPayload, bidId: number) => {
    setIsLoading(true);
    try {
      const response = await shippingService.shippingResource.updateBid(
        payload,
        bidId,
      );
      setIsLoading(false);
      return response;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { updateBid, isLoading, isError };
};
export const useAbandonBid = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const abandonBid = async (bidId: number) => {
    setIsLoading(true);
    try {
      const response = await shippingService.shippingResource.abandonBid(bidId);
      setIsLoading(false);
      return response;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { abandonBid, isLoading, isError };
};
export const useWinnerWarehouse = () => {
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");
  const winnerWarehouse = async (
    bidId: number,
    payload: { agent_warehouse_id: number },
  ) => {
    setIsLoading(true);
    try {
      const response = await shippingService.shippingResource.winnerWarehouse(
        bidId,
        payload,
      );
      setIsLoading(false);
      return response;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { winnerWarehouse, isLoading, isError };
};

// Shipment product add new package
export const useShipmentProductAddPackage = () => {
  const [shipmentOrderCollection, setShipmentOrderCollection] = useAtom(
    shipmentOrderCollectionAtom,
  );
  const [shipmentProduct, setShipmentProduct] = useAtom(shipmentProductAtom);
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");

  const addPackage = async (
    productId: number,
    payload: IShipmentProductAddPackageForm,
  ) => {
    setIsLoading(true);
    try {
      const response = await shippingService.shippingResource.addPackage(
        productId,
        payload,
      );
      setIsLoading(false);
      setShipmentOrderCollection({
        ...shipmentOrderCollection,
        refetch: true,
      });
      setShipmentProduct({
        ...shipmentProduct,
        refetch: true,
      });
      return response;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { addPackage, isLoading, isError };
};

// Shipment product add new package
export const useShipmentProductAttachPackageImage = () => {
  const [shipmentProduct, setShipmentProduct] = useAtom(shipmentProductAtom);
  const { shippingService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");

  const attachImage = async (
    shipmentProductId: number,
    payload: IShipmentProductAttachImageForm,
  ) => {
    setIsLoading(true);
    try {
      const response = await shippingService.shippingResource.attachImage(
        shipmentProductId,
        payload,
      );
      setIsLoading(false);
      setShipmentProduct({
        ...shipmentProduct,
        refetch: true,
      });
      return response;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };

  return { attachImage, isLoading, isError };
};
