import { Form, FormInstance, Input, Select } from "antd";
import { TrackingTypesEnums } from "enums/shipForMeContextEnums";
import { transformEnumToLabeledValue } from "utils/helpers";

interface IProps {
  form: FormInstance<any>;
  onOk: () => void;
}

export const TrackingModificationModal = ({ form, onOk }: IProps) => {
  return (
    <Form form={form} name="form" onFinish={onOk}>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Tracking is required",
          },
        ]}
        required
        name="tracking"
        label="Tracking"
      >
        <Input placeholder="Type tracking" />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Tracking type is required",
          },
        ]}
        required
        name="type"
        label="Type"
      >
        <Select
          placeholder="Select"
          options={transformEnumToLabeledValue(TrackingTypesEnums)}
        />
      </Form.Item>
    </Form>
  );
};
