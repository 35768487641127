import React, { useMemo, useRef, useState } from "react";
import { Spin, Form, TreeSelect } from "antd";
import type { TreeSelectProps } from "antd";
import client from "utils/api-client";
import { IShippingCategoryData } from "types/shippingCategory";
import { CarryOutOutlined } from "@ant-design/icons";
import {
  ServiceBaseUrl,
  ServiceNameEnum,
} from "lib/core/apiServices/servicBaseUrl";
import { filterResourceEnum } from "enums/filterResourceEnum";

type TreeSelectd = {
  value: string;
  title: string;
  children: TreeSelectd[];
};

type ShippingCategoryDataModel = {
  label: string;
  value: string;
  children: ShippingCategoryDataModel[];
};

const getShippingCategoryTreeIds = (
  options: ShippingCategoryDataModel[],
): TreeSelectd[] => {
  const treeData: TreeSelectd[] = [];

  function buildTree(dataItem: ShippingCategoryDataModel): TreeSelectd {
    const node: TreeSelectd = {
      value: dataItem.value,
      title: dataItem.label,
      children: [],
    };

    console.log(node);

    if (dataItem.children && dataItem.children.length > 0) {
      dataItem.children.forEach((childItem: ShippingCategoryDataModel) => {
        const childNode = buildTree(childItem);
        node.children.push(childNode);
      });
    }
    return node;
  }

  options.forEach((dataItem) => {
    const treeItem = buildTree(dataItem);
    treeData.push(treeItem);
  });

  return treeData;
};

const debounce = <F extends (...args: any[]) => void>(
  fn: F,
  T: number,
): (() => void) => {
  let timer: NodeJS.Timeout | null;

  return function (...args: any[]) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, T);
  };
};

export interface DebounceTreeSelectProps<ValueType = any>
  extends Omit<TreeSelectProps<ValueType>, "options" | "children"> {
  fetchOptions: (search: string) => Promise<ShippingCategoryDataModel[]>;
  debounceTimeout?: number;
  resource: string;
}

function DebounceTreeSelect<
  ValueType extends { value: string | number } = any,
>({
  fetchOptions,
  debounceTimeout = 800,
  ...props
}: DebounceTreeSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ShippingCategoryDataModel[]>([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  // Convert the fetched options to treeData format
  const treeData = useMemo(
    () => getShippingCategoryTreeIds(options),
    [options],
  );

  return (
    <TreeSelect<ValueType>
      showSearch
      labelInValue
      allowClear
      filterTreeNode={false}
      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      treeData={treeData}
      treeLine={{ showLeafIcon: true }}
      treeIcon={<CarryOutOutlined />}
    />
  );
}

async function fetchUserList(
  resource: string,
  username: string,
): Promise<ShippingCategoryDataModel[]> {
  let endpoint;

  if (resource === filterResourceEnum.SHIPPING_CATEGORY) {
    endpoint = `${ServiceBaseUrl.getServiceBaseUrl(ServiceNameEnum.COMMERCE_SERVICE)}/api/shipping-core/admin/shipping-category/v1/shipping-categories?expand=children.children&shipping-categories=${username}`;
  } else {
    throw new Error("Unsupported resource type");
  }

  const response = await client.get(endpoint);
  const users = response.data;

  return users.data.map((user: IShippingCategoryData) => ({
    label: `${user.name}`,
    value: user.id,
    Children: user.children,
  }));
}

export const ShippingCategoryTreeSelect = ({
  value,
  allowClear,
  resource,
  showSearch,
  placeholder,
  onChange,
  style,
  ...props
}: Omit<DebounceTreeSelectProps, "fetchOptions">) => {
  const form = Form.useFormInstance();
  const handleChangeUser = async (
    newValue:
      | { label: React.ReactNode; value: string | number }
      | { label: React.ReactNode; value: string | number }[],
    option: any,
    extra: any,
  ) => {
    if ("label" in newValue) {
      form.setFieldsValue({ user: newValue.value });
    }
    if (onChange) {
      onChange(newValue, option, extra);
    }
  };

  return (
    <DebounceTreeSelect
      resource={""}
      value={value}
      allowClear={allowClear}
      showSearch={showSearch}
      placeholder={placeholder}
      fetchOptions={(username) => fetchUserList(resource, username)}
      onChange={handleChangeUser}
      style={style}
      {...props}
    />
  );
};

export default ShippingCategoryTreeSelect;
