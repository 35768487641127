import React, { useState, useEffect } from 'react'
import { PageHeader } from '@ant-design/pro-layout';
import {
    Descriptions,
    Col,
    Row,
    Divider,
    Table,
    Button,
    Image,
    Skeleton,
    message,
    Collapse,
    Timeline,
    Space,
    Tag,
    Spin,
    Tooltip,
} from 'antd';
import './BuyShipProductDetails.css'
import Date from "../Date"
import {
    DeleteOutlined,
} from '@ant-design/icons'
import { OrderInvoices, ShippingProductDetails, StatusTag } from 'components'
import { getOrderInvoices, getShippingInfo } from 'utils/buy-invoice-client'
import { getBuyProductDetails, removeCharge } from "utils/buy-products"
import { formatDate, formatTime } from "utils/helpers.js";
import {matchPath, useLocation, useParams} from "react-router-dom";
import IssueDetails from "components/IssueDetails/IssueDetails";

const { Column } = Table;

const { Panel } = Collapse;

const BuyShipProductDetails = Props => {
    const {id} = useParams();

    const { product: selectedProduct } = Props

    let productValue = selectedProduct?.id

    let location = useLocation();

    const match = matchPath(location.pathname, {
        path: "/buy-product/:id",
        exact: true,
        strict: false
    });

    if (match) {
        productValue = id
    }

    const [product, setProduct] = useState(null)
    const [isProductLoading, setIsProductLoading] = useState(true)
    const [invoices, setInvoices] = useState(null)
    const [isInvoiceLoading, setIsInvoiceLoading] = useState(false)
    const [shipmentProduct, setShipmentProduct] = useState(null);
    const [isShipmentLoading, setIsShipmentLoading] = useState(false)
    const [isChargeRemoving, setIsChargeRemoving] = useState(false)
    const agentCompany = product?.agentCompany ? product.agentCompany : null

    const fetchShipmentInfo = React.useCallback(
        () => {
            setIsShipmentLoading(true)
            getShippingInfo(product.id)
                .then(res => {
                    setShipmentProduct(res.data.data)
                    setIsShipmentLoading(false)
                })
                .catch(err => {
                    setIsShipmentLoading(false)
                })
        },
        [product],
    )

    const fetchProduct = React.useCallback(
        () => {
            setIsProductLoading(true)
            getBuyProductDetails(productValue)
                .then(res => {
                    setProduct(res.data.data)
                    setIsProductLoading(false)
                })
                .catch(err => {
                    setIsProductLoading(false)
                })
        },
        [productValue],
    )

    const removeProductCharge = React.useCallback(
        (chargeId) => {
            setIsChargeRemoving(true)
            removeCharge(chargeId)
                .then(res => {
                    message.success('Charge removed successfully!')
                    setIsChargeRemoving(false)
                    fetchProduct()
                })
                .catch(err => {
                    message.error('Failed to remove!')
                    setIsChargeRemoving(false)
                })
        },
        [fetchProduct],
    )

    const fetchInvoices = React.useCallback(
        () => {
            setIsInvoiceLoading(true)
            getOrderInvoices(product?.order_id)
                .then(res => {
                    setInvoices(res.data.data)
                    setIsInvoiceLoading(false)
                })
                .catch(err => {
                    setIsInvoiceLoading(false)
                })
        },
        [product?.order_id],
    )

    useEffect(() => {
        fetchProduct()
        return () => {
            setInvoices(null)
            setShipmentProduct(null)
        }
    }, [productValue, fetchProduct])

    const moveOn_commission = product?.mv_comm

    const updateSize = (url, size) => {
        return url.replace(/_\d+x\d+/, `_${size}x${size}`);
    }

    if (isProductLoading) return <Skeleton active />

    const totalQuantity = product?.itemMetas.reduce((acc, value) => {
        if (value.qty === '') {
            value = 0
        } else {
            value = parseInt(value.qty)
        }
        return acc + (value)
    }, 0)

    const totalPrice = product?.itemMetas.reduce((acc, value) => {
        let basePrice = (value?.price * 100) / (100 + moveOn_commission)
        if (value.price === '') {
            value = 0
        } else {
            value = parseFloat(basePrice * value.qty)
        }
        return acc + (value)
    }, 0)

    const totalPriceBdt = product?.itemMetas.reduce((acc, value) => {
        if (value.productPrice === '') {
            value = 0
        } else {
            value = parseFloat(value.productPrice * value.qty)
        }
        return acc + (value)
    }, 0)

    const totalCharges = product?.charges.reduce((acc, value) => {
        return acc + (value.amount)
    }, 0)

    if (isProductLoading && !product) {
        return <Skeleton />
    }

    return (
        <Spin spinning={isProductLoading}>
            <PageHeader ghost={false} className="site-page-header-responsive" title={`Product #${product?.product_number}`}>
                <Row>
                    <Col span={24}>
                        <Descriptions title="Product Info">
                            <Descriptions.Item label="Product Number">{product.product_number}</Descriptions.Item>
                            <Descriptions.Item label="Status">
                                <StatusTag text={product?.status_label} slug={product?.status} />
                            </Descriptions.Item>
                            <Descriptions.Item label="Category">{product.shipping.category}</Descriptions.Item>
                            <Descriptions.Item
                                label="Fx Rate">{(product.fx + (product.fx * (product.mv_comm / 100))).toFixed(2)}</Descriptions.Item>
                            <Descriptions.Item label="Price">{product.price} {product.currency}</Descriptions.Item>
                            {/*<Descriptions.Item label="MoveOn Commission">{product.mv_comm}</Descriptions.Item>*/}
                            <Descriptions.Item label="Vendor">{product.vendor}</Descriptions.Item>
                            <Descriptions.Item label="Total">{product.total}</Descriptions.Item>
                            <Descriptions.Item label="Note">{product.note}</Descriptions.Item>
                            <Descriptions.Item label="Title">{product.title}</Descriptions.Item>
                            {
                                product.link &&
                                <Descriptions.Item label="Link">
                                    <a href={product.link}>{product.link}</a> <br />
                                </Descriptions.Item>
                            }
                        </Descriptions>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={24}>
                        <Descriptions title="Buy Agent">
                            <Descriptions.Item label="Name">{agentCompany?.name}</Descriptions.Item>
                            <Descriptions.Item label="IAddress">{agentCompany?.address}</Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Divider />
                {
                    <Table
                        dataSource={product.itemMetas}
                        bordered={false}
                        pagination={false}
                        summary={pageData => {
                            return (
                                <>
                                    {
                                        product?.charges && product.charges.map((item, index) => {
                                            return (
                                                <Table.Summary.Row key={index + "charge"}>
                                                    <Table.Summary.Cell colSpan={4}>{item.type_label}</Table.Summary.Cell>
                                                    <Table.Summary.Cell align={"center"}>
                                                        <span>{(item.amount).toFixed(2)}</span>
                                                        <Button
                                                            style={{ margin: '15px' }}
                                                            size="small"
                                                            icon={<DeleteOutlined />}
                                                            disabled={isChargeRemoving}
                                                            onClick={() => {
                                                                removeProductCharge(item.id)
                                                            }}
                                                        />
                                                    </Table.Summary.Cell>
                                                </Table.Summary.Row>
                                            )
                                        })

                                    }
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell colSpan={2}>Total</Table.Summary.Cell>
                                        <Table.Summary.Cell align={"center"}>
                                            <span>{totalQuantity}</span>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell align={"center"}>
                                            <span>{(totalPrice).toFixed(2)}</span>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell align={"center"}>
                                            <span>{(totalPriceBdt + totalCharges).toFixed(2)}</span>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </>
                            );
                        }}
                    >
                        <Column
                            title="#SL"
                            dataIndex="id"
                            key="SL"
                            align={"center"}
                            render={(key, record, index) => (
                                index + 1
                            )}
                        />
                        <Column
                            title="Meta"
                            dataIndex="itemMetas"
                            key="meta"
                            // align={"center"}
                            render={(meta, record) => {
                                let calculateCommission = (((product?.mv_comm * record?.fx) / 100) + record?.fx)

                                return (
                                    <>
                                        {
                                            record?.meta?.map((itemVariation, itemVariationID) => {
                                                return (
                                                    <Row key={`varItem-${itemVariationID}`}>
                                                        <Col span={6}>
                                                            {
                                                                itemVariation?.image &&
                                                                <Image
                                                                    shape="square"
                                                                    width={50}
                                                                    src={updateSize(itemVariation?.image, 800)}

                                                                />
                                                            }

                                                        </Col>
                                                        <Col span={18}>
                                                            <Space size={2} direction="vertical">
                                                                {
                                                                    itemVariation?.title &&
                                                                    <div className="warehouse-address ml-lg-4">
                                                                        <div>
                                                                            {itemVariation?.title}: <span style={{ color: "black", fontWeight: "bold" }}>{itemVariation?.value}</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <span style={{ color: "black", fontWeight: "bold" }}>SKU: {record?.sku_id}</span>
                                                                <span style={{ color: "black", fontWeight: "bold" }}>Key: {record?.key}</span>
                                                                <span>FX : <Tag color="#f5222d">{calculateCommission?.toFixed(3)}</Tag></span>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                        }
                                    </>
                                )
                            }}
                        />
                        <Column
                            title="Quantity"
                            dataIndex="qty"
                            key="qty"
                            align={"center"}
                            render={(qty, record) => (
                                record && record.qty
                            )}
                        />
                        <Column
                            title="Price (USD/RMB)"
                            dataIndex="price"
                            key="price"
                            align={"center"}
                            render={(price, record) => {
                                let basePrice = (record?.price * 100) / (100 + moveOn_commission)

                                return (
                                    (basePrice * record?.qty).toFixed(2)
                                )
                            }}
                        />
                        <Column
                            title="Price (BDT)"
                            dataIndex="productPrice"
                            key="productPrice"
                            align={"center"}
                            render={(productPrice, record) => (
                                (record?.productPrice * record.qty).toFixed(2)
                            )}
                        />
                    </Table>

                }
                <Divider />
                {
                    product.order &&
                    <>
                        <Row>
                            <Col span={24}>
                                <Descriptions title="Order Info">
                                    <Descriptions.Item label="Order Number">{product.order.order_number}</Descriptions.Item>
                                    <Descriptions.Item label="Order Type">{product.order.type}</Descriptions.Item>
                                    <Descriptions.Item label="Order Created At"><Date
                                        date={product.order.created_at} /></Descriptions.Item>
                                </Descriptions>
                                <Descriptions title="Order Handler">
                                    <Descriptions.Item label="Name">{product?.order?.orderHandler?.name}</Descriptions.Item>
                                    <Descriptions.Item
                                        label="Phone">{product?.order?.orderHandler?.phone}</Descriptions.Item>
                                    <Descriptions.Item
                                        label="Email">{product?.order?.orderHandler?.email}</Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                        <Divider />
                    </>
                }
                {
                    product?.order?.customer &&
                    <>
                        <Row>
                            <Col span={24}>
                                <Descriptions title="Customer Info">
                                    <Descriptions.Item label="Name">
                                      {product.order.customer.name}
                                      <div style={{ paddingLeft: '10px' }}>
                                        {
                                          product.order.customer.badges && product.order.customer.badges.length > 0 ?
                                            product.order.customer.badges.map(x => <Tooltip title={x.description}>
                                              <Tag color={x.color} key={x.id}>
                                                {x.title}
                                              </Tag>
                                            </Tooltip>)
                                            :
                                            null
                                        }
                                      </div>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Email">{product.order.customer.email}</Descriptions.Item>
                                    <Descriptions.Item label="Phone">{product.order.customer.phone}</Descriptions.Item>
                                    <Descriptions.Item label="Shipping make">{product.order.customer.shipping_mark}</Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                        <Divider />
                    </>
                }

                {
                    product?.shipping &&
                    <>
                        <Row>
                            <Col span={24}>
                                <Descriptions title="Shipping Info">
                                    <Descriptions.Item label="Category">{product.shipping.category}</Descriptions.Item>
                                    <Descriptions.Item
                                        label="Shipping Company">{product.shipping.company}</Descriptions.Item>
                                    <Descriptions.Item
                                        label="Rate">{product.shipping_rate}/- {product.shipping.unit_type}</Descriptions.Item>
                                    <Descriptions.Item
                                        label="Shipping Type">{product.shipping.shipping_type}</Descriptions.Item>
                                    <Descriptions.Item label="Warehouse">{product.shipping.warehouse}</Descriptions.Item>
                                    <Descriptions.Item
                                        label="Warehouse IAddress">{product.shipping.warehouse_address}</Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                        <Divider />
                    </>
                }

                <Descriptions title="Images">
                </Descriptions>
                {
                    <Image
                        width={100}
                        src={product?.store?.storeId === 1 ? updateSize(product.image, 100) : updateSize(product.image, 800)}
                        alt={product.title}
                    />
                }
                <Divider />
                <Descriptions title="Product Log">
                </Descriptions>

                {
                    product?.logs.length > 1 &&
                    <Collapse defaultActiveKey={['0']}>
                        <Panel header="View Product Log" key="1">
                            <Timeline>
                                {
                                    product?.logs?.map((logView, index) => {
                                        return (
                                            <Timeline.Item key={index + "timeline"} color="green">{logView?.instruction} - <span
                                                className="product_details">{formatDate(logView.created_at)} {formatTime(logView.created_at)} </span></Timeline.Item>

                                        )
                                    })
                                }
                            </Timeline>
                        </Panel>
                    </Collapse>
                }

                <Divider />
                <Row>
                    <Col span={24} style={{ marginTop: 20, marginBottom: 20 }}>
                        {
                            !invoices && <Button type="primary" onClick={() => fetchInvoices()} loading={isInvoiceLoading}
                                                 disabled={isInvoiceLoading}>
                                Show invoices
                            </Button>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ marginTop: 20, marginBottom: 20 }}>
                        {
                            invoices && <OrderInvoices data={invoices} />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col span={8} style={{ marginTop: 20, marginBottom: 20 }}>
                        {
                            !shipmentProduct &&
                            <Button type="primary" onClick={() => fetchShipmentInfo()} loading={isShipmentLoading}
                                    disabled={isShipmentLoading}>
                                Show Shipment Info
                            </Button>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ marginTop: 20, marginBottom: 20 }}>
                        {
                            shipmentProduct && <ShippingProductDetails shippingInfo={shipmentProduct.shipmentProduct} />
                        }
                    </Col>
                </Row>

                {/*<Row>
                    <Col span={24} style={{ marginTop: 20, marginBottom: 20 }}>
                        {
                            !issueList && <Button type="primary" onClick={() => fetchIssueList()} loading={isIssueLoading}
                                                  disabled={isIssueLoading}>
                                Show Issue List
                            </Button>
                        }
                    </Col>
                </Row>*/}

                <Row>
                    <Col span={24} style={{ marginTop: 20, marginBottom: 20 }}>
                        {/*{
                            issueList && <IssueProductList issueResponse={issueList} selectedProduct={product} />
                        }*/}
                        {
                            product && <IssueDetails itemsDetails={product} cameFrom="item_details"/>
                        }
                    </Col>
                </Row>
            </PageHeader>
        </Spin>
    );
}

export default BuyShipProductDetails;
