import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import { Button, Dropdown, MenuProps, Modal, notification } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";
import {
  AppstoreOutlined,
  CheckSquareOutlined,
  DownOutlined,
  HddFilled,
  PlusOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import clsx from "clsx";

import {
  StyledProductHeader,
  StyledProductHeaderBtn,
  StyledProductHeaderLeft,
  StyledProductHeaderRight,
  StyledProductHeaderSearch,
  StyledProductHeaderShowingText,
} from "./index.styled";
import { VIEW_TYPE } from "../index";
import {
  campaignProductCollectionAtom,
  productSearchResultAtom,
} from "lib/core-react/store/store";
import { ProductSearchResultModel } from "models/productDetailCollectionModel";
import { CampaignProductCollectionModel } from "models/campaignProductCollectionModel";
import { CampaignOperationEnum } from "enums/campaignCollectionEnums";

import { AddProductToCampaign } from "./components";
import { BuyOrderOperationEnum } from "enums/buyOrderCollectionEnums";
import { useDeleteCampaignPrdoucts } from "lib/core-react/hooks/private/useCampaign";
import { IDeleteCampaginProducts } from "types/campaignCollection";

type Props = {
  onSearch: (data: string) => void;
  viewType: string;
  setViewType: (data: string) => void;
  defaultSearchValue: string | undefined;
  selectedProducts: string[];
  handleSelectAllProducts: () => void;
};
const ProductHeader = ({
  onSearch,
  viewType,
  setViewType,
  defaultSearchValue,
  selectedProducts,
  handleSelectAllProducts,
}: Props) => {
  const location = useLocation();
  const operation = new URLSearchParams(location.search).get("operation");
  const region = new URLSearchParams(location.search).get("region");
  const campaignId = new URLSearchParams(location.search).get("campaignId");
  const id = new URLSearchParams(location.search).get("id");
  const { deleteCampaignProducts, isLoading, isError } =
    useDeleteCampaignPrdoucts();
  const [isShowAddToCampaignModal, setIsShowAddToCampaignModal] =
    useState(false);

  // Product list
  const [{ data }] = useAtom(productSearchResultAtom);
  const productList = data && new ProductSearchResultModel(data);

  // Product list (campaign)
  const [{ data: campaignData }] = useAtom(campaignProductCollectionAtom);
  const campaignProductList =
    campaignData && new CampaignProductCollectionModel(campaignData);

  const handleMenuClick = (e: MenuInfo) => {
    if (e.key === CampaignOperationEnum.AddCampaignProduct) {
      setIsShowAddToCampaignModal(true);
    }
  };

  const onConfirmDelete = async () => {
    const payload: IDeleteCampaginProducts = {
      ids: selectedProducts,
    };

    try {
      await deleteCampaignProducts(id as string, payload);
      notification["success"]({
        message: "Deleted Compagins Products successfully",
      });
    } catch (error: any) {
      if (error?.response?.data?.message) {
        notification["error"]({
          message: error.response.data.message,
        });
      }
    }
  };

  const items: MenuProps["items"] = [
    {
      icon: <PlusOutlined />,
      label: "Add to campaign",
      key: CampaignOperationEnum.AddCampaignProduct,
      disabled:
        operation !== CampaignOperationEnum.AddCampaignProduct ||
        !selectedProducts.length ||
        !campaignId,
    },
    {
      icon: <PlusOutlined />,
      label: "Delete campaign Product",
      key: CampaignOperationEnum.ViewCampaignProduct,
      onClick: () => {
        onConfirmDelete();
      },
      disabled: operation !== CampaignOperationEnum.ViewCampaignProduct,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const paginationFrom =
    operation === "viewProduct"
      ? campaignProductList?.getPagination().getFrom()
      : productList?.getPagination().getFrom();
  const paginationTo =
    operation === "viewProduct"
      ? campaignProductList?.getPagination().getTo()
      : productList?.getPagination().getTo();
  const paginationTotal =
    operation === "viewProduct"
      ? campaignProductList?.getPagination().getTotal()
      : productList?.getPagination().getTotal();

  return (
    <>
      <StyledProductHeader>
        <StyledProductHeaderLeft>
          <h3>
            {operation === CampaignOperationEnum.AddCampaignProduct
              ? "Select Products"
              : operation === CampaignOperationEnum.ViewCampaignProduct
                ? "Campaign Product List"
                : operation === BuyOrderOperationEnum.AddBuyOrderProduct
                  ? "Select a Product"
                  : operation === BuyOrderOperationEnum.PlaceBuyOrder
                    ? "Select a Product"
                    : "Product List"}
          </h3>
          {!operation && (
            <StyledProductHeaderShowingText className="text-truncate">
              (Showing {paginationFrom || 0} - {paginationTo || 0} products of{" "}
              {paginationTotal || 0} products)
            </StyledProductHeaderShowingText>
          )}
        </StyledProductHeaderLeft>
        <StyledProductHeaderRight>
          {(operation === CampaignOperationEnum.AddCampaignProduct ||
            operation === CampaignOperationEnum.ViewCampaignProduct) && (
            <div style={{ marginRight: "16px" }}>
              <Dropdown menu={menuProps}>
                <Button style={{ height: "36px" }} icon={<HddFilled />}>
                  Actions <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          )}
          {(operation === CampaignOperationEnum.AddCampaignProduct ||
            operation === CampaignOperationEnum.ViewCampaignProduct) && (
            <div style={{ marginRight: "16px" }}>
              <Button
                style={{ height: "36px" }}
                icon={<CheckSquareOutlined />}
                onClick={handleSelectAllProducts}
              >
                Select All
              </Button>
            </div>
          )}

          {operation !== CampaignOperationEnum.ViewCampaignProduct && (
            <StyledProductHeaderSearch
              placeholder="Search here"
              defaultValue={defaultSearchValue}
              onSearch={onSearch}
            />
          )}
          <StyledProductHeaderBtn
            className={clsx({
              active: viewType === VIEW_TYPE.LIST,
            })}
            onClick={() => setViewType(VIEW_TYPE.LIST)}
          >
            <UnorderedListOutlined />
          </StyledProductHeaderBtn>
          <StyledProductHeaderBtn
            className={clsx({
              active: viewType === VIEW_TYPE.GRID,
            })}
            onClick={() => setViewType(VIEW_TYPE.GRID)}
          >
            <AppstoreOutlined />
          </StyledProductHeaderBtn>
        </StyledProductHeaderRight>
      </StyledProductHeader>

      {campaignId && region && (
        <Modal
          title="Add Configuration"
          open={isShowAddToCampaignModal}
          onCancel={() => setIsShowAddToCampaignModal(false)}
          footer={null}
        >
          <AddProductToCampaign
            setIsShowAddToCampaignModal={setIsShowAddToCampaignModal}
            region={region}
            campaignId={campaignId}
            selectedProducts={selectedProducts}
          />
        </Modal>
      )}
    </>
  );
};

export default ProductHeader;
