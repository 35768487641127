import ChunkedUploady from "@rpldy/chunked-uploady";
import { DivUploadButton } from "./DivUploadButton";
import { FILE_UPLOAD_URL, FILE_UPLOAD_URL_TOKEKN } from "consts/storage";
import setAndGetGuestData from "helpers/setAndGetGuestData";

interface IProps {
  form: any;
  fieldName: string;
  accept?: string;
  uploadUrl?: string;
}
export const MultiChunkUpload = ({
  form,
  fieldName,
  accept = "image/jpeg,image/png,image/jpg,image/gif,image/webp",
  uploadUrl,
}: IProps) => {
  return (
    <ChunkedUploady
      accept={accept}
      multiple={true}
      chunkSize={204800}
      method="POST"
      destination={{
        url: FILE_UPLOAD_URL,
        headers: {
          Accept: "application/json",
          Authorization: FILE_UPLOAD_URL_TOKEKN,
          "X-GUEST-ID": setAndGetGuestData()?.guestId || "",
          "X-GUEST-SESSION": setAndGetGuestData()?.guestSession || "",
        },
      }}
    >
      <DivUploadButton form={form} fieldName={fieldName} />
    </ChunkedUploady>
  );
};
