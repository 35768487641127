import { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import {
  Alert,
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  message,
  Card, Col
} from "antd";
import {DeleteTwoTone, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { useAtom } from "jotai";
import { useGetRegion } from "lib/core-react/hooks/private";
import { regionRegion, storeStoreAtom } from "lib/core-react/store/store";
import {
  renderOptionsFromEnum,
  renderOptionsFromEnumReversed,
} from "components/Form/forms";
import { RegionModel } from "models/region";
import { useCreateExchange } from "lib/core-react/hooks/private/useExchange";
import { useGetStore } from "lib/core-react/hooks/private/useStore";
import { StoreCollectionModel } from "models/storeCollectionModel";
import { ExchangeListPostRequestModel } from "models/exchangeListCollectionModel";
import { CommissionRateTypeEnums } from "enums/exchangeListCollectionEnums";
import {useNavigate} from "react-router-dom";

const CreateStoreExchange = () => {
  const { getRegion } = useGetRegion();
  const navigate = useNavigate()
  const [{ data: regionDataAtom, isLoading: regionLoading }] =
    useAtom(regionRegion);
  const { getStore } = useGetStore();
  const [{ data: storeDataAtom, isLoading: storeLoading }] =
    useAtom(storeStoreAtom);

  const { createExchange, isLoading, isError } = useCreateExchange();
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  useEffect(() => {
    getRegion("per_page=200");
    getStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const regionData = regionDataAtom && new RegionModel(regionDataAtom);
  const storeData = storeDataAtom && new StoreCollectionModel(storeDataAtom);

  const onFinish = async (value: ExchangeListPostRequestModel) => {
    const payload = {
      name: value.name,
      is_default: value.is_default ?? false,
      regional_exchanges: value.regional_exchanges,
    };

    try {
      const response = await createExchange(
        payload as ExchangeListPostRequestModel,
      );
      setIsErrorVisible(false);
      message.success(response);
      navigate("/store-manage/store-exchanges")
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  return (
    <PageHeader
      style={{ marginTop: "10px" }}
      ghost={false}
      title="Create an Exchange List"
      onBack={() => window.history.back()}
    >
      <Card title={" Create Form"}  style={{margin:"10px 0px"}}>
      <Form
        layout="vertical"
        form={form}
        name="create-exchange-form"
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          regional_exchanges: [
            {
              store_exchanges: [
                {
                  store_id: undefined,
                },
              ],
            },
          ],
        }}
      >
        {/* Exchange Name */}
        <Form.Item
          rules={[
            {
              required: true,
              message: "Exchange name is required",
            },
          ]}
          label="Name"
          name="name"
        >
          <Input placeholder="Exchange name" />
        </Form.Item>

        {/* Regional Exchanges */}
        <Form.List name="regional_exchanges">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (

                <Card
                  key={field.key}
                  size="small"
                  title={`Region #${index + 1}`}
                  extra={index > 0 && (
                    <MinusCircleOutlined onClick={() => remove(field.name)} style={{ color: "red" }} />
                  )}
                  style={{ marginBottom: 16, border:"4px solid #DFFFED" }}
                >
                <Space
                  key={uuidv4()}
                  direction="vertical"
                  size="middle"
                  style={{
                    display: "flex",
                    margin: "0 10px",
                  }}
                >
                  <Row
                    justify="space-between"
                    style={{
                      padding: 5,
                    }}
                  >
                    {/*<Typography.Text mark>Region #{index + 1}</Typography.Text>*/}
                    {index > 0 && (
                      <DeleteTwoTone
                        twoToneColor="hotpink"
                        onClick={() => remove(field.name)}
                      />
                    )}
                  </Row>
                  {/* Region */}
                  <Form.Item
                    rules={[{ required: true, message: "Region is required" }]}
                    name={[field.name, "region_id"]}
                    label="Region"
                  >
                    <Select
                      loading={regionLoading}
                      placeholder="Please select a region"
                    >
                      {regionData?.getRegionIdsAsObject() &&
                        renderOptionsFromEnum(
                          regionData?.getRegionIdsAsObject(),
                        )}
                    </Select>
                  </Form.Item>

                  {/* Store Exchanges */}
                  <Form.List name={[field.name, "store_exchanges"]}>
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <Card
                            key={field.key}
                            size="small"
                            title={`Store #${index + 1}`}
                            extra={index > 0 && (
                              <MinusCircleOutlined onClick={() => remove(field.name)} style={{ color: "red" }} />
                            )}
                            style={{ marginBottom: 16, border:"2px solid #DFFFED" }}
                          >

                          <Space
                            key={uuidv4()}
                            direction="vertical"
                            size="middle"
                            style={{
                              display: "flex",
                              margin: "0 10px",
                            }}
                          >
                            <Row
                              justify="space-between"
                              style={{
                                padding: 5,
                              }}
                            >
                              {index > 0 && (
                                <DeleteTwoTone
                                  twoToneColor="hotpink"
                                  onClick={() => remove(field.name)}
                                />
                              )}
                            </Row>
                            {/* Store */}

                            <Row gutter={16}>
                              <Col xs={24}  sm={24} md={12} lg={8}>
                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                      message: "Store is required",
                                    },
                                  ]}
                                  name={[field.name, "store_id"]}
                                  label="Store"
                                >
                                  <Select
                                    loading={storeLoading}
                                    placeholder="Please select a store"
                                  >
                                    {storeData?.getStoreIdsAsObject() &&
                                      renderOptionsFromEnum(
                                        storeData?.getStoreIdsAsObject(),
                                      )}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={8}>
                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                      message: "FX rate is required",
                                    },
                                  ]}
                                  label="FX Rate"
                                  name={[field.name, "fx_rate"]}
                                >
                                  <InputNumber
                                    placeholder="FX Rate"
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>

                              </Col>
                              <Col xs={24} sm={24} md={12} lg={8}>
                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                      message: "Commission rate is required",
                                    },
                                  ]}
                                  label="Commission Rate"
                                  name={[field.name, "commission_rate"]}
                                >
                                  <InputNumber
                                    placeholder="Commission Rate"
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>

                              </Col>
                             <Col xs={24} sm={24} md={12} lg={8}>
                               <Form.Item
                                 rules={[
                                   {
                                     required: true,
                                     message: "Commission rate type is required",
                                   },
                                 ]}
                                 label="Commission Rate Type"
                                 name={[field.name, "commission_rate_type"]}
                               >
                                 <Select placeholder="Please select a commission rate type">
                                   {renderOptionsFromEnumReversed(
                                     CommissionRateTypeEnums,
                                   )}
                                 </Select>
                               </Form.Item>

                             </Col>

                              <Col xs={24} sm={24} md={12} lg={8}>
                                <Form.Item
                                  rules={[
                                    {
                                      required: true,
                                      message: "Minimum order amount is required",
                                    },
                                  ]}
                                  label="Minimum Order Amount"
                                  name={[
                                    field.name,
                                    "options",
                                    "minimum_order_amount",
                                  ]}
                                >
                                  <InputNumber
                                    placeholder="Minimum Order Amount"
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Space>
                            </Card>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add exchange for another store
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Space>
                </Card>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add exchange for another region
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item name="is_default" valuePropName="checked">
          <Checkbox>Set as Default</Checkbox>
        </Form.Item>

        <Form.Item>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isError}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
      </Card>
    </PageHeader>
  );
};

export default CreateStoreExchange;
