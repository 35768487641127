import { useEffect } from "react";
import { useAtom } from "jotai";
import { Space, Table, Tag, Typography } from "antd";

import { purchaseCommissionCollectionAtom } from "lib/core-react/store/store";
import useDataFilters from "hooks/useDataFilters";
import { AgentCompanyModel } from "models/agentCompanyCollectionModel";
import { ApiHelperModel } from "models/apiHelper";
import { PaginationModel } from "models/pagination";
import { usePurchaseCommissionGet } from "lib/core-react/hooks/private";
import {
  PurchaseCommissionCollectionModel,
  PurchaseCommissionModel,
} from "models/purchaseCommissionCollectionModel";
import { BuyProductCommissionRateTypeEnum } from "enums/purchaseCommissionCollectionEnum";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import { formatDate } from "utils/helpers";

interface IProps {
  data: AgentCompanyModel;
}

export const PurchaseCommissionTable = ({ data }: IProps) => {
  const { getPurchaseCommission } = usePurchaseCommissionGet(data.getId());
  const [{ data: purchaseCommissionCollectionData, isLoading, refetch }] =
    useAtom(purchaseCommissionCollectionAtom);
  const { filters, handleFilterChange, isFirstCall, isFetched } =
    useDataFilters();
  const { isMobile } = useWindowWidth();
  const { Text } = Typography;

  // Api Call
  useEffect(() => {
    if ((filters && !isFetched && isFirstCall) || refetch) {
      ApiHelperModel.makeGetRequest({}, getPurchaseCommission);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filters]);

  const PurchaseCommissionCollectionData =
    purchaseCommissionCollectionData &&
    new PurchaseCommissionCollectionModel(purchaseCommissionCollectionData);

  // Pagination
  const paginationData = PurchaseCommissionCollectionData?.getPagination();

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getPurchaseCommission,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    PurchaseCommissionCollectionData,
    handlePaginationChange,
  );

  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "id",
      width: 70,
      align: "center" as const,
      render: (_: string, __: PurchaseCommissionModel, index: number) => {
        return (
          <>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </>
        );
      },
    },
    {
      title: "Commission Details",
      dataIndex: "store",
      hidden: !isMobile,
      key: "store",
      width: 100,
      render: (_: string, record: PurchaseCommissionModel) => {
        return (
          <>
            <Text>
              Store:{" "}
              <Typography.Text>{record.getStore()?.getName()}</Typography.Text>
            </Text>
            <br />
            <Text>
              Rate:{" "}
              <Typography.Text>
                {record.getCommissionRate()}
                {record.getCommissionRateType() ===
                BuyProductCommissionRateTypeEnum.PERCENTAGE
                  ? "%"
                  : "(Fixed)"}
              </Typography.Text>
            </Text>
            <br />
            {record.getCreatedAt() ? (
              <Tag color="purple">{formatDate(record.getCreatedAt()!)}</Tag>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      title: "Store",
      dataIndex: "store",
      hidden: isMobile,
      key: "store",
      width: 100,
      render: (_: string, record: PurchaseCommissionModel) => {
        return (
          <>
            <Typography.Text>{record.getStore()?.getName()}</Typography.Text>
          </>
        );
      },
    },
    {
      title: "Rate",
      dataIndex: "commission_rate",
      key: "commission_rate",
      hidden: isMobile,
      width: 30,
      align: "center" as const,
      render: (_: string, record: PurchaseCommissionModel) => (
        <Typography.Text>
          {record.getCommissionRate()}
          {record.getCommissionRateType() ===
          BuyProductCommissionRateTypeEnum.PERCENTAGE
            ? "%"
            : "(Fixed)"}
        </Typography.Text>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      hidden: isMobile,
      align: "center" as const,
      width: 100,
      key: "created_at",
      render: (_: string, record: PurchaseCommissionModel) => {
        return record.getCreatedAt() ? (
          <Tag color="purple">{formatDate(record.getCreatedAt()!)}</Tag>
        ) : (
          ""
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      size="small"
      bordered
      loading={isLoading}
      dataSource={PurchaseCommissionCollectionData?.getData()}
      pagination={paginationConfig}
      rowKey="id"
    />
  );
};
