import React, { useState, useLayoutEffect } from 'react'
import { useAsync } from 'react-async'
import { bootstrapAppData } from '../utils/bootstrap'
import * as authClient from '../utils/auth-client'
import { MOVEON_ADMIN_USER_KEY } from 'consts/storage'

const AuthContext = React.createContext()

function AuthProvider(props) {
	const [firstAttemptFinished, setFirstAttemptFinished] = useState(false)
	const {
		data = { user: null },
		error,
		isRejected,
		// isPending,
		isSettled,
		// reload,
	} = useAsync({
		promiseFn: bootstrapAppData,
	})

	useLayoutEffect(() => {
		if (isSettled) {
			setFirstAttemptFinished(true)

			if (data) {
				window.localStorage.setItem(MOVEON_ADMIN_USER_KEY, JSON.stringify(data.user))
			}
		}
	}, [isSettled, data])

	if (!firstAttemptFinished) {
		if (isRejected) {
			return (
				<div>
					<p>Uh oh... There's a problem. Try refreshing the app.</p>
					<pre>{error.message}</pre>
				</div>
			)
		}
	}

	const login = form => authClient.login(form)
	const loggedIn = () => authClient.loggedIn()

	return (
		<AuthContext.Provider value={{ data, login, loggedIn }} {...props} />
	)
}

function useAuth() {
	const context = React.useContext(AuthContext)
	if (context === undefined) {
		throw new Error(`useAuth must be used within a AuthProvider`)
	}
	return context
}

export { AuthProvider, useAuth }
