import { Tag } from "antd";
import { TakeActionsEnums } from "enums/takeActionsEnums";
import { ActionSequenceModel } from "models/buyActionCollectionModel";
import React from "react";
interface IProps {
  data: ActionSequenceModel;
}
const ActionNeededIssuesType = ({ data }: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const templateRedColor =
    data.getType() === TakeActionsEnums.SHIPPING_CHARGE_INCREASE ||
    data.getType() === TakeActionsEnums.LOCAL_DELIVERY_CHARGE_ADDED ||
    data.getType() === TakeActionsEnums.PRODUCT_STOCK_OUT ||
    data.getType() === TakeActionsEnums.TAX_ADDED ||
    data.getType() === TakeActionsEnums.QUANTITY_UPDATE ||
    data.getType() === TakeActionsEnums.SERVICE_CHARGE ||
    data.getType() === TakeActionsEnums.CATEGORY_CHANGE ||
    data.getType() === TakeActionsEnums.NO_RISK_PRODUCT ||
    data.getType() === TakeActionsEnums.HIGH_TAX_POSSIBILITY ||
    data.getType() === TakeActionsEnums.PRICE_INCREASED;

  return (
    <div
      style={{
        fontWeight: "bold",
        marginLeft: "10px",
      }}
    >
      Issue Type:{" "}
      <Tag
        color="processing"
        style={{
          marginTop: "3px",
        }}
      >
        {data.getType()}
      </Tag>
    </div>
  );
};

export default ActionNeededIssuesType;
