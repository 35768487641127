import {
  Alert,
  Button,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
  TreeSelect,
} from "antd";
import { useState, createRef, useEffect } from "react";
import { renderOptionsFromEnum, renderOptionsFromEnum2 } from "components/Form/forms";
import { useAtom } from "jotai";
import { regionRegion, shippingCategoryAtom } from "lib/core-react/store/store";
import {
  useCreateAuctionRule,
  useGetRegion,
  useGetShippingCategory,
} from "lib/core-react/hooks/private";
import { AuctionRuleRequestModel } from "models/auctionRule";
import {
  AuctionRuleDataStatus,
  AuctionRuleOperatorEnum,
  AuctionRuleTypeEnum,
} from "enums/auctionRuleCollectionEnums";
import { RegionModel } from "models/region";
import { ShippingCategoryModel } from "models/shippingCategory";
interface IProps {
  setIsShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const CreateAuctionRule = ({ setIsShowCreateModal }: IProps) => {
  const { createAuctionRule, isLoading, isError } = useCreateAuctionRule();
  const [visible, setVisible] = useState(false);

  const formRef: React.Ref<FormInstance<any>> = createRef();
  const [form] = Form.useForm();
  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [form]);

  //hide error message after 5 sec
  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setVisible(false);
      }, 5000);
    }
  }, [visible]);

  // Region, Shipping category
  const { getRegion } = useGetRegion();
  const { getShippingCategory } = useGetShippingCategory();

  useEffect(() => {
    getRegion("per_page=200");
    getShippingCategory("per_page=200");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [{ data: regionDataAtom, isLoading: regionLoading }] =
    useAtom(regionRegion);
  const [
    { data: shippingCategoryDataAtom, isLoading: shippingCategoryLoading },
  ] = useAtom(shippingCategoryAtom);

  const regionData = regionDataAtom && new RegionModel(regionDataAtom);
  const shippingCategoryData =
    shippingCategoryDataAtom &&
    new ShippingCategoryModel(shippingCategoryDataAtom);

  const onFinish = async (value: AuctionRuleRequestModel) => {
    console.log(value,"values")
    const payload = {
      name: value.name,
      status: value.status,
      region_id: value.region_id,
      operator: value.operator,
      shipping_category_id: value.shipping_category_id,
      value: value.value,
      rule_type: value.rule_type,
    };

    try {
      await createAuctionRule(payload as AuctionRuleRequestModel);
      setIsShowCreateModal(false);
      setVisible(false);
    } catch (error) {
      setVisible(true);
    }
  };
  return (
    <>
      <div>
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          name="Create_Auction_Rules"
          ref={formRef}
        >
          <Form.Item
            rules={[{ required: true, message: "Auction name is required" }]}
            label="Name"
            name="name"
          >
            <Input placeholder="Auction name" />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Region is required" }]}
            name="region_id"
            label="Region"
          >
            <Select
              loading={regionLoading}
              placeholder="Please select a Region"
            >
              {regionData?.getRegionIdsAsObject() &&
                renderOptionsFromEnum(regionData?.getRegionIdsAsObject())}
            </Select>
          </Form.Item>

          <Form.Item required name="operator" label="Operator">
            <Select placeholder="Please select a operator">
              {renderOptionsFromEnum2(AuctionRuleOperatorEnum)}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: "Shipping Category is required" },
            ]}
            label="Shipping Category"
            name="shipping_category_id"
          >
            <TreeSelect
              showSearch
              style={{ width: "100%" }}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              placeholder="Please select a shipping category"
              allowClear
              loading={shippingCategoryLoading}
              treeLine={{ showLeafIcon: true }}
              treeData={shippingCategoryData?.getShippingCategoryTreeIds()}
            />
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: "Auction Value is required" }]}
            label="Auction Value"
            name="value"
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="Auction Value"
            />
          </Form.Item>
          <Form.Item required name="rule_type" label="Rule Type">
            <Select placeholder="Please select a rule type">
              {renderOptionsFromEnum2(AuctionRuleTypeEnum)}
            </Select>
          </Form.Item>

          <Form.Item required name="status" label="Status">
            <Select placeholder="Please select a status">
              {renderOptionsFromEnum(AuctionRuleDataStatus)}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              loading={isLoading}
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
            >
              Submit
            </Button>
          </Form.Item>
          {visible && (
            <Alert
              style={{ marginTop: "7px" }}
              message={isError}
              closable
              afterClose={() => setVisible(false)}
              type="error"
              showIcon
            />
          )}
        </Form>
      </div>
    </>
  );
};

export default CreateAuctionRule;
