import { PageHeader } from "@ant-design/pro-layout";
import {
  Button, Card,
  Dropdown,
  MenuProps, Modal,
  Table,
  Tag,
  Badge
} from "antd";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import {
  invoicePaymentRuleCollectionAtom
} from "lib/core-react/store/store";
import useDataFilters from "hooks/useDataFilters";
import { ApiHelperModel } from "models/apiHelper";
import { PaginationModel } from "models/pagination";
import {
  DeleteFilled,
  DownOutlined,
  EditOutlined,
  HddFilled,
} from "@ant-design/icons";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import {useInvoicePaymentRule} from "../../../../lib/core-react/hooks/private/useInvoicePaymentRule";
import {InvoicePaymentRuleModel} from "../../../../models/invoicePaymentRoleModel";
import {StatusTag} from "../../../../components";
import InvoiceRuleForm from "../component/createOrUpdateForm";


type ModalForType = 'create' | 'update' | 'delete' | "attach-user";

const AgentInvoiceList = () => {
  const { getInvoicePaymentRules, deleteInvoicePaymentRules } = useInvoicePaymentRule();
  const [{ data: invoicePaymentRoleCollectionData, isLoading, refetch }] = useAtom(
    invoicePaymentRuleCollectionAtom,
  );
  const [modalFor, setModalFor] = useState<ModalForType | undefined>(undefined)
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [selectedRuleGroup, setSelectedRuleGroups] = useState<InvoicePaymentRuleModel | undefined>(undefined)

  const { isMobile } = useWindowWidth();
  const {
    filters,
    handleFilterChange,
    isFirstCall,
    isFetched,
  } = useDataFilters();
  // Api Call
  useEffect(() => {
    if ((!isFetched && isFirstCall) || refetch) {
      ApiHelperModel.makeGetRequest(filters || {}, getInvoicePaymentRules);
    }
  }, [isFirstCall, isFetched, refetch]);

  const paginationData = invoicePaymentRoleCollectionData?.getPagination()


  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getInvoicePaymentRules,
    );
  };


  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    invoicePaymentRoleCollectionData,
    handlePaginationChange,
  );


  const getModelTitle=(modelFor:ModalForType)=>{
    switch (modelFor) {
      case 'create':
        return `Create`;
      case 'update':
        return `Update ${selectedRuleGroup?.getName()}`;
      default:
        return '';
    }

  }
  const  getModalContent = ()=> {
    if (modalFor === "create") {
      return (
         <InvoiceRuleForm modelFor='create' selectedData={undefined} setIsShowModal={setIsShowModal}/>
      );
    } else if (modalFor === "update" ) {
      return (
        <InvoiceRuleForm modelFor='update' selectedData={selectedRuleGroup} setIsShowModal={setIsShowModal}/>
      );
    } else if(modalFor === "attach-user"){
      return <div>Please select a proper action </div>
    }else{
      return <div>Please select a proper action </div>

    }
  }


const handleDelete = async (id:number) =>{
    try {
       await deleteInvoicePaymentRules(id)
    }catch (e) {
    }
}
  const columns  = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "sl",
      align: "center" as const,
      width: 70,
      render: (_: string, record: InvoicePaymentRuleModel, index: number) => {
        return (
          <div>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </div>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center" as const,
      render: (name, record: InvoicePaymentRuleModel) => {
        return <div color="purple">
          { record.isDefault() ?     <Badge.Ribbon text="Default">
            <Card style={{
              borderRadius:"0px",
            }}  size="small">
              {record.getName()}
            </Card>
          </Badge.Ribbon>: <Card style={{
            borderRadius:"0px",
            background:"transparent"
          }}  size="small">
            {record.getName()}
          </Card>
          }
         </div>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width:350,
      key: "status",
      align: "center" as const,
      render: (status, record: InvoicePaymentRuleModel) => {
        const statuses = [
          { label: "Wallet Enabled", method: record.isWalletEnabled() },
          { label: "Automatic Gateway Enabled", method: record.isAutomaticGatewayEnabled() },
          { label: "Bank Deposit Enabled", method: record.isBankDepositEnabled() },
          { label: "Mobile Merchant Enabled", method: record.isMobileMerchantEnabled() },
        ];

        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {statuses.map((status) => (
              <div key={status.label} style={{ display: 'flex', justifyContent: 'space-between', width: '100%', margin: '4px 0' }}>
                <span style={{ fontWeight: 'bold' }}>{status.label}:</span>
                <StatusTag slug={status.method ? 'active' : 'inactive'} text={status.method ? 'active' : 'inactive'} />
              </div>
            ))}
          </div>
        );
      },
    },

    {
      title: "Payment Percentages",
      dataIndex: "paymentPercentages",
      width:350,
      key: "paymentPercentages",
      align: "center" as const,
      render: (text, record: InvoicePaymentRuleModel) => {
        const paymentPercentages = [
          { label: "Wallet Payment", value: record.getWalletPaymentPercentage() },
          { label: "Automatic Gateway Payment", value: record.getAutomaticGatewayPaymentPercentage() },
          { label: "Bank Deposit Payment", value: record.getBankDepositPaymentPercentage() },
          { label: "Mobile Merchant Payment", value: record.getMobileMerchantPaymentPercentage() },
        ];

        return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {paymentPercentages.map((payment) => (
              <div key={payment.label} style={{ display: 'flex', justifyContent: 'space-between', width: '100%', margin: '4px 0' }}>
                <span style={{ fontWeight: 'bold' }}>{payment.label}:</span>
                <span>{payment.value}%</span>
              </div>
            ))}
          </div>
        );
      },
    },


    {
      title: "Created At",
      dataIndex: "created_at",
      hidden:isMobile,
      key: "created_at",
      align: "center" as const,
      render: (created_at, record: InvoicePaymentRuleModel) => {
        return <Tag color="purple">{record.getCreatedAt()}</Tag>;
      },
    },

    {
      align: "center" as const,
      title: "Actions",
      key: "actions",
      width: 100,
      fixed: isMobile ? "right" : undefined,
      render: (_: string, record: InvoicePaymentRuleModel) => {

        const menuItems: MenuProps["items"] = [
          {
            label: "Update",
            key: "update",
            icon: <EditOutlined />,
            onClick: async (e) => {
              console.log("update")
              setSelectedRuleGroups(record)
              setModalFor('update')
              setIsShowModal(true)

            },
            style: { margin: "5px", padding: "8px 16px" },
          },
          {
            label: "Delete",
            key: "delete",
            icon: <DeleteFilled />,
            onClick: async (e) => {
              handleDelete(record.getId())
            },
            style: { margin: "5px", padding: "8px 16px" },
          },
        ];

        if (isMobile) {
          menuItems.push(
            {
              label: <Tag color="purple">{record.getCreatedAt()}</Tag>,
              key: "created_at",
            },
          );
        }

        return (
          <Dropdown
            menu={{
              items: menuItems,
            }}
          >
            <Button icon={<HddFilled />}>
              {isMobile ? (
                <DownOutlined />
              ) : (
                <>
                  Actions <DownOutlined />{" "}
                </>
              )}
            </Button>
          </Dropdown>
        );
      },
    },
  ];
  // test
  return (
    <>
      <div>
        <PageHeader
          ghost={false}
          title="Invoice payment rule groups"
          style={{ marginTop: "10px" }}
          extra={[
            <Button key="1" type="primary" onClick={()=>{
              setIsShowModal(true)
              setModalFor('create')
            }}>
              Create
            </Button>,
          ]}

        >
          <div className="responsive-table-container">
            <Table
              loading={isLoading}
              rowKey="id"
              bordered={true}
              dataSource={
                invoicePaymentRoleCollectionData ? invoicePaymentRoleCollectionData.getData() : []
              }
              //@ts-ignore
              columns={columns}
              pagination={paginationConfig}
              scroll={{ x: 1237 }}
            />
          </div>
        </PageHeader>
      </div>
      {isShowModal && modalFor  && <Modal
        title={getModelTitle(modalFor)}
        open={isShowModal}
        footer={false}
        onCancel={() => setIsShowModal(false)}
      >
        {getModalContent()}
      </Modal>
      }
    </>
  );
};

export default AgentInvoiceList;
