import { useAtom } from "jotai";
import { useService } from "lib/core-react/contexts";
import { buyActionCollectionAtom } from "lib/core-react/store/store";
import { getError } from "../utils/errors";

export const useTakeActionList = () => {
    const [takeAction, setTakeAction] = useAtom(buyActionCollectionAtom);

    const { buyTakeActionsService } = useService();

    const getAllTakeActions = async (params?: string): Promise<void> => {
        setTakeAction({ ...takeAction, isLoading: true, error: null });
      try {
        const response = await buyTakeActionsService.buyActionResource.getBuyActions(params);
        setTakeAction({
          ...takeAction,
          isLoading: false,
          data: response,
          refetch: false,
        });
      } catch (error: any) {
        setTakeAction({
          ...takeAction,
          isLoading: false,
          refetch: false,
          error: getError(error),
        });
        throw error;
      }
    };

    return { getAllTakeActions } as const;
  };
