import { DownOutlined, EditOutlined, HddFilled } from "@ant-design/icons";
import {
  Avatar,
  Table,
  Tag,
  Image,
  Typography,
  Space,
  MenuProps,
  Dropdown,
  Button,
} from "antd";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import { PaginationModel } from "models/pagination";
import {
  PayoutGatewayCollectionModel,
  PayoutGatewayModel,
} from "models/payoutGatewayCollectionModel";
import { MenuInfo } from "rc-menu/lib/interface";

interface IPayoutGatewayTableProps {
  isLoading: boolean;
  payoutGatewayCollectionData: PayoutGatewayCollectionModel | undefined;
  handlePaginationChange: (pageCount: number, pageSize: number) => void;
  handleMenuClick: (e: MenuInfo, record: PayoutGatewayModel) => void;
}

const PayoutGatewayMasterTable = ({
  isLoading,
  payoutGatewayCollectionData,
  handlePaginationChange,
  handleMenuClick,
}: IPayoutGatewayTableProps) => {
  //const paginationData = payoutGatewayCollectionData?.getPagination();
  const { isMobile } = useWindowWidth();

  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "sl",
      width: 70,
      align: "center" as const,
      render: (_: string, __: PayoutGatewayModel, index: number) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "Gateway info",
      dataIndex: "name",
      key: "name",
      hidden: isMobile,
      render: (_: string, record: PayoutGatewayModel) => {
        const logoCollection = record.getLogo();
        const logoUrl =
          logoCollection
            ?.getData()
            .map((logo) => logo.getSmall()?.url)
            .find((url) => url) || "";
        return (
          <div style={{ display: "flex", gap: "16px", width: 300 }}>
            <Avatar
              shape="circle"
              size={70}
              style={{ objectFit: "contain" }}
              src={<Image src={logoUrl} />}
            />
            <div
              style={{
                display: "flex",
                gap: "10px",
                flexDirection: "column",
              }}
            >
              <div>
                <Typography.Text strong>{record.getName()}</Typography.Text>
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Slug: </span>

                <Tag color="green">{record.getSlug().replaceAll("-", " ")}</Tag>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Gateway info",
      dataIndex: "name",
      hidden: !isMobile,
      key: "name",
      render: (_: string, record: PayoutGatewayModel) => {
        const logoCollection = record.getLogo();
        const logoUrl =
          logoCollection
            ?.getData()
            .map((logo) => logo.getSmall()?.url)
            .find((url) => url) || "";
        return (
          <div style={{ display: "flex", gap: "16px", width: 300 }}>
            <Avatar
              shape="circle"
              size={70}
              style={{ objectFit: "contain" }}
              src={<Image src={logoUrl} />}
            />
            <div
              style={{
                display: "flex",
                gap: "10px",
                flexDirection: "column",
              }}
            >
              <div>
                <span style={{ fontWeight: "bold" }}>Name: </span>
                <Typography.Text strong>{record.getName()}</Typography.Text>
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Slug: </span>
                <Tag color="green">{record.getSlug().replaceAll("-", " ")}</Tag>
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Type: </span>
                <Tag color="green">{record.getType().replaceAll("-", " ")}</Tag>
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Country: </span>

                {record.getCountry() && (
                  <Space align="center">
                    <img
                      src={record.getCountry()?.getFlag()}
                      style={{ width: 18, height: 18 }}
                      alt=""
                    />
                    {record.getCountry()?.getName()}

                    {record.getCountry()?.getStatus() === "active" ? (
                      <Tag color="#87d068">{"Active"}</Tag>
                    ) : (
                      <Tag color="#f50">{"Inactive"}</Tag>
                    )}
                  </Space>
                )}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Gateway Type",
      dataIndex: "type",
      hidden: isMobile,
      key: "type",
      render: (_: string, record: PayoutGatewayModel) => {
        return (
          <div>
            <Tag color="green">{record.getType().replaceAll("-", " ")}</Tag>
          </div>
        );
      },
    },
    {
      title: "Country",
      dataIndex: "country",
      hidden: isMobile,
      key: "country",
      render: (_: string, record: PayoutGatewayModel) => {
        return (
          <div>
            {record.getCountry() && (
              <Space align="center">
                <img
                  src={record.getCountry()?.getFlag()}
                  style={{ width: 18, height: 18 }}
                  alt=""
                />
                {record.getCountry()?.getName()}

                {record.getCountry()?.getStatus() === "active" ? (
                  <Tag color="#87d068">{"Active"}</Tag>
                ) : (
                  <Tag color="#f50">{"Inactive"}</Tag>
                )}
              </Space>
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      fixed: isMobile ? "right" : undefined,
      width: 100,
      render: (_: string, record: PayoutGatewayModel) => {
        const menuItems: MenuProps["items"] = [
          {
            label: "Update",
            key: "update",
            icon: <EditOutlined />,
            onClick: (e) => handleMenuClick(e, record),
          },
        ];

        return (
          <Dropdown
            menu={{
              items: menuItems,
            }}
          >
            <Button icon={<HddFilled />}>
              {isMobile ? (
                <DownOutlined />
              ) : (
                <>
                  Actions <DownOutlined />{" "}
                </>
              )}
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    payoutGatewayCollectionData,
    handlePaginationChange,
  );

  const dataSource = payoutGatewayCollectionData
    ? payoutGatewayCollectionData.getData()
    : [];

  return (
    <Table
      style={{ marginTop: 10 }}
      loading={isLoading}
      rowKey="index"
      bordered={true}
      dataSource={dataSource}
      //@ts-ignore
      columns={columns}
      pagination={paginationConfig}
      scroll={{ x: 600 }}
    />
  );
};

export default PayoutGatewayMasterTable;
