import { BaseResource } from "../public";
import { ResponsePromise } from "lib/core/request/types/typings";
import {
  ShippingCountryPostRequestModel,
  ShippingCountryUpdateRequestModel,
} from "models/shippingCountry";

class ShippingCountryResource extends BaseResource {
  path = `/api/shipping-core/admin/shipping-country/v1/shipping-countries`;

  get(
    params?: string,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}?region_id=3&expand=country,region${params ? `&${params}` : ""}`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  create(
    payload: ShippingCountryPostRequestModel,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }

  update(
    id: number,
    payload: ShippingCountryUpdateRequestModel,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/${id}`;
    return this.client.request("PUT", path, payload, {}, customHeaders);
  }
}

export default ShippingCountryResource;
