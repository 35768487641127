import { useLocation, useNavigate } from "react-router-dom";

import {
  StyledProductGridAction,
  StyledProductGridActionItem,
  StyledProductGridCard,
  StyledProductGridCardHeader,
  StyledProductGridCardHeaderThumb,
  StyledProductGridCardPara,
  StyledProductGridCardTitle,
} from "./index.styled";
import { ProductDetailModel } from "models/productDetailCollectionModel";
import { Checkbox } from "antd";
import { BuyOrderOperationEnum } from "enums/buyOrderCollectionEnums";

type Props = {
  item: ProductDetailModel;
  isChecked: boolean;
  handleCheck: (id: string) => void;
  selectable: boolean;
};

const GridItem = (props: Props) => {
  const location = useLocation();
  const operation = new URLSearchParams(location.search).get("operation");
  const buyOrderId = new URLSearchParams(location.search).get("buyOrderId");
  const userId = new URLSearchParams(location.search).get("userId");
  const region = new URLSearchParams(location.search).get("region");

  const { item, isChecked, handleCheck, selectable } = props;
  const navigate = useNavigate();

  return (
    <StyledProductGridCard
      className=""
      extra={
        selectable ? (
          <Checkbox
            checked={isChecked}
            onChange={() => handleCheck(item.getId())}
          />
        ) : null
      }
    >
      <StyledProductGridCardHeader onClick={() => handleCheck(item.getId())}>
        <StyledProductGridCardHeaderThumb>
          <img src={item.getImage()} alt="product" />
        </StyledProductGridCardHeaderThumb>
      </StyledProductGridCardHeader>

      <StyledProductGridCardTitle
        className="text-truncate item-hover"
        onClick={() => {
          if (
            operation === BuyOrderOperationEnum.AddBuyOrderProduct &&
            buyOrderId
          ) {
            navigate(
              `product_detail/${item.getId()}?buyOrderId=${buyOrderId}&operation=${operation}`,
            );
          } else if (operation === BuyOrderOperationEnum.PlaceBuyOrder) {
            navigate(
              `product_detail/${item.getId()}?userId=${userId}&operation=${operation}&region=${region}`,
            );
          } else {
            navigate(`product_detail/${item.getId()}`);
          }
        }}
      >
        {item.getTitle()}
      </StyledProductGridCardTitle>

      <StyledProductGridCardPara
        className="text-truncate"
        onClick={() => {
          operation === BuyOrderOperationEnum.AddBuyOrderProduct && buyOrderId
            ? navigate(
                "product_detail/" +
                  item.getId() +
                  `?buyOrderId=${buyOrderId}&operation=${operation}`,
              )
            : navigate("product_detail/" + item.getId());
        }}
      >
        {/* {item.getDescription()} */}
      </StyledProductGridCardPara>

      <StyledProductGridAction
        onClick={() => {
          operation === BuyOrderOperationEnum.AddBuyOrderProduct && buyOrderId
            ? navigate(
                "product_detail/" +
                  item.getId() +
                  `?buyOrderId=${buyOrderId}&operation=${operation}`,
              )
            : navigate("product_detail/" + item.getId());
        }}
      >
        <StyledProductGridActionItem>
          ৳
          {item.getPrice().getDiscount().getMin()
            ? Math.ceil(item.getPrice().getDiscount().getMin())
            : Math.ceil(item.getPrice().getOriginal().getMin())}
        </StyledProductGridActionItem>
        <StyledProductGridActionItem className="cut">
          ৳{Math.ceil(item.getPrice().getOriginal().getMin())}
        </StyledProductGridActionItem>
      </StyledProductGridAction>
    </StyledProductGridCard>
  );
};

export default GridItem;
