import { useEffect } from "react";
import { Col, Form, Input, InputNumber, Row, Select } from "antd";
import { useAtom } from "jotai";

import { useGetCurrency } from "lib/core-react/hooks/private";
import {
  PaymentGatewayFeeTypeEnums,
  PaymentGatewayIdentifierEnums,
  PaymentGatewayStatusEnums,
  PaymentGatewayTypeEnums,
} from "enums/paymentGatewayCollectionEnums";
import { regionCurrency } from "lib/core-react/store/store";
import { CurrencyCollectionModel } from "models/currencyCollectionModel";
import { PaymentGatewayModel } from "models/paymentGatewayCollectionModel";
import { transformEnumToLabeledValue } from "utils/helpers";
import { SingleChunkUpload } from "components/SingleChunkUpload";

interface IProps {
  data?: PaymentGatewayModel;
  form: any;
  onFinish: (values: any) => Promise<void>;
}

export const PaymentGatewayModification = ({
  data,
  form,
  onFinish,
}: IProps) => {
  const { getCurrency } = useGetCurrency();

  const [{ data: currencyDataAtom, isLoading: currencyLoading }] =
    useAtom(regionCurrency);

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  useEffect(() => {
    getCurrency("per_page=200");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const currencyData =
    currencyDataAtom && new CurrencyCollectionModel(currencyDataAtom);
  const SmallLogoUrl =
    data
      ?.getLogo()
      ?.getData()
      .map((logo) => logo.getSmall()?.src)
      .find((url) => url) || "";
  const MediumlogoUrl =
    data
      ?.getLogo()
      ?.getData()
      .map((logo) => logo.getMedium()?.src)
      .find((url) => url) || "";
  return (
    <Form
      onFinish={onFinish}
      form={form}
      layout="vertical"
      name="Add Payment Gateway"
      initialValues={{
        name: data?.getName(),
        currencies: data?.getCurrencies().getDataIds(),
        identifier: data?.getIdentifier(),
        type: data?.getType(),
        fee_type: data?.getFeeType(),
        status: data?.getStatus(),
        fee: data?.getFee(),
        small_logo: SmallLogoUrl,
        medium_logo: MediumlogoUrl,
      }}
    >
      <Form.Item
        rules={[
          {
            required: true,
            message: "Payment gateway name is required",
          },
        ]}
        label="Name"
        name="name"
      >
        <Input placeholder="Payment gateway name" />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Currency is required" }]}
        name="currencies"
        label="Currency"
      >
        <Select
          mode="multiple"
          loading={currencyLoading}
          placeholder="Please select currencies"
          options={currencyData?.getCurrencyLabeledValueV2()}
        />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Identifier is required" }]}
        name="identifier"
        label="Identifier"
      >
        <Select
          placeholder="Please select a identifier"
          options={transformEnumToLabeledValue(PaymentGatewayIdentifierEnums)}
        />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Type is required" }]}
        name="type"
        label="Type"
      >
        <Select
          placeholder="Please select a type"
          options={transformEnumToLabeledValue(PaymentGatewayTypeEnums)}
        />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Fee type is required" }]}
        name="fee_type"
        label="Fee type"
      >
        <Select
          placeholder="Please select a fee type"
          options={transformEnumToLabeledValue(PaymentGatewayFeeTypeEnums)}
        />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Fee is required" }]}
        name="fee"
        label="Fee"
      >
        <InputNumber placeholder="Enter fee" style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Status is required" }]}
        name="status"
        label="Status"
      >
        <Select
          placeholder="Please select a status"
          options={transformEnumToLabeledValue(PaymentGatewayStatusEnums)}
        />
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="small_logo"
            label="Small Size Logo"
            rules={[{ required: true, message: "Logo is required" }]}
          >
            <SingleChunkUpload
              form={form}
              accept="image/jpeg,image/png,image/jpg,image/gif,image/webp"
              fieldName="small_logo"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="medium_logo"
            label="Medium Size Logo"
            rules={[{ required: true, message: "Logo is required" }]}
          >
            <SingleChunkUpload
              form={form}
              accept="image/jpeg,image/png,image/jpg,image/gif,image/webp"
              fieldName="medium_logo"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
