import qs from "qs";
import { useLocation } from "react-router-dom";

import {
  FacetFieldModel,
  FacetValuesFieldModel,
} from "models/productDetailCollectionModel";

import {
  StyledProductSidebarItem,
  StyledProductSidebarItemTitle,
  StyledProductSidebarTree,
} from "../index.styled";
import { DownOutlined } from "@ant-design/icons";
import { useMemo } from "react";

// interface ITransformData {
//   name: string;
//   values: string[];
// }

interface IProps {
  propertiesField: FacetValuesFieldModel[];
}

export type Key = string | number;

const PropertiesFacet = ({ propertiesField }: IProps) => {
  const location = useLocation();
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  // const navigate = useNavigate();
  const { properties, ...rest } = parsedQuery;
  // const { getProductDetailsCollection } = useGetProductDetailsCollection();

  const prepareParent = () => {
    return propertiesField.map((p) => {
      return {
        title: `${p.getName()} (${p.getCount()})`,
        key: `*-${p.getName()} (${p.getCount()})`,
        children: prepareChildren(p.getName(), p.getValues()),
        disableCheckBox: true,
      };
    });
  };

  const prepareChildren = (name: string, values: FacetFieldModel[]) => {
    return values.map((v) => {
      return {
        title: `${v.getName()} (${v.getCount()})`,
        key: `${name};${v.getName()}`,
      };
    });
  };

  const preparedData = useMemo(() => prepareParent(), [propertiesField]);

  // const onCheck = async (
  //   checked: Key[] | { checked: Key[]; halfChecked: Key[] },
  // ) => {
  //   if (Array.isArray(checked)) {
  //     let transformedData: ITransformData[] = [];

  //     // Loop through each item in the checked array
  //     checked.forEach((item) => {
  //       // Split the item by ";"
  //       let [name, value] = item.toString().split(";");

  //       // Check if an object with the same name already exists in the transformedData array
  //       let existingItem = transformedData.find(
  //         (element) => element.name === name,
  //       );

  //       // If an object with the same name exists, push the value to its values array
  //       if (existingItem) {
  //         existingItem.values.push(value);
  //       } else {
  //         // If no object with the same name exists, create a new object and push it to the transformedData array
  //         if (!name.startsWith("*")) {
  //           transformedData.push({
  //             name: name,
  //             values: [value],
  //           });
  //         }
  //       }
  //     });

  //     const queryString = qs.stringify({
  //       ...rest,
  //       properties: transformedData,
  //     });
  //     await getProductDetailsCollection(
  //       {
  //         region: "BD",
  //         locale: "en",
  //       },
  //       { properties: transformedData, ...rest },
  //     );
  //     navigate(`/product-manage/products?${queryString}`);
  //   } else {
  //     console.log("onCheck", checked.checked); // Access the checked keys from the object
  //     console.log("halfChecked", checked.halfChecked); // Access the half checked keys from the object
  //   }
  // };

  return (
    <StyledProductSidebarItem>
      <StyledProductSidebarItemTitle>Properties</StyledProductSidebarItemTitle>
      <StyledProductSidebarTree
        checkable
        switcherIcon={<DownOutlined />}
        treeData={preparedData}
        // @ts-ignore FIXME
        // onCheck={onCheck}
      />
    </StyledProductSidebarItem>
  );
};
export default PropertiesFacet;
