import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Button,
  Card,
  Form,
  InputNumber,
  Select,
  Skeleton,
  TreeSelect,
  Typography,
} from 'antd'
import {
  useGetDestinationWarehouse,
  useGetShippingCategory,
} from 'lib/core-react/hooks/private'
import {
  agentCategoryPriceReadCollectionAtom,
  destinationWarehouseCollectionAtom,
  shippingCategoryAtom,
  shippingCountryCollectionAtom,
} from 'lib/core-react/store/store'
import { useAtom, useAtomValue } from 'jotai'
import { DestinationWarehouseCollectionModel } from 'models/destinationWarehouseCollectionModel'
import { ShippingCategoryModel } from 'models/shippingCategory'
import { transformEnumToLabeledValue } from 'utils/helpers'
import { ShippingModesEnums, ShippingTypesEnums } from 'enums/shippingEnums'
import { MoveonShippingModel } from 'models/buyOrderCollectionModel'
import { useGetShippingCountries } from 'lib/core-react/hooks/private/useShippingCountry'
import { ShippingCountryCollectionModel } from 'models/shippingCountryCollectionModel'
import { AgentCategoryPriceReadCollectionModel } from 'models/agentCategoryPriceReadCollectionModel'
import { StarTwoTone } from '@ant-design/icons'
import { useGetCategoryPrices } from 'lib/core-react/hooks/private/useShippingAgent'
import {
  IAgentCompanyId,
  IUpdateShippingCategoriyPrice,
} from 'types/buyOrderCollection'
import { useBuyProductUpdateShippigCategoryPrice } from 'lib/core-react/hooks/private/usePurchase'
interface IProps {
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  shipping: MoveonShippingModel
  user: Number
  region: string
  buyProductId: Number
}

const ShippingUpdateModal = ({
  shipping,
  user,
  region,
  buyProductId,
  setIsModalVisible
}: IProps) => {
  const { Text } = Typography
  const { getDestinationWarehouse } = useGetDestinationWarehouse()
  const { getShippingCategory } = useGetShippingCategory()
  const { getShippingCountries } = useGetShippingCountries()
  const { getCategoryPrices } = useGetCategoryPrices()
  const { buyProductUpdateShippigAgentCategoryPrice, isLoading:updateLoading } =
    useBuyProductUpdateShippigCategoryPrice()

  const [isErrorVisible, setIsErrorVisible] = useState(false)

  const [shippingType, setShippingType] = useState<string>('air')
  const [shippingMode, setShippingMode] = useState<string>('cargo')

  const [productCategory, setProductCategory] = useState<string | undefined>()
  const [{ data: warehouseData, isLoading }] = useAtom(
    destinationWarehouseCollectionAtom,
  )

  const [{ data: shippingCategoryAtomData }] = useAtom(shippingCategoryAtom)

  const [destinationWarehouse, setDestinationWarehouse] = useState<
    string | undefined
  >()

  const [shippingCountry, setSetShippingCountry] = useState<
    Number | undefined
  >()

  const [form] = Form.useForm()

  // Warehouse
  const DestinationWarehouseCollectionData =
    warehouseData && new DestinationWarehouseCollectionModel(warehouseData)

  const [{ data: shippingCountryCollectionData }] = useAtom(
    shippingCountryCollectionAtom,
  )

  const shippingCategoryOptions =
    shippingCategoryAtomData &&
    new ShippingCategoryModel(
      shippingCategoryAtomData,
    ).getShippingCategoryTreeIds()

  const {
    data: categoryPriceReadCollection,
    isLoading: categoryPriceReadLoading,
  } = useAtomValue(agentCategoryPriceReadCollectionAtom)

  useEffect(() => {
    getDestinationWarehouse()
    getShippingCountries()
    getShippingCategory()
  }, [])

  useEffect(() => {
    if (productCategory) {
      getCategoryPrices({
        user_id: Number(user),
        region: region,
        destination_warehouse_id: Number(destinationWarehouse)
          ? Number(destinationWarehouse)
          : shipping.getDestinationWarehouse().getById(),
        shipping_category_id: productCategory,
        shipping_country_id: Number(shippingCountry)
          ? Number(shippingCountry)
          : shipping.getShippingCountry().getById(),
        shipping_mode: shippingMode ? shippingMode : shipping.getShippingMode(),
        shipping_type: shippingType ? shippingType : shipping.getShippingType(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingType, shippingMode, productCategory])

  const ShippingCountryData =
    shippingCountryCollectionData &&
    new ShippingCountryCollectionModel(shippingCountryCollectionData)

  const categoryPriceReadCollectionData =
    categoryPriceReadCollection &&
    new AgentCategoryPriceReadCollectionModel(categoryPriceReadCollection)


  const [agetnCategoryId, setAgentCatetoryPrice] = useState<
    IAgentCompanyId | undefined
  >()


  useEffect(() => {
    return () => {
      form.resetFields()
    }
  }, [shippingCategoryAtomData, form])

  useEffect(() => {
    if (!shippingCategoryAtomData?.data) {
      getShippingCategory()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingCategoryAtomData])

  const onFinish = async () => {
    const payload: IUpdateShippingCategoriyPrice = {
      destination_warehouse_id: Number(destinationWarehouse)
          ? Number(destinationWarehouse)
          : shipping.getDestinationWarehouse().getById(),
      shipping_category_id: productCategory as string,
      shipping_country_id: Number(shippingCountry) ? Number(shippingCountry) : shipping.getShippingCountry().getById(),
      shipping_type: shippingType,
      shipping_mode: shippingMode,
      agent_category_price_read_id: Number(agetnCategoryId?.agent_category_price_read_id),
    }

    try {
      await buyProductUpdateShippigAgentCategoryPrice(Number(buyProductId), payload)
      form.resetFields()
      setIsModalVisible(false)
    } catch (error) {
      setIsErrorVisible(true)
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Form
        name="shippingUpdate"
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          shippingCountry: shipping?.getShippingCountry().getById(),
          destinationWarehouse: shipping?.getDestinationWarehouse().getById(),
          shipping_type: shipping?.shipping_type,
          shipping_mode: shipping?.shipping_mode,
          rate: shipping?.rate,
        }}
      >
        <Form.Item
          label="Select a Shipping Country"
          name="shippingCountry"
          rules={[{ required: true, message: 'Shipping Country is required' }]}
        >
          <Select
            loading={isLoading}
            placeholder="Select a Shipping Country"
            onChange={(value) => setSetShippingCountry(value)}
            options={ShippingCountryData?.getLabeledCountries()}
          />
        </Form.Item>

        <Form.Item
          label="Seleted the Destinations Warehouse"
          name="destinationWarehouse"
          rules={[
            { required: true, message: 'Destinations Warehouse is required' },
          ]}
        >
          <Select
            loading={isLoading}
            placeholder="Please select a destination Warehouse"
            options={DestinationWarehouseCollectionData?.getDataAsLabelValue()}
            onChange={(value) => setDestinationWarehouse(value)}
          />
        </Form.Item>

        <Form.Item name="shipping_type" label="Shipping Type">
          <Select
            loading={isLoading}
            placeholder="Please select a shipping type"
            onChange={(value) => setShippingType(value)}
            options={transformEnumToLabeledValue(ShippingTypesEnums)}
          />
        </Form.Item>

        <Form.Item name="shipping_mode" label="Shipping Mode">
          <Select
            loading={isLoading}
            placeholder="Please select a shipping mode"
            onChange={(value) => setShippingMode(value)}
            options={transformEnumToLabeledValue(ShippingModesEnums)}
          />
        </Form.Item>

        <Form.Item
          label="Select Shipping Category"
          name="shipping_category_id"
          rules={[{ required: true, message: 'Shipping Category is required' }]}
        >
          <TreeSelect
            onChange={(value) => setProductCategory(value)}
            showSearch
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Please select a shipping category"
            allowClear
            loading={isLoading}
            treeLine={{ showLeafIcon: true }}
            treeData={shippingCategoryOptions}
          />
        </Form.Item>
        <div>
          <Card
            title={<Text strong>Select Cargo Company</Text>}
            style={{
              backgroundColor: '#F7F8FA',
            }}
          >
            <div style={{ maxHeight: '220px', overflowY: 'auto' }}>
              {categoryPriceReadLoading ? (
                <Skeleton active />
              ) : !categoryPriceReadCollectionData ? (
                <div>Please select a category</div>
              ) : categoryPriceReadCollectionData?.getData().length === 0 ? (
                <div>Shipping partner is not available for this category!</div>
              ) : (
                categoryPriceReadCollectionData?.getData().map((data) => (
                  <div
                    onClick={() => {
                      productCategory &&
                        setAgentCatetoryPrice({
                          agent_category_price_read_id: data.getId(),
                        })
                    }}
                    key={data.getId()}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: 10,
                      backgroundColor: 'white',
                      borderRadius: '5px',
                      padding: '16px',
                      marginBottom: '16px',
                      cursor: 'pointer',
                      border: `${
                        agetnCategoryId?.agent_category_price_read_id ===
                        data.getId()
                          ? '1px solid green'
                          : ''
                      }`,
                    }}
                  >
                    <div style={{ display: 'flex', gap: '12px' }}>
                      <Avatar
                        size={50}
                        shape="square"
                        style={{
                          backgroundColor: '#00897B',
                          color: 'white',
                          fontWeight: 700,
                          fontSize: '20px',
                        }}
                      >
                        {data.getAgentWarehouse().getName()[0]}
                      </Avatar>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Text style={{ fontSize: '16px' }}>
                          {data.getAgentWarehouse().getName()}
                        </Text>
                        <div style={{ display: 'flex', gap: '16px' }}>
                          <div
                            style={{
                              borderRight: '2px solid #DDD',
                              paddingRight: '16px',
                              display: 'flex',
                            }}
                          >
                            <StarTwoTone twoToneColor="#FFC107" />
                            <StarTwoTone twoToneColor="#FFC107" />
                            <StarTwoTone twoToneColor="#FFC107" />
                            <StarTwoTone twoToneColor="#FFC107" />
                            <StarTwoTone twoToneColor="#FFC107" /> (700)
                          </div>
                          <div>
                            <Text strong>800+ </Text>
                            <Text
                              style={{ color: '#707070', fontSize: '14px' }}
                            >
                              Shipping Delivered
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '7px',
                      }}
                    >
                      <Text strong>
                        {data.getRate()}/{data.getUnitType()}
                      </Text>
                      <Text style={{ fontSize: '12px', color: '#6b7386' }}>
                        Tax included
                      </Text>
                    </div>
                  </div>
                ))
              )}
            </div>
          </Card>
        </div>

        <Form.Item
          style={{ textAlign: 'center', width: '100%', marginTop: '10px' }}
        >
          <Button loading={updateLoading} disabled={updateLoading} type="primary" htmlType="submit" style={{ width: '100%' }}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default ShippingUpdateModal
