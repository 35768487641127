import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Card,
  Collapse,
  Descriptions,
  Dropdown,
  Form,
  Image,
  List,
  Modal,
  Space,
  Typography,
  message,
} from "antd";
import type { MenuProps } from "antd";
import type { DescriptionsProps } from "antd";
import type { CollapseProps } from "antd";
import {
  CaretRightOutlined,
  DeleteTwoTone,
  DownOutlined,
  EditOutlined,
  HddFilled,
  PlusSquareTwoTone,
} from "@ant-design/icons";

import { FALLBACK_IMAGE_URL } from "consts/appConstants";
import {
  useShipmentProductAddPackage,
  useShipmentProductAttachPackageImage,
  useShipmentProductAttachTrackingId,
  useShipmentProductUpdateTrackingId,
} from "lib/core-react/hooks/private/useShipping";
import { getError } from "lib/core-react/hooks/utils/errors";

import {
  IShipmentProductAttachImageForm,
  IShipmentProductDetailCommonProps,
} from "types/shipmentProductCollection";
import { ProductSourceEnum } from "enums/shipForMeContextEnums";
import { ShipmentProductOperationEnum } from "enums/shipmentProductCollectionEnums";

import {
  AddOwnSourceCartonModal,
  TrackingModificationModal,
  AttachCartonImageModal,
  CartonVariationsModal,
} from ".";
import { ShipmentProductPackageModel } from "models/shipmentProductPackageCollectionModel";

export const Carton = ({
  isLoading,
  ShipmentProductData,
  title,
}: IShipmentProductDetailCommonProps) => {
  const navigate = useNavigate();
  const [addCartonModalOpen, setAddCartonModalOpen] = useState<boolean>(false);

  const [attachCartonImageModalOpen, setAttachCartonImageModalOpen] =
    useState<boolean>(false);

  const [selectedPackageIds, setSelectedPackageIds] = useState<number[]>([]);
  const [selectedPackage, setSelectedPackage] =
    useState<ShipmentProductPackageModel>();
  const [trackingModalOpen, setTrackingModalOpen] = useState<boolean>(false);
  const [selectedTrackingId, setSelectedTrackingId] = useState<number>();
  const [variationsModalOpen, setVariationsModalOpen] =
    useState<boolean>(false);
  
  const shipment_product_id = ShipmentProductData?.getId();

  const [form] = Form.useForm();

  const {
    addPackage: addShipmentProductPackage,
    isLoading: isLoadingAddShipmentProductPackage,
  } = useShipmentProductAddPackage();

  const {
    attachImage: attachShipmentProductPackageImage,
    isLoading: isLoadingAttachShipmentProductPackageImage,
  } = useShipmentProductAttachPackageImage();

  const {
    attachTrackingId,
    isLoading: isLoadingAttachShipmentProductTrackingId,
  } = useShipmentProductAttachTrackingId();

  const {
    updateTrackingId,
    isLoading: isLoadingUpdateShipmentProductTrackingId,
  } = useShipmentProductUpdateTrackingId();

  const handleMenuClick = () => {};
  const handleSecondaryMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key === "add-tracking") {
      setTrackingModalOpen(true);
    }
    if (e.key === "attach-carton-image") {
      setAttachCartonImageModalOpen(true);
    }
  };

  const items: MenuProps["items"] = [
    {
      icon: <PlusSquareTwoTone />,
      label: "Add Carton",
      key: "add-carton",
      onClick: () =>
        ShipmentProductData?.getProductSource() === ProductSourceEnum.OWN_SOURCE
          ? setAddCartonModalOpen(true)
          : navigate(
              `/product-manage/products/product_detail/find?operation=${ShipmentProductOperationEnum.AddShipmentProductPackage}&shipmentProductId=${ShipmentProductData?.getId()}&region=${ShipmentProductData?.getRegion().getCode()}&userId=${ShipmentProductData?.getOrder().getCustomer().getId()}&productUrl=${ShipmentProductData?.getLink()}`,
            ),
    },
  ];

  const secondaryItems: MenuProps["items"] = [
    {
      icon: <PlusSquareTwoTone />,
      label: "Add Tracking",
      key: "add-tracking",
    },
    {
      icon: <PlusSquareTwoTone />,
      label: "Attach Image",
      key: "attach-carton-image",
    },
    {
      icon: <DeleteTwoTone />,
      label: "Delete Carton",
      key: "delete-carton",
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const secondaryMenuProps = {
    items: secondaryItems,
    onClick: handleSecondaryMenuClick,
  };

  const handleOwnSourceAddPackage = async () => {
    try {
      const trigger = await form.validateFields();
      if (shipment_product_id) {
        await addShipmentProductPackage(shipment_product_id, {
          ...trigger,
        });
        message.success("Add carton successfull.");
        setAddCartonModalOpen(false);
      } else {
        message.error("Shipment product number not found!");
      }
    } catch (err) {
      message.error(getError(err));
    }
  };

  const handleAttachCartonImage = async () => {
    try {
      if (shipment_product_id && selectedPackageIds.length) {
        const images = form.getFieldValue("images");

        // Create a list of promises for attaching images to each package
        const promises = selectedPackageIds.map((packageId) => {
          const payload: IShipmentProductAttachImageForm = {
            packages: [
              {
                id: packageId,
                images: images,
              },
            ],
          };
          return attachShipmentProductPackageImage(
            shipment_product_id,
            payload,
          );
        });

        // Wait for all promises to resolve
        await Promise.all(promises);
        message.success("Attached carton images successfully.");
        setSelectedPackageIds([]);
        setAddCartonModalOpen(false);
      } else {
        message.error("Shipment product id not found!");
      }
    } catch (err) {
      message.error(getError(err));
    }
  };

  const handleAttachTracking = async () => {
    try {
      const trigger = await form.validateFields();
      if (selectedPackageIds.length && shipment_product_id) {
        await attachTrackingId(shipment_product_id, {
          ...trigger,
          package_ids: selectedPackageIds,
        });
        message.success("Add tracking successfull.");
        setSelectedPackageIds([]);
        setTrackingModalOpen(false);
      } else {
        message.error("Shipment product number not found!");
      }
    } catch (err) {
      message.error(getError(err));
    }
  };

  const handleUpdateTracking = async () => {
    try {
      const trigger = await form.validateFields();
      if (
        selectedPackageIds.length &&
        shipment_product_id &&
        selectedTrackingId
      ) {
        await updateTrackingId(shipment_product_id, selectedTrackingId, {
          ...trigger,
        });
        message.success("Update tracking successfull.");
        setSelectedPackageIds([]);
        setSelectedTrackingId(undefined);
        setTrackingModalOpen(false);
      } else {
        message.error("Shipment product number or tracking id not found!");
      }
    } catch (err) {
      message.error(getError(err));
    }
  };

  return (
    <Card
      title={title}
      loading={isLoading}
      bordered={false}
      extra={[
        <Dropdown menu={menuProps} key="main-actions">
          <Button icon={<HddFilled />}>
            Actions <DownOutlined />
          </Button>
        </Dropdown>,
      ]}
    >
      <List
        pagination={{ pageSize: 3 }}
        dataSource={ShipmentProductData?.getPackages().getData()}
        renderItem={(item, index) => {
          const shipmentItems: DescriptionsProps["items"] = [
            {
              key: "shipment_no",
              label: "Shipment no",
              children: item.getWarehouseShipment()?.getShipmentNumber(),
            },
            {
              key: "waybill_no",
              label: "Waybill no",
              children: item.getWarehouseShipment()?.getWaybillNumber(),
            },
            {
              key: "estimated_fly_time",
              label: "Estimated fly time",
              children: item.getWarehouseShipment()?.getEstimatedFlyTime(),
            },
          ];
          const shipmentCollapseItems: CollapseProps["items"] = [
            {
              key: "shipment_info",
              label: "Shipment informations",
              children: <Descriptions items={shipmentItems} />,
            },
          ];

          const additionalItems: DescriptionsProps["items"] = [
            {
              key: "package-no",
              label: "Package no",
              children: item.getPackageNumber(),
            },
            {
              key: "carton-no",
              label: "Carton no",
              children: item.getCartonNumber(),
            },
            {
              key: "contains",
              label: "Contains",
              children: item.getContains(),
            },
            {
              key: "fullfillment",
              label: "Fulfillment",
              children: item.getFulfillmentStatus(),
            },
            {
              key: "height",
              label: "Height",
              children: item.getHeight(),
            },
            {
              key: "weight",
              label: "Weight",
              children: item.getWeight(),
            },
            {
              key: "width",
              label: "Width",
              children: item.getWidth(),
            },
            {
              key: "length",
              label: "Length",
              children: item.getLength(),
            },
            {
              key: "cbm",
              label: "Cbm",
              children: item.getCbm(),
            },
          ];
          const addittionalCollapseItems: CollapseProps["items"] = [
            {
              key: "additional-info",
              label: "Additional informations",
              children: <Descriptions items={additionalItems} />,
            },
          ];
          return (
            <Card
              key={item.getPackageNumber()}
              title={`Package ${index + 1}`}
              bordered
              extra={[
                <Dropdown
                  menu={secondaryMenuProps}
                  onOpenChange={() => setSelectedPackageIds([item.getId()])}
                  key="package-actions"
                >
                  <Button icon={<HddFilled />}>
                    Actions <DownOutlined />
                  </Button>
                </Dropdown>,
              ]}
              style={{ marginBottom: 10 }}
            >
              <Space
                direction="vertical"
                style={{ display: "flex", marginBottom: "10px" }}
              >
                {item.getImages().getData().length ? (
                  <Image.PreviewGroup>
                    <List
                      grid={{ gutter: 16, column: 8 }}
                      size="small"
                      pagination={{
                        hideOnSinglePage: true,
                        pageSize: 3,
                      }}
                      dataSource={item.getImages().getData()}
                      renderItem={(item) => (
                        <Image width={50} height={50} src={item.getSrc()} />
                      )}
                    />
                  </Image.PreviewGroup>
                ) : (
                  "No images available"
                )}

                <Space direction="vertical" style={{ marginBottom: 10 }}>
                  {item.getTrackings().getData().length === 0 ? (
                    "No tracking available"
                  ) : (
                    <List
                      grid={{
                        gutter: 16,
                        sm: 1,
                        lg: 2,
                      }}
                      dataSource={item
                        .getTrackings()
                        .getData()
                        .map((tracking) => {
                          return {
                            id: tracking.getId(),
                            title: tracking.getType(),
                            content: tracking.getTracking(),
                          };
                        })}
                      renderItem={(tracking) => (
                        <List.Item key={tracking.id}>
                          <Typography.Text
                            strong
                            style={{ textTransform: "capitalize" }}
                          >
                            {tracking.title.replaceAll("-", " ")}:{" "}
                          </Typography.Text>{" "}
                          {tracking.content}{" "}
                          <Button
                            style={{ padding: 0 }}
                            type="link"
                            onClick={() => {
                              setSelectedPackageIds([item.getId()]);
                              setSelectedTrackingId(tracking.id);
                              setTrackingModalOpen(true);
                              form.setFieldValue("type", tracking.title);
                              form.setFieldValue("tracking", tracking.content);
                            }}
                          >
                            <EditOutlined />
                          </Button>
                        </List.Item>
                      )}
                    />
                  )}
                </Space>

                {item.getWarehouseShipment() ? (
                  <Collapse
                    bordered={false}
                    items={shipmentCollapseItems}
                    expandIcon={({ isActive }) => (
                      <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    )}
                  />
                ) : (
                  ""
                )}
                <Collapse
                  bordered={false}
                  items={addittionalCollapseItems}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                />

                <Space
                  size="large"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Avatar
                    shape="square"
                    src={
                      <Image
                        src={item.getImages().getFirstImageSrc() || ""}
                        fallback={FALLBACK_IMAGE_URL}
                      />
                    }
                  />
                  <Typography.Text strong>
                    {ShipmentProductData?.getCurrency().getSymbolNative()}
                    {item.getProductPrice()} × {item.getQuantity()}
                  </Typography.Text>
                  <Typography.Text strong>
                    {ShipmentProductData?.getCurrency().getSymbolNative()}{" "}
                    {(item.getProductPrice() * item.getQuantity()).toFixed()}
                  </Typography.Text>
                </Space>

                {/* Variations */}
                <Button
                  onClick={() => {
                    setSelectedPackage(item);
                    setVariationsModalOpen(true);
                  }}
                  type="link"
                >
                  Show Variations
                </Button>
              </Space>
            </Card>
          );
        }}
      />

      {addCartonModalOpen && ShipmentProductData && (
        <Modal
          title="Add Carton"
          open={addCartonModalOpen}
          centered
          okText="Add"
          onOk={handleOwnSourceAddPackage}
          onCancel={() => setAddCartonModalOpen(false)}
          okButtonProps={{ loading: isLoadingAddShipmentProductPackage }}
        >
          <AddOwnSourceCartonModal
            form={form}
            onOk={handleOwnSourceAddPackage}
          />
        </Modal>
      )}

      <Modal
        title="Attach Carton Image"
        open={attachCartonImageModalOpen}
        centered
        okText="Attach"
        onOk={handleAttachCartonImage}
        onCancel={() => setAttachCartonImageModalOpen(false)}
        okButtonProps={{ loading: isLoadingAttachShipmentProductPackageImage }}
      >
        <AttachCartonImageModal form={form} onOk={handleAttachCartonImage} />
      </Modal>

      {selectedPackage && (
        <Modal
          open={variationsModalOpen}
          centered
          footer={null}
          onCancel={() => {
            setVariationsModalOpen(false);
            setSelectedPackage(undefined);
          }}
        >
          <CartonVariationsModal productPackage={selectedPackage} />
        </Modal>
      )}

      {ShipmentProductData && (
        <Modal
          title={selectedTrackingId ? `Update Tracking` : `Add Tracking`}
          open={trackingModalOpen}
          centered
          okText={selectedTrackingId ? `Update` : `Add`}
          onOk={
            selectedTrackingId ? handleUpdateTracking : handleAttachTracking
          }
          onCancel={() => {
            setTrackingModalOpen(false);
            setSelectedTrackingId(undefined);
          }}
          okButtonProps={{
            loading:
              isLoadingAttachShipmentProductTrackingId ||
              isLoadingUpdateShipmentProductTrackingId,
          }}
        >
          <TrackingModificationModal
            form={form}
            onOk={handleOwnSourceAddPackage}
          />
        </Modal>
      )}
    </Card>
  );
};
