import { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import {
  Button,
  Card,
  Col,
  Dropdown,
  MenuProps,
  Modal,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import { useAtom } from "jotai";
import {
  DownOutlined,
  FileOutlined,
  HddFilled,
  PlusOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

import FiltersComponent from "components/FiltersComponent";
import CreateCustomerUser from "./components/CreateCustomerUser";
import UpdateCustomerUser from "./components/UpdateCustomerUser";

import { useGetCustomerUser } from "lib/core-react/hooks/private/useCustomerUser";
import { customerUserCollectionAtom } from "lib/core-react/store/store";

import useDataFilters from "hooks/useDataFilters";

import {
  CustomerUserCollectionModel,
  CustomerUserModel,
} from "models/customerUserCollectionModel";
import { ApiHelperModel } from "models/apiHelper";
import { PaginationModel } from "models/pagination";
import { IFilterType } from "types/filters";
import AddExchangeGroupCustomer from "./components/AddExchangeGroupCustomer";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import AddUserInvoicePaymentGroup from "./components/AddUserInvoicePaymentGroup";

type ModalForType =
  | "create"
  | "update"
  | "agent-wallet"
  | "attach-user"
  | "add-to-group"
  | "add-to-invoice-payment-group";

const CustomerUser = () => {
  const { getCustomerUser } = useGetCustomerUser();
  const [{ data: customersData, isLoading, refetch }] = useAtom(
    customerUserCollectionAtom,
  );
  const { Text } = Typography;
  const [selectedCustomerUser, setSelectedCustomerUser] = useState<
    CustomerUserModel | undefined
  >(undefined);
  const [modalFor, setModalFor] = useState<ModalForType | undefined>(undefined);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const { isMobile } = useWindowWidth();
  const navigate = useNavigate();

  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
    refetch: refetchFromFilter,
  } = useDataFilters();

  const CustomerUserData =
    customersData && new CustomerUserCollectionModel(customersData);
  // Api Call
  useEffect(() => {
    if (
      (filters && !isFetched && isFirstCall) ||
      refetch ||
      refetchFromFilter
    ) {
      filters && ApiHelperModel.makeGetRequest(filters, getCustomerUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

  const filterData = CustomerUserData?.filters;
  const paginationData = CustomerUserData?.pagination;

  useEffect(() => {
    if (!isFetched && CustomerUserData?.filters) {
      initializeAvailableFilter(filterData as IFilterType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, initializeAvailableFilter, CustomerUserData?.filters]);

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getCustomerUser,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    CustomerUserData,
    handlePaginationChange,
  );

  // Filter Handler
  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getCustomerUser);
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key === "update") {
      setIsShowModal(true);
      setModalFor("update");
    } else if (e.key === "add-to-group") {
      setIsShowModal(true);
      setModalFor("add-to-group");
    } else if (e.key === "add-to-invoice-payment-group") {
      setIsShowModal(true);
      setModalFor("add-to-invoice-payment-group");
    } else if (e.key === "agent-wallet") {
      navigate(`/customer/user/${selectedCustomerUser?.getId()}/wallet`);
    } else if (e.key === "add-to-invoice-payment-group") {
    }
  };
  const getModalTitle = (modelFor: ModalForType) => {
    switch (modelFor) {
      case "create":
        return `Create`;
      case "update":
        return `Update ${selectedCustomerUser?.getName()}`;
      case "add-to-group":
        return `Add Exchange Group Customer`;
      case "add-to-invoice-payment-group":
        return `Add Invoice payment Rule ( ${selectedCustomerUser?.getName()} )`;
      default:
        return "";
    }
  };

  const getModalContent = () => {
    if (modalFor === "create") {
      return <CreateCustomerUser setIsShowCreateModal={setIsShowModal} />;
    } else if (modalFor === "update" && selectedCustomerUser) {
      return (
        <UpdateCustomerUser
          selectedCustomerUser={selectedCustomerUser}
          setIsShowUpdateModal={setIsShowModal}
        />
      );
    } else if (modalFor === "add-to-group" && selectedCustomerUser) {
      return (
        <AddExchangeGroupCustomer
          setIsShowAddCustomerModal={setIsShowModal}
          selectedCustomerUser={selectedCustomerUser}
        />
      );
    } else if (
      modalFor === "add-to-invoice-payment-group" &&
      selectedCustomerUser
    ) {
      return (
        <AddUserInvoicePaymentGroup
          setIsShowModal={setIsShowModal}
          selectedCustomerUser={selectedCustomerUser}
        />
      );
    } else {
      return <div>Please select a proper action </div>;
    }
  };

  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "sl",
      align: "center" as const,
      width: 70,
      render: (_: string, __: CustomerUserModel, index: number) => {
        return (
          <div>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </div>
        );
      },
    },
    {
      title: "Customer Details",
      dataIndex: "name",
      hidden: !isMobile,
      key: "name",
      render: (text: string, record: CustomerUserModel) => {
        return (
          <>
            Name: <Text>{record.getName()}</Text>
            <br />
            Email: <Text>{record.getEmail()}</Text>
            <br />
            Shippig Mark: <Tag color="green">{record.getShippingMark()}</Tag>
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      hidden: isMobile,
      key: "name",
      render: (text: string) => {
        return (
          <>
            <Row>{text}</Row>
          </>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      hidden: isMobile,
      key: "email",
    },
    {
      title: "Shipping mark",
      dataIndex: "shipping_mark",
      hidden: isMobile,
      key: "shipping_mark",
      render: (text: string) => {
        return (
          <Space direction="vertical" size={2}>
            <Tag color="green">{text}</Tag>
          </Space>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      fixed: isMobile ? "right" : undefined,
      width: 100,
      render: (_: string, record: CustomerUserModel) => {
        const items: MenuProps["items"] = [
          {
            icon: <UsergroupAddOutlined />,
            label: "Update",
            key: "update",
          },
          {
            icon: <TeamOutlined />,
            label: "Add to exchange group",
            key: "add-to-group",
          },
          // {
          //   icon: <WalletOutlined />,
          //   label: "User Wallet",
          //   key: "user-wallet",
          // },
          {
            icon: <FileOutlined />,
            label: "Add to invoice payment group",
            key: "add-to-invoice-payment-group",
          },
        ];

        const menuProps = {
          items,
          onClick: handleMenuClick,
        };

        return (
          <Dropdown
            menu={menuProps}
            onOpenChange={() => setSelectedCustomerUser(record)}
          >
            <Button icon={<HddFilled />}>
              {isMobile ? (
                <DownOutlined />
              ) : (
                <>
                  Actions <DownOutlined />{" "}
                </>
              )}
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <PageHeader
            ghost={false}
            style={{ marginTop: "10px" }}
            title={"Customers"}
            extra={[
              <Button
                key={v4()}
                onClick={() => {
                  setIsShowModal(true);
                  setModalFor("create");
                }}
                icon={<PlusOutlined />}
                type="primary"
              >
                Add new customer
              </Button>,
            ]}
          >
            {filters && Object.keys(filters).length > 0 && (
              <Row>
                <Col style={{ marginBottom: "10px" }} span={24}>
                  <Card title="Filter">
                    <FiltersComponent
                      handleProductFilter={handleProductFilter}
                      handleFilterChange={handleFilterChange}
                      handelFilterClear={handelFilterClear}
                      isFetched={isFetched}
                      filters={filters}
                      filtersData={filterData}
                      isFromProductReceived={true}
                    />
                  </Card>
                </Col>
              </Row>
            )}

            <Table
              //@ts-ignore
              columns={columns}
              dataSource={CustomerUserData?.data}
              loading={isLoading}
              size="middle"
              pagination={paginationConfig}
              rowKey="id"
            />
          </PageHeader>
        </Col>
      </Row>
      {modalFor && isShowModal && (
        <Modal
          title={getModalTitle(modalFor)}
          open={isShowModal}
          footer={false}
          onCancel={() => setIsShowModal(false)}
        >
          {getModalContent()}
        </Modal>
      )}
    </>
  );
};

export default CustomerUser;
