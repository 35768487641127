import { message, Tag } from "antd";
import { SUPER_ADMIN_SLUG } from "consts/app-constants";
import {  MOVEON_ADMIN_USER_KEY} from "consts/storage";
import moment from "moment";
import pathPermissions from "./route-permissions";

const isAuthorizedRoute = (path) => {
  let pathPermisson = pathPermissions.find((item) => item.path === path);
  if (pathPermisson) {
    const user = JSON.parse(window.localStorage.getItem(MOVEON_ADMIN_USER_KEY));
    const roles = user?.roles ? user?.roles : [];
    if (roles?.length > 0) {
      for (let i = 0; i < roles.length; i++) {
        const role = roles[i];
        if (role?.permissions) {
          let userPermissons = role?.permissions.map((item) => item.name);
          return userPermissons.includes(pathPermisson.permission);
        }
      }
    }
  }
  return true;
};


const havePermission = (permission) => {
  let havePermission = false;
  const user = JSON.parse(window.localStorage.getItem(MOVEON_ADMIN_USER_KEY));
  const roles = user?.roles ? user?.roles : [];
  for (let i = 0; i < roles.length; i++) {
    const role = roles[i];
    if (role.name === SUPER_ADMIN_SLUG) {
      havePermission = true;
      break;
    }
    if (role.permissions) {
      let permissionResult = role.permissions.find(
        (item) => item.name === permission
      );
      if (permissionResult) {
        havePermission = true;
        break;
      }
    }
  }

  return havePermission;
};

const formateDate = (date) => {
  return date ? moment(date).format("DD/MM/YYYY") : null;
};

const calculateShippingPrice = (rate, commission) => {
  if (commission) {
    rate = rate + rate * (commission / 100);
  } else {
    return rate;
  }
  return rate;
};

const getStatus = (name, objectType) => {
  for (let [, value] of Object.entries(objectType)) {
    if (value.name === name) {
      return <Tag color={value.color}>{value.label}</Tag>;
    }
  }
};

const formatDate = (dateData) => {
  let monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let today = new window.Date(dateData);
  let date = today.getDate().toString();
  if (date.length === 1) {
    date = "0" + date;
  }
  return (
    date + " " + monthNames[today.getMonth()] + " , " + today.getFullYear()
  );
};

const formatTime = (time) => {
  return new window.Date(time).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

const formatDateTime = (dateTime) => {
  return formatDate(dateTime) + " - " + formatTime(dateTime);
};

function isValidISODate(dateString) {
  // Regular expression to match ISO 8601 format
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,6})?Z$/;

  return regex.test(dateString);
}

const updateSize = (url, size) => {
  // console.log(url.replace(/\d+x\d+/, `${size}x${size}`))
  return url.replace(/_\d+x\d+/, `_${size}x${size}`);
};

export const updateSizeMore = (url, size) => {
  return url?.replace(/\d+x\d+/, `${size}x${size}`);
};

export const calculateFx = (valueFx, valueCommission) => {
  return (valueFx + valueFx * (valueCommission / 100)).toFixed(2);
};

export const exportToCsv = (filename, rows) => {
  let processRow = function (row) {
    let finalVal = "";
    for (let j = 0; j < row.length; j++) {
      let innerValue = row[j] === null ? "" : row[j]?.toString();
      if (row[j] instanceof Date) {
        innerValue = row[j]?.toLocaleString();
      }
      let result = innerValue?.replace(/"/g, '""');
      if (result.search(/([",\n])/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ",";
      finalVal += result;
    }
    return finalVal + "\n";
  };

  let csvFile = "";
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  let blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });

  let link = document.createElement("a");
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    let url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const formattedWithFloatAmount = (price) => {
  return Math.round(price * 100) / 100;
};

// Quick and simple export target #table_id into a csv
export const download_table_as_csv = (table_id, separator = ",") => {
  // Select rows from table_id
  let rows = document.querySelectorAll("table#" + table_id + " tr");
  // Construct csv
  let csv = [];
  for (let i = 0; i < rows.length; i++) {
    let row = [],
      cols = rows[i].querySelectorAll("td, th");
    for (let j = 0; j < cols.length; j++) {
      // Clean innertext to remove multiple spaces and jumpline (break csv)
      // @ts-ignore
      let data = cols[j].innerText
        .replace(/(\r\n|\n|\r)/gm, "")
        .replace(/(\s\s)/gm, " ");
      data = data.replace(/"/g, '""');
      // Push escaped string
      row.push('"' + data + '"');
    }
    csv.push(row.join(separator));
  }
  let csv_string = csv.join("\n");

  // Download it
  let filename =
    "Export_" + table_id + "_" + formatDateTime(new Date()) + ".csv";
  let link = document.createElement("a");
  link.style.display = "none";
  link.setAttribute("target", "_blank");
  link.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
  );
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const toastErrors = (erorrs) => {
  if (erorrs?.errors) {
    let errors = erorrs.errors;
    Object.keys(errors)?.forEach((key) => {
      message.error(errors?.[key]?.[0]);
    });
  } else {
    message.error(erorrs?.message);
  }
};

export const convertDecimalPlaces = (value) => {
  return !isNaN(Math.round(value * 100) / 100)
    ? Math.round(value * 100) / 100
    : 0;
};

export const replaceAllHyphens = (string) => {
  if (string) {
    if (string.indexOf("-") > -1) {
      return string.replace(/-/g, " ");
    }
  }
  return string;
};

export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  }
  return string;
};

export const round = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2);
};

const transformDataKeyValueTypeToArray = (data) => {
  return Object.entries(data).map(([key, value]) => {
    return {
      title: key,
      ...value,
    };
  });
};

const slugMaker = (text) => {
  if (text) {
    return text.trim().replace(/\s+/g, " ").split(" ").join("-").toLowerCase();
  }
  return "";
};

const transformEnumToLabeledValue = (enumObj) => {
  return Object.keys(enumObj).map((key) => ({
    label: key,
    value: enumObj[key],
  }));
};

export {
  isAuthorizedRoute,
  formateDate,
  havePermission,
  calculateShippingPrice,
  getStatus,
  formatDate,
  formatTime,
  formatDateTime,
  isValidISODate,
  updateSize,
  toastErrors,
  transformDataKeyValueTypeToArray,
  slugMaker,
  transformEnumToLabeledValue,
};
