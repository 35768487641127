import { BaseResource } from '../public'
import {
  IDefaultAssignAgentPayload,
  IDefaultWithPriceAssignAgentPayload,
  IOpenBidPayload,
  IShipmentProductAddPackageForm,
  IShipmentProductAttachImageForm,
} from 'types/shipmentProductCollection'
import { ShippingModesEnums, ShippingTypesEnums } from 'enums/shippingEnums'
import { TrackingTypesEnums } from 'enums/shipForMeContextEnums'
import { ResponsePromise } from 'lib/core/request/types/typings'

class ShippingResource extends BaseResource {
  shipmentOrdersPath = `/api/shipping/admin/shipment-product/v1/shipment-orders`
  shipmentProductsPath = `/api/shipping/admin/shipment-product/v1/shipment-products`
  approvedShippingProductPath = `/api/shipping/admin/shipment-product/v1/shipment-products`
  rejectShippingProductPath = `/api/shipping/admin/shipment-product/v1/shipment-products`
  openBidPath = `/api/shipping/admin/bid/v1/`
  updateBidPath = `/api/shipping/admin/bid/v1/bids`
  abandonBidPath = `/api/shipping/admin/bid/v1/bids`
  winnerWarehousePath = `/api/shipping/admin/bid/v1/bids`
  changeShippingCountryPath = `/api/shipping/admin/shipment-product/v1/shipment-products/bulk/change-shipping-country`
  changeDestinationWarehousePath = `/api/shipping/admin/shipment-product/v1/shipment-products/bulk/change-destination-warehouse`
  changeShippingCategoryPath = `/api/shipping/admin/shipment-product/v1/shipment-products/bulk/change-shipping-category`
  changeShippingModePath = `/api/shipping/admin/shipment-product/v1/shipment-products/bulk/change-shipping-mode`

  changeShippingTypePath = `/api/shipping/admin/shipment-product/v1/shipment-products/bulk/change-shipping-type`
  defaultAgentAssignPath = `/api/shipping/admin/shipment-product/v1/shipment-products/bulk/assign-agent-default`
  defaultWithPriceAgentAssignPath = `/api/shipping/admin/shipment-product/v1/shipment-products/bulk/assign-agent-with-price`
  attachTrackingIdPath = `/api/shipping/admin/shipment-product/v1/shipment-products`
  addProductImageToProductPath = `/api/shipping/admin/shipment-product/v1/shipment-products/attach-images`
  getShipmentOrders(
    params?: string,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.shipmentOrdersPath}${params ? `?${params}` : ''}`
    return this.client.request('GET', path, undefined, {}, customHeaders)
  }
  getShipmentProducts(
    params?: string,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.shipmentProductsPath}${params ? `?${params}` : ''}`
    return this.client.request('GET', path, undefined, {}, customHeaders)
  }
  getShipmentProduct(
    productId: number,
    params?: string,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.shipmentProductsPath}/${productId}/${params ? `?${params}` : ''}`
    return this.client.request('GET', path, undefined, {}, customHeaders)
  }
  approvedShipmentProduct(
    productId: number,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.approvedShippingProductPath}/${productId}/approve`
    return this.client.request('PUT', path, undefined, {}, customHeaders)
  }
  rejectShipmentProduct(
    productId: number,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.rejectShippingProductPath}/${productId}/reject`
    return this.client.request('PUT', path, undefined, {}, customHeaders)
  }
  agentDefaultAssign(
    payload: IDefaultAssignAgentPayload,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    return this.client.request(
      'POST',
      `${this.defaultAgentAssignPath}`,
      payload,
      {},
      customHeaders,
    )
  }
  agentDefaultWithPriceAssign(
    payload: IDefaultWithPriceAssignAgentPayload,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    return this.client.request(
      'POST',
      `${this.defaultWithPriceAgentAssignPath}`,
      payload,
      {},
      customHeaders,
    )
  }
  createBid(
    payload: IOpenBidPayload,
    productId: number,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    return this.client.request(
      'POST',
      `${this.openBidPath}/${productId}/bids`,
      payload,
      {},
      customHeaders,
    )
  }
  updateBid(
    payload: IOpenBidPayload,
    bidId: number,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    return this.client.request(
      'PUT',
      `${this.updateBidPath}/${bidId}/attach`,
      payload,
      {},
      customHeaders,
    )
  }
  abandonBid(
    bidId: number,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    return this.client.request(
      'PUT',
      `${this.abandonBidPath}/${bidId}/abandon`,
      {},
      {},
      customHeaders,
    )
  }
  winnerWarehouse(
    bidId: number,
    payload: { agent_warehouse_id: number },
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    return this.client.request(
      'PUT',
      `${this.winnerWarehousePath}/${bidId}/winner`,
      payload,
      {},
      customHeaders,
    )
  }
  getAllBids(
    productId: number,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    return this.client.request(
      'GET',
      `${this.openBidPath}${productId}/bids`,
      {},
      {},
      customHeaders,
    )
  }
  // Update Shipping Country
  updateShippingCountry(
    payload: { shipment_product_ids: number[]; shipping_country_id: number },
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    return this.client.request(
      'PUT',
      `${this.changeShippingCountryPath}`,
      payload,
      {},
      customHeaders,
    )
  }
  // Update Destination warehouse
  updateDestinationWarehouse(
    payload: {
      shipment_product_ids: number[]
      destination_warehouse_id: number
    },
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    return this.client.request(
      'PUT',
      `${this.changeDestinationWarehousePath}`,
      payload,
      {},
      customHeaders,
    )
  }
  // Update Shipping category
  updateShippingCategory(
    payload: { shipment_product_ids: number[]; shipping_category_id: string },
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    return this.client.request(
      'PUT',
      `${this.changeShippingCategoryPath}`,
      payload,
      {},
      customHeaders,
    )
  }
  // Update Shipping mode
  updateShippingMode(
    payload: {
      shipment_product_ids: number[]
      shipping_mode: ShippingModesEnums
    },
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    return this.client.request(
      'PUT',
      `${this.changeShippingModePath}`,
      payload,
      {},
      customHeaders,
    )
  }
  // Update Shipping type
  updateShippingType(
    payload: {
      shipment_product_ids: number[]
      shipping_type: ShippingTypesEnums
    },
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    return this.client.request(
      'PUT',
      `${this.changeShippingTypePath}`,
      payload,
      {},
      customHeaders,
    )
  }
  // Add tracking id
  attachTrackingId(
    shipmentProductId: number,
    payload: {
      tracking: string
      type: TrackingTypesEnums
      package_ids: number[]
    },
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    return this.client.request(
      'PUT',
      `${this.attachTrackingIdPath}/${shipmentProductId}/tracking`,
      payload,
      {},
      customHeaders,
    )
  }

  // Update tracking id
  updateTrackingId(
    shipmentProductId: number,
    trackingId: number,
    payload: {
      tracking: string
      type: TrackingTypesEnums
    },
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    return this.client.request(
      'PUT',
      `${this.attachTrackingIdPath}/${shipmentProductId}/tracking/${trackingId}`,
      payload,
      {},
      customHeaders,
    )
  }
  // Add Image to product
  addProductImage(
    payload: { shipment_product_ids: number[]; images: string[] },
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    return this.client.request(
      'POST',
      `${this.addProductImageToProductPath}`,
      payload,
      {},
      customHeaders,
    )
  }

  addPackage(
    shipmentProductId: number,
    payload: IShipmentProductAddPackageForm,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.shipmentProductsPath}/${shipmentProductId}/packages`
    return this.client.request('POST', path, payload, {}, customHeaders)
  }

  deleteProduct(
    shipmentProductId: number,
    packageNumber: number,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.shipmentProductsPath}/${shipmentProductId}/packages/${packageNumber}`
    return this.client.request('DELETE', path, undefined, {}, customHeaders)
  }

  attachImage(
    shipmentProductId: number,
    payload: IShipmentProductAttachImageForm,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.shipmentProductsPath}/${shipmentProductId}/attach-images`
    return this.client.request('PUT', path, payload, {}, customHeaders)
  }
}

export default ShippingResource
