import React from "react";
import { Upload, Modal,message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FILE_UPLOAD_URL, FILE_UPLOAD_URL_TOKEKN } from "consts/storage";
import setAndGetGuestData from "helpers/setAndGetGuestData";


function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

class PicturesWallWithUpload extends React.Component {
    state = {
        previewVisible: false,
        previewImage: '',
        previewTitle: '',
        fileList: this.props.images ? this.props.images : [],
    };

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };
    removeImage = file => {
        if(this.props.handleDeleteImage){
            this.props.handleDeleteImage(file);
        }
    }
    handleChange = info => {
        let {fileList} = info;
        const status = info.file.status;
        if (status === 'done') {
            const response = info.file.response;
            this.props.handleUploadedImage(response);
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }

        //重新设置state
        this.setState( {fileList});
    };

    render() {
        const { previewVisible, previewImage, fileList, previewTitle } = this.state;
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        return (
            (<div className="clearfix">
                <Upload
                    action={`${FILE_UPLOAD_URL}`}
                    method="POST"
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                    onRemove={this.removeImage}
                    headers= {{
                        authorization: FILE_UPLOAD_URL_TOKEKN,
                        "X-GUEST-ID" :   setAndGetGuestData()?.guestId || ""  , 
                        "X-GUEST-SESSION" : setAndGetGuestData()?.guestSession || ""
                    }}
                >
                    {fileList.length >= (this.props.max ? this.props.max : 8) ? null : uploadButton}
                </Upload>
                <Modal
                    open={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>)
        );
    }
}

export default PicturesWallWithUpload;
