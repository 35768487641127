import { Divider, Typography } from "antd";

import { StyledProductView } from "./index.styled";
import { ProductDetailExtendedModel } from "models/productDetailExtendedModel";

import ProductSpecification from "./ProductSpecification";
import ProductVariations from "../ProductVariations";
import { useLocation } from "react-router-dom";
import { BuyOrderOperationEnum } from "enums/buyOrderCollectionEnums";

type Props = {
  product: ProductDetailExtendedModel;
};
const ProductView = ({ product }: Props) => {
  const location = useLocation();
  const operation = new URLSearchParams(location.search).get("operation");
  return (
    <StyledProductView>
      {product.getVariation().getSkus().getData().length ? (
        <ProductVariations variations={product.getVariation()} />
      ) : (
        <Typography.Text type="warning">
          Variations not available for this product
        </Typography.Text>
      )}
      <Divider style={{ marginTop: 15, marginBottom: 15 }} />
      { operation === BuyOrderOperationEnum.PlaceBuyOrder  ? ""  : <ProductSpecification productSpec={product.getSpecifications() || []} />}
    </StyledProductView>
  );
};

export default ProductView;
