export enum StoreCollectionEnums {
  StoreCollection = "StoreCollection",
  Store = "Store",
}

export enum StoreAvilabilityStatusEnums {
  Public = "public",
  Private = "private",
}

export enum StoreIdentifierEnums {
    aliexpress          = 'aliexpress',
    gearbest            = 'gearbest',
    tmall               = 'tmall',
    amazon_in           = 'amazon_in',
    amazon              = 'amazon',
    ali2bd              = 'ali2bd',
    netmeds             = 'netmeds',
    alibaba             = 'alibaba',
    yangkeduo           = 'yangkeduo',
    noon                = 'noon',
    onesixeighteight    = 'onesixeighteight',
    flipkart            = 'flipkart',
    taobao              = 'taobao',

}
