import { Dispatch, SetStateAction } from "react";
import { FormInstance } from "antd";

import { TimelineTrackingModel } from "models/timelineTrackingModel";
import { ITrackerAddCommentFormData } from "types/timelineTracking";
import { SelectLabelValue } from "types/buyOrderPlace";

interface IProps {
  specificUserIds: SelectLabelValue[];
  commentContent: string;
  TimelineTrackingData: TimelineTrackingModel | undefined;
  createComment: (payload: ITrackerAddCommentFormData, productId:string) => Promise<any>;
  form: FormInstance<any>;
  setCommentContent: Dispatch<SetStateAction<string>>;
  setSpecificUserIds: Dispatch<SetStateAction<SelectLabelValue[]>>;
  message: any;
  setIsErrorVisible: (value: SetStateAction<boolean>) => void;
  setCustomError: Dispatch<SetStateAction<string | null>>;
  visibility: any;
  role_ids: any;
  productId: string
}

export const handleCreateComment = async ({
  specificUserIds,
  commentContent,
  TimelineTrackingData,
  createComment,
  form,
  setCommentContent,
  setSpecificUserIds,
  message,
  setIsErrorVisible,
  setCustomError,
  visibility,
  role_ids,
  productId
}: IProps) => {
  // Check if comment contains only a single empty HTML tag
  const tagRegex = /^<\w+><\/\w+>$/;
  if (!commentContent || tagRegex.test(commentContent)) {
    setIsErrorVisible(true);
    setCustomError("Comment message cannot be empty");
    return;
  }

  const specific_user_ids: number[] = specificUserIds.map((value) =>
    Number(value.value),
  );
  const mentioned_user_ids: number[] = [];

  // parse mentioned user ids
  const regex = /data-id="([^"]+)"/g;
  let match;

  while ((match = regex.exec(commentContent)) !== null) {
    mentioned_user_ids.push(match[1]);
  }
  if (TimelineTrackingData) {
    try {
      const payload: ITrackerAddCommentFormData = {
        mentioned_user_ids,
        role_ids,
        specific_user_ids,
        message: commentContent,
        tracker_id: TimelineTrackingData.getData().getId(),
        visibility,
      };

      await createComment(payload, productId)
      form.resetFields();
      setCommentContent("");
      setSpecificUserIds([]);
      message.success("Comment created successfully");
      setIsErrorVisible(false);
    } catch (error) {
      setIsErrorVisible(true);
    }
  }
};
