import { useState } from "react";
import { useService } from "../../contexts";
import { useAtom } from "jotai";
import { getError } from "../utils/errors";
import {
  exchangeGroupCollectionAtom,
  exchangeGroupCustomerUserCollectionAtom,
} from "lib/core-react/store/store";
import {
  IExchangeGroupPostRequest,
  IExchangeGroupUserRequest,
} from "types/exchangeGroupCollection";
import { message, Modal } from "antd";
const { confirm } = Modal;

// Exchange Group
export const useGetExchangeGroup = () => {
  const [exchangeGroup, setExchangeGroup] = useAtom(
    exchangeGroupCollectionAtom,
  );
  const { storeService } = useService();
  const getExchangeGroup = async (params?: string): Promise<void> => {
    setExchangeGroup({ ...exchangeGroup, isLoading: true, error: null });
    try {
      const response = await storeService.exchangeGroupResource.get(params);
      setExchangeGroup({
        ...exchangeGroup,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setExchangeGroup({
        ...exchangeGroup,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
    }
  };
  return { getExchangeGroup } as const;
};

export const useCreateExchangeGroup = () => {
  const { storeService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [exchangeGroupData, setExchangeGroupData] = useAtom(
    exchangeGroupCollectionAtom,
  );

  const createExchangeGroup = async (payload: IExchangeGroupPostRequest) => {
    setIsLoading(true);
    try {
      const response = await storeService.exchangeGroupResource.create(payload);
      setIsLoading(false);
      setExchangeGroupData({ ...exchangeGroupData, refetch: true });
      return response.message;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
    }
  };

  return { createExchangeGroup, isLoading, isError };
};

export const useUpdateExchangeGroup = () => {
  const { storeService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [exchangeGroupData, setExchangeGroupData] = useAtom(
    exchangeGroupCollectionAtom,
  );

  const updateExchangeGroup = async (
    id: number,
    payload: IExchangeGroupPostRequest,
  ) => {
    setIsLoading(true);
    try {
      await storeService.exchangeGroupResource.update(id, payload);
      setIsLoading(false);
      setExchangeGroupData({ ...exchangeGroupData, refetch: true });
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
    }
  };

  return { updateExchangeGroup, isLoading, isError };
};

export const useDeleteExchangeGroup = () => {
  const { storeService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [exchangeGroupData, setExchangeGroupData] = useAtom(
    exchangeGroupCollectionAtom,
  );

  const deleteExchangeGroup = async (id: number) => {
    setIsLoading(true);
    let loading = false;
    confirm({
      title: "Do you want to delete exchange group?",
      content: "This action cannot be undone.",
      async onOk() {
        try {
          loading = true;
          await storeService.exchangeGroupResource.delete(id);
          setIsLoading(false);
          setExchangeGroupData({ ...exchangeGroupData, refetch: true });
          message.success("Exchange group successfully deleted");
        } catch (error) {
          setError(getError(error));
          setIsLoading(false);
          message.error(
            "Internal server error. Unable to delete. Try again later",
          );
        } finally {
          loading = false;
          setIsLoading(false);
        }
      },
      onCancel() {},
    });
  };

  return { deleteExchangeGroup, isLoading, isError };
};

export const useGetExchangeGroupCustomers = (exchange_group_id: number) => {
  const [exchangeGroupCustomers, setExchangeGroupCustomers] = useAtom(
    exchangeGroupCustomerUserCollectionAtom,
  );

  const { storeService } = useService();

  const getExchangeGroupCustomers = async (): Promise<void> => {
    setExchangeGroupCustomers({
      ...exchangeGroupCustomers,
      isLoading: true,
      error: null,
    });
    try {
      const response =
        await storeService.exchangeGroupResource.getUsers(exchange_group_id);
      setExchangeGroupCustomers({
        ...exchangeGroupCustomers,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setExchangeGroupCustomers({
        ...exchangeGroupCustomers,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
    }
  };

  return { getExchangeGroupCustomers };
};

export const useAddExchangeGroupCustomer = () => {
  const { storeService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [exchangeGroupData, setExchangeGroupData] = useAtom(
    exchangeGroupCustomerUserCollectionAtom,
  );

  const addExchangeGroupCustomer = async (
    payload: IExchangeGroupUserRequest,
  ) => {
    setIsLoading(true);
    try {
      const response =
        await storeService.exchangeGroupResource.addUser(payload);
      setIsLoading(false);
      setExchangeGroupData({ ...exchangeGroupData, refetch: true });
      return response.message;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
    }
  };

  return { addExchangeGroupCustomer, isLoading, isError };
};

export const useUpdateExchangeGroupCustomer = () => {
  const { storeService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [exchangeGroupCustomers, setExchangeGroupCustomers] = useAtom(
    exchangeGroupCustomerUserCollectionAtom,
  );

  const updateExchangeGroupCustomer = async (
    payload: IExchangeGroupUserRequest,
  ) => {
    setIsLoading(true);
    try {
      await storeService.exchangeGroupResource.deleteUser(payload.user_id);
      await storeService.exchangeGroupResource.updateUser(payload);
      message.success("Exchange group customer updated successfully");
      setIsLoading(false);
      setExchangeGroupCustomers({ ...exchangeGroupCustomers, refetch: true });
    } catch (error: any) {
      if (error.response?.data?.message) {
        message.error(error.response.data.message);
      }
      setError(getError(error));
      setIsLoading(false);
    }
  };

  return { updateExchangeGroupCustomer, isLoading, isError };
};

export const useDeleteExchangeCustomer = () => {
  const { storeService } = useService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string | null>(null);
  const [exchangeGroupCustomers, setExchangeGroupCustomers] = useAtom(
    exchangeGroupCustomerUserCollectionAtom,
  );

  const deleteExchangeGroupCustomer = async (id: number) => {
    setIsLoading(true);
    try {
      const response = await storeService.exchangeGroupResource.deleteUser(id);
      setIsLoading(false);
      setExchangeGroupCustomers({ ...exchangeGroupCustomers, refetch: true });
      return response.message;
    } catch (error: any) {
      setError(getError(error));
      setIsLoading(false);
    }
  };

  return { deleteExchangeGroupCustomer, isLoading, isError };
};
