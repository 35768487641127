import React, {useEffect, useState} from 'react'
import {Col, Image, message, Row, Skeleton, Space, Table} from 'antd'
import {formatDateTime} from "utils/helpers";
import {getBuyShipInvoiceDetails} from "utils/buy-invoice-client";
import {getInvoiceDetails} from "utils/invoice-client";
import Text from "antd/es/typography/Text.js";

const {Column} = Table;

const InvoiceTransctions = ({invoiceId, fromShipment}) => {

    const [invoiceData, setInvoiceData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [shipInvoiceData, setShipInvoiceData] = useState(null);

    const fetchItem = React.useCallback(
        () => {
            setIsLoading(true)
            getBuyShipInvoiceDetails(invoiceId)
                .then(res => {
                    setInvoiceData(res.data)
                    setIsLoading(false)
                })
                .catch(err => {
                    setIsLoading(false)
                    message.error("data not found")
                })
        },
        [invoiceId],
    )

    const fetchShipmentInvoice = React.useCallback(
        () => {
            setIsLoading(true)
            getInvoiceDetails(invoiceId)
                .then(res => {
                    setShipInvoiceData(res.data)
                    setIsLoading(false)
                })
                .catch(err => {
                    setIsLoading(false)
                    message.error("data not found")
                })
        },
        [invoiceId],
    )

    useEffect(() => {
        if (fromShipment) {
            fetchShipmentInvoice()
        } else {
            fetchItem()
        }
    }, [fetchItem, fetchShipmentInvoice, fromShipment])

    if (isLoading) {
        return (<Skeleton/>)
    }

    return (
        <>
            {
                invoiceData && invoiceData?.invoice?.payments?.length > 0 ?
                    <Row>
                        <Col span={24}>
                            <div style={{textAlign: "center", color: 'red'}}>
                                <span style={{fontWeight: "bold"}}>Transaction Details</span>
                            </div>
                            <Table
                                dataSource={invoiceData.invoice.payments}
                                bordered={false}
                                pagination={false}
                                style={{marginTop: '2%'}}
                            >
                                <Column
                                    title="#SL"
                                    dataIndex="id"
                                    key="SL"
                                    align={"center"}
                                    render={(key, record, index) => (
                                        index + 1
                                    )}
                                />
                                <Column
                                    title="Date & Time"
                                    dataIndex="dateTime"
                                    key="dateTime"
                                    align={"center"}
                                    render={(qty, record) => (
                                        <Space size={2} direction="vertical">
                                            <span>Created At: {formatDateTime(record?.created_at)}</span>
                                            {
                                                record?.payable?.meta?.approved_at &&
                                                <span>Approved At: {formatDateTime(record?.payable?.meta?.approved_at)}</span>
                                            }
                                            {
                                                record?.payable?.meta?.deposit_date &&
                                                <span>Deposit At: {formatDateTime(record?.payable?.meta?.deposit_date)}</span>
                                            }
                                            {
                                                record?.payable?.meta?.images?.length > 0 &&
                                                <>
                                                    {
                                                        record?.payable?.meta?.images?.map((el, index) => {
                                                            return (
                                                                <Image key={`elImage${index}`} width={100} src={el}/>
                                                            )
                                                        })

                                                    }
                                                </>
                                            }
                                        </Space>
                                    )
                                    }
                                />
                                <Column
                                    title="Transaction ID"
                                    dataIndex="qty"
                                    key="qty"
                                    align={"center"}
                                    render={(qty, record) => (record?.payable?.transaction_id)}
                                />
                                <Column
                                    title="Reference"
                                    dataIndex="price"
                                    key="price"
                                    align={"center"}
                                    render={(qty, record) => (record?.payable?.reference)}
                                />
                                <Column
                                    title="Amount "
                                    dataIndex="price"
                                    key="price"
                                    align={"center"}
                                    render={(price, record) => (
                                        record?.amount
                                    )}
                                />
                            </Table>

                        </Col>
                    </Row>
                    :
                    <Text> {!fromShipment && "No Transaction has been submitted"}</Text>
            }

            {
                shipInvoiceData?.invoice?.paymentHistories?.length > 0 && fromShipment ?
                    <Row>
                        <Col span={24}>
                            <div style={{textAlign: "center", color: 'red'}}>
                                <span style={{fontWeight: "bold"}}>Transaction Details</span>
                            </div>
                            <Table
                                dataSource={shipInvoiceData?.invoice?.paymentHistories}
                                bordered={false}
                                pagination={false}
                                style={{marginTop: '2%'}}
                            >
                                <Column
                                    title="#SL"
                                    dataIndex="id"
                                    key="SL"
                                    align={"center"}
                                    render={(key, record, index) => (
                                        index + 1
                                    )}
                                />
                                <Column
                                    title="Date & Time"
                                    dataIndex="dateTime"
                                    key="dateTime"
                                    align={"center"}
                                    render={(qty, record) => (formatDateTime(record?.paid_at))}
                                />
                                <Column
                                    title="Gateways"
                                    dataIndex="gateways"
                                    key="gateways"
                                    align={"center"}
                                    render={(qty, record) => (record?.payable?.gateway)}
                                />
                                <Column
                                    title="Transaction ID"
                                    dataIndex="qty"
                                    key="qty"
                                    align={"center"}
                                    render={(qty, record) => (record?.payable?.transaction_id)}
                                />
                                <Column
                                    title="Reference"
                                    dataIndex="price"
                                    key="price"
                                    align={"center"}
                                    render={(qty, record) => (record?.payable?.reference)}
                                />
                                <Column
                                    title="Amount "
                                    dataIndex="price"
                                    key="price"
                                    align={"center"}
                                    render={(price, record) => (
                                        record?.amount
                                    )}
                                />
                            </Table>

                        </Col>
                    </Row>
                    :
                    <Text> {fromShipment && "No Transaction has been submitted"}</Text>
            }

        </>
    );
}

export default InvoiceTransctions;
