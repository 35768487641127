import { BaseResource } from "../public";
import { ResponsePromise } from "lib/core/request/types/typings";
import {
  IAgentInvoiceDetailsResponse,
  ICustomerInvoiceDetailsResponse,
  IInvoiceTransactionDetailsResponse,

} from "../../../../types/InvoiceCollection";

class InvoiceResource extends BaseResource {
  customerPath = `/api/invoice/admin/invoice/v1/invoices`;
  agentPath = `/api/invoice/admin/agent-invoice/v1/agent-invoices`;

  getCustomerInvoices(
    params?:string,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.customerPath}?${
        params ? `&${params}` : ""
      }`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }
  getAgentInvoices( params?:string, customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.agentPath}?${
      params ? `&${params}` : ""
    }`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  getCustomerInvoiceDetails(id:number | string):Promise<ICustomerInvoiceDetailsResponse>{
    const path = `${this.customerPath}/${id}`
    return  this.client.request("GET", path, undefined,undefined,{})
  }
  getAgentInvoiceDetails(id:string | number):Promise<IAgentInvoiceDetailsResponse>{
    const path = `${this.agentPath}/${id}`
    return this.client.request("GET", path, undefined,undefined,{})
  }
  getTransactionDetails(invoiceId:string | number , transactionId:string | number, requestFor:"customer" | "agent"):Promise<IInvoiceTransactionDetailsResponse>{
    const customerInvoicePath =`${this.customerPath}/${invoiceId}/transactions/${transactionId}`
    const agentInvoicePath =`${this.agentPath}/${invoiceId}/transactions/${transactionId}`
    const path = requestFor === "customer"?  customerInvoicePath : agentInvoicePath
    return this.client.request("GET", path, undefined,undefined,{})
  }

  settleInvoiceTransaction(invoiceId:number | string, requestFor:"customer" | "agent"){
    const path = requestFor === "customer"?  `${this.customerPath}/${invoiceId}/settle`:`${this.agentPath}/${invoiceId}/settle`
    return this.client.request("POST", path, undefined,undefined,{})
  }

  recalculateInvoice( invoiceId:number | string, requestFor:"customer" | "agent"){
       const path = requestFor === "customer"? `${this.customerPath}/${invoiceId}/recalculate`:`${this.agentPath}/${invoiceId}/recalculate`
       return this.client.request("POST", path, undefined,undefined,{})
  }

//   invoice role

}

export default InvoiceResource;
