import React, { useEffect, useState } from "react";
import { Button, Form, InputNumber, Radio, Select, Tooltip } from "antd";

import { useGetRegion } from "lib/core-react/hooks/private";
import { useAtom } from "jotai";
import { regionRegion } from "lib/core-react/store/store";
import { RegionModel } from "models/region";
import { useAddPrice } from "lib/core-react/hooks/private/useAddonService";
import {
  renderOptionsFromEnum,
  renderOptionsFromEnumAddeonDependsOnEnum,
} from "components/Form/forms";
import {
  AddonServicePricesModel,
  Price,
} from "models/addonServiceCollectionModel";
import { DependsOnEnum } from "enums/addonServicesCollectionEnums";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

interface IProps {
  setIsShowAddPriceModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedAddPrice: AddonServicePricesModel;
}

const AddPrice = ({ setIsShowAddPriceModal, selectedAddPrice }: IProps) => {
  const { addPrice } = useAddPrice();
  const { getRegion } = useGetRegion();

  const [{ data: regionDataAtom, isLoading: regionLoading }] =
    useAtom(regionRegion);

  const [isErrorVisible, setIsErrorVisible] = useState(false);

  //const formRef: React.Ref<FormInstance<any>> = createRef();
  const [form] = Form.useForm();

  const regionData = regionDataAtom && new RegionModel(regionDataAtom);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  useEffect(() => {
    getRegion("per_page=200");
  }, []);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const onFinish = async (value: Price) => {
    try {
      await addPrice(Number(selectedAddPrice.getId()), value);
      setIsShowAddPriceModal(false);
      setIsErrorVisible(false);
      form.resetFields();
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  // const onValuesChange = (changedValues: any, allValues: any) => {
  //   if (form) {
  //     Forms.formValueChange(form, changedValues, allValues);
  //   }
  // };

  return (
    <Form
      name="serviceForm"
      form={form}
      onFinish={onFinish}
      layout="vertical"
      //onValuesChange={onValuesChange}
    >
      <Form.Item
        rules={[{ required: true, message: "Region is required" }]}
        name="region_id"
        label="Region"
      >
        <Select loading={regionLoading} placeholder="Please select a region">
          {regionData?.getRegionIdsAsObject() &&
            renderOptionsFromEnum(regionData?.getRegionIdsAsObject())}
        </Select>
      </Form.Item>

      <Form.Item
        label="Depends On"
        name="depends_on"
        rules={[{ required: true, message: "Depends On is required" }]}
      >
        <Select placeholder="Please select a Depends On">
          {renderOptionsFromEnumAddeonDependsOnEnum(DependsOnEnum)}
        </Select>
      </Form.Item>

      <Form.Item
        name="is_primary"
        label="Is primary"
        rules={[{ required: true, message: "Primary is required" }]}
      >
        <Radio.Group>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Price"
        name="price"
        rules={[{ required: true, message: "Please enter price" }]}
      >
        <InputNumber />
      </Form.Item>

      <Form.List name="slots">
        {(slotFields, { add: addSlot, remove: removeSlot }) => (
          <div>
            {slotFields.map((slotField, slotIndex) => (
              <div
                key={slotField.key}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "16px",
                }}
              >
                <div style={{ marginRight: "16px" }}>
                  <Form.Item
                    label="Min"
                    name={[slotField.name, "min_amount"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter min amount",
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                </div>
                <div style={{ marginRight: "16px" }}>
                  <Form.Item
                    label="Max"
                    name={[slotField.name, "max_amount"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter max amount",
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                </div>
                <div style={{ marginRight: "16px" }}>
                  <Form.Item
                    label="Price"
                    name={[slotField.name, "price"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter price",
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                </div>
                <Tooltip title="Remove slot">
                  <Button
                    type="primary"
                    danger
                    onClick={() => removeSlot(slotField.name)}
                    icon={<DeleteOutlined />}
                  ></Button>
                </Tooltip>
              </div>
            ))}
            <Form.Item>
              <Tooltip title="Add Slot">
                <Button
                  onClick={() => addSlot()}
                  style={{ display: "block" }}
                  type="primary"
                  icon={<PlusOutlined />}
                ></Button>
              </Tooltip>
            </Form.Item>
          </div>
        )}
      </Form.List>
      <Form.Item
        style={{ textAlign: "center", width: "100%", marginTop: "10px" }}
      >
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddPrice;
