import { ResponsePromise } from "lib/core/request/types/typings";
import BaseResource from "../public/baseResource";
class BuyActionResource extends BaseResource {
  getBuyActions(
    params?: string
  ): ResponsePromise {
    let path = `/api/buy-take-action/admin/buy-action/v1/buy-actions`;
     path = `${path}?${
      params ? `&${params}` : ""
    }`;
    return this.client.request("GET", path, undefined,undefined, {});
  }
}

export default BuyActionResource;
