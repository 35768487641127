
export enum ROLE_PERMISSION {
  ADMIN_ROLE_INDEX = "admin-role-index",
  ADMIN_ROLE_CREATE = "admin-role-create",
  ADMIN_ROLE_UPDATE = "admin-role-update",
  ADMIN_ROLE_DELETE = "admin-role-delete",
  ADMIN_USER_ROLE_SYNC = "admin-role-sync",
  ADMIN_ROLE_PERMISSION_SYNC = "admin-role-permission-sync",
  ADMIN_SHIP_FOR_ME_LIST_VIEW="ship-for-me-order-list-view"
}

