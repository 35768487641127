import { Table, Typography } from "antd";
import { StatusTag } from "components";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import { PaginationModel } from "models/pagination";
import { TransactionCollectionModel } from "models/walletTransactionCollectionModel";

interface IWalletTableProps {
  isLoading: boolean;
  transactionsCollectionData: TransactionCollectionModel | undefined;
  handlePaginationChange: (pageCount: number, pageSize: number) => void;
}

const WalletMasterTable = ({
  isLoading,
  transactionsCollectionData,
  handlePaginationChange,
}: IWalletTableProps) => {
  const paginationData = transactionsCollectionData?.getPagination();
  const { isMobile } = useWindowWidth();
  const { Text } = Typography;

  const columns = [
    {
      title: "#SL",
      dataIndex: "sl",
      key: "sl",
      align: "center" as const,
      width: 70,
      render: (_: any, id: any, index: any) => {
        return (
          <div>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </div>
        );
      },
    },
    {
      title: "Transction No",
      dataIndex: "transaction_number",
      key: "transaction_number",
      align: "center" as const,
      render: (transaction_number: string) => (
        <Text copyable>{transaction_number}</Text>
      ),
    },
    {
      title: "Transction Details",
      hidden: !isMobile,
      key: "transaction_details",
      render: (_: string, record) => {
        const prefix = record.getType() === "credit" ? "+" : "-";
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              minWidth: "150px",
            }}
          >
            <div style={{ marginBottom: "4px" }}>
              <Text strong style={{ marginRight: "5px" }}>
                Status:
              </Text>
              <StatusTag text={record.getStatus()} slug={record.getStatus()} />
            </div>
            <div style={{ marginBottom: "4px" }}>
              <Text strong style={{ marginRight: "5px" }}>
                Type:
              </Text>
              <StatusTag text={record.getType()} slug={record.getType()} />
            </div>
            <div>
              <Text strong style={{ marginRight: "5px" }}>
                Amount:
              </Text>
              {prefix} {record.amount}
            </div>
          </div>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      hidden: isMobile,
      align: "center" as const,
      render: (status: string, record) => {
        return (
          <StatusTag text={record.getStatus()} slug={record.getStatus()} />
        );
      },
    },
    {
      title: "Transction Type",
      dataIndex: "type",
      hidden: isMobile,
      key: "type",
      align: "center" as const,
      render: (type: string, record) => {
        return <StatusTag text={record.getType()} slug={record.getType()} />;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      hidden: isMobile,
      align: "center" as const,
      render: (amount: number, record: any) => {
        const prefix = record.type === "credit" ? "+" : "-";
        return (
          <div>
            {prefix} {amount}
          </div>
        );
      },
    },
  ];

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    transactionsCollectionData,
    handlePaginationChange,
  );

  const dataSource = transactionsCollectionData
    ? transactionsCollectionData.getData()
    : [];

  return (
    <Table
      style={{ marginTop: 10 }}
      loading={isLoading}
      rowKey="index"
      bordered={true}
      dataSource={dataSource}
      columns={columns}
      pagination={paginationConfig}
      scroll={{ x: 1257 }}
    />
  );
};

export default WalletMasterTable;
