import { PageHeader } from "@ant-design/pro-layout";
import {
  Button,
  Card,
  Col,
  Dropdown,
  MenuProps,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { agentInvoiceCollectionAtom } from "lib/core-react/store/store";
import useDataFilters from "hooks/useDataFilters";
import { ApiHelperModel } from "models/apiHelper";
import { PaginationModel } from "models/pagination";
import {
  CalculatorFilled,
  CheckOutlined,
  CopyOutlined,
  DownOutlined,
  EditOutlined,
  EyeFilled,
  HddFilled,
  PrinterOutlined,
} from "@ant-design/icons";
import { useInvoice } from "lib/core-react/hooks/private/useInvoice";
import {
  AgentInvoiceCollectionModel,
  AgentInvoiceModel,
} from "models/invoiceCollectionModel";
import FiltersComponent from "components/FiltersComponent";
import { IFilterType } from "types/filters";
import { useNavigate } from "react-router-dom";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import {StatusTag} from "../../../../components";
const AgentInvoiceList = () => {
  const navigate = useNavigate();
  const { getAgentInvoice, settleInvoice, recalculateInvoiceData } = useInvoice();
  const [copied, setCopied] = useState<null | number>(null);
  const [{ data: invoiceCollectionData, isLoading, refetch }] = useAtom(
    agentInvoiceCollectionAtom,
  );
  
  const [isRecalculating, setIsRecalculating] = useState(false);
  const { isMobile } = useWindowWidth();
  const { Text } = Typography;
  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
  } = useDataFilters();
  // Api Call
  useEffect(() => {
    if ((!isFetched && isFirstCall) || refetch) {
      ApiHelperModel.makeGetRequest(filters || {}, getAgentInvoice);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstCall, isFetched, refetch]);

  const InvoiceCollectionData =
    invoiceCollectionData &&
    new AgentInvoiceCollectionModel(invoiceCollectionData);
  const paginationData = InvoiceCollectionData?.getPagination();
  const filterData = InvoiceCollectionData?.getFilter();

  useEffect(() => {
    if (!isFetched && InvoiceCollectionData?.getFilter()) {
      initializeAvailableFilter(filterData as IFilterType);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFetched,
    initializeAvailableFilter,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    InvoiceCollectionData?.getFilter(),
  ]);

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getAgentInvoice,
    );
  };

  const handleShowInvoice = (invoiceId: number) => {
    if (!invoiceId) return;
    navigate(`/invoice/agent-invoice/print/${invoiceId}`);
  };

  const handleShowDetail = async (invoiceId: number) => {
    navigate(`/invoice/agent-invoice/details/${invoiceId}`);
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    InvoiceCollectionData,
    handlePaginationChange,
  );

  const handleProductFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getAgentInvoice);
  };

  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "sl",
      align: "center" as const,
      width: 70,
      render: (_: string, record: AgentInvoiceModel, index: number) => {
        return (
          <div>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </div>
        );
      },
    },
    {
      title: "Invoice Number",
      dataIndex: "invoice_number",
      key: "invoice_number",
      width: 150,
      align: "center" as const,
      render: (_: string, record: AgentInvoiceModel, index: number) => {
        const handleCopy = (value: string) => {
          navigator.clipboard.writeText(value);
          setCopied(index);
          setTimeout(() => {
            setCopied(null);
          }, 3000);
        };

        return (
          <div
            onClick={() => {
              handleCopy(record.getInvoiceNumber());
            }}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Text
              style={{
                width: "150px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              type="success"
            >
              {record.getInvoiceNumber().slice(0, 10)}
            </Text>
            {copied === index ? (
              <CheckOutlined style={{ color: "#0A8FD", marginLeft: "5px" }} />
            ) : (
              <CopyOutlined
                style={{
                  cursor: "pointer",
                  marginLeft: "5px",
                  color: "#0A8FD",
                }}
              />
            )}
          </div>
        );
      },
    },

    {
      title: "Total",
      dataIndex: "total_amount",
      key: "total_amount",
      align: "center" as const,
      render: (_: string, record: AgentInvoiceModel, index: number) => {
        return (
          <div>
            <Text>{record.getTotalAmount()}</Text>
          </div>
        );
      },
    },
    {
      title: "Financial Details",
      dataIndex: "transaction_summary",
      key: "transaction_summary",
      align: "center" as const,
      render: (_: string, record: AgentInvoiceModel, index: number) => {
        return (
          <div className="financial-details-container">
            <div className="financial-detail">
              <Text strong className="financial-text">
                Paid:
              </Text>
              <Text>{record.getTransaction().paid?.toFixed(2)}</Text>
            </div>
            <div className="financial-detail">
              <Text strong className="financial-text">
                Due:
              </Text>
              <Text>{record.getTransaction().due?.toFixed(2)}</Text>
            </div>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center" as const,
      render: (_: string, record: AgentInvoiceModel, index: number) => {
        const status = record.getStatus();
        return (
          <div>
            <StatusTag slug={status} text={status} />
            {/*<Tag style={{minWidth:"100px", textAlign:"center"}} color={getStatusBadgeColor(record.getStatus())}>{capitalize(record.getStatus().replaceAll("-"," "))}</Tag>*/}
          </div>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      align: "center" as const,
      render: (created_at, record: AgentInvoiceModel) => {
        return <Tag color="purple">{record.getCreatedAt()}</Tag>;
      },
    },
    {
      title: "Details",
      dataIndex: "ud",
      key: "details",
      align: "center" as const,
      render: (_: string, record: AgentInvoiceModel, index: number) => {
        return (
          <>
            <Space size={4}>
              <Tooltip title="Invoice Details">
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    handleShowDetail(record.getId());
                  }}
                  shape="round"
                  icon={<EyeFilled />}
                />
              </Tooltip>
              <Tooltip title="Invoice">
                <Button
                  type="default"
                  size="small"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    handleShowInvoice(record.getId());
                  }}
                  shape="round"
                  icon={<PrinterOutlined />}
                />
              </Tooltip>
            </Space>
          </>
        );
      },
    },

    {
      align: "center" as const,
      title: "Actions",
      key: "actions",
      fixed: isMobile ? "right" : undefined,
      render: (_: string, record: AgentInvoiceModel) => {
        const handleRecalculate = async () => {
          setIsRecalculating(true);
          try {
            await recalculateInvoiceData(record.getId(), "agent");
          } finally {
            setIsRecalculating(false);
          }
        };

        const menuItems: MenuProps["items"] = [
          {
            label: "Settlement",
            key: "update_settlement",
            icon: <EditOutlined />,
            onClick: async (e) => {
              await settleInvoice(record.getId(), "agent");
            },
            style: { margin: "5px", padding: "8px 16px" },
          },
          {
            label: isRecalculating ? "Wait...." : "Re-calculate",
            key: "re-calculate",
            icon: <CalculatorFilled />,
            onClick: handleRecalculate,
            disabled: isRecalculating,
            style: { margin: "5px", padding: "8px 16px" },
          },
        ];

        if (isMobile) {
          menuItems.push(
            {
              label: "Details",
              key: "details",
              icon: <EyeFilled />,
              onClick: () => handleShowDetail(record.getId()),
              style: { margin: "5px", padding: "8px 16px" },
            },
            {
              label: "Invoice",
              key: "details-invoice",
              icon: <PrinterOutlined />,
              onClick: () => handleShowInvoice(record.getId()),
              style: { margin: "5px", padding: "8px 16px" },
            },
            {
              label: <Tag color="purple">{record.getCreatedAt()}</Tag>,
              key: "created_at",
            },
          );
        }

        return (
          <Dropdown
            menu={{
              items: menuItems,
            }}
          >
            <Button icon={<HddFilled />}>
              {isMobile ? (
                <DownOutlined />
              ) : (
                <>
                  Actions <DownOutlined />{" "}
                </>
              )}
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <PageHeader
          ghost={false}
          title="Agent Invoices"
          style={{ marginTop: "10px" }}
        >
          {filters && Object.keys(filters).length > 0 && (
            <Row>
              <Col span={24}>
                <Card style={{ marginBottom: "10px" }} title="Filter">
                  <FiltersComponent
                    handleProductFilter={handleProductFilter}
                    handleFilterChange={handleFilterChange}
                    handelFilterClear={handelFilterClear}
                    isFetched={isFetched}
                    filters={filters}
                    filtersData={filterData}
                    isFromProductReceived={true}
                  />
                </Card>
              </Col>
            </Row>
          )}
          <div className="responsive-table-container">
            <Table
              loading={isLoading}
              rowKey="id"
              bordered={true}
              dataSource={
                InvoiceCollectionData ? InvoiceCollectionData.getData() : []
              }
              //@ts-ignore
              columns={columns}
              pagination={paginationConfig}
              scroll={{ x: 1237 }}
            />
          </div>
        </PageHeader>
      </div>
    </>
  );
};

export default AgentInvoiceList;
