import axios from "axios";
import {getUserTokenFromLocalStorage} from "./helper";
import { MOVEON_ADMIN_TOKEN_KEY } from "consts/storage";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + getUserTokenFromLocalStorage();

let api_url = process.env.REACT_APP_DEV_API_URL_LINK;

if (process.env.NODE_ENV === "production") {
  api_url = process.env.REACT_APP_PROD_API_URL;
}

const instance = axios.create({
  baseURL: api_url,
  headers: { "Access-Control-Allow-Origin": "*" },
});

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      window.localStorage.removeItem(MOVEON_ADMIN_TOKEN_KEY);
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

export default instance;
