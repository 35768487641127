import { UserOutlined } from "@ant-design/icons";
import { Button, Table, notification } from "antd";
import { useAtomValue } from "jotai";
import {
  useCreateBid,
  useUpdateBid,
} from "lib/core-react/hooks/private/useShipping";
import { useGetCategoryPrices } from "lib/core-react/hooks/private/useShippingAgent";
import { agentCategoryPriceReadCollectionAtom } from "lib/core-react/store/store";
import {
  AgentCategoryPriceReadCollectionModel,
  AgentCategoryPriceReadModel,
} from "models/agentCategoryPriceReadCollectionModel";
import React, { useEffect, useState } from "react";
import { ICategoryPriceDetails } from "types/agentCategoryPriceReadCollection";
import { IOpenBidPayload } from "types/shipmentProductCollection";
const { Column } = Table;

interface IProps {
  agentCategoryPriceRead: ICategoryPriceDetails;
  productId: number;
  bidId?: number;
  onCancel: () => void;
  mode: "create" | "update";
}
const AgentAssignWithBidManage = ({
  agentCategoryPriceRead,
  productId,
  onCancel,
  bidId,
  mode,
}: IProps) => {
  const { getCategoryPrices } = useGetCategoryPrices();
  const { data, isLoading: priceReadLoading } = useAtomValue(
    agentCategoryPriceReadCollectionAtom,
  );
  const agentCategoryPriceReadObj =
    data && new AgentCategoryPriceReadCollectionModel(data);

  const getData = async () => {
    await getCategoryPrices(agentCategoryPriceRead);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentCategoryPriceRead]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { createBid, isLoading } = useCreateBid();
  const { updateBid, isLoading: updateBidLoading } = useUpdateBid();

  const createBidHandler = async (
    payload: IOpenBidPayload,
    productId: number,
  ) => {
    try {
      const res = await createBid(payload, productId);
      notification["success"]({
        message: res.message,
      });
      onCancel();
    } catch (error: any) {
      notification["error"]({
        message: error.response.data.message,
      });
    }
  };
  const updateBidHandler = async (payload: IOpenBidPayload, bidId: number) => {
    try {
      const res = await updateBid(payload, bidId);
      notification["success"]({
        message: res.message,
      });
      onCancel();
    } catch (error: any) {
      notification["error"]({
        message: error.response.data.message,
      });
    }
  };

  return (
    <div>
      <div style={{ textAlign: "right" }}>
        <Button
          loading={isLoading || updateBidLoading}
          onClick={() => {
            if (mode === "create") {
              createBidHandler(
                {
                  agent_warehouse_ids: [
                    ...selectedRowKeys.map((key) => Number(key)),
                  ],
                },
                productId,
              );
            } else if (mode === "update") {
              updateBidHandler(
                {
                  agent_warehouse_ids: [
                    ...selectedRowKeys.map((key) => Number(key)),
                  ],
                },
                bidId as number,
              );
            }
          }}
          disabled={selectedRowKeys.length === 0}
          type="primary"
          icon={<UserOutlined />}
          size={"middle"}
        >
          {mode === "create" ? "Open Bid" : "Update Bid"}
        </Button>
      </div>
      {agentCategoryPriceReadObj && (
        <Table
          style={{ verticalAlign: "top" }}
          dataSource={agentCategoryPriceReadObj.getData()}
          rowKey="id"
          loading={priceReadLoading}
          rowSelection={{
            selectedRowKeys,
            //   type: "radio",
            onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
          }}

          //   loading={isLoading}
        >
          <Column
            title="Warehouse"
            dataIndex="warehouse"
            key="warehouse"
            className="vertical-align-top"
            render={(_: string, record: AgentCategoryPriceReadModel) => (
              <div style={{ display: "flex", gap: "16px" }}>
                {record.getAgentWarehouse().getName()}
              </div>
            )}
          />

          {/* <Column
            title="Actions"
            dataIndex="actions"
            key="actions"
            render={(shipping, record: AgentCategoryPriceReadModel) => (
              <Dropdown
                // disabled={selectedProductRowKeys.length === 0}
                overlay={<Menu></Menu>}
                placement="bottomCenter"
              >
                <Button icon={<HddFilled />}>
                  Actions
                  <DownOutlined />
                </Button>
              </Dropdown>
            )}
          /> */}
        </Table>
      )}
    </div>
  );
};

export default AgentAssignWithBidManage;
