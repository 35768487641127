import { PlusOutlined } from '@ant-design/icons'
import { Card, Col, Descriptions, Modal, Row } from 'antd'
import { useEffect, useState } from 'react'
import AddressModal from './AddressModal'

const Address = ({ address, selectedAddressId, setSelectedAddressId }) => {
  const [isModalVisibleAddress, setIsModalVisibleAddress] = useState(false)

  useEffect(() => {
    if (address.length > 0 && !selectedAddressId) {
      setSelectedAddressId(address[0].id)
    }
  }, [address, selectedAddressId, setSelectedAddressId])

  const handleCardClick = (id) => {
    setSelectedAddressId(id)
  }

  const showModal = () => {
    setIsModalVisibleAddress(true)
  }

  const handleOk = () => {
    setIsModalVisibleAddress(false)
  }

  const handleCancel = () => {
    setIsModalVisibleAddress(false)
  }

  return (
    <>
      <div className="site-card-wrapper">
        <Row gutter={[16, 16]}>
          {address.map((addr, index) => {
            const items = [
              {
                label: 'Name',
                children: addr.name,
              },
              {
                label: 'Address',
                children: `${addr.address_1}, ${addr.address_2}, ${addr.country}, ${addr.district}, ${addr.postal_code}`,
              },
            ]

            return (
              <Col xs={24} sm={12} md={8} lg={6} xl={4} key={index}>
                <Card
                  onClick={() => handleCardClick(addr.id)}
                  style={{
                    cursor: 'pointer',
                    border:
                      selectedAddressId === addr.id ? '2px solid #7ABA78' : '',
                    marginBottom: '0',
                  }}
                >
                  <h4> {`Address ${index + 1}`}</h4>
                  <Descriptions column={1} style={{ marginBottom: '0' }}>
                    {items.map((item) => (
                      <Descriptions.Item
                        style={{ marginBottom: '0', paddingBottom: '0' }}
                        label={item.label}
                        key={item.label}
                      >
                        {item.children}
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                </Card>
              </Col>
            )
          })}
          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            <Card
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                cursor: 'pointer',
              }}
              onClick={showModal}
            >
              <PlusOutlined style={{ fontSize: '50px', color: '#1890ff' }} />
            </Card>
          </Col>
        </Row>
      </div>

      <Modal
        title="Add New Address"
        visible={isModalVisibleAddress}
        onCancel={handleCancel}
        footer={null}
      >
        <AddressModal setIsModalVisible={setIsModalVisibleAddress} />
      </Modal>
    </>
  )
}

export default Address
