import {
  Button,
  Col,
  Form,
  FormInstance,
  InputNumber,
  Row,
  Typography,
} from "antd";
import { useUpdateFx } from "lib/core-react/hooks/private/usePurchase";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { UpdateFax } from "../BuyProducts";
import { CommissionRateTypeEnums } from "enums/exchangeListCollectionEnums";

interface IProps {
  isSeletedFaxUpdate: UpdateFax;
  setIsUpdateFxModalOpen: Dispatch<SetStateAction<boolean>>;
}

const UpdateFxModal = ({
  isSeletedFaxUpdate,
  setIsUpdateFxModalOpen,
}: IProps) => {
  const formRef: React.Ref<FormInstance<any>> = React.createRef();
  const [form] = Form.useForm();
  const { updateFx, isLoading } = useUpdateFx();
  const [, setIsErrorVisible] = useState(false);
  const [fxRate, setFxRate] = useState(isSeletedFaxUpdate.fxRate);
  const { Text } = Typography;
  const [totalValue, setTotalValue] = useState<number | string | null>();

  const [commissionRate, setCommissionRate] = useState<number | null>(0);

  const onFinish = async () => {
    const payload = {
      fx_rate: fxRate,
      commission_rate: commissionRate ? commissionRate : null,
    };

    try {
      await updateFx(isSeletedFaxUpdate.product_id, payload);
      setIsErrorVisible(false);
      setIsUpdateFxModalOpen(false);
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  useEffect(()=>{
    setTotalValue(isSeletedFaxUpdate.totalFxAndCommission)
  },[isSeletedFaxUpdate])

  useEffect(() => {
    form.resetFields();
  }, [form,isSeletedFaxUpdate]);

  const handleFxRateChange = (value: number) => {
    setFxRate(value);
  };

  const handleCommissionRateChange = (value: number | null) => {
    setCommissionRate(value);
  };

  const calculateTotalValue = (changedValues: any, allValues: any) => {
    console.log(allValues, "allValues");
    const { fx_rate, commission_rate } = allValues;
    if (fx_rate && commission_rate && isSeletedFaxUpdate.commission_type) {
      let total = 0;
      if (isSeletedFaxUpdate.commission_type === CommissionRateTypeEnums.PERCENTAGE) {
        total = fx_rate + (fx_rate * commission_rate) / 100;
      } else {
        total = fx_rate + commission_rate;
      }
      setTotalValue(total.toFixed(2));
    }
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        name="update_Fx"
        ref={formRef}
        initialValues={{
          fx_rate: isSeletedFaxUpdate.fxRate,
          commission_rate: isSeletedFaxUpdate.commission_rate,
      }}
        onValuesChange={calculateTotalValue}
      >
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item label="FX Rate" name="fx_rate">
              <InputNumber
                min={0}
                value={fxRate}
                onChange={(value) => {
                  if (value !== null) {
                    handleFxRateChange(value);
                  }
                }}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Commission Rate" name="commission_rate">
              <InputNumber
                min={0}
                value={commissionRate}
                onChange={(value) => {
                  if (value !== null) {
                    handleCommissionRateChange(value);
                  }
                }}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        {totalValue !== null && (
          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col span={24} style={{ textAlign: "center" }}>
              <Text strong>Total Value: {totalValue}</Text>
            </Col>
          </Row>
        )}
        <Form.Item>
          <Button
            loading={isLoading}
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default UpdateFxModal;
