import qs from "qs";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAtom } from "jotai";
import { SearchProps } from "antd/lib/input";
import { Pagination, Skeleton } from "antd";
import { PaginationProps } from "antd/es/pagination";

import { useGetCampaginProductList } from "lib/core-react/hooks/private/useCampaign";
import {
  campaignProductCollectionAtom,
  productSearchResultAtom,
} from "lib/core-react/store/store";
import { ProductSearchResultModel } from "models/productDetailCollectionModel";
import { CampaignProductCollectionModel } from "models/campaignProductCollectionModel";
import { useGetProductDetailsCollection } from "lib/core-react/hooks/private/useProduct";
import {
  StyledProductListMainContent,
  StyledProductListView,
} from "./index.styled";
import { VIEW_TYPE } from "../index";
import { CampaignOperationEnum } from "enums/campaignCollectionEnums";

import ProductHeader from "../ProductHeader";
import ProductGrid from "./ProductGrid";
import ProductList from "./ProductList";
import AppsHeader from "components/App/AppsContainer/AppsHeader";
import AppsContent from "components/App/AppsContainer/AppsContent";

type Props = {
  viewType: string;
  setViewType: (viewType: string) => void;
};

const ProductListing = ({ viewType, setViewType }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const operation = new URLSearchParams(location.search).get("operation");
  const id = new URLSearchParams(location.search).get("id");
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { keyword, page, ...rest } = parsedQuery;

  // Product list
  const { getProductDetailsCollection } = useGetProductDetailsCollection();

  // Product list (campaign)
  const { getCampaignProducts } = useGetCampaginProductList();

  // Product list
  const [{ data, isLoading }] = useAtom(productSearchResultAtom);
  const productList = data && new ProductSearchResultModel(data);

  // Product list (campaign)
  const [{ data: campaignData, isLoading: isCampaignDataLoading }] = useAtom(
    campaignProductCollectionAtom,
  );
  const campaignProductList =
    campaignData && new CampaignProductCollectionModel(campaignData);

  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

  const handleSelectAllProducts = () => {
    const currentlyLoadedProductIds = new Set(
      operation === CampaignOperationEnum.ViewCampaignProduct
        ? campaignProductList?.getData().map((p) => p.getId())
        : productList?.getData().map((p) => p.getId()),
    );

    // Add previously selected product IDs
    selectedProducts.forEach((id) => currentlyLoadedProductIds.add(id));

    // Convert the Set back to an array of unique IDs
    const uniqueProductIds = Array.from(currentlyLoadedProductIds);

    setSelectedProducts(uniqueProductIds);
  };

  const onSearchProduct: SearchProps["onSearch"] = async (value: string) => {
    const queryString = qs.stringify({
      ...rest,
      keyword: value,
    });
    await getProductDetailsCollection({
      region: "BD",
      locale: "en",
      keyword: value,
      ...rest,
    });
    navigate(`/product-manage/products?${queryString}`);
  };

  const onChangePage: PaginationProps["onChange"] = async (page) => {
    setCurrentPage(page);
    const queryString = qs.stringify({
      ...rest,
      page,
    });
    operation === CampaignOperationEnum.ViewCampaignProduct && id
      ? await getCampaignProducts(id, {
          page,
          ...rest,
        })
      : await getProductDetailsCollection(
          {
            region: "BD",
            locale: "en",
          },
          { page, ...rest },
        );
    navigate(`/product-manage/products?${queryString}`);
  };

  const paginationItemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement,
  ) => {
    if (type === "prev") {
      return <button>Previous</button>;
    }
    if (type === "next") {
      return <button>Next</button>;
    }
    return originalElement;
  };

  // Skeleton
  const showSkeleton =
    operation === CampaignOperationEnum.ViewCampaignProduct
      ? !campaignProductList || isCampaignDataLoading
      : !productList || isLoading;

  // Pagination
  const paginationPageSize =
    operation === CampaignOperationEnum.ViewCampaignProduct
      ? campaignProductList?.getPagination().getPerPage()
      : productList?.getPagination().getPerPage();

  const paginationTotal =
    operation === CampaignOperationEnum.ViewCampaignProduct
      ? campaignProductList?.getPagination().getTotal()
      : productList?.getPagination().getTotal();

  return (
    <StyledProductListView>
      <AppsHeader>
        <ProductHeader
          viewType={viewType}
          onSearch={onSearchProduct}
          setViewType={setViewType}
          defaultSearchValue={typeof keyword === "string" ? keyword : undefined}
          selectedProducts={selectedProducts}
          handleSelectAllProducts={handleSelectAllProducts}
        />
      </AppsHeader>

      <AppsContent>
        <StyledProductListMainContent>
          {showSkeleton ? (
            <Skeleton
              style={{
                marginTop: "16px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              active
            />
          ) : viewType === VIEW_TYPE.GRID ? (
            <ProductGrid
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              ecommerceList={
                operation !== CampaignOperationEnum.ViewCampaignProduct
                  ? productList?.getData()
                  : undefined
              }
              campaignProductList={
                operation === CampaignOperationEnum.ViewCampaignProduct
                  ? campaignProductList?.getData()
                  : undefined
              }
              loading={isLoading}
            />
          ) : (
            <ProductList
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              ecommerceList={
                operation !== CampaignOperationEnum.ViewCampaignProduct
                  ? productList?.getData()
                  : undefined
              }
              campaignProductList={
                operation === CampaignOperationEnum.ViewCampaignProduct
                  ? campaignProductList?.getData()
                  : undefined
              }
              loading={isLoading}
            />
          )}

          {showSkeleton ? (
            <Skeleton
              style={{
                marginTop: "16px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
              active
            />
          ) : (
            <Pagination
              style={{
                marginTop: "16px",
                display: "flex",
                justifyContent: "center",
              }}
              onChange={onChangePage}
              showSizeChanger={false}
              current={currentPage}
              pageSize={paginationPageSize}
              total={paginationTotal}
              itemRender={paginationItemRender}
            />
          )}
        </StyledProductListMainContent>
      </AppsContent>
    </StyledProductListView>
  );
};

export default ProductListing;
