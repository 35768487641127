import { getUser } from './auth-client'
import {MOVEON_ADMIN_USER_KEY} from 'consts/storage'


async function bootstrapAppData() {
	const user = JSON.parse(window.localStorage.getItem(MOVEON_ADMIN_USER_KEY))
	if (user) {
		return { user }
	}
	const data = await getUser()
	if (!data) {
		return { user: null }
	}
	return {
		user: data.data
	}
}

export { bootstrapAppData }
