import { Rate } from "antd";

import {
  StyledProductDetailHeader,
  StyledProductDetailHeaderContent,
  StyledProductDetailHeaderInfo,
  StyledProfileMbText,
  StyledProfileReviewText,
  StyledProductDetailSocial,
} from "./index.styled";
import { ProductDetailExtendedModel } from "models/productDetailExtendedModel";

interface IProps {
  product: ProductDetailExtendedModel;
}

const Header = ({ product }: IProps) => {
  return (
    <StyledProductDetailHeader>
      <StyledProductDetailHeaderInfo>
        <h3>{product.getTitle()}</h3>
        <StyledProductDetailHeaderContent>
          <Rate defaultValue={product.getRatingsAverage()} disabled />
          <StyledProfileReviewText>
            {product.getRatingsCount() || 0 + " reviews"}
          </StyledProfileReviewText>

          <StyledProfileReviewText>
            Sales :{" "}
            <StyledProfileMbText>{product.getSales()}</StyledProfileMbText>
          </StyledProfileReviewText>
          <StyledProfileReviewText>
            Stock :{" "}
            <StyledProfileMbText>{product.getStock()}</StyledProfileMbText>
          </StyledProfileReviewText>
          {product.getVendorLink() ? (
            <StyledProfileReviewText>
              <StyledProfileMbText>
                <a href={product.getVendorLink()}>Original link</a>
              </StyledProfileMbText>
            </StyledProfileReviewText>
          ) : (
            ""
          )}
        </StyledProductDetailHeaderContent>
      </StyledProductDetailHeaderInfo>

      <StyledProductDetailSocial></StyledProductDetailSocial>
    </StyledProductDetailHeader>
  );
};

export default Header;
