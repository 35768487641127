import React, { useEffect } from "react";
import { Button, Form, FormInstance, Modal, TreeSelect, message } from "antd";
import { useAtom } from "jotai";

import { useGetShippingCategory } from "lib/core-react/hooks/private";
import { useUpdateShippingCategory } from "lib/core-react/hooks/private/useShipping";
import { shippingCategoryAtom } from "lib/core-react/store/store";
import { ShippingCategoryModel } from "models/shippingCategory";
import { getError } from "lib/core-react/hooks/utils/errors";

interface IProps {
  productIds: number[];
  isOpen: boolean;
  onCancel: () => void;
  existingId: string | undefined;
}

export const ChangeShippingCategoryModal = ({
  isOpen,
  onCancel,
  productIds,
  existingId,
}: IProps) => {
  const { getShippingCategory } = useGetShippingCategory();
  const { updateShippingCategory, isLoading: updateShippingCategoryLoading } =
    useUpdateShippingCategory();
  const [{ data: shippingCategoryAtomData, isLoading }] =
    useAtom(shippingCategoryAtom);
  const formRef: React.Ref<FormInstance<any>> = React.createRef();
  const [form] = Form.useForm();

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [shippingCategoryAtomData, form]);

  useEffect(() => {
    if (!shippingCategoryAtomData?.data) {
      getShippingCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingCategoryAtomData]);

  const shippingCategoryOptions =
    shippingCategoryAtomData &&
    new ShippingCategoryModel(
      shippingCategoryAtomData,
    ).getShippingCategoryTreeIds();

  // Ok handler
  const onOkHandler = async (data: { shipping_category_id: string }) => {
    const payload: {
      shipment_product_ids: number[];
      shipping_category_id: string;
    } = {
      shipment_product_ids: productIds,
      shipping_category_id: data.shipping_category_id,
    };

    try {
      const data = await updateShippingCategory(payload);
      message.success(data.message);
      onCancel();
    } catch (error) {
      message.error(getError(error));
    }
  };

  return (
    <Modal
      title="Change Shipping Category"
      open={isOpen}
      destroyOnClose={true}
      confirmLoading={updateShippingCategoryLoading}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        onFinish={onOkHandler}
        form={form}
        layout="vertical"
        name="update_warehouse"
        ref={formRef}
        initialValues={{
          shipping_category_id: existingId,
        }}
      >
        <Form.Item
          label="Select Shipping Category"
          name="shipping_category_id"
          rules={[{ required: true, message: "Shipping Category is required" }]}
        >
          <TreeSelect
            showSearch
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            placeholder="Please select a shipping category"
            allowClear
            loading={isLoading}
            treeLine={{ showLeafIcon: true }}
            treeData={shippingCategoryOptions}
          />
        </Form.Item>

        <Form.Item>
          <Button
            loading={updateShippingCategoryLoading}
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
