import {
  IShipmentProductOrder,
  IShipmentOrderCountry,
  IShipmentOrderCurrency,
  IShipmentProduct,
  IShipmentProductCollection,
  IShipmentProductCountry,
  IShipmentProductDestinationWarehouse,
  IShipmentProductRegion,
  IShipmentProductShippingCategory,
  IShipmentProductInvoice,
  IShipmentProductInvoiceTransactionSummary,
  IShipmentProductAddonService,
  IShipmentProductAddonServiceCollection,
  IShipmentOrderShipmentProductCollection,
  IShipmentProductAddonServicePriceCollection,
  IShipmentProductAddonServicePrices,
  IShipmentProductAddonServicePriceSlotCollection,
  IShipmentProductAddonServicePriceSlot,
  IShipmentProductDeliveryEnrollmentCollection,
  IShipmentProductDeliveryEnrollment,
  IShipmentProductDeliveryRequest,
  IShipmentProductDeliveryRequestCourierOption,
} from "types/shipmentProductCollection";
import { ShipmentProductAgentAssociationModel } from "./shipmentProductAgentAssociationModel";
import { ShipmentProductCustomerAssociationModel } from "./shipmentProductCustomerAssociationModel";
import { ShipmentProductMoveOnAssociationModel } from "./shipmentProductMoveOnAssociationModel";
import { ShipmentProductPackageCollectionModel } from "./shipmentProductPackageCollectionModel";
import { IFilterType } from "types/filters";
import {
  CommonProductImageCollectionModel,
  CommonProductImageModel,
  CommonUserModel,
} from "./shared";
import { ShippingModesEnums, ShippingTypesEnums } from "enums/shippingEnums";
import { PaginationModel } from "./paginationModel";
import { ProductSourceEnum } from "enums/shipForMeContextEnums";

export class ShipmentOrderCurrencyModel {
  object: string;
  id: number;
  code: string;
  name: string;
  symbol: string;
  symbol_native: string;

  constructor(data: IShipmentOrderCurrency) {
    this.id = data.id;
    this.object = data.object;
    this.code = data.code;
    this.symbol = data.symbol;
    this.symbol_native = data.symbol_native;
    this.name = data.name;
  }

  getCode = () => this.code;
  getSymbol = () => this.symbol;
  getSymbolNative = () => this.symbol;
  getName = () => this.name;
}
export class ShipmentOrderCountryModel {
  object: string;
  id: number;
  name: string;
  code: string;
  flag: string;

  constructor(data: IShipmentOrderCountry) {
    this.id = data?.id;
    this.object = data?.object;
    this.code = data?.code;
    this.name = data?.name;
    this.flag = data?.flag;
  }

  getName = () => this.name;
  getCode = () => this.code;
  getFlag = () => this.flag;
  getId = () => this.id;
}

export class ShipmentProductOrderModel {
  id: number;
  order_number: string;
  order_handler: CommonUserModel | null;
  type: string;
  status: string;
  payment_status: string;
  customer: CommonUserModel;
  created_at: string;
  oauth_client_id: null | string;
  addresses: Record<string, string>[];

  constructor(data: IShipmentProductOrder) {
    this.id = data.id;
    this.order_number = data.order_number;
    this.order_handler = data.order_handler
      ? new CommonUserModel(data.order_handler)
      : null;
    this.customer = new CommonUserModel(data.customer);
    this.type = data.type;
    this.status = data.status;
    this.payment_status = data.payment_status;
    this.created_at = data.created_at;
    this.oauth_client_id = data.oauth_client_id;
    this.addresses = data.addresses;
  }

  getId = () => this.id;
  getOrderHandler = () => this.order_handler;
  getCustomer = () => this.customer;
  getCreatedAt = () => this.created_at;
  getStatus = () => this.status;
  getType = () => this.type;
  getOauthClientId = () => this.oauth_client_id;
  getOrderNumber = () => this.order_number;
  getPaymentStatus = () => this.payment_status;
}

export class ShipmentProductRegionModel {
  object: string;
  id: number;
  name: string;
  store: string;
  store_type: string;
  code: string;
  currency: ShipmentOrderCurrencyModel;

  constructor(data: IShipmentProductRegion) {
    this.id = data.id;
    this.object = data.object;
    this.name = data.name;
    this.store = data.store;
    this.store_type = data.store_type;
    this.code = data.code;
    this.currency = new ShipmentOrderCurrencyModel(data.currency);
  }

  getCode = () => this.code;
  getName = () => this.name;
  getCurrency = () => this.currency;
}
export class ShipmentProductShippingCategoryModel {
  object: string;
  id: string;
  parent_id: null | string;
  base_shipping_category_id: string;
  name: string;
  slug: string;
  status: string;

  constructor(data: IShipmentProductShippingCategory) {
    this.id = data.id;
    this.object = data.object;
    this.name = data.name;
    this.parent_id = data.parent_id;
    this.base_shipping_category_id = data.base_shipping_category_id;
    this.slug = data.slug;
    this.status = data.status;
  }

  getId = () => this.id;
  getName = () => this.name;
  getStatus = () => this.status;
}
export class ShipmentProductCountryModel {
  object: string;
  id: number;
  country: ShipmentOrderCountryModel;

  constructor(data: IShipmentProductCountry) {
    this.id = data?.id;
    this.object = data?.object;
    this.country = new ShipmentOrderCountryModel(data?.country);
  }

  getCountry = () => this.country;
  getId = () => this.id;
}
export class ShipmentProductDestinationWarehouseModel {
  object: string;
  id: number;
  slug: string;
  name: string;
  country: ShipmentOrderCountryModel;

  constructor(data: IShipmentProductDestinationWarehouse) {
    this.id = data?.id;
    this.object = data?.object;
    this.slug = data?.slug;
    this.name = data?.name;
    this.country = new ShipmentOrderCountryModel(data?.country);
  }

  getName = () => this.name;
  getId = () => this.id;
  getCountry = () => this.country;
}

export class ShipmentProductInvoiceTransactionSummaryModel {
  paid: number;
  due: number;
  overpaid: number;

  constructor(data: IShipmentProductInvoiceTransactionSummary) {
    this.paid = data.paid;
    this.due = data.due;
    this.overpaid = data.overpaid;
  }

  getPaid = () => this.paid;
  getDue = () => this.due;
  getOverpaid = () => this.overpaid;
}

export class ShipmentProductInvoiceModel {
  object: string;
  id: number;
  invoice_number: string;
  type: null;
  product_number: null;
  status: string;
  total_amount: number;
  initial_amount: number;
  refundable_amount: number;
  transaction_summary: ShipmentProductInvoiceTransactionSummaryModel;
  created_at: string;

  constructor(data: IShipmentProductInvoice) {
    this.object = data.object;
    this.id = data.id;
    this.invoice_number = data.invoice_number;
    this.type = data.type;
    this.product_number = data.product_number;
    this.status = data.status;
    this.total_amount = data.total_amount;
    this.initial_amount = data.initial_amount;
    this.refundable_amount = data.refundable_amount;
    this.transaction_summary =
      new ShipmentProductInvoiceTransactionSummaryModel(
        data.transaction_summary,
      );
    this.created_at = data.created_at;
  }

  getId = () => this.id;
  getInvoiceNumber = () => this.invoice_number;
  getType = () => this.type;
  getProductNumber = () => this.product_number;
  getStatus = () => this.status;
  getTotalAmount = () => this.total_amount;
  getInitialAmount = () => this.initial_amount;
  getRefundableAmount = () => this.refundable_amount;
  getTransactionSummary = () => this.transaction_summary;
  getCreatedAt = () => this.created_at;
}

export class ShipmentProductAddonServicePriceSlotModel {
  object: string;
  id: number;
  addon_service_price_id: number;
  min_amount: number;
  max_amount: number;
  price: number;

  constructor(data: IShipmentProductAddonServicePriceSlot) {
    this.object = data.object;
    this.id = data.id;
    this.addon_service_price_id = data.addon_service_price_id;
    this.min_amount = data.min_amount;
    this.max_amount = data.max_amount;
    this.price = data.price;
  }

  getId = () => this.id;
  getAddonServicePriceId = () => this.addon_service_price_id;
  getMinAmount = () => this.min_amount;
  getMaxAmount = () => this.max_amount;
  getPrice = () => this.price;
}

export class ShipmentProductAddonServicePriceSlotCollectionModel {
  object: string;
  data: ShipmentProductAddonServicePriceSlotModel[];

  constructor(data: IShipmentProductAddonServicePriceSlotCollection) {
    this.object = data.object;
    this.data = data.data.map(
      (d) => new ShipmentProductAddonServicePriceSlotModel(d),
    );
  }

  getData = () => this.data;
}

export class ShipmentProductAddonServicePricesModel {
  object: string;
  id: number;
  addon_service_id: number;
  region_id: number;
  depends_on: string;
  is_primary: number;
  price: number;
  price_slots: ShipmentProductAddonServicePriceSlotCollectionModel;

  constructor(data: IShipmentProductAddonServicePrices) {
    this.object = data.object;
    this.id = data.id;
    this.addon_service_id = data.addon_service_id;
    this.region_id = data.region_id;
    this.depends_on = data.depends_on;
    this.is_primary = data.is_primary;
    this.price = data.price;
    this.price_slots = new ShipmentProductAddonServicePriceSlotCollectionModel(
      data.price_slots,
    );
  }

  getId = () => this.id;
  getAddonServiceId = () => this.addon_service_id;
  getRegionId = () => this.region_id;
  getDependsOn = () => this.depends_on;
  getIsPrimary = () => this.is_primary;
  getPrice = () => this.price;
  getPriceSlots = () => this.price_slots;
}

export class ShipmentProductAddonServicePriceCollectionModel {
  object: string;
  data: ShipmentProductAddonServicePricesModel[];

  constructor(data: IShipmentProductAddonServicePriceCollection) {
    this.object = data.object;
    this.data = data.data.map(
      (d) => new ShipmentProductAddonServicePricesModel(d),
    );
  }

  getData = () => this.data;
}

export class ShipmentProductAddonServiceModel {
  object: string;
  id: number;
  name: string;
  addon_for: string;
  identifier: string;
  description: string;
  status: string;
  image: CommonProductImageModel;
  prices: ShipmentProductAddonServicePriceCollectionModel;
  created_at: string;

  constructor(data: IShipmentProductAddonService) {
    this.object = data.object;
    this.id = data.id;
    this.name = data.name;
    this.addon_for = data.addon_for;
    this.identifier = data.identifier;
    this.description = data.description;
    this.status = data.status;
    this.image = new CommonProductImageModel(data.image);
    this.prices = new ShipmentProductAddonServicePriceCollectionModel(
      data.prices,
    );
    this.created_at = data.created_at;
  }

  getId = () => this.id;
  getName = () => this.name;
  getAddonFor = () => this.addon_for;
  getIdentifier = () => this.identifier;
  getDescription = () => this.description;
  getStatus = () => this.status;
  getImage = () => this.image;
  getPrices = () => this.prices;
  getCreatedAt = () => this.created_at;
}

export class ShipmentProductAddonServiceCollectionModel {
  object: string;
  data: ShipmentProductAddonServiceModel[];

  constructor(data: IShipmentProductAddonServiceCollection) {
    this.object = data.object;
    this.data = data.data.map((d) => new ShipmentProductAddonServiceModel(d));
  }

  getData = () => this.data;
}

export class ShipmentProductDeliveryRequestCourierOptionModel {
  availability_status: string;
  created_at: string;
  flat_amount: number;
  id: number;
  name: string;
  note: string;
  object: string;
  price_type: string;
  provider_id: string;

  constructor(data: IShipmentProductDeliveryRequestCourierOption) {
    this.availability_status = data.availability_status;
    this.created_at = data.created_at;
    this.flat_amount = data.flat_amount;
    this.id = data.id;
    this.name = data.name;
    this.note = data.note;
    this.object = data.object;
    this.price_type = data.price_type;
    this.provider_id = data.provider_id;
  }

  getAvailabilityStatus = () => this.availability_status;
  getCreatedAt = () => this.created_at;
  getFlatAmount = () => this.flat_amount;
  getId = () => this.id;
  getName = () => this.name;
  getNote = () => this.note;
  getPriceType = () => this.price_type;
  getProviderId = () => this.provider_id;
}

export class ShipmentProductDeliveryRequestModel {
  object: string;
  id: number;
  request_number: number;
  shipping_address: IFilterType;
  status: string;
  courier_note: string | null;
  courier_option: ShipmentProductDeliveryRequestCourierOptionModel;
  created_at: string;
  note: string | null;
  total_height: number;
  total_length: number;
  total_quantity: number;
  total_weight: number;
  total_width: number;

  constructor(data: IShipmentProductDeliveryRequest) {
    this.object = data.object;
    this.id = data.id;
    this.request_number = data.request_number;
    this.shipping_address = data.shipping_address;
    this.status = data.status;
    this.courier_note = data.courier_note;
    this.courier_option = new ShipmentProductDeliveryRequestCourierOptionModel(
      data.courier_option,
    );
    this.created_at = data.created_at;
    this.note = data.note;
    this.total_height = data.total_height;
    this.total_length = data.total_length;
    this.total_quantity = data.total_quantity;
    this.total_weight = data.total_weight;
    this.total_width = data.total_width;
  }

  getId = () => this.id;
  getRequestNumber = () => this.request_number;
  getShippingAddress = () => this.shipping_address;
  getStatus = () => this.status;
  getCourierNote = () => this.courier_note;
  getCourierOption = () => this.courier_option;
  getCreatedAt = () => this.created_at;
  getNote = () => this.note;
  getTotalHeight = () => this.total_height;
  getTotalLength = () => this.total_length;
  getTotalQuantity = () => this.total_quantity;
  getTotalWeight = () => this.total_weight;
  getTotalWidth = () => this.total_width;
}

export class ShipmentProductDeliveryEnrollmentModel {
  object: string;
  id: number;
  delivery_request: ShipmentProductDeliveryRequestModel;
  created_at: string;

  constructor(data: IShipmentProductDeliveryEnrollment) {
    this.object = data.object;
    this.id = data.id;
    this.delivery_request = new ShipmentProductDeliveryRequestModel(
      data.delivery_request,
    );
    this.created_at = data.created_at;
  }

  getId = () => this.id;
  getDeliveryRequest = () => this.delivery_request;
  getCreatedAt = () => this.created_at;
}

export class ShipmentProductDeliveryEnrollmentCollectionModel {
  object: string;
  data: ShipmentProductDeliveryEnrollmentModel[];

  constructor(data: IShipmentProductDeliveryEnrollmentCollection) {
    this.object = data.object;
    this.data = data.data.map(
      (d) => new ShipmentProductDeliveryEnrollmentModel(d),
    );
  }

  getData = () => this.data;
}

export class ShipmentProductModel {
  object: string;
  id: number;
  product_number: string;
  product_source: ProductSourceEnum;
  images: CommonProductImageCollectionModel;
  link: string | null;
  title: string;
  order: ShipmentProductOrderModel;
  currency: ShipmentOrderCurrencyModel;
  status: string;
  status_label: string;
  total_quantity: number;
  total_weight: number;
  shipping_address: IFilterType;
  shipping_country: ShipmentProductCountryModel;
  destination_country: ShipmentOrderCountryModel;
  destination_warehouse: ShipmentProductDestinationWarehouseModel;
  region: ShipmentProductRegionModel;
  shipping_category: ShipmentProductShippingCategoryModel;
  shipment_instruction: string | null;
  shipping_mode: ShippingModesEnums;
  shipping_type: ShippingTypesEnums;
  packages: ShipmentProductPackageCollectionModel;
  addon_services: ShipmentProductAddonServiceCollectionModel;
  customer_invoice: ShipmentProductInvoiceModel | null;
  agent_invoice: ShipmentProductInvoiceModel | null;
  agent_association: ShipmentProductAgentAssociationModel | null;
  customer_association: ShipmentProductCustomerAssociationModel | null;
  moveon_association: ShipmentProductMoveOnAssociationModel | null;
  delivery_enrollments: ShipmentProductDeliveryEnrollmentCollectionModel | null;

  constructor(data: IShipmentProduct) {
    this.object = data.object;
    this.id = data.id;
    this.product_number = data.product_number;
    this.product_source = data.product_source;
    this.images = new CommonProductImageCollectionModel(data.images);
    this.shipping_country = new ShipmentProductCountryModel(
      data.shipping_country,
    );
    this.link = data.link;
    this.title = data.title;
    this.order = new ShipmentProductOrderModel(data.order);
    this.currency = new ShipmentOrderCurrencyModel(data.currency);
    this.status = data.status;
    this.status_label = data.status_label;
    this.total_quantity = data.total_quantity;
    this.total_weight = data.total_weight;
    this.shipping_address = data.shipping_address;
    this.shipment_instruction = data.shipment_instruction;
    this.destination_country = new ShipmentOrderCountryModel(
      data.destination_country,
    );
    this.destination_warehouse = new ShipmentProductDestinationWarehouseModel(
      data.destination_warehouse,
    );
    this.region = new ShipmentProductRegionModel(data.region);
    this.shipping_category = new ShipmentProductShippingCategoryModel(
      data.shipping_category,
    );
    this.shipping_mode = data.shipping_mode;
    this.shipping_type = data.shipping_type;
    this.packages = new ShipmentProductPackageCollectionModel(data.packages);
    this.addon_services =
      data.addon_services &&
      new ShipmentProductAddonServiceCollectionModel(data.addon_services);
    this.customer_invoice =
      data.customer_invoice &&
      new ShipmentProductInvoiceModel(data.customer_invoice);
    this.agent_invoice =
      data.agent_invoice && new ShipmentProductInvoiceModel(data.agent_invoice);
    this.agent_association = data.agent_association
      ? new ShipmentProductAgentAssociationModel(data.agent_association)
      : null;
    this.customer_association = data.customer_association
      ? new ShipmentProductCustomerAssociationModel(data.customer_association)
      : null;
    this.moveon_association = data.moveon_association
      ? new ShipmentProductMoveOnAssociationModel(data.moveon_association)
      : null;
    this.delivery_enrollments = data.delivery_enrollments
      ? new ShipmentProductDeliveryEnrollmentCollectionModel(
          data.delivery_enrollments,
        )
      : null;
  }
  getTitle = () => this.title;
  getOrder = () => this.order;
  getProductNumber = () => this.product_number;
  getProductSource = () => this.product_source;
  getStatus = () => this.status;
  getStatusLabel = () => this.status_label;
  getTotalQuantity = () => this.total_quantity;
  getTotalWeight = () => this.total_weight;
  getShippingAddress = () => this.shipping_address;
  getShipmentInstruction = () => this.shipment_instruction;
  getPackages = () => this.packages;
  getAddonServices = () => this.addon_services;
  getShippingCategory = () => this.shipping_category;
  getCustomerAssociation = () => this.customer_association;
  getAgentAssociation = () => this.agent_association;
  getMoveonAssociation = () => this.moveon_association;
  getId = () => this.id;
  getRegion = () => this.region;
  getDestinationWarehouse = () => this.destination_warehouse;
  getLink = () => this.link;
  getCurrency = () => this.currency;
  getDestinationCountry = () => this.destination_country;
  getAgentInvoice = () => this.agent_invoice;
  getCustomerInvoice = () => this.customer_invoice;
  getDeliveryEnrollments = () => this.delivery_enrollments;

  getImages() {
    return this.images;
  }
  getUserId(): number {
    return this.getOrder().getCustomer().getId();
  }
  getRegionCode(): string {
    return this.region?.code || "";
  }
  getShippingCategoryId(): string {
    return this.shipping_category.id;
  }
  getDestinationWarehouseId(): number {
    return this.destination_warehouse.id;
  }
  getShippingType() {
    return this.shipping_type;
  }
  getShippingMode() {
    return this.shipping_mode;
  }
  getShippingCountry() {
    return this.shipping_country;
  }
  getShippingCountryName() {
    return this.shipping_country?.country.name;
  }
}

export class ShipmentProductCollectionModel {
  object: string;
  data: ShipmentProductModel[];
  pagination: PaginationModel;
  filters: IFilterType | undefined;

  constructor(data: IShipmentProductCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new ShipmentProductModel(item));
    this.pagination = new PaginationModel(data.pagination);
    this.filters = data.filters;
  }

  getData = () => this.data;
  getAllProducts = () => this.data;
}

export class ShipmentOrderShipmentProductCollectionModel {
  object: string;
  data: ShipmentProductModel[];

  constructor(data: IShipmentOrderShipmentProductCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new ShipmentProductModel(item));
  }

  getData = () => this.data;
  getAllProducts = () => this.data;
}
