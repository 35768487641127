import React, { useEffect, useState, createRef } from "react";
import { Alert, Button, Form, Input } from "antd";
import { FormInstance } from "antd/es/form/Form";

import { useRole, useUpdateRole } from "lib/core-react/hooks/private/useRole";

import { Forms } from "models/form";
import {
  RoleModel,
  RolePostRequestModel,
  RoleUpdateRequestModel,
} from "models/roleCollectionModel";
import {handleFormErrors} from "../../../../../utils/helper";

interface IProps {
  onHide: () => void;
  role?: RoleModel;
}

const RoleModification = ({ onHide, role }: IProps) => {
  const { createRole, isLoading, isError } = useRole();
  const {
    updateRole,
    isLoading: isLoadingUpdate,
  } = useUpdateRole();
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const formRef: React.Ref<FormInstance<any>> = createRef();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const onFinishUpdate = async (value: RoleUpdateRequestModel) => {
    const payload: RoleUpdateRequestModel = {
      name: value.name,
      label: value.label,
      permission_ids: role
        ? role.permissions?.data?.map((permission) => permission.id)
        : [],
    };
    try {
      role && (await updateRole(role.id, payload));
      onHide();
      setIsErrorVisible(false);
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  const onFinishCreate = async (value: RolePostRequestModel) => {
    const payload: RolePostRequestModel = {
      name: value.name,
      label: value.label,
    };
    try {
      await createRole(payload);
      onHide();
      setIsErrorVisible(false);
    } catch (error:any) {
      if(error.response.data.errors){
        handleFormErrors(form, error.response);
      }
      setIsErrorVisible(true);
    }
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    Forms.formValueChange(form, changedValues, allValues);
  };

  return (
    <Form
      onFinish={role ? onFinishUpdate : onFinishCreate}
      form={form}
      layout="vertical"
      name={role ? "Update Role" : "Create Role"}
      onFieldsChange={onValuesChange}
      ref={formRef}
      initialValues={{
        name: role?.name,
        label: role?.label,
      }}
    >
      <Form.Item
        rules={[
          {
            required: true,
            message: "Name is required",
          },
        ]}
        label="Name"
        name="name"
      >
        <Input placeholder="Name" />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Label is required" }]}
        label="Label"
        name="label"
      >
        <Input placeholder="Label" />
      </Form.Item>

      <Form.Item>
        <Button
          loading={isLoading || isLoadingUpdate}
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RoleModification;
