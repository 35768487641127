import { Alert, Button, Form, Input, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import { renderOptionsFromEnum } from "components/Form/forms";
import { useUpdateCourierGateway } from "lib/core-react/hooks/private";
import { useGetCountry } from "lib/core-react/hooks/private/useCountry";
import { useAtom } from "jotai";
import { regionCountry } from "lib/core-react/store/store";
import { CountryModel } from "models/country";
import { ICourierGatewayForm } from "types/courierGatewayCollection";
import { CourierGatewayStatus } from "enums/courierGatewayCollectionEnums";
import { CourierGatewayModel } from "models/courierGatewayCollection";

interface IProps {
  selectedCourierGateway: CourierGatewayModel;
  setIsShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UpdateCourierGateway = ({
  selectedCourierGateway,
  setIsShowUpdateModal,
}: IProps) => {
  const { getCountry } = useGetCountry();
  const [{ data: countryDataAtom, isLoading: countryLoading }] =
    useAtom(regionCountry);
  const { updateCourierGateway, isLoading, isError } =
    useUpdateCourierGateway();

  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const formRef: React.Ref<FormInstance<any>> = React.createRef();

  const [form] = Form.useForm();

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [selectedCourierGateway, form]);

  useEffect(() => {
    getCountry("per_page=200");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // @ts-ignore FIXME
  const countryData = countryDataAtom && new CountryModel(countryDataAtom);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const onFinish = async (value: ICourierGatewayForm) => {
    const payload = {
      name: value.name,
      country_id: value.country_id,
      slug: value.slug,
      status: value.status,
    };

    try {
      await updateCourierGateway(selectedCourierGateway.id, payload);
      setIsShowUpdateModal(false);
      setIsErrorVisible(false);
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  return (
    <>
      <div>
        <Form
          onFinish={onFinish}
          form={form}
          layout="vertical"
          name="Edit_country"
          ref={formRef}
          initialValues={{
            name: selectedCourierGateway.name,
            slug: selectedCourierGateway.slug,
            status: selectedCourierGateway.status,
          }}
        >
          <Form.Item
            rules={[
              {
                required: true,
                message: "Courier gateway name is required",
              },
            ]}
            label="Name"
            name="name"
          >
            <Input placeholder="Courier gateway name" />
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: "Slug name is required" }]}
            label="Slug"
            name="slug"
          >
            <Input placeholder="Slug name" />
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: "Country is required" }]}
            name="country_id"
            label="Country"
          >
            <Select
              loading={countryLoading}
              placeholder="Please select a Country"
            >
              {countryData?.getCountryCode() &&
                renderOptionsFromEnum(countryData?.getCountryId())}
            </Select>
          </Form.Item>

          <Form.Item
            rules={[{ required: true, message: "Status is required" }]}
            name="status"
            label="Status"
          >
            <Select placeholder="Please select a Status">
              {renderOptionsFromEnum(CourierGatewayStatus)}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              loading={isLoading}
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
            >
              Submit
            </Button>
          </Form.Item>
          {isErrorVisible && (
            <Alert
              style={{ marginTop: "7px" }}
              message={isError}
              closable
              afterClose={() => setIsErrorVisible(false)}
              type="error"
              showIcon
            />
          )}
        </Form>
      </div>
    </>
  );
};
