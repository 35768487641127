import { BaseResource } from "../public";
import { ResponsePromise } from "lib/core/request/types/typings";
class V1Resource extends BaseResource {
  path = `/api/v1/admin/bulk-users`;

  getUsers(
    params: string,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.path}?${params ? params : ""}`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

}

export default V1Resource;
