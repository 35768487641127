import { Table, TableColumnsType, Tag } from "antd";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";
import { ShippingCartItemPackageModel } from "models/shippingCartCollectionModel";
import { IShippingCartItemPackageVariation } from "types/shippingCartCollection";

interface IProps {
  data: ShippingCartItemPackageModel[];
}
const PackagesTable = ({ data }: IProps) => {
  const { isMobile } = useWindowWidth();
  const columns: TableColumnsType<ShippingCartItemPackageModel> = [
    {
      title: <div>Package Info</div>,
      dataIndex: "quantity",
      key: "title",
      align: "center" as const,
      render: (_: string, record: ShippingCartItemPackageModel) => {
        return (
          <div
            style={{
              textTransform: "capitalize",
              display: "flex",
              flexWrap: "wrap",
              gap: 10,
              width: 300,
            }}
          >
            <div>
              <strong>Price:</strong>{" "}
              <span>
                {record.price.currency} {record.price.amount}
              </span>
            </div>
            <div>
              <strong>Contains:</strong>{" "}
              <Tag
                style={{
                  textTransform: "capitalize",
                  background: "#14b8a6",
                  color: "white",
                  borderRadius: 10,
                }}
              >
                {record.contains}
              </Tag>
            </div>

            <div>
              <strong>HS Code:</strong> <span>{record.hs_code}</span>
            </div>
            <div>
              <strong>Weight:</strong>{" "}
              <span>
                {record.weight.amount} {record.weight.unit}
              </span>
            </div>
            <div>
              <strong>Width:</strong>{" "}
              <span>
                {record.width.amount} {record.width.unit}
              </span>
            </div>
            <div>
              <strong>Height:</strong>{" "}
              <span>
                {record.height.amount} {record.height.unit}
              </span>
            </div>
            <div>
              <strong>Length:</strong>{" "}
              <span>
                {record.length.amount} {record.length.unit}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Variations",
      dataIndex: ["variations", "data"],
      key: "title",
      render: (variations: IShippingCartItemPackageVariation[]) => {
        return (
          <div
            style={{ display: "flex", gap: "15px", flexDirection: "column" }}
          >
            {variations.map((variation) => (
              <div
                key={variation.id}
                style={{
                  display: "flex",
                  gap: "30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <div>
                    <strong>SKU ID:</strong> <span>{variation.sku_id}</span>
                  </div>
                  <div>
                    <strong>Quantity:</strong> <span>{variation.quantity}</span>
                  </div>
                </div>
                <div>
                  {variation.properties.data.map((prop) => (
                    <div key={prop.id}>
                      <strong>{prop.property_name}</strong>:{" "}
                      {prop.property_value_title}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={{ pageSize: 3 }}
      rowKey="id"
      scroll={{ x: 900 }}
    />
  );
};

export default PackagesTable;
