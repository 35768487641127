import QueryString from "qs";
import keyManager from "lib/core/request/keyManager";
import { Config } from "lib/core/request/types/request";
import { Client } from "lib/core/request/request";



export default class BaseResource {
  public client: Client;

  constructor(config: Config) {
    this.client = new Client(config);
  }
  /**
   * Set a PublishableApiKey that will be sent with each request
   * to define the scope of available resources.
   *
   * @param key - PublishableApiKey identifier
   */
  setPublishableKey(key: string) {
    keyManager.registerPublishableApiKey(key);
  }

  protected addQuery(query: Record<string, any>) {
    return QueryString.stringify(query, { addQueryPrefix: true });
  }
}
