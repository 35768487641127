import { SharedModel } from "./SharedModel";

export class Forms extends SharedModel {
  static formValueChange(form: any, changedValues: any, allValues: any) {
    const fieldName: any = changedValues[0].name[0];
    const fieldNameValue: any = changedValues[0].value;
    if (fieldName === "name") {
      form.setFieldsValue({ slug: `${this.slugMaker(fieldNameValue)}` });
    }
  }
}
