import React, { useEffect, useState, createRef } from "react";
import { Alert, Button, Form, Input, notification } from "antd";
import { FormInstance } from "antd/es/form/Form";

import { useUpdateCustomerUser } from "lib/core-react/hooks/private/useCustomerUser";

import { Forms } from "models/form";
import {
  CustomerUserModel,
  CustomerUserUpdateRequestModel,
} from "models/customerUserCollectionModel";

interface IProps {
  setIsShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCustomerUser: CustomerUserModel;
}

const UpdateCustomerUser = ({
  setIsShowUpdateModal,
  selectedCustomerUser,
}: IProps) => {
  const { updateCustomerUser, isLoading, isError } = useUpdateCustomerUser();
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const formRef: React.Ref<FormInstance<any>> = createRef();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const onFinish = async (value: CustomerUserUpdateRequestModel) => {
    try {
      await updateCustomerUser(selectedCustomerUser.id, value);
      setIsShowUpdateModal(false);
      setIsErrorVisible(false);
      notification['success']({
        message: "Updated User successfully",
      })
    } catch (error:any) {
      setIsErrorVisible(true);
      if(error?.response?.data?.message){
        notification['error']({
          message: error.response.data.message,
        })
      }
    }
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    Forms.formValueChange(form, changedValues, allValues);
  };

  return (
    <Form
      onFinish={onFinish}
      form={form}
      layout="vertical"
      name="Update User"
      onFieldsChange={onValuesChange}
      ref={formRef}
      initialValues={{
        name: selectedCustomerUser.name,
        shipping_mark: selectedCustomerUser.shipping_mark,
      }}
    >
      <Form.Item
        rules={[
          {
            required: true,
            message: "User name is required",
          },
        ]}
        label="Name"
        name="name"
      >
        <Input placeholder="User name" />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: "Shipping mark is required" }]}
        label="Shipping Mark"
        name="shipping_mark"
      >
        <Input placeholder="Shipping mark" />
      </Form.Item>

      <Form.Item>
        <Button
          loading={isLoading}
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Submit
        </Button>
      </Form.Item>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isError}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </Form>
  );
};

export default UpdateCustomerUser;
