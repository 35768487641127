/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import {
  Button,
  Dropdown,
  MenuProps,
  Modal,
  Popconfirm,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  HddFilled,
} from "@ant-design/icons";

import { addressCollectionAtom } from "lib/core-react/store/store";
import { getError } from "lib/core-react/hooks/utils/errors";
import useDataFilters from "hooks/useDataFilters";

import { ApiHelperModel } from "models/apiHelper";
import { PaginationModel } from "models/pagination";
import { DestinationWarehouseAddressModalType } from "types/destinationWarehouseCollection";

import {
  AddressCollectionModel,
  AddressModel,
} from "models/addressCollectionModel";
import {
  useAddressDelete,
  useGetAddressV2,
} from "lib/core-react/hooks/private/useAddress";
import { DestinationWarehouseModel } from "models/destinationWarehouseCollectionModel";
import { getKeyByValue } from "./utils";
import { DwAddressModification } from "./DwAddressModification";

interface IProps {
  destinationWarehouse: DestinationWarehouseModel;
}

export const DwAddressesTable = ({ destinationWarehouse }: IProps) => {
  const { deleteAddress, isLoading: isLoadingDelete } = useAddressDelete();
  const { getAddress } = useGetAddressV2();
  const [{ data: addressCollectionData, isLoading, refetch }] = useAtom(
    addressCollectionAtom,
  );
  const AddressCollectionData =
    addressCollectionData && new AddressCollectionModel(addressCollectionData);

  const [modalOpen, setModalOpen] = useState<
    DestinationWarehouseAddressModalType | undefined
  >(undefined);

  const [
    selectedDestinationWarehouseAddress,
    setSelectedDestinationWarehouseAddress,
  ] = useState<AddressModel | undefined>(undefined);

  const { filters, handleFilterChange } = useDataFilters();

  // Api Call
  useEffect(() => {
    getAddress(
      `countries[0]=${destinationWarehouse.getCountry().getCode()}&destination_warehouse_id=${destinationWarehouse.getId()}`,
    );
  }, [destinationWarehouse]);

  // Pagination
  const paginationData = AddressCollectionData?.getPagination();

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getAddress,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    AddressCollectionData,
    handlePaginationChange,
  );

  const handleDeleteAddress = async (id: number) => {
    try {
      await deleteAddress(id);
      await getAddress(
        `countries[0]=${destinationWarehouse.getCountry().getCode()}&destination_warehouse_id=${destinationWarehouse.getId()}`,
      );
      message.success("Address deleted.");
    } catch (error) {
      message.error(getError(error));
    }
  };

  const onCancelModal = () => {
    setModalOpen(undefined);
    setSelectedDestinationWarehouseAddress(undefined);
  };

  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "id",
      align: "center" as const,
      render: (_: string, __: AddressModel, index: number) => {
        return (
          <>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </>
        );
      },
    },
    {
      title: "Address info",
      dataIndex: "address",
      key: "address",
      render: (_: string, record: AddressModel) => {
        return (
          <div
            style={{ display: "flex", flexWrap: "wrap", gap: 10, width: 200 }}
          >
            {record.getAddress() && Object.keys(record.getAddress()).length ? (
              Object.keys(record.getAddress()).map((key) => (
                <div key={key}>
                  <Typography.Text
                    strong
                    style={{ textTransform: "capitalize" }}
                  >
                    {key.replaceAll("_", " ")}:
                  </Typography.Text>{" "}
                  {record.getAddress()[key]}
                </div>
              ))
            ) : (
              <Tag color="error">Address not found</Tag>
            )}

            {record.getIsDefaultBilling() || record.getIsDefaultShipping() ? (
              <Tag icon={<CheckCircleOutlined />} color="success">
                {record.getIsDefaultShipping()
                  ? "Default Shipping Address"
                  : record.getIsDefaultBilling()
                    ? "Default Billing Address"
                    : "Not default"}
              </Tag>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: string, record: AddressModel) => {
        const items: MenuProps["items"] = [
          {
            key: "1",
            label: (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectedDestinationWarehouseAddress(record);
                  setModalOpen(
                    DestinationWarehouseAddressModalType["Update Address"],
                  );
                }}
              >
                <EditOutlined /> <span> Update</span>
              </div>
            ),
          },
          // {
          //   key: "2",
          //   label: (
          //     <Popconfirm
          //       title="Delete this address"
          //       description="Are you sure to delete this address?"
          //       onConfirm={() => handleDeleteAddress(record.getId())}
          //       onCancel={onCancelModal}
          //       okButtonProps={{ loading: isLoadingDelete }}
          //       okText="Yes"
          //       okType="danger"
          //       cancelText="No"
          //       disabled={record.getIsDefaultShipping()}
          //     >
          //       <span onClick={(e) => e.stopPropagation()}>
          //         <DeleteOutlined /> Delete
          //       </span>
          //     </Popconfirm>
          //   ),
          //   danger: true,
          // },
        ];

        if (!record.getIsDefaultShipping()) {
          items.push({
            key: '2',
            label: (
              <Popconfirm
                title="Delete this address"
                description="Are you sure to delete this address?"
                onConfirm={() => handleDeleteAddress(record.getId())}
                onCancel={onCancelModal}
                okButtonProps={{ loading: isLoadingDelete }}
                okText="Yes"
                okType="danger"
                cancelText="No"
              >
                <span onClick={(e) => e.stopPropagation()}>
                  <DeleteOutlined /> Delete
                </span>
              </Popconfirm>
            ),
            danger: true,
          });
        }

        return (
          <Dropdown menu={{ items }}>
            <Button icon={<HddFilled />}>
              Actions <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        size="small"
        bordered
        loading={isLoading}
        dataSource={AddressCollectionData?.getData()}
        pagination={paginationConfig}
        rowKey="id"
      />

      <Modal
        title={modalOpen && getKeyByValue(modalOpen)}
        open={Boolean(modalOpen)}
        footer={null}
        onCancel={onCancelModal}
      >
        {modalOpen === DestinationWarehouseAddressModalType["Update Address"] &&
          selectedDestinationWarehouseAddress && (
            <DwAddressModification
              destinationWarehouse={destinationWarehouse}
              data={selectedDestinationWarehouseAddress}
              onHide={onCancelModal}
            />
          )}
      </Modal>
    </>
  );
};
