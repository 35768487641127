import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  InputNumber,
  Modal,
  notification,
  Row,
  Space,
  Table,
} from "antd";
import type { TableColumnsType } from "antd";
import {
  IDisputeApprovedFormData,
  IDisputeDetails,
} from "types/disputeDetailsCollection";
import { disputeDetailsAtom } from "lib/core-react/store/store";
import { useAtom } from "jotai";
import { useParams } from "react-router-dom";
import {
  useGetDispute,
  useUpdateDispute,
} from "lib/core-react/hooks/private/useDispute";
import {
  DisputeBuyProductVariationPropertiesModel,
  DisputeDetailsCollectionModel,
  DisputeItems,
} from "models/disputeDetailsCollectionModel";
import DisputeVariation from "./DisputeVariation";
import DisputeItemProperties from "./DisputeItemProperties";
import { DisputeItemStatusEnum } from "enums/disputeCollectionEnum";
import { JSX } from "react/jsx-runtime";
import DisputeVariationProduct from "./DisputeVariationProduct";
import { NotificationPlacement } from "antd/es/notification/interface";

const ItemsTable = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [approvePayload, setApprovePayload] = useState<DisputeItems[]>([]);
  const { getDispute } = useGetDispute();
  const { updateDispute } = useUpdateDispute();
  const [{ data: disputeDetailsData, isLoading, refetch, error }] =
    useAtom(disputeDetailsAtom);
  const { id } = useParams();
  const [api, contextHolder] = notification.useNotification();

  const onNotification = ({
    status,
    reason,
    placement = "top",
  }: {
    status: DisputeItemStatusEnum;
    reason: string;
    placement?: NotificationPlacement;
  }) => {
    switch (status) {
      case DisputeItemStatusEnum.APPROVED:
        api.info({
          message: ` ${status}`,
          description: `${reason}`,
          placement,
        });
        break;
      case DisputeItemStatusEnum.REJECTED:
        api.error({
          message: `${status}`,
          description: `${reason}`,
          placement,
        });
        break;
      default:
        break;
    }
  };

  const parsedId: number = Number(id);
  useEffect(() => {
    getDispute(parsedId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, refetch]);

  const disputeDetailsCollectionModelData =
    disputeDetailsData && new DisputeDetailsCollectionModel(disputeDetailsData);
  const detailsData = disputeDetailsCollectionModelData?.getData();
  const items = detailsData?.getItems();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm(); // Create a form instance

  const showModal = () => {
    setVisible(true);
  };
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setVisible(false);
        handleApprove(values);
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
        api.error({
          message: `Some went wrong`,
          description: `${"please communcate with developer"}`,
          placement: "top",
        });
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const hasSelected = selectedRowKeys.length > 0;

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    record: DisputeItems[],
  ) => {
    setApprovePayload(record);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: DisputeItems) => {
      return {
        disabled: record.item.getApprovedPercentage(),
      };
    },
  };

  const handleApprove = async (values: { approved_percentage: number }) => {
    try {
      if (hasSelected) {
        const payloadData = approvePayload
          ?.map((p: DisputeItems) => {
            return {
              id: p?.item?.getId(),
              approved_percentage: values?.approved_percentage,
            };
          })
          .filter((d) => d?.approved_percentage);

        const payload = { items: payloadData } as IDisputeApprovedFormData;
        const response: IDisputeDetails = await updateDispute(
          parsedId,
          payload,
        );
        if (response) {
          onNotification({
            status: DisputeItemStatusEnum?.APPROVED,
            reason: "Successfully Approved",
          });
        }
      } else {
        onNotification({
          reason: "Please Select Atleast 1 Itmem",
          status: DisputeItemStatusEnum?.REJECTED,
        });
      }
    } catch (error) {
      onNotification({
        reason: "Something went wrong",
        status: DisputeItemStatusEnum?.REJECTED,
      });
    }
  };

  if (error) {
    api?.error({
      message: "error occur ",
    });
  }
  const columns: TableColumnsType<DisputeItems> = [
    {
      title: "properties",
      dataIndex: "key",
      key: "key",
      render: (tags: any, record) => {
        const properties = record.item
          ?.getBuyProductVariation()
          ?.getProperties();

        return (
          <div>
            <Row>
              <Col>
                <Space size={1} direction="vertical">
                  {properties?.map(
                    (
                      property: JSX.IntrinsicAttributes &
                        DisputeBuyProductVariationPropertiesModel,
                      index: number,
                    ) => {
                      if (index === properties.length - 1) {
                        return (
                          <div key={property?.getId()}>
                            <Divider />
                            <DisputeItemProperties
                              key={property?.getId()}
                              {...property}
                            />
                          </div>
                        );
                      }
                      return (
                        <DisputeItemProperties
                          key={property?.getId()}
                          {...property}
                        />
                      );
                    },
                  )}
                </Space>
              </Col>
            </Row>
          </div>
        );
      },
    },

    {
      title: "Variant",
      dataIndex: "dispute_id",
      key: "key",
      render: (tags: any, record) => {
        const variation = record?.item?.getBuyProductVariation();
        return <DisputeVariation {...variation} />;
      },
    },
    {
      title: "Variant Product",
      dataIndex: "dispute_id",
      key: "key",
      render: (tags: any, record) => {
        const requested_amount = record?.item?.getRequestedAmount();
        const approved_amount = record?.item?.getApprovedAmount();
        const approved_percentage = record?.item?.getApprovedPercentage();
        const status = record?.item?.getStatus();
        return (
          <DisputeVariationProduct
            data={{
              requested_amount,
              approved_amount,
              approved_percentage,
              status,
            }}
          />
        );
      },
    },
  ];
  return (
    <div>
      <Row style={{ marginBottom: 10 }}>
        <Button disabled={!hasSelected} type="primary" onClick={showModal}>
          Approve
        </Button>
      </Row>

      <Table
        scroll={{ x:1000 }}
        rowKey="key"
        bordered={true}
        //@ts-ignore
        rowSelection={rowSelection}
        columns={columns}
        dataSource={items}
        pagination={false}
        loading={isLoading}
      />

      <div>
        <Modal
          title={` Write Approved Percentage for Dispute id ${selectedRowKeys.join(
            ",",
          )}`}
          open={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Save"
          confirmLoading={false}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              initialValue={
                hasSelected && approvePayload[0].item.getApprovedPercentage()
              }
              label="Approved Percentage"
              name="approved_percentage"
              style={{ color: "red" }}
            >
              <InputNumber
                placeholder="Give Approved Percentage"
                color="#db3a3a"
                min={1}
                max={100}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
      {contextHolder}
    </div>
  );
};

export default ItemsTable;
