/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useAtomValue } from 'jotai'
import { PageHeader } from '@ant-design/pro-layout'
import {
  Card,
  Col,
  Row,
  Dropdown,
  Space,
  Table,
  TableColumnsType,
  Image,
  Button,
  MenuProps,
  Modal, Tag, Typography, message, Tabs,
} from 'antd'

import {
  useGetBuyOrders, useGetBuyProduct,
  useRejectBuyProduct,
} from '../../../lib/core-react/hooks/private/usePurchase'
import { buyOrderCollectionAtom } from '../../../lib/core-react/store/buyOrderAtoms/buyOrderAtoms'
import useDataFilters from '../../../hooks/useDataFilters'
import { ApiHelperModel } from '../../../models/apiHelper'
import {
  BuyOrderCollectionModel,
  ShipmentProductModel,
} from '../../../models/buyOrderCollectionModel'
import { IFilterType } from '../../../types/filters'
import FiltersComponent from '../../../components/FiltersComponent'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
  EditOutlined,
  HddFilled,
} from '@ant-design/icons'
import BuyProductsTable from '../components/BuyProductsTable'
import ProductInfo, { IProductInfo } from '../components/ProductInfo'
import { ProductMeta } from '../components'
import ShippingDetails from '../components/ShippingDetails'
import BuyProductAgentAssignModal from '../BuyProducts/components/BuyProductAgentAssignModal'
import { PaginationModel } from 'models/pagination'
import { ShippingTypesEnums } from '../../../enums/shippingEnums'
import { ChangeShippingTypeModal } from 'pages/Shipping/ShipmentProducts'
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import TabPane from "antd/es/tabs/TabPane";
import {TimelineTracking} from "../components/viewDetails/TimelineTracking";
import {useAtom} from "jotai";
import {buyProductDetail} from "../../../lib/core-react/store/store";
import {BuyProductView} from "../../../models/buyProductDetailModel";
const {Text} = Typography
export interface BuyOrderDataType {
  key: React.Key
  orderId: string
  handler?: string
  customerName: string
  customerPhone: string
  totalItems: number
  total: string
  due: number
  createdAt: string
}

interface ExpandedDataType {
  key: React.Key
  date: string
  name: string
  upgradeNum: string
}

const BuyOrders = () => {
  const navigate = useNavigate()
  const [selectedOrderId, setSelectedOrderId] = useState<string>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [openReject, setOpenReject] = useState<boolean>(false)
  const [isApproveModalOpen, setIsApproveModalOpen] = useState<boolean>(false)
  const [isSeletedProduct, setIsSeletedProduct] = useState<number>()
  const {isMobile} = useWindowWidth()
  const [isRejectionLoading, setIsRejectionLoading] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<string>('')
  const { getBuyProduct } = useGetBuyProduct();
  const [
    {
      data: buyProductDetailData,
      isLoading: isBuyProductLoding,
    },
  ] = useAtom(buyProductDetail);
  // Change Shipping type
  const [changeShippingTypeDetails, setChangeShippingTypeDetails] = useState<{
    isOpen: boolean
    productIds: number[] | undefined
    existingData: ShippingTypesEnums | undefined
  }>({
    isOpen: false,
    productIds: undefined,
    existingData: undefined,
  })

  const {
    data: buyOrdersCollectionData,
    isLoading,
    refetch,
  } = useAtomValue(buyOrderCollectionAtom)

  const { getBuyOrders } = useGetBuyOrders()
  const { rejectBuyProduct } = useRejectBuyProduct()

  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
    refetch: refetchFromFilter
  } = useDataFilters()

  // --------------------------------- API Call -> ------------------------------ //

  useEffect(() => {
    if ((filters && !isFetched && isFirstCall) || refetch || refetchFromFilter) {
      filters && ApiHelperModel.makeGetRequest(filters, getBuyOrders)
    }
  }, [isFirstCall, filters, isFetched, refetch ,refetchFromFilter])

  // ------------------------------ API Data Model -> --------------------------- //

  const buyOrderCollectionModel =
    buyOrdersCollectionData &&
    new BuyOrderCollectionModel(buyOrdersCollectionData)

  // ---------------------------- Filter Data Model -> -------------------------- //

  let orderFiltersModel = buyOrderCollectionModel
    ? buyOrderCollectionModel.getFilters()
    : undefined

  // ---------------------- Getting all available filters -> -------------------- //

  useEffect(() => {
    if (!isFetched && orderFiltersModel) {
      initializeAvailableFilter(orderFiltersModel as IFilterType)
    }
  }, [isFetched, initializeAvailableFilter])

  // ------------------------------ Event Handlers -> ---------------------------- //

  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize }
    handleFilterChange(pageInfo)
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getBuyOrders,
    )
  }
  const fetchItemDetails=(id:number)=>{
    getBuyProduct(id);
  }

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    buyOrderCollectionModel,
    handlePaginationChange,
  )

  // Filter Handler
  const handleFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getBuyOrders)
  }

  // Reject handlers =>

  const showRejectConfirm = (opration: number) => {
    setIsSeletedProduct(opration)
    setOpenReject(true)
  }

  const handleRejectOk = async (productID: number) => {

    try {
      setIsRejectionLoading(true)
      const data = await rejectBuyProduct(productID)
      if (data) {
        message.success(data.message)
        setOpenReject(false)
      }
    } catch (error: any) {
      if(error?.response?.data?.message){
        message.error(error.response.data.message)
      }
      setOpenReject(false)
    }finally {
      setIsRejectionLoading(false)
    }
  }

  const handleRejectCancel = () => {
    setOpenReject(false)
  }
  const buyProductDetailsForTimeLine = buyProductDetailData && new BuyProductView(buyProductDetailData);

  const changeShippingTypeHandler = (
    ids: number[],
    shippingType: ShippingTypesEnums | undefined,
  ) => {
    setChangeShippingTypeDetails({
      existingData: shippingType,
      isOpen: true,
      productIds: ids,
    })
  }

  const ShowApprovedModal = (opration: number) => {
    setIsSeletedProduct(opration)
    setIsApproveModalOpen(true)
  }

  const createMenuProps = (operation: {
    shipmentProduct: ShipmentProductModel | undefined
    productID: number
    productNumber: any
  }) => ({
    items: [
      {
        key: 'approved',
        icon: <CheckCircleOutlined />,
        onClick: () => ShowApprovedModal(operation.productID),
        label: 'Approved',
      },

      {
        key: 'reject',
        icon: <CloseCircleOutlined />,
        onClick: () => showRejectConfirm(operation.productID),
        label: 'Reject',
      },
      // {
      //   label: "Shipment Actions",
      //   key: "shipmentActions",
      //   icon: <UserOutlined />,
      //   children: [
      //     {
      //       key: "Change Shipping Mode",
      //       label: "Change Shipping Type",
      //       onClick: () =>
      //         changeShippingTypeHandler(
      //           [operation.shipmentProduct?.getId() as number],
      //           operation.shipmentProduct?.getShippingType() as ShippingTypesEnums,
      //         ),
      //     },
      //   ],
      // },
    ],
  })

  // ----------------------------------- Nested Table Configuration Start -> ----------------------------------- //
  const expandedRowRender = (singleOrder: BuyOrderDataType) => {
    const columns: TableColumnsType<ExpandedDataType> = [
      {
        title: 'Image',
        dataIndex: 'image',
        key: 'image',
        width: 120,
        render: (image) => (
          <Image
            width={100}
            src={image}
            placeholder={<Image preview={false} src={image} width={100} />}
          />
        ),
      },
      {
        title: 'Product Info',
        dataIndex: 'productInfo',
        key: 'productInfo',
        width: 210,
        render: (productInfo: IProductInfo, record) =>{
          return  (
            <>
              <ProductInfo productInfo={productInfo} />
            </>

          )
        },
      },
      // {
      //   title: 'Product Meta',
      //   key: 'productMeta',
      //   dataIndex: 'productMeta',
      //   width: 400,
      //   render: (productMeta) => <ProductMeta productMeta={productMeta} />,
      // },
      {
        title: 'Shipping Details',
        dataIndex: 'shippingDetails',
        width:200,
        key: 'shippingDetails',
        render: (shippingDetails) => (
          <ShippingDetails shippingDetails={shippingDetails} />
        ),
      },
      {
        title: 'Actions',
        dataIndex: 'operation',
        key: 'operation',
        fixed: isMobile? "right" : undefined,
        width: isMobile? 100:150,
        render: (operation: {
          productID: number
          productNumber: string
          shipmentProduct: ShipmentProductModel
        }) => {
          const menuProps = createMenuProps(operation)
          return (
            <>
              <Space size="middle">
                <Dropdown menu={menuProps}>
                  <Button icon={<HddFilled />}>
                    {isMobile ? (
                      <DownOutlined />
                    ) : (
                      <>
                        Actions <DownOutlined />{" "}
                      </>
                    )}
                  </Button>
                </Dropdown>
              </Space>
            </>
          )
        },
      },
    ]

    const data = buyOrderCollectionModel
      ? buyOrderCollectionModel.getProcessedProducts(singleOrder.orderId)
      : []
    return (
      <BuyProductsTable
        columns={columns}
        dataSource={data}
        pagination={false}
        setSelectedRowKeys={setSelectedRowKeys}
        selectedRowKeys={selectedRowKeys}
        rowKey="uniqueKey"
        expandedRowRender={{
          expandedRowKeys:data?.map(x=>x.uniqueKey),
          expandedRowRender:(record)=> {
            return  <Tabs  onChange={(key) => {
              setActiveTab(key)
              if(key.includes("timeline")) {
                console.log(record,'record')
                fetchItemDetails(record.uniqueKey)
              }
            }}  activeKey={activeTab}>
              <TabPane   tab="Product Meta" key={`productMeta-${record.uniqueKey}`}>
                <ProductMeta productMeta={record.productMeta} />
              </TabPane>
              <TabPane tab="Timeline" key={`timeline-${record.uniqueKey}`}>

                {isBuyProductLoding ? "Please wait...": buyProductDetailsForTimeLine?.getData() &&   <TimelineTracking
                  productData={buyProductDetailsForTimeLine?.getData()}
                  isLoading={false}
                  title="Timeline"
                />
                }
              </TabPane>
            </Tabs>
          },
          expandIcon: () => null,
        }}
      />
    )
  }
  // ------------------------------------- Child Table Configuration End x ------------------------------------- //

  // ----------------------------------- Parent Table Configuration Start -> ----------------------------------- //

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'edit-order') {
      navigate(`/buy-and-ship-for-me/orders/edit/${selectedOrderId}`)
    }
  }



  const columns: TableColumnsType<BuyOrderDataType> = [
    {
      title: 'Order',
      dataIndex: 'orderId',
      key: 'orderId',
      render: (text) => <Text copyable>{text} </Text>,
    },
    {
      title: "Customer Details",
      key: "customer_details",
      render: (text, record) => (
        <div style={{ minWidth: "150px" }}>
        <Space direction="vertical" size="small">
          <div><strong>Name:</strong> {record.customerName || "N/A"}</div>
          <div><strong>Phone:</strong> {record.customerPhone || "N/A"}</div>
          <div><strong>Handler:</strong> {record.handler || "N/A"}</div>
        </Space>
        </div>
      )
    },
    { title: 'Total Items', dataIndex: 'totalItems', key: 'totalItems', hidden:true },
    {
      title: "Financial Details",
      key: "financial_details",
      render: (text, record) => (
        <div style={{ minWidth: "150px" }}>
        <Space direction="vertical" size="small">
          <div><strong>Quantity:</strong> {record.totalItems || "N/A"}</div>
          <div><strong>Total:</strong> {record.total || 0}</div>
          <div><strong>Due:</strong> {record.due || "N/A"}</div>

        </Space>
        </div>
      )
    },
    { title: 'Total', dataIndex: 'total', key: 'total' , hidden:true },
    { title: 'Due', dataIndex: 'due', key: 'due' , hidden:true},
    { title: 'Created At', hidden:isMobile, dataIndex: 'createdAt', key: 'createdAt',
     render:  (text, record) => (
       <Tag color={"purple"}>
         {text}
       </Tag>
     )
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      fixed: isMobile ? "right" : undefined,
      width: isMobile? 100 :150,
      key: 'operation',
      render: (_: string, record: BuyOrderDataType) =>  {


        const menuItems: MenuProps['items'] = [
          // {
          //   label: "Copy Order",
          //   key: "copy-order",
          //   icon: <CopyOutlined />,
          // },
          {
            label: 'Edit',
            key: 'edit-order',
            icon: <EditOutlined />,
            onClick: handleMenuClick
          },
          // {
          //   label: "Print",
          //   key: "print-order",
          //   icon: <PrinterOutlined />,
          // },
          // {
          //   label: "Re-active",
          //   key: "re-active",
          //   icon: <InteractionOutlined />,
          // },
          // {
          //   label: "Assign Handler",
          //   key: "assign-handler",
          //   icon: <UserSwitchOutlined />,
          // },
        ]

          if(isMobile){
            menuItems.push({
              label: <Tag color={"purple"}>
                {record.createdAt}
              </Tag>,
              key: 'createAt',
              disabled:true
            })
          }

          return(
            <Dropdown
              menu={{
                items: menuItems,
              }}
            placement={'bottom'}
            onOpenChange={() => {
              // setSelectedRow(record);
              setSelectedOrderId(record.key.toString())
            }}
          >
            <Button icon={<HddFilled />}>
              {isMobile ?<DownOutlined />:<> Actions
                <DownOutlined /></>
              }
            </Button>
          </Dropdown>
          )
        }
    },
  ]

  const data: BuyOrderDataType[] = buyOrderCollectionModel
    ? buyOrderCollectionModel.getOrders()
    : []

  // ------------------------------------- Parent Table Configuration End x ------------------------------------ //

  return (
    <div>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={'Buy And Ship Orders'}
        style={{ marginTop: '10px' }}
        extra={[
          <Link key="create-order" to="/buy-and-ship-for-me/orders/create">
            <Button type="primary">Create Order</Button>
          </Link>,
        ]}
      >
        {filters && Object.keys(filters).length > 0 && (
          <Row>
            <Col span={24}>
              <Card title={'Filter'}>
                <FiltersComponent
                  handleProductFilter={handleFilter}
                  handleFilterChange={handleFilterChange}
                  handelFilterClear={handelFilterClear}
                  isFetched={isFetched}
                  filters={filters}
                  filtersData={orderFiltersModel}
                  isFromProductReceived={true}
                />
              </Card>
            </Col>
          </Row>
        )}

        <div style={{ marginTop: '15px' }}>
          <Table
            loading={isLoading}
            columns={columns}
            rowKey="orderId"
            rowSelection={{
              selectedRowKeys,
              onChange: (selectedRowKeys) =>
                setSelectedRowKeys(selectedRowKeys),
            }}
            expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
            style={{ verticalAlign: 'top' }}
            dataSource={data}
            pagination={paginationConfig}
            scroll={{x:1000}}
          />
        </div>
        {isSeletedProduct && isApproveModalOpen && (
          <Modal
            title="Select Buying Agent"
            open={isApproveModalOpen}
            footer={false}
            onCancel={() => {
              setIsApproveModalOpen(false)
              setIsSeletedProduct(undefined)
            }}
            width={isMobile? "90%": '70%'}
          >
            <BuyProductAgentAssignModal
              isApproveModalOpen={isApproveModalOpen}
              selectedRowKeys={selectedRowKeys}
              setIsApproveModalOpen={setIsApproveModalOpen}
              productId={isSeletedProduct}
            />
          </Modal>
        )}

        {isSeletedProduct && (
          <Modal
            open={openReject}
            title="Product Reject"
            onOk={() => handleRejectOk(isSeletedProduct)}
            onCancel={handleRejectCancel}
            footer={[
              <Button key="back" onClick={handleRejectCancel}>
                No
              </Button>,
              <Button
                style={{marginLeft:"5px"}}
                key="submit"
                loading={isRejectionLoading}
                disabled={isRejectionLoading}
                type="primary"
                onClick={() => handleRejectOk(isSeletedProduct)}
              >
                Yes
              </Button>,
            ]}
          >
            Are you sure reject the product?
          </Modal>
        )}

        {changeShippingTypeDetails.productIds &&
          changeShippingTypeDetails.productIds.length && (
            <ChangeShippingTypeModal
              existingType={changeShippingTypeDetails.existingData}
              isOpen={changeShippingTypeDetails.isOpen}
              productIds={changeShippingTypeDetails.productIds}
              onCancel={() =>
                setChangeShippingTypeDetails({
                  isOpen: false,
                  existingData: undefined,
                  productIds: undefined,
                })
              }
            />
          )}
      </PageHeader>
    </div>
  )
}

export default BuyOrders
