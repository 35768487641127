import React, { useEffect } from "react";
import {
  Button,
  Form,
  FormInstance,
  Modal,
  Select,
  message,
} from "antd";
import { useAtom } from "jotai";

import { useGetDestinationWarehouse } from "lib/core-react/hooks/private";
import { useUpdateDestinationWarehouse } from "lib/core-react/hooks/private/useShipping";
import { destinationWarehouseCollectionAtom } from "lib/core-react/store/store";
import { DestinationWarehouseCollectionModel } from "models/destinationWarehouseCollectionModel";
import { getError } from "lib/core-react/hooks/utils/errors";

interface IProps {
  country_id: number;
  productIds: number[];
  isOpen: boolean;
  onCancel: () => void;
  existingWarehouseId: number | undefined;
}

export const ChangeDestinationWarehouseModal = ({
  country_id,
  isOpen,
  onCancel,
  productIds,
  existingWarehouseId,
}: IProps) => {
  const { getDestinationWarehouse } = useGetDestinationWarehouse();
  const {
    updateDestinationWarehouse,
    isError,
    isLoading: updateWarehouseLoading,
  } = useUpdateDestinationWarehouse();
  const [{ data: destinationWarehouseData, isLoading }] = useAtom(
    destinationWarehouseCollectionAtom,
  );
  const formRef: React.Ref<FormInstance<any>> = React.createRef();
  const [form] = Form.useForm();

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [destinationWarehouseData, form]);

  useEffect(() => {
    getDestinationWarehouse(`country_id=${country_id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country_id]);

  const destinationWarehouseObj =
    destinationWarehouseData &&
    new DestinationWarehouseCollectionModel(destinationWarehouseData);

  // Ok handler
  const onOkHandler = async (data: { destinationWarehouse: number }) => {
    const payload: {
      shipment_product_ids: number[];
      destination_warehouse_id: number;
    } = {
      shipment_product_ids: productIds,
      destination_warehouse_id: data.destinationWarehouse,
    };

    try {
      const data = await updateDestinationWarehouse(payload);
      message.success(data.message);
      onCancel();
    } catch (error) {
      message.error(getError(error));
    }
  };

  return (
    <Modal
      title="Change Destination Warehouse"
      open={isOpen}
      destroyOnClose={true}
      confirmLoading={updateWarehouseLoading}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        onFinish={onOkHandler}
        form={form}
        layout="vertical"
        name="update_warehouse"
        ref={formRef}
        initialValues={{
          destinationWarehouse: existingWarehouseId,
        }}
      >
        <Form.Item name="destinationWarehouse" label="Destination Warehouse">
          <Select
            loading={isLoading}
            placeholder="Please select a destination warehouse"
            options={destinationWarehouseObj?.getDataAsLabelValue()}
          />
        </Form.Item>
        <Form.Item>
          <Button
            loading={updateWarehouseLoading}
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
