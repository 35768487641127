
import React from 'react'
import {
    Select
} from 'antd'
import {useSelector} from "react-redux";
const {Option} = Select

const StoreSelect = ({handleStoreSelect}) => {

    const {store, isStoreLoading: fetching} = useSelector((state) => state.store);
    let data = store && store.data

    const handleChange = value => {
        let val = data.filter(item => item.value === value);
        handleStoreSelect(val[0])
    };

    return(
        <Select
            placeholder="Select Store"
            filterOption={false}
            onSelect={handleChange}
            allowClear
            showSearch
            loading={fetching}
            style={{ width: '100%' }}
        >
            {data?.length > 0 && data.map((d,index) => (
                <Option key={index} value={d.value}>{d.name}</Option>
            ))}
        </Select>
    );
}

export default StoreSelect;