import { useNavigate } from "react-router-dom";

import {
  StyledProductGridAction,
  StyledProductGridActionItem,
  StyledProductGridCard,
  StyledProductGridCardHeader,
  StyledProductGridCardHeaderThumb,
  StyledProductGridCardPara,
  StyledProductGridCardTitle,
} from "./index.styled";
import { Checkbox } from "antd";
import { CampaignProductModel } from "models/campaignProductCollectionModel";

type Props = {
  item: CampaignProductModel;
  isChecked: boolean;
  handleCheck: (id: string) => void;
  selectable: boolean;
};

const CampaignGridItem = (props: Props) => {
  const { item, isChecked, handleCheck, selectable } = props;
  const navigate = useNavigate();

  return (
    <StyledProductGridCard
      extra={
        selectable ? (
          <Checkbox
            checked={isChecked}
            onChange={() => handleCheck(item.getId())}
          />
        ) : null
      }
    >
      <StyledProductGridCardHeader onClick={() => handleCheck(item.getId())}>
        <StyledProductGridCardHeaderThumb>
          <img src={item.getProduct().getImage()} alt="product" />
        </StyledProductGridCardHeaderThumb>
      </StyledProductGridCardHeader>

      <StyledProductGridCardTitle
        className="text-truncate"
        // onClick={() => {
        //   navigate("product_detail/" + item.getId());
        // }}
      >
        {item.getProduct().getTitle()}
      </StyledProductGridCardTitle>

      <StyledProductGridCardPara
        className="text-truncate item-hover"
        onClick={() => {
          navigate("product_detail/" + item.getId());
        }}
      >
        {/* {item.getProduct().getDescription()} */}
      </StyledProductGridCardPara>

      <StyledProductGridAction
      // onClick={() => {
      //   navigate("product_detail/" + item.getId());
      // }}
      >
        <StyledProductGridActionItem>
          ৳
          {item.getProduct().getPrice().getHikeMin()
            ? Math.ceil(item.getProduct().getPrice().getHikeMin())
            : Math.ceil(item.getProduct().getPrice().getHikeMax())}
        </StyledProductGridActionItem>
        <StyledProductGridActionItem className="cut">
          ৳{Math.ceil(item.getProduct().getPrice().getHikeMax())}
        </StyledProductGridActionItem>
      </StyledProductGridAction>
    </StyledProductGridCard>
  );
};

export default CampaignGridItem;
