import { ResponsePromise } from "lib/core/request/types/typings";
import { BaseResource } from "../public";
import { IUpdateStatus } from "types/transactionCollection";

class TransactionsResource extends BaseResource {
  paymentPath = `/api/payment/admin/transaction/v1`;

  get(
    params?: string,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.paymentPath}/transactions/?expand=attachments,gateway,association${
      params ? `&${params}` : ""
    }`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  getDetail(
    transaction_id: number,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.paymentPath}/transactions/${transaction_id}?expand=attachments,gateway,association`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  updateSatatus(
    id: number,
    payload: IUpdateStatus,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.paymentPath}/transactions/${id}/status`;
    return this.client.request("PUT", path, payload, {}, customHeaders);
  }
}

export default TransactionsResource;
