import { Form, Button, message, Alert } from "antd";
import {
  useDeleteExchangeCustomer,
  useGetExchangeGroupCustomers,
} from "lib/core-react/hooks/private/useExchangeGroup";
import { CustomerUserModel } from "models/customerUserCollectionModel";
import { ExchangeGroupModel } from "models/exchangeGroupCollectionModel";
import { useState, useEffect } from "react";

interface IProps {
  setIsShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCustomerUser: CustomerUserModel;
  data: ExchangeGroupModel;
}

const DeleteExchangeGroupCustomer = ({
  setIsShowDeleteModal,
  selectedCustomerUser,
  data,
}: IProps) => {
  const { getExchangeGroupCustomers } = useGetExchangeGroupCustomers(data.id);
  const { deleteExchangeGroupCustomer, isLoading, isError } =
    useDeleteExchangeCustomer();

  const [isErrorVisible, setIsErrorVisible] = useState(false);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const onConfirmDelete = async () => {
    try {
      await deleteExchangeGroupCustomer(selectedCustomerUser.getId());
      await getExchangeGroupCustomers();
      setIsShowDeleteModal(false);
      setIsErrorVisible(false);
      message.success({
        content: "Exchange group customer deleted successfully.",
        key: "deleteExchangeGroupCustomer",
        duration: 2,
      });
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  return (
    <>
      <Form layout="vertical">
        <Form.Item>
          <h5>
            Are you sure you want to delete this customer from this exchange
            group?
          </h5>
        </Form.Item>

        <Form.Item>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              style={{ minWidth: "100px" }}
              disabled={isLoading}
              onClick={() => setIsShowDeleteModal(false)}
            >
              No
            </Button>
            <Button
              style={{ marginLeft: "10px", minWidth: "100px" }}
              type="primary"
              loading={isLoading}
              onClick={onConfirmDelete}
            >
              Yes
            </Button>
          </div>
        </Form.Item>
      </Form>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isError}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </>
  );
};

export default DeleteExchangeGroupCustomer;
