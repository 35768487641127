import {Configuration, FrontendApi} from "@ory/client";
import {ServiceBaseUrl, ServiceNameEnum} from "lib/core/apiServices/servicBaseUrl";

const basePath = ServiceBaseUrl.getServiceBaseUrl(ServiceNameEnum.AUTH_SERVICE)
const ory = new FrontendApi(
  new Configuration({
    basePath,
    baseOptions: {
      withCredentials: true,
    },
  }),
)

export default ory
