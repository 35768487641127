import React from 'react'
import PropTypes from 'prop-types'
import {Modal, Button, Space} from 'antd'

const ConfirmationModal = props => {
    const {title, message, visible, onCancel, onOk, isLoading} = props

    const handleOk = () => {
        onOk()
        onCancel()
    }

    return (
        (<Modal
            title={title}
            open={visible}
            closable={false}
            footer={null}
        >
            <p>{message}</p>
            <Space>
                <Button onClick={onCancel} disabled={isLoading}>Cancel</Button>
                <Button type="primary" onClick={handleOk} disabled={isLoading} loading={isLoading}>Submit</Button>
            </Space>
        </Modal>)
    );
}

ConfirmationModal.prototype = {
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onOk: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
}

export default ConfirmationModal
