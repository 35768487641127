import { BaseResource } from "../public";
import { ResponsePromise } from "lib/core/request/types/typings";
import {
  CustomerUserPostRequestModel,
  CustomerUserUpdateRequestModel,
} from "models/customerUserCollectionModel";

class CustomerUserResource extends BaseResource {
  path = `/api/user/admin/customer-user/v1/customer-users`;

  get(
    params?: string,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    let path = `${this.path}`;
    if (params) {
      path = `${path}/?${params}`;
    }
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  create(
    payload: CustomerUserPostRequestModel,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.path}/`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }

  update(
    id: number,
    payload: CustomerUserUpdateRequestModel,
    customHeaders: Record<string, any> = {}
  ): ResponsePromise {
    const path = `${this.path}/${id}`;
    return this.client.request("PUT", path, payload, {}, customHeaders);
  }
}

export default CustomerUserResource;
