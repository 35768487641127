import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PageHeader } from "@ant-design/pro-layout";
import {
  Alert,
  Button,
  Card,
  Form,
  InputNumber,
  Row,
  Select,
  Space,
  Typography,
  message,
} from "antd";
import { DeleteTwoTone, PlusOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { shippingCommissionAtom } from "lib/core-react/store/store";
import { renderOptionsFromEnumReversed } from "components/Form/forms";
import {
  CategoryCommissionCollectionModel,
  CommissionAssociationCollectionModel,
  CommissionCollectionModel,
} from "models/commissionCollectionModel";
import {
  RateTypeEnums,
  ShippingModeEnums,
  ShippingTypeEnums,
  UnitTypeEnums,
} from "enums/shippingCoreEnums";
import { ICategoryCommissionAssociationsPutRequest } from "types/commissionCollection";
import {
  useGetCommission,
  useUpdateCommissionCategory,
} from "lib/core-react/hooks/private/useCommission";
import { transformEnumToLabeledValue } from "utils/helpers";

const UpdateCommissionCategory = () => {
  const { regionId, categoryId, commissionAssociationId, commissionId } =
    useParams();

  const {
    updateCommissionCategory,
    isLoading: updateLoading,
    isError,
  } = useUpdateCommissionCategory();
  const { getCommission } = useGetCommission();
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [form] = Form.useForm();
  const [{ data: commissionStoreData, isLoading }] = useAtom(
    shippingCommissionAtom,
  );

  const CommissionData =
    commissionStoreData &&
    new CommissionCollectionModel(commissionStoreData)?.getCommissionById(
      Number(commissionId),
    );
  const CommissionAssociationsData =
    CommissionData?.getCommissionAssociations() &&
    new CommissionAssociationCollectionModel(
      CommissionData?.getCommissionAssociations(),
    )?.getCommissionAssociationById(Number(commissionAssociationId));
  const CommissionCategoryData =
    CommissionAssociationsData?.getCategoryCommissionAssociations() &&
    new CategoryCommissionCollectionModel(
      CommissionAssociationsData?.getCategoryCommissionAssociations(),
    )?.getCategoryCommissionById(Number(categoryId));

  useEffect(() => {
    getCommission(`region_id=${regionId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [form]);

  //hide error message after 5 sec
  useEffect(() => {
    if (isErrorVisible) {
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 5000);
    }
  }, [isErrorVisible]);

  const onFinish = async (value: ICategoryCommissionAssociationsPutRequest) => {
    try {
      // Check if at least one slot is present
      if (!ensureSlotPresent(value)) {
        message.error(
          "Please ensure at least one slot is present for each commission rate.",
        );
        return;
      }

      const commission_rates = value.commission_rates.map((item) => ({
        shipping_type: item.shipping_type,
        shipping_mode: item.shipping_mode,
        unit_type: item.unit_type,
        rate: item.rate,
        rate_type: item.rate_type,
        max_cap: item.max_cap,
        slots: item.slots.data.map((slot) => ({
          min_amount: slot.min_amount,
          max_amount: slot.max_amount,
          rate: slot.rate,
          rate_type: slot.rate_type,
          max_cap: slot.max_cap,
        })),
      }));

      await updateCommissionCategory(categoryId!, {
        commission_rates: commission_rates as any[],
      });
      message.success("Category commission updated successfully.");
      setIsErrorVisible(false);
    } catch (error) {
      setIsErrorVisible(true);
    }
  };

  const ensureSlotPresent = (
    value: ICategoryCommissionAssociationsPutRequest,
  ): boolean => {
    for (const item of value.commission_rates) {
      if (!item.slots || !item.slots.data || item.slots.data.length === 0) {
        return false;
      }
    }
    return true;
  };

  return (
    <PageHeader
      style={{ marginTop: "10px" }}
      ghost={false}
      title="Update Commission Category"
      onBack={() => window.history.back()}
    >
      <Form
        layout="vertical"
        form={form}
        name="create-commission-form"
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          commission_rates:
            CommissionCategoryData?.getCommissionRates().getData(),
        }}
      >
        {/* Commission Rate */}
        <Card>
          <Form.List name={"commission_rates"}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Space
                    key={key}
                    direction="vertical"
                    size="middle"
                    style={{
                      display: "flex",
                      margin: "0 10px",
                    }}
                  >
                    <Row
                      justify="space-between"
                      style={{
                        padding: 5,
                        borderLeft: "2px solid hotpink",
                      }}
                    >
                      <Typography.Text mark>
                        Commission Rate #{index + 1}
                      </Typography.Text>
                      {index > 0 && (
                        <DeleteTwoTone
                          twoToneColor="hotpink"
                          onClick={() => remove(name)}
                        />
                      )}
                    </Row>
                    <Form.Item
                      {...restField}
                      rules={[
                        {
                          required: true,
                          message: "Shipping type is required",
                        },
                      ]}
                      required
                      name={[name, "shipping_type"]}
                      label="Shipping Type"
                    >
                      <Select
                        placeholder="Please select a shipping type"
                        options={transformEnumToLabeledValue(ShippingTypeEnums)}
                      />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      rules={[
                        {
                          required: true,
                          message: "Shipping mode is required",
                        },
                      ]}
                      required
                      name={[name, "shipping_mode"]}
                      label="Shipping Mode"
                    >
                      <Select
                        placeholder="Please select a shipping mode"
                        options={transformEnumToLabeledValue(ShippingModeEnums)}
                      />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      rules={[
                        {
                          required: true,
                          message: "Unit type is required",
                        },
                      ]}
                      required
                      name={[name, "unit_type"]}
                      label="Unit Type"
                    >
                      <Select
                        placeholder="Please select a unit type"
                        options={transformEnumToLabeledValue(UnitTypeEnums)}
                      />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      rules={[
                        {
                          required: true,
                          message: "Rate type is required",
                        },
                      ]}
                      required
                      name={[name, "rate_type"]}
                      label="Rate Type"
                    >
                      <Select
                        placeholder="Please select a rate type"
                        options={transformEnumToLabeledValue(RateTypeEnums)}
                      />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      rules={[
                        {
                          required: true,
                          message: "Rate is required",
                        },
                      ]}
                      required
                      name={[name, "rate"]}
                      label="Rate"
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder="Rate"
                      />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, "max_cap"]}
                      label="Max Cap"
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder="Max Cap"
                      />
                    </Form.Item>

                    {/* Slot */}
                    <Card>
                      <Form.List name={[name, "slots", "data"]} key={key}>
                        {(slotFields, { add, remove }) => (
                          <>
                            {slotFields.map(
                              (
                                { key: slotKey, name: slotName, ...restField },
                                slotIndex,
                              ) => (
                                <Space
                                  key={slotKey}
                                  direction="vertical"
                                  size="middle"
                                  style={{
                                    display: "flex",
                                    margin: "0 10px",
                                  }}
                                >
                                  <Row
                                    justify="space-between"
                                    style={{
                                      padding: 5,
                                      borderLeft: "2px solid hotpink",
                                    }}
                                  >
                                    <Typography.Text mark>
                                      Slot #{slotIndex + 1}
                                    </Typography.Text>
                                    {slotIndex > 0 && (
                                      <DeleteTwoTone
                                        twoToneColor="hotpink"
                                        onClick={() => remove(slotName)}
                                      />
                                    )}
                                  </Row>
                                  <Form.Item
                                    {...restField}
                                    label="Minimum Amount"
                                    name={[slotName, "min_amount"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Minimum amount is required",
                                      },
                                    ]}
                                    required
                                  >
                                    <InputNumber
                                      style={{ width: "100%" }}
                                      placeholder="Minimum amount"
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    label="Maximum amount"
                                    name={[slotName, "max_amount"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Maximum amount is required",
                                      },
                                    ]}
                                    required
                                  >
                                    <InputNumber
                                      style={{ width: "100%" }}
                                      placeholder="Maximum amount"
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        message: "Rate type is required",
                                      },
                                    ]}
                                    required
                                    name={[slotName, "rate_type"]}
                                    label="Rate Type"
                                  >
                                    <Select placeholder="Please select a rate type">
                                      {renderOptionsFromEnumReversed(
                                        RateTypeEnums,
                                      )}
                                    </Select>
                                  </Form.Item>

                                  <Form.Item
                                    rules={[
                                      {
                                        required: true,
                                        message: "Rate is required",
                                      },
                                    ]}
                                    required
                                    name={[slotName, "rate"]}
                                    label="Rate"
                                  >
                                    <InputNumber
                                      style={{ width: "100%" }}
                                      placeholder="Rate"
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    name={[slotName, "max_cap"]}
                                    label="Max Cap"
                                  >
                                    <InputNumber
                                      style={{ width: "100%" }}
                                      placeholder="Max Cap"
                                    />
                                  </Form.Item>
                                </Space>
                              ),
                            )}

                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => {
                                  form
                                    .validateFields()
                                    .then(() => add())
                                    .catch((error) => {
                                      console.error("Validation Error:", error);
                                    });
                                }}
                                block
                                icon={<PlusOutlined />}
                              >
                                Add slot
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </Card>
                  </Space>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      form
                        .validateFields()
                        .then(() => add())
                        .catch((error) => {
                          console.error("Validation Error:", error);
                        });
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add commission rate
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Card>

        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            loading={isLoading || updateLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>

      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isError}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )}
    </PageHeader>
  );
};

export default UpdateCommissionCategory;
