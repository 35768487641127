import React, { useEffect } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import {
  Row,
  Col,
  Button,
  Modal,
  Table,
  Card,
  Divider,
  Descriptions,
  Switch,
  Skeleton,
  Tooltip,
  message,
  Tag,
} from "antd";
import {
  CloseOutlined,
  PlusOutlined,
  EditOutlined,
  EyeFilled,
} from "@ant-design/icons";
import { useState } from "react";
import { useCreateOrder } from "context/create-order-context";
import { FALLBACK_IMAGE_URL } from "consts/app-constants";
import ImageGallery from "components/ImageGallery";
import "./ManualBuyShipOrder.css";
import { getCustomerAddresses } from "utils/customer-client";
import * as helpers from "utils/helpers";
import ProductItem from "./../ProductItem";
import CustomerDrawer from "../../../CustomerManage/Customers/components/CustomerDrawer";
import AddressDrawer from "../../../CustomerManage/Customers/components/AddressDrawer";
import InvoiceDetailDrawer from "../../../BuyingAgentInvoice/components/InvoiceDetailDrawer";
import { ClientSelect, CustomerSelect } from "components";
// import { Prompt } from "react-router-dom";

const { Column } = Table;

const ManualBuyShipOrder = () => {
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [addressType, setAddressType] = useState(null);
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const [isCustomerEdit, setIsCustomerEdit] = useState(false);
  const [isOAuthClientEdit, setIsOAuthClientEdit] = useState(false);
  const [showCustomerDrawer, setShowCustomerDrawer] = useState(false);
  const [showAddressDrawer, setShowAddressDrawer] = useState(false);
  const [showInvoiceDrawer, setShowInvoiceDrawer] = useState(false);
  const [isRemoveMeta, setIsRemoveMeta] = useState(false);
  const [selectedDeleteMeta, setSelectedDeleteMeta] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [isLeaving, setIsLeaving] = useState(true);

  window.addEventListener("beforeunload", (ev) => {
    ev.preventDefault();
    return (ev.returnValue = "Are you sure you want to close?");
  });

  const {
    productList,
    orderInfo,
    currentProduct,
    setCurrentProduct,
    setSelectedVariationKeys,
    setVariations,
    saveLoading,
    address,
    setAddress,
    selectedCustomer,
    setSelectedCustomer,
    selectedOAuthClient,
    setSelectedOAuthClient,
    handleOrderSave,
    isLoading,
    handleRemoveFromOder,
    setCategory,
    setAwCategory,
    setStore,
    handleProductUpdate,
    clearProductData,
    fetchStoreList,
  } = useCreateOrder();

  useEffect(() => {
    fetchStoreList(selectedCustomer, selectedOAuthClient);
  }, [selectedCustomer, selectedOAuthClient]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSave = () => {
    setIsLeaving(false);
    if (productList.length === 0) {
      message.error("Empty Product list");
      return;
    }

    if (!selectedCustomer) {
      message.error("Please select a customer first");
      return;
    }
    if (!address.shipping) {
      message.error("Please select shipping address");
      return;
    }

    if (!address.billing) {
      message.error("Please select billing address");
      return;
    }

    handleOrderSave();
  };

  const handleAddItem = () => {
    clearProductData();
    setShowAddItemModal(true);
  };

  const handleCustomerSelect = (customer) => {
    setSelectedCustomer(customer);
    setIsCustomerEdit(false);
  };

  const handleClientSelect = (client) => {
    setSelectedOAuthClient(client);
    setIsOAuthClientEdit(false);
  };

  const handleAddressSelect = (type, selectedAddress) => {
    let updatedAddress = address;
    if (type === "shipping") {
      updatedAddress.shipping = selectedAddress;
    } else if (type === "billing") {
      updatedAddress.billing = selectedAddress;
    } else if (type === "delivery") {
      updatedAddress.delivery = selectedAddress;
    }
    setAddress(updatedAddress);
  };

  const getProductImage = (row) => {
    return [
      {
        imageURL: row.image ? row.image : FALLBACK_IMAGE_URL,
        thumbURL: row.image ? row.image : FALLBACK_IMAGE_URL,
      },
    ];
  };
  const getMetaItems = (row) => {
    let metas = [];
    row?.metaItems?.forEach((metaItem) => {
      metaItem.meta.forEach((item, index) => {
        metas.push({
          title: item.title,
          value: item.value,
        });
      });
    });
    return metas;
  };

  const getExtras = (row) => {
    return [
      {
        key: "Store",
        value: row.store?.name,
      },
      {
        key: "Category",
        value: row.category && row.category.name ? row.category.name : "N/A",
      },
    ];
  };

  const handleProductEdit = (index, product) => {
    product.index = index;
    product.isEdit = true;
    setCurrentProduct(product);
    if (product.agentCategory) {
      setAwCategory(product.agentCategory);
    }
    if (product.category) {
      setCategory(product.category);
    }
    if (product.store) {
      setStore(product.store);
    }
    let metaItems = [];
    product.metaItems.forEach((item) => {
      let baseTotal = (100 * item.price) / (100 + product.mv_comm);
      metaItems.push({ ...item, price: baseTotal });
    });
    setVariations([...metaItems]);
    setSelectedVariationKeys(
      product.metaItems.map(
        (item) => `${item.sku_id}${item.key?.replace(",", "")}`,
      ),
    );
    setShowEditDrawer(true);
  };
  const handleShowAddressModal = (type) => {
    if (!selectedCustomer) {
      message.error("Please select a customer first");
      return;
    }
    setAddressType(type);
    setShowAddressModal(true);
  };

  const getProductPrice = (row) => {
    let total = 0;
    let store = row.store;
    row.metaItems.forEach((item) => {
      let price = item.price * store.fx;
      total += item.qty * price;
    });
    return total.toFixed(2);
  };

  const handleRemoveMetaProduct = (value) => {
    setIsRemoveMeta(true);
    setSelectedDeleteMeta(value);
  };

  const handleCancel = () => {
    setIsRemoveMeta(false);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      handleRemoveFromOder(selectedDeleteMeta);
      setIsRemoveMeta(false);
      setConfirmLoading(false);
    }, 2000);
  };

  return (
    <>
      {/* <Prompt when={isLeaving} message="Are you sure you want to leave?" /> */}
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={
          orderInfo ? `Edit order ${orderInfo.order_number}` : "Create order"
        }
        extra={[
          <div key={"order-action-button"}>
            <Button
              key={"manual-order-button-add-item-2"}
              onClick={handleAddItem}
              style={{ marginTop: 20 }}
              danger
              size={"large"}
              icon={<PlusOutlined />}
              disabled={isLoading}
            >
              Add Item
            </Button>
            <Button
              key={"manual-order-button-save-1"}
              type="primary"
              size={"large"}
              loading={saveLoading}
              onClick={onSave}
              disabled={isLoading}
            >
              Save
            </Button>
          </div>,
        ]}
      >
        <Row className="order-form-wrapper">
          <Col xxl={8} xl={8} lg={24} md={24} sm={24}>
            <Card style={{ minHeight: 220 }}>
              {isLoading ? (
                <Skeleton active paragraph={{ rows: 1 }} />
              ) : (
                <Descriptions
                  column={1}
                  title={
                    <>
                      <div className="ant-descriptions-title">
                        <span style={{ marginRight: 10 }}>Customer</span>
                        <Switch
                          checkedChildren="View"
                          unCheckedChildren="Edit"
                          checked={isCustomerEdit}
                          onChange={(checked, event) => {
                            setIsCustomerEdit(checked);
                          }}
                        />
                      </div>
                    </>
                  }
                >
                  {!isCustomerEdit ? (
                    <>
                      {selectedCustomer && selectedCustomer.name ? (
                        <Descriptions.Item
                          label={
                            <span style={{ fontWeight: "bold" }}>Name</span>
                          }
                        >
                          {selectedCustomer.name}
                        </Descriptions.Item>
                      ) : null}
                      {selectedCustomer && selectedCustomer.phone ? (
                        <Descriptions.Item
                          label={
                            <span style={{ fontWeight: "bold" }}>Phone</span>
                          }
                        >
                          {selectedCustomer.phone}
                        </Descriptions.Item>
                      ) : null}
                      {selectedCustomer && selectedCustomer.email ? (
                        <Descriptions.Item
                          label={
                            <span style={{ fontWeight: "bold" }}>Email</span>
                          }
                        >
                          {selectedCustomer.email}
                        </Descriptions.Item>
                      ) : null}
                      {selectedCustomer && selectedCustomer.shipping_mark ? (
                        <Descriptions.Item
                          label={
                            <span style={{ fontWeight: "bold" }}>
                              Shipping Mark
                            </span>
                          }
                        >
                          {selectedCustomer.shipping_mark}
                        </Descriptions.Item>
                      ) : null}
                      {selectedCustomer && (
                        <Descriptions.Item span={3}>
                          <Button
                            onClick={() => setShowAddressDrawer(true)}
                            icon={<PlusOutlined />}
                            type="primary"
                          >
                            Add a address
                          </Button>
                        </Descriptions.Item>
                      )}
                    </>
                  ) : (
                    <Descriptions.Item>
                      <div style={{ width: "100%" }}>
                        <CustomerSelect
                          handleCustomerSelect={handleCustomerSelect}
                        />
                      </div>
                      <Button
                        onClick={() => {
                          setShowCustomerDrawer(true);
                        }}
                        style={{ marginTop: 20, marginLeft: 10 }}
                        icon={<PlusOutlined />}
                        type="primary"
                      >
                        Create
                      </Button>
                    </Descriptions.Item>
                  )}
                </Descriptions>
              )}
            </Card>
          </Col>
          <Col xxl={16} xl={16} lg={24} md={24} sm={24}>
            <Card style={{ minHeight: 260 }}>
              {selectedCustomer && selectedCustomer.is_drop_user && (
                <>
                  {isLoading ? (
                    <Skeleton active paragraph={{ rows: 1 }} />
                  ) : (
                    <Descriptions
                      title={
                        <>
                          <div className="ant-descriptions-title">
                            <span style={{ marginRight: 10 }}>
                              Drop Website
                            </span>
                            <Switch
                              checkedChildren="View"
                              unCheckedChildren="Edit"
                              checked={isOAuthClientEdit}
                              onChange={(checked, event) => {
                                setIsOAuthClientEdit(checked);
                              }}
                            />
                          </div>
                        </>
                      }
                      column={1}
                    >
                      {!isOAuthClientEdit ? (
                        <>
                          {selectedOAuthClient && selectedOAuthClient.name ? (
                            <Descriptions.Item
                              label={
                                <span style={{ fontWeight: "bold" }}>
                                  Website Name
                                </span>
                              }
                            >
                              {selectedOAuthClient.name}
                            </Descriptions.Item>
                          ) : null}
                          {selectedOAuthClient &&
                          selectedOAuthClient.redirect ? (
                            <Descriptions.Item
                              label={
                                <span style={{ fontWeight: "bold" }}>
                                  Website Url
                                </span>
                              }
                            >
                              {selectedOAuthClient.redirect}
                            </Descriptions.Item>
                          ) : null}
                        </>
                      ) : (
                        <Descriptions.Item>
                          <div style={{ width: "100%" }}>
                            <ClientSelect
                              handleClientSelect={handleClientSelect}
                              customer={selectedCustomer}
                            />
                          </div>
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                  )}
                </>
              )}
            </Card>
          </Col>
        </Row>
        <Row className="order-form-wrapper">
          <Col xxl={24} xl={24} lg={24} md={24} sm={24}>
            <Card style={{ minHeight: 222 }}>
              {isLoading ? (
                <Skeleton active paragraph={{ rows: 3 }} />
              ) : (
                <Descriptions title="Order Info">
                  {orderInfo ? (
                    <>
                      <Descriptions.Item
                        label={
                          <span style={{ fontWeight: "bold" }}>
                            Order Number
                          </span>
                        }
                      >
                        {orderInfo.order_number}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={<span style={{ fontWeight: "bold" }}>Type</span>}
                      >
                        {orderInfo.type}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          <span style={{ fontWeight: "bold" }}>Created at</span>
                        }
                      >
                        {helpers.formatDateTime(orderInfo.created_at)}
                      </Descriptions.Item>
                      {orderInfo.total_amount !== undefined && (
                        <Descriptions.Item
                          label={
                            <span style={{ fontWeight: "bold" }}>
                              Total Amount
                            </span>
                          }
                        >
                          BDT {orderInfo.total_amount}
                        </Descriptions.Item>
                      )}
                      {orderInfo.paid_amount !== undefined && (
                        <Descriptions.Item
                          label={
                            <span style={{ fontWeight: "bold" }}>
                              Paid Amount
                            </span>
                          }
                        >
                          BDT {orderInfo.paid_amount}
                        </Descriptions.Item>
                      )}
                      {orderInfo.payment_status && (
                        <Descriptions.Item
                          label={
                            <span style={{ fontWeight: "bold" }}>
                              Payment Status
                            </span>
                          }
                        >
                          <Tag color="#2db7f5">{orderInfo.payment_status}</Tag>
                        </Descriptions.Item>
                      )}
                      {orderInfo.invoices && orderInfo.invoices.length > 0 && (
                        <Descriptions.Item>
                          <Button
                            onClick={() => {
                              setShowInvoiceDrawer(true);
                            }}
                            icon={<EyeFilled />}
                            type="secondary"
                          >
                            Show Invoice
                          </Button>
                        </Descriptions.Item>
                      )}
                    </>
                  ) : (
                    <p>Order is not created yet</p>
                  )}
                </Descriptions>
              )}
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col span={24} className="address-view-wrapper">
            <Card>
              {isLoading ? (
                <Skeleton active paragraph={{ rows: 3 }} />
              ) : (
                <Row className={"address-view"}>
                  <Col span={11}>
                    <Descriptions
                      title={
                        <>
                          <div className="ant-descriptions-title">
                            <span style={{ marginRight: 10 }}>
                              Billing Address
                            </span>
                            <Button
                              onClick={() => handleShowAddressModal("billing")}
                              type="dashed"
                              size={64}
                              icon={<EditOutlined />}
                            >
                              Edit
                            </Button>
                          </div>
                        </>
                      }
                    >
                      {address.billing && (
                        <>
                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>Name</span>
                            }
                          >
                            {address?.billing?.name}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>Phone</span>
                            }
                          >
                            {address?.billing?.phone}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>
                                Country
                              </span>
                            }
                          >
                            {address?.billing?.country}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>
                                District
                              </span>
                            }
                          >
                            {address?.billing?.district?.name}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>Area</span>
                            }
                          >
                            {address?.billing?.area?.name}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>
                                Address
                              </span>
                            }
                          >
                            {address?.billing?.address}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>
                                Postal Code
                              </span>
                            }
                          >
                            {address?.billing?.postal_code}
                          </Descriptions.Item>
                        </>
                      )}
                    </Descriptions>
                  </Col>
                  <Divider type={"vertical"} />
                  <Col span={11}>
                    <Descriptions
                      title={
                        <>
                          <div className="ant-descriptions-title">
                            <span style={{ marginRight: 10 }}>
                              Shipping Address
                            </span>
                            <Button
                              onClick={() => handleShowAddressModal("shipping")}
                              type="dashed"
                              size={64}
                              icon={<EditOutlined />}
                            >
                              Edit
                            </Button>
                          </div>
                        </>
                      }
                    >
                      {address.shipping && (
                        <>
                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>Name</span>
                            }
                          >
                            {address?.shipping?.name}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>Phone</span>
                            }
                          >
                            {address?.shipping?.phone}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>
                                Country
                              </span>
                            }
                          >
                            {address?.shipping?.country}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>
                                District
                              </span>
                            }
                          >
                            {address?.shipping?.district?.name}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>Area</span>
                            }
                          >
                            {address?.shipping?.area?.name}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>
                                Address
                              </span>
                            }
                          >
                            {address?.shipping?.address}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={
                              <span style={{ fontWeight: "bold" }}>
                                Postal Code
                              </span>
                            }
                          >
                            {address?.shipping?.postal_code}
                          </Descriptions.Item>
                        </>
                      )}
                    </Descriptions>
                  </Col>
                </Row>
              )}
            </Card>
          </Col>
        </Row>

        <Row className={"products-list-wrapper"}>
          <Col span={24}>
            <Table
              dataSource={productList}
              pagination={false}
              rowKey="id"
              loading={isLoading}
            >
              <Column
                title="Product and Image"
                dataIndex="index"
                key="product"
                width={"50%"}
                render={(index, row) => (
                  <Row>
                    <Col span={4}>
                      <div className={"product-image-wrapper"}>
                        <ImageGallery
                          images={getProductImage(row)}
                          style={{
                            image: {
                              width: 100,
                              height: 100,
                            },
                          }}
                        />
                      </div>
                    </Col>
                    <Col span={20}>
                      <div className="product-title-wrapper">
                        <div className={"title"}>
                          <span>Title: &nbsp;</span>
                          {row.title}
                        </div>
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <div className={"link"}>
                          <span>Link: &nbsp;</span>
                          <a target={"_blank"} href={row.link} rel="noreferrer">
                            {row.link}
                          </a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              />

              <Column
                title="Meta"
                dataIndex="index"
                key="index"
                width={"15%"}
                render={(index, row) => (
                  <div className={"product-metas"}>
                    {getMetaItems(row).map((meta, index) => {
                      return (
                        <div
                          key={"manual-order-meta-item-" + index}
                          className={"meta-item"}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {meta.title}
                          </span>
                          : &nbsp; {meta.value}
                        </div>
                      );
                    })}
                  </div>
                )}
              />

              <Column
                title="Product Price"
                dataIndex="index"
                key="index"
                align={"center"}
                width={"10%"}
                render={(index, row) => <span>BDT {getProductPrice(row)}</span>}
              />
              <Column
                title="Extra"
                dataIndex="index"
                key="index"
                width={"15%"}
                render={(index, row) => (
                  <div className={"product-extras"}>
                    {getExtras(row).map((meta, index) => {
                      return (
                        <div
                          key={"manual-order-extra-item-" + index}
                          className="extra-item"
                        >
                          <span>{meta.key}</span>: &nbsp;{meta.value}
                        </div>
                      );
                    })}
                  </div>
                )}
              />

              <Column
                title="Actions"
                dataIndex="id"
                key="products"
                align={"center"}
                width={"20%"}
                render={(id, row, index) => (
                  <div>
                    <Button
                      onClick={() => handleProductEdit(index, row)}
                      type="primary"
                      size={64}
                      icon={<EditOutlined />}
                      style={{ marginRight: 5 }}
                    >
                      Edit
                    </Button>
                    <Tooltip title="Remove this product">
                      <Button
                        // onClick={()=>handleRemoveFromOder(index)}
                        onClick={() => handleRemoveMetaProduct(index)}
                        type="dashed"
                        size={64}
                        icon={<CloseOutlined />}
                      />
                    </Tooltip>
                  </div>
                )}
              />
            </Table>
            <Button
              key={"manual-order-button-add-item-2"}
              style={{ marginTop: 20 }}
              type="primary"
              onClick={handleAddItem}
              size={"large"}
              icon={<PlusOutlined />}
              disabled={isLoading}
            >
              Add Item
            </Button>
          </Col>
        </Row>
      </PageHeader>
      {showAddressModal && (
        <AddressSelect
          show={showAddressModal}
          customer={selectedCustomer}
          onHide={() => setShowAddressModal(false)}
          type={addressType}
          handleAddressSelect={handleAddressSelect}
        />
      )}
      {
        <ProductItem
          show={showAddItemModal}
          onHide={() => {
            setShowAddItemModal(false);
          }}
          onUpdate={handleProductUpdate}
          isEdit={false}
        />
      }
      {currentProduct && (
        <ProductItem
          show={showEditDrawer}
          onHide={() => setShowEditDrawer(false)}
          onUpdate={handleProductUpdate}
          isEdit={true}
        />
      )}
      {showCustomerDrawer && (
        <CustomerDrawer
          show={showCustomerDrawer}
          onHide={() => setShowCustomerDrawer(false)}
          drawerAction={"create"}
          updateCustomer={handleCustomerSelect}
        />
      )}
      {showAddressDrawer && selectedCustomer && (
        <AddressDrawer
          show={showAddressDrawer}
          onHide={() => setShowAddressDrawer(false)}
          customer={selectedCustomer}
        />
      )}
      {showInvoiceDrawer &&
        orderInfo &&
        orderInfo.invoices &&
        orderInfo.invoices.length > 0 && (
          <InvoiceDetailDrawer
            show={showInvoiceDrawer}
            onHide={() => setShowInvoiceDrawer(false)}
            invoiceId={orderInfo.invoices[0].id}
          />
        )}
      <Modal
        title="Product Remove"
        open={isRemoveMeta}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
      >
        <p>Are you sure, you want to delete this product</p>
      </Modal>
    </>
  );
};

const AddressSelect = (Props) => {
  const [addresses, setAddresses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [customerId, setCustomerId] = useState(null);

  useEffect(() => {
    if (Props.customer && Props.customer.id) {
      if (customerId && customerId === Props.customer.id) {
        return;
      }

      setIsLoading(true);
      getCustomerAddresses(Props.customer.id)
        .then((res) => {
          setAddresses(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally((x) => {
          setIsLoading(false);
        });
      setCustomerId(Props.customer.id);
    }
    setCurrentAddress(null);
  }, [Props, customerId]);

  return (
    <Modal
      title={`Select ${Props.type} address`}
      open={Props.show}
      onCancel={() => {
        Props.onHide();
        setCurrentAddress(null);
      }}
      onOk={() => {
        Props.handleAddressSelect(Props.type, currentAddress);
        setCurrentAddress(null);
        Props.onHide();
      }}
      width={"60vw"}
    >
      <Row className={"customer-addresses"}>
        {isLoading ? (
          <Skeleton active paragraph={{ rows: 10 }} />
        ) : (
          addresses &&
          addresses.length > 0 &&
          addresses.map((address, index) => {
            return (
              <Col
                onClick={() => {
                  setCurrentAddress(address);
                }}
                xxl={8}
                xl={8}
                lg={12}
                md={24}
                sm={24}
                key={`address-item-${index}`}
              >
                <Card
                  className={
                    currentAddress && address.id === currentAddress.id
                      ? "address-section selected"
                      : "address-section"
                  }
                >
                  <div className="address-item">
                    <span>Name:</span>
                    {address.name}
                  </div>
                  <div className="address-item">
                    <span>Phone:</span>
                    {address.phone}
                  </div>
                  <div className="address-item">
                    <span>Country:</span>
                    {address.country}
                  </div>
                  {address.district && address.district.name && (
                    <div className="address-item">
                      <span>District:</span>
                      {address.district?.name}
                    </div>
                  )}
                  {address.area && address.area.name && (
                    <div className="address-item">
                      <span>Area:</span>
                      {address.area?.name}
                    </div>
                  )}
                  {
                    <div className="address-item">
                      <span>Address:</span>
                      {address.address}
                    </div>
                  }
                  {
                    <div className="address-item">
                      <span>Postal Code:</span>
                      {address.postal_code}
                    </div>
                  }
                </Card>
              </Col>
            );
          })
        )}
      </Row>
    </Modal>
  );
};

export default ManualBuyShipOrder;
