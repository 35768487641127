import React from "react";
import { Link } from "react-router-dom";
import type { TableColumnsType } from "antd";
import { Table, Tag } from "antd";
import { Button, Card, Col, Row, Typography } from "antd";
import { useEffect } from "react";
import { useAtomValue } from "jotai";
import { PageHeader } from "@ant-design/pro-layout";

import { formatDateTime } from "utils/helpers";
import useDataFilters from "hooks/useDataFilters";
import { useGetShipmentOrder } from "lib/core-react/hooks/private/useShipping";
import { shipmentOrderCollectionAtom } from "lib/core-react/store/shipmentOrder/shipmentOrderAtom";
import { ShipmentOrderCollectionModel } from "models/shipmentOrderCollectionModel";
import { PaginationModel } from "models/pagination";
import { ApiHelperModel } from "models/apiHelper";
import { ShipmentProductModel } from "models/shipmentProductCollectionModel";
import { IFilterType } from "types/filters";

import FiltersComponent from "components/FiltersComponent";
import NestedProductTable from "./components/ShipmentProductsTable";
import useWindowWidth from "lib/core-react/hooks/public/useWindowWidth";

export interface ShipmentOrderDataType {
  key: React.Key;
  orderId: string;
  customerName: string;
  customerPhone?: string | null;
  customerEmail: string;
  customerId: number;
  customerGravatar?: string | null;
  shippingMark?: string | null;
  createdAt: string;
  products: ShipmentProductModel[];
}
const ShipmentOrders = () => {
  const { getShipmentOrder } = useGetShipmentOrder();
  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
    refetch: refetchFromFilter
  } = useDataFilters();
  // State
  const {
    data: shipmentOrderCollectionData,
    isLoading: shipmentOrderLoading,
    refetch,
  } = useAtomValue(shipmentOrderCollectionAtom);
  
  const { isMobile } = useWindowWidth();

  // Api Call
  useEffect(() => {
    if ((filters && !isFetched && isFirstCall) || refetch || refetchFromFilter) {
      filters && ApiHelperModel.makeGetRequest(filters, getShipmentOrder);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstCall, filters, isFetched, refetch , refetchFromFilter]);

  // shipment Order Collection
  const shipmentOrderCollection =
    shipmentOrderCollectionData &&
    new ShipmentOrderCollectionModel(shipmentOrderCollectionData);

  // Filter
  let filterData = shipmentOrderCollection?.filters;

  // Getting all available filters
  useEffect(() => {
    if (!isFetched && shipmentOrderCollection?.filters) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [isFetched, initializeAvailableFilter, shipmentOrderCollection?.filters]);

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getShipmentOrder,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    getShipmentOrder,
    handlePaginationChange,
  );

  // Filter Handler
  const handleFilter = () => {
    filters && ApiHelperModel.makeGetRequest(filters, getShipmentOrder);
  };

  const expandedRowRender = (allRecord: ShipmentOrderDataType) => {
    return <NestedProductTable data={allRecord.products} />;
  };

  const columns: TableColumnsType<ShipmentOrderDataType> = [
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
      render: (value: string) => {
        return <Typography.Text copyable>{value}</Typography.Text>;
      },
    },
    {
      title: "Customer name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Phone",
      dataIndex: "customerPhone",
      key: "customerPhone",
      render: (phone: string) => {
        return <a href={`tel:${phone}`}>{phone}</a>;
      },
    },
    {
      title: "Email",
      dataIndex: "customerEmail",
      key: "customerEmail",
      render: (email: string) => {
        return <a href={`mailto:${email}`}>{email}</a>;
      },
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      hidden: isMobile,
      render: (value: string) => {
        return <Tag color="purple">{formatDateTime(value)}</Tag>;
      },
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      hidden: !isMobile,
      fixed: isMobile ? "right" : undefined,
      render: (value: string) => {
        return <Tag color="purple">{formatDateTime(value)}</Tag>;
      },
    },
  ];

  const data: ShipmentOrderDataType[] = shipmentOrderCollection
    ? shipmentOrderCollection.getOrders()
    : [];

  return (
    <>
      <div>
        <PageHeader
          ghost={false}
          onBack={() => window.history.back()}
          title="Shipment Orders"
          style={{ marginTop: "10px" }}
          extra={[
            <Link
              key="create-commission-button"
              to="/shipping/shipment-orders/create"
            >
              <Button type="primary">Create Order For Customer</Button>
            </Link>,
          ]}
        >
          {filters && Object.keys(filters).length > 0 && (
            <Row>
              <Col span={24}>
                <Card title="Filter">
                  <FiltersComponent
                    handleProductFilter={handleFilter}
                    handleFilterChange={handleFilterChange}
                    handelFilterClear={handelFilterClear}
                    isFetched={isFetched}
                    filters={filters}
                    filtersData={filterData}
                    isFromProductReceived={true}
                  />
                </Card>
              </Col>
            </Row>
          )}

          <div style={{ marginTop: "15px" }}>
            <Table
              columns={columns}
              expandable={{ expandedRowRender }}
              rowKey="orderId"
              pagination={paginationConfig}
              dataSource={data}
              loading={shipmentOrderLoading}
              scroll={{ x: 1257 }}
            />
          </div>
        </PageHeader>
      </div>
    </>
  );
};

export default ShipmentOrders;
