import BaseResource from "../public/baseResource";
import { ResponsePromise } from "lib/core/request/types/typings";
import { CreateCampaignPostRequestModel } from "models/campaignCollectionModel";
import {
  IAddCampaignProductsPostRequest,
  IDeleteCampaginProducts,
  IUpdateCampainPostRequest,
} from "types/campaignCollection";
import QueryString from "qs";

class CampaignResource extends BaseResource {
  path = `/api/campaign/admin/campaign/v1/campaigns`;
  getCampaign(customHeaders: Record<string, any> = {}): ResponsePromise {
    const path = `${this.path}?expand=region,store`;
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  createCampaign(
    payload: CreateCampaignPostRequestModel,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }

  updateCampaign(
    id: string,
    payload: IUpdateCampainPostRequest,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/${id}`;
    return this.client.request("PUT", path, payload, {}, customHeaders);
  }

  updatePause(
    id: string,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/${id}/pause`;
    return this.client.request("PUT", path, {},undefined, customHeaders);
  }

  updateResume(
    id: string,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/${id}/resume`;
    return this.client.request("PUT", path, {},undefined, customHeaders);
  }

  delete(id: string, customHeaders: Record<string, any> = {}): ResponsePromise {
    const path = `${this.path}/${id}`;
    return this.client.request("DELETE", path, {},undefined, customHeaders);
  }

  // Campaign product list
  getCampaignProducts(
    id: string,
    params?: Record<string, any>,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    let path = `${this.path}/${id}/products`;
    if (params) {
      path = `${this.path}/${id}/products?${QueryString.stringify(params)}`;
    }
    return this.client.request("GET", path, undefined, {}, customHeaders);
  }

  deleteCampaginProducts(
    id: string,
    payload: IDeleteCampaginProducts,
  ): ResponsePromise {
    const path = `${this.path}/${id}/products`;
    return this.client.request("DELETE", path, payload, {},{});
  }

  // Add product to campaign
  addCampaignProducts(
    id: string,
    locale: string,
    region: string,
    payload: IAddCampaignProductsPostRequest,
    customHeaders: Record<string, any> = {},
  ): ResponsePromise {
    const path = `${this.path}/${id}/products-by-id?locale=${locale}&region=${region}`;
    return this.client.request("POST", path, payload, {}, customHeaders);
  }
}

export default CampaignResource;
