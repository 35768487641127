import { useAtom } from "jotai";
import { useService } from "lib/core-react/contexts";
import {
  transactionCollectionAtom,
  transactionDetailAtom,
} from "lib/core-react/store/store";
import { getError } from "../utils/errors";
import { IUpdateStatus } from "types/transactionCollection";
import { useState } from "react";

export const useTransactions = () => {
  const [transaction, setTransaction] = useAtom(transactionCollectionAtom);

  const { paymentService } = useService();

  const getTransctions = async (params?: string): Promise<void> => {
    setTransaction({ ...transaction, isLoading: true, error: null });
    try {
      const response = await paymentService.transactionResource.get(params);
      setTransaction({
        ...transaction,
        isLoading: false,
        data: response,
        refetch: false,
      });
    } catch (error: any) {
      setTransaction({
        ...transaction,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };

  return { getTransctions } as const;
};

export const useTransactionsDetail = () => {
  const [transaction, setTransaction] = useAtom(transactionDetailAtom);

  const { paymentService } = useService();

  const getTransctionsDetail = async (
    transaction_id: string,
  ): Promise<void> => {
    setTransaction({ ...transaction, isLoading: true, error: null });
    try {
      const response = await paymentService.transactionResource.getDetail(
        Number(transaction_id),
      );
      setTransaction({
        ...transaction,
        isLoading: false,
        data: response.data,
        refetch: false,
      });
    } catch (error: any) {
      setTransaction({
        ...transaction,
        isLoading: false,
        refetch: false,
        error: getError(error),
      });
      throw error;
    }
  };

  return { getTransctionsDetail } as const;
};

export const useUpeateStatus = () => {
  const [transaction, setTransaction] = useAtom(transactionCollectionAtom);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setError] = useState<string>("");

  const { paymentService } = useService();

  const updateStatus = async (id: number, payload: IUpdateStatus) => {
    setIsLoading(true);
    try {
      const response = await paymentService.transactionResource.updateSatatus(
        id,
        payload,
      );
      setIsLoading(false);
      setTransaction({ ...transaction, refetch: true });
      return response.data;
    } catch (error) {
      setError(getError(error));
      setIsLoading(false);
      throw error;
    }
  };
  return { updateStatus, isLoading, isError };
};
