/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useAtom } from "jotai";
import { PageHeader } from "@ant-design/pro-layout";
import { Button, Card, Col, Row, Space, Table, Tag, Typography } from "antd";
import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import { useGetDiscountUsageHistories } from "lib/core-react/hooks/private/useDiscount";
import { discountUsageHistoryCollectionAtom } from "lib/core-react/store/store";
import useDataFilters from "hooks/useDataFilters";
import { IFilterType } from "types/filters";
import { PaginationModel } from "models/pagination";
import { ApiHelperModel } from "models/apiHelper";
import { formatDateTime } from "utils/helpers";

import FiltersComponent from "components/FiltersComponent";
import {
  DiscountUsageHistoryCollectionModel,
  DiscountUsageHistoryModel,
} from "models/discountUsageHistoryCollectionModel";
import { useNavigate } from "react-router-dom";

const DiscountUsageHistory = () => {
  const navigate = useNavigate();
  const { getDiscountUsageHistories } = useGetDiscountUsageHistories();
  const [{ data: discountUsageHistoryCollectionData, isLoading, refetch }] =
    useAtom(discountUsageHistoryCollectionAtom);
  const {
    filters,
    handleFilterChange,
    handelFilterClear,
    isFirstCall,
    isFetched,
    initializeAvailableFilter,
  } = useDataFilters();

  // Api Call
  useEffect(() => {
    if ((!isFetched && isFirstCall) || refetch) {
      ApiHelperModel.makeGetRequest({}, getDiscountUsageHistories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstCall, isFetched, refetch]);

  const DiscountUsageHistoryCollectionData =
    discountUsageHistoryCollectionData &&
    new DiscountUsageHistoryCollectionModel(discountUsageHistoryCollectionData);

  // Filter
  const filterData = DiscountUsageHistoryCollectionData?.getFilters();
  // Pagination
  const paginationData = DiscountUsageHistoryCollectionData?.getPagination();

  // Getting all available filters
  useEffect(() => {
    if (!isFetched && DiscountUsageHistoryCollectionData?.filters) {
      initializeAvailableFilter(filterData as IFilterType);
    }
  }, [
    isFetched,
    initializeAvailableFilter,
    DiscountUsageHistoryCollectionData?.getFilters(),
  ]);

  // Pagination Handler
  const handlePaginationChange = (pageCount: number, pageSize: number) => {
    const pageInfo = { page: pageCount, per_page: pageSize };
    handleFilterChange(pageInfo);
    ApiHelperModel.makeGetRequest(
      {
        ...filters,
        ...pageInfo,
      },
      getDiscountUsageHistories,
    );
  };

  // Pagination Configuration
  const paginationConfig = PaginationModel.getPaginationConfig(
    DiscountUsageHistoryCollectionData,
    handlePaginationChange,
  );

  // Filter Handler
  const handleProductFilter = () => {
    filters &&
      ApiHelperModel.makeGetRequest(filters, getDiscountUsageHistories);
  };

  const { Text } = Typography;

  const columns = [
    {
      title: "#SL",
      dataIndex: "id",
      key: "sl",
      width: 70,
      align: "center" as const,
      render: (_: string, __: DiscountUsageHistoryModel, index: number) => {
        return (
          <div>
            {paginationData
              ? (paginationData.current_page - 1) * paginationData.per_page +
                index +
                1
              : ""}
          </div>
        );
      },
    },
    {
      title: "Discount info",
      dataIndex: "name",
      key: "name",
      render: (_: string, record: DiscountUsageHistoryModel) => {
        return (
          <Space direction="vertical" wrap>
            <Space>
              <Text strong>Region:</Text>
              <Text>{record.getRegion().getName()}</Text>
            </Space>
            <Space>
              <Text strong>Name:</Text>
              <Text>{record.getDiscount().getName()}</Text>
            </Space>
            <Space>
              <Text strong>Coupon code</Text>
              <Text copyable>{record.getDiscount().getCouponCode()}</Text>
            </Space>
            <Space direction="vertical">
              <Text strong>Start - End:</Text>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>{formatDateTime(record.getDiscount().getStartDate())}</div>
                <div>{formatDateTime(record.getDiscount().getEndDate())}</div>
              </div>
            </Space>
            <Space>
              <Text strong>Amount:</Text>
              <Text>{record.getDiscount().getAmount()}</Text>
              <Tag color="success">{record.getDiscount().getAmountType()}</Tag>
            </Space>
            <Space>
              <Text strong>Max discount amount:</Text>
              <Text>{record.getDiscount().getMaximumDiscountAmount()}</Text>
            </Space>
            <Space>
              <Text strong>Limitation type:</Text>
              <Tag color="success">
                {record.getDiscount().getLimitationType()}
              </Tag>
            </Space>
            <Space>
              <Text strong>Limitation times:</Text>
              <Text>{record.getDiscount().getLimitationTimes()}</Text>
            </Space>
          </Space>
        );
      },
    },
    {
      title: "Invoice",
      dataIndex: "discountable",
      key: "discountable",
      render: (_: string, record: DiscountUsageHistoryModel) => {
        return (
          <Space direction="vertical" wrap>
            <Space>
              <Text strong>User:</Text>
              <Text>
                {record.getUser().getName()} ({record.getUser().getEmail()})
              </Text>
            </Space>
            <Space>
              <Text strong>Total amount:</Text>
              <Text>{record.getDiscountable().getTotalAmount()}</Text>
            </Space>
            <Space>
              <Text strong>Initial amount:</Text>
              <Text>{record.getDiscountable().getInitialAmount()}</Text>
            </Space>
            <Space>
              <Text strong>Refundable amount:</Text>
              <Text>{record.getDiscountable().getRefundableAmount()}</Text>
            </Space>
            <Space>
              <Text strong>Status:</Text>
              <Tag
                icon={
                  record.getDiscountable().getStatus() === "unpaid" ? (
                    <ExclamationCircleOutlined />
                  ) : (
                    <CheckCircleOutlined />
                  )
                }
                color={
                  record.getDiscountable().getStatus() === "unpaid"
                    ? "warning"
                    : "success"
                }
              >
                {record.getDiscountable().getStatus()}
              </Tag>
            </Space>
            <Space>
              <Text strong>Invoice Created:</Text>
              <Text>
                {formatDateTime(record.getDiscountable().getCreatedAt())}
              </Text>
            </Space>
            <Space>
              <Text strong>Last Update:</Text>
              <Text>
                {formatDateTime(record.getDiscountable().getUpdatedAt())}
              </Text>
            </Space>
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={() => {
                navigate(
                  `/buy-and-ship-for-me/orders/edit/${record
                    .getDiscountable()
                    .getOrderId()}`,
                );
              }}
            >
              View Order Details <ArrowRightOutlined />
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <PageHeader
            style={{ marginTop: "10px" }}
            ghost={false}
            title="Discount Usage History"
          >
            {filters && Object.keys(filters).length > 0 && (
              <Row>
                <Col span={24}>
                  <Card title="Filter">
                    <FiltersComponent
                      handleProductFilter={handleProductFilter}
                      handleFilterChange={handleFilterChange}
                      handelFilterClear={handelFilterClear}
                      isFetched={isFetched}
                      filters={filters}
                      filtersData={filterData}
                      isFromProductReceived={true}
                    />
                  </Card>
                </Col>
              </Row>
            )}
            <div>
              <Table
                columns={columns}
                dataSource={DiscountUsageHistoryCollectionData?.getData()}
                bordered
                loading={isLoading}
                rowKey="id"
                pagination={paginationConfig}
                scroll={{ x: 1257 }}
              />
            </div>
          </PageHeader>
        </Col>
      </Row>
    </>
  );
};

export default DiscountUsageHistory;
