import {
  Button,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Typography,
  notification,
} from "antd";
import React, { useEffect } from "react";
import { DeleteTwoTone, PlusSquareTwoTone } from "@ant-design/icons";

import { useDefaultAgentAssignWithPrice } from "lib/core-react/hooks/private/useShipping";
import { IDefaultWithPriceAssignAgentPayload } from "types/shipmentProductCollection";
import { ICategoryPriceDetails } from "types/agentCategoryPriceReadCollection";

import { WeightUnitEnum } from "enums/shipForMeContextEnums";
import { CommissionRateTypeEnums } from "enums/exchangeListCollectionEnums";

import { transformEnumToLabeledValue } from "utils/helpers";

interface IProps {
  open: boolean;
  priceReadId: number;
  agentCategoryPriceRead: ICategoryPriceDetails;
  selectedProductIds: React.Key[];
  onCancel: () => void;
}
const AgentAssignWithPriceModal = ({
  open,
  onCancel,
  priceReadId,
  selectedProductIds,
}: IProps) => {
  const {
    defaultAgentAssignWithPrice,
    isLoading: defaultAgentAssignWithPriceLoading,
  } = useDefaultAgentAssignWithPrice();
  const [form] = Form.useForm();
  const okHandler = () => {
    console.log(form);
  };

  useEffect(() => {
    form.resetFields();
  }, [form, open]);

  const handleFormHandler = async (values: any) => {
    console.log(values, "values");
    try {
      const payload: IDefaultWithPriceAssignAgentPayload = {
        agent_category_price_read_id: priceReadId,
        shipment_product_ids: [...selectedProductIds.map((id) => Number(id))],
        override: {
          unit_type: values.unit_type,
          rate: values.rate,
          slot: values.slots,
          commission: {
            rate: values.commission.rate,
            rate_type: values.commission.rate_type,
            max_cap: values.commission.max_cap,
          },
        },
      };
      const res = await defaultAgentAssignWithPrice(payload);
      notification["success"]({
        message: res.message,
      });
      onCancel();
    } catch (error: any) {
      notification["error"]({
        message: error.response.data.message,
      });
    }
  };

  return (
    <Modal
      footer={false}
      destroyOnClose={true}
      title="Agent Assign With Price"
      open={open}
      onOk={okHandler}
      onCancel={onCancel}
      width="500px"
    >
      <Form
        onFinish={handleFormHandler}
        form={form}
        initialValues={{
          slots: [
            {
              min_amount: "",
              max_amount: "",
              rate: "",
            },
          ],
        }}
      >
        <div>
          <Space direction="horizontal">
            <Form.Item
              name="unit_type"
              label="Unit type"
              rules={[
                {
                  required: true,
                  message: "Unit type is required",
                },
              ]}
            >
              <Select
                placeholder="Select"
                options={transformEnumToLabeledValue(WeightUnitEnum)}
              />
            </Form.Item>

            <Form.Item
              name="rate"
              label="Rate"
              rules={[
                {
                  required: true,
                  message: "Rate is required",
                },
              ]}
            >
              <InputNumber placeholder="0" />
            </Form.Item>
          </Space>
          <Form.Item style={{ marginBottom: "0" }} label="Commission">
            <Space style={{ display: "flex" }} align="baseline">
              <Form.Item
                name={["commission", "rate_type"]}
                rules={[{ required: true, message: "Required" }]}
              >
                <Select
                  placeholder="Rate type"
                  options={transformEnumToLabeledValue(CommissionRateTypeEnums)}
                />
              </Form.Item>
              <Form.Item
                name={["commission", "rate"]}
                rules={[{ required: true, message: "Required" }]}
              >
                <InputNumber placeholder="Rate" />
              </Form.Item>
              <Form.Item name={["commission", "max_cap"]}>
                <InputNumber placeholder="Max cap" />
              </Form.Item>
            </Space>
          </Form.Item>

          <Form.List name="slots">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Space
                    key={key}
                    direction="vertical"
                    size="middle"
                    style={{
                      display: "flex",
                      margin: "0 10px",
                    }}
                  >
                    <Row
                      justify="space-between"
                      style={{
                        padding: 5,
                        borderLeft: "2px solid hotpink",
                      }}
                    >
                      <Typography.Text mark>Slot #{index + 1}</Typography.Text>

                      {index > 0 && (
                        <DeleteTwoTone
                          twoToneColor="hotpink"
                          onClick={() => remove(name)}
                        />
                      )}
                    </Row>
                    <Space style={{ display: "flex", flexWrap: "wrap" }}>
                      <Form.Item
                        {...restField}
                        name={[name, "min_amount"]}
                        rules={[{ required: true, message: "Required" }]}
                        label="Min amount"
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          placeholder="Min amount"
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "max_amount"]}
                        rules={[{ required: true, message: "Required" }]}
                        label="Max amount"
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          placeholder="Max amount"
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "rate"]}
                        rules={[{ required: true, message: "Required" }]}
                        label="Rate"
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          placeholder="Rate"
                        />
                      </Form.Item>
                    </Space>
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      const lastVariationIndex = fields.length - 1;
                      form
                        .validateFields([
                          ["slots", lastVariationIndex, "min_amount"],
                          ["slots", lastVariationIndex, "max_amount"],
                          ["rate", lastVariationIndex, "rate"],
                        ])
                        .then(() => {
                          add({
                            min_amount: "",
                            max_amount: "",
                            rate: "",
                          });
                        })
                        .catch((error) => {
                          console.error("Validation Error:", error);
                        });
                    }}
                    block
                    icon={<PlusSquareTwoTone />}
                  >
                    Add more slot
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
        <Form.Item style={{ textAlign: "right" }}>
          <Button
            loading={defaultAgentAssignWithPriceLoading}
            type="primary"
            htmlType="submit"
          >
            Assign Agent
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AgentAssignWithPriceModal;
