import {
  CountryCollectionEnums,
  CountryDataStatus,
} from "enums/countryCollectionEnums";
import {
  ICountry,
  ICountryCollection,
  ICountryUpdateRequest,
  IProductDetailExtendedCountry,
} from "types/countryCollection";
import { IFilterType } from "types/filters";
import { PaginationModel } from "./paginationModel";

export class ProductDetailExtendedCountryModel {
  object: string;
  code: string;
  name: string;

  constructor(dataItem: IProductDetailExtendedCountry) {
    this.object = dataItem.object;
    this.code = dataItem.code;
    this.name = dataItem.name;
  }
  getCode = () => this.code;
  getName = () => this.name;
}

export class CountryModel {
  object: CountryCollectionEnums.Country;
  id: number;
  code: string;
  name: string;
  flag: string;
  status?: string;

  constructor(dataItem: ICountry) {
    this.object = dataItem.object;
    this.id = dataItem.id;
    this.code = dataItem.code;
    this.name = dataItem.name;
    this.flag = dataItem.flag;
    this.status = dataItem.status;
  }
  getId = () => this.id;
  getCode = () => this.code;
  getName = () => this.name;
  getFlag = () => this.flag;
  getStatus = () => this.status;
}

export class CountryCollectionModel {
  object: CountryCollectionEnums.CountryCollection;
  data: CountryModel[];
  pagination: PaginationModel;
  filters: IFilterType;

  constructor(data: ICountryCollection) {
    this.object = data.object;
    this.data = data.data.map((item) => new CountryModel(item));
    this.pagination = new PaginationModel(data.pagination);
    this.filters = data.filters;
  }

  getData = () => this.data;
  getPagination = () => this.pagination;
  getFilters = () => this.filters;

  getCountryCode() {
    const obj: { [key: string]: string } = {};
    this.data.forEach((item) => (obj[item.id] = item.code));
    return obj;
  }
  getCountryId() {
    const obj: { [key: number]: string } = {};
    this.data.forEach((item) => (obj[item.id] = item.name));
    return obj;
  }

  getCountryId2() {
    const obj: { [key: number]: string } = {};
    this.data.forEach((item) => (obj[item.id] = item.code));
    return obj;
  }

  getCountryById(id: number): CountryModel | undefined {
    return this.data.find((item) => item.id === id);
  }

  getCountryLabeledValue() {
    return this.data.map((item) => ({
      label: item.getName(),
      value: item.getId(),
    }));
  }
  getCountryLabeledLabel() {
    return this.data.map((item) => ({
      label: item.getName(),
      value: item.getName(),
    }));
  }

  getCountryLabeledValueAndId() {
    return this.data.map((item) => ({
      label: item.getName(),
      value: item.getCode(),
      id: item.getId(),
    }));
  }
}

//update Request
export class CountryUpdateRequestModel {
  name: string;
  status: CountryDataStatus;

  constructor(reqData: ICountryUpdateRequest) {
    this.name = reqData.name;
    this.status = reqData.status;
  }
}
